import React, { useCallback } from 'react';
import FinButton from 'components/FinButton';

import { useDispatch } from 'react-redux';

import { SeparatorVertical } from 'components/FinSeparador';
import { Wrapper } from 'components/FinWrappers';
import { Ring } from '@uiball/loaders';
import { Creators as uiDialogActions } from 'store/ducks/uiDialog';
import {
  IconCheckStyle,
  IconCirclePlusStyle,
  IconSendStyle,
  IconCalcStyle,
} from './style';
import { STATUS } from '../../config';

import { useMutateNovaProposta } from '../../mutates/useMutateNovaProposta';
import { useMutateSimular } from '../../mutates/useMutateSimular';
import { useMutateAceitarProposta } from '../../mutates/useMutateAceitarProposta';
import { useMutateEnviarProposta } from '../../mutates/useMutateEnviarProposta';
import { useMutateConfirmarPagamento } from '../../mutates/useMutateConfirmarPagamento';

export const PropostaButtons = ({ tipo, data, excludeInteraction = [] }) => {
  const negociar =
    STATUS[tipo].permissoes.indexOf('negociar') !== -1 &&
    excludeInteraction.indexOf('negociar') === -1;
  const aceitar =
    STATUS[tipo].permissoes.indexOf('aceitar') !== -1 &&
    excludeInteraction.indexOf('aceitar') === -1;
  const confirmar =
    STATUS[tipo].permissoes.indexOf('confirmarPagamento') !== -1 &&
    excludeInteraction.indexOf('confirmarPagamento') === -1;
  const enviar =
    STATUS[tipo].permissoes.indexOf('enviar') !== -1 &&
    excludeInteraction.indexOf('enviar') === -1;
  const recalcular =
    STATUS[tipo].permissoes.indexOf('recalcular') !== -1 &&
    excludeInteraction.indexOf('recalcular') === -1;

  const novaProposta = useMutateNovaProposta();
  const simular = useMutateSimular();
  const aceitarProposta = useMutateAceitarProposta();
  const enviarProposta = useMutateEnviarProposta();
  const confirmarPagamento = useMutateConfirmarPagamento();

  const dispatch = useDispatch();

  const dialogOpen = useCallback(
    (tipo, action, values) => {
      dispatch(uiDialogActions.uiDialogOpen(tipo, action, null, values));
    },
    [dispatch]
  );

  return (
    <Wrapper padding="0" style={{ alignItems: 'flex-end', width: '100%' }}>
      {negociar && (
        <FinButton
          tipo="secondary"
          preenchimento="outline"
          largura="total"
          rounded
          onClick={() =>
            novaProposta.mutate({
              idOperacao: data.idOperacao,
              idProposta: data.idProposta,
            })
          }
        >
          <IconCirclePlusStyle />
          <SeparatorVertical pwidth="0.5rem" />
          Negociar
        </FinButton>
      )}

      {negociar && aceitar && <SeparatorVertical pwidth="1rem" />}

      {aceitar && (
        <FinButton
          largura="total"
          onClick={(e) => {
            e.preventDefault();
            dialogOpen('aceitarProposta', () =>
              aceitarProposta.mutate({
                // idGrupo: data.idGrupo,
                idProposta: data.idProposta,
                // idPessoaConvenio: data.idPessoaConvenio,
              })
            );
          }}
          rounded
        >
          {aceitarProposta.isLoading ? (
            <Ring size={20} lineWeight={5} speed={2} color="black" />
          ) : (
            <>
              <IconCheckStyle />
              <SeparatorVertical pwidth="0.5rem" />
              Aceitar
            </>
          )}
        </FinButton>
      )}

      {recalcular && (
        <FinButton
          tipo="secondary"
          preenchimento="outline"
          largura="total"
          onClick={() => simular.mutate()}
          disabled={simular.isLoading}
          rounded
        >
          {simular.isLoading ? (
            <Ring size={20} lineWeight={5} speed={2} color="black" />
          ) : (
            <>
              <IconCalcStyle />
              <SeparatorVertical pwidth="0.5rem" />
              Recalcular
            </>
          )}
        </FinButton>
      )}

      {recalcular && enviar && <SeparatorVertical pwidth="1rem" />}

      {enviar && (
        <FinButton
          largura="total"
          disabled={enviarProposta.isLoading}
          onClick={(e) => {
            e.preventDefault();
            // simular.mutate();
            dialogOpen('enviarProposta', () => enviarProposta.mutate());
          }}
          rounded
        >
          {enviarProposta.isLoading ? (
            <Ring size={20} lineWeight={5} speed={2} color="black" />
          ) : (
            <>
              <IconSendStyle />
              <SeparatorVertical pwidth="0.5rem" />
              Enviar
            </>
          )}
        </FinButton>
      )}

      {confirmar && (
        <FinButton
          largura="total"
          onClick={(e) => {
            e.preventDefault();
            dialogOpen('confirmarPagamento', () =>
              confirmarPagamento.mutate({ idProposta: data.idProposta })
            );
          }}
          rounded
        >
          {confirmarPagamento.isLoading ? (
            <Ring size={20} lineWeight={5} speed={2} color="black" />
          ) : (
            <>
              <IconCheckStyle />
              <SeparatorVertical pwidth="0.5rem" />
              Confirmar Pagamento
            </>
          )}
        </FinButton>
      )}
    </Wrapper>
  );
};

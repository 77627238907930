import React from 'react';

import Skeleton from 'react-loading-skeleton';
import { NWTable } from '../Table';

/* <div
  style={{
    width: 100,
    display: 'flex',
    gap: '15px',
  }}
>
  <Skeleton width="157px" height="159px" />
  <Skeleton width="157px" height="159px" />
  <Skeleton width="157px" height="159px" />
  <Skeleton width="157px" height="159px" />
</div>; */

export function SkeletonTable() {
  return (
    <NWTable.Table>
      <NWTable.Thead>
        <NWTable.Tr>
          <NWTable.Th borderRadiusLeft largura="200px">
            EMPRESA
          </NWTable.Th>
          <NWTable.Th largura="100px">DATA FUNDAÇÃO</NWTable.Th>
          <NWTable.Th largura="40px">STATUS</NWTable.Th>
          <NWTable.Th largura="100px">CAPITAL SOCIAL</NWTable.Th>
          <NWTable.Th borderRadiusRight largura="75px">
            FATURAMENTO
          </NWTable.Th>
        </NWTable.Tr>
      </NWTable.Thead>
      <NWTable.Tbody>
        <NWTable.Tr>
          <NWTable.Td style={{ padding: '4px' }}>
            <Skeleton />
          </NWTable.Td>
          <NWTable.Td style={{ padding: '4px' }}>
            <Skeleton />
          </NWTable.Td>
          <NWTable.Td style={{ padding: '4px' }}>
            <Skeleton />
          </NWTable.Td>
          <NWTable.Td style={{ padding: '4px' }}>
            <Skeleton />
          </NWTable.Td>
          <NWTable.Td style={{ padding: '4px' }}>
            <Skeleton />
          </NWTable.Td>
        </NWTable.Tr>
        <NWTable.Tr>
          <NWTable.Td style={{ padding: '4px' }}>
            <Skeleton />
          </NWTable.Td>
          <NWTable.Td style={{ padding: '4px' }}>
            <Skeleton />
          </NWTable.Td>
          <NWTable.Td style={{ padding: '4px' }}>
            <Skeleton />
          </NWTable.Td>
          <NWTable.Td style={{ padding: '4px' }}>
            <Skeleton />
          </NWTable.Td>
          <NWTable.Td style={{ padding: '4px' }}>
            <Skeleton />
          </NWTable.Td>
        </NWTable.Tr>
        <NWTable.Tr>
          <NWTable.Td style={{ padding: '4px' }}>
            <Skeleton />
          </NWTable.Td>
          <NWTable.Td style={{ padding: '4px' }}>
            <Skeleton />
          </NWTable.Td>
          <NWTable.Td style={{ padding: '4px' }}>
            <Skeleton />
          </NWTable.Td>
          <NWTable.Td style={{ padding: '4px' }}>
            <Skeleton />
          </NWTable.Td>
          <NWTable.Td style={{ padding: '4px' }}>
            <Skeleton />
          </NWTable.Td>
        </NWTable.Tr>
      </NWTable.Tbody>
    </NWTable.Table>
  );
}

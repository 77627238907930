import React from 'react';
import ShakaPlayer from 'shaka-player-react';
import 'shaka-player-react/dist/controls.css';

import { NwDialog } from 'components/NwDialog';

export function FinPlayer({ src, children }) {
  return (
    <NwDialog trigger={children}>
      <ShakaPlayer
        className="sk-player-container"
        width="750"
        height="420"
        autoPlay
        // src="https://adservicesdiag306.blob.core.windows.net/video/video%20(1).mp4?sv=2020-10-02&st=2022-09-26T19%3A32%3A00Z&se=2025-01-26T19%3A32%3A00Z&sr=b&sp=r&sig=AGFC5sgl60VdkR3KaHk1VzJz3lLH6qJheBHi0Fwl4I8%3D"
        src={src}
      />
    </NwDialog>
  );
}

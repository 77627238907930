/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';

import { Layout } from 'components/FinLayout';
import { Navigation } from 'components/FinNavigation';

import Dialog from 'components/FinDialog';
import { Provider } from 'pages/Operacoes/provider';
import { ModalDefault } from 'components/ModalDefault';

import { useAuth } from 'react-oidc-context';

import { FinPopUp } from 'components/FinPopUp';
import { Condicionante } from 'components/Condicionante';

import { Notification } from 'components/FinNotification';

import { useGetDataAccess } from 'hooks/useGetDataAccess';

import { ProviderAuth } from 'providers/ProviderAuth';
import { ReactLoadingCuston } from 'components/FinLoader';
import { PaginaLogin } from 'pages/FinLogin';

import 'helpers/autenticacao/nwinterceptor';
import { ModalTermos } from 'components/FinModalCuston';

export function LoggedArea({ children }) {
  const [user, setUser] = useState(null);

  const auth = useAuth();

  const { fetchDataAccess, getDataAccess } = useGetDataAccess();

  useEffect(() => {
    fetchDataAccess().then(() => setUser(getDataAccess()));
  }, [fetchDataAccess]);

  if (auth.isAuthenticated && user) {
    return (
      <ProviderAuth value={user}>
        <Provider>
          <ModalDefault />
          <ModalTermos />
          <Condicionante />
          <Notification />
          <FinPopUp />
          <Dialog />
          <Navigation />
          <Layout>{children}</Layout>
        </Provider>
      </ProviderAuth>
    );
  }
  if (!auth.isAuthenticated) {
    return <Route component={PaginaLogin} />;
  }
  return (
    <ReactLoadingCuston isLoading={true} positionFixed showLabel={false} />
  );
}

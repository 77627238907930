import React from 'react';
import iconNoMatch from 'assets/images/icon-x-circle.svg';
import { ButtonPdf } from './ButtonPdf';

export function ButtonNoMatch({ onClick }) {
  return (
    <ButtonPdf
      label="Não deu Match"
      icon={iconNoMatch}
      iconColor="rgba(217, 37, 37, 1)"
      bgColor="#FBDFDF"
      borderColor="#EE9A9A"
      onClick={onClick}
    />
  );
}

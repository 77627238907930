import { rem } from 'polished';

const cores = {
  preto: '#000000',
  branco: '#FFFFFF',
  cinzaPrimario: '#6A6464',
  preto10: 'rgba(0,0,0,0.1)',
  preto50: 'rgba(0,0,0,0.5)',
  cinzaSecundario: '#909090',
  cinzaTerciario: '#ececec',
  azulPrimario: '#007A87',
  azulPrimarioEscuro: '#005e67',
  azulSecundario: '#00E0D8',
  azulSecundarioEscuro: '#009c97',
  amarelo: '#FFCB04',
  amareloEscuro: '#bb9400',
  vermelho: '#F75E4C',
  vermelhoEscuro: '#c73e2b',
  corMatch: 'rgba(141,205,71,1)',
  corAnalisar: 'rgba(240,180,28,1)',
  corNoMatch: 'rgba(247,94,76,1)',
  corMatch50: 'rgba(141,205,71,0.5)',
  corAnalisar50: 'rgba(240,180,28,0.5)',
  corNoMatch50: 'rgba(247,94,76,0.5)',
};

const colors = {
  transparent: 'transparent',
  preto: '#000000',
  branco: '#FFFFFF',
  azulClaro: '#F7F7F7',
  cinza1: '#F4F4F4',
  cinza2: '#D8D8D8',
  cinza3: '#B8B8B8',
  cinza4: '#969696',
  cinza5: '#646464',
  cinza6: '#1F1F1F',
  azulPrimario: '#008894',
  azulSecundario: '#00DBD5',
  azulTerceario: '#003F46',
  vermelhoPrimario: '#F75E4C',
  vermelhoSecundario: '#FF8D7F',
  vermelhoTerceario: '#A02F21',
  verde: '#6DD400',
  laranja: '#F7B500',
  gradiente1: 'linear-gradient(171.24deg, #008894 -26.67%, #F75E4C 112.93%)',
  gradiente2: 'linear-gradient(178.81deg, #00DBD5 0%, #008894 100%)',
  gradiente3: 'linear-gradient(174.55deg, #DFFAF9 0%, #EFD5D2 85.99%)',
  corMatch: 'rgba(141,205,71,1)',
  corAnalisar: 'rgba(240,180,28,1)',
  corNoMatch: 'rgba(247,94,76,1)',
  corMatch50: 'rgba(141,205,71,0.5)',
  corAnalisar50: 'rgba(240,180,28,0.5)',
  corNoMatch50: 'rgba(247,94,76,0.5)',
};

const effects = {
  dropShadow1: '0px 0px 5px rgba(0,0,0,0.2)',
  dropShadow2: '0px 4px 10px rgba(216, 216, 216, 0.55)',
  dropShadow3: '0px 0px 10px rgba(0,0,0,0.1)',
  dropShadow4: '0 1px 3px rgba(30, 37, 44, 0.14)',
  dropShadow5: '0px 11px 30px rgba(216,216,216,0.98)',
};

const font = {
  fontFamily: `'Roboto',sans-serif;`,
  titulo1: {
    tamanho: rem('96px'),
    espacamento: '-1.5px',
    peso: 'light',
  },
  titulo2: {
    tamanho: rem('60px'),
    espacamento: '-0.5px',
    peso: 'light',
  },
  titulo3: {
    tamanho: rem('48px'),
    espacamento: '0px',
    peso: 'normal',
  },
  titulo4: {
    tamanho: rem('34px'),
    espacamento: '0.25px',
    peso: '600',
  },
  titulo5: {
    tamanho: rem('24px'),
    espacamento: '0px',
    peso: 'normal',
  },
  titulo6: {
    tamanho: rem('20px'),
    espacamento: '0.15px',
    peso: 'medio',
  },
  subtitulo1: {
    tamanho: rem('16px'),
    espacamento: '0.15px',
    peso: 'normal',
  },
  subtitulo2: {
    tamanho: rem('14px'),
    espacamento: '0.1px',
    peso: 'medio',
  },
  corpo1: {
    tamanho: rem('16px'),
    espacamento: '0.5px',
    peso: 'normal',
  },
  corpo2: {
    tamanho: rem('14px'),
    espacamento: '0.25px',
    peso: 'normal',
  },
  botao: {
    tamanho: rem('14px'),
    espacamento: '1.25px',
    peso: 'medio',
  },
  caption: {
    tamanho: rem('12px'),
    espacamento: '0.4px',
    peso: 'normal',
  },
  overline: {
    tamanho: rem('10px'),
    espacamento: '1.5px',
    peso: 'normal',
  },
};

const buttonSizes = {
  smallX: '32px',
  smallM: '38px',
  small: '48px',
  medium: '56px',
  large: '64px',
};

const border = {
  radius: '4px',
};

const paddings = {
  s1: '1rem',
  s2: '2rem',
  s3: '3rem',
};

const finTheme = {
  cores,
  overlay: {
    preto: 'rgba(0, 0, 0, 0.3)',
    branco: 'rgba(255, 255, 255, 0.3)',
  },
  color: {
    preto10: cores.preto10,
    preto50: cores.preto50,
    primario: cores.cinzaPrimario,
    secundario: cores.cinzaSecundario,
    terciario: cores.cinzaTerciario,
    critico: cores.vermelho,
    destaque: cores.amarelo,
    branco: cores.branco,
    azulPrimario: cores.azulPrimario,
    azulSecundario: cores.azulSecundario,
    vermelhoPrimario: cores.vermelho,
    corMatch: colors.corMatch,
    corAnalisar: colors.corAnalisar,
    corNoMatch: colors.corNoMatch,
    corMatch50: colors.corMatch50,
    corAnalisar50: colors.corAnalisar50,
    corNoMatch50: colors.corNoMatch50,
  },
  backgroundColor: {
    preto10: cores.preto10,
    preto50: cores.preto50,
    branco: cores.branco,
    cinza: cores.cinzaSecundario,
    destaque: cores.amarelo,
    destaqueEscuro: cores.amareloEscuro,
    critico: cores.vermelho,
    criticoEscuro: cores.vermelhoEscuro,
    cinzaPrimario: cores.cinzaPrimario,
    cinzaSecundario: cores.cinzaSecundario,
    cinzaTerciario: cores.cinzaTerciario,
    azulPrimario: cores.azulPrimario,
    azulPrimarioEscuro: cores.azulPrimarioEscuro,
    azulSecundario: cores.azulSecundario,
    azulSecundarioEscuro: cores.azulSecundarioEscuro,
    vermelhoPrimario: cores.vermelho,
    vermelhoPrimarioEscuro: cores.vermelhoEscuro,
    corMatch: colors.corMatch,
    corAnalisar: colors.corAnalisar,
    corNoMatch: colors.corNoMatch,
    corMatch50: colors.corMatch50,
    corAnalisar50: colors.corAnalisar50,
    corNoMatch50: colors.corNoMatch50,
  },
  zIndex: {
    botaoCircular: 1,
    dropDownSeta: 2,
    ofertaFiltroDropDown: 8,
    loader: 310,
    modal: {
      container: 350,
      overlay: 300,
    },
    navegacao: {
      barraSuperior: 100,
      menuLateral: 200,
      menuPerfil: 98,
      menuPrincipal: 100,
      overlay: 199,
      rodapeMenuPrincipal: 97,
    },
    operacaoOverlay: 80,
    paginaModal: {
      modalContainer: 400,
      modalOverlay: 149,
    },
    stepLinker: 0,
  },
  fontes: {
    titulo: '#666',
    descricao: '#212529',
    valor: '#444',
    cabecalho: '#444',
  },
  botoes: {
    filtro: {
      backgroundColor: '#FF4D35',
    },
    naoSelecionado: {
      backgroundColor: '#fff',
      color: '#999',
    },
    selecionado: {
      backgroundColor: '#007884',
      color: '#FFF',
    },
    etapaConcluida: {
      backgroundColor: '#007884',
      color: '#FFF',
    },
    etapaAtual: {
      backgroundColor: '#00dbd5',
      color: '#FFF',
    },
    etapaFutura: {
      backgroundColor: '#8b8b8b',
      color: '#00e0d8',
    },
    etapaDesabilitada: {
      backgroundColor: '#8b8b8b',
      color: '#00e0d8',
    },
    etapaNegada: {
      backgroundColor: '#f75e4c',
    },
    desistir: {
      color: '#b9b9b9',
      backgroundColor: 'transparent',
    },
  },
  menu: {
    superior: {
      backgroundColor: '#fff',
    },
    filtro: {
      borderColor: '#d8d8d8',
    },
    botao: {
      abrir: '#d8d8d8',
      fechar: '#00e0d8',
      perfil: '#00dbd5',
    },
    lateral: {
      backgroundColor: '#008894',
      icone: {
        ativado: '#319b84',
        desativado: '#00dbd5',
      },
      celula: {
        ativa: '#00dbd5',
        hover: '#00717b',
        borda: '#ff4d35',
      },
    },
    sombra: {
      backgroundColor: 'rgba(12, 55, 58, 0.61)',
    },
  },
  cronometro: {
    backgroundColor: '#ffeeec',
    color: '#ff4d35',
  },
  body: {
    backgroundColor: '#d8d8d8',
  },
  feedback: {
    backgroundColor: '#FF4D35',
    color: '#FFF',
  },
  modal: {
    botao: {
      confirmar: {
        backgroundColor: '#00dbd5',
        color: '#ffffff',
      },
      cancelar: {
        backgroundColor: '#f8f9fa',
        color: '#212529',
        borderColor: '#f8f9fa',
      },
      fechar: {
        backgroundColor: '#d1d1d1',
      },
    },
    campo: {
      valido: {
        borderColor: '#d8d8d8',
      },
      invalido: {
        borderColor: 'red',
      },
    },
  },
  operacao: {
    posicao: {
      backgroundColor: '#00717b',
    },
    status: {
      color: '#666',
    },
  },
  /** New theme */
  newtheme: {
    colors,
    font: {
      fontFamily: font.fontFamily,
      titulo1: font.titulo1,
      titulo2: font.titulo2,
      titulo3: font.titulo3,
      titulo4: font.titulo4,
      titulo5: font.titulo5,
      titulo6: font.titulo6,
      subtitulo1: font.subtitulo1,
      subtitulo2: font.subtitulo2,
      corpo1: font.corpo1,
      corpo2: font.corpo2,
      botao: font.botao,
      caption: font.caption,
      overline: font.overline,
    },
    tooltip: {
      color: colors.branco,
      backgroundColor: colors.preto,
    },
    framer: {
      box: {
        backgroundColor: colors.branco,
        color: colors.cinza3,
      },
    },
    borderRadius: border.radius,
    paddings: {
      s1: paddings.s1,
      s2: paddings.s2,
      s3: paddings.s3,
    },
    overlay: {
      preto: 'rgba(0, 0, 0, 0.3)',
      branco: 'rgba(255, 255, 255, 0.8)',
    },
    dropShadow: {
      dropShadow1: effects.dropShadow1,
      dropShadow2: effects.dropShadow2,
      dropShadow3: effects.dropShadow3,
      dropShadow4: effects.dropShadow4,
      dropShadow5: effects.dropShadow5,
    },
    color: {
      preto10: cores.preto10,
      preto50: cores.preto50,
      azulPrimario: colors.azulPrimario,
      azulSecundario: colors.azulSecundario,
      azulTerciario: colors.azulTerceario,
      vermelhoPrimario: colors.vermelhoPrimario,
      vermelhoSecundario: colors.vermelhoSecundario,
      vermelhoTerceario: colors.vermelhoTerceario,
      azulClaro: colors.azulClaro,
      cinza1: colors.cinza1,
      cinza2: colors.cinza2,
      cinza3: colors.cinza3,
      cinza4: colors.cinza4,
      cinza5: colors.cinza5,
      cinza6: colors.cinza6,
      branco: colors.branco,
      preto: colors.preto,
      error: colors.vermelhoPrimario,
      success: colors.verde,
      alert: colors.laranja,
      transparent: colors.transparent,
      corMatch: colors.corMatch,
      corAnalisar: colors.corAnalisar,
      corNoMatch: colors.corNoMatch,
      corMatch50: colors.corMatch50,
      corAnalisar50: colors.corAnalisar50,
      corNoMatch50: colors.corNoMatch50,
    },
    gradiente: {
      gradiente1: colors.gradiente1,
      gradiente2: colors.gradiente2,
      gradiente3: colors.gradiente3,
    },
    backgroundColor: {
      preto10: cores.preto10,
      preto50: cores.preto50,
      azulPrimario: colors.azulPrimario,
      azulSecundario: colors.azulSecundario,
      azulTerciario: colors.azulTerceario,
      vermelhoPrimario: colors.vermelhoPrimario,
      vermelhoSecundario: colors.vermelhoSecundario,
      vermelhoTerceario: colors.vermelhoTerceario,
      cinza1: colors.cinza1,
      cinza2: colors.cinza2,
      cinza3: colors.cinza3,
      cinza4: colors.cinza4,
      cinza5: colors.cinza5,
      cinza6: colors.cinza6,
      branco: colors.branco,
      preto: colors.preto,
      error: colors.vermelhoPrimario,
      success: colors.verde,
      alert: colors.laranja,
      transparent: colors.transparent,
      corMatch: colors.corMatch,
      corAnalisar: colors.corAnalisar,
      corNoMatch: colors.corNoMatch,
      corMatch50: colors.corMatch50,
      corAnalisar50: colors.corAnalisar50,
      corNoMatch50: colors.corNoMatch50,
    },
    zIndex: {
      dialogModal: 400,
      dropdownMenu: 9,
      menuInfoUser: 97,
      menuCnpjsEmpresas: 100,
      sideBarMenu: 190,
      shadownMenu: 140,
      header: 190,
      topBarContent: 100,
      menuSearch: 200,
      menuBotao: 300,
      loader: 170,
      modal: {
        container: 350,
        overlay: 600,
      },
      layout: {
        header: 100,
        menuSide: 200,
        menuUser: 98,
        footer: 97,
      },
    },
    fontes: {
      titulo1: colors.cinza6,
      titulo2: colors.vermelhoPrimario,
      descricao: colors.cinza6,
      paragrafo: colors.cinza6,
      labelStrong: colors.cinza6,
      labelLight: colors.cinza5,
      withBgColor: colors.branco,
      tabela: colors.cinza5,
      palavra: colors.cinza5,
      valores: colors.cinza4,
      acaoSecundaria: colors.cinza4,
      input: colors.cinza5,
    },
    modal: {
      shadowColor: colors.preto,
      bodyColor: colors.branco,
    },
    buttons: {
      text: colors.branco,
      sizes: {
        smallX: buttonSizes.smallX,
        small: buttonSizes.small,
        medium: buttonSizes.medium,
        large: buttonSizes.large,
      },
      disabled: {
        background: colors.cinza4,
        border: colors.cinza4,
      },
      primary: {
        background: colors.azulPrimario,
        border: colors.azulPrimario,
      },
      light: {
        background: colors.branco,
        border: colors.branco,
        text: colors.cinza6,
      },
      secondary: {
        background: colors.cinza5,
        border: colors.cinza5,
      },
      error: {
        background: colors.vermelhoTerceario,
        border: colors.vermelhoTerceario,
      },
      alert: {
        background: colors.laranja,
        border: colors.laranja,
      },
      success: {
        background: colors.verde,
        border: colors.verde,
      },
      info: {
        background: colors.vermelhoSecundario,
        border: colors.vermelhoSecundario,
      },
      dark: {
        background: colors.cinza6,
        border: colors.cinza6,
      },
      link: {
        background: colors.transparent,
        border: colors.transparent,
        text: colors.vermelhoPrimario,
      },
    },
  },
};

export { finTheme };

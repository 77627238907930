import React from 'react';

import {
  PaginationButton,
  ArrowTitle,
  Arrow,
  PaginationLinkBack,
} from './style.pagination';

export const PreviousButton = ({ onclick, subtract, visibility, disabled }) => (
  <PaginationButton visibility={visibility}>
    <PaginationLinkBack
      disabled={disabled}
      onClick={(e) => onclick(e, subtract)}
    >
      <Arrow />
      <ArrowTitle></ArrowTitle>
    </PaginationLinkBack>
  </PaginationButton>
);

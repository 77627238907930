import React, { useContext, useState, useEffect } from 'react';

import { ReactComponent as IconDownload } from 'assets/images/icon-download-outline.svg';
import { Ring } from '@uiball/loaders';
import { Card } from '../Comps/Card';
import { NWTable } from '../Comps/Table';
import { DocsUser } from './DocsUser';
import { ButtonDownload, ContainerButton, Wrapper } from './styles.docsuser';

import { TitleDocumentos } from '../Comps/TitleHead';
import { PerfilContext } from '../PaginaFinCredit';
import { useMutateDownloadDoc } from '../hooks/mutate';

export function Documentos() {
  const [selecionados, setSelecionados] = useState([]);
  const { perfil } = useContext(PerfilContext);
  const download = useMutateDownloadDoc();

  const onHandlerDownload = () => {
    const words = perfil.data.nome.split(' ');
    const first = words[0];
    const name = `${first
      .toString()
      .toLowerCase()}-${perfil.data.cnpj.toString()}`;

    const params = {
      idGrupo: perfil.data.idGrupo,
      nome: name,
      documentos: selecionados,
    };
    download.mutate(params);
  };

  useEffect(() => {
    if (download.isSuccess) {
      setSelecionados([]);
    }
  }, [download.isSuccess]);

  return perfil.data &&
    perfil.data.documentos &&
    perfil.data.documentos.length > 0 ? (
    <>
      <TitleDocumentos />
      <Card style={{ margin: '13px 0 48px' }} direction="column">
        <Wrapper>
          <NWTable.Table>
            <NWTable.Thead>
              <NWTable.Tr>
                <NWTable.Th borderRadiusLeft largura="300px">
                  DOCUMENTO
                </NWTable.Th>
                <NWTable.Th largura="200px">ÚLTIMO ATUALIZAÇÃO</NWTable.Th>
                <NWTable.Th borderRadiusRight>ÚLTIMO DOWNLOAD</NWTable.Th>
              </NWTable.Tr>
            </NWTable.Thead>
            <NWTable.Tbody>
              {perfil.data &&
                perfil.data.documentos.map((item) => (
                  <DocsUser
                    data={item}
                    selecionados={selecionados}
                    setSelecionados={setSelecionados}
                  />
                ))}
            </NWTable.Tbody>
          </NWTable.Table>
        </Wrapper>
        <ContainerButton>
          <ButtonDownload
            style={{ minWidth: '200px' }}
            disabled={download.isLoading || selecionados.length === 0}
            onClick={onHandlerDownload}
          >
            {download.isLoading ? (
              <Ring size={20} lineWeight={3} speed={2} color="white" />
            ) : (
              <>
                <IconDownload />
                Baixar Documentos
              </>
            )}
          </ButtonDownload>
        </ContainerButton>
      </Card>
    </>
  ) : null;
}

import styled from 'styled-components/macro';
import { device } from 'devices';

export const FramePic = styled.div`
  width: ${({ pwidth }) => pwidth || '60px'};
  height: ${({ pheight }) => pheight || '60px'};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid #ffffff;
  border-radius: 50%;
  background-color: ${({ pbgColor, theme }) =>
    pbgColor || theme.newtheme.backgroundColor.vermelhoPrimario};
  box-shadow: ${({ theme }) => theme.newtheme.dropShadow.dropShadow1};
  /*@media ${device.laptop} {
    width: ${({ pwidth }) => pwidth || '30px'};
    height: ${({ pheight }) => pheight || '30px'};
  }
  @media ${device.desktop} {
    width: ${({ pwidth }) => pwidth || '60px'};
    height: ${({ pheight }) => pheight || '60px'};
  }*/
`;

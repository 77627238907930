import React from 'react';
import { toast } from 'react-hot-toast';
import { CustomNotification } from './CustomNotification';

export const useNotification = () => {
  return {
    show: (props) => {
      const { data, duration, ...rest } = props;
      toast((t) => <CustomNotification data={data} idToast={t.id} />, {
        duration: duration || Infinity,
        ...rest,
      });
    },
  };
};

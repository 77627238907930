import React from 'react';

import * as Tag from './style.tagmember';

export const FinScanner = ({ style, className }) => {
  return (
    <Tag.Wrapper
      style={style}
      className={className}
      bgColor="linear-gradient(84.06deg, #0CA4B1 17.43%, #52EAFA 93.02%)"
    >
      <Tag.IconCircleWaveStyle
        style={{ marginRight: '5px' }}
        fillColor="#FFFFFF
        "
      />
      <Tag.Label color="#343333">FINSCANNER</Tag.Label>
    </Tag.Wrapper>
  );
};

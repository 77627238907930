/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  showModalCondicionante,
  loadListCondicionanteRequest,
  concluirCondicionanteRequest,
  showModal,
  filterdListCedenteRequest,
  excluirCondicionanteRequest,
  updateCondicDefault,
  sendCondicRequest,
} from 'store/old_actions/actions';
import Modal from './Modal';
// import CurrencyFormatter from 'currency-formatter';

import ItemCondicionante from './ItemCondicionante';
import EditCondicionante from './EditCondicionante';

import { MESSAGES } from '../../utils/messageConst';

import { SeparatorHorizontal, Loading, LoadingBar } from '../Elements';

import { GraphicsInline } from '../Graphics';

const Loader = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        height: '100vh',
      }}
    >
      <Loading lWidth="2rem" aHeight="2rem">
        <LoadingBar />
        <LoadingBar />
        <LoadingBar />
        <LoadingBar />
      </Loading>
    </div>
  );
};

const Condicionante = (props) => {
  const [edit, setEdit] = useState(false);
  const action = (value, data = '') => {
    switch (value) {
      case 'OPEN':
        props.showCondicionante(true);
        break;
      case 'CLOSE':
        props.showCondicionante(false);
        break;
      case 'ADD':
        setEdit(true);
        console.log('ADD');
        break;
      case 'CANCEL':
        setEdit(false);
        console.log('CANCEL');
        props.listCondicionante(null);
        break;
      case 'SAVE':
        console.log('SAVE');
        props.showModal(
          'Atenção',
          MESSAGES.enviarCondicionante,
          {
            type: 'SEND_CONDIC_REQUEST',
          },
          'Sim',
          false,
          false,
          'Não'
        );
        // props.sendCondic();
        break;
      case 'CONCLUIR':
        console.log('CONCLUIR');
        props.showModal(
          'Atenção',
          MESSAGES.concluirCondicionante,
          {
            type: 'CONCLUIR_CONDIC_REQUEST',
            value: data,
          },
          'Sim',
          false,
          false,
          'Não'
        );
        // props.concluirCondicionante(data);
        break;
      case 'CANCELAR':
        console.log('CANCELAR');
        props.showModal(
          'Atenção',
          MESSAGES.cancelarCondicionante,
          {
            type: 'CANCELAR_CONDIC_REQUEST',
            value: data,
          },
          'Sim',
          false,
          false,
          'Não'
        );
        // props.concluirCondicionante(data);
        break;
      case 'EXCLUIR':
        console.log('EXCLUIR');
        props.showModal(
          'Atenção',
          MESSAGES.excluirCondicionante,
          {
            type: 'EXCLUIR_CONDIC_REQUEST',
            value: data,
          },
          'Sim',
          false,
          false,
          'Não'
        );
        // props.excluirCondicionante(data);
        break;
      case 'SEARCH':
        console.log('SEARCH');
        console.log(data);
        props.filterdListCedente(data);
        break;
      case 'CHANGE_TEXT_AREA':
        console.log('CHANGE_TEXT_AREA');
        console.log(data);
        props.updateCondicDefault('texto', data);
        break;
      case 'CHANGE_VALUE':
        console.log('CHANGE_VALUE');
        console.log(data);
        props.updateCondicDefault('valor', data);
        break;
      case 'CHANGE_DATE':
        console.log('CHANGE_DATE');
        console.log(data);
        props.updateCondicDefault('dtValidade', data);
        break;
      case 'CHANGE_OPTION_CEDENTE':
        console.log('CHANGE_OPTION_CEDENTE');
        console.log(data);
        props.updateCondicDefault('idCedente', data);
        break;
      default:
        console.log('DEFAULT');
    }
  };

  useEffect(() => {
    if (props.showModalCondicionante === true) {
      setEdit(false);
      props.listCondicionante(null);
    }
  }, [props.showModalCondicionante]);

  useEffect(() => {
    // console.log(props.errorSend);
    if (!props.errorSend) {
      setEdit(false);
    }
  }, [props.errorSend]);

  useEffect(() => {
    // console.log(props.errorSend);
    if (!props.errorSend) {
      setEdit(false);
    }
  }, [props.isLoadingSend]);

  return (
    <div>
      {props.showModalCondicionante && (
        <Modal title="Condicionante" action={action} edit={edit}>
          {props.isLoading === true && <Loader />}

          {!edit && props.isLoading === false && props.data.length === 0 && (
            <div
              style={{
                height: '100vh',
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <GraphicsInline image="undraw" />
            </div>
          )}

          {edit && <EditCondicionante action={action} />}

          {!props.error ? (
            !edit &&
            props.isLoading === false &&
            props.data.length > 0 &&
            props.data.map((res, key) => {
              return (
                <div key={key}>
                  <div style={{ width: '100%' }}>
                    <ItemCondicionante
                      action={action}
                      data={res}
                      status={res.status.nome}
                    />
                    <SeparatorHorizontal hsize="0.5rem 0" />
                  </div>
                </div>
              );
            })
          ) : (
            <GraphicsInline image="error" />
          )}
        </Modal>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    showModalCondicionante: state.reducerUI.showModalCondicionante,
    data: state.condicionante.data,
    isLoading: state.condicionante.isLoading,
    error: state.condicionante.error,
    errorSend: state.condicionante.errorSend,
    isLoadingSend: state.condicionante.isLoadingSend,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showCondicionante: (data) => dispatch(showModalCondicionante(data)),
    listCondicionante: (idStatus) =>
      dispatch(loadListCondicionanteRequest(idStatus)),
    concluirCondicionante: (idCondicionante) =>
      dispatch(concluirCondicionanteRequest(idCondicionante)),
    filterdListCedente: (data) => dispatch(filterdListCedenteRequest(data)),
    excluirCondicionante: (idCondicionante) =>
      dispatch(excluirCondicionanteRequest(idCondicionante)),
    updateCondicDefault: (field, data) =>
      dispatch(updateCondicDefault(field, data)),
    sendCondic: (data) => dispatch(sendCondicRequest(data)),
    showModal: (
      title,
      content,
      modalActions,
      modalLabelBtn1,
      modalSingleBtn,
      modalTextarea,
      modalLabelBtn2
    ) =>
      dispatch(
        showModal(
          title,
          content,
          modalActions,
          modalLabelBtn1,
          modalSingleBtn,
          modalTextarea,
          modalLabelBtn2
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Condicionante);

/* eslint-disable no-unused-vars */
import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import axios from 'axios';
import CONFIG from 'config';
import toast from 'react-hot-toast';
import { ConvitesContext } from '../ListaConvites/Provider';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const removeConvite = async (params) => {
  const payload = {
    idConexao: params.idConexao,
    idTipoRecusa: params.idTipoRecusa,
    observacao: params.obs,
  };

  const mutateData = axios.put(`${BASE_URL}/matching/conexao/recusar`, payload);

  toast.promise(mutateData, {
    loading: 'Loading',
    success: 'Convite recusado com sucesso',
    error: (error) => {
      if (error.response.status === 400) {
        return `Erro: ${error.response.data.message.toString()}`;
      }
      return `Erro: ${error.toString()}`;
    },
  });

  const result = await mutateData;
  const { data } = result;

  return data || [];
};

export const useMutateConviteRecusa = () => {
  const queryClient = useQueryClient();
  const [state, actions] = useContext(ConvitesContext);

  return useMutation((params) => removeConvite(params), {
    onMutate: async (params) => {
      await queryClient.cancelQueries(['convites']);

      const {
        pagination: { skip },
        pagination: { size },
      } = state;

      const queryKey = ['convites', 'recebidos', { size, skip }];

      const previousData = queryClient.getQueryData([...queryKey]);

      const updatedData = previousData.data.filter(
        (item) => item.idConexao !== params.idConexao
      );

      const newPage = updatedData.length === 0 ? skip - 1 : skip;

      const newData = {
        ...previousData,
        totalPages: newPage,
        totalRecords: previousData.totalRecords - 1,
        data: updatedData,
      };

      const queryCustom = () => {
        queryKey[2] = { size, skip: newPage };
        return queryKey;
      };

      if (updatedData.length === 0) {
        actions.setPage(newPage);
      } else {
        queryClient.setQueryData([...queryCustom()], newData);
      }

      return { previousData, newData, params, queryKey };
    },
    onError: (err, previousData, context, queryKey) => {
      queryClient.setQueryData([...context.queryKey], context.previousData);
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('convites');
    },
  });
};

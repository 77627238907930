import React, { useContext } from 'react';

import { ReactComponent as IconeFolderOpen } from 'assets/images/icon-folder-open.svg';
import { ReactComponent as IconeMessage } from 'assets/images/icon-chat-message.svg';
import { TagStatus } from 'components/FinTag';
import { Wrapper } from 'components/FinWrappers';
import CurrencyFormatter from 'currency-formatter';

import { currency } from 'helpers/format';
import { dotToComma } from 'components/Formatter';

import { Caption } from 'components/FinParagraph';
import {
  FinInput,
  InputCurrencyStyle,
  FieldStyle,
} from 'components/FinInput/MainInput';

import { STATUS, IDS } from '../../config';
import { OperacoesContext, MODALS } from '../../provider';
import {
  CardRowStyle,
  CaptionCustom,
  CaptionCustomLine,
  ButtonRowStyle,
} from '../style';

export const EditaCheque = () => {
  const [state, actions] = useContext(OperacoesContext);
  const {
    propostas: { nova },
  } = state;

  const onHandleFloat = (event) => {
    actions.updateNovaProposta([
      {
        field: 'semNota',
        subField: 'vFloat',
        value: Number(event.target.value),
      },
    ]);
  };

  const onHandleBlurFloat = (event) => {
    if (event.target.value === '') {
      actions.updateNovaProposta([
        { field: 'semNota', subField: 'vFloat', value: 0 },
      ]);
    }
  };

  const onHandleTaxa = (event) => {
    const value = CurrencyFormatter.unformat(event.target.value, {
      code: 'EUR',
    });

    actions.updateNovaProposta([
      { field: 'semNota', subField: 'taxa', value: Number(value) },
    ]);
  };

  const acertosAcess =
    STATUS[IDS[nova.statusProposta.idPropostaStatus]].permissoes.indexOf(
      'verAcertos'
    ) !== -1
      ? ['view']
      : ['edit'];

  const comentarioAcess =
    STATUS[IDS[nova.statusProposta.idPropostaStatus]].permissoes.indexOf(
      'verComentario'
    ) !== -1
      ? ['view']
      : ['edit'];

  return (
    <>
      <CardRowStyle>
        <Caption>Bruto Proposto</Caption>
        <CaptionCustom>{currency(nova.semNota.valorBruto)}</CaptionCustom>
      </CardRowStyle>

      <CardRowStyle>
        <Caption>Tipo</Caption>
        <CaptionCustom>Cheque</CaptionCustom>
      </CardRowStyle>

      <CardRowStyle>
        <Caption>PMP (dias)</Caption>
        <CaptionCustom>{dotToComma(nova.semNota.pmp.toFixed(2))}</CaptionCustom>
      </CardRowStyle>

      <CardRowStyle hideIndicator style={{ padding: '7px 0' }}>
        <CaptionCustomLine>Taxa (%)</CaptionCustomLine>
        <FieldStyle>
          <InputCurrencyStyle
            name="taxa"
            prefix=""
            decimalSeparator=","
            thousandSeparator=""
            onChangeEvent={onHandleTaxa}
            value={dotToComma(nova.semNota.taxa)}
          />
        </FieldStyle>
      </CardRowStyle>

      <CardRowStyle hideIndicator style={{ padding: '7px 0' }}>
        <CaptionCustomLine>Float (dias)</CaptionCustomLine>
        <FinInput
          name="float"
          onChange={onHandleFloat}
          onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
          onBlur={onHandleBlurFloat}
          value={nova.semNota.vFloat}
        />
      </CardRowStyle>

      <CardRowStyle>
        <Wrapper padding="0">
          <ButtonRowStyle
            fillSvg="#008894"
            colorHover="#70e5ef"
            onClick={() =>
              actions.setModalActive({
                active: MODALS.acertos,
                values: { rules: acertosAcess },
              })
            }
          >
            <IconeFolderOpen />
          </ButtonRowStyle>
          <Caption>Acertos</Caption>
        </Wrapper>
        <CaptionCustom>{currency(nova.semNota.acertos)}</CaptionCustom>
      </CardRowStyle>

      <CardRowStyle>
        <Caption>Líquido</Caption>
        <CaptionCustom>{currency(nova.semNota.liquido)}</CaptionCustom>
      </CardRowStyle>
      <CardRowStyle hideIndicator>
        <Wrapper padding="0">
          <ButtonRowStyle
            strokeSvg="#008894"
            colorHover="#70e5ef"
            onClick={() =>
              actions.setModalActive({
                active: MODALS.comentarios,
                values: { rules: comentarioAcess },
              })
            }
          >
            <IconeMessage />
          </ButtonRowStyle>
          <Caption>Comentário</Caption>
        </Wrapper>
        {!!nova.observacao && (
          <TagStatus
            bgcolor="#FF4D35"
            textColor="#FFF"
            altura="24px"
            id="count-comments"
          >
            1
          </TagStatus>
        )}
      </CardRowStyle>
    </>
  );
};

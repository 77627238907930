import styled from 'styled-components/macro';
import { device } from 'devices';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;
`;

export const ContentStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const TitleBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-shadow: inset 0 -1px 0 ${({ theme }) => theme.newtheme.color.cinza2};
  @media ${device.laptop} {
    padding: 0 85px;
  }
  @media ${device.laptopL} {
    padding: 0 100px;
  }
  @media ${device.desktop} {
    padding: 0 300px;
  }
  @media ${device.desktopL} {
    padding: 0 400px;
  }
`;

export const ViewList = styled.div`
  display: flex;
  flex-direction: column;
  height: 89vh;
  overflow-y: auto;
  @media ${device.laptop} {
    padding: 36px 85px;
  }
  @media ${device.laptopL} {
    padding: 54px 100px;
  }
  @media ${device.desktop} {
    padding: 67px 300px;
  }
  @media ${device.desktopL} {
    padding: 67px 400px;
  }
`;

import React, { useContext } from 'react';

import { ReactComponent as IconeFolderOpen } from 'assets/images/icon-folder-open.svg';
import { ReactComponent as IconeMessage } from 'assets/images/icon-chat-message.svg';
import { TagStatus } from 'components/FinTag';
import { Wrapper } from 'components/FinWrappers';

import { Caption } from 'components/FinParagraph';
import { currency } from 'helpers/format';
import { dotToComma } from 'components/Formatter';
import { CardRowStyle, CaptionCustom, ButtonRowStyle } from './style';

import { IDS_INDICATORS, STATUS, IDS } from '../config';
import { OperacoesContext, MODALS } from '../provider';

export const Cheque = ({ data }) => {
  const [, actions] = useContext(OperacoesContext);

  const acertosAcess =
    STATUS[IDS[data.statusProposta.idPropostaStatus]].permissoes.indexOf(
      'verAcertos'
    ) !== -1
      ? ['view']
      : ['edit'];

  const comentarioAcess =
    STATUS[IDS[data.statusProposta.idPropostaStatus]].permissoes.indexOf(
      'verComentario'
    ) !== -1
      ? ['view']
      : ['edit'];

  return (
    <>
      <CardRowStyle>
        <Caption>Bruto Proposto</Caption>
        <CaptionCustom>{currency(data.semNota.valorBruto)}</CaptionCustom>
      </CardRowStyle>

      <CardRowStyle>
        <Caption>Tipo</Caption>
        <CaptionCustom>Cheque</CaptionCustom>
      </CardRowStyle>

      <CardRowStyle
        indicator={IDS_INDICATORS[data.variacaoValores.varianteValorPmp]}
      >
        <Caption>PMP (dias)</Caption>
        <CaptionCustom>{dotToComma(data.semNota.pmp.toFixed(2))}</CaptionCustom>
      </CardRowStyle>

      <CardRowStyle
        indicator={IDS_INDICATORS[data.variacaoValores.varianteValorTaxa]}
      >
        <Caption>Taxa (%)</Caption>
        <CaptionCustom>{dotToComma(data.semNota.taxa)}</CaptionCustom>
      </CardRowStyle>

      <CardRowStyle
        indicator={IDS_INDICATORS[data.variacaoValores.varianteValorFloat]}
      >
        <Caption>Float (dias)</Caption>
        <CaptionCustom>{data.semNota.vFloat}</CaptionCustom>
      </CardRowStyle>

      <CardRowStyle
        indicator={IDS_INDICATORS[data.variacaoValores.varianteValorAcerto]}
      >
        <Wrapper padding="0">
          <ButtonRowStyle
            fillSvg="#008894"
            colorHover="#70e5ef"
            onClick={() =>
              actions.setModalActive({
                active: MODALS.acertos,
                values: { rules: acertosAcess, idProposta: data.idProposta },
              })
            }
          >
            <IconeFolderOpen />
          </ButtonRowStyle>
          <Caption>Acertos</Caption>
        </Wrapper>
        <CaptionCustom>{currency(data.semNota.acertos)}</CaptionCustom>
      </CardRowStyle>
      <CardRowStyle
        indicator={IDS_INDICATORS[data.variacaoValores.varianteValorLiquido]}
      >
        <Caption>Líquido</Caption>
        <CaptionCustom>{currency(data.semNota.liquido)}</CaptionCustom>
      </CardRowStyle>
      <CardRowStyle hideIndicator>
        <Wrapper padding="0">
          <ButtonRowStyle
            strokeSvg="#008894"
            colorHover="#70e5ef"
            onClick={() =>
              actions.setModalActive({
                active: MODALS.comentarios,
                values: { rules: comentarioAcess, idProposta: data.idProposta },
              })
            }
          >
            <IconeMessage />
          </ButtonRowStyle>
          <Caption>Comentário</Caption>
        </Wrapper>
        {!!data.observacao && (
          <TagStatus
            bgcolor="#FF4D35"
            textColor="#FFF"
            altura="24px"
            id="count-comments"
          >
            1
          </TagStatus>
        )}
      </CardRowStyle>
    </>
  );
};

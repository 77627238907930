import { useQuery } from 'react-query';
import axios from 'axios';
import CONFIG from 'config';
import { getUserData } from 'services/getUserData';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const fetchAgendaGrafico = async (params) => {
  const { idEmpresa } = await getUserData();

  const fetchData = axios.post(
    `${BASE_URL}/agenda/grafico/prioridade/${idEmpresa}`,
    { ...params }
  );

  const result = await fetchData;
  const { data } = result;

  return data || [];
};

const initialGraphic = {
  data: {
    graficoValoresTotaisAgenda: {
      vlrTotalAgenda: 0,
      vlrTotalMesAnterior: 0,
      vlrTotalSpread: 0,
    },
    graficoPrioridades: [
      {
        idPrioridade: 0,
        qtdSelecionadaPrioridades: 0,
        qtdTotalPrioridades: 0,
        vlrTotalAgenda: 0,
        percTotalAgenda: 0,
        percMax: 20,
      },
      {
        idPrioridade: 1,
        qtdSelecionadaPrioridades: 0,
        qtdTotalPrioridades: 0,
        vlrTotalAgenda: 0,
        percTotalAgenda: 0,
        percMax: 20,
      },
      {
        idPrioridade: 2,
        qtdSelecionadaPrioridades: 0,
        qtdTotalPrioridades: 0,
        vlrTotalAgenda: 0,
        percTotalAgenda: 0,
        percMax: 10,
      },
      {
        idPrioridade: 3,
        qtdSelecionadaPrioridades: 0,
        qtdTotalPrioridades: 0,
        vlrTotalAgenda: 0,
        percTotalAgenda: 0,
        percMax: 50,
      },
    ],
  },
  isSuccessStatusCode: true,
};

export const useAgendaGrafico = (params) => {
  return useQuery(
    ['agenda', 'grafico', params],
    () => fetchAgendaGrafico(params),
    {
      initialData: initialGraphic,
      keepPreviousData: false,
      enabled: !!Number(params.mesAnoVigencia.mes),
    }
  );
};

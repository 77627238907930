import React from 'react';
import { capitalizeWords } from 'helpers/string';
import { formatCnpjCpf } from 'helpers/format';
import { Title, SubTitle } from './style.socio';
import { Bullet, Flex } from '../../style.layout';

export function SocioItem({ data }) {
  return (
    <Flex style={{ gap: '12px' }} justify="flex-start">
      <Bullet bgColor="rgba(46, 195, 140, 1)" icon={data.tipo} />
      <Flex column style={{ width: 'auto', alignItems: 'flex-start' }}>
        <Title>{capitalizeWords(data.empresa || data.nomeSocio)}</Title>
        <SubTitle>{formatCnpjCpf(data.documento)}</SubTitle>
      </Flex>
    </Flex>
  );
}

import React from 'react';

import { AnimatePresence } from 'framer-motion';
import { Container, Shadow } from './style.wizard';

export const Wrapper = React.forwardRef((props, ref) => {
  const { children, show, position } = props;
  return (
    show && (
      <Shadow>
        <Container ref={ref} style={{ ...position }}>
          <AnimatePresence exitBeforeEnter />
          {children}
        </Container>
      </Shadow>
    )
  );
});

import React, { useContext, useState } from 'react';

import {
  PopoverTriggerFiliais,
  Popover,
  PopoverContent,
} from 'pages/MinhaRede/Comps/Popover';

import { ReactComponent as IconArrow } from 'assets/images/icon-arrow-solid.svg';

import { SeparatorVertical } from 'components/FinSeparador';
import { capitalizeWords } from 'helpers/string';
import { Name, Cnpj, Flex, FinAvatarCuston } from './styles.filiais';
import { Separator, IconLockStyle } from '../style.layout';

import { ItemMenu } from './ItemMenu';
import { PerfilContext } from '../PaginaFinCredit';

export function MenuFiliais() {
  const [showPopover, setShowPopover] = useState(false);
  const { filiais, setEmpreendedor, empreendedor } = useContext(PerfilContext);

  const capitalizedName = capitalizeWords(empreendedor && empreendedor.name);

  return (
    <Popover open={showPopover} onOpenChange={setShowPopover}>
      <PopoverTriggerFiliais>
        <Flex justify="flex-start">
          {empreendedor && (
            <>
              <FinAvatarCuston online name={empreendedor.name} />
              <SeparatorVertical pwidth="1rem" />
              <Name>{capitalizedName}</Name>
              <Separator color="#2e2e2e" />
              {empreendedor.cnpj ? (
                <Cnpj>{empreendedor.cnpj}</Cnpj>
              ) : (
                <IconLockStyle />
              )}
            </>
          )}
        </Flex>
        <IconArrow />
      </PopoverTriggerFiliais>
      <PopoverContent
        align="start"
        style={{ maxHeight: '300px', overflow: 'auto' }}
      >
        <Flex
          justify="flex-start"
          column
          style={{ width: '700px', gap: '10px' }}
        >
          {filiais.map((item) => {
            return (
              <ItemMenu
                key={item.idCedente}
                data={item}
                onClick={() => {
                  setEmpreendedor({
                    idCedente: item.idCedente,
                    idGrupo: item.idGrupo,
                    cnpj: item.cnpj,
                    name: item.name,
                    matriz: item.matriz,
                  });
                  setShowPopover(false);
                }}
              />
            );
          })}
        </Flex>
      </PopoverContent>
    </Popover>
  );
}

/* eslint-disable default-case */
/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import React from 'react';

import { useAuth, hasAuthParams } from 'react-oidc-context';
import { ReactLoadingCuston } from 'components/FinLoader';
import { setItem } from 'helpers/armazenamento';
import { SigninMethods } from 'auth/nwUserManager'

export function PaginaLogin() {
  const auth = useAuth();

  React.useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading
    ) {
      setItem('signin-method', SigninMethods.DIRECT);
      auth.signinRedirect();
    }
  }, [auth]);

  if (auth.isLoading) {
    return (
      <ReactLoadingCuston isLoading={true} positionFixed showLabel={false} />
    );
  }


  switch (auth.activeNavigator) {
    case 'signinSilent':
      return <div>Signing you in...</div>;
    case 'signoutRedirect':
      return <div>Signing you out...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  return <div>Login...</div>;
}

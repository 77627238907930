import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { rgba } from 'polished';
import { finTheme } from 'finTheme';
import { Ring } from '@uiball/loaders';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: ${({ bgColor, bgOpacity }) => rgba(bgColor, bgOpacity)};
  ${({ positionFixed }) =>
    positionFixed &&
    css`
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 800;
    `}
`;

const Label = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.newtheme.color.cinza3};
  font-weight: normal;
  margin: 16px 0;
`;

export const ReactLoadingCuston = (props) => {
  const { isLoading, bgColor, bgOpacity, positionFixed, label, showLabel } =
    props;

  return (
    isLoading && (
      <Wrapper
        bgColor={bgColor}
        positionFixed={positionFixed}
        bgOpacity={bgOpacity}
      >
        <Ring size={38} lineWeight={5} speed={2} color="#378894" />
        {showLabel && <Label>{label}</Label>}
      </Wrapper>
    )
  );
};

ReactLoadingCuston.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  delay: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  bgColor: PropTypes.string,
  bgOpacity: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  positionFixed: PropTypes.bool,
  showLabel: PropTypes.bool,
  label: PropTypes.string,
};

ReactLoadingCuston.defaultProps = {
  type: 'spin',
  color: finTheme.newtheme.color.azulPrimario,
  height: 40,
  width: 40,
  bgColor: finTheme.newtheme.color.branco,
  bgOpacity: 0.8,
  positionFixed: false,
  showLabel: true,
  label: 'Carregando aguarde ...',
};

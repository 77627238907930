const INITIAL_STATE = {
  data: [],
  isLoading: null,
  error: null,
};

const cep = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LOAD_CEP_REQUEST':
      console.log(action);
      return {
        ...state,
        isLoading: true,
      };
    case 'LOAD_CEP_SUCCESS':
      console.log(action);
      return {
        ...state,
        data: action.data,
        error: false,
        isLoading: false,
      };
    case 'LOAD_CEP_FAILURE':
      return {
        ...state,
        data: action.data,
        isLoading: false,
        error: true,
      };
    case 'CLEAR_CEP':
      console.log(action);
      return {
        ...state,
        data: [],
      };
    default:
      return state;
  }
};

export default cep;

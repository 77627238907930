import React, { useContext, useState, useRef } from 'react';

import { FinErrorBoundary } from 'components/FinErrorBoundary';
import { useAgendaGrafico } from './queries/useAgendaGrafico';

import { AgendaContext } from './reducer';
import { AgendaCardsView } from './AgendaCardsView';
import { AgendaFilter } from './AgendaFilter';
import { AgendaTable } from './AgendaTable';
import { useAgendaList } from './queries/useAgendaList';
import { months } from './months';
import { useHabilitacaoEdicao } from './queries/useHabilitacaoEdicao';

import {
  Container,
  Header,
  IconContainer,
  CalendarIcon,
  TitleContainer,
  Title,
  TimeDate,
  TableContainer,
} from './styles';

import { Loader } from './Loader';

export const AgendaPage = () => {
  const [state] = useContext(AgendaContext);
  const refTarget = useRef();

  const [selected, setSelected] = useState([]);
  const [prioritySelect, setPrioritySelect] = useState(4);

  const {
    filter: { mesAnoVigencia },
    skip,
  } = state;

  const habilitacao = useHabilitacaoEdicao();
  const { data, isLoading } = useAgendaList(state);
  const grafico = useAgendaGrafico({ mesAnoVigencia });

  const tableHeaders = [
    {
      idHeader: 1,
      nome: 'EMPREENDEDOR',
    },
    {
      idHeader: 2,
      nome: 'PRIORIDADE',
    },
    {
      idHeader: 3,
      nome: 'TAXA MÉDIA',
    },
    {
      idHeader: 4,
      nome: 'VOP MÊS ANTERIOR',
    },
    {
      idHeader: 5,
      nome: 'AGENDA DO MÊS',
    },
    {
      idHeader: 6,
      nome: 'DESÁGIO',
    },
    {
      idHeader: 7,
      nome: 'ÚLTIMA OP',
    },
  ];
  const priorityVars = {
    0: 'max',
    1: 'higher',
    2: 'high',
    3: 'standard',
  };

  React.useEffect(() => {
    refTarget.current.scrollIntoView();
  }, [skip]);

  return (
    <Container>
      <FinErrorBoundary>
        <div ref={refTarget}></div>
        <div style={{ maxWidth: '1280px', width: '1280px', margin: '0 auto' }}>
          <Header>
            <IconContainer>
              <CalendarIcon />
            </IconContainer>
            <TitleContainer>
              <Title>Agenda de Operações</Title>
              <TimeDate>
                {months[mesAnoVigencia.mes]}/{mesAnoVigencia.ano}
              </TimeDate>
            </TitleContainer>
          </Header>
          <AgendaCardsView cardsViewData={grafico.data.data} />
          <TableContainer>
            <AgendaFilter
              habilitacao={habilitacao.data}
              priorityVars={priorityVars}
              selected={selected}
              setSelected={setSelected}
              prioritySelect={prioritySelect}
              setPrioritySelect={setPrioritySelect}
            />
            {isLoading && <Loader />}
            {!isLoading && (
              <FinErrorBoundary>
                <AgendaTable
                  habilitacao={habilitacao.data}
                  tableHeaders={tableHeaders}
                  tableData={data}
                  priorityVars={priorityVars}
                  selected={selected}
                  setSelected={setSelected}
                />
              </FinErrorBoundary>
            )}
          </TableContainer>
        </div>
      </FinErrorBoundary>
    </Container>
  );
};

import React from 'react';
import { Ring } from '@uiball/loaders';
import { finTheme } from 'finTheme';

export const Loader = () => {
  return (
    <div
      style={{
        widtd: '100%',
        minHeight: '20vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Ring
        size={35}
        lineWeight={5}
        speed={5}
        color={finTheme.newtheme.color.azulPrimario}
      />
    </div>
  );
};

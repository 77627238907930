import React from 'react';

import { Sacado } from './Sacado';
import { Titulos } from './Titulos';
import { ContainerCard } from './style.bordero';

export const CardBordero = ({ idOpen, setIdOpen, data }) => {
  const onHandleClick = (e) => {
    e.preventDefault();
    setIdOpen((state) =>
      state.indexOf(data.idSacado) !== -1 ? [] : [data.idSacado]
    );
  };
  return (
    <ContainerCard>
      <Sacado
        isActive={idOpen.indexOf(data.idSacado) !== -1}
        onClick={onHandleClick}
        data={data}
      />
      {idOpen.indexOf(data.idSacado) !== -1 && <Titulos data={data.titulos} />}
    </ContainerCard>
  );
};

import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    uiSendMessage: ['message'],
    uiRemoveMessage: ['message'],
    uiClearAllMessages: null,
  },
  {
    prefix: 'UI/',
  }
);

export const TypesUiMessages = Types;
export const CreatorsUiMessages = Creators;

const INITIAL_STATE = {
  messages: [],
};

const send = (state = INITIAL_STATE, action) => {
  const msgs = Array.isArray(action.message)
    ? action.message
    : [action.message];
  return {
    messages: [...state.messages, ...msgs],
  };
};

const remove = (state = INITIAL_STATE, action) => {
  const msgs = Array.isArray(action.message)
    ? action.message
    : [action.message];
  return {
    messages: state.messages.filter((message) => msgs.indexOf(message) === -1),
  };
};

const clear = () => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [Types.UI_SEND_MESSAGE]: send,
  [Types.UI_REMOVE_MESSAGE]: remove,
  [Types.UI_CLEAR_ALL_MESSAGES]: clear,
});

import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Breadcrumb, ItemLink } from 'components/FinBreadcrumb';
import Header from './Header';
import NavLinksDetail from './NavLinksDetail';
import Content from './Content';

import { ListaFiliais } from './ListaFiliais';

const PageOffModal = ({
  tabActive,
  setTabActive,
  setInputValue,
  loadedProfile,
  setSelectedValue,
  idEmpreendedor,
  options,
  isLoading,
  ...rest
}) => {
  const match = useRouteMatch();

  return (
    <>
      <Breadcrumb>
        <ItemLink to="/clientes?page=1&qtd=10&order=a-z&aba=todos" icon>
          Clientes
        </ItemLink>
        <ItemLink to={match.url} disabled>
          Detalhe
        </ItemLink>
      </Breadcrumb>
      <Header {...rest} />
      <ListaFiliais
        setInputValue={setInputValue}
        loadedProfile={loadedProfile}
        setSelectedValue={setSelectedValue}
        idEmpreendedor={idEmpreendedor}
        options={options}
        isLoading={isLoading}
      />
      <NavLinksDetail
        isSelected={tabActive}
        changeTab={setTabActive}
        {...rest}
      />
      <Content
        tabActive={tabActive}
        idEmpreendedor={idEmpreendedor}
        {...rest}
      />
    </>
  );
};

export default PageOffModal;

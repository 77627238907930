/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { createActions, createReducer } from 'reduxsauce';
import { call, put, take, fork, cancel, cancelled } from 'redux-saga/effects';
import axios, { CancelToken } from 'axios';

import { getUserData } from 'services/getUserData';
import { Creators as uiToasterActions } from 'store/ducks/uiToaster';

import CONFIG from '../../config';

/**
 * Criando action types & creators
 */

export const { Types, Creators } = createActions({
  apiCNAERequest: [],
  apiCNAESuccess: ['data'],
  apiCNAEError: [],
  apiCNAECancel: [],
});

/**
 * Criando os reducer handlers
 */

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: false,
};

const apiListRequest = (state = INITIAL_STATE, action) => {
  // console.log(action);
  return {
    isLoading: true,
    data: [],
    error: false,
  };
};

const apiListSuccess = (state = INITIAL_STATE, action) => {
  // console.log(action);
  return {
    isLoading: false,
    data: [action.data],
    error: false,
  };
};

const apiListError = (state = INITIAL_STATE, action) => {
  // console.log(action);
  return {
    isLoading: false,
    data: [],
    error: true,
  };
};

const apiListCancel = (state = INITIAL_STATE, action) => {
  // console.log(action);
  return {
    isLoading: false,
    data: [],
    error: false,
  };
};

/**
 * Criando reducer
 */

export default createReducer(INITIAL_STATE, {
  [Types.API_CNAE_REQUEST]: apiListRequest,
  [Types.API_CNAE_SUCCESS]: apiListSuccess,
  [Types.API_CNAE_ERROR]: apiListError,
  [Types.API_CNAE_CANCEL]: apiListCancel,
});

/**
 * Sagas
 */

function changeData(data) {
  if (data.length > 0) {
    const values = data.map((res) => ({
      id: res.id,
      name: res.nome,
    }));
    values.unshift({ id: 0, name: '' });
    return values;
  }
  return [];
}

function* sagaGetAsynResult(params, endpoint) {
  const source = CancelToken.source();

  const url = `${CONFIG.API_BASE_URL}${endpoint}`;

  try {
    const response = yield axios.get(url, {
      // cancelToken: source.token,
    });

    // console.log('ANDREW DADOS');
    // console.log(JSON.stringify(response.data));

    yield put(Creators.apiCNAESuccess(response.data));
  } catch (error) {
    console.log('error');
    console.log(error);
    yield put(uiToasterActions.uiToasterMessage(error, 'error'));
    yield put(Creators.apiCNAEError());
  } finally {
    if (yield cancelled()) {
      console.log('cancel');
      source.cancel();
    }
  }
}

export function* sagaCNAE(action) {
  const user = yield call(getUserData);

  // if (user) {
  const params = {
    // idcedente: action.idCedente,
    idusuario: user.idUsuario,
    //   idConvenio: user.idEmpresa,
  };
  // const params2 = `?idconvenio=${user.idEmpresa}&idcedente=${action.idCedente}&idusuario=${user.idUsuario}`;
  if (params) {
    const task = yield fork(
      sagaGetAsynResult,
      params,
      `/api/finmatch/obtem-listagem-finmatch`
    );

    yield take(Types.API_CNAE_CANCEL);
    yield cancel(task);
  } else {
    yield put(Creators.apiCNAEError());
  }
  // }
}

import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components/macro';
import { rgba } from 'polished';
import { NavLink } from 'react-router-dom';

import { Wrapper } from 'components/FinWrappers';
import ReactTooltip from 'react-tooltip';
import BlurryText from 'components/FinBlurryText';
import { Ellipsis } from 'helpers/string';
import UserInfo from 'components/FinUserInfo';
import { finTheme } from 'finTheme';
import { SubTitle1 } from 'components/FinSubTitles';
import { MenuBloquear } from 'components/FinMenu';
import MoreButton from 'components/FinDotButton';
import { maskCNPJ } from 'utils/formatValues';

import { Creators as uiDialogActions } from 'store/ducks/uiDialog';

import { useActions } from 'hooks/useActions';
import Buttons from './Buttons';

import { useMutateBloqueio } from '../mutates/useMutateBloqueio';

const Box = styled(Wrapper)`
  width: 100%;
  border-radius: ${({ theme }) => theme.newtheme.borderRadius};
  border: ${({ theme }) =>
    `3px solid ${rgba(theme.newtheme.color.cinza3, 0.2)}`};
  transition: color 300ms ease, background-color 300ms ease,
    border-color 300ms ease, box-shadow 300ms ease;

  justify-content: space-between;
  margin-bottom: 16px;

  &:hover {
    box-shadow: ${({ theme }) => theme.newtheme.dropShadow.dropShadow4};
  }
`;

export const ItemLink = styled(NavLink)`
  padding: 0;
  white-space: nowrap;
  background-color: initial;
  border: 0;
  transition: border-bottom-color 0.36s ease-in;
  text-decoration: none;
  width: 100%;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
      cursor: auto;
    `}
`;

const WrapperBtn = styled(Wrapper)`
  padding: 0;
  min-width: 10px;
`;

const CardList = ({ tipo, data }) => {
  const [dropdown, setDropdown] = useState(false);
  const uiDialog = useActions(uiDialogActions);

  const bloquear = useMutateBloqueio();

  const uiDialogOpen = useCallback(() => {
    uiDialog.uiDialogOpen('bloqueio', () =>
      bloquear.mutate({
        idCedente: data.idGrupo,
        tipo,
      })
    );
  }, [uiDialog, data, bloquear, tipo]);

  const closeDropdown = () => {
    setDropdown(false);
  };

  const changeClick = (e) => {
    e.preventDefault();
    setDropdown(!dropdown);
  };

  const checkStatus = useCallback(() => {
    if (data.localChangedMessage) {
      if (data.localChangedMessage === 'aceitando') {
        if (data.localChangedReady) {
          return 'aceito';
        }
        return 'aguardando';
      }

      if (data.localChangedMessage === 'recusando') {
        if (!data.localChangedReady) {
          return 'aguardando';
        }
      }

      if (data.localChangedMessage === 'bloqueando') {
        if (!data.localChangedReady) {
          return 'aguardando';
        }
      }

      if (data.localChangedMessage === 'removendo') {
        if (!data.localChangedReady) {
          return 'aguardando';
        }
      }
    }
    return tipo;
  }, [data, tipo]);

  return (
    <ItemLink
      exact
      to={{
        pathname: `/clientes/${data.idGrupo}/detalhe`,
      }}
    >
      <Box>
        <Wrapper padding="0">
          <UserInfo
            name={data.nome}
            image={null}
            pbgColor={rgba(finTheme.newtheme.color.cinza2, 0.5)}
            colorText={finTheme.newtheme.color.azulPrimario}
            size="medium"
            viewUserOnline
            online={data.online}
            infoDisabled
          />
          <Wrapper
            tipo="column"
            padding="0"
            alignItems="flex-start"
            pmargin="0 2rem"
          >
            <Wrapper padding="0" alignItems="flex-start">
              <ReactTooltip
                id={`info-name-financiador-convite-${data.idConvite}`}
                wrapper="span"
                place="right"
                type="dark"
                effect="solid"
              >
                {data.nome}
              </ReactTooltip>

              <SubTitle1
                data-tip
                data-for={`info-name-financiador-convite-${data.idConvite}`}
              >
                <Ellipsis pwidth="200px">
                  <BlurryText blurry={false}>{data.nome}</BlurryText>
                </Ellipsis>
              </SubTitle1>
              <SubTitle1
                style={{
                  color: finTheme.newtheme.color.cinza4,
                  marginLeft: '1rem',
                }}
              >
                <BlurryText blurry={data.criptografia || false}>
                  {data.criptografia ? data.cnpj : maskCNPJ(data.cnpj)}
                </BlurryText>
              </SubTitle1>
            </Wrapper>
          </Wrapper>
        </Wrapper>

        <Wrapper padding="0">
          <Buttons tipo={checkStatus()} idConvite={data.idConvite} />
          <WrapperBtn>
            {checkStatus() !== 'aceito' && (
              <MoreButton tipo="vertical" onclick={changeClick}>
                <MenuBloquear
                  open={dropdown}
                  close={closeDropdown}
                  onclick={uiDialogOpen}
                />
              </MoreButton>
            )}
          </WrapperBtn>
        </Wrapper>
      </Box>
    </ItemLink>
  );
};

export default CardList;

import React from 'react';
import { Route, Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import ReactTooltip from 'react-tooltip';

import { Wrapper } from 'components/FinWrappers';
import { StringToSvg } from 'helpers/svgFiles/StringToSvg';
import { finTheme } from 'finTheme';

const Cell = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  transition: all 0.2s;
  color: ${({ theme }) => theme.newtheme.color.cinza6};
  font-weight: 500;
  font-size: 0.875rem;
  background-color: ${({ ativo, theme }) =>
    ativo ? theme.newtheme.color.cinza1 : 'transparent'};

  cursor: pointer;
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }

  ${({ ativo }) =>
    !ativo
      ? css`
          &:hover {
            background-color: ${({ theme }) => theme.newtheme.color.cinza1};
          }
        `
      : css`
          &:hover {
            background-color: ${({ theme }) => theme.newtheme.color.cinza2};
          }
        `}
`;

const LinkCuston = ({
  label,
  to,
  icon,
  exact,
  onClick,
  tooltipText,
  isDisabledTooltip,
  id,
  viewBox,
  strokeWidth,
}) => {
  return (
    <Route
      path={typeof to === 'string' ? to : to.pathname}
      exact={exact}
      children={({ match, location }) => {
        let matchMain = match;

        if (!match) {
          const path = typeof to === 'string' ? to : to.pathname;
          const firstPathLink = path.split('/')[1];
          const mainPathLocation = location.pathname.split('/')[1];
          if (firstPathLink === mainPathLocation) {
            matchMain = true;
          } else {
            matchMain = false;
          }
        }

        return (
          <>
            {tooltipText && !isDisabledTooltip && (
              <ReactTooltip
                id={`info-${id}`}
                wrapper="span"
                place="right"
                type="dark"
                effect="solid"
              >
                {tooltipText}
              </ReactTooltip>
            )}
            <Cell
              to={to}
              ativo={matchMain ? 1 : 0}
              onClick={onClick}
              data-tip
              data-for={`info-${id}`}
            >
              <Wrapper
                padding="0"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Wrapper pwidth="70px" pheight="60px">
                  <StringToSvg
                    viewBox={viewBox || '0 0 34 34'}
                    width="100%"
                    height="100%"
                    strokeWidth={strokeWidth || '1'}
                    stroke={
                      matchMain
                        ? finTheme.newtheme.color.azulPrimario
                        : finTheme.newtheme.color.cinza5
                    }
                  >
                    {icon}
                  </StringToSvg>
                </Wrapper>
                <Wrapper
                  pwidth="215px"
                  pheight="60px"
                  justifyContent="flex-start"
                >
                  {label}
                </Wrapper>
              </Wrapper>
            </Cell>
          </>
        );
      }}
    />
  );
};

export default LinkCuston;

import React from 'react';

import { ReactComponent as IconChevronDown } from 'assets/images/CaretDoubleDown.svg';
import { ReactComponent as IconChock } from 'assets/images/ClockCounterClockwise.svg';

import { Button } from './style.buttonstretch';

export function ButtonStretch({ isOpen, action }) {
  return (
    <Button isOpen={isOpen} onClick={action}>
      <IconChevronDown strokeWidth={2} />
    </Button>
  );
}

export function ButtonModal(props) {
  return (
    <Button {...props}>
      <IconChock strokeWidth={2} />
    </Button>
  );
}

export const checkStatus = (data) => {
  const { statusHabilitado, bloqueado, statusConexao } = data;

  /**
   *
       AnaliseAndamento/Pendente = 1
       Recusado = 2
       Habilitado = 3
   */

  const status = [
    {
      name: 'pendente',
      active: statusHabilitado === 1,
      tipo: 'alert',
    },
    {
      name: 'recusado',
      active: statusHabilitado === 2,
      tipo: 'error',
    },
    {
      name: 'habilitado',
      active: statusHabilitado === 3,
      tipo: 'success',
    },
    {
      name: 'notset',
      active: statusHabilitado === undefined,
      tipo: 'financier',
    },
    {
      name: 'notset',
      active: statusHabilitado === undefined && statusConexao === undefined,
      tipo: 'financier',
    },
    { name: 'bloqueado', active: bloqueado, tipo: 'secondary' },
  ];

  const value = status.filter((res) => res.active === true)[0];

  return value;
};

export const checkStatusConexao = (data) => {
  const { statusConexao, bloqueado, tipoConvite } = data;

  /**
   *  Pendente = 1
      Aceita = 2
      Recusada = 3
      Cancelada = 4
   */

  let status = '';

  switch (statusConexao) {
    case 1:
      status = 'convite-pendente';
      break;
    case 2:
      status = 'conectado';
      break;
    case 3:
      status = 'não-conectado';
      break;
    case 4:
      status = 'não-conectado';
      break;
    default:
      status = 'não-conectado';
  }

  if (statusConexao === 1) {
    if (tipoConvite)
      status = tipoConvite === 1 ? 'convite-enviado' : 'convite-pendente';
  }

  if (bloqueado) {
    status = 'bloqueado';
  }

  if (data.localChangedMessage) {
    if (data.localChangedMessage === 'enviando-convite') {
      if (data.localChangedReady) {
        status = 'convite-enviado';
      } else {
        status = 'aguardando';
      }
    }

    if (data.localChangedMessage === 'bloqueando') {
      if (data.localChangedReady) {
        status = 'bloqueado';
      } else {
        status = 'aguardando';
      }
    }

    if (data.localChangedMessage === 'desbloqueando') {
      if (!data.localChangedReady) {
        status = 'aguardando';
      }
    }

    if (data.localChangedMessage === 'cancelando') {
      if (!data.localChangedReady) {
        status = 'aguardando';
      } else {
        status = 'não-conectado';
      }
    }

    if (data.localChangedMessage === 'aceitando') {
      if (!data.localChangedReady) {
        status = 'aguardando';
      }
    }

    if (data.localChangedMessage === 'recusando') {
      if (!data.localChangedReady) {
        status = 'aguardando';
      }
    }
  }

  return status;
};

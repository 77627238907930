import React from 'react';
import PropTypes from 'prop-types';

import {
  Focus,
  Label,
  Select,
  StandardSelect,
  FieldStyle,
} from './style.select';

export const FinSelectBox = (props) => {
  const {
    id,
    label,
    largura,
    onChange,
    value,
    defaultValue,
    className,
    children,
    tabIndex,
  } = props;
  return (
    <FieldStyle largura={largura} className={className}>
      {label && <Label for={id}>{label}</Label>}
      <Select>
        <StandardSelect
          onChange={onChange}
          value={value}
          defaultValue={defaultValue}
          id={id}
          tabIndex={tabIndex}
        >
          {children}
        </StandardSelect>
        <Focus></Focus>
      </Select>
    </FieldStyle>
  );
};

FinSelectBox.propTypes = {
  children: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  largura: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
};

FinSelectBox.defaultProps = {
  disabled: false,
  onChange: null,
};

import React from 'react';
import styled from 'styled-components/macro';

import { StringToSvg } from 'helpers/svgFiles/StringToSvg';
import feather from 'feather-icons/dist/icons.json';
import { finTheme } from 'finTheme';

import { Wrapper } from 'components/FinWrappers';

const Button = styled(Wrapper)`
  position: relative;
  cursor: pointer;
  margin-left: 10px;

  &:hover > svg {
    stroke: ${({ theme }) => theme.newtheme.color.cinza6};
  }
`;

const MoreButton = ({ tipo, onclick, children }) => {
  return (
    <Button padding="0" pwidth="23px" pheight="23px" onClick={onclick}>
      <StringToSvg
        width="100%"
        height="100%"
        stroke={finTheme.newtheme.color.cinza4}
      >
        {feather[`more-${tipo}`]}
      </StringToSvg>
      {children}
    </Button>
  );
};

export default MoreButton;

import React from 'react';
import iconMath from 'assets/images/icon-match-green.svg';
import { ButtonPdf } from './ButtonPdf';

export function ButtonMatch({ onClick }) {
  return (
    <ButtonPdf
      label="match"
      icon={iconMath}
      iconColor="rgba(46, 195, 140, 1)"
      bgColor="#CDF9D8"
      borderColor="#80D094"
      onClick={onClick}
    />
  );
}

import React from 'react';

import CONFIG from 'config';

const URL = `${CONFIG.API_FINIMAGE}/api/imagem`;

export function useGetImage({ url, fallback, ...props }) {
  return React.useMemo(() => {
    let path = url ? `${URL}?caminho=${url}` : fallback;

    if (!url) {
      return path;
    }

    Object.keys(props).forEach((item) => {
      path = `${path}&${item}=${props[item]}`;
    });

    return path;
  }, [url, fallback, props]);
}

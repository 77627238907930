import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
  width: 157px;
  min-height: 159px;
  padding: 1rem;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--fingray-100);
  border-radius: 15px;
  border: 1px solid #eaeaea;

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: #ffffff;
      box-shadow: 0px 3px 7px -1px rgba(0, 0, 0, 0.15);
    `}
`;

export const ContainerIcon = styled.div`
  width: 77px;
  height: 77px;
  padding: 22px 0;
  margin-bottom: 10px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    stroke: var(--fingray-400);
    & path {
      stroke: var(--fingray-400);
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: var(--finblue-tertiary);
      & > svg {
        stroke: var(--finblue-primary);
        & path {
          stroke: var(--finblue-primary);
        }
      }
    `}
`;

export const CardLabel = styled.span`
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: var(--fingray-900);
  font-family: 'Inter';
`;

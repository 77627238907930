import styled, { css } from 'styled-components/macro';
import { ReactComponent as IconLock } from 'assets/images/icon-lockey.svg';

import iconUserSquare from 'assets/images/UserSquareFill.svg';
import iconUserSuitcase from 'assets/images/SuitcaseSimple.svg';
import { device } from 'devices';

export const IconLockStyle = styled(IconLock)`
  fill: ${({ color }) => color || `var(--fingrey900)`};
  & path {
    fill: ${({ color }) => color || `var(--fingrey900)`};
  }
`;

const truncate = css`
  width: ${({ limite }) => limite || `20ch`};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Container = styled.div`
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Wrapper = styled.div`
  position: relative;
  margin: 0px auto;
  max-width: 1167px;
  height: 100%;
  width: 100%;
`;

export const RegionFullImageBanner = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 320px;

  @media ${device.laptop} {
    height: 18vw;
  }

  @media ${device.desktop} {
    height: 320px;
  }
`;

export const WrapperImageBanner = styled.div`
  display: flex;
  transition: transform 0.5s ease 0s;
  min-width: 100%;
  height: 100%;
  background-color: #cccccc;
`;

export const ImageBanner = styled.img`
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  border-style: none;
`;

export const WrapperShadow = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 99;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  /* box-shadow: inset 0px -108px 60px rgba(0, 0, 0, 0.45); */
  background: linear-gradient(
    180deg,
    rgba(28, 29, 31, 0) 0%,
    rgba(28, 29, 31, 0.9) 100%
  );
`;

export const RegionInfoUser = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

export const AreaContent = styled.div`
  display: flex;
  margin: 0px auto;
  width: 1167px;
`;

export const CompanyName = styled.span`
  color: #ffffff;
  font-size: 2rem;
  font-weight: 700;
  text-rendering: geometricprecision;
  white-space: nowrap;
  line-height: 49px;
  /* user-select: none; */

  text-shadow: 2px 2px rgba(0, 0, 0, 0.25);

  &::selection {
    background: var(--finblue-primary);
  }

  ${truncate}
`;

export const Cnpj = styled.span`
  color: #ffffff;
  font-size: 1rem;
  font-weight: 400;
  text-rendering: geometricprecision;
  white-space: nowrap;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.25);
  &::selection {
    background: var(--finblue-primary);
  }
`;

export const Site = styled.span`
  color: #ffffff;
  font-size: 1rem;
  font-weight: 400;
  text-rendering: geometricprecision;
  white-space: nowrap;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.25);
  &::selection {
    background: var(--finblue-primary);
  }
`;

export const Separator = styled.span`
  display: inline-block;
  width: 5px;
  height: 5px;
  background: ${({ color }) => color || '#ffffff'};
  border-radius: 50%;
  margin: 0 10px;

  &::selection {
    background: var(--finblue-primary);
  }
`;

export const Flex = styled.div`
  display: flex;
  ${({ row }) =>
    row &&
    css`
      flex-direction: row;
    `}

  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
    `}

  justify-content: ${({ justify }) => justify || `center`};
  align-items: ${({ align }) => align || `center`};
  width: 100%;
`;

export const RegionContent = styled.div`
  flex-grow: 0;
  max-width: 70%;
  flex-basis: 70%;
  padding: 0 24px 24px 0;
`;

export const RegionCard = styled.div`
  flex-grow: 0;
  max-width: 30%;
  flex-basis: 30%;
  position: relative;
`;

export const WrapperContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const TitleSocio = styled.div`
  font-family: 'Inter';
  color: var(--fingray-900);
  font-size: 1.438rem;
  font-weight: 700;
  text-rendering: geometricprecision;
  white-space: nowrap;
  width: 24ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CpfCnpjSocio = styled.div`
  font-family: 'Inter';
  color: var(--fingray-900);
  font-size: 1rem;
  font-weight: 400;
  text-rendering: geometricprecision;
  white-space: nowrap;
`;

export const Bullet = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 16px;
  ${({ icon, bgColor }) => {
    if (icon === 'pj') {
      return `background:url(${iconUserSuitcase}) no-repeat center center;
              background-size: 24px 24px;
              background-color: ${bgColor};
      `;
    }

    return `background:url(${iconUserSquare}) no-repeat center center;
            background-size: 24px 24px;
            background-color: ${bgColor};
    `;
  }}
`;

export const Tag = styled.span`
  background-color: var(--fingray-900);
  border-radius: 32px;
  padding: 2px 8px;
  font-family: 'Inter';
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 600;
  text-rendering: geometricprecision;
  white-space: nowrap;
`;

export const SubTitle = styled.div`
  font-family: 'Inter';
  color: var(--fingray-900);
  font-size: 1rem;
  font-weight: 400;
  text-rendering: geometricprecision;
  white-space: nowrap;
`;

import React, { useContext } from 'react';
import { Title6 } from 'components/FinHeadings';
import { Overline } from 'components/FinParagraph';
import { PropostaButtons } from './Status/PropostaButtons';
import { EditaAntecipacao } from './Forms/EditaAntecipacao';
import { EditaEmprestimo } from './Forms/EditaEmprestimo';
import { EditaCheque } from './Forms/EditaCheque';
import { EditaCobrancaSimples } from './Forms/EditaCobrancaSimples';

import {
  CardStyle,
  CardStyleHead,
  CardStyleBody,
  CardStyleFooter,
} from './style';

import { Podium } from './Podium';
import { Status } from './Status';
import { IDS, TIPO_PREGAO } from '../config';
import { OperacoesContext } from '../provider';

export const EditaProposta = () => {
  const [state] = useContext(OperacoesContext);

  const {
    propostas: { nova },
  } = state;

  const styleCustom = {
    border: `1px solid #008894`,
    backgroundColor: `#f8feff`,
  };

  return (
    <CardStyle style={styleCustom}>
      <CardStyleHead>
        <>
          <Title6
            style={{ margin: '0.4rem 0 0 0', fontWeight: 'bold' }}
          >{`${nova.titulo}`}</Title6>
          <Overline>{`Total: ${
            nova.podium.quantidadePropostas || 0
          } Propostas`}</Overline>
          <Podium position={nova.podium.posicao} />
        </>
      </CardStyleHead>
      <CardStyleBody>
        {TIPO_PREGAO.antecipacao.indexOf(nova.tipoPregao) !== -1 && (
          <EditaAntecipacao />
        )}
        {TIPO_PREGAO.cobrancaSimples.indexOf(nova.tipoPregao) !== -1 && (
          <EditaCobrancaSimples />
        )}
        {TIPO_PREGAO.emprestimo.indexOf(nova.tipoPregao) !== -1 && (
          <EditaEmprestimo />
        )}
        {TIPO_PREGAO.cheque.indexOf(nova.tipoPregao) !== -1 && <EditaCheque />}
      </CardStyleBody>
      <CardStyleFooter>
        <PropostaButtons
          tipo={IDS[nova.statusProposta.idPropostaStatus]}
          data={nova}
        />
        <Status tipo={IDS[nova.statusProposta.idPropostaStatus]} />
      </CardStyleFooter>
    </CardStyle>
  );
};

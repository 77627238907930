import React from 'react';

import {
  PaginationButton,
  Arrow,
  PaginationLinkNext,
} from './style.pagination';

export const LastButton = ({ onclick, last, visibility, disabled }) => (
  <PaginationButton visibility={visibility}>
    <PaginationLinkNext
      style={{ width: '40px' }}
      disabled={disabled}
      onClick={(e) => onclick(e, last)}
    >
      <Arrow />
      <Arrow />
    </PaginationLinkNext>
  </PaginationButton>
);

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { finTheme } from 'finTheme';
import { SeparatorVertical } from 'components/FinSeparador';

import { Creators as createMeetingActions } from 'store/ducks/apiCreateMeeting';
import { Creators as uiDialogActions } from 'store/ducks/uiDialog';
import { Creators as cancelarActions } from 'store/ducks/apiCancelar';

import { Types as TypesConvidar } from 'store/ducks/apiConvidar';
import { Types as TypesApiDesbloquear } from 'store/ducks/apiDesbloquear';
import { Types as TypesAceitar } from 'store/ducks/apiAceitar';
import { Types as TypesRecusar } from 'store/ducks/apiRecusar';

import { useActions } from 'hooks/useActions';

import { TagInviteSent, TagLoadingInfo } from 'components/FinTag';
import {
  ButtonChat,
  ButtonConect,
  ButtonEnable,
  ButtonRemove,
  ButtonUnlock,
  ButtonRefuse,
  ButtonAccept,
} from './Buttons';

const ButtonChange = ({ tipo, online, id, idConvite, statusHabilitado }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const actions = useActions([createMeetingActions, uiDialogActions]);
  const dispatch = useDispatch();

  const removeInvite = useCallback(() => {
    dispatch(cancelarActions.apiCancelarRequest(idConvite, id));
  }, [dispatch, idConvite, id]);

  const enable = useCallback((e) => {
    e.preventDefault();
    setOpenMenu((state) => !state);
  }, []);

  const closeMenuEnable = useCallback(() => {
    setOpenMenu(false);
  }, []);

  const meeting = useCallback(
    (e, idCedente) => {
      e.preventDefault();
      actions[0].apiCreateMeetingRequest(idCedente);
    },
    [actions]
  );

  const openDialog = useCallback(
    (e, tipo, dispatch) => {
      e.preventDefault();
      actions[1].uiDialogOpen(tipo, dispatch);
    },
    [actions]
  );

  switch (tipo) {
    case 'não-conectado':
      return (
        <ButtonConect
          onConect={(e) =>
            openDialog(e, 'conectar', {
              type: TypesConvidar.API_CONVIDAR_REQUEST,
              idCedente: id,
            })
          }
        />
      );
    case 'conectado':
      return (
        <>
          <ButtonEnable
            open={openMenu}
            close={() => closeMenuEnable()}
            onEnable={(e) => enable(e)}
            id={id}
            statusHabilitado={statusHabilitado}
          />
          <SeparatorVertical pwidth="0.5rem" />
          <ButtonChat online={online} onChat={(e) => meeting(e, id)} />
        </>
      );
    case 'bloqueado':
      return (
        <ButtonUnlock
          onUnlock={(e) =>
            openDialog(e, 'desbloquear', {
              type: TypesApiDesbloquear.API_DESBLOQUEAR_REQUEST,
              idCedente: id,
            })
          }
        />
      );
    case 'aguardando':
      return (
        <TagLoadingInfo bgcolor={finTheme.newtheme.color.success}>
          Aguarde...
        </TagLoadingInfo>
      );
    case 'convite-enviado':
      return (
        <>
          <TagInviteSent />
          &nbsp;&nbsp;
          {idConvite && (
            <ButtonRemove
              onRemove={(e) => {
                e.preventDefault();
                removeInvite();
              }}
            />
          )}
        </>
      );
    case 'convite-pendente':
      return (
        <>
          <ButtonAccept
            onAccept={(e) =>
              openDialog(e, 'aceitarConvite', {
                type: TypesAceitar.API_ACEITAR_REQUEST,
                idConvite,
                idCedente: id,
              })
            }
          />
          <SeparatorVertical pwidth="0.5rem" />
          <ButtonRefuse
            onRefuse={(e) =>
              openDialog(e, 'recusarConvite', {
                type: TypesRecusar.API_RECUSAR_REQUEST,
                idConvite,
                idCedente: id,
              })
            }
          />
        </>
      );
    default:
      return null;
  }
};

ButtonChange.propTypes = {
  tipo: PropTypes.string.isRequired,
};

export default ButtonChange;

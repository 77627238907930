import React from 'react';

import { AgendaInput } from './tableStyles';

export function InputAgendaValue({
  value,
  setUpdateAgendaValue,
  data,
  tabId,
  disabled,
}) {
  const [agenda, setAgenda] = React.useState(value);

  const callUpdate = () => {
    setUpdateAgendaValue(agenda, data.idItemAgenda);
  };

  const modifyEnterKeyPressAsTab = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      const { name } = e.target;
      const [, fieldIndex] = name.split('-');

      const fieldIntIndex = Number(fieldIndex);

      const nextfield = document.querySelector(
        `input[name=agenda-${fieldIntIndex + 1}]`
      );

      if (nextfield !== null) {
        nextfield.focus();
      }
    }
  };

  React.useEffect(() => {
    setAgenda(value);
  }, [value]);

  return (
    <AgendaInput
      onKeyDown={modifyEnterKeyPressAsTab}
      onBlur={() => callUpdate()}
      value={agenda}
      onChangeEvent={(event, maskedvalue, floatvalue) => setAgenda(floatvalue)}
      largura={'144px'}
      aligntext="center"
      tabIndex={tabId}
      name={`agenda-${tabId}`}
      disabled={disabled}
    />
  );
}

import styled from 'styled-components/macro';

export const SubTitle1 = styled.span`
  color: ${({ theme, color }) => color || theme.newtheme.color.cinza6};
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  letter-spacing: ${({ theme }) => theme.newtheme.font.subtitulo1.espacamento};
  font-weight: ${({ theme, pweight }) =>
    pweight || theme.newtheme.font.subtitulo1.peso};
  font-size: ${({ theme }) => theme.newtheme.font.subtitulo1.tamanho};
`;

export const SubTitle2 = styled.span`
  color: ${({ theme, color }) => color || theme.newtheme.color.cinza6};
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  letter-spacing: ${({ theme }) => theme.newtheme.font.subtitulo2.espacamento};
  font-weight: ${({ theme }) => theme.newtheme.font.subtitulo2.peso};
  font-size: ${({ theme }) => theme.newtheme.font.subtitulo2.tamanho};
`;

const CONFIG =
  window && window.config
    ? window.config
    : {
        API_BASE_URL: '',
        API_REMODELING_URL: '',
        SIGNALR_BASE_URL: '',
        IDENTITY_BASE_URL: '',
        SERVICOS_API: 'https://service-cnab-api-dev.azurewebsites.net',
        IS_CLIENT_ID: '',
        IS_CLIENT_ROOT: '',
        IS_CLIENT_SCOPE: '',
        POST_LOGOUT_REDIRECT_URI: '',
        CBBOX_EMPREENDEDORES: '',
        CBBOX_FINANCIADORES: '',
        API_BASE_CBBOX: '',
        API_FINIMAGE: '',
      };

export default { ...CONFIG };

import React, { useCallback } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { rgba } from 'polished';
import { Title5, Title6 } from 'components/FinHeadings';
import { Caption, Paragraph1 } from 'components/FinParagraph';

import UserInfo from 'components/FinUserInfo';

import { useDispatch } from 'react-redux';

import { ReactComponent as IconeClock } from 'assets/images/icon-clock-red.svg';
import { ReactComponent as IcoTrash } from 'assets/images/icon-trash.svg';
import { ReactComponent as IcoResume } from 'assets/images/icon-resume.svg';
import { ReactComponent as IcoUser } from 'assets/images/icon-user.svg';

import { currency, dateTime, dotToCommaDecimals } from 'components/Formatter';
import { Creators as uiDialogActions } from 'store/ducks/uiDialog';
import Timer from 'components/Timer';

import { truncate, capitalizeWords } from 'helpers/string';

import {
  SeparatorHorizontal,
  SeparatorVertical,
} from 'components/FinSeparador';
import { finTheme } from 'finTheme';
import { Wrapper } from 'components/FinWrappers';
import { TagStatus, TagScrollText } from 'components/FinTag';
import { InfoStatus } from './InfoStatus';
import { useMutateDesistirOperacao } from '../../mutates/useMutateDesistirOperacao';
import { useMutateBorderoDownload } from '../../mutates/useMutateBorderoDownload';

import { CardStyle, BorderLeft } from './style';

import { ButtonRounded } from './ButtonRounded';
import { Steps } from './Steps';

export const Card = ({ data }) => {
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const desistir = useMutateDesistirOperacao();
  const download = useMutateBorderoDownload();

  const openPropostas = (idOperacao) => {
    history.push({
      pathname: `/propostas/${idOperacao}`,
      state: { background: location },
    });
  };

  const openPerfil = () =>
    history.push({
      pathname: `/clientes/${data.idGrupo}/detalhe`,
      state: { background: location },
    });

  const dialogOpen = useCallback(
    (tipo, action, values) => {
      dispatch(uiDialogActions.uiDialogOpen(tipo, action, null, values));
    },
    [dispatch]
  );

  const isHidden = [
    'finalizado',
    'cancelado',
    'desistencia',
    'perdido',
    'pagamento',
  ];

  return (
    <CardStyle>
      <BorderLeft />

      <Wrapper padding="0" pposition="relative" pwidth="54px">
        <UserInfo
          name={data.cedente}
          image={null}
          pbgColor={rgba(finTheme.newtheme.color.cinza2, 0.5)}
          colorText={finTheme.newtheme.color.azulPrimario}
          size="medium"
          infoDisabled
        />
      </Wrapper>

      <Wrapper
        tipo="column"
        padding="0"
        alignItems="flex-start"
        pmargin="0 1rem 0"
      >
        <Wrapper padding="0 0 0.5rem 0">
          <ButtonRounded
            hoverColor="#008894"
            id="button-user-perfil"
            tooltipText="Informações básicas"
            onClick={() => openPerfil()}
            size="tiny"
            bgColor="#e8e9ea"
          >
            <IcoUser />
          </ButtonRounded>
          <SeparatorVertical pwidth="0.4rem" />
          <Title6 style={{ fontWeight: 'bold', margin: '0' }}>
            {capitalizeWords(truncate(data.cedente, 27))}
          </Title6>
        </Wrapper>
        <Wrapper padding="0" pwidth="364px" justifyContent="flex-start">
          <Wrapper padding="0" tipo="column" alignItems="flex-start">
            <Caption style={{ lineHeight: '13px' }}>Oferta</Caption>
            <Caption>{data.idPregao}</Caption>
          </Wrapper>
          <Wrapper padding="0 1rem" tipo="column" alignItems="flex-start">
            <Caption style={{ lineHeight: '13px' }}>Operação</Caption>
            <Caption>{data.idOperacao}</Caption>
          </Wrapper>
          <Wrapper padding="0 1rem" tipo="column" alignItems="flex-start">
            <Caption style={{ lineHeight: '13px' }}>Data</Caption>
            <Caption>{dateTime(data.dtInicio)}</Caption>
          </Wrapper>
          <Wrapper padding="0 1rem" tipo="column" alignItems="flex-start">
            <Caption style={{ lineHeight: '13px' }}>PMP</Caption>
            <Caption>{`${dotToCommaDecimals(data.pmp, 2)} Dias`}</Caption>
          </Wrapper>
        </Wrapper>
      </Wrapper>

      <Wrapper padding="0 1rem" tipo="column" alignItems="flex-start">
        <TagScrollText
          bgcolor="#70e5ef"
          textColor="#000"
          scrollOn={data.tipo.label.length > 11}
        >
          {data.tipo.label.toUpperCase()}
        </TagScrollText>
        <SeparatorHorizontal ppadding="0.2rem 0" />
        <TagStatus
          bgcolor="#FFEEEC"
          textColor="#FF4D35"
          altura="24px"
          id="timer"
        >
          <IconeClock width="18" height="18" /> &nbsp;
          <Timer time={data.cronometroInicio} expira={data.cronometroFim} />
        </TagStatus>
      </Wrapper>

      <Wrapper
        padding="0 1rem"
        tipo="column"
        alignItems="flex-start"
        pwidth="220px"
      >
        <Paragraph1 style={{ margin: '0', lineHeight: '13px' }}>
          Valor Bruto
        </Paragraph1>
        <Title5
          style={{
            fontWeight: 'bold',
            margin: '0.5rem 0 0 0',
            lineHeight: '18px',
          }}
        >
          {currency(data.valorBruto)}
        </Title5>
      </Wrapper>

      {!data.link && <InfoStatus>Processando</InfoStatus>}

      {data.link && (
        <Steps
          data={data}
          openPropostas={openPropostas}
          dialogOpen={(e) => {
            e.preventDefault();
            dialogOpen(
              'participarOperacao',
              () =>
                download.mutate({
                  idOperacao: data.idOperacao,
                  nome: data.cedente,
                }),
              { idOperacao: data.idOperacao, nome: data.cedente }
            );
          }}
          downloadDirect={(e) => {
            e.preventDefault();
            download.mutate({
              idOperacao: data.idOperacao,
              nome: data.cedente,
            });
          }}
        />
      )}

      {!isHidden.includes(data.step.nome) && (
        <ButtonRounded
          hoverColor="#FF4D35"
          id="button-trash"
          tooltipText="Desisitir desta operação"
          positionButton={['position: absolute', 'top: 10px', 'right: 10px']}
          onClick={(e) => {
            e.preventDefault();
            dialogOpen(
              'desistirOperacao',
              (aditionalPayloadToAction) =>
                desistir.mutate({
                  idOperacao: data.idOperacao,
                  ...aditionalPayloadToAction,
                }),
              data.idOperacao
            );
          }}
        >
          <IcoTrash />
        </ButtonRounded>
      )}

      <ButtonRounded
        hoverColor="#008894"
        id="button-resume"
        tooltipText="Resumo da operação"
        positionButton={[
          'position: absolute',
          !isHidden.includes(data.step.nome) ? 'top: 43px' : 'top: 10px',
          'right: 10px',
        ]}
        onClick={(e) => {
          e.preventDefault();
          dialogOpen('resumoOperacao', null, {
            idOperacao: data.idOperacao,
            idGrupo: data.idGrupo,
          });
        }}
      >
        <IcoResume />
      </ButtonRounded>
    </CardStyle>
  );
};

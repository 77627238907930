/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { createActions, createReducer } from 'reduxsauce';
import { call, put, take, fork, cancel, cancelled } from 'redux-saga/effects';
import axios, { CancelToken } from 'axios';

import { getUserData } from 'services/getUserData';
import { Creators as uiToasterActions } from 'store/ducks/uiToaster';

import CONFIG from '../../config';

/**
 * Criando action types & creators
 */

export const { Types, Creators } = createActions({
  apiMotivoConvitesRequest: ['idTipoEmpresa'],
  apiMotivoConvitesSuccess: ['data'],
  apiMotivoConvitesError: [],
  apiMotivoConvitesCancel: [],
});

/**
 * Criando os reducer handlers
 */

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: false,
};

const apiListRequest = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: true,
    data: [],
    error: false,
  };
};

const apiListSuccess = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: action.data,
    error: false,
  };
};

const apiListError = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: [],
    error: true,
  };
};

const apiListCancel = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: [],
    error: false,
  };
};

/**
 * Criando reducer
 */

export default createReducer(INITIAL_STATE, {
  [Types.API_MOTIVO_CONVITES_REQUEST]: apiListRequest,
  [Types.API_MOTIVO_CONVITES_SUCCESS]: apiListSuccess,
  [Types.API_MOTIVO_CONVITES_ERROR]: apiListError,
  [Types.API_MOTIVO_CONVITES_CANCEL]: apiListCancel,
});

/**
 * Sagas
 */

function changeData(data) {
  if (data.length > 0) {
    const values = data.map((res) => ({
      id: res.id,
      name: res.nome,
    }));
    values.unshift({ id: 0, name: '' });
    return values;
  }
  return [];
}

function* sagaGetAsynResult(params, endpoint) {
  const source = CancelToken.source();
  const url = `${CONFIG.API_REMODELING_URL}${endpoint}/${params.idTipoEmpresa}`;
  try {
    const response = yield axios.get(url, { cancelToken: source.token });

    const data = yield call(changeData, response.data.data);

    console.log(data);

    yield put(Creators.apiMotivoConvitesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(uiToasterActions.uiToasterMessage(error, 'error'));
    yield put(Creators.apiMotivoConvitesError());
  } finally {
    if (yield cancelled()) {
      console.log('cancel');
      source.cancel();
    }
  }
}

export function* sagaMotivoConvites(action) {
  const user = yield call(getUserData);

  if (user) {
    // const params = {
    //   params: {
    //     idTipoEmpresa: action.idTipoEmpresa,
    //   },
    // };

    const params = {
      idTipoEmpresa: action.idTipoEmpresa,
    };

    const task = yield fork(
      sagaGetAsynResult,
      params,
      '/business-api/api/matching/conexao/motivos/recusa/convite'
    );

    yield take(Types.API_MOTIVO_CONVITES_CANCEL);
    yield cancel(task);
  } else {
    yield put(Creators.apiTermosAssinarError());
  }
}

import { createStitches, keyframes } from '@stitches/react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { darken } from 'polished';
import { ButtonRounded } from 'components/FinButton/ButtonRounded';
import { ReactComponent as ThreeDotsVertical } from '../../assets/icones/icone-tres-pontos-vertical.svg';
import { ReactComponent as Undo } from '../../assets/icones/icone-desfazer.svg';

const slideDown = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-10px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const { styled } = createStitches({
  utils: {
    icon: (value) => ({
      background: `url(${value}) no-repeat center left`,
    }),
  },
});

export const Popover = PopoverPrimitive.Root;

export const PopoverTriggerButtonFloat = styled(PopoverPrimitive.Trigger, {
  fontFamily: 'Inter',
  borderRadius: '10px',
  height: '40px',
  fontSize: '0.9rem',
  fontWeight: 'normal',
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  '&:hover': { backgroundColor: darken(0.1, '#fff') },
  padding: '0 6px',
  position: 'absolute',
  right: '4px',
  top: '4px',
  width: '40px',
  zIndex: '99',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const PopoverTrigger = styled(PopoverPrimitive.Trigger, {
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
});

export const PopoverAnchor = styled(PopoverPrimitive.Anchor, {});

export const PopoverContent = styled(PopoverPrimitive.Content, {
  flexDirection: 'column',
  animationDuration: '0.6s',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  '&[data-side="bottom"]': { animationName: slideDown },
  borderRadius: '17px',
  backgroundColor: '#fff',
  border: '1px solid #ECECEC',
  boxShadow: '0px 3px 4px 3px rgba(0, 0, 0, 0.05)',
  padding: '10px 12px',
  zIndex: '999',
});

export const Option = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  textAlign: 'center',
  width: '140px',
  heigth: '40px',
  backgroundColor: '#fff',
  borderRadius: '5px',
  margin: '4px 0',
  padding: '5px 0',
  // '&:hover': {
  //   backgroundColor: '#EBFDFF',
  // },
});

export const ThreeDotsIcon = styled(ThreeDotsVertical, {});

export const UndoBtn = styled(ButtonRounded, {
  width: '36px',
  height: '32px',
  borderRadius: '12px',
  backgroundColor: '#008894',

  '&:hover': {
    backgroundColor: '#72afb6',
    '& svg': {
      '& path': {
        stroke: '#fff',
      },
    },
  },

  '&:active': {
    backgroundColor: '#008894',
  },
});

export const UndoIcon = styled(Undo, {});

export const Label = styled('span', {
  textAlign: 'left',
  fontFamily: 'Inter, sans-serif',
  fontSize: '11px',
  color: '#646464',
  marginLeft: '8px',
});

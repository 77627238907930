const INITIAL_STATE = {
  data: [],
  isLoading: null,
  error: false,
};

const resumo = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    /********************** RESUMO **********************/
    case 'LOAD_RESUMO_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'LOAD_RESUMO_SUCCESS':
      console.log(action);
      return {
        ...state,
        data: action.data,
        isLoading: false,
      };
    case 'LOAD_RESUMO_FAILURE':
      return {
        ...state,
        data: action.data,
        error: true,
      };
    default:
      return state;
  }
};

export default resumo;

import styled, { css } from 'styled-components/macro';

import {
  FinInputTextArea,
  InputCurrencyStyle,
  FinInputCurrency,
  InputErrorStyle,
} from 'components/FinInput/MainInput';

export const ErrorCuston = styled(InputErrorStyle)``;

export const InputCurrency = styled(FinInputCurrency)`
  font-family: 'Inter', sans-serif;
  width: 100%;
  & > input {
    font-family: 'Inter', sans-serif;
    position: relative;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    height: 40px;
    border-radius: 10px;
    border: none;
    background-color: var(--finblue-secondary);
    font-size: 0.9rem;
    letter-spacing: 0;
    color: var(--fingray-900);
    &::placeholder {
      color: var(--fingray-900);
      letter-spacing: 0;
    }
    ${({ error }) =>
      error &&
      css`
        border: 2px solid red;
      `}
    ${({ disabled }) =>
      disabled &&
      css`
        background: #f2f2f3;
      `}
      & + span {
      margin-top: 12px;
    }
  }
`;

export const InputConcentracaoMaxima = styled(InputCurrencyStyle)`
  width: 100%;
  font-family: 'Inter', sans-serif;
  position: relative;
  font-family: 'Inter', sans-serif;
  height: 40px;
  font-weight: 600;
  border-radius: 10px;
  border: none;
  background-color: var(--finblue-secondary);
  font-size: 0.9rem;
  letter-spacing: 0;
  color: var(--fingray-900);
  ${({ error }) =>
    error &&
    css`
      border: 2px solid red;
    `}
  &::placeholder {
    color: var(--fingray-900);
    letter-spacing: 0;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      background: #f2f2f3;
    `}
  & + span {
    margin-top: 12px;
    left: 0;
  }
`;

export const InputTextArea = styled(FinInputTextArea)`
  font-family: 'Inter', sans-serif;
  width: 100%;
  & > textarea {
    font-family: 'Inter', sans-serif;
    position: relative;
    font-family: 'Inter', sans-serif;
    height: 40px;
    border-radius: 10px;
    border: none;
    font-weight: 600;
    background-color: var(--finblue-secondary);
    font-size: 0.9rem;
    letter-spacing: 0;
    color: var(--fingray-900);
    &::placeholder {
      color: var(--fingray-900);
      letter-spacing: 0;
    }

    ${({ error }) =>
      error &&
      css`
        border: 2px solid red;
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        background: #f2f2f3;
      `}

      & + span {
      margin-top: 12px;
      left: 0;
    }
  }
`;

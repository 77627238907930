import React from 'react';

import { NwDialog } from 'components/NwDialog';
import { Ring } from '@uiball/loaders';
import { ReactComponent as IconCheck } from 'assets/images/icon-checkcircle.svg';
import { ReactComponent as IconError } from 'assets/images/icon-close-flat.svg';

import { SeparatorHorizontal } from 'components/FinSeparador';
import { ButtonStretch } from 'pages/FinCredit/MainCardAction/Comps/ButtonStretch';
import {
  Title,
  Flex,
  Text,
  Accordion,
} from 'pages/FinCredit/MainCardAction/Credito/style.credito';
import * as NWCard from './style.card';
import { NWButton } from '../Button';

import { ContentHabilitar, ContentDesabilitar } from '../Dialogs';

import { useMutateEnable, useMutateDisable } from '../../hook/mutates';
import { statusHabilitacao, habilitacaoTextIcon } from '../../config';

export function Habilitação({ data, largura }) {
  const [accordionOpen, setAccordionOpen] = React.useState(false);

  const style = {
    width: largura || '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
  };

  const enabled = useMutateEnable();
  const disable = useMutateDisable();

  return (
    <>
      <Flex justify="space-between" style={{ gap: '8px' }}>
        <Flex justify="flex-start">
          {habilitacaoTextIcon[data.statusHabilitado].icon}
          <Title
            style={{
              marginLeft: '0.8rem',
              /* color: habilitacaoTextIcon[data.statusHabilitado].color, */
            }}
          >
            {habilitacaoTextIcon[data.statusHabilitado].label}
          </Title>
        </Flex>
        <ButtonStretch
          action={() => setAccordionOpen((state) => !state)}
          isOpen={accordionOpen}
        />
      </Flex>
      <Accordion open={accordionOpen}>
        <Flex column>
          <Text>
            Informe se está empresa está habilitada no administrador de seu
            fundo.
          </Text>
          <SeparatorHorizontal ppadding="0.4rem" />
          {data.statusHabilitado === statusHabilitacao.habilitado ? (
            <NWCard.RegionButtons style={style}>
              <Flex column style={{ width: '100%', gap: '5px' }}>
                <NwDialog
                  size="small"
                  trigger={
                    <NWButton.Error
                      largura="total"
                      disabled={disable.isLoading}
                    >
                      {disable.isLoading ? (
                        <Ring
                          size={20}
                          lineWeight={3}
                          speed={2}
                          color="white"
                        />
                      ) : (
                        <>
                          <IconError />
                          <span style={{ marginLeft: '0.5rem' }}>
                            Remover Habilitação
                          </span>
                        </>
                      )}
                    </NWButton.Error>
                  }
                >
                  <ContentDesabilitar
                    desabilitar={() =>
                      disable.mutate({ idGrupo: data.idGrupo })
                    }
                  />
                </NwDialog>
              </Flex>
            </NWCard.RegionButtons>
          ) : (
            <NWCard.RegionButtons style={style}>
              <Flex column style={{ width: '100%', gap: '5px' }}>
                <NwDialog
                  size="small"
                  trigger={
                    <NWButton.Primary
                      largura="total"
                      disabled={enabled.isLoading}
                    >
                      {enabled.isLoading ? (
                        <Ring
                          size={20}
                          lineWeight={3}
                          speed={2}
                          color="white"
                        />
                      ) : (
                        <>
                          <IconCheck />
                          <span style={{ marginLeft: '0.5rem' }}>
                            Habilitar
                          </span>
                        </>
                      )}
                    </NWButton.Primary>
                  }
                >
                  <ContentHabilitar
                    enabled={() => enabled.mutate({ idGrupo: data.idGrupo })}
                  />
                </NwDialog>
              </Flex>
            </NWCard.RegionButtons>
          )}
        </Flex>
      </Accordion>
    </>
  );
}

import React, { useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { icons } from 'assets/icones/finplaceIcons';
import { finTheme } from 'finTheme';
import { StringToSvg } from 'helpers/svgFiles/StringToSvg';
import Dropdown, { DropdownItem } from './Dropdown';

const Container = styled.div`
  position: absolute;
  z-index: ${({ theme }) => theme.newtheme.zIndex.dropdownMenu};
  bottom: -22%;
  right: 11%;
  margin-right: 200px;

  opacity: ${({ opacity }) => opacity || '0'};
  transition: opacity 0.4s ease-in-out;
`;

const icon = (
  <StringToSvg
    viewBox="0 0 24 24"
    width="24"
    height="24"
    stroke={finTheme.newtheme.color.cinza5}
  >
    {icons.flag}
  </StringToSvg>
);

const MenuBloquear = ({ open, close, onclick }) => {
  const refMenu = useRef();
  const clickListener = useCallback(
    (e) => {
      if (e) {
        if (refMenu.current && !refMenu.current.contains(e.target)) {
          close();
        }
      }
    },
    [close]
  );

  useEffect(() => {
    document.addEventListener('click', clickListener);
    return () => {
      document.removeEventListener('click', clickListener);
    };
  }, [clickListener]);

  return (
    open && (
      <Container opacity={open ? 1 : 0} ref={refMenu}>
        <Dropdown>
          <DropdownItem icon={icon} onclick={onclick}>
            Bloquear
          </DropdownItem>
        </Dropdown>
      </Container>
    )
  );
};

MenuBloquear.propTypes = {
  onclick: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

export default MenuBloquear;

import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { authenticationService } from '../components/AuthenticationService';
import { Theme } from '../theme';
import { IconInclude } from '../components/Elements/icons';

import { SeparatorVertical, SeparatorHorizontal } from '../components/Elements';

import CardFramer from '../images/cardFramer.svg';

const WordText = styled.span`
  font-weight: bold !important;
  font-size: 2rem;
  word-spacing: 1.8px;
  /* letter-spacing: -2.4px; */
  line-height: 48px;
  padding: 0 32px 32px;
  text-align: center;
  background: -webkit-linear-gradient(
    45deg,
    rgba(0, 132, 144, 1),
    rgba(246, 94, 76, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  strong {
    font-size: 3rem;
  }
`;

const CardButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: ${Theme.greenSm};
  border-radius: 5px;
  -webkit-box-shadow: -1px 9px 15px -4px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: -1px 9px 15px -4px rgba(0, 0, 0, 0.17);
  box-shadow: -1px 9px 15px -4px rgba(0, 0, 0, 0.17);
  background: url(${CardFramer}) no-repeat;
  background-size: cover;
  height: 46vh;
  background-position-y: 0;
  padding: 1rem;
  min-width: 278px;
  width: 278px;
  transition: all 0.2s ease-in-out;
  position: relative;
  text-align: center;
  &:hover {
    transform: scale(1.1);
  }
`;

const Container = styled.div`
  display: flex;
  width: 1228px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
`;

const Titulo = styled.h3`
  color: ${Theme.gray6};
  font-weight: bold;
  font-size: 1.5rem;
`;

const Parag = styled.p`
  color: ${Theme.gray6};
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.5rem;
`;

const WrapIco = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
`;

const Dot = styled.div`
  border-radius: 90rem;
  background-color: ${({ bgcolor }) => bgcolor || Theme.gray2};
  width: 0.6rem;
  height: 0.6rem;
  ${({ absolute }) =>
    absolute &&
    `
  top: 5px;
  right: 5px;
  position: absolute;
  `}
`;

const Content = styled.div`
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      verModalAberto: true,
      isChecked: false,
      buttons: [
        {
          label: 'OK',
          action: 'CONFIRME',
          type: 'primary',
          disabled: true,
        },
      ],
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    authenticationService.currentUser.subscribe((x) =>
      this.setState({ currentUser: x })
    );
  }

  render() {
    return (
      <Content>
        <WordText>
          Bem-vindo(a) à Finplace!
          <br></br>A melhor maneira de conceder crédito e conquistar novos
          clientes.
        </WordText>

        <Container>
          <Link to="/meusdados">
            <CardButton>
              <Dot bgcolor={Theme.primary} absolute />
              <WrapIco>
                <IconInclude
                  size="65px"
                  padding="3rem"
                  bgcolor={Theme.gray6}
                  icon="touchId"
                />
              </WrapIco>
              <SeparatorHorizontal hsize="0.5rem 0" />
              <div style={{ minHeight: '150px' }}>
                <Titulo>Dados Cadastrais</Titulo>
                <SeparatorHorizontal hsize="0.2rem 0" />
                <Parag>
                  Insira aqui as suas informações básicas, endereço e contatos.
                </Parag>
              </div>
            </CardButton>
          </Link>

          <SeparatorVertical wsize="1rem" />

          <Link to="/Finmatch">
            <CardButton>
              <Dot bgcolor={Theme.primary} absolute />
              <WrapIco>
                <IconInclude
                  size="65px"
                  padding="3rem"
                  bgcolor={Theme.gray6}
                  icon="FinmatchSymbol"
                />
              </WrapIco>
              <SeparatorHorizontal hsize="0.5rem 0" />
              <div style={{ minHeight: '150px' }}>
                <Titulo>Finmatch</Titulo>
                <SeparatorHorizontal hsize="0.2rem 0" />
                <Parag>
                  Preencha sua política de crédito e encontre empresas aderentes
                  ao seu perfil.
                </Parag>
              </div>
            </CardButton>
          </Link>

          <SeparatorVertical wsize="1rem" />

          <Link
            to={{
              pathname: '/minha-rede',
            }}
          >
            <CardButton>
              <Dot bgcolor={Theme.primary} absolute />
              <WrapIco>
                <IconInclude
                  size="65px"
                  padding="3rem"
                  bgcolor={Theme.gray6}
                  icon="iGlobe"
                />
              </WrapIco>
              <SeparatorHorizontal hsize="0.5rem 0" />
              <div style={{ minHeight: '150px' }}>
                <Titulo>Minha Rede</Titulo>
                <SeparatorHorizontal hsize="0.2rem 0" />
                <Parag>
                  Visualize todos os empreendedores cadastrados na plataforma.
                </Parag>
              </div>
            </CardButton>
          </Link>
          <SeparatorVertical wsize="1rem" />
          <Link to="/operacoes">
            {/* <Link to="/main-operacoes/andamento-convenio"> */}
            <CardButton>
              <Dot bgcolor={Theme.primary} absolute />
              <WrapIco>
                <IconInclude
                  size="65px"
                  padding="3rem"
                  bgcolor={Theme.gray6}
                  icon="currencyExchange"
                />
              </WrapIco>
              <SeparatorHorizontal hsize="0.5rem 0" />
              <div style={{ minHeight: '150px' }}>
                <Titulo>Negociações</Titulo>
                <SeparatorHorizontal hsize="0.2rem 0" />
                <Parag>
                  Veja suas negociações em andamento, ou aquelas já realizadas.
                </Parag>
              </div>
            </CardButton>
          </Link>
          <SeparatorVertical wsize="1rem" />
          {/* <Link to="/dashboard">
                  <CardButton>
                    <Dot bgcolor={Theme.primary} absolute />
                    <WrapIco>
                      <IconInclude
                        size="65px"
                        padding="3rem"
                        bgcolor={Theme.gray6}
                        icon="stockMarket"
                      />
                    </WrapIco>
                    <SeparatorHorizontal hsize="0.5rem 0" />
                    <div style={{ minHeight: '150px' }}>
                      <Titulo>Indicadores</Titulo>
                      <SeparatorHorizontal hsize="0.2rem 0" />
                      <Parag>
                        Analise seu histórico, volume de participação e vitórias
                        nas operações.
                      </Parag>
                    </div>
                  </CardButton>
                </Link> */}
        </Container>
      </Content>
    );
  }
}

export default Home;

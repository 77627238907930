/* eslint-disable no-return-assign */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { /* useState, */ useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components/macro';
import { useField } from 'formik';
import { connect } from 'react-redux';
import { Theme } from '../../theme';

const StatusBar = styled.span`
  display: block;
  position: relative;
  margin-top: -1px;
  height: 2px;
  width: 100%;
  &:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    transform: scaleX(0);
    transition: transform 0.25s ease, transform 0.25s ease;
  }
`;

const Label = styled.span`
  color: ${({ color }) => color};
  font-weight: ${({ fontweight }) => fontweight};
  ${({ marginbottom }) =>
    marginbottom &&
    css`
      margin-bottom: ${marginbottom};
    `}
`;

export const InputMainMaterial = styled.input`
  padding-left: 0;
  padding-bottom: 5px;
  background: hsla(0, 0%, 100%, 0);
  font-size: 18px;
  font-weight: 400;
  color: #333;
  line-height: 1.35;
  border: 0;
  border-bottom: 1px solid #ccc;
  height: 30px;
  border-radius: 0;
  width: 100%;
  outline: 0;
  font-weight: 400;
`;

const Field = styled.div`
  width: ${({ largura }) => largura || '50%'};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 98px;
  &:hover ${StatusBar}:after {
    background-color: rgba(0, 0, 0, 0.45);
    transform: scaleX(1);
  }
  & ${InputMainMaterial}:focus + ${StatusBar}:after {
    background-color: ${Theme.primary};
    transform: scaleX(1);
  }
  ${({ error }) =>
    error &&
    css`
      & ${InputMainMaterial}:focus + ${StatusBar}:after {
        background-color: ${Theme.orange};
        transform: scaleX(1);
      }
    `}
`;

const InputFeedback = styled.div`
  color: ${({ cor }) => cor || Theme.orange};
  font-weight: 400;
  margin-top: 5px;
`;

/* const InputTextLoc = ({
  label,
  value,
  handleBlur,
  id,
  error,
  handleChange,
  errormessage,
  largura,
  isLoading,
  data,
  name,
  errorCep,
}) => {
  const [field, meta, helpers] = useField(id);
  const [valueC, setValueC] = useState(value);
  const handleCustonChange = e => {
    handleChange(e);
  };
  useEffect(() => {
    !isLoading && isLoading !== null
      ? errorCep
        ? setValueC('')
        : setValueC(data[name])
      : setValueC(value);
  }, [data]);
  useEffect(() => {
    data && helpers.setValue(data[name]);
  }, [valueC]);
  return (
    <Field error={error} largura={largura}>
      <Label color={Theme.gray3} fontweight="400" marginbottom="0.5rem">
        {label}
      </Label>
      <InputMainMaterial
        id={id}
        onChange={handleChange}
        value={field.value}
        onBlur={handleBlur}
      />
      <StatusBar />
      {error && <InputFeedback>{errormessage}</InputFeedback>}
    </Field>
  );
}; */

/**
 * ************************* useField create components ************************
 */
export const TextInputFormikRedux = ({
  label,
  custonChange,
  custonBlur,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const { onBlur, onChange } = field;
  const custonHandleBlur = (e) => {
    onBlur(e);
    custonBlur();
  };
  const custonHandleChange = (e) => {
    onChange(e);
    custonChange();
  };
  const getCustonFunctions = () => {
    const funcs = {};
    if (custonChange && custonBlur) {
      funcs.onBlur = custonHandleBlur;
      funcs.onChange = custonHandleChange;
      Object.keys(field)
        .filter((res) => res !== 'onBlur' && res !== 'onChange')
        .forEach((r) => (funcs[r] = field[r]));
      return funcs;
    }
    if (custonBlur) {
      funcs.onBlur = custonHandleBlur;
      Object.keys(field)
        .filter((res) => res !== 'onBlur')
        .forEach((r) => (funcs[r] = field[r]));
      return funcs;
    }
    if (custonChange) {
      funcs.onChange = custonHandleChange;
      Object.keys(field)
        .filter((res) => res !== 'onChange')
        .forEach((r) => (funcs[r] = field[r]));
      return funcs;
    }

    return Object.keys(funcs).length > 0 ? funcs : false;
  };
  const custonFields = getCustonFunctions() ? getCustonFunctions() : field;
  const popData = useCallback(() => {
    if (Object.keys(props.data).length > 0) {
      switch (props.name) {
        case 'estado':
          props.data && setValue(props.data.uf);
          break;
        case 'cidade':
          props.data && setValue(props.data.localidade);
          break;
        case 'bairro':
          props.data && setValue(props.data.bairro);
          break;
        case 'rua':
          props.data && setValue(props.data.logradouro);
          break;
        default:
          console.log('default');
      }
    }
  }, [props.data]);
  useEffect(() => {
    popData();
  }, [popData]);
  return (
    <Field error={meta.error} largura={props.largura}>
      <Label htmlFor={props.id || props.name} marginbottom="0.5rem">
        {label}
      </Label>
      <InputMainMaterial
        {...custonFields}
        {...props}
        value={field.value || ''}
      />
      <StatusBar />
      {meta.touched && meta.error
        ? !props.errorCep && <InputFeedback>{meta.error}</InputFeedback>
        : null}
      {props.isLoading && props.isLoading !== null && props.statusApi && (
        <InputFeedback cor={Theme.gray3}>Buscando...</InputFeedback>
      )}
      {props.errorCep &&
        props.errorCep !== null &&
        props.statusApi &&
        !props.isLoading && <InputFeedback>{props.data}</InputFeedback>}
    </Field>
  );
};
/**
 * ***************************** end custonFormik ********************************
 */

const mapStateToProps = (state) => {
  return {
    isLoading: state.cep.isLoading,
    data: state.cep.data,
    errorCep: state.cep.error,
  };
};

export default connect(mapStateToProps)(TextInputFormikRedux);

import styled, { css } from 'styled-components/macro';

export const Button = styled.button`
  outline: none;
  border: none;
  background: #f4f4f4;
  border-radius: 12px;
  width: 31px;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: filter 0.3s;
  & > svg {
    transition: transform 0.3s;
  }
  &:hover {
    filter: brightness(90%);
  }
  &:active {
    filter: brightness(100%);
  }
  ${({ isOpen }) =>
    isOpen
      ? css`
          & > svg {
            transform: rotate(-180deg);
          }
        `
      : css`
          & > svg {
            transform: rotate(0deg);
          }
        `}
`;

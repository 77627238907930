import styled from 'styled-components/macro';

import { ReactComponent as IconPdf } from 'assets/images/icon-pdf.svg';

export const Wrapper = styled.div`
  display: flex;
`;

export const ViewMatch = styled.div`
  height: 60px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  /* border-top-right-radius: 0;
  border-bottom-right-radius: 0; */
  border: ${({ borderColor }) =>
    borderColor ? `2px solid ${borderColor}` : `2px solid var(--fingray-400)`};
  color: var(--fingray-900);
  background: ${({ bgColor }) => bgColor || `var(--fingray-300)`};
  min-width: 200px;
`;

export const Text = styled.div`
  position: relative;
  font-family: 'Inter';
  font-weight: 700;
  font-size: 0.875rem;
  text-transform: uppercase;
  text-indent: 45px;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 37px;
    height: 37px;
    transform: translate(0, -50%);
    background: url(${({ icon }) => icon}) no-repeat center center;
  }
`;

export const Button = styled.button`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border: ${({ borderColor }) =>
    borderColor ? `2px solid ${borderColor}` : `2px solid var(--fingray-400)`};
  background: ${({ bgColor }) => bgColor || `var(--fingray-300)`};
  cursor: pointer;
  border-left: none;
  transition: filter 0.4s ease-in-out;

  &:hover {
    filter: brightness(92%);
  }
`;

export const IconPdfStyle = styled(IconPdf)`
  path {
    fill: ${({ iconColor }) => iconColor || `rgba(46, 46, 46, 1)`};
  }
`;

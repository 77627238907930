import React from 'react';

import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

import { useQueryState } from 'hooks/useQueryState';
import { useRenderResponseData } from 'hooks/useRenderResponseData';
import { NWBreadcrumb, NWBreadcrumbItem } from 'components/NWBreadcrumb';
import {
  Heading,
  RegionListCards,
  TitleSmall,
  RegionTitle,
  RegionAction,
} from '../style.layout';

import { Card, List, ItemList } from '../Comps/Card';
import { NWPagination } from '../Comps/Pagination';

import { useQueryInvites } from '../hook/useQueryInvites';

export function Convites({ executeScroll }) {
  const { getQueries, setQueriesAdd } = useQueryState();

  const skip = getQueries('skip') || 1;

  const { data, isLoading, error } = useQueryInvites();

  const { PlaceNoResult, PlaceLoader, PlaceError } = useRenderResponseData({
    data,
    isLoading,
    error,
  });

  const history = useHistory();

  const withData = data && data.data;

  const query = queryString.parse(history.location.search);
  const { invite } = query;

  const inviteSent = invite === 'sent';

  const changePage = (page) => {
    setQueriesAdd({ skip: page });
    executeScroll();
  };

  return (
    <>
      <RegionListCards>
        <NWBreadcrumb>
          <NWBreadcrumbItem to="/minha-rede">Minha Rede</NWBreadcrumbItem>
          <NWBreadcrumbItem noIcon>Convites</NWBreadcrumbItem>
        </NWBreadcrumb>
        <Heading>
          <RegionTitle>
            <TitleSmall>
              {inviteSent ? `Convites enviados` : `Convites recebidos`}
            </TitleSmall>
          </RegionTitle>
          <RegionAction></RegionAction>
        </Heading>
        <PlaceNoResult />
        <PlaceLoader />
        <PlaceError />
        <List direction="vertical">
          {withData &&
            data.data.map((item) => {
              return (
                <ItemList key={item.idConvite}>
                  <Card
                    isInvitation={inviteSent ? 'sent' : 'received'}
                    data={item}
                  />
                </ItemList>
              );
            })}
        </List>
      </RegionListCards>
      <NWPagination
        totalPages={data && data.totalPages}
        page={skip}
        changePage={({ page }) => changePage(page)}
        place="center"
      />
    </>
  );
}

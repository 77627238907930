import React from 'react';
import { Wrapper } from 'components/FinWrappers';
import { useSelector } from 'react-redux';
import { Title6 } from 'components/FinHeadings';
import { useFetchOperacoesResumo } from 'pages/Operacoes/queries/useFetchOperacaoResumo';
import { Loader } from 'pages/Operacoes/Elements/Loader';
import FeedbackInfo from 'components/FinFeedbackInfo';
import { ContaBancaria } from 'pages/Operacoes/Ofertas/ContaBancaria';
import { ResumeValues } from 'pages/Operacoes/Ofertas/ResumeValues';
import { ListPercentagens } from 'pages/Operacoes/Ofertas/ListPercentagens';
import { DialogCuston } from './DialogCuston';

const DialogResumo = () => {
  const uiDialogState = useSelector((state) => state.uiDialog);

  const { data, isLoading, error } = useFetchOperacoesResumo({
    ...uiDialogState.values,
  });

  const isExistData = !isLoading && !!data && !!data.data && !error;

  return (
    <DialogCuston largura="720px">
      <Wrapper
        padding="32px 0"
        justifyContent="flex-start"
        alignItems="center"
        pwidth="100%"
        tipo="column"
      >
        {isLoading && <Loader />}
        {error && <FeedbackInfo tipo="error" />}
        {isExistData && data.data.length === 0 && (
          <FeedbackInfo tipo="empty" text="Conteúdo não disponível" />
        )}

        {isExistData && (
          <Wrapper
            padding="0"
            pwidth="100%"
            justifyContent="flex-start"
            tipo="column"
            alignItems="flex-start"
          >
            <Title6>Dados Bancários</Title6>
            <ContaBancaria data={data.data.contaCorrente} />
            <ResumeValues data={data.data.resumoOperacoes} />
            <Title6>Concentração Top 5 Sacados</Title6>
            <ListPercentagens
              width="100%"
              title="Concentração Top 5 Sacados"
              data={data.data.resumoSacados}
            />
          </Wrapper>
        )}
      </Wrapper>
    </DialogCuston>
  );
};

export default DialogResumo;

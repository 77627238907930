import styled from 'styled-components/macro';
import { darken } from 'polished';
import { FinInput } from 'components/FinInput/MainInput';
import IconSearch from 'assets/images/search-outline.svg';
import Button from 'components/FinButton';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 99;
`;

export const Search = styled(FinInput)`
  font-family: 'Inter', sans-serif;
  width: 100%;
  & > input {
    font-family: 'Inter', sans-serif;
    position: relative;
    font-family: 'Inter', sans-serif;
    height: 40px;
    border-radius: 10px;
    border: none;
    background: url(${IconSearch}) no-repeat 11px center;
    background-color: var(--fingray-300);
    font-size: 0.9rem;
    text-indent: 40px;
    letter-spacing: 0;
    color: var(--fingray-900);
    &::placeholder {
      color: var(--fingray-900);
      letter-spacing: 0;
    }
  }
`;

export const ButtonClose = styled(Button)`
  position: absolute;
  top: 50%;
  right: 6px;
  z-index: 9;
  width: 30px;
  height: 30px !important;
  padding: 6px;
  transform: translate(0, -50%);

  background-color: var(--fingray-300);
  border-radius: 10px;
  height: 40px;
  font-size: 0.9rem;
  font-weight: normal;
  color: var(--fingray-900);

  &:enabled:hover {
    background-color: ${darken(0.1, '#f2f2f3')};
  }

  & svg path {
    stroke-width: 2px;
    stroke: var(--fingray-900);
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import bgImage from 'assets/images/background.jpg';

const MainLayout = styled.div`
  position: relative;
  margin: 56px 0 0 68px;

  height: calc(100vh - 56px);
  width: calc(100vw - 68px);

  background: url(${({ background }) => background || bgImage}) no-repeat 0 0;
  /* background-color: #fff; */
  background-size: cover;
  background-attachment: fixed;
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};

  overflow-y: auto;
`;

const Layout = ({ background, children }) => {
  return (
    <MainLayout id="areaRolegem" background={background}>
      {children}
    </MainLayout>
  );
};

Layout.propTypes = {
  background: PropTypes.string,
  children: PropTypes.any,
};

Layout.defaultProps = {
  background: bgImage,
};

export default Layout;

import React from 'react';
import { SkeletonGenerator } from 'helpers/SkeletonGenerator';

import {
  CardStyle,
  CardStyleHead,
  CardStyleBody,
  CardStyleFooter,
} from './style';

const Card = () => {
  return (
    <CardStyle>
      <CardStyleHead>
        <SkeletonGenerator isLoading={true} count={2} />
      </CardStyleHead>
      <CardStyleBody>
        <SkeletonGenerator isLoading={true} count={8} />
      </CardStyleBody>
      <CardStyleFooter>
        <SkeletonGenerator isLoading={true} count={1} />
      </CardStyleFooter>
    </CardStyle>
  );
};

export const CardSkeleton = ({ count }) => {
  const skeleton = Array(count).fill(<Card />);
  return skeleton;
};

import React from 'react';
import {
  FinPremium,
  FinBasic,
  FinStandard,
  FinScanner,
} from 'components/FinTagMember';

export const useSelectTag = ({ tipo, pos }) => {
  const absolute = {
    position: 'absolute',
    ...pos,
    zIndex: 5,
  };
  const position = pos ? absolute : { width: 'fit-content' };
  switch (tipo) {
    case 'premium':
      return {
        comp: () => <FinPremium style={position} />,
      };
    case 'standard':
      return {
        comp: () => <FinStandard style={position} />,
      };
    case 'basic':
      return {
        comp: () => <FinBasic style={position} />,
      };
    case 'finscanner':
      return {
        comp: () => <FinScanner style={position} />,
      };
    default:
      return {
        comp: () => <FinBasic style={position} />,
      };
  }
};

import React from 'react';
import styled from 'styled-components/macro';

import { Theme } from '../../theme';

import { Wrapper, ChevronDown, ChevronUp } from '../Elements';

const BtnAction = styled.div`
  background-color: transparent;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
`;

const Content = styled.div`
  width: 67px;
  height: inherit;
  background-color: ${({ color }) => color};
`;
const ButtonChevron = props => {
  return (
    <Content color={props.bgcolor}>
      {!props.type ? (
        <Wrapper>
          <ChevronDown
            size="34px"
            style={{ position: 'absolute', right: '15px', top: '36%' }}
            color={props.color ? props.color : Theme.white}
          />
        </Wrapper>
      ) : (
        <Wrapper>
          <ChevronUp
            size="34px"
            style={{ position: 'absolute', right: '15px', top: '36%' }}
            color={props.color ? props.color : Theme.white}
          />
        </Wrapper>
      )}
      <BtnAction onClick={() => props.appereanceContent()} />
    </Content>
  );
};

export default ButtonChevron;

import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({ tipo }) => tipo || 'row'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  width: ${({ pwidth }) => pwidth || 'auto'};
  height: ${({ pheight }) => pheight || 'auto'};
  padding: ${({ padding }) => padding || '1rem'};
  margin: ${({ pmargin }) => pmargin || '0'};
  ${({ cutText }) =>
    cutText &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `};
  ${({ pposition }) =>
    pposition &&
    css`
      position: ${pposition};
    `}
`;

import React, { useContext } from 'react';

import { Td } from 'components/FinTable';
import { currency, date } from 'helpers/format';
import { Wrapper } from 'components/FinWrappers';
import { Caption } from 'components/FinParagraph';

import Button from 'components/FinButton';
import { FinInputCurrency, FinInputDate } from 'components/FinInput/MainInput';
import { OperacoesContext } from '../../provider';

import { IconeMinusStyle } from '../../Bordero/style.bordero';
import { TrCustom } from './styles.parcelas';

export const Parcela = ({
  data,
  index,
  deleteParcela,
  onChangeVencimento,
  onChangeValor,
}) => {
  const [state] = useContext(OperacoesContext);

  const {
    modal: { values },
  } = state;

  return (
    <TrCustom>
      <Td padding="4px">
        <Wrapper padding="0" pwidth="50px">
          <Caption>{data.idNumero}</Caption>
        </Wrapper>
      </Td>
      <Td padding="4px">
        {values.rules.indexOf('view') !== -1 ? (
          <Caption>{currency(data.valor)}</Caption>
        ) : (
          <FinInputCurrency
            value={data.valor}
            name="valor-input"
            onChangeEvent={(e, m, f) => onChangeValor(f, data.idNumero)}
            largura="100%"
          />
        )}
      </Td>
      <Td padding="4px">
        {values.rules.indexOf('view') !== -1 ? (
          <Caption>{date(data.vencimento)}</Caption>
        ) : (
          <FinInputDate
            value={date(data.vencimento)}
            name="vencimento-input"
            onValueChange={({ formattedValue }) => {
              onChangeVencimento(formattedValue, data.idNumero);
            }}
            largura="100%"
          />
        )}
      </Td>
      <Td padding="4px" style={{ width: '50px' }}>
        {values.rules.indexOf('edit') !== -1 && (
          <Wrapper padding="0" pwidth="50px">
            <Wrapper padding="0" pwidth="30px">
              <Button
                tipo="error"
                rounded
                size="30px"
                largura="total"
                style={{ padding: 0 }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  deleteParcela(index);
                }}
              >
                <IconeMinusStyle width={20} height={20} />
              </Button>
            </Wrapper>
          </Wrapper>
        )}
      </Td>
    </TrCustom>
  );
};

/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { createActions, createReducer } from 'reduxsauce';
import { put, take, fork, cancel, cancelled } from 'redux-saga/effects';

import axios, { CancelToken } from 'axios';

import { Creators as uiToasterActions } from 'store/ducks/uiToaster';

import CONFIG from '../../config';

/**
 * Criando action types & creators
 */

export const { Types, Creators } = createActions({
  apiFiltroRequest: [],
  apiFiltroSuccess: ['data'],
  apiFiltroError: [],
  apiFiltroCancel: [],
});

/**
 * Criando os reducer handlers
 */

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: false,
};

const apiGetRequest = (state = INITIAL_STATE, action) => {
  return {
    isLoading: true,
    data: [],
    error: false,
  };
};

const apiGetSuccess = (state = INITIAL_STATE, action) => {
  return {
    isLoading: false,
    data: [action.data],
    error: false,
  };
};

const apiGetError = (state = INITIAL_STATE, action) => {
  return {
    isLoading: false,
    data: [],
    error: true,
  };
};

const apiGetCancel = (state = INITIAL_STATE, action) => {
  return {
    isLoading: false,
    data: [],
    error: false,
  };
};

/**
 * Criando reducer
 */

export default createReducer(INITIAL_STATE, {
  [Types.API_FILTRO_REQUEST]: apiGetRequest,
  [Types.API_FILTRO_SUCCESS]: apiGetSuccess,
  [Types.API_FILTRO_ERROR]: apiGetError,
  [Types.API_FILTRO_CANCEL]: apiGetCancel,
});

/**
 * Sagas
 */

function* sagaGetAsynResult(endpoint) {
  const source = CancelToken.source();
  const url = `${CONFIG.API_REMODELING_URL}${endpoint}`;

  try {
    const response = yield axios.get(url, {
      cancelToken: source.token,
    });

    yield put(Creators.apiFiltroSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    yield put(uiToasterActions.uiToasterMessage(error, 'error'));
    yield put(Creators.apiFiltroError());
  } finally {
    if (yield cancelled()) {
      console.log('cancel');
      source.cancel();
    }
  }
}

export function* sagaApiGetFiltro(action) {
  // /api/filtro/financiador
  const task = yield fork(
    sagaGetAsynResult,
    '/business-api/api/matching/filtros/financiador'
  );

  yield take(Types.API_FILTRO_CANCEL);
  yield cancel(task);
}

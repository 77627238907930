/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';

import { useQueryClient } from 'react-query';
import { date, initialLetters } from 'helpers/format';
import { currency } from 'components/Formatter';
import { CheckboxFlat } from 'components/FinCheckboxCuston/CheckboxFlat';
import ReactTooltip from 'react-tooltip';
import { useMutateFields } from './mutates/useUpdateFields';

import { AgendaContext } from './reducer';
import { NWPagination } from '../MinhaRede/Comps/Pagination';
import { PriorityPopover } from './Priority';
import { RecoverPopover } from './Recover';
import { Spinner } from './Spinner';

import { InputTax } from './InputTax';
import { InputAgendaValue } from './InputAgendaValue';

import {
  Table,
  Container,
  SequenceIcon,
  EnterpriseCont,
  EditPriCont,
  EditAgendaCont,
  Footer,
  UltimaOpCont,
  DetailsContainer,
  InfoIcon,
  CorpIcon,
  CorpName,
  RedText,
  EditTaxContainer,
  InfoContainer,
} from './tableStyles';

export const AgendaTable = ({
  habilitacao,
  tableHeaders,
  tableData,
  priorityVars,
  selected,
  setSelected,
}) => {
  const queryClient = useQueryClient();
  const [state, actions] = useContext(AgendaContext);
  const updateFields = useMutateFields();

  const {
    filter: { ordemAgenda },
    skip,
  } = state;

  const lista = queryClient.getQueryData(['agenda', 'lista', state]);

  const changeOrder = (event, order) => {
    actions.setOrder(order);
    event.preventDefault();
  };

  const selectAll = (event) => {
    const ids = lista.data.map((item) => item.idItemAgenda);
    if (event.target.checked) {
      setSelected(ids);
    } else {
      setSelected([]);
    }
  };

  const selectCheck = (event, id) => {
    if (event.target.checked) {
      setSelected((state) => [...state, id]);
    } else {
      const removed = selected.filter((item) => Number(item) !== Number(id));
      setSelected(removed);
    }
  };

  const setUpdateTax = (value, idAgenda) => {
    updateFields.mutate([
      {
        idItemAgenda: idAgenda,
        percTaxa: value,
      },
    ]);
  };

  const setUpdateAgendaValue = (value, idAgenda) => {
    updateFields.mutate([
      {
        idItemAgenda: idAgenda,
        vlrAgenda: value,
      },
    ]);
  };

  return (
    <>
      <Container>
        <Table>
          <thead
            style={{
              verticalAlign: 'middle',
            }}
          >
            <tr>
              <th>
                <CheckboxFlat
                  ariaLabel="checkbox-header"
                  name="checkbox-header"
                  checked={
                    lista && lista.data && selected.length === lista.data.length
                  }
                  indeterminate={
                    selected.length > 0 && selected.length !== lista.data.length
                  }
                  onChange={selectAll}
                />
              </th>
              {tableHeaders.map((item, index) => {
                return (
                  <th key={item.idHeader}>
                    <div style={{ position: 'relative' }}>
                      {item.nome}
                      <a
                        onClick={(e) =>
                          changeOrder(e, {
                            campo: item.idHeader,
                            ordem:
                              ordemAgenda.campo === item.idHeader
                                ? ordemAgenda.ordem === 1
                                  ? 2
                                  : 1
                                : 2,
                          })
                        }
                      >
                        <SequenceIcon />
                      </a>
                      {tableHeaders.length === index + 1 &&
                        updateFields.isLoading && <Spinner />}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr style={{ height: '16px' }}></tr>
            {tableData &&
              tableData.data &&
              tableData.data.map((item, index) => {
                const { first, last } = initialLetters(item.nomeGrupo);
                return (
                  <tr key={item.idGrupo}>
                    <td>
                      <CheckboxFlat
                        ariaLabel={`checkbox-${item.idGrupo}`}
                        name={`checkbox-name-${item.idGrupo}`}
                        tabIndex={'-1'}
                        checked={selected.indexOf(item.idItemAgenda) !== -1}
                        onChange={(e) => selectCheck(e, item.idItemAgenda)}
                      />
                    </td>
                    <td>
                      <EnterpriseCont>
                        <CorpIcon>{`${first}${last}`}</CorpIcon>
                        <ReactTooltip
                          id={`corp-name-tip-${item.idGrupo}`}
                          place={'bottom'}
                          tabIndex={'-1'}
                        >
                          <span style={{ fontSize: '14px' }}>
                            {item.nomeGrupo}
                          </span>
                        </ReactTooltip>
                        <CorpName
                          data-tip
                          data-for={`corp-name-tip-${item.idGrupo}`}
                          tabIndex={'-1'}
                        >
                          {item.nomeGrupo}
                        </CorpName>
                      </EnterpriseCont>
                    </td>
                    <td>
                      <EditPriCont>
                        {habilitacao && habilitacao.data && (
                          <PriorityPopover
                            checkEdition={habilitacao.data}
                            priorityVars={priorityVars}
                            varId={item.idPrioridade}
                            idAgenda={item.idItemAgenda}
                          />
                        )}
                      </EditPriCont>
                    </td>
                    <td>
                      <EditTaxContainer>
                        <InputTax
                          value={
                            item.percTaxaMediaAlterado !== undefined
                              ? item.percTaxaMediaAlterado
                              : item.percTaxaMedia
                          }
                          setUpdateTax={setUpdateTax}
                          data={item}
                          disabled={
                            habilitacao &&
                            !habilitacao.data.habilitacaoEdicaoGeral
                          }
                        />
                        <span style={{ marginLeft: '2px' }}>%</span>
                        <DetailsContainer>
                          <InfoContainer tabIndex={'-1'}>
                            {item.percTaxaMediaAlterado !== undefined && (
                              <>
                                <ReactTooltip
                                  id={`tax-tip-${item.idGrupo}`}
                                  tabIndex={'-1'}
                                >
                                  <span style={{ fontSize: '12px' }}>
                                    Taxa média original: <br />
                                  </span>
                                  <span style={{ fontSize: '14px' }}>
                                    {item.percTaxaMedia}%
                                  </span>
                                </ReactTooltip>
                                <div style={{ width: '18px', height: '18px' }}>
                                  <InfoIcon
                                    data-tip
                                    data-for={`tax-tip-${item.idGrupo}`}
                                    tabIndex={'-1'}
                                  />
                                </div>
                              </>
                            )}
                          </InfoContainer>
                        </DetailsContainer>
                      </EditTaxContainer>
                    </td>
                    <td>{currency(item.vlrVopMesAnterior || 0)}</td>
                    <td>
                      <EditAgendaCont>
                        <InputAgendaValue
                          value={
                            item.vlrAgendaAlterado !== undefined
                              ? item.vlrAgendaAlterado
                              : item.vlrAgenda
                          }
                          setUpdateAgendaValue={setUpdateAgendaValue}
                          data={item}
                          tabId={index}
                          disabled={
                            habilitacao &&
                            !habilitacao.data.habilitacaoEdicaoGeral
                          }
                        />
                      </EditAgendaCont>
                    </td>
                    <td>{currency(item.vlrSpread)}</td>
                    <td>
                      <UltimaOpCont>
                        {!item.ultimaDataOperacaoConvenio ? (
                          <RedText>não operou</RedText>
                        ) : (
                          <span>{date(item.ultimaDataOperacaoConvenio)}</span>
                        )}
                        <DetailsContainer>
                          <InfoContainer tabIndex={'-1'}>
                            {item.ultimaDataOperacaoPlataforma && (
                              <>
                                <ReactTooltip
                                  id={`op-date-tip-${item.idGrupo}`}
                                  tabIndex={'-1'}
                                >
                                  <span style={{ fontSize: '12px' }}>
                                    Última operação <br /> na plataforma: <br />
                                  </span>
                                  <span style={{ fontSize: '14px' }}>
                                    {date(item.ultimaDataOperacaoPlataforma)}
                                  </span>
                                </ReactTooltip>
                                <div style={{ width: '18px', height: '18px' }}>
                                  <InfoIcon
                                    data-tip
                                    data-for={`op-date-tip-${item.idGrupo}`}
                                    tabIndex={'-1'}
                                  />
                                </div>
                              </>
                            )}
                          </InfoContainer>
                        </DetailsContainer>
                        <RecoverPopover tabIndex={'-1'} data={item} />
                      </UltimaOpCont>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Container>
      <Footer>
        <NWPagination
          totalPages={tableData && tableData.totalPages}
          page={skip}
          changePage={({ page }) => {
            actions.setSkip(page);
          }}
          place="center"
        />
      </Footer>
    </>
  );
};

import React from 'react';
import styled, { css, keyframes } from 'styled-components/macro';

const MarqueeKeys = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
`;

const Wrapper = styled.div`
  max-width: 105px;
  max-height: 24px;
  overflow: hidden;
  border-radius: 100px;
  ${({ bgcolor }) =>
    bgcolor &&
    css`
      background-color: ${bgcolor};
    `}
`;

const Marquee = styled.div`
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;

  ${({ scrollOn }) =>
    scrollOn &&
    css`
      animation: ${MarqueeKeys} 20s linear infinite;
    `}
`;

const Paragraph = styled.p`
  display: inline-block;
  color: ${({ theme, color }) => color || theme.newtheme.color.cinza6};
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  letter-spacing: ${({ theme }) => theme.newtheme.font.caption.espacamento};
  font-weight: ${({ fontWeigth, theme }) =>
    fontWeigth || theme.newtheme.font.caption.peso};
  font-size: ${({ theme }) => theme.newtheme.font.caption.tamanho};
  padding: 0 0.7rem;
  min-width: 100px;
  text-align: center;
  line-height: 24px;
  vertical-align: text-bottom;
`;

export const TagScrollText = ({ bgcolor, textColor, scrollOn, children }) => {
  return (
    <Wrapper bgcolor={bgcolor}>
      <Marquee scrollOn={scrollOn}>
        {scrollOn ? (
          <>
            <Paragraph fontWeigth="500" color={textColor}>
              {children}
            </Paragraph>
            <Paragraph fontWeigth="500" color={textColor}>
              {children}
            </Paragraph>
          </>
        ) : (
          <Paragraph fontWeigth="500" color={textColor}>
            {children}
          </Paragraph>
        )}
      </Marquee>
    </Wrapper>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import ScaleLoader from 'react-spinners/ScaleLoader';
import styled, { css } from 'styled-components/macro';

import { Caption } from 'components/FinParagraph';
import { Wrapper } from 'components/FinWrappers';

import { finTheme } from 'finTheme';

const LoaderOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: block;
  position: ${({ position }) => position || 'fixed'};
  top: 0;
  left: 0;
  content: '';
  z-index: ${({ theme }) => theme.newtheme.zIndex.loader};

  ${({ color, theme }) =>
    color === 'preto' &&
    css`
      background-color: ${theme.newtheme.overlay.preto};
    `};

  ${({ color, theme }) =>
    color === 'branco' &&
    css`
      background-color: ${theme.newtheme.overlay.branco};
    `};

  ${({ color }) =>
    color === 'transparent' &&
    css`
      background-color: 'transparent';
    `};
`;

const LoaderContainer = styled.div`
  position: fixed;
  margin: 0 auto;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Loading = ({ loading, onlyLoader, color, position, info }) => {
  if (loading)
    return !onlyLoader ? (
      <LoaderOverlay data-testid="loader" color={color} position={position}>
        <LoaderContainer>
          <ScaleLoader
            color={finTheme.newtheme.color.azulPrimario}
            loading={true}
          />
          {info && <Caption>{info || 'Carregando...'}</Caption>}
        </LoaderContainer>
      </LoaderOverlay>
    ) : (
      <ScaleLoader
        color={finTheme.newtheme.color.azulPrimario}
        loading={true}
      />
    );
  return '';
};

export const LoadingInfo = ({ loading }) => {
  return (
    loading && (
      <Wrapper tipo="column">
        <Loading loading={loading} onlyLoader />
        <Caption>Carregando...</Caption>
      </Wrapper>
    )
  );
};

Loading.propTypes = {
  color: PropTypes.oneOf(['preto', 'branco', 'transparent']),
  loading: PropTypes.bool.isRequired,
  onlyLoader: PropTypes.bool,
  info: PropTypes.string,
};

Loading.defaultProps = {
  color: 'branco',
  onlyLoader: false,
};

export const MESSAGES = {
  participar:
    'Você realmente gostaria de participar? Será realizado o download das notas fiscais para sua análise, e você estará participando desta oferta',
  excluir: 'Você deseja desistir da ',
  download:
    'Você realmente gostaria de fazer o download ?, ao faze-lo automaticamente estará participando desta oferta',
  erroBordero:
    'Selecione ao menos 1 título para prosseguir! Caso você não concorde com nenhum sacado, terá que desistir da operação...',
  aceiteProposta: 'Você realmente gostaria de aceitar esta proposta ?',
  confirmarPagamento: 'Você realmente gostaria de confirmar esse pagamento ?',
  pocDocumentos: 'Ver documentos do empreendedor',
  habilitado:
    'Você informou que este empreendedor está habilitado para operar com você. Caso não esteja, clique no botão abaixo.',
  pendente:
    'Se este empreendedor já está habilitado para operar com você, informe-o clicando no botão abaixo.',
  excluirCondicionante: 'Confirmar exclusão ?',
  concluirCondicionante: 'Confirmar conclusão ?',
  enviarCondicionante: 'Confirmar envio ?',
  cancelarCondicionante: 'Confirmar cancelamento ?',
  notFound: 'Nenhum documento encontrado',
  resumoEmpty: 'Sem informação para este tipo de oferta',
  campanha: {
    1: 'Campanha ativada com sucesso. Bons Negócios!',
    2: 'Campanha substituída com sucesso. Bons Negócios!',
    3: 'Não identificamos nenhuma campanha com este código. Verifique se não houve nenhum erro de digitação e tente novamente!',
    4: 'Infelizmente esta campanha já foi finalizada.',
    5: 'Esta campanha já está ativa em sua conta.',
    6: 'Ops... infelizmente você não vai conseguir participar dessa campanha promocional! Mas, não fique triste! Surgirão novas oportunidades para você.',
  },
  permissoes: {
    solicitar: {
      text1:
        'Você gostaria de solicitar acesso aos documentos do empreendedor $$$$ ?',
      text2:
        'Com isso, você poderá analisar e habilitá-lo para realizar operações com você.',
    },
    aceitarAnalise: {
      text1: 'O empreendedor $$$$ solicitou uma análise de crédito para você!',
      text2:
        'Ao aceitar, você terá acesso aos documentos cadastrais para análise de crédito e possivelmente habilitá-lo. Bons negócios!',
    },
    recusarAnalise: {
      text1: 'O empreendedor $$$$ solicitou uma análise de crédito para você!',
      text2:
        'Ao aceitar, você terá acesso aos documentos cadastrais para análise de crédito e possivelmente habilitá-lo. Bons negócios!',
    },
  },
};

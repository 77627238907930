import React from 'react';

import { Card } from '../Comps/Card';
import * as StylesMetricas from './styles.metricas';
// import { NWDropdown } from '../../MinhaRede/Comps/Dropdown';
import { FaturamentoMensal } from './FaturamentoMensal';
import { FaturamentoDiario } from './FaturamentoDiario';
import { Tab, ItemTab, Item } from '../Comps/Tab';
import { ConcentracaoSacado } from './ConcetracaoSacado';

export function Metricas() {
  const [tabActive, setTabActive] = React.useState('faturametoMensal');

  return (
    <Card largura="852px" direction="column">
      {/* <StylesMetricas.Flex
        justify="flex-end"
        style={{
          position: 'absolute',
          top: '18px',
          right: '62px',
          zIndex: '9',
          width: '120px',
        }}
      >
        <NWDropdown.Select>
          <NWDropdown.Option>2022</NWDropdown.Option>
          <NWDropdown.Option>2021</NWDropdown.Option>
        </NWDropdown.Select>
      </StylesMetricas.Flex> */}

      <Tab>
        <ItemTab isActive={tabActive === 'faturametoMensal'}>
          <Item onClick={() => setTabActive('faturametoMensal')}>
            Faturamento Mensal
          </Item>
        </ItemTab>
        <ItemTab isActive={tabActive === 'faturamentoDiario'}>
          <Item onClick={() => setTabActive('faturamentoDiario')}>
            Faturamento Médio Diário
          </Item>
        </ItemTab>
        <ItemTab isActive={tabActive === 'concetracaoSacado'}>
          <Item onClick={() => setTabActive('concetracaoSacado')}>
            Curva ABC
          </Item>
        </ItemTab>
      </Tab>

      <StylesMetricas.Flex direction="column" style={{ height: '350px' }}>
        {tabActive === 'faturametoMensal' && (
          <StylesMetricas.WrapperChart>
            <FaturamentoMensal />
          </StylesMetricas.WrapperChart>
        )}

        {tabActive === 'faturamentoDiario' && (
          <StylesMetricas.WrapperChart>
            <FaturamentoDiario />
          </StylesMetricas.WrapperChart>
        )}

        {tabActive === 'concetracaoSacado' && (
          <StylesMetricas.WrapperChart>
            <ConcentracaoSacado />
          </StylesMetricas.WrapperChart>
        )}
      </StylesMetricas.Flex>
    </Card>
  );
}

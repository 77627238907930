import React from 'react';
import { formatCnpjCpf, currency } from 'helpers/format';

import { capitalizeWords } from 'helpers/string';
import { useParams } from 'react-router-dom';
import { useRenderResponseData } from 'hooks/useRenderResponseData';
import { ChartRedeSocios } from './Comps/ChartEndividamento';
import { TituloChartSocios } from './Comps/TitleHead';

import { NWTable } from './Comps/Table';
import { useGetSocios, useListSocios } from './hooks';

import {
  TitleSocio,
  CpfCnpjSocio,
  Flex,
  Bullet,
  Tag,
  SubTitle,
} from './style.layout';

import { SocioItem } from './Comps/SocioItem';
import { SkeletonHeading, SkeletonTable } from './Comps/Skeleton';

export function PageSocios() {
  const { idGrupo } = useParams();
  const [currentNode, setCurrentNode] = React.useState({
    cpfcnpj: null,
    color: '#000000',
  });

  const { data, isLoading, error } = useGetSocios({ idGrupo });
  const response = useListSocios({ idGrupo, cpfcnpj: currentNode.cpfcnpj });

  const { PlaceNoResult, PlaceLoader, PlaceError } = useRenderResponseData({
    data,
    isLoading,
    error,
  });

  const withData = data && !Array.isArray(data) ? data : null;

  return (
    <div style={{ minHeight: '500px', marginTop: '3rem' }}>
      <PlaceNoResult />
      <PlaceLoader />
      <PlaceError />

      {withData && (
        <>
          <TituloChartSocios />
          <ChartRedeSocios setCurrentNode={setCurrentNode} data={data} />
        </>
      )}

      <Flex justify="flex-start" style={{ gap: '12px', margin: '2rem 0' }}>
        {response.isLoading && <SkeletonHeading />}
        {response.data && response.data.cabecalho && (
          <>
            <Flex style={{ gap: '12px', width: 'auto' }}>
              <Bullet
                bgColor={currentNode.color}
                icon={response.data.cabecalho.tipo}
              />
              <Flex column align="flex-start" style={{ width: 'auto' }}>
                <TitleSocio>
                  {capitalizeWords(
                    response.data.cabecalho.empresa ||
                      response.data.cabecalho.nomeSocio
                  )}
                </TitleSocio>
                <CpfCnpjSocio>
                  {response.data.cabecalho.documento.indexOf('*') !== -1
                    ? response.data.cabecalho.documento
                    : formatCnpjCpf(response.data.cabecalho.documento)}
                </CpfCnpjSocio>
              </Flex>
            </Flex>
            {response.data.cabecalho.nomeSocio &&
              response.data.cabecalho.funcao && (
                <Tag>{response.data.cabecalho.funcao}</Tag>
              )}
          </>
        )}
      </Flex>
      {response.data && response.data.lista.length > 0 && (
        <SubTitle>Histórico Empresas</SubTitle>
      )}
      <Flex style={{ margin: '1rem 0' }}>
        {response.isLoading && <SkeletonTable />}
        {response.data && response.data.lista.length > 0 && (
          <NWTable.Table>
            <NWTable.Thead>
              <NWTable.Tr>
                <NWTable.Th borderRadiusLeft largura="200px">
                  EMPRESA
                </NWTable.Th>
                <NWTable.Th largura="120px">DATA FUNDAÇÃO</NWTable.Th>
                <NWTable.Th largura="60px">STATUS</NWTable.Th>
                <NWTable.Th largura="100px">CAPITAL SOCIAL</NWTable.Th>
                <NWTable.Th borderRadiusRight largura="75px">
                  FATURAMENTO
                </NWTable.Th>
              </NWTable.Tr>
            </NWTable.Thead>
            <NWTable.Tbody>
              {response.data.lista.map((res) => {
                return (
                  <NWTable.Tr key={res.documento}>
                    <NWTable.Td>
                      <SocioItem data={res} />
                    </NWTable.Td>
                    <NWTable.Td>{res.dataEntradaFundacao}</NWTable.Td>
                    <NWTable.Td>
                      {res.status && <Tag>{res.status}</Tag>}
                    </NWTable.Td>
                    <NWTable.Td>
                      {res.capitalSocial ? currency(res.capitalSocial) : `-`}
                    </NWTable.Td>
                    <NWTable.Td>
                      {res.faturamento ? currency(res.faturamento) : `-`}
                    </NWTable.Td>
                  </NWTable.Tr>
                );
              })}
            </NWTable.Tbody>
          </NWTable.Table>
        )}
      </Flex>
    </div>
  );
}

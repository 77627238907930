import React, { createElement, useMemo } from 'react';
import { useTooltip } from '@nivo/tooltip';

export const CustomBar = (props) => {
  const { showTooltipFromEvent, hideTooltip } = useTooltip();

  const { bar, tooltip } = props;
  const { x, y, width, height, color, data } = bar;

  const renderTooltip = useMemo(
    () => () => createElement(tooltip, { ...bar, ...data }),
    [tooltip, bar, data]
  );

  const dNegative = `M${x},${y} l0,0 l-${width},0 l-0,${height} c0,${6} ${width},${6} ${width} 0`;
  const dPositive = `M${x},${y} c0,-${6} ${width},-${6} ${width} 0 l0,${height} l-${width},0 l0,-${height}`;

  const vencidos = [
    'curtoPrazoVencido',
    'longoPrazoVencido',
    'medioPrazoVencido',
  ];

  return (
    <path
      d={(() => {
        if (vencidos.indexOf(data.id) !== -1) {
          return dNegative;
        }
        return dPositive;
      })()}
      fill={color}
      onMouseEnter={(event) => showTooltipFromEvent(renderTooltip(), event)}
      onMouseMove={(event) => showTooltipFromEvent(renderTooltip(), event)}
      onMouseLeave={() => hideTooltip()}
    />
  );
};

import React from 'react';
import { useParams } from 'react-router-dom';
import { useRenderResponseData } from 'hooks/useRenderResponseData';
import {
  ChartEndividamento,
  ChartCategoria,
  ChartPrazo,
} from './Comps/ChartEndividamento';
import { useChartEndividamento } from './hooks/useChartEndividamento';

import {
  TitleEndividamento,
  TituloChartCategorias,
  TituloChartPrazos,
} from './Comps/TitleHead';

export function PageEndividamento() {
  const { idGrupo } = useParams();
  const { data, isLoading, error } = useChartEndividamento({ idGrupo });

  const { PlaceNoResult, PlaceLoader, PlaceError } = useRenderResponseData({
    data,
    isLoading,
    error,
  });

  const withData = data && data.data;

  return (
    <div style={{ minHeight: '500px', marginTop: '3rem' }}>
      <PlaceNoResult />
      <PlaceLoader />
      <PlaceError />
      {withData && (
        <>
          <TitleEndividamento data={withData} />
          <ChartEndividamento data={withData} />
          <TituloChartCategorias />
          <ChartCategoria data={withData} />
          <TituloChartPrazos />
          <ChartPrazo data={withData} />
        </>
      )}
    </div>
  );
}

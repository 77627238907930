import React from 'react';
import { ReactComponent as ImageNotFound } from 'assets/images/undraw_real_time_analytics_re_yliv.svg';
import { Title, Text, WrapperImage, Container } from './style.placeholder';

export function NWNoResultFound() {
  return (
    <Container>
      <WrapperImage>
        <ImageNotFound />
      </WrapperImage>
      <Title>Nenhum resultado encontrado</Title>
      <Text>
        Não foi possível encontrar o que você pesquisou. Tente pesquisar
        novamente
      </Text>
    </Container>
  );
}

import React from 'react';

import { Caption } from 'components/FinParagraph';
import { currency } from 'helpers/format';
import { dotToComma } from 'components/Formatter';
import { CardRowStyle, CaptionCustom } from '../style';

export const TipoAntecipacao = ({ data }) => {
  return (
    <>
      <CardRowStyle>
        <Caption>Bruto Solicitado</Caption>
        <CaptionCustom>{currency(data.nota.face)}</CaptionCustom>
      </CardRowStyle>
      <CardRowStyle>
        <Caption>Títulos Recusados</Caption>
        <CaptionCustom>{currency(data.bordero.valorRecusado)}</CaptionCustom>
      </CardRowStyle>
      <CardRowStyle>
        <Caption>Bruto Proposta</Caption>
        <CaptionCustom>{currency(data.nota.vop)}</CaptionCustom>
      </CardRowStyle>
      <CardRowStyle>
        <Caption>PMP (dias)</Caption>
        <CaptionCustom>{dotToComma(data.nota.pmp.toFixed(2))}</CaptionCustom>
      </CardRowStyle>
      <CardRowStyle>
        <Caption>Taxa (%)</Caption>
        <CaptionCustom>{dotToComma(data.nota.taxa)}</CaptionCustom>
      </CardRowStyle>
      <CardRowStyle>
        <Caption>Float (dias)</Caption>
        <CaptionCustom>{data.nota.vFloat}</CaptionCustom>
      </CardRowStyle>
      <CardRowStyle>
        <Caption>Acertos</Caption>
        <CaptionCustom>{currency(data.nota.acertos)}</CaptionCustom>
      </CardRowStyle>
      <CardRowStyle>
        <Caption>Líquido</Caption>
        <CaptionCustom>{currency(data.nota.liquido)}</CaptionCustom>
      </CardRowStyle>
    </>
  );
};

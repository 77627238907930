import React from 'react';

import styled, { css } from 'styled-components/macro';
import PropTypes from 'prop-types';
import { rgba, darken } from 'polished';
import ReactTooltip from 'react-tooltip';
import requiredIf from 'react-required-if';

import { finTheme } from 'finTheme';

const SIZES = {
  large: {
    width: '30px',
    height: '30px',
  },
  medium: {
    width: '24px',
    height: '24px',
  },
  small: {
    width: '18px',
    height: '18px',
  },
};

const STATUS = {
  primaryFlat: {
    color: finTheme.newtheme.color.branco,
    bgColor: finTheme.newtheme.color.azulPrimario,
  },
  primary: {
    color: finTheme.newtheme.color.azulPrimario,
    bgColor: finTheme.newtheme.color.branco,
  },
  default: {
    color: finTheme.newtheme.color.cinza4,
    bgColor: finTheme.newtheme.color.branco,
  },
  success: {
    color: finTheme.newtheme.color.success,
    bgColor: finTheme.newtheme.color.branco,
  },
  warning: {
    color: finTheme.newtheme.color.alert,
    bgColor: finTheme.newtheme.color.branco,
  },
  error: {
    color: finTheme.newtheme.color.error,
    bgColor: finTheme.newtheme.color.branco,
  },
};

const ButtonStyle = styled.button.attrs(({ ariaLabel, id, tooltipText }) =>
  tooltipText !== ''
    ? {
        'aria-label': ariaLabel,
        'data-tip': '',
        'data-for': `info-${id}`,
      }
    : {
        'aria-label': ariaLabel,
      }
)`
  background-color: ${({ theme }) => theme.newtheme.color.branco};
  outline: none;
  transition: background 125ms cubic-bezier(0.66, 0, 0, 1);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  width: ${({ size }) => SIZES[size].width};
  height: ${({ size }) => SIZES[size].height};

  &:hover {
    background-color: ${({ status }) => darken(0.1, STATUS[status].bgColor)};
  }

  ${({ status }) =>
    status &&
    css`
      background-color: ${STATUS[status].bgColor};
      & > span svg {
        stroke: ${STATUS[status].color};
        & path {
          stroke: ${STATUS[status].color};
        }
      }
    `}

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) =>
      theme.newtheme.buttons.disabled.background};
    &:hover {
      background-color: ${({ theme }) =>
        theme.newtheme.buttons.disabled.background};
    }
  }
`;

const ViewStyle = styled.span.attrs(({ ariaLabel, id, tooltipText }) =>
  tooltipText !== ''
    ? {
        'aria-label': ariaLabel,
        'data-tip': '',
        'data-for': `info-${id}`,
      }
    : {
        'aria-label': ariaLabel,
      }
)`
  background-color: ${({ theme }) => theme.newtheme.color.branco};
  outline: none;
  transition: background 125ms cubic-bezier(0.66, 0, 0, 1);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  border: none;
  border-radius: 50%;
  padding: 0;
  width: ${({ size }) => SIZES[size].width};
  height: ${({ size }) => SIZES[size].height};

  ${({ hoverActive }) =>
    hoverActive &&
    css`
      &:hover {
        background-color: ${({ theme }) =>
          rgba(theme.newtheme.color.cinza2, 0.5)};
      }
    `}

  ${({ status }) =>
    status &&
    css`
      background-color: ${STATUS[status].bgColor};
      & > span svg {
        stroke: ${STATUS[status].color};
        & path {
          stroke: ${STATUS[status].color};
        }
      }
    `}

    ${({ disabled, theme }) =>
    disabled &&
    css`
      cursor: not-allowed;
      background-color: ${theme.newtheme.buttons.disabled.background};
      &:hover {
        background-color: ${theme.newtheme.buttons.disabled.background};
      }
    `}
`;

const Wrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => SIZES[size].width};
  height: ${({ size }) => SIZES[size].height};
`;

export const ButtonCircle = (props) => {
  const {
    ariaLabel,
    size,
    tipo,
    onClick,
    tooltipText,
    tooltipPlace,
    tooltipDisable,
    id,
    hoverActive,
    children,
    status,
    disabled,
  } = props;
  const renderTootip = () => {
    return (
      tooltipText !== '' &&
      !tooltipDisable && (
        <ReactTooltip
          id={`info-${id}`}
          wrapper="span"
          place={tooltipPlace}
          type="dark"
          effect="solid"
        >
          {tooltipText}
        </ReactTooltip>
      )
    );
  };
  if (tipo === 'button') {
    return (
      <>
        {renderTootip()}
        <ButtonStyle
          ariaLabel={ariaLabel}
          onClick={onClick}
          id={id}
          tooltipText={tooltipText}
          size={size}
          status={status}
          disabled={disabled}
        >
          <Wrapper size={size}>{children}</Wrapper>
        </ButtonStyle>
      </>
    );
  }
  if (tipo === 'view') {
    return (
      <>
        {renderTootip()}
        <ViewStyle
          ariaLabel={ariaLabel}
          id={id}
          tooltipText={tooltipText}
          hoverActive={hoverActive}
          size={size}
          status={status}
          disabled={disabled}
        >
          <Wrapper size={size}>{children}</Wrapper>
        </ViewStyle>
      </>
    );
  }
  return null;
};

ButtonCircle.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  status: PropTypes.oneOf([
    'primary',
    'primaryFlat',
    'default',
    'success',
    'warning',
    'error',
  ]),
  onClick: PropTypes.func,
  tipo: PropTypes.oneOf(['button', 'view']),
  tooltipText: PropTypes.string,
  tooltipPlace: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  tooltipDisable: PropTypes.bool,
  id: requiredIf(PropTypes.string, (props) => props.tooltipText !== ''),
  hoverActive: PropTypes.bool,
};

ButtonCircle.defaultProps = {
  disabled: false,
  size: 'medium',
  onClick: null,
  tipo: 'button',
  tooltipText: '',
  tooltipPlace: 'bottom',
  tooltipDisable: false,
  hoverActive: false,
  status: 'default',
};

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
// import { device } from 'devices';
// import { flexUnit } from 'mixins';

import PicUser from 'components/FinPicUser';
import UserInitial from 'components/FinUserInitial';
import { SeparadorVertical } from 'components/FinSeparador';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  position: relative;
  cursor: pointer;
  & > span:nth-of-type(1) {
    color: gray;
  }
  & > span:nth-of-type(2) {
    color: red;
  }
`;

const Online = styled.div`
  position: absolute;
  bottom: 3px;
  right: 0px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.newtheme.color.branco};
  background-color: ${({ theme, online }) =>
    online
      ? theme.newtheme.color.success
      : theme.newtheme.color.vermelhoPrimario};
  display: ${({ viewUserOnline }) =>
    viewUserOnline ? 'inline-block' : 'none'};
`;

const UserInfo = forwardRef(
  (
    {
      name,
      image,
      infoDisabled,
      pbgColor,
      colorText,
      online,
      viewUserOnline,
      size,
      onClick,
    },
    ref
  ) => {
    return (
      <Wrapper onClick={onClick} ref={ref}>
        <Online online={online} viewUserOnline={viewUserOnline} />
        {image ? (
          <PicUser image={image} size={size} />
        ) : (
          <UserInitial
            colorText={colorText}
            pbgColor={pbgColor}
            texto={name}
            size={size}
          />
        )}
        {!infoDisabled && (
          <>
            <SeparadorVertical />
            <span>Olá, &nbsp;</span>
            <span>{name}</span>
          </>
        )}
      </Wrapper>
    );
  }
);

UserInfo.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  onClick: PropTypes.func,
  infoDisabled: PropTypes.bool,
  pbgColor: PropTypes.string,
  colorText: PropTypes.string,
  online: PropTypes.any,
  viewUserOnline: PropTypes.bool,
  size: PropTypes.oneOf(['padrao', 'small', 'medium', 'large']),
};

UserInfo.defaultProps = {
  name: 'Richard S. Vignelli.',
  onClick: null,
  infoDisabled: false,
  online: false,
  viewUserOnline: false,
  size: 'padrao',
};

export default UserInfo;

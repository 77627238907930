import React, { useContext } from 'react';

import { currency } from 'helpers/format';

import { ReactComponent as IconUp } from 'assets/images/icon-trendup-outline.svg';
import { ReactComponent as IconDown } from 'assets/images/icon-trenddown-outiline.svg';
import { ReactComponent as IconArrows } from 'assets/images/icon-arrows-center.svg';

import { Flex, ContentIdicator, Value, Label } from './styles.match';
import { FinMatchContext } from '../PageFinmatch';

export function RegionIndicator() {
  const { data } = useContext(FinMatchContext);

  return (
    <Flex>
      <ContentIdicator>
        <IconUp />
        <Value>{currency(data.informacoes_Match.faturamento)}</Value>
        <Label>Faturamento Anual</Label>
      </ContentIdicator>

      <ContentIdicator>
        <IconDown />
        <Value>{currency(data.informacoes_Match.endividamento)}</Value>
        <Label>Endividamento</Label>
      </ContentIdicator>

      <ContentIdicator>
        <IconArrows />
        <Value>Não Classificado</Value>
        <Label>Concentração</Label>
      </ContentIdicator>
    </Flex>
  );
}

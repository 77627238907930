import React from 'react';
import { finTheme } from 'finTheme';

import { icons } from 'assets/icones/finplaceIcons';
import TagStatus from './TagStatus';

const TagBloqueado = ({ id }) => {
  return (
    <TagStatus
      viewTooltip
      id={`bloqueado-${id}`}
      tooltipText="Bloqueado"
      vbox="-5 -2 24 24"
      bgcolor={finTheme.newtheme.color.cinza3}
      textColor={finTheme.newtheme.color.branco}
      iconColor={finTheme.newtheme.color.branco}
      icon={icons.banFlat}
    >
      Bloqueado
    </TagStatus>
  );
};

export default TagBloqueado;

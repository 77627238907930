import React from 'react';

import { Caption } from 'components/FinParagraph';

import { StringToSvg } from 'helpers/svgFiles/StringToSvg';
import feather from 'feather-icons/dist/icons.json';
import { finTheme } from 'finTheme';
import { Wrapper } from 'components/FinWrappers';
import { CustonWrapper, List, Item, Link, WithOutLink } from './styles';

export const ItemLink = ({ to, icon, disabled, children }) => (
  <Item>
    <Link exact to={to} disabled={disabled}>
      <Caption>{children}</Caption>
      {icon && (
        <Wrapper padding="0" pwidth="20px" pheight="23px" pmargin="0 10px">
          <StringToSvg
            width="100%"
            height="100%"
            stroke={finTheme.newtheme.color.azulPrimario}
          >
            {feather['chevron-right']}
          </StringToSvg>
        </Wrapper>
      )}
    </Link>
  </Item>
);

export const ItemWithoutLink = ({ onclick, icon, disabled, children }) => (
  <Item>
    <WithOutLink onClick={onclick} disabled={disabled}>
      <Caption>{children}</Caption>
      {icon && (
        <Wrapper padding="0" pwidth="20px" pheight="23px" pmargin="0 10px">
          <StringToSvg
            width="100%"
            height="100%"
            stroke={finTheme.newtheme.color.azulPrimario}
          >
            {feather['chevron-right']}
          </StringToSvg>
        </Wrapper>
      )}
    </WithOutLink>
  </Item>
);

const Breadcrumb = ({ children }) => {
  return (
    <CustonWrapper pwidth="100%">
      <List>{children}</List>
    </CustonWrapper>
  );
};

export default Breadcrumb;

/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { createActions, createReducer } from 'reduxsauce';
import { call, put, take, fork, cancel, cancelled } from 'redux-saga/effects';
import axios from 'axios';

import { getUserData } from 'services/getUserData';
import { Creators as uiToasterActions } from 'store/ducks/uiToaster';

import CONFIG from '../../config';

const { CancelToken } = axios;

/**
 * Criando action types & creators
 */

export const { Types, Creators } = createActions({
  apiPesquisaRequest: ['value', 'page'],
  apiPesquisaSuccess: ['data', 'totalPages', 'totalRecords', 'loadMore'],
  apiPesquisaError: [],
  apiPesquisaCancel: [],
});

/**
 * Criando os reducer handlers
 */

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: false,
  isLoadingMore: false,
  totalPages: 0,
  totalRecords: 0,
};

const apiSetRequest = (state = INITIAL_STATE, action) => {
  console.log(action);

  if (action.page) {
    return {
      ...state,
      isLoadingMore: true,
    };
  }

  return {
    ...state,
    isLoading: true,
    data: [],
    error: false,
  };
};

const apiSetSuccess = (state = INITIAL_STATE, action) => {
  console.log(action);

  if (action.loadMore) {
    return {
      ...state,
      isLoadingMore: false,
      data: [...state.data, ...action.data],
      totalPages: action.totalPages,
      totalRecords: action.totalRecords,
    };
  }

  return {
    isLoading: false,
    data: action.data,
    error: false,
    isLoadingMore: false,
    totalPages: action.totalPages,
    totalRecords: action.totalRecords,
  };
};

const apiSetError = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    ...state,
    isLoading: false,
    data: [],
    error: true,
  };
};

const apiSetCancel = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: [],
    error: false,
    isLoadingMore: false,
    totalPages: 0,
    totalRecords: 0,
  };
};

/**
 * Criando reducer
 */

export default createReducer(INITIAL_STATE, {
  [Types.API_PESQUISA_REQUEST]: apiSetRequest,
  [Types.API_PESQUISA_SUCCESS]: apiSetSuccess,
  [Types.API_PESQUISA_ERROR]: apiSetError,
  [Types.API_PESQUISA_CANCEL]: apiSetCancel,
});

/**
 * Sagas
 */

function* sagaGetAsynResult(params, endpoint, page) {
  const source = CancelToken.source();
  const url = `${CONFIG.API_REMODELING_URL}${endpoint}`;
  try {
    const response = yield axios.post(url, params, {
      cancelToken: source.token,
    });

    if (response.data.data) {
      yield put(
        Creators.apiPesquisaSuccess(
          response.data.data,
          response.data.totalPages,
          response.data.totalRecords,
          page
        )
      );
    } else {
      yield put(Creators.apiPesquisaSuccess([], 0, 0, page));
    }
  } catch (error) {
    console.log(error);
    yield put(uiToasterActions.uiToasterMessage(error, 'error'));
    yield put(Creators.apiPesquisaError());
  } finally {
    if (yield cancelled()) {
      console.log('cancel pesquisa');
      source.cancel();
    }
  }
}

export function* sagaPesquisa(action) {
  const user = yield call(getUserData);

  if (user) {
    const params = {
      filter: {
        idconvenio: user.idEmpresa,
        search: action.value.trim().toLowerCase(),
      },
      size: 10,
      skip: action.page || 1,
    };

    const task = yield fork(
      sagaGetAsynResult,
      params,
      '/business-api/api/matching/convenio/pesquisa',
      action.page
    );

    yield take(Types.API_PESQUISA_CANCEL);
    yield cancel(task);
  } else {
    yield put(Creators.apiPesquisaError());
  }
}

import React from 'react';
import { Router } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { ReactQueryDevtools } from 'react-query/devtools';

import { AuthProvider } from 'react-oidc-context';
import { oidcConfig } from 'auth/oidcConfig';

import { ThemeProvider } from 'styled-components/macro';
import { Provider } from 'react-redux';

import { finTheme } from 'finTheme';
import 'config/ReactotronConfig';

import toast, { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider, QueryCache } from 'react-query';

import { store, persistor } from 'store';

import { PersistGate } from 'redux-persist/integration/react';
import { Routes, LoggedArea } from 'routes';
import { history } from './components/History';

import GlobalStyle from './globalStyles';

// import './helpers/autenticacao/interceptor';
// import './helpers/requisicoes/interceptor';

import './pwa';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => toast.error(`Something went wrong: ${error.message}`),
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  if (window.location.pathname !== '/signin-callback')
    localStorage.setItem(
      'historico_navegacao',
      `${window.location.pathname}${
        window.location.search && `/${window.location.search}`
      }`
    );
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <ConnectedRouter history={history}>
            <ThemeProvider theme={finTheme}>
              <Toaster position="top-center" />
              <GlobalStyle />
              <div className="App">
                <Router history={history}>
                  <AuthProvider {...oidcConfig}>
                    <LoggedArea>
                      <Routes />
                    </LoggedArea>
                  </AuthProvider>
                </Router>
              </div>
            </ThemeProvider>
          </ConnectedRouter>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;

import React from 'react';
import styled, { css } from 'styled-components/macro';
import { useField } from 'formik';
import CurrencyInput from 'react-currency-input';
// import IMask from 'imask';
// import media from 'styled-media-query';

import { rgba } from 'polished';
import ImageIco from 'images/iconsSvg/info.svg';
import { TooltipDefault } from 'components/TooltipDefault';

import { Theme } from '../../theme';
import { maskPhone } from '../../utils/formatValues';

export const Input = styled.input`
  background-color: ${Theme.white};
  border: 1px solid ${Theme.gray2};
  border-radius: 2px;
  align-items: center;
  text-align: left;
  box-shadow: none;
  display: inline-flex;
  font-size: 0.9rem;
  height: 2.5rem;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.375em - 2px);
  padding-left: calc(0.625em - 2px);
  padding-right: calc(0.625em - 2px);
  padding-top: calc(0.375em - 2px);
  position: relative;
  vertical-align: top;
  width: 100%;
  color: ${Theme.gray5};
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:active {
    border-color: ${Theme.primary};
    box-shadow: ${`0 0 0 0.125em ${rgba(Theme.primary, 0.4)}`};
  }
  &:focus {
    border-color: ${Theme.primary};
    box-shadow: ${`0 0 0 0.125em ${rgba(Theme.primary, 0.4)}`};
  }
  &:hover {
    border-color: ${Theme.primary};
  }
`;

export const TextArea = styled.textarea`
  background-color: ${Theme.white};
  border: 2px solid ${Theme.gray2};
  border-radius: 2px;
  align-items: center;
  text-align: left;
  box-shadow: none;
  display: inline-flex;
  font-size: 0.9rem;
  height: 6rem;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.375em - 2px);
  padding-left: calc(0.625em - 2px);
  padding-right: calc(0.625em - 2px);
  padding-top: calc(0.375em - 2px);
  position: relative;
  vertical-align: top;
  width: 100%;
  color: ${Theme.gray5};
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:active {
    border-color: ${Theme.primary};
    box-shadow: ${`0 0 0 0.125em ${rgba(Theme.primary, 0.4)}`};
  }
  &:focus {
    border-color: ${Theme.primary};
    box-shadow: ${`0 0 0 0.125em ${rgba(Theme.primary, 0.4)}`};
  }
  &:hover {
    border-color: ${Theme.primary};
  }
`;

export const SpanCustomSufixo = styled.span`
  font-style: italic;
  position: absolute;
  bottom: 35px;
  right: 0;
  color: #333;
  box-sizing: border-box;
  padding: 5px;
  opacity: 0.5;
`;

export const InputCurrency = styled(CurrencyInput)`
  background-color: ${Theme.white};
  border: 2px solid ${Theme.gray2};
  border-radius: 2px;
  align-items: center;
  text-align: left;
  box-shadow: none;
  display: inline-flex;
  font-size: 0.9rem;
  height: 2.5rem;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.375em - 2px);
  padding-left: calc(0.625em - 2px);
  padding-right: calc(0.625em - 2px);
  padding-top: calc(0.375em - 2px);
  position: relative;
  vertical-align: top;
  width: 100%;
  color: ${Theme.gray5};
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:active {
    border-color: ${Theme.primary};
    box-shadow: ${`0 0 0 0.125em ${rgba(Theme.primary, 0.4)}`};
  }
  &:focus {
    border-color: ${Theme.primary};
    box-shadow: ${`0 0 0 0.125em ${rgba(Theme.primary, 0.4)}`};
  }
  &:hover {
    border-color: ${Theme.primary};
  }
`;

const StatusBar = styled.span`
  display: block;
  position: relative;
  margin-top: -1px;
  height: 2px;
  width: 100%;
  &:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    transform: scaleX(0);
    transition: transform 0.25s ease, transform 0.25s ease;
  }
`;

const Label = styled.span`
  color: ${({ color }) => color};
  font-weight: ${({ fontweight }) => fontweight};
  ${({ marginbottom }) =>
    marginbottom &&
    css`
      margin-bottom: ${marginbottom};
    `}
  .tooltip_match {
    float: right;
    left: 5px;
    top: 2px;
  }
`;

export const InputMainMaterial = styled.input.attrs(
  (props) =>
    props.placeholder && {
      placeholder: props.placeholder,
    }
)`
  position: relative;
  padding-left: 0;
  padding-bottom: 5px;
  background: hsla(0, 0%, 100%, 0);
  font-size: 16px;
  font-weight: 400;
  color: #333;
  line-height: 1.35;
  border: 0;
  border-bottom: 1px solid #ccc;
  height: 30px;
  border-radius: 0;
  width: 100%;
  outline: 0;
  font-weight: 400;
`;

export const InputMainTextAreaMaterial = styled.textarea`
  padding-left: 0;
  padding-bottom: 5px;
  background: hsla(0, 0%, 100%, 0);
  font-size: 18px;
  font-weight: 400;
  color: #333;
  line-height: 1.35;
  border: 0;
  border-bottom: 1px solid #ccc;
  height: 6rem;
  border-radius: 0;
  width: 100%;
  outline: 0;
  font-weight: 400;
`;

const Field = styled.div`
  position: relative;
  width: ${({ largura }) => largura || '50%'};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 98px;
  &:hover ${StatusBar}:after {
    background-color: rgba(0, 0, 0, 0.45);
    transform: scaleX(1);
  }
  & ${InputMainMaterial}:focus + ${StatusBar}:after {
    background-color: ${Theme.primary};
    transform: scaleX(1);
  }
  ${({ error }) =>
    error &&
    css`
      & ${InputMainMaterial}:focus + ${StatusBar}:after {
        background-color: ${Theme.orange};
        transform: scaleX(1);
      }
    `}
  & ${InputMainTextAreaMaterial}:focus + ${StatusBar}:after {
    background-color: ${Theme.primary};
    transform: scaleX(1);
  }
  ${({ error }) =>
    error &&
    css`
      & ${InputMainTextAreaMaterial}:focus + ${StatusBar}:after {
        background-color: ${Theme.orange};
        transform: scaleX(1);
      }
    `}
`;

const InputFeedback = styled.div`
  color: ${({ cor }) => cor || Theme.orange};
  font-weight: 400;
  margin-top: 5px;
`;

export const InputMaterial = ({
  label,
  value,
  handleBlur,
  id,
  error,
  handleChange,
  errormessage,
  largura,
  format,
  setFieldValue,
  colorLabel,
  weightLabel,
  placeholder,
  // handleFocus,
  handleClick,
  tooltip,
  sufixo,
}) => {
  const handleCuston = (e) => {
    // const decimalRegex = /\d{0,2}(\.\d{1,2})?/;
    handleChange(e);

    setFieldValue(id, maskPhone(e.target.value));
  };

  return (
    <Field error={error} largura={largura}>
      <Label
        color={colorLabel || Theme.gray3}
        fontweight={weightLabel || '400'}
        marginbottom="0.5rem"
      >
        {label}
        {tooltip && (
          <TooltipDefault
            style={{ float: 'right', left: '5px', top: '2px' }}
            position="left"
            data={tooltip}
          >
            <img src={ImageIco} width="18" alt="Icon" />
          </TooltipDefault>
        )}
      </Label>

      <InputMainMaterial
        id={id}
        onChange={format ? handleCuston : handleChange}
        value={value}
        onBlur={handleBlur}
        placeholder={placeholder}
        onClick={handleClick}
        sufixo={sufixo}
      />
      {sufixo && <SpanCustomSufixo>{sufixo}</SpanCustomSufixo>}

      <StatusBar />
      {error && <InputFeedback>{errormessage}</InputFeedback>}
    </Field>
  );
};

export const InputTextAreaMaterial = ({
  label,
  value,
  handleBlur,
  id,
  error,
  handleChange,
  errormessage,
  largura,
}) => {
  return (
    <Field error={error} largura={largura}>
      <Label color={Theme.gray3} fontweight="400" marginbottom="0.5rem">
        {label}
      </Label>
      <InputMainTextAreaMaterial
        id={id}
        onChange={handleChange}
        value={value}
        onBlur={handleBlur}
      />
      <StatusBar />
      {error && <InputFeedback>{errormessage}</InputFeedback>}
    </Field>
  );
};

/**
 * useField create components
 */
export const TextInputFormik = ({
  label,
  custonChange,
  custonBlur,
  ...props
}) => {
  const [field, meta /* ,helpers */] = useField(props);
  // const { setValue, setError } = helpers;
  const { onBlur, onChange } = field;
  const custonHandleBlur = (e) => {
    onBlur(e);
    custonBlur();
  };
  const custonHandleChange = (e) => {
    onChange(e);
    custonChange();
  };
  const getCustonFunctions = () => {
    const funcs = {};
    if (custonChange && custonBlur) {
      funcs.onBlur = custonHandleBlur;
      funcs.onChange = custonHandleChange;
      Object.keys(field)
        .filter((res) => res !== 'onBlur' && res !== 'onChange')
        // eslint-disable-next-line no-return-assign
        .forEach((r) => (funcs[r] = field[r]));
      return funcs;
    }
    if (custonBlur) {
      funcs.onBlur = custonHandleBlur;
      Object.keys(field)
        .filter((res) => res !== 'onBlur')
        // eslint-disable-next-line no-return-assign
        .forEach((r) => (funcs[r] = field[r]));
      return funcs;
    }
    if (custonChange) {
      funcs.onChange = custonHandleChange;
      Object.keys(field)
        .filter((res) => res !== 'onChange')
        // eslint-disable-next-line no-return-assign
        .forEach((r) => (funcs[r] = field[r]));
      return funcs;
    }

    return Object.keys(funcs).length > 0 ? funcs : false;
  };
  const custonFields = getCustonFunctions() ? getCustonFunctions() : field;
  // console.log(custonFields);
  return (
    <Field error={meta.error} largura={props.largura}>
      <Label htmlFor={props.id || props.name}>{label}</Label>
      <InputMainMaterial {...custonFields} {...props} />
      <StatusBar />
      {meta.touched && meta.error ? (
        <InputFeedback>{meta.error}</InputFeedback>
      ) : null}
      {props.isLoading && (
        <InputFeedback cor={Theme.gray3}>Buscando...</InputFeedback>
      )}
      {props.error && <InputFeedback>{props.data}</InputFeedback>}
    </Field>
  );
};
/**
 * *********************end custonFormik*****************************************
 */
const TextareaMainInput = InputMainMaterial.withComponent('textarea');

export const TextareaInputFormik = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <Field error={meta.error} largura={props.largura}>
      <Label htmlFor={props.id || props.name}>{label}</Label>
      <TextareaMainInput {...field} {...props} rows={5} />
      <StatusBar />
      {meta.touched && meta.error ? (
        <InputFeedback>{meta.error}</InputFeedback>
      ) : null}
    </Field>
  );
};

const INITIAL_STATE = {
  modalActions: [],
  modalTitle: '',
  modalContent: '',
  modalSingleBtn: false,
  modalLabelBtn1: 'SIM',
  modalLabelBtn2: 'NÃO',
  modalTextarea: false,
  actionValue: [],
  showModal: false,
  openMenu: false,
  showNotification: false,
  contentNotification: '',
  timeNotification: 3000,
  valuesNotification: [],
  isLoading: false,
  scrollingBody: true,
  notifyData: [],
  showModalCondicionante: false,
  paginacao: [],
  termosData: [],
  termosIsLoading: null,
  termosError: null,
};

const reducerUI = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SHOW_MODAL':
      //console.log(action);
      return {
        ...state,
        showModal: true,
        modalTitle: action.title,
        modalContent: action.content,
        modalActions: action.modalActions,
        modalLabelBtn1: action.modalLabelBtn1,
        modalLabelBtn2: action.modalLabelBtn2,
        modalSingleBtn: action.modalSingleBtn,
        modalTextarea: action.modalTextarea,
        //scrollingBody: false,
      };
    case 'CLOSE_MODAL':
      //console.log(action);
      return {
        ...state,
        showModal: false,
        //scrollingBody: true,
      };
    case 'OPEN_MENU':
      return {
        openMenu: true,
      };
    case 'CLOSE_MENU':
      return {
        openMenu: false,
      };
    case 'SHOW_NOTIFICATION':
      //console.log(action);
      return {
        ...state,
        showNotification: true,
      };
    case 'CLOSE_NOTIFICATION':
      //console.log(action);
      return {
        ...state,
        showNotification: false,
      };
    case 'CONTENT_NOTIFICATION':
      //console.log(action);
      return {
        ...state,
        contentNotification: action.data,
        timeNotification: action.timeNotification,
        valuesNotification: action.values,
      };
    case 'SHOW_LOADING':
      return {
        ...state,
        isLoading: true,
        //scrollingBody: false,
      };
    case 'HIDE_LOADING':
      return {
        ...state,
        isLoading: false,
        //scrollingBody: true,
      };
    case 'OFF_SCROLLING_BODY':
      return {
        ...state,
        scrollingBody: false,
      };
    case 'ON_SCROLLING_BODY':
      return {
        ...state,
        scrollingBody: true,
      };
    case 'INSERT_DATA_NOTIFY':
      //console.log(action);
      return {
        ...state,
        notifyData: action.data,
      };
    case 'REMOVE_DATA_NOTIFY':
      //console.log(action);
      return {
        ...state,
        notifyData: action.data,
      };
    case 'CLEAR_UI':
      return {
        modalActions: [],
        modalTitle: '',
        showModal: false,
        menuOpen: false,
        showNotification: false,
        contentNotification: '',
      };
    case 'SHOW_MODAL_CONDIC':
      return {
        ...state,
        showModalCondicionante: action.data,
      };
    case 'LOAD_PAGINACAO':
      //console.log(action);
      return {
        ...state,
        paginacao: action.data,
      };
    case 'LOAD_PAGINACAO_CLEAR':
      return {
        ...state,
        paginacao: [],
      };
    case 'GET_TERMOS_REQUEST':
      return {
        ...state,
        termosIsLoading: true,
      };
    case 'GET_TERMOS_SUCCESS':
      return {
        ...state,
        termosData: action.data,
        termosIsLoading: false,
        termosError: false,
      };
    case 'GET_TERMOS_FAILURE':
      return {
        ...state,
        termosData: action.data,
        termosIsLoading: false,
        termosError: true,
      };
    default:
      return state;
  }
};

export default reducerUI;

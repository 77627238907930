import React, { useState, useContext } from 'react';

import { ModalCuston } from 'components/FinModalCuston';
import { Loader } from 'pages/Operacoes/Elements/Loader';
import FeedbackInfo from 'components/FinFeedbackInfo';
import { Head } from './Head';
import { Container } from './style.bordero';

import { useFetchListaBordero } from '../queries/useFetchListaBordero';
import { CardBordero } from './CardBordero';

import { OperacoesContext } from '../provider';

export const ModalBordero = () => {
  const [idOpen, setIdOpen] = useState([]);
  const [state, actions] = useContext(OperacoesContext);

  const {
    modal: { values },
  } = state;

  const { data, isLoading, error } = useFetchListaBordero({
    idBordero: values.idBordero,
  });
  const isExistData = !isLoading && !!data && !!data.sacados;

  const propsOpen = { idOpen, setIdOpen };

  const sacados = isExistData ? data.sacados : {};

  return (
    <ModalCuston
      open={true}
      width="1088px"
      title=""
      scrolling="true"
      onClose={() => actions.removeModalActive()}
      header={<Head rules={values.rules} />}
      ppadding="0"
      margin="5vh 0 10vh 0"
    >
      <Container>
        {isLoading && <Loader />}
        {error && <FeedbackInfo tipo="error" />}
        {isExistData && Object.keys(data.sacados).length === 0 && (
          <FeedbackInfo tipo="empty" text="Conteúdo não disponível" />
        )}
        {isExistData &&
          sacados.map((item) => (
            <CardBordero {...propsOpen} data={item} key={item.idSacado} />
          ))}
      </Container>
    </ModalCuston>
  );
};

import React from 'react';

import * as CardStyle from './styles.cardtypes';

export function CardTypes({ label, icon, ...rest }) {
  return (
    <>
      <CardStyle.Wrapper {...rest}>
        <CardStyle.ContainerIcon {...rest}>{icon}</CardStyle.ContainerIcon>
        <CardStyle.CardLabel>{label}</CardStyle.CardLabel>
      </CardStyle.Wrapper>
    </>
  );
}

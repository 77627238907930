import React, { useContext } from 'react';

import { Flex, Text } from './styles.sobreempresa';
import { TitleSobre } from '../Comps/TitleHead';
import { PerfilContext } from '../PaginaFinCredit';

export function SobreEmpresa() {
  const { perfil } = useContext(PerfilContext);

  return perfil.data && perfil.data.sobre ? (
    <Flex
      column
      align="flex-start"
      style={{
        marginBottom: '48px',
      }}
    >
      <TitleSobre />
      <Flex
        column
        align="flex-start"
        style={{
          marginTop: '13px',
        }}
      >
        <Text>
          {perfil && perfil.data && perfil.data.sobre ? perfil.data.sobre : ''}
        </Text>
      </Flex>
    </Flex>
  ) : null;
}

import React from 'react';

import { PopoverContent } from './Popover';

import {
  PopoverTrigger,
  Popover,
  Option,
  ThreeDotsIcon,
  UndoBtn,
  UndoIcon,
  Label,
} from './recoverStyles';

// É melhor fazer um OUTRO POPOVER só para a seleção da EDIÇÃO DE PRIORIDADE EM MASSA. Assim, não há lógicas conflitantes, e o botão de salvar faz sentido.

import { useMutateRetornaValor } from './mutates/useRetornaValor';

export const RecoverPopover = ({ data }) => {
  const retornaValor = useMutateRetornaValor();
  return (
    <Popover tabIndex={'-1'}>
      <PopoverTrigger tabIndex={'-1'}>
        <ThreeDotsIcon />
      </PopoverTrigger>
      <PopoverContent>
        <Option>
          <UndoBtn
            onClick={() => retornaValor.mutate({ idAgenda: data.idItemAgenda })}
          >
            <UndoIcon />
          </UndoBtn>
          <Label>
            Recuperar <br /> Agenda Sugerida
          </Label>
        </Option>
      </PopoverContent>
    </Popover>
  );
};

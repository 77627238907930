import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components/macro';
import { useField } from 'formik';
import CurrencyInput from 'react-currency-input';
import NumberFormat from 'react-number-format';
import { rgba } from 'polished';

const NumberFormatStyle = styled(NumberFormat).attrs(
  (props) =>
    props.placeholder && {
      placeholder: props.placeholder,
    }
)`
  padding-left: 10px;
  font-size: 0.75rem;
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  letter-spacing: ${({ theme }) => theme.newtheme.font.caption.espacamento};
  font-weight: 400;
  color: #333;
  line-height: 1.35;
  height: 30px;
  width: calc(100% - 6px);
  outline: 0;
  font-weight: 400;
  background: ${({ theme }) => rgba(theme.newtheme.color.cinza1, 0.3)};
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 3px;

  &:focus {
    outline: none;
    box-shadow: ${({ theme }) =>
      `0 0 0 3px ${rgba(theme.newtheme.color.azulPrimario, 0.5)}`};
    border-color: ${({ theme }) => theme.newtheme.color.cinza2};
  }

  ${({ error }) =>
    error &&
    css`
      border: 1px solid ${({ theme }) => theme.newtheme.color.error};
      background: ${({ theme }) => rgba(theme.newtheme.color.error, 0.1)};
    `}
`;

export const InputLabelStyle = styled.span`
  color: ${({ theme, color }) => color || theme.newtheme.color.cinza5};
  letter-spacing: ${({ theme }) => theme.newtheme.font.caption.espacamento};
  font-weight: bold;
  font-size: ${({ theme }) => theme.newtheme.font.caption.tamanho};
  line-height: 24px;
  text-transform: uppercase;
  margin: ${({ margin }) => margin || '0 0 0.4rem 6px'};
  width: 100%;
  text-align: left;
`;

const InputWrapperLabelStyle = styled.span`
  margin-bottom: ${({ margin }) => margin || '0.4rem'};
  width: 100%;
`;

export const InputErrorStyle = styled.span`
  color: ${({ theme, color }) => color || theme.newtheme.color.error};
  letter-spacing: ${({ theme }) => theme.newtheme.font.overline.espacamento};
  font-weight: normal;
  font-size: ${({ theme }) => theme.newtheme.font.overline.tamanho};
  line-height: 13px;
  margin-top: 6px;
  text-transform: uppercase;
  position: absolute;
  top: ${({ label }) => (label ? `calc(30px + 32px)` : `30px`)};
  background-color: #fffaf0;
  width: 100%;
  padding: 0.5rem;
  z-index: 99;
`;

export const FieldStyle = styled.div`
  position: relative;
  width: ${({ largura }) => largura || '50%'};
  display: flex;
  flex-direction: ${({ direction }) => direction || 'column'};
  align-items: center;
`;

export const FinInputDate = forwardRef((props, ref) => {
  const {
    label,
    value,
    name,
    error,
    onValueChange,
    largura,
    colorLabel,
    direction,
    placeholder,
    disabled,
    onKeyDown,
    onBlur,
  } = props;
  return (
    <FieldStyle largura={largura} direction={direction}>
      {typeof label === 'string' ? (
        <InputLabelStyle color={colorLabel}>{label}</InputLabelStyle>
      ) : (
        label && <InputWrapperLabelStyle>{label}</InputWrapperLabelStyle>
      )}
      <NumberFormatStyle
        name={name}
        onValueChange={onValueChange}
        mask="_"
        value={value}
        ref={ref}
        format="##/##/####"
        placeholder={placeholder || 'DD/MM/AAAA'}
        error={error}
        disabled={disabled}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
      />
      {error && <InputErrorStyle label={label}>{error}</InputErrorStyle>}
    </FieldStyle>
  );
});

export const FinInputDateFormik = ({
  label,
  colorLabel,
  largura,
  direction,
  placeholder,
  onValueChange,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const changeValue = (formattedValue) => {
    setValue(formattedValue);
    if (onValueChange) {
      onValueChange(formattedValue);
    }
  };
  return (
    <FieldStyle largura={largura} direction={direction}>
      {typeof label === 'string' ? (
        <InputLabelStyle color={colorLabel}>{label}</InputLabelStyle>
      ) : (
        label && <InputWrapperLabelStyle>{label}</InputWrapperLabelStyle>
      )}
      <NumberFormatStyle
        {...field}
        {...props}
        onValueChange={({ formattedValue }) => changeValue(formattedValue)}
        mask="_"
        format="##/##/####"
        placeholder={placeholder || 'DD/MM/AAAA'}
        error={meta.touched && meta.error ? 1 : 0}
      />
      {meta.touched && meta.error ? (
        <InputErrorStyle label={label}>{meta.error}</InputErrorStyle>
      ) : null}
    </FieldStyle>
  );
};

export const InputCurrencyStyle = styled(CurrencyInput).attrs(
  (props) =>
    props.placeholder && {
      placeholder: props.placeholder,
    }
)`
  padding-left: 10px;
  font-size: 0.75rem;
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  letter-spacing: ${({ theme }) => theme.newtheme.font.caption.espacamento};
  font-weight: 400;
  color: #333;
  line-height: 1.35;
  height: 30px;
  width: calc(100% - 6px);
  outline: 0;
  font-weight: 400;
  background: ${({ theme }) => rgba(theme.newtheme.color.cinza1, 0.3)};
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 3px;

  &:focus {
    outline: none;
    box-shadow: ${({ theme }) =>
      `0 0 0 3px ${rgba(theme.newtheme.color.azulPrimario, 0.5)}`};
    border-color: ${({ theme }) => theme.newtheme.color.cinza2};
  }

  ${({ error }) =>
    error &&
    css`
      border: 1px solid ${({ theme }) => theme.newtheme.color.error};
      background: ${({ theme }) => rgba(theme.newtheme.color.error, 0.1)};
    `}
`;

export const FinInputCurrency = forwardRef((props, ref) => {
  const {
    label,
    value,
    name,
    error,
    onChangeEvent,
    largura,
    colorLabel,
    placeholder,
    aligntext,
    direction,
    disabled,
    className,
    onBlur,
    onFocus,
    onKeyDown,
  } = props;
  return (
    <FieldStyle largura={largura} direction={direction} className={className}>
      {typeof label === 'string' ? (
        <InputLabelStyle color={colorLabel}>{label}</InputLabelStyle>
      ) : (
        label && <InputWrapperLabelStyle>{label}</InputWrapperLabelStyle>
      )}
      <InputCurrencyStyle
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onFocus={onFocus}
        name={name}
        placeholder={placeholder}
        prefix="R$ "
        decimalSeparator=","
        thousandSeparator="."
        precision={2}
        onChangeEvent={onChangeEvent}
        value={value}
        ref={ref}
        aligntext={aligntext}
        error={error}
        disabled={disabled}
      />
      {error && <InputErrorStyle label={label}>{error}</InputErrorStyle>}
    </FieldStyle>
  );
});

export const FinInputCurrencyFormik = ({
  label,
  largura,
  colorLabel,
  placeholder,
  aligntext,
  direction,
  onChangeEvent,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const changeValue = (floatvalue) => {
    setValue(floatvalue);
    if (onChangeEvent) {
      onChangeEvent(floatvalue);
    }
  };
  return (
    <FieldStyle largura={largura} direction={direction}>
      {typeof label === 'string' ? (
        <InputLabelStyle color={colorLabel}>{label}</InputLabelStyle>
      ) : (
        label && <InputWrapperLabelStyle>{label}</InputWrapperLabelStyle>
      )}
      <InputCurrencyStyle
        {...field}
        {...props}
        placeholder={placeholder || 'R$ 0,00'}
        prefix="R$ "
        decimalSeparator=","
        thousandSeparator="."
        precision={2}
        onChangeEvent={(event, maskedvalue, floatvalue) =>
          changeValue(floatvalue)
        }
        aligntext={aligntext}
        error={meta.touched && meta.error ? 1 : 0}
      />
      {meta.touched && meta.error ? (
        <InputErrorStyle label={label}>{meta.error}</InputErrorStyle>
      ) : null}
    </FieldStyle>
  );
};

const InputStyle = styled.input.attrs(
  (props) =>
    props.placeholder && {
      placeholder: props.placeholder,
    }
)`
  padding-left: 10px;
  font-size: 0.75rem;
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  letter-spacing: ${({ theme }) => theme.newtheme.font.caption.espacamento};
  font-weight: 400;
  color: #333;
  line-height: 1.35;
  height: 30px;
  width: calc(100% - 6px);
  outline: 0;
  font-weight: 400;
  background: ${({ theme }) => rgba(theme.newtheme.color.cinza1, 0.3)};
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 3px;

  &:focus {
    outline: none;
    box-shadow: ${({ theme }) =>
      `0 0 0 3px ${rgba(theme.newtheme.color.azulPrimario, 0.5)}`};
    border-color: ${({ theme }) => theme.newtheme.color.cinza2};
  }

  ${({ error }) =>
    error &&
    css`
      border: 1px solid ${({ theme }) => theme.newtheme.color.error};
      background: ${({ theme }) => rgba(theme.newtheme.color.error, 0.1)};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background: ${({ theme }) => rgba(theme.newtheme.color.cinza4, 0.1)};
    `}
`;

export const FinInput = forwardRef((props, ref) => {
  const {
    label,
    value,
    name,
    error,
    onChange,
    onKeyPress,
    onBlur,
    onFocus,
    largura,
    colorLabel,
    placeholder,
    aligntext,
    direction,
    disabled,
    className,
    tabIndex,
  } = props;
  return (
    <FieldStyle largura={largura} direction={direction} className={className}>
      {typeof label === 'string' ? (
        <InputLabelStyle color={colorLabel}>{label}</InputLabelStyle>
      ) : (
        label && <InputWrapperLabelStyle>{label}</InputWrapperLabelStyle>
      )}
      <InputStyle
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
        onFocus={onFocus}
        value={value}
        ref={ref}
        aligntext={aligntext}
        error={error}
        disabled={disabled}
        tabIndex={tabIndex}
      />
      {error && <InputErrorStyle label={label}>{error}</InputErrorStyle>}
    </FieldStyle>
  );
});

export const FinInputFormik = ({
  label,
  largura,
  colorLabel,
  placeholder,
  aligntext,
  direction,
  onChangeEvent,
  value,
  handleChange,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const changeValue = (e) => {
    setValue(e.target.value);
    if (onChangeEvent) {
      onChangeEvent(e.target.value);
    }
  };
  return (
    <FieldStyle largura={largura} direction={direction}>
      {typeof label === 'string' ? (
        <InputLabelStyle color={colorLabel}>{label}</InputLabelStyle>
      ) : (
        label && <InputWrapperLabelStyle>{label}</InputWrapperLabelStyle>
      )}
      <InputStyle
        {...field}
        {...props}
        placeholder={placeholder || ''}
        onChange={(e) => changeValue(e)}
        aligntext={aligntext}
        error={meta.touched && meta.error ? 1 : 0}
      />
      {meta.touched && meta.error ? (
        <InputErrorStyle label={label}>{meta.error}</InputErrorStyle>
      ) : null}
    </FieldStyle>
  );
};

const InputTextStyle = styled.textarea.attrs(
  (props) =>
    props.placeholder && {
      placeholder: props.placeholder,
    }
)`
  padding-left: 10px;
  font-size: 0.75rem;
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  letter-spacing: ${({ theme }) => theme.newtheme.font.caption.espacamento};
  font-weight: 400;
  color: #333;
  line-height: 1.35;
  height: 30px;
  width: calc(100% - 6px);
  outline: 0;
  font-weight: 400;
  background: ${({ theme }) => rgba(theme.newtheme.color.cinza1, 0.3)};
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0.5rem;

  ${({ altura }) =>
    altura &&
    css`
      height: ${altura};
    `}

  &:focus {
    outline: none;
    box-shadow: ${({ theme }) =>
      `0 0 0 3px ${rgba(theme.newtheme.color.azulPrimario, 0.5)}`};
    border-color: ${({ theme }) => theme.newtheme.color.cinza2};
  }

  ${({ error }) =>
    error &&
    css`
      border: 1px solid ${({ theme }) => theme.newtheme.color.error};
      background: ${({ theme }) => rgba(theme.newtheme.color.error, 0.1)};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background: ${({ theme }) => rgba(theme.newtheme.color.cinza4, 0.1)};
    `}
`;

export const FinInputTextArea = forwardRef((props, ref) => {
  const {
    label,
    value,
    name,
    error,
    onChange,
    largura,
    altura,
    colorLabel,
    placeholder,
    aligntext,
    direction,
    disabled,
    className,
    onFocus,
  } = props;
  return (
    <FieldStyle largura={largura} direction={direction} className={className}>
      {typeof label === 'string' ? (
        <InputLabelStyle color={colorLabel}>{label}</InputLabelStyle>
      ) : (
        label && <InputWrapperLabelStyle>{label}</InputWrapperLabelStyle>
      )}
      <InputTextStyle
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        ref={ref}
        aligntext={aligntext}
        error={error}
        disabled={disabled}
        altura={altura}
        onFocus={onFocus}
      />
      {error && <InputErrorStyle label={label}>{error}</InputErrorStyle>}
    </FieldStyle>
  );
});

export const FinInputTextAreaFormik = ({
  label,
  largura,
  colorLabel,
  placeholder,
  aligntext,
  direction,
  onChangeEvent,
  altura,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const changeValue = (e) => {
    setValue(e.target.value);
    if (onChangeEvent) {
      onChangeEvent(e.target.value);
    }
  };
  return (
    <FieldStyle largura={largura} direction={direction}>
      {typeof label === 'string' ? (
        <InputLabelStyle color={colorLabel}>{label}</InputLabelStyle>
      ) : (
        label && <InputWrapperLabelStyle>{label}</InputWrapperLabelStyle>
      )}
      <InputTextStyle
        {...field}
        {...props}
        placeholder={placeholder || ''}
        onChange={(e) => changeValue(e)}
        aligntext={aligntext}
        error={meta.touched && meta.error ? 1 : 0}
        altura={altura}
      />
      {meta.touched && meta.error ? (
        <InputErrorStyle label={label}>{meta.error}</InputErrorStyle>
      ) : null}
    </FieldStyle>
  );
};

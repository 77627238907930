/* eslint-disable no-console */
import axios from 'axios';
import { put, call, select } from 'redux-saga/effects';
import { format } from 'date-fns';

import { getUserData } from 'services/getUserData';
import {
  loadDataOperacoesSuccess,
  loadDataOperacoesFailure,
  downloadOperacoesSuccess,
  downloadOperacoesFailure,
  desistirDataOperacoesSuccess,
  desistirDataOperacoesFailure,
  openChatRequestFailure,
  openChatRequestSuccess,
  loadListPocDocSuccess,
  loadListPocDocFailure,
  sendMessage,
  showLoading,
  hideLoading,
  showNotification,
  contentNotification,
  getOneOperationSuccess,
  getOneOperationFailure,
  loadResumoSucess,
  loadResumoFailure,
} from 'store/old_actions/actions';

// import { loadDataFinalizadosSuccess } from 'store/old_actions/actions';
// import { loadDataFinalizadosFailure } from 'store/old_actions/actions';

import { filtrarPregao } from './filtrarPregao';

import {
  getPregoes,
  getFinalizados,
  getOperacaoReasonCancel,
} from './selectors';

import config from '../../config';

export function* getOperacoes(action) {
  const reqData = {
    params: {
      idConvenio: action.idConvenio,
      idPessoaConvenio: action.idPessoaConvenio,
    },
  };
  // console.log(reqData);
  try {
    const res = yield axios.post(
      `${config.API_BASE_URL}/api/operacao/pregao-operacoes`,
      null,
      reqData
    );
    // console.log('load operacao request: ' + JSON.stringify(res.data));
    yield put(loadDataOperacoesSuccess(res.data));
  } catch (err) {
    console.log(err);
    yield put(loadDataOperacoesFailure(err.response));
  }
}

export function* downloadBorderos(axios, action) {
  try {
    // const a = config.API_BASE_URL;
    // console.log(a);

    const url = `https://hom-storage-api.azurewebsites.net/api/NFe/download?idConvenio=${action.idConvenio}&idOperacao=${action.idOperacao}`;
    console.log(url);

    // const url = 'https://hom-storage-api.azurewebsites.net/api/NFe/download?idConvenio=1&idOperacao=117'

    const res = yield axios({
      url,
      method: 'GET',
      responseType: 'blob',
    });

    console.log(`download request: ${res.data}`);
    yield put(downloadOperacoesSuccess(res.data));

    const reqData2 = {
      idCedente: 0,
      idConvenio: action.idConvenio,
      idPessoaConvenio: action.idPessoaConvenio,
      idProposta: 0,
      idOperacao: 0,
      idBordero: 0,
      idTitulo: 0,
      cnpjCedente: 0,
      cnpjSacado: 0,
      cnpjConvenio: 0,
    };

    // console.log('Load operacoes: ' + JSON.stringify(reqData2));

    try {
      const res1 = yield axios.post(
        `${config.API_BASE_URL}/api/operacao/pregao-operacoes`,
        reqData2
      );
      yield put(loadDataOperacoesSuccess(res1.data));

      // console.log(res.headers)
      // console.log(res.data)

      // let fileName = res.headers["content-disposition"].split("filename=")[1];
      // const effectiveFileName = (fileName === undefined ? "arquivo.zip" : fileName);

      // console.log(effectiveFileName)

      /* const data = state => state.operacoes.dataBordero;
      const bord = yield select(data);

      console.log(bord)


      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'OP-'+action.idOperacao+'.zip'); //or any other extension
      document.body.appendChild(link);
      link.click(); */

      window.location.assign(
        'https://homstoragefiles.blob.core.windows.net/cedente-8/bordero.zip'
      );

      /*
    const downloadUrl = "https://homstoragefiles.blob.core.windows.net/cedente-8/bordero.zip";
    const downloadFrame = document.createElement("iframe");
    downloadFrame.setAttribute('src',downloadUrl);
    downloadFrame.setAttribute('class',"screenReaderText");
    document.body.appendChild(downloadFrame); */
    } catch (err) {
      console.log(err);
      yield put(loadDataOperacoesFailure(err.response));
    }
  } catch (err) {
    console.log(err);
    yield put(downloadOperacoesFailure(err.response));
  }
}

/** ***************************** Desistir operacao ******************************** */
export function* desistirOperacao(action) {
  try {
    // const headers = yield call(getUserAuthenticated);

    const user = yield call(getUserData);

    // Get Reason cancel data
    const reasonCancel = yield select(getOperacaoReasonCancel);

    if (reasonCancel === '') {
      throw new Error('Motivo é obrigatório!!!');
    } else {
      yield put(showLoading());

      const reqData = {
        params: {
          idOperacao: action.value,
          idConvenio: user.idEmpresa,
          idPessoaConvenio: user.idUsuario,
          obs: reasonCancel,
        },
      };

      // console.log(JSON.stringify(reqData));

      // Request data
      const res = yield axios.post(
        `${config.API_BASE_URL}/api/operacao/desiste-convenio`,
        null,
        reqData
      );

      // console.log(JSON.stringify(res.data));

      yield put(desistirDataOperacoesSuccess(res.data));

      yield put(sendMessage('MESSAGE_CLOSE_MODAL_DELETE'));

      yield put({
        type: 'LOAD_DATA_PREGOES_REQUEST',
      });
    }
  } catch (err) {
    console.log(err);
    yield put(desistirDataOperacoesFailure(err.response));
    yield put(contentNotification(`Mensagem: ${err.message}`, 2000));
    yield put(showNotification());
    yield put(hideLoading());
  }
}

/** ***************************** Load Filtros operacao ******************************** */
export function* loadFiltro(action) {
  // console.log(action);
  yield call(
    filtrarPregao,
    action.orderBy,
    action.target,
    action.quantidade,
    action.page,
    action.filters
  );
}

/** ************************ Download Bordero Operacao ******************************* */
export function* newDownloadBodero(action) {
  try {
    const dataUser = yield call(getUserData);

    // console.log(dataUser);

    yield put(showLoading());

    const reqData = {
      params: {
        idOperacao: action.idOperacao,
        idConvenio: dataUser.idEmpresa,
        idPessoaConvenio: dataUser.idUsuario,
      },
    };

    // Download link borderô
    let pregoes = yield select(getPregoes);

    console.log(pregoes);

    if (action.typeUrl === 'filter') {
      pregoes = yield select(getFinalizados);
    }

    const pregao = pregoes.data.filter((res2) => {
      console.log(res2.idOperacao);
      return res2.idOperacao.toString() === action.idOperacao.toString();
    });

    const linkUrl = pregao[0].link;

    // console.log(linkUrl);

    if (linkUrl) {
      const a = document.createElement('a');
      // a.target = '_blank';
      a.href = linkUrl;
      a.download = linkUrl.substr(linkUrl.lastIndexOf('/') + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } /* else {
      throw new Error('Erro durante Download!!');
    } */

    // Post api download
    const res = yield axios.post(
      `${config.API_BASE_URL}/api/operacao/download`,
      null,
      reqData
    );
    // console.log(res.data);
    yield put(downloadOperacoesSuccess(res.data));

    /** ****Somente atualiza pagina pregoes e operacoes *********** */
    if (action.typeUrl === 'andamento-convenio') {
      yield put({
        type: 'LOAD_DATA_PREGOES_REQUEST',
        dataInicial: format(new Date(), 'yyyy-MM-dd'),
        typeUrl: 'andamento-convenio',
      });
    } else {
      yield put({
        type: 'LOAD_DATA_PREGOES_REQUEST',
        dataInicial: format(new Date(), 'yyyy-MM-dd'),
      });
    }

    // Notification
    if (linkUrl) {
      yield put(
        contentNotification(
          'Download das notas fiscais realizado com sucesso!',
          2000
        )
      );
      yield put(showNotification());
    } else {
      yield put(contentNotification('Oferta iniciada com sucesso!', 2000));
      yield put(showNotification());
    }
  } catch (err) {
    console.log(err);
    yield put(hideLoading());
    yield put(
      contentNotification(
        `Erro!!! Name: ${err.name}Message :${err.message}`,
        2000
      )
    );
    yield put(showNotification());
  }
}

/** ***************************** Open chat ******************************** */
export function* openchat(action) {
  try {
    // const headers = yield call(getUserAuthenticated);

    const reqData = {
      params: {
        idOperacao: action.idOperacao,
      },
    };

    // console.log(JSON.stringify(reqData));

    // Request data
    const res = yield axios.post(
      `${config.API_BASE_URL}/api/chat/inicia-convenio`,
      null,
      reqData
    );

    // console.log(JSON.stringify(res.data));

    yield put(openChatRequestSuccess(res.data));

    window.open(
      `https://meet.jit.si/Finplace-${action.idOperacao}`,
      'Chat Finplace',
      'height=600,width=700'
    );
  } catch (err) {
    console.log(err);
    yield put(openChatRequestFailure(err.response));
    yield put(
      contentNotification(
        `Erro!!! Name: ${err.name}Message :${err.message}`,
        2000
      )
    );
    yield put(showNotification());
  }
}

/** ***************************** List POC documentos ******************************** */
export function* listPocDocs(action) {
  try {
    // const headers = yield call(getUserAuthenticated);

    const reqData = {
      params: {
        idCedente: action.idCedente,
      },
    };

    // console.log(JSON.stringify(reqData));

    // Request data
    const res = yield axios.post(
      `${config.API_BASE_URL}/api/documento/lista-convenio`,
      null,
      reqData
    );

    // console.log(JSON.stringify(res.data));

    yield put(loadListPocDocSuccess(res.data));
  } catch (err) {
    console.log(err);
    yield put(loadListPocDocFailure(err.response));
    yield put(
      contentNotification(
        `Erro!!! Name: ${err.name}Message :${err.message}`,
        2000
      )
    );
    yield put(showNotification());
  }
}

/** ***************************** Get one operation ******************************** */
export function* getOneOperation(action) {
  const dataUser = yield call(getUserData);

  const reqData = {
    params: {
      idConvenio: dataUser.idEmpresa,
      idPessoaConvenio: dataUser.idUsuario,
      idOperacao: action.idOperacao,
    },
  };

  // console.log('request: ' + JSON.stringify(reqData));
  try {
    const res = yield axios.post(
      `${config.API_BASE_URL}/api/pregao/operacao`,
      null,
      reqData
    );
    // console.log(res.data);
    yield put(getOneOperationSuccess(res.data));
  } catch (err) {
    console.log(err);
    yield put(getOneOperationFailure(err.response));
  }
}

/** ***************************** Get resume ******************************** */
export function* getResumeOper(action) {
  const dataUser = yield call(getUserData);

  const reqData = {
    params: {
      idConvenio: dataUser.idEmpresa,
      idCedente: action.idCedente,
      idOperacao: action.idOperacao,
    },
  };

  // console.log('request: ' + JSON.stringify(reqData));
  try {
    const res = yield axios.post(
      `${config.API_BASE_URL}/api/operacao/resumo`,
      null,
      reqData
    );
    // console.log(res.data);
    yield put(loadResumoSucess(res.data));
  } catch (err) {
    console.log(err);
    yield put(loadResumoFailure(err.response));
  }
}

import React from 'react';

import { ReactComponent as IconX } from 'assets/images/icon-x.svg';
import { useQueryState } from 'hooks/useQueryState';
import * as Modal from './style.modalfiltros';
import { Form } from './Form';

import { MinhaRedeContext } from '../PaginaMinhaRede';

export function ModalFiltros() {
  const { getQueries, setQueries } = useQueryState();
  const [filters, setFilters] = React.useState(getQueries());

  const { setOpenFilters } = React.useContext(MinhaRedeContext);

  const changeFilter = (e, f) => {
    setFilters((state) => ({ ...state, [e.target.name]: f }));
  };

  const changeFilterCheckbox = (e) => {
    const field = e.target.name.split('-');

    if (e.target.checked) {
      setFilters((state) => {
        const fieldState = state[field[0]] || [];

        return {
          ...state,
          [field[0]]: [...fieldState, field[1]],
        };
      });
    } else {
      setFilters((state) => {
        const removed = state[field[0]].filter((item) => item !== field[1]);
        if (removed.length === 0) {
          const { [field[0]]: value, ...rest } = state;
          return rest;
        }
        return { ...state, [field[0]]: removed };
      });
    }
  };

  const onSubmit = () => {
    const values = filters;

    if (Number(values.faturamento_ate === 0)) {
      delete values.faturamento_ate;
    }

    if (Number(values.faturamento_de === 0)) {
      delete values.faturamento_de;
    }

    setQueries({ ...values, skip: 1 });
    setOpenFilters(false);
  };

  const clear = () => {
    setFilters({});
    setQueries({});
    setOpenFilters(false);
  };

  return (
    <Modal.Shadow>
      <Modal.Container>
        <Modal.Header>
          <Modal.Title>Filtros</Modal.Title>
          <Modal.ButtonSquare onClick={() => setOpenFilters(false)}>
            <IconX />
          </Modal.ButtonSquare>
        </Modal.Header>
        <Modal.Body>
          <Modal.Content>
            <Form
              filters={filters}
              setFilters={setFilters}
              changeFilter={changeFilter}
              changeFilterCheckbox={changeFilterCheckbox}
            />
          </Modal.Content>
        </Modal.Body>
        <Modal.Footer>
          <Modal.ButtonSecondary onClick={clear}>Limpar</Modal.ButtonSecondary>
          <Modal.ButtonPrimary onClick={onSubmit}>
            Aplicar Filtros
          </Modal.ButtonPrimary>
        </Modal.Footer>
      </Modal.Container>
    </Modal.Shadow>
  );
}

/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { put, call } from 'redux-saga/effects';

import { getUserData } from 'services/getUserData';
import {
  loadDataCardsSuccess,
  loadDataCardsFailure,
  loadDataChartsSuccess,
  loadDataChartsFailure,
  showNotification,
  contentNotification,
} from 'store/old_actions/actions';

import config from '../../config';

export function* loadCardsData(action) {
  try {
    // const headers = yield call(getUserAuthenticated);

    const user = yield call(getUserData);

    // console.log(headers);

    const requestData = {
      params: {
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
      },
    };

    // console.log(JSON.stringify(requestData));

    // Request data
    const res = yield axios.post(
      `${config.API_BASE_URL}/api/dashboard/convenio-cards`,
      null,
      requestData
    );

    // console.log(JSON.stringify(res.data));

    yield put(loadDataCardsSuccess(res.data));
  } catch (err) {
    console.log(err);
    yield put(loadDataCardsFailure(err.response));
    yield put(
      contentNotification(
        `Erro!!! Name: ${err.name}Message :${err.message}`,
        2000
      )
    );
    yield put(showNotification());
  }

  /* const reqData = {
    idEmpresa: action.idEmpresa,
  };
  console.log('Load Cards :' + JSON.stringify(reqData));
  try {
    const res = yield axios.post(
      config.API_BASE_URL + '/api/dashboard/convenio-cards',
      reqData
    );
    console.log(res.data);
    yield put(loadDataCardsSuccess(res.data));
  } catch (err) {
    console.log(err);
    yield put(loadDataCardsFailure(err.response));
  } */
}

export function* loadChartData(action) {
  try {
    // const headers = yield call(getUserAuthenticated);

    const user = yield call(getUserData);

    const requestData = {
      params: {
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
      },
    };

    // console.log(JSON.stringify(requestData));

    // Request data
    const res = yield axios.post(
      `${config.API_BASE_URL}/api/dashboard/convenio-chart2`,
      null,
      requestData
    );

    // console.log(JSON.stringify(res.data));

    yield put(loadDataChartsSuccess(res.data));
  } catch (err) {
    console.log(err);
    yield put(loadDataChartsFailure(err.response));
    yield put(
      contentNotification(
        `Erro!!! Name: ${err.name}Message :${err.message}`,
        2000
      )
    );
    yield put(showNotification());
  }

  /* const reqData = {
    idEmpresa: action.idEmpresa,
  };
  console.log('Load Charts');
  try {
    const res = yield axios.post(
      config.API_BASE_URL + '/api/dashboard/convenio-chart1',
      reqData
    );
    console.log(res.data);
    yield put(loadDataChartsSuccess(res.data));
  } catch (err) {
    console.log(err);
    yield put(loadDataChartsFailure(err.response));
  } */
}

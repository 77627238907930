import React from 'react';

import {
  PaginationButton,
  ArrowTitle,
  Arrow,
  PaginationLinkBack,
} from './styles';

export const PreviousButton = ({ onclick, subtract, visibility }) => (
  <PaginationButton visibility={visibility}>
    <PaginationLinkBack onClick={(e) => onclick(e, subtract)}>
      <Arrow />
      <ArrowTitle>Anterior</ArrowTitle>
    </PaginationLinkBack>
  </PaginationButton>
);

import React from 'react';
import { NavLink } from 'react-router-dom';

import styled, { css } from 'styled-components/macro';
import { darken } from 'polished';
import { Wrapper } from 'components/FinWrappers';

const NavTab = styled(Wrapper)`
  box-shadow: inset 0 -1px 0 ${({ theme }) => theme.newtheme.color.cinza2};
  overflow: hidden;
  padding: ${({ ppadding }) => ppadding || '0 2rem'};
  justify-content: space-between;
  width: 100%;
  min-height: 50px;
  margin-top: 2rem;
`;

const UnderlineNav = styled.ul`
  display: flex;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
`;

export const ItemTab = styled.li`
  display: flex;
  text-align: -webkit-match-parent;
`;

export const ItemLink = styled(NavLink)`
  padding: 8px 16px;
  font-size: ${({ theme }) => theme.newtheme.font.subtitulo2.tamanho};
  line-height: 30px;
  color: ${({ theme }) => theme.newtheme.color.cinza6};
  text-align: center;
  white-space: nowrap;
  background-color: initial;
  border: 0;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 0.36s ease-in;

  white-space: nowrap;
  text-decoration: none;

  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none;
    border-bottom-color: ${({ theme }) => theme.newtheme.color.cinza3};
    outline: 1px dotted transparent;
    outline-offset: -1px;
    transition-timing-function: ease-out;
    transition-duration: 0.12s;
  }

  &.active {
    font-weight: 600;
    border-bottom-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
    outline: 1px dotted transparent;
    outline-offset: -1px;
  }
`;

export const Item = styled.span`
  padding: 8px 16px;
  font-size: ${({ theme }) => theme.newtheme.font.subtitulo2.tamanho};
  line-height: 30px;
  color: ${({ theme }) => theme.newtheme.color.cinza6};
  text-align: center;
  white-space: nowrap;
  background-color: initial;
  border: 0;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 0.36s ease-in;

  white-space: nowrap;
  text-decoration: none;

  display: flex;
  align-items: center;

  cursor: pointer;

  &:hover {
    text-decoration: none;
    border-bottom-color: ${({ theme }) => theme.newtheme.color.cinza3};
    outline: 1px dotted transparent;
    outline-offset: -1px;
    transition-timing-function: ease-out;
    transition-duration: 0.12s;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      font-weight: 600;
      border-bottom-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
      outline: 1px dotted transparent;
      outline-offset: -1px;
      &:hover {
          border-bottom-color: ${({ theme }) =>
            darken(0.2, theme.newtheme.color.azulPrimario)};}
      }
    `}
`;

const Tabs = ({ ppadding, className, children }) => {
  return (
    <NavTab ppadding={ppadding} className={className}>
      <UnderlineNav>{children}</UnderlineNav>
    </NavTab>
  );
};

export default Tabs;

import styled from 'styled-components/macro';

export const Card = styled.div`
  max-width: ${({ largura }) => largura || '100%'};
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  background-color: ${({ bgColor }) => bgColor || '#ffffff'};
  border-radius: 29px;
  border: 1px solid #eaeaea;
  /* box-shadow: 0px 2px 15px -2px rgba(0, 0, 0, 0.15); */
  padding: 1.5rem;
  overflow: hidden;
  position: relative;

  &::-webkit-scrollbar-track {
    margin: 30px 0;
  }
`;

import React from 'react';

import { PaginationButton, Arrow, PaginationLinkBack } from './styles';

export const FirstButton = ({ onclick, first, visibility }) => (
  <PaginationButton visibility={visibility}>
    <PaginationLinkBack
      style={{ width: '36px' }}
      onClick={(e) => onclick(e, first)}
    >
      <Arrow />
      <Arrow />
    </PaginationLinkBack>
  </PaginationButton>
);

/* eslint-disable no-console */
import IMask from 'imask';
import { format } from 'date-fns';

export const currency = (value, digits = 2, prefix) => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: digits,
  });

  if (prefix !== undefined) {
    const masked = formatter.format(value);
    return masked.replace('R$', prefix);
  }

  return formatter.format(value);
};

const phoneRegExp = /(?:\()[0-9]{2}(?:\))\s?[0-9]{4,5}(?:-)[0-9]{4}$/;

export const maskPhone = (value, type = 'masked') => {
  if (value) {
    if (value.toString().search(phoneRegExp) !== -1) {
      // console.log('Formated');
    }

    // eslint-disable-next-line no-unused-vars
    let text = value.replace(')', '');
    text = text.replace('(', '');
    text = text.replace('-', '');
    text = text.replace(/(\s+)/, '');

    const v = value.toString().length;
    let masked = [];

    if (v <= 15) {
      if (v >= 1 && v <= 14) {
        // console.log('residencia');
        masked = IMask.createMask({
          mask: '(00) 0000-0000',
        });
      } else {
        // console.log('celular');
        masked = IMask.createMask({
          mask: '(00) 00000-0000',
        });
      }
      masked.resolve(value.toString());
      if (type === 'masked') {
        // console.log(masked.value);
        return masked.value;
      }
      return masked.unmaskedValue;
    }
    return value;
  }
  return value;
};

export const maskCNPJ = (value, type = 'masked') => {
  if (value) {
    const masked = IMask.createMask({
      mask: '00.000.000/0000-00',
    });
    masked.resolve(value.toString());
    if (type === 'masked') {
      return masked.value;
    }
    return masked.unmaskedValue;
  }
  return '';
};

export const countRecords = (data) => {
  if (Array.isArray(data)) {
    return data.length;
  }
  return Object.keys(data).length;
};

export const dotToCommaDecimals = (value, decimal, withPercent) => {
  const val = value.toFixed(decimal);
  if (withPercent) {
    const percent = val.toString().replace('.', ',');
    return `${percent} %`;
  }
  return val.toString().replace('.', ',');
};

export const date = (value) => {
  const result = format(new Date(value), 'dd/MM/yyyy');

  return result;
};

export function formatCnpjCpf(value) {
  const cnpjCpf = value.replace(/\D/g, '');

  if (cnpjCpf.length === 11) {
    return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }

  return cnpjCpf.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    '$1.$2.$3/$4-$5'
  );
}

export const dateTime = (value) => {
  const result = format(new Date(value), 'dd/MM/yyyy HH:mm');
  return result;
};

export const initialLetters = (texto) => {
  if (texto) {
    let res = texto;
    res = res.toUpperCase();
    res = res.split(' ');
    let letters = null;
    if (res.length > 1) {
      letters = {
        first: res[0].charAt(0),
        last: res[1].charAt(0),
      };
    } else {
      letters = {
        first: res[0].charAt(0),
        last: '',
      };
    }
    return letters;
  }
  const letters = {
    first: 'D',
    last: 'F',
  };
  return letters;
};

import React from 'react';

import iconBank from 'assets/images/ixon-bank.svg';
import { TitleHead } from './TitleHead';

export function TitleEndividamento({ data }) {
  return (
    <TitleHead
      icon={iconBank}
      title={`Endividamento Anual (${data.consolidado.nomeMesAno})`}
    />
  );
}

import React, { useContext } from 'react';

import Pagination from 'components/FinPagination/PaginationDefault';
import { Wrapper } from 'components/FinWrappers';

import { OperacoesContext } from '../provider';

export const Footer = ({ totalPages }) => {
  const [state, actions] = useContext(OperacoesContext);
  const {
    pagination: { skip },
  } = state;
  const { setPage } = actions;

  return (
    <Wrapper padding="1rem 0 2rem 0" pwidth="100%">
      <Pagination
        changePage={(value) => setPage(value.page)}
        totalPages={totalPages}
        page={skip}
        place="center"
        estilo="rounded"
      />
    </Wrapper>
  );
};

import { useContext } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { OperacoesContext } from '../provider';

export const useSelectProposta = (params) => {
  const [state] = useContext(OperacoesContext);

  const {
    propostas: { nova },
  } = state;

  const { idProposta } = params;

  const queryClient = useQueryClient();
  const { idOperacao } = useParams();

  if (idProposta) {
    const propostas = queryClient.getQueryData([
      'propostas',
      'lista',
      { idOperacao: Number(idOperacao) },
    ]);

    const proposta = propostas.data.find(
      (proposta) => proposta.idProposta === Number(idProposta)
    );

    return { proposta };
  }

  return { proposta: nova };
};

/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { createActions, createReducer } from 'reduxsauce';

/**
 * Criando action types & creators
 */

export const { Types, Creators } = createActions({
  uiToasterMessage: ['data', 'tipo'],
});

/**
 * Criando os reducer handlers
 */

const INITIAL_STATE = {
  data: [],
  tipo: 'default',
};

const setUiToaster = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    data: [action.data],
    tipo: action.tipo,
  };
};

/**
 * Criando reducer
 */

export default createReducer(INITIAL_STATE, {
  [Types.UI_TOASTER_MESSAGE]: setUiToaster,
});

import React from 'react';

import {
  PaginationButton,
  Arrow,
  PaginationLinkBack,
} from './style.pagination';

export const FirstButton = ({ onclick, first, visibility, disabled }) => (
  <PaginationButton visibility={visibility}>
    <PaginationLinkBack
      disabled={disabled}
      style={{ width: '40px' }}
      onClick={(e) => onclick(e, first)}
    >
      <Arrow />
      <Arrow />
    </PaginationLinkBack>
  </PaginationButton>
);

/* eslint-disable no-unused-vars */
import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import axios from 'axios';
import CONFIG from 'config';
import toast from 'react-hot-toast';
import { getUserData } from 'services/getUserData';
import { handleErrors } from 'helpers/requisicoes/handleErrors';
import { OperacoesContext } from '../provider';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const ABAS = {
  emAberto: 1,
  todas: 2,
  realizadas: 3,
  canceladas: 4,
  desistencias: 5,
  perdidas: 6,
};

const desistir = async (params) => {
  const { idUsuario } = await getUserData();

  const mutateData = axios.put(`${BASE_URL}/operacao/convenio/desistir`, {
    ...params,
    idUsuario,
  });

  toast.promise(mutateData, {
    loading: 'Loading',
    success: 'Operação desistida',
    error: (error) => {
      const msg = handleErrors(error);
      return `${msg}`;
    },
  });

  const result = await mutateData;
  const { data } = result;

  return data || [];
};

export const useMutateDesistirOperacao = () => {
  const queryClient = useQueryClient();
  const [state, actions] = useContext(OperacoesContext);

  return useMutation((params) => desistir(params), {
    onMutate: async (params) => {
      await queryClient.cancelQueries(['operacoes']);

      const {
        navigation: { tabActive },
        pagination: { skip },
        pagination: { size },
        search,
        date,
      } = state;

      const values = {
        size,
        skip,
        busca: search,
        dataInicioVigencia: date,
        tipoAba: ABAS[tabActive],
      };

      const queryKey = ['operacoes', 'list', values];

      const previousData = queryClient.getQueryData([...queryKey]);

      if (previousData) {
        const updatedData = previousData.data.filter(
          (item) => item.idOperacao !== params.idOperacao
        );

        const newPage = updatedData.length === 0 ? skip - 1 : skip;

        const newData = {
          ...previousData,
          totalPages: newPage,
          totalRecords: previousData.totalRecords - 1,
          data: updatedData,
        };

        const queryCustom = () => {
          queryKey[2] = { ...values, skip: newPage };
          return queryKey;
        };

        if (updatedData.length === 0) {
          actions.setPage(newPage);
        } else {
          queryClient.setQueryData([...queryCustom()], newData);
        }
      }

      return { previousData, queryKey };
    },
    onError: (err, previousData, context, queryKey) => {
      queryClient.setQueryData([...context.queryKey], context.previousData);
    },
    onSuccess: (data, variables, context) => {
      actions.setResetProposta();
      queryClient.invalidateQueries('operacoes');
      queryClient.invalidateQueries('propostas');
    },
  });
};

import React from 'react';

import { Wrapper } from 'components/FinWrappers';
import { TagStatusStyle, IconProcessStyle } from './style';

export const InfoStatus = ({ children }) => {
  return (
    <Wrapper padding="0" pheight="100%">
      <TagStatusStyle bgColor="#d6edef">
        <IconProcessStyle />
        {children}
      </TagStatusStyle>
    </Wrapper>
  );
};

import React from 'react';

import iconLocked from 'assets/images/icon-lock-key.svg';

import { NwDialog } from 'components/NwDialog';
import { Popover, PopoverTriggerButtonFloat, PopoverContent } from '../Popover';
import { Item, ItemLabel } from './style.mainmenu';
import { IconTreeStyle } from '../Card/style.card';

import { BlockDialog } from '../Dialogs';

export function MenuContext({ fillColor, data }) {
  const [showPopover, setShowPopover] = React.useState(false);

  const closePopover = () => setShowPopover(false);

  return (
    <Popover open={showPopover} onOpenChange={setShowPopover}>
      <PopoverTriggerButtonFloat>
        <IconTreeStyle fillcolor={fillColor} />
      </PopoverTriggerButtonFloat>
      <PopoverContent align="end">
        <Item>
          <NwDialog
            size="small"
            trigger={<ItemLabel css={{ icon: iconLocked }}>Bloquear</ItemLabel>}
          >
            <BlockDialog data={data} closePopover={closePopover} />
          </NwDialog>
        </Item>
      </PopoverContent>
    </Popover>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useAuth } from 'react-oidc-context';

import { ReactLoadingCuston } from 'components/FinLoader';
import { useDispatch } from 'react-redux';
import { UiPopUpActions } from 'store/ducks/uiPopUp';

export function PaginaLogout() {
  const auth = useAuth();
  const dispatch = useDispatch();

  const clearStorage = new Promise((resolve) => {
    localStorage.removeItem('userData');
    localStorage.removeItem('first_acess_termos');
    localStorage.removeItem('historico_navegacao');
    dispatch(UiPopUpActions.campaignNotShowedAll());
    resolve();
  });

  React.useEffect(() => {
    clearStorage.then(() => auth.signoutRedirect());
  }, []);

  return (
    <ReactLoadingCuston isLoading={true} positionFixed showLabel={false} />
  );
}

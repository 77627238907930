import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import ReactTooltip from 'react-tooltip';

import feather from 'feather-icons/dist/icons.json';
import { icons } from 'assets/icones/finplaceIcons';

import { MakeSvgUri } from 'helpers/svgFiles';

const Button = styled.span`
  align-items: center;
  border: none;
  display: inline-flex;
  justify-content: center;
  outline: none;
  position: relative;
  z-index: 0;
  height: ${({ pheight }) => pheight};
  margin: 0;
  padding: 0;
  top: 0;
  width: ${({ pwidth }) => pwidth};
  cursor: pointer;
  & img {
    display: none;
  }

  &::before {
    background-image: url(${({ uriSvg }) => uriSvg});
    background-position: ${({ pbgPosition }) => pbgPosition};
    background-repeat: no-repeat;
    background-size: ${({ pbgSize }) => pbgSize};
    opacity: 0.16;
    display: block;
    height: ${({ pheight }) => pheight};
    width: ${({ pwidth }) => pwidth};
    content: '';
  }

  &::after {
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    z-index: -1;
    top: 50%;
    left: 50%;
    background: none;
    border-radius: 5px;
    box-sizing: border-box;
    transform: scale(0) translate(-50%, -50%);
    transition-property: transform, opacity;
    width: 40px;
    height: 40px;
  }

  &:hover ::before {
    opacity: 1;
  }
  &:hover ::after {
    background-color: rgba(32, 33, 36, 0.059);
    border: none;
    box-shadow: none;
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
  }
`;

const StatusButton = forwardRef(
  ({ icon, onClick, id, isDisabledTooltip, tooltipText, ...rest }, ref) => {
    const data = feather[icon] || icon;
    const uri = MakeSvgUri({ data, ...rest });
    return (
      <>
        {tooltipText && !isDisabledTooltip && (
          <ReactTooltip
            id={`info-${id}`}
            wrapper="span"
            place="bottom"
            type="dark"
            effect="solid"
          >
            {tooltipText}
          </ReactTooltip>
        )}
        <Button
          {...rest}
          data-tip
          data-for={`info-${id}`}
          ref={ref}
          onClick={onClick}
          uriSvg={uri}
          role="button"
        />
      </>
    );
  }
);

StatusButton.propTypes = {
  icon: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string,
  heigth: PropTypes.string,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.string,
  strokeLinecap: PropTypes.string,
  strokeLinejoin: PropTypes.string,
  classe: PropTypes.string,
  onClick: PropTypes.func,
  pbgPosition: PropTypes.string,
  isDisabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  toolTipPosition: PropTypes.string,
  isDisabledTooltip: PropTypes.bool,
};

StatusButton.defaultProps = {
  icon: icons.housePricing,
  pbgSize: '20px',
  pwidth: '20px',
  pheight: '20px',
  pbgPosition: 'center',
  isDisabled: false,
  tooltipText: null,
  toolTipPosition: 'bottom',
};

export default StatusButton;

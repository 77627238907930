import React, { useCallback, useContext, useEffect } from 'react';

import { Table, Tbody, Td, Tr } from 'components/FinTable';
import { CheckboxFlat } from 'components/FinCheckboxCuston/CheckboxFlat';
import { Caption } from 'components/FinParagraph';
import { dotToComma } from 'components/Formatter';
import { currency } from 'helpers/format';
import { truncate } from 'helpers/string';
import { flatten } from 'ramda';

import { useQueryClient } from 'react-query';

import {
  CaptionLabel,
  ContainerSacado,
  WrapperCustom2,
  IconeChevronStyle,
} from './style.bordero';

import { OperacoesContext } from '../provider';

export const Sacado = ({ isActive, onClick, data }) => {
  const [state, actions] = useContext(OperacoesContext);

  const queryClient = useQueryClient();

  const {
    modal: { values },
    bordero: { selecionados, nãoSelecionados },
  } = state;

  const sacadoSelecionado = () => {
    const tipo = [];
    const titulos = data.titulos.map((s) => s.id);

    if (values.rules.indexOf('edit') !== -1) {
      selecionados.forEach((item) => {
        if (titulos.indexOf(item.id) !== -1) {
          tipo.push(item.id);
        }
      });
    } else {
      data.titulos.forEach((item) => {
        if (item.selecionado) {
          tipo.push(item.id);
        }
      });
    }

    if (tipo.length === 0) {
      return 'empty';
    }
    if (titulos.length === tipo.length) {
      return 'all';
    }
    return 'indeterminate';
  };

  const isSelected = () => {
    return (
      sacadoSelecionado() === 'all' || sacadoSelecionado() === 'indeterminate'
    );
  };

  const selecionaSacado = useCallback(
    (event, id) => {
      const bordero = queryClient.getQueryData([
        'bordero',
        'lista',
        {
          idBordero: values.idBordero,
        },
      ]);

      const titulos = flatten(
        bordero.sacados.filter((s) => s.idSacado === id).map((s) => s.titulos)
      );

      const titulosNew = titulos.map((t) => ({ id: t.id, valor: t.valor }));

      if (event.target.checked) {
        actions.setSelecao({ value: titulosNew });
      } else {
        const countRemaining = selecionados.length - titulosNew.length;

        if (countRemaining < 1) {
          // eslint-disable-next-line no-console
          console.log('Pelo menos um titulo selecionado');
        } else {
          actions.removeSelecao({ value: titulosNew });
        }
      }
    },
    [values, queryClient, actions, selecionados]
  );

  useEffect(() => {
    if (values.rules.indexOf('edit') !== -1) {
      actions.updateValorRecusado();
    }
  }, [actions, values, selecionados, nãoSelecionados]);

  return (
    <ContainerSacado isActive={isActive} onClick={onClick}>
      <Table>
        <Tbody>
          <Tr>
            <Td>
              <WrapperCustom2>
                <CheckboxFlat
                  ariaLabel="Checkbox row"
                  onChange={(e) => selecionaSacado(e, data.idSacado)}
                  checked={isSelected()}
                  indeterminate={sacadoSelecionado() === 'indeterminate'}
                  name={`item-row-checkbox-${data.idSacado}`}
                  disabled={values.rules.indexOf('view') !== -1}
                />
              </WrapperCustom2>
            </Td>
            <Td>
              <WrapperCustom2 style={{ width: '262px' }}>
                <Caption>Razão Social</Caption>
                <CaptionLabel>{truncate(data.razaoSocial, 28)}</CaptionLabel>
              </WrapperCustom2>
            </Td>

            <Td>
              <WrapperCustom2 style={{ width: '100px' }}>
                <Caption>Notas Fiscais</Caption>
                <CaptionLabel>{data.qtdTitulosSacado}</CaptionLabel>
              </WrapperCustom2>
            </Td>

            <Td>
              <WrapperCustom2 style={{ width: '100px' }}>
                <Caption>Valor Total</Caption>
                <CaptionLabel>{currency(data.totalSacado)}</CaptionLabel>
              </WrapperCustom2>
            </Td>

            <Td>
              <WrapperCustom2 style={{ width: '100px' }}>
                <Caption>PMP</Caption>
                <CaptionLabel>{dotToComma(data.pmpSacado)}</CaptionLabel>
              </WrapperCustom2>
            </Td>

            <Td>
              <WrapperCustom2 align="flex-end">
                <IconeChevronStyle width="24px" height="24px" />
              </WrapperCustom2>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </ContainerSacado>
  );
};

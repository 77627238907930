import styled from 'styled-components/macro';
import { darken } from 'polished';
import Button from 'components/FinButton';

export const Shadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  overflow: hidden;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 18px;
  width: 665px;
  // width: 730px;
  // min-height: calc(100% - 12vh);
  min-height: calc(100% - 18vh);
  // max-height: 710px;
  max-height: 590px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  letter-spacing: -0.05em;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 30px 43px;
  position: relative;
`;

export const Header = styled(Wrapper)`
  border-bottom: 1px solid var(--finborder-color);
  height: 100px;
  align-items: center;
`;

export const Title = styled.h4`
  font-size: 1.8rem;
  color: var(--fingray-900);
  font-weight: 600;
  line-height: 1rem;
`;

export const ButtonCuston = styled(Button)`
  background-color: var(--fingray-300);
  border-radius: 10px;
  height: 40px;
  font-size: 0.9rem;
  font-weight: normal;
  color: var(--fingray-900);
  &:enabled:hover {
    background-color: ${darken(0.1, '#f2f2f3')};
  }
`;

export const ButtonSquare = styled(ButtonCuston)`
  position: absolute;
  top: 15px;
  right: 15px;
  width: 35px;
  height: 35px;
  padding: 6px;

  & svg path {
    stroke-width: 2px;
    stroke: var(--fingray-900);
  }
`;

export const Body = styled(Wrapper)`
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0px 43px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Footer = styled(Wrapper)`
  border-top: 1px solid var(--finborder-color);
  height: 100px;
  align-items: center;
  justify-content: flex-end;
`;

export const ButtonPrimary = styled(Button)`
  background-color: var(--finblue-primary);
  border-radius: 10px;
  height: 40px;
  font-size: 0.9rem;
  font-weight: normal;
  color: #ffffff;
  padding: 0;
  width: 145px;
  margin-left: 17px;
  &:enabled:hover {
    background-color: ${darken(0.1, '#008894')};
  }
`;

export const ButtonSecondary = styled(Button)`
  background-color: var(--finblue-secondary);
  border-radius: 10px;
  height: 40px;
  width: 145px;
  font-size: 0.9rem;
  font-weight: normal;
  color: var(--finblue-primary);
  &:enabled:hover {
    background-color: ${darken(0.1, '#D6FBFF')};
  }
`;

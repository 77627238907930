/* eslint-disable no-console */
import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { connect } from 'react-redux';
import { closeModal, setDataReasonCancel } from 'store/old_actions/actions';

import {
  TextArea,
  SeparatorHorizontal,
  SeparatorVertical,
  TextValue,
  Label,
} from '../Elements';

import { Button, ButtonDelete } from '../Button';
import { Title, Paragraph } from '../Headings';

const Modal = (props) => {
  const [textInputValue, setTextInputValue] = useState('');
  const onChangeText = (e) => {
    setTextInputValue(e.target.value);
    props.setReasonCancel(e.target.value);
  };
  const confirmAction = () => {
    console.log(props.modalActions.value);
    props.genericAction(props.modalActions.type, props.modalActions.value);
    if (props.modalActions.type !== 'DESISTIR_OPERACOES_REQUEST') {
      props.closeModal();
    } else if (textInputValue !== '') {
      props.closeModal();
    }
  };
  const modalClose = () => {
    props.closeModal();
    setTextInputValue('');
    props.setReasonCancel('');
  };
  // console.log(props.modalTextarea);
  return (
    <CSSTransition
      in={props.showModal}
      timeout={200}
      classNames="transition-messages"
      unmountOnExit
    >
      <div className="messages-gc">
        <article className="message">
          <div
            className="message-header"
            style={{
              borderBottom: 'none',
              padding: '32px 32px 0',
              margin: '0',
            }}
          >
            <Title>{props.modalTitle}</Title>
            <ButtonDelete
              onClick={() => modalClose()}
              aria-label="delete"
            ></ButtonDelete>
          </div>
          <div className="message-body" style={{ padding: '20px 32px' }}>
            {props.modalTextarea ? (
              <div>
                <TextValue>{props.modalContent}</TextValue>
                <SeparatorHorizontal hsize="0.4rem 0" />
                {/* <Label>Para finalizar essa ação:</Label> */}
                <SeparatorHorizontal hsize="0.1rem 0" />
                <Label>
                  Ao desistir dessa operação você não poderá encaminhar novas
                  propostas! Se ainda assim você deseja desistir, registre o
                  motivo e confirme:
                </Label>
                <TextArea
                  value={textInputValue}
                  onChange={(e) => onChangeText(e)}
                />
              </div>
            ) : (
              <Paragraph>{props.modalContent}</Paragraph>
            )}
            <br />
          </div>
          <div className="message-footer" style={{ display: 'flex' }}>
            {/* <button
              className="button is-danger"
              onClick={() => confirmAction()}
            >
              {props.modalLabelBtn1}
            </button> */}
            <Button type="orange" onClick={() => confirmAction()}>
              {props.modalLabelBtn1}
            </Button>
            <SeparatorVertical wsize="1rem" />
            {!props.modalSingleBtn && (
              /* <button className="button" onClick={() => modalClose()}>
                {props.modalLabelBtn2}
              </button> */
              <Button type="gray3" onClick={() => modalClose()}>
                {props.modalLabelBtn2}
              </Button>
            )}
          </div>
        </article>
      </div>
    </CSSTransition>
  );
};

const mapStateToProps = (state) => {
  return {
    modalTitle: state.reducerUI.modalTitle,
    modalActions: state.reducerUI.modalActions,
    modalContent: state.reducerUI.modalContent,
    modalSingleBtn: state.reducerUI.modalSingleBtn,
    modalLabelBtn1: state.reducerUI.modalLabelBtn1,
    modalLabelBtn2: state.reducerUI.modalLabelBtn2,
    modalTextarea: state.reducerUI.modalTextarea,
    showModal: state.reducerUI.showModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
    genericAction: (type, value) => dispatch({ type, value }),
    setReasonCancel: (data) => dispatch(setDataReasonCancel(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);

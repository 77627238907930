import React, { forwardRef } from 'react';

import * as FinInput from './style.input';

export const InputCurrency = forwardRef((props, ref) => {
  return <FinInput.InputCurrency {...props} ref={ref} />;
});

export const InputTextArea = forwardRef((props, ref) => {
  return <FinInput.InputTextArea {...props} ref={ref} />;
});

export const InputConcentracaoMaxima = forwardRef((props, ref) => {
  const { error } = props;
  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <FinInput.InputConcentracaoMaxima
        aligntext="center"
        name="taxa"
        suffix="%"
        decimalSeparator=","
        thousandSeparator=""
        {...props}
        ref={ref}
      />
      {error && <FinInput.ErrorCuston>{error}</FinInput.ErrorCuston>}
    </div>
  );
});

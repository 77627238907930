/* eslint-disable no-unused-vars */
import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import axios from 'axios';
import CONFIG from 'config';
import toast from 'react-hot-toast';
import { getUserData } from 'services/getUserData';
import { handleErrors } from 'helpers/requisicoes/handleErrors';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const vitoria = async (params) => {
  const { idUsuario, idEmpresa } = await getUserData();

  const mutateData = axios.put(`${BASE_URL}/proposta/convenio/vitoria`, {
    ...params,
    idUsuario,
    idConvenio: idEmpresa,
  });

  const result = await mutateData;
  const { data } = result;

  return data || [];
};

export const useMutateConvenioVitoria = () => {
  const queryClient = useQueryClient();

  return useMutation((params) => vitoria(params), {
    onMutate: async (params) => {
      await queryClient.cancelQueries(['propostas']);

      return { params };
    },
    onError: (error) => {
      const message = handleErrors(error);
      toast.error(message, { duration: 4000 });
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('propostas');
    },
  });
};

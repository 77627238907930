import React from 'react';

import * as Tag from './style.tagmember';

export const FinBasic = ({ style, className }) => {
  return (
    <Tag.Wrapper
      style={style}
      className={className}
      bgColor="linear-gradient(257.01deg, #F9B37F 7.9%, #E37624 89%, #A34009 154.96%);"
    >
      <Tag.IconCircleWaveStyle
        style={{ marginRight: '5px' }}
        fillColor="#FFB176"
      />
      <Tag.Label color="#6F3000">Basic</Tag.Label>
    </Tag.Wrapper>
  );
};

import React, { useCallback } from 'react';

import UserInfo from 'components/FinUserInfo';
import { finTheme } from 'finTheme';
import { rgba } from 'polished';

import FeedbackInfo from 'components/FinFeedbackInfo';
import { Ring } from '@uiball/loaders';
import { Wrapper } from 'components/FinWrappers';
import {
  SeparatorVertical,
  SeparatorHorizontal,
} from 'components/FinSeparador';

import Button from 'components/FinButton';

import { Title6 } from 'components/FinHeadings';
import { Caption } from 'components/FinParagraph';

import { currency, dateTime, dotToCommaDecimals } from 'components/Formatter';
import Timer from 'components/Timer';
import { TagStatus, TagScrollText } from 'components/FinTag';
import { ReactComponent as IconeClock } from 'assets/images/icon-clock-red.svg';
import { ReactComponent as IcoTrash } from 'assets/images/icon-trash.svg';
import { ReactComponent as IcoResume } from 'assets/images/icon-resume.svg';
import { Creators as uiDialogActions } from 'store/ducks/uiDialog';
import { useDispatch } from 'react-redux';

import { truncate, capitalizeWords } from 'helpers/string';
import { HeaderStyle, IconCamStyle } from './style';

import { ButtonRounded } from '../Ofertas/Card/ButtonRounded';
import { useMutateDesistirOperacao } from '../mutates/useMutateDesistirOperacao';
import { useFetchHeader } from '../queries/useFetchHeader';
import { useMutateChat } from '../mutates/useMutateChat';

import { HeaderSkeleton } from './HeaderSkeleton';

export const Header = ({ idOperacao }) => {
  const dispatch = useDispatch();
  const desistir = useMutateDesistirOperacao();
  const { data, isLoading, error } = useFetchHeader({ idOperacao });

  const chatFecht = useMutateChat();

  const isExistData = !isLoading && !!data && !!data.data;

  const openChat = useCallback(() => {
    chatFecht.mutate({ idOperacao });
  }, [chatFecht, idOperacao]);

  const dialogOpen = useCallback(
    (tipo, action, values) => {
      dispatch(uiDialogActions.uiDialogOpen(tipo, action, null, values));
    },
    [dispatch]
  );
  const isHidden = [
    'finalizado',
    'cancelado',
    'desistencia',
    'perdido',
    'pagamento',
  ];

  const cstyle = {
    borderRadius: '6px',
    padding: '9px 10px 5px 10px',
    border: '1px solid #e7e4e4',
    // backgroundColor: '#f4f4f4',
    // backgroundColor: '#e7eeef',
  };

  return (
    <HeaderStyle>
      {isLoading && <HeaderSkeleton />}
      {error && <FeedbackInfo tipo="error" />}
      {!!data && data.data.length === 0 && (
        <FeedbackInfo tipo="empty" text="Conteúdo não disponível" />
      )}
      {isExistData && (
        <>
          <UserInfo
            name={data.data.cedente}
            image={null}
            pbgColor={rgba(finTheme.newtheme.color.cinza2, 0.5)}
            colorText={finTheme.newtheme.color.azulPrimario}
            size="medium"
            infoDisabled
          />
          <SeparatorVertical pwidth="1rem" />

          {/* <Title6 style={{ fontWeight: 'bold', margin: '0' }}>
            {truncate(data.data.cedente, 20)}
          </Title6> */}

          <Wrapper padding="0" tipo="column" alignItems="flex-start">
            <Title6
              style={{
                fontWeight: 'bold',
                margin: '0 0 6px 0',
                lineHeight: '18px',
                minWidth: '189px',
                overflow: 'hidden',
              }}
            >
              {capitalizeWords(truncate(data.data.cedente, 20))}
            </Title6>
            <Wrapper padding="0" alignItems="flex-start">
              <Caption style={{ lineHeight: '13px' }}>Oferta</Caption>
              <SeparatorVertical pwidth="0.5rem" />
              <Caption style={{ lineHeight: '13px', fontWeight: 'bold' }}>
                {data.data.idPregao}
              </Caption>
              <SeparatorVertical pwidth="1rem" />
              <Caption style={{ lineHeight: '13px' }}>Operação</Caption>
              <SeparatorVertical pwidth="0.5rem" />
              <Caption style={{ lineHeight: '13px', fontWeight: 'bold' }}>
                {data.data.idOperacao}
              </Caption>
            </Wrapper>
          </Wrapper>

          {/* <SeparatorVertical pwidth="2rem" /> */}
          {/* <Wrapper padding="0" tipo="column" alignItems="flex-start">
            <Caption style={{ lineHeight: '13px' }}>Oferta</Caption>
            <Caption>{data.data.idPregao}</Caption>
          </Wrapper>
          <SeparatorVertical pwidth="1rem" />
          <Wrapper padding="0" tipo="column" alignItems="flex-start">
            <Caption style={{ lineHeight: '13px' }}>Operação</Caption>
            <Caption>{data.data.idOperacao}</Caption>
          </Wrapper> */}
          <SeparatorVertical pwidth="1.5rem" />
          <Wrapper
            padding="0 1rem"
            tipo="column"
            alignItems="flex-start"
            style={cstyle}
          >
            <Caption style={{ lineHeight: '13px' }}>Data</Caption>
            <Caption style={{ fontWeight: 'bold', lineHeight: '22px' }}>
              {dateTime(data.data.dtInicio)}
            </Caption>
          </Wrapper>
          <SeparatorVertical pwidth="0.5rem" />
          <Wrapper
            padding="0"
            tipo="column"
            alignItems="flex-start"
            style={cstyle}
          >
            <Caption style={{ lineHeight: '13px' }}>Bruto Solicitado</Caption>
            <Caption style={{ fontWeight: 'bold', lineHeight: '22px' }}>
              {currency(data.data.valorBruto)}
            </Caption>
          </Wrapper>
          <SeparatorVertical pwidth="0.5rem" />
          <Wrapper
            padding="0 1rem"
            tipo="column"
            alignItems="flex-start"
            style={cstyle}
          >
            <Caption style={{ lineHeight: '13px' }}>PMP</Caption>
            <Caption
              style={{ fontWeight: 'bold', lineHeight: '22px' }}
            >{`${dotToCommaDecimals(data.data.pmp, 2)} Dias`}</Caption>
          </Wrapper>
          <SeparatorVertical pwidth="1rem" />

          <Wrapper
            padding="0 1rem"
            justifyContent="flex-start"
            alignItems="flex-start"
            tipo="column"
          >
            <TagScrollText
              bgcolor="#70e5ef"
              textColor="#000"
              scrollOn={data.data.tipo.label.length > 11}
            >
              {data.data.tipo.label.toUpperCase()}
            </TagScrollText>
            <SeparatorHorizontal ppadding="2px" />
            <TagStatus
              bgcolor="#FFEEEC"
              textColor="#FF4D35"
              altura="24px"
              id="timer"
            >
              <IconeClock width="18" height="18" /> &nbsp;
              <Timer
                time={data.data.cronometroInicio}
                expira={data.data.cronometroFim}
              />
            </TagStatus>
          </Wrapper>

          <Wrapper padding="0" pwidth="110px">
            <Button
              onClick={() => openChat()}
              disabled={chatFecht.isLoading}
              largura="total"
              rounded
            >
              {chatFecht.isLoading ? (
                <Ring size={20} lineWeight={5} speed={2} color="black" />
              ) : (
                <>
                  <IconCamStyle />
                  <SeparatorVertical pwidth="0.4rem" />
                  Ligar
                </>
              )}
            </Button>
          </Wrapper>

          <SeparatorVertical pwidth="1rem" />

          <Wrapper
            padding="0"
            pwidth="100px"
            pheight="26px"
            justifyContent="flex-start"
            pposition="relative"
          >
            {!isHidden.includes(data.data.step.nome) && (
              <ButtonRounded
                label="Excluir"
                bgColor="#e7eeef"
                hoverColor="#FF4D35"
                id="button-trash-header"
                tooltipText="Desisitir desta operação"
                positionButton={['position: absolute', 'top: 0', 'left: 0']}
                onClick={(e) => {
                  e.preventDefault();
                  dialogOpen(
                    'desistirOperacao',
                    (aditionalPayloadToAction) =>
                      desistir.mutate({
                        idOperacao: data.data.idOperacao,
                        ...aditionalPayloadToAction,
                      }),
                    data.data.idOperacao
                  );
                }}
              >
                <IcoTrash />
              </ButtonRounded>
            )}

            <ButtonRounded
              label="Resumo"
              bgColor="#e7eeef"
              hoverColor="#008894"
              id="button-resume-header"
              tooltipText="Resumo da operação"
              positionButton={[
                'position: absolute',
                !isHidden.includes(data.data.step.nome)
                  ? 'left: 95px'
                  : 'left: 0',
                'top: 0',
              ]}
              onClick={(e) => {
                e.preventDefault();
                dialogOpen('resumoOperacao', null, {
                  idOperacao: data.data.idOperacao,
                  idGrupo: data.data.idGrupo,
                });
              }}
            >
              <IcoResume />
            </ButtonRounded>
          </Wrapper>
        </>
      )}
    </HeaderStyle>
  );
};

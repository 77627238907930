import React from 'react';
import { finTheme } from 'finTheme';

import { icons } from 'assets/icones/finplaceIcons';
import TagStatus from './TagStatus';

const TagHabilitado = ({ id }) => {
  return (
    <TagStatus
      viewTooltip
      id={`habilitado-${id}`}
      tooltipText="Habilitação concluida"
      bgcolor={finTheme.newtheme.color.success}
      textColor={finTheme.newtheme.color.branco}
      iconColor={finTheme.newtheme.color.branco}
      icon={icons.checkFlat}
    >
      Habilitado
    </TagStatus>
  );
};

export default TagHabilitado;

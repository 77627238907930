import React from 'react';

import * as StylesButton from './styled.buttonpdf';

export function ButtonPdf({
  label,
  icon,
  bgColor,
  borderColor,
  largura,
  // iconColor,
  // onClick,
}) {
  return (
    <StylesButton.Wrapper>
      <StylesButton.ViewMatch
        bgColor={bgColor}
        borderColor={borderColor}
        largura={largura}
      >
        <StylesButton.Text icon={icon}>{label}</StylesButton.Text>
      </StylesButton.ViewMatch>

      {/* <StylesButton.Button
        bgColor={bgColor}
        borderColor={borderColor}
        onClick={onClick}
      >
        <StylesButton.IconPdfStyle iconColor={iconColor} />
      </StylesButton.Button> */}
    </StylesButton.Wrapper>
  );
}

/* eslint-disable eqeqeq */
import React from 'react';

import { NWSwitch } from 'components/NWSwitch';
import { useLocation } from 'react-router-dom';
import * as FormStyle from './style.form';
// import { BuscaCnae } from '../BuscaCnae';
import { useQueryFilters } from '../hook/useQueryFilters';

const enabled = ['/minha-rede/conexoes'];

export function Form({
  filters,
  setFilters,
  changeFilter,
  changeFilterCheckbox,
}) {
  const { data } = useQueryFilters();

  const location = useLocation();

  const isChecked = (value) => {
    const field = value.split('-');

    return filters[field[0]]
      ? filters[field[0]].indexOf(field[1]) !== -1
      : false;
  };

  const isEven = (num) => num % 2 == 0;

  const isOdd = (n) => {
    return Math.abs(n % 2) == 1;
  };

  const withList = data && data.data;

  const onHandleChange = (event) => {
    if (event) {
      setFilters((state) => ({ ...state, somenteMatriz: true }));
    } else {
      const removed = filters;
      delete removed.somenteMatriz;
      setFilters({ ...removed });
    }
  };

  const changeFinscanner = (event) => {
    if (!event.target.checked) {
      const removed = filters;
      delete removed.scannerAtivo;
      setFilters({ ...removed });

      return null;
    }

    return setFilters((state) => ({ ...state, scannerAtivo: true }));
  };

  return (
    <>
      <FormStyle.Fieldset>
        <FormStyle.Column>
          <FormStyle.Label>Aplicar Filtros</FormStyle.Label>
          <FormStyle.Paragraphy>
            As informações exibidas nos cards da sua busca, são referentes à
            matriz das empresas. Pode ser que o filtro aplicado, se refira à uma
            ou mais filiais. Para ter mais detalhes, clique na razão social da
            empresa.
          </FormStyle.Paragraphy>
          <NWSwitch
            checked={!!filters.somenteMatriz}
            label="Somente Matriz"
            onCheckedChange={onHandleChange}
            name="onlyMatriz"
            value="yes"
          />
        </FormStyle.Column>
      </FormStyle.Fieldset>

      {/* <FormStyle.Fieldset>
        <FormStyle.Column>
          <FormStyle.Label tag="Selecionados: 2">CNAE</FormStyle.Label>
          <FormStyle.InputSearch
            placeholder="Pesquisar CNAE"
            onChange={() => {}}
          />
          <BuscaCnae />
        </FormStyle.Column>
      </FormStyle.Fieldset> */}

      <FormStyle.Fieldset>
        <FormStyle.Row style={{ alignItems: 'flex-end', gap: '10px' }}>
          <FormStyle.Column>
            <FormStyle.Label>Faturamento anual</FormStyle.Label>
            <FormStyle.InputCurrency
              label="Valor Inicial"
              name="faturamento_de"
              value={filters.faturamento_de || 0}
              onChangeEvent={(e, m, f) => changeFilter(e, f)}
            />
          </FormStyle.Column>
          <FormStyle.Column>
            <FormStyle.InputCurrency
              name="faturamento_ate"
              label="Valor Final"
              value={filters.faturamento_ate || 0}
              onChangeEvent={(e, m, f) => changeFilter(e, f)}
            />
          </FormStyle.Column>
        </FormStyle.Row>
      </FormStyle.Fieldset>

      <FormStyle.Fieldset style={{ flexDirection: 'column' }}>
        <FormStyle.Row>
          <FormStyle.Label>Segmento</FormStyle.Label>
        </FormStyle.Row>
        <FormStyle.Row style={{ gap: '10px' }}>
          <FormStyle.Column>
            {withList &&
              data.data.segmento
                .filter((item) => isOdd(Number(item.id)))
                .map((i) => (
                  <FormStyle.Checkbox
                    key={i.id}
                    name={`segmentos-${i.id}`}
                    label={i.nome}
                    onChange={changeFilterCheckbox}
                    checked={isChecked(`segmentos-${i.id}`)}
                  />
                ))}
          </FormStyle.Column>
          <FormStyle.Column>
            {withList &&
              data.data.segmento
                .filter((item) => isEven(Number(item.id)))
                .map((i) => (
                  <FormStyle.Checkbox
                    key={i.id}
                    name={`segmentos-${i.id}`}
                    label={i.nome}
                    onChange={changeFilterCheckbox}
                    checked={isChecked(`segmentos-${i.id}`)}
                  />
                ))}
          </FormStyle.Column>
        </FormStyle.Row>
      </FormStyle.Fieldset>

      <FormStyle.Fieldset style={{ flexDirection: 'column' }}>
        <FormStyle.Row>
          <FormStyle.Label>Estado</FormStyle.Label>
        </FormStyle.Row>
        <FormStyle.Row style={{ gap: '10px' }}>
          <FormStyle.Column>
            {withList &&
              data.data.estados
                .filter((item) => isOdd(Number(item.id)))
                .map((i) => (
                  <FormStyle.Checkbox
                    key={i.id}
                    name={`estado-${i.nome}`}
                    label={i.nome}
                    onChange={changeFilterCheckbox}
                    checked={isChecked(`estado-${i.nome}`)}
                  />
                ))}
          </FormStyle.Column>

          <FormStyle.Column>
            {withList &&
              data.data.estados
                .filter((item) => isEven(Number(item.id)))
                .map((i) => (
                  <FormStyle.Checkbox
                    key={i.id}
                    name={`estado-${i.nome}`}
                    label={i.nome}
                    onChange={changeFilterCheckbox}
                    checked={isChecked(`estado-${i.nome}`)}
                  />
                ))}
          </FormStyle.Column>
        </FormStyle.Row>
      </FormStyle.Fieldset>

      {enabled.includes(location.pathname) && (
        <FormStyle.Fieldset style={{ flexDirection: 'column' }}>
          <FormStyle.Row style={{ gap: '10px' }}>
            <FormStyle.Column>
              <FormStyle.Label>Habilitação</FormStyle.Label>
            </FormStyle.Column>

            <FormStyle.Column>
              <FormStyle.Label>Crédito</FormStyle.Label>
            </FormStyle.Column>
          </FormStyle.Row>

          <FormStyle.Row style={{ gap: '10px' }}>
            <FormStyle.Column>
              <FormStyle.Checkbox
                name="habilitacao-habilitado"
                label="Habilitado"
                onChange={changeFilterCheckbox}
                checked={isChecked('habilitacao-habilitado')}
              />
              <FormStyle.Checkbox
                name="habilitacao-naoHabilitado"
                label="Não habilitado"
                onChange={changeFilterCheckbox}
                checked={isChecked('habilitacao-naoHabilitado')}
              />
              <FormStyle.Checkbox
                name="habilitacao-pedente"
                label="Pendente"
                onChange={changeFilterCheckbox}
                checked={isChecked('habilitacao-pedente')}
              />
            </FormStyle.Column>

            <FormStyle.Column>
              <FormStyle.Checkbox
                name="credito-aprovado"
                label="Aprovado"
                onChange={changeFilterCheckbox}
                checked={isChecked('credito-aprovado')}
              />
              <FormStyle.Checkbox
                name="credito-naoAprovado"
                label="Recusado"
                onChange={changeFilterCheckbox}
                checked={isChecked('credito-naoAprovado')}
              />
              <FormStyle.Checkbox
                name="credito-pendente"
                label="Não definido"
                onChange={changeFilterCheckbox}
                checked={isChecked('credito-pendente')}
              />
            </FormStyle.Column>
          </FormStyle.Row>
        </FormStyle.Fieldset>
      )}

      <FormStyle.Fieldset style={{ flexDirection: 'column' }}>
        <FormStyle.Row>
          <FormStyle.Column>
            <FormStyle.Label>Perfil</FormStyle.Label>
          </FormStyle.Column>
          <FormStyle.Column>
            <FormStyle.Label>Status</FormStyle.Label>
          </FormStyle.Column>
        </FormStyle.Row>

        <FormStyle.Row>
          <FormStyle.Column>
            <FormStyle.Checkbox
              name="perfil-completo"
              label="Perfil completo"
              onChange={changeFilterCheckbox}
              checked={isChecked('perfil-completo')}
            />
            <FormStyle.Checkbox
              name="perfil-docCompleto"
              label="Documentos básicos completos"
              onChange={changeFilterCheckbox}
              checked={isChecked('perfil-docCompleto')}
            />
          </FormStyle.Column>

          <FormStyle.Column>
            <FormStyle.Checkbox
              name="status-online"
              label="Online"
              onChange={changeFilterCheckbox}
              checked={isChecked('status-online')}
            />
            <FormStyle.Checkbox
              name="status-offline"
              label="Offline"
              onChange={changeFilterCheckbox}
              checked={isChecked('status-offline')}
            />
          </FormStyle.Column>
        </FormStyle.Row>
      </FormStyle.Fieldset>

      <FormStyle.Fieldset
        style={{ flexDirection: 'column', borderBottom: 'none' }}
      >
        <FormStyle.Row>
          {/* <FormStyle.Label>Matching</FormStyle.Label> */}

          <FormStyle.Column>
            <FormStyle.Label>Matching</FormStyle.Label>
          </FormStyle.Column>
          <FormStyle.Column>
            <FormStyle.Label>Finscanner</FormStyle.Label>
          </FormStyle.Column>
        </FormStyle.Row>

        <FormStyle.Row>
          <FormStyle.Column>
            <FormStyle.Checkbox
              name="matching-match"
              label="Deu Match"
              onChange={changeFilterCheckbox}
              checked={isChecked('matching-match')}
            />
            <FormStyle.Checkbox
              name="matching-indicacao"
              label="Indicação"
              onChange={changeFilterCheckbox}
              checked={isChecked('matching-indicacao')}
            />
          </FormStyle.Column>
          <FormStyle.Column>
            <FormStyle.Checkbox
              name="scannerAtivo"
              label="Ativo"
              onChange={changeFinscanner}
              checked={!!filters.scannerAtivo}
            />
          </FormStyle.Column>
        </FormStyle.Row>
      </FormStyle.Fieldset>
    </>
  );
}

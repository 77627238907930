import React from 'react';
import { toast } from 'react-hot-toast';

import { Paragraph2 } from 'components/FinParagraph';

import Button from 'components/FinButton';
import { ButtonRounded } from 'components/FinButton/ButtonRounded';

import { ReactComponent as IconX } from 'assets/images/icon-x.svg';
import { Wrapper, Head, Body, Footer } from './style.notification';
import { tipos } from './config';

export const CustomNotification = (props) => {
  const { idToast, data } = props;
  const message = data.mensagem.split('<br>');
  const message1 = message[0];
  const message2 = message[1];
  return (
    <Wrapper>
      <ButtonRounded
        hoverColor="#008894"
        id="button-fechar"
        positionButton={['position: absolute', 'top: 10px', 'right: 10px']}
        onClick={(e) => {
          e.preventDefault();
          toast.dismiss(idToast);
        }}
      >
        <IconX />
      </ButtonRounded>
      <Head>
        <Paragraph2 style={{ fontWeight: 'bold' }}>{data.titulo}</Paragraph2>
      </Head>
      <Body>
        <Paragraph2 style={{ lineHeight: '20px' }}>{message1}</Paragraph2>
        <Paragraph2 style={{ lineHeight: '20px' }}>{message2}</Paragraph2>
      </Body>
      <Footer>
        <Button
          rounded
          onClick={() => {
            tipos[data.tipo].action(data);
            toast.dismiss(idToast);
          }}
        >
          {tipos[data.tipo].description}
        </Button>
      </Footer>
    </Wrapper>
  );
};

import React, { useState, useEffect, useCallback } from 'react';

import {
  differenceInMinutes,
  isFuture,
  addMinutes,
  differenceInSeconds,
} from 'date-fns';

const leftPad = (number, targetLength) => {
  let output = `${number}`;
  while (output.length < targetLength) {
    output = `0${output}`;
  }
  return output;
};

// eslint-disable-next-line no-unused-vars
const TimeRemain = ({ time, expira, teste }) => {
  const [minutes, setMinutes] = useState('00');
  const [seconds, setSeconds] = useState('00');

  const calcTimer = useCallback(
    (i) => {
      const isFut = isFuture(new Date(expira));

      // console.log(time + expira);

      if (isFut) {
        let timer1 = new Date();
        const expira1 = new Date(expira);

        // let temp = differenceInHours(expira1, timer1);
        // let result = temp + " hours ";
        // timer1 = addHours(timer1, temp);

        let temp = differenceInMinutes(expira1, timer1, {
          additionalDigits: 1,
        });
        // eslint-disable-next-line no-unused-vars
        let result = `${leftPad(temp, 2)} minutes `;

        setMinutes(leftPad(temp, 2));

        timer1 = addMinutes(timer1, temp);

        temp = differenceInSeconds(expira1, timer1);
        result = `${result + leftPad(temp, 2)} seconds`;

        setSeconds(leftPad(temp, 2));

        // console.log(result);
      } else {
        setMinutes('00');
        setSeconds('00');
        clearInterval(i);
      }
    },
    [expira]
  );
  useEffect(() => {
    const interval = setInterval(() => calcTimer(interval), 1000);
    return () => clearInterval(interval);
  }, [calcTimer]);
  return <span className="text-val">{`${minutes} : ${seconds}`}</span>;
};

export default TimeRemain;

import styled from 'styled-components';

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 183px;
  background: #ebfdff;
  border-radius: 16px;
  margin: 50px 0 37px;
`;

export const TotalCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 28%;
  height: 100%;
  background: #c2f9ff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 25px 28px;
`;

export const TotalValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
`;

export const TotalValue = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.608px;
`;

export const TotalValueSub = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-items: center;
  width: 100%;
  color: #063236;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
`;

export const SubsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
`;

export const Subtitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 14px;
  line-height: 1.5;

  & > span:last-child {
    font-size: 16px;
    font-weight: 600;
  }
`;

export const PriorityCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 72%;
  height: 100%;
  padding: 17px 25px 21px;
  border: solid 1px #b1eaf0;
  border-radius: 16px;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

export const PriorityTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
`;

export const PriorityCards = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;

  & div:last-child {
    margin-right: 0;
  }
`;

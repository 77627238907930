/* eslint-disable prefer-rest-params */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { UserManager, Log } from 'oidc-client-ts';

import { history } from 'components/History';
import { getItem, setItem } from 'helpers/armazenamento';
import { oidcConfig } from './oidcConfig';

export const SigninMethods = {
  DIRECT: '0',
  SILENT: '1',
};

const chaveSignin = 'signin-method';

export function getActualMethod() {
  return getItem(chaveSignin);
}

Log.setLogger(console);
Log.setLevel(Log.INFO);

// config

function log() {
  Array.prototype.forEach.call(arguments, (msg) => {
    if (msg instanceof Error) {
      console.log('[ERROR]', 'AUTH', `${msg.message}\r\n`);
    } else if (typeof msg !== 'string') {
      console.log('[DEBUG]', 'AUTH', `${JSON.stringify(msg, null, 2)}\r\n`);
    }
  });
}

const mgr = new UserManager(oidcConfig);

// events

// mgr.events.addAccessTokenExpiring(() => {
//   console.log('token expiring');
//   log('token expiring');

//   mgr
//     .signinSilent()
//     .then((user) => {
//       log('silent renew success', user);
//     })
//     .catch((e) => {
//       log('silent renew error', e.message);
//     });
// });

mgr.events.addAccessTokenExpired(() => {
  console.log('token expired');
  log('token expired');
});

mgr.events.addSilentRenewError((e) => {
  console.log('silent renew error', e.message);
  log('silent renew error', e.message);
});

mgr.events.addUserLoaded((user) => {
  console.log('user loaded', user);
  mgr.getUser().then(() => {
    console.log('getUser loaded user after userLoaded event fired');
  });
});

mgr.events.addUserUnloaded((e) => {
  console.log('user unloaded');
});

mgr.events.addUserSignedIn((e) => {
  log('user logged in to the token server');
});
mgr.events.addUserSignedOut((e) => {
  log('user logged out of the token server');
});

// functions for UI elements

function clearState() {
  mgr
    .clearStaleState()
    .then(() => {
      log('clearStateState success');
    })
    .catch((err) => {
      console.error(err);
      log(err);
    });
}

function getUser() {
  return mgr.getUser();
  // .then((user) => {
  //   log('got user', user);
  // })
  // .catch((err) => {
  //   console.error(err);
  //   log(err);
  // });
}

function removeUser() {
  mgr
    .removeUser()
    .then(() => {
      log('user removed');
      history.push('/login');
    })
    .catch((err) => {
      console.error(err);
      log(err);
    });
}

function querySessionStatus() {
  mgr
    .querySessionStatus()
    .then((status) => {
      log("user's session status", status);
    })
    .catch((err) => {
      console.error(err);
      log(err);
    });
}

function revokeAccessToken() {
  mgr
    .revokeAccessToken()
    .then(() => {
      log('access token revoked');
    })
    .catch((err) => {
      console.error(err);
      log(err);
    });
}

function startSigninMainWindow() {
  mgr
    .signinRedirect({
      state: { foo: 'bar' } /* , useReplaceToNavigate: true */,
    })
    .then(() => {
      log('signinRedirect done');
    })
    .catch((err) => {
      console.error(err);
      log(err);
    });
}

function endSigninMainWindow() {
  mgr
    .signinCallback()
    .then((user) => {
      log('signed in', user);
      // this is how you get the custom state after the login:
      const customState = user.state;
      console.log("here's our post-login custom state", customState);
    })
    .catch((err) => {
      console.error(err);
      log(err);
    });
}

function popupSignin() {
  mgr
    .signinPopup()
    .then((user) => {
      log('signed in', user);
    })
    .catch((err) => {
      console.error(err);
      log(err);
    });
}

function popupSignout() {
  mgr
    .signoutPopup()
    .then(() => {
      log('signed out');
    })
    .catch((err) => {
      console.error(err);
      log(err);
    });
}

async function iframeSignin() {
  return mgr.signinSilent();
  // .then((user) => {
  //   log('signed in silent', user);
  // })
  // .catch((err) => {
  //   console.error(err);
  //   log(err);
  // });
}

function startSignoutMainWindow() {
  mgr
    .signoutRedirect()
    .then((resp) => {
      log('signed out', resp);
    })
    .catch((err) => {
      console.error(err);
      log(err);
    });
}

function endSignoutMainWindow() {
  mgr
    .signoutCallback()
    .then((resp) => {
      log('signed out', resp);
    })
    .catch((err) => {
      console.error(err);
      log(err);
    });
}

// Custom functions

function signinRedirect(args) {
  setItem(chaveSignin, SigninMethods.DIRECT);
  return mgr.signinRedirect(args);
}

function signinSilentCallback(url) {
  return mgr.signinSilentCallback(url);
}

function signinRedirectCallback(url) {
  return mgr.signinRedirectCallback(url);
}

async function genericSigninCallback(url) {
  try {
    switch (getActualMethod()) {
      case SigninMethods.SILENT:
        return await signinSilentCallback(url);
      case SigninMethods.DIRECT:
        return await signinRedirectCallback(url);
      default:
        return signinRedirect();
    }
  } catch (error) {
    return signinRedirect();
  }
}

export {
  clearState,
  getUser,
  removeUser,
  querySessionStatus,
  revokeAccessToken,
  startSigninMainWindow,
  endSigninMainWindow,
  popupSignin,
  popupSignout,
  iframeSignin,
  startSignoutMainWindow,
  endSignoutMainWindow,
  genericSigninCallback,
  signinSilentCallback,
};

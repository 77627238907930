import styled, { css } from 'styled-components/macro';
import iconInfo from 'assets/images/icon-Info.svg';

export const Title = styled.span`
  color: var(--fingray-900);
  font-size: 1rem;
  font-weight: 600;
  text-rendering: geometricprecision;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  letter-spacing: -0.8%;
  Line height: 19.2px;
`;

export const Text = styled.span`
  color: var(--fingray-900);
  font-size: 1rem;
  font-weight: 400;
  text-rendering: geometricprecision;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  letter-spacing: -0.8%;
  Line height: 19.2px;
`;

export const Label = styled.span`
  color: var(--fingray-900);
  font-size: 1rem;
  font-weight: 400;
  text-rendering: geometricprecision;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  letter-spacing: -0.8%;
  Line height: 19.2px;
`;

export const Info = styled.span`
  background: url(${iconInfo}) no-repeat center center;
  width: 18px;
  height: 18px;
  margin-left: 5px;
`;

export const Flex = styled.div`
  display: flex;
  ${({ row }) =>
    row &&
    css`
      flex-direction: row;
    `}

  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
    `}

  justify-content: ${({ justify }) => justify || `center`};
  align-items: ${({ align }) => align || `center`};
  width: 100%;
`;

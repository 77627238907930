/* eslint-disable no-unused-vars */
import React, { useContext, useCallback, useEffect, useRef } from 'react';
import { ModalCuston } from 'components/FinModalCuston';
import { finTheme } from 'finTheme';

import { useParams } from 'react-router-dom';
import FeedbackInfo from 'components/FinFeedbackInfo';
import { FinErrorBoundary } from 'components/FinErrorBoundary';
import { useDispatch } from 'react-redux';
import { Creators } from 'store/ducks/uiNotificationContent';
import { Header } from './Header';
import { Container, MediaQueryModal } from './style';

import { CardProposta } from './CardProposta';
import { OperacoesContext, MODALS } from '../provider';
import { useFetchListaPropostas } from '../queries/useFetchListaPropostas';

import { CardSkeleton } from './CardSkeleton';
import {
  ModalBordero,
  ModalAcertos,
  ModalComentario,
  ModalParcelas,
} from '../Bordero';
import { EditaProposta } from './EditaProposta';

import { CardPagamento } from './CardPagamento';
import { IDS } from '../config';

export const Content = ({ history, isModal }) => {
  const [state, actions] = useContext(OperacoesContext);

  const dispatch = useDispatch();

  const {
    propostas: { edicao },
    modal,
  } = state;

  const { idOperacao } = useParams();
  const { data, isLoading, error } = useFetchListaPropostas({
    idOperacao: Number(idOperacao),
  });

  const isExistData = !isLoading && !!data && !!data.data;

  const scrollTarget = useRef(null);

  const executeScroll = useCallback(() => {
    if (scrollTarget.current) {
      scrollTarget.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, []);

  const goBack = () => {
    actions.setResetProposta();
    actions.resetBordero();
    history.goBack();
  };

  const showPayment = useCallback(() => {
    if (isExistData) {
      const { data: dados } = data;
      const size = dados.length;
      const id = dados[size - 1].statusProposta.idPropostaStatus;
      return (
        IDS[id] === 'aConfirmarPagamento' ||
        IDS[id] === 'operacaoPaga' ||
        IDS[id] === 'pagamentoConcluido'
      );
    }
    return null;
  }, [isExistData, data]);

  useEffect(() => {
    if (isExistData) {
      executeScroll();

      // Remove notificação
      dispatch(Creators.remove(data.data[0].idPregao, 'proposta'));
    }
  }, [isExistData, executeScroll, dispatch, data]);

  return (
    <>
      {MODALS.bordero === modal.active && <ModalBordero />}
      {MODALS.acertos === modal.active && <ModalAcertos />}
      {MODALS.comentarios === modal.active && <ModalComentario />}
      {MODALS.parcelas === modal.active && <ModalParcelas />}
      {isModal ? (
        <ModalCuston
          center={true}
          zIndex={finTheme.newtheme.zIndex.dialogModal}
          width={'calc(100% - 139px)'}
          title={'Modal de propostas'}
          open={true}
          scrolling="true"
          onClose={() => goBack()}
          header={<Header idOperacao={idOperacao} />}
          footer={null}
          margin="0 0 10vh 0"
          minHeight="83vh"
          ppadding="0"
          customStyle={MediaQueryModal}
        >
          <Container>
            <FinErrorBoundary>
              {isLoading && <CardSkeleton count={1} />}
              {error && <FeedbackInfo tipo="error" />}
              {!!data && data.data.length === 0 && (
                <FeedbackInfo tipo="empty" text="Conteúdo não disponível" />
              )}

              {isExistData &&
                data.data.map((item) => (
                  <CardProposta data={item} key={item.idProposta} />
                ))}

              {edicao && <EditaProposta data={data} />}
              {showPayment() && (
                <CardPagamento data={data.data[data.data.length - 1]} />
              )}
              <section
                style={{
                  height: '100%',
                  minWidth: '360px',
                }}
                ref={scrollTarget}
              ></section>
            </FinErrorBoundary>
          </Container>
        </ModalCuston>
      ) : (
        <div>
          <FinErrorBoundary>
            <Header idOperacao={idOperacao} />
            <Container>
              {isLoading && <CardSkeleton count={1} />}
              {error && <FeedbackInfo tipo="error" />}
              {!!data && data.data.length === 0 && (
                <FeedbackInfo tipo="empty" text="Conteúdo não disponível" />
              )}

              {isExistData &&
                data.data.map((item) => (
                  <CardProposta data={item} key={item.idProposta} />
                ))}

              {edicao && <EditaProposta data={data} />}
              {showPayment() && (
                <CardPagamento data={data.data[data.data.length - 1]} />
              )}
              <section
                style={{
                  height: '100%',
                  minWidth: '360px',
                }}
                ref={scrollTarget}
              ></section>
            </Container>
          </FinErrorBoundary>
        </div>
      )}
    </>
  );
};

import React, { useEffect, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';
import { Wrapper } from 'components/FinWrappers';
import { useReactToPrint } from 'react-to-print';

import { icons } from 'assets/icones/finplaceIcons';
import { StringToSvg } from 'helpers/svgFiles/StringToSvg';
import {
  CustomWrapperMensagem,
  CustomH2,
  Line,
  AreaAcao,
  AreaImpressao,
  LogoImpressao,
} from './Styles';
// import { sugestaoValores } from './sugestaoCredito';
// import { RatingCedente } from './cedenteRating';
import { Impeditivos } from './ImpeditivosCbbox';
import DadosCedente from './DadosCedente';

const ConteudoCbbox = () => {
  const {
    mensagem,
    mensagemLog,
    informacoes_Match: apiCbboxState,
  } = useSelector((state) => state.apiCbbox.data[0]);

  const thisIsMyCopy = (conteudo) => {
    return ReactHtmlParser(conteudo);
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {}, [handlePrint]);
  if (!mensagemLog) {
    return (
      <>
        {/* <input
          type="button"
          style={{ position: 'absolute' }}
          onClick={handlePrint}
          value="Impressão"
        /> */}
        <AreaImpressao className="areaImpressaoPaisagem" ref={componentRef}>
          <LogoImpressao>
            <>
              <StringToSvg
                strokeWidth="0"
                viewBox="0 0 200 36"
                width="200px"
                height="50px"
                fill="#007A87"
              >
                {icons.finmatch}
              </StringToSvg>
              {/* <TituloModalNovo>Finmatch</TituloModalNovo> */}
            </>
          </LogoImpressao>
          <AreaAcao>
            <a
              // eslint-disable-next-line no-script-url
              href="javascript:void(0);"
              className="LinkAcao"
              onClick={handlePrint}
            >
              <StringToSvg
                viewBox="0 2 21 15"
                width="21px"
                height="34px"
                strokeWidth="0"
              >
                {icons.acaoExportarPDF}
              </StringToSvg>
            </a>
            {/* <a href="/Finmatch" className="LinkAcao">
              <StringToSvg
                viewBox="0 -12 14 30"
                width="16px"
                height="30px"
                strokeWidth="0"
              >
                {icons.acaoEditar}
              </StringToSvg>
            </a> */}
          </AreaAcao>
          <DadosCedente />

          <Line />
          {/* <BlocoConteudo> */}
          <Wrapper style={{ 'flex-wrap': 'wrap', 'justify-content': 'start' }}>
            <CustomH2>Dados de Mercado</CustomH2>
            <Impeditivos
              titulosImpeditivos={'socios'}
              respImpeditivos={apiCbboxState.alteracao_Societaria}
            />
            <Impeditivos
              titulosImpeditivos={'ccf'}
              respImpeditivos={apiCbboxState.ccf}
            />
            <Impeditivos
              titulosImpeditivos={'liminar'}
              respImpeditivos={apiCbboxState.liminar}
            />
            <Impeditivos
              titulosImpeditivos={'pefin'}
              respImpeditivos={apiCbboxState.pfin}
            />
            <Impeditivos
              titulosImpeditivos={'refin'}
              respImpeditivos={apiCbboxState.refin}
            />
          </Wrapper>
        </AreaImpressao>
      </>
    );
  }
  return (
    <>
      <Wrapper pwidth="100%">
        <CustomWrapperMensagem pwidth="100%">
          {thisIsMyCopy(mensagem)}
        </CustomWrapperMensagem>
      </Wrapper>
    </>
  );
};

export default ConteudoCbbox;

import React from 'react';

import { Flex, Label, SwitchRoot, SwitchThumb } from './style.switch';

export function NWSwitch({ label, ...rest }) {
  return (
    <form>
      <Flex css={{ alignItems: 'center' }}>
        <Label htmlFor="airplane-mode" css={{ paddingRight: 15 }}>
          {label}
        </Label>
        <SwitchRoot id="airplane-mode" {...rest}>
          <SwitchThumb />
        </SwitchRoot>
      </Flex>
    </form>
  );
}

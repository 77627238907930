const INITIAL_STATE = {
  data: [],
  isLoading: null,
  error: false,
  dataAceite: [],
  isLoadingAceite: false,
  errorAceite: false,
  dataRejeita: [],
  isLoadingRejeita: false,
  errorRejeita: false,
  countRecords: [],
  isLoadingCount: false,
};

const pregoes = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LOAD_DATA_PREGOES_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'LOAD_DATA_PREGOES_SUCCESS':
      return {
        ...state,
        data: action.data,
        isLoading: false,
      };
    case 'LOAD_DATA_PREGOES_FAILURE':
      return {
        ...state,
        data: action.data,
        error: true,
      };
    /** **************************************** */
    case 'ACEITA_PREGOES_REQUEST':
      return {
        ...state,
        isLoadingAceite: true,
      };
    case 'ACEITA_PREGOES_SUCCESS':
      return {
        ...state,
        dataAceite: action.data,
        isLoadingAceite: false,
      };
    case 'ACEITA_PREGOES_FAILURE':
      return {
        ...state,
        dataAceite: action.data,
        errorAceite: true,
      };
    /** **************************************** */
    case 'REJEITA_PREGOES_REQUEST':
      return {
        ...state,
        isLoadingRejeita: true,
      };
    case 'REJEITA_PREGOES_SUCCESS':
      return {
        ...state,
        dataRejeita: action.data,
        isLoadingRejeita: false,
      };
    case 'REJEITA_PREGOES_FAILURE':
      return {
        ...state,
        dataRejeita: action.data,
        errorRejeita: true,
      };
    /** **************************************** */
    case 'LOAD_COUNT_RECORDS_DATA_REQUEST':
      return {
        ...state,
        isLoadingCount: true,
      };
    case 'LOAD_COUNT_RECORDS_DATA_SUCCESS':
      return {
        ...state,
        countRecords: action.data,
        isLoadingCount: false,
      };
    case 'LOAD_COUNT_RECORDS_DATA_FAILURE':
      return {
        ...state,
        countRecords: action.data,
        isLoadingCount: true,
      };
    /** **************************************** */
    default:
      return state;
  }
};

export default pregoes;

import React from 'react';

import { Table, Tbody, Tr, Thead, Th } from 'components/FinTable';
import { Titulo } from './Titulo';
import { WrapperTitulo, CaptionLabel, WrapperCustom } from './style.bordero';

export const Titulos = ({ data }) => {
  return (
    <WrapperTitulo>
      <Table striped="odd" color="#eff5f6">
        <Thead sticky style={{ backgroundColor: '#fff' }}>
          <Tr>
            <Th padding="16px 0">
              <WrapperCustom justifyContent="center"></WrapperCustom>
            </Th>
            <Th padding="16px 0">
              <WrapperCustom>
                <CaptionLabel>Nota Fiscal</CaptionLabel>
              </WrapperCustom>
            </Th>
            <Th padding="16px 0">
              <WrapperCustom>
                <CaptionLabel>Série</CaptionLabel>
              </WrapperCustom>
            </Th>
            <Th padding="16px 0">
              <WrapperCustom>
                <CaptionLabel>Parcela</CaptionLabel>
              </WrapperCustom>
            </Th>
            <Th padding="16px 0">
              <WrapperCustom>
                <CaptionLabel>CFOP</CaptionLabel>
              </WrapperCustom>
            </Th>
            <Th padding="16px 0">
              <WrapperCustom>
                <CaptionLabel>Prazo</CaptionLabel>
              </WrapperCustom>
            </Th>
            <Th padding="16px 0">
              <WrapperCustom>
                <CaptionLabel>Emissão</CaptionLabel>
              </WrapperCustom>
            </Th>
            <Th padding="16px 0">
              <WrapperCustom>
                <CaptionLabel>Vencimento</CaptionLabel>
              </WrapperCustom>
            </Th>
            <Th padding="16px 0">
              <WrapperCustom>
                <CaptionLabel>Valor</CaptionLabel>
              </WrapperCustom>
            </Th>
            <Th padding="16px 0">
              <WrapperCustom>
                <CaptionLabel>CNPJ Emissor</CaptionLabel>
              </WrapperCustom>
            </Th>
            <Th padding="16px 0">
              <WrapperCustom>
                <CaptionLabel>Alterado</CaptionLabel>
              </WrapperCustom>
            </Th>
            <Th padding="16px 0">
              <WrapperCustom>
                <CaptionLabel>Aceite</CaptionLabel>
              </WrapperCustom>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item) => (
            <Titulo key={item.id} data={item} />
          ))}
        </Tbody>
      </Table>
    </WrapperTitulo>
  );
};

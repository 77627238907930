import produce from 'immer';

const INITIAL_STATE = {
  data: [],
  isLoading: true,
  error: false,
};

const borderos = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_DEFAULT_DATA_BORDEROS':
      // console.log(action);
      return {
        ...state,
        data: action.data,
      };
    case 'LOAD_DATA_BORDEROS_REQUEST':
      // console.log(action);
      return {
        ...state,
        isLoading: true,
      };
    case 'LOAD_DATA_BORDEROS_SUCCESS':
      // console.log(action);
      return {
        ...state,
        data: action.data,
        isLoading: false,
      };
    case 'LOAD_DATA_BORDEROS_FAILURE':
      // console.log(action);
      return {
        ...state,
        data: action.data,
        error: true,
      };
    case 'UPDATE_DATA_BORDEROS_REQUEST':
      // console.log(action);
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case 'UPDATE_DATA_BORDEROS_SUCCESS':
      // console.log(action);
      // eslint-disable-next-line no-case-declarations
      const a = produce({ ...state, isLoading: false }, (draft) => {
        action.titulos.forEach((titulo) => {
          draft.data.bordero.titulos[titulo.item].selecionado = titulo.checked;
        });
      });
      // console.log(a);
      return a;
    case 'UPDATE_GROUP_SACADOS':
      // console.log(action);
      // eslint-disable-next-line no-case-declarations
      const b = produce({ ...state }, (draft) => {
        action.titulos.forEach((titulo) => {
          draft.data.sacados[titulo.keyParent].titulos[
            titulo.keyChild
          ].selecionado = titulo.selected;
        });
      });
      // console.log(b);
      return b;
    case 'UPDATE_ITEM_TITULO_SACADOS':
      // console.log(action);
      // eslint-disable-next-line no-case-declarations
      const c = produce({ ...state }, (draft) => {
        draft.data.sacados[action.titulos.keyParent].titulos[
          action.titulos.keyChild
        ].selecionado = action.titulos.selected;
      });
      // console.log(c);
      return c;
    case 'UPDATE_ITEM_SACADOS':
      // console.log(action);
      // eslint-disable-next-line no-case-declarations
      const d = produce({ ...state }, (draft) => {
        draft.data.sacados[action.titulos.keyParent].selecionado =
          action.titulos.selected;
      });
      // console.log(d);
      return d;
    /* let c = action.titulos.map(res => {
        return {
          ...state.data,
          sacados: {
            ...state.data.sacados,
            [res.keyParent]: {
              ...state.data.sacados[res.keyParent],
              titulos: {
                ...state.data.sacados.titulos,
                [res.keyChild]: {
                  ...state.data.sacados.titulos[res.keyChild],
                  selecionado: res.selected,
                },
              },
            },
          },
        };
      });
      console.log(c);
      return c; */
    /*
      let a = action.titulos.map(res => {
        return {
          ...state.data,
            bordero: {
              ...state.data.bordero,
              titulos: {
                ...state.data.bordero.titulos,
                [res]: {
                  ...state.data.bordero.titulos[res],
                  selecionado: false
                }
              }
            }
        };
      });
      let b = {...state, data: a}
      console.log(b)
      return b */
    default:
      return state;
  }
};

export default borderos;

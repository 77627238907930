/* eslint-disable no-return-assign */
// import {
//   HubConnectionBuilder,
//   LogLevel,
//   HubConnectionState,
// } from '@aspnet/signalr';

import {
  HubConnectionBuilder,
  LogLevel,
  HubConnectionState,
} from '@microsoft/signalr';

import { getUserData } from 'services/getUserData';
import config from '../../config';

const signalrUrl = config.SIGNALR_BASE_URL;
export const signalrRecebeNotificacao = 'RecebeNotificacao';

export const hubConnectionNotificacao = new HubConnectionBuilder()
  .configureLogging(LogLevel.Information)
  .withUrl(`${signalrUrl}/notificacao`, {
    accessTokenFactory: async () => {
      return getUserData().then((user) => user && user.access_token);
    },
  })
  .build();

export const signalStart = async (connection) => {
  try {
    if (hubConnectionNotificacao.state === HubConnectionState.Disconnected)
      await connection.start();
  } catch (err) {
    setTimeout(() => signalStart(connection), 5000);
  }
};

export const limparConexoes = (connections) =>
  connections.forEach((connection) => (connection.closedCallbacks = []));

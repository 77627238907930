import React, { useCallback, useState, useEffect, useMemo } from 'react';

import { useParams } from 'react-router-dom';
import { ModalCuston } from 'components/FinModalCuston';
import styled from 'styled-components/macro';
import FeedbackInfo from 'components/FinFeedbackInfo';
import { maskCNPJ } from 'helpers/format';
import { useSelector } from 'react-redux';

import Header from './Header';
import NavLinksDetail from './NavLinksDetail';
import Content from './Content';
import PageOffModal from './PageOffModal';
import { usePerfil, useListFiliais } from '../hooks/queries';
import { ListaFiliais } from './ListaFiliais';
import { Loader } from './Loader';

const Container = styled.div`
  width: calc(100% - 100px);
  margin: auto;
  background-color: ${({ theme }) => theme.newtheme.color.branco};
  margin-top: 3rem;
  margin-bottom: 3rem;
  border-radius: ${({ theme }) => theme.newtheme.borderRadius};
  padding-bottom: 2rem;
  border: ${({ theme }) => `1px solid ${theme.newtheme.color.cinza1}`};
`;

const PaginaDetalhe = ({ history, match, location, isModal, ...rest }) => {
  const [tabActive, setTabActive] = useState('geral');

  // eslint-disable-next-line no-unused-vars
  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);
  const { idEmpreendedor } = useParams();
  const perfil = usePerfil(selectedValue);
  const toBack = () => history.goBack();

  const { messages } = useSelector((state) => state.uiMessages);

  const { data, isLoading } = useListFiliais(idEmpreendedor);

  const options = useMemo(
    () =>
      data
        ? data.cedentes.map((res) => {
            const value = data.criptografia ? `` : `${maskCNPJ(res.cnpj)} - `;
            return {
              value: `${res.id}-${res.idGrupo}`,
              label: `${value}${res.nome}`,
              matriz: res.matriz,
            };
          })
        : [],
    [data]
  );

  useEffect(() => {
    if (!isLoading && options && options.length > 0) {
      setSelectedValue(options.filter((res) => res.matriz)[0]);
    }
  }, [isLoading, setSelectedValue, options]);

  const loadedProfile = useCallback(
    () => !perfil.isLoading && !!perfil.data,
    [perfil]
  );

  const checkMessage = (messages) => {
    return (
      messages.indexOf('enabled_sucessfully') > -1 ||
      messages.indexOf('disable_sucessfully') > -1 ||
      messages.indexOf('invitation_sent_sucessfully') > -1 ||
      messages.indexOf('block_sucessfully') > -1 ||
      messages.indexOf('unblock_sucessfully') > -1 ||
      messages.indexOf('accepted_sucessfully') > -1 ||
      messages.indexOf('refuse_sucessfully') > -1 ||
      messages.indexOf('cancel_sucessfully') > -1
    );
  };

  useEffect(() => {
    if (checkMessage(messages)) {
      perfil.refetch();
    }
  }, [messages, perfil]);

  return isModal ? (
    <ModalCuston
      width="1127px"
      open={true}
      title=""
      scrolling="true"
      onClose={toBack}
      header={loadedProfile() && <Header {...rest} {...perfil} />}
      ppadding="0"
    >
      <ListaFiliais
        setInputValue={setInputValue}
        loadedProfile={loadedProfile}
        setSelectedValue={setSelectedValue}
        idEmpreendedor={idEmpreendedor}
        options={options}
        isLoading={isLoading}
      />

      {loadedProfile() && (
        <NavLinksDetail
          isSelected={tabActive}
          changeTab={setTabActive}
          {...perfil}
        />
      )}
      <div
        style={{
          width: '100%',
          minHeight: '30vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {perfil.isLoading && <Loader />}

        {perfil.data && (
          <Content
            {...perfil}
            tabActive={tabActive}
            idEmpreendedor={idEmpreendedor}
          />
        )}

        {perfil.isError && <FeedbackInfo tipo="error" />}
      </div>
    </ModalCuston>
  ) : (
    <Container>
      {perfil.isLoading && <Loader />}

      {!!perfil.data && (
        <PageOffModal
          tabActive={tabActive}
          setTabActive={setTabActive}
          {...perfil}
          setInputValue={setInputValue}
          loadedProfile={loadedProfile}
          setSelectedValue={setSelectedValue}
          idEmpreendedor={idEmpreendedor}
          options={options}
          isLoading={isLoading}
        />
      )}

      {perfil.isError && <FeedbackInfo tipo="error" />}
    </Container>
  );
};

export default PaginaDetalhe;

import React, { useContext } from 'react';

import { currency, date } from 'helpers/format';

import { ReactComponent as IconWarn } from 'assets/images/icon-warning-new.svg';
import { ReactComponent as IconCheck } from 'assets/images/icon-shield-check.svg';
import { TitleDadosMercado } from '../Comps/TitleHead';

import {
  Flex,
  WrapperMarketData,
  ContainerMarketData,
  ContentMarketData,
  IconMarket,
  InfoMarket,
  NameMarket,
  SubNameMarket,
} from './styles.match';
import { FinMatchContext } from '../PageFinmatch';

export function RegionMarketData() {
  const { data } = useContext(FinMatchContext);
  return (
    <Flex
      justify="space-between"
      direction="row"
      style={{ borderBottom: 'none' }}
    >
      <WrapperMarketData>
        <TitleDadosMercado />

        <ContainerMarketData>
          <ContentMarketData>
            {data.informacoes_Match.matchAlterSocietaria ? (
              <IconMarket>
                <IconCheck />
              </IconMarket>
            ) : (
              <IconMarket backgroundwarning>
                <IconWarn />
              </IconMarket>
            )}
            <InfoMarket>
              <NameMarket>Alteração Societária</NameMarket>
              <SubNameMarket>
                {data.informacoes_Match.alteracao_Societaria === '' &&
                  'Nada Consta'}
                {data.informacoes_Match.alteracao_Societaria &&
                  date(data.informacoes_Match.alteracao_Societaria)}
              </SubNameMarket>
            </InfoMarket>
          </ContentMarketData>

          <ContentMarketData>
            {data.informacoes_Match.matchCCF ? (
              <IconMarket>
                <IconCheck />
              </IconMarket>
            ) : (
              <IconMarket backgroundwarning>
                <IconWarn />
              </IconMarket>
            )}
            <InfoMarket>
              <NameMarket>CCF</NameMarket>
              <SubNameMarket>
                {data.informacoes_Match.ccf || 'Nada Consta'}
              </SubNameMarket>
            </InfoMarket>
          </ContentMarketData>
        </ContainerMarketData>

        <ContainerMarketData>
          <ContentMarketData>
            {data.informacoes_Match.matchLiminar ? (
              <IconMarket>
                <IconCheck />
              </IconMarket>
            ) : (
              <IconMarket backgroundwarning>
                <IconWarn />
              </IconMarket>
            )}
            <InfoMarket>
              <NameMarket>Liminar</NameMarket>
              <SubNameMarket>
                {data.informacoes_Match.liminar === false && 'Nada Consta'}
                {data.informacoes_Match.liminar === true &&
                  'Indicação de Liminar'}
              </SubNameMarket>
            </InfoMarket>
          </ContentMarketData>

          <ContentMarketData>
            {data.informacoes_Match.matchRefin ? (
              <IconMarket>
                <IconCheck />
              </IconMarket>
            ) : (
              <IconMarket backgroundwarning>
                <IconWarn />
              </IconMarket>
            )}
            <InfoMarket>
              <NameMarket>Refin</NameMarket>
              <SubNameMarket>
                {data.informacoes_Match.refin === 0 && 'Nada Consta'}
                {data.informacoes_Match.refin > 0 &&
                  currency(data.informacoes_Match.refin)}
              </SubNameMarket>
            </InfoMarket>
          </ContentMarketData>
        </ContainerMarketData>

        <ContainerMarketData>
          <ContentMarketData>
            {data.informacoes_Match.matchPfin ? (
              <IconMarket>
                <IconCheck />
              </IconMarket>
            ) : (
              <IconMarket backgroundwarning>
                <IconWarn />
              </IconMarket>
            )}
            <InfoMarket>
              <NameMarket>Pefin</NameMarket>
              <SubNameMarket>
                {data.informacoes_Match.pfin === 0 && 'Nada Consta'}
                {data.informacoes_Match.pfin > 0 &&
                  currency(data.informacoes_Match.pfin)}
              </SubNameMarket>
            </InfoMarket>
          </ContentMarketData>
        </ContainerMarketData>
      </WrapperMarketData>
    </Flex>
  );
}

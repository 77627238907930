import React from 'react';
import PropTypes from 'prop-types';

import Hover from './Hover';
import TooltipItem from './TooltipItem';

const TooltipDefault = ({
  data,
  bgcolor,
  maxWidth,
  position,
  align,
  largura,
  children,
  style,
}) => {
  return (
    <Hover
      style={style}
      onHover={
        <TooltipItem
          bgcolor={bgcolor || '#333333'}
          maxWidth={maxWidth}
          data={data}
          position={position}
          align={align}
          largura={largura}
        />
      }
      position={position}
      align={align}
      largura={largura}
    >
      {children}
    </Hover>
  );
};

TooltipDefault.propTypes = {
  data: PropTypes.string.isRequired,
  bgcolor: PropTypes.string.isRequired,
  maxWidth: PropTypes.string,
  position: PropTypes.string.isRequired,
  align: PropTypes.string,
  children: PropTypes.any.isRequired,
};

export default TooltipDefault;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';

import { useHistory, useLocation } from 'react-router-dom';

import { icons } from 'assets/icones/finplaceIcons';
import { Wrapper } from 'components/FinWrappers';
import { finTheme } from 'finTheme';
import { StringToSvg } from 'helpers/svgFiles/StringToSvg';
import { SeparadorVertical } from 'components/FinSeparador';
// import Counter from 'components/FinCounter';
import { Creators as searchRouterActions } from 'store/ducks/searchRouter';
import { useActions } from 'hooks/useActions';
import { TopBar } from './Styles';
import ButtonsIcons from './ButtonsIcons';

const WrapperCuston = styled(Wrapper)`
  border-right: 1px solid ${({ theme }) => theme.newtheme.color.cinza2};
  padding: 0;
  width: 60px;
  height: 56px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  &:hover {
    background-color: #fff;
    border-bottom: 1px solid ${({ theme }) => theme.newtheme.color.cinza2};
  }
`;

const BarAction = ({ openMenu }) => {
  const history = useHistory();
  const location = useLocation();

  const queryState = useSelector((state) => state.router.location.query);
  const searchRoute = useActions(searchRouterActions);

  const updateOrder = useCallback(() => {
    searchRoute.addSearchRoute({
      order: queryState.order === 'a-z' ? 'z-a' : 'a-z',
    });
  }, [searchRoute, queryState]);

  return (
    <TopBar>
      <WrapperCuston onClick={openMenu}>
        <Wrapper pwidth="16px" pheight="16px" padding="0">
          <StringToSvg
            viewBox="0 0 18 18"
            width="100%"
            height="100%"
            strokeWidth={'1'}
            stroke={finTheme.newtheme.color.azulPrimario}
          >
            {icons.filter}
          </StringToSvg>
        </Wrapper>
      </WrapperCuston>
      <Wrapper padding="0">
        <ButtonsIcons
          icon={icons.user}
          onclick={() =>
            history.push('/convites', {
              background: location,
            })
          }
        >
          Convites
        </ButtonsIcons>
        {/* <ButtonsIcons icon={icons.user} onclick={() => console.log('clicked')}>
          Convites recebidos <Counter pbgcolor="white">3</Counter>
        </ButtonsIcons>
        <ButtonsIcons icon={icons.gear} onclick={() => console.log('clicked')}>
          Gerenciar convites
  </ButtonsIcons> */}
        <SeparadorVertical />
        <ButtonsIcons icon={icons.switchVertical} onclick={() => updateOrder()}>
          Ordenar: {queryState.order || 'a-z'}
        </ButtonsIcons>
      </Wrapper>
    </TopBar>
  );
};

BarAction.propTypes = {
  openMenu: PropTypes.func.isRequired,
};

BarAction.defaultProps = {
  container: 'padrao',
};

export default BarAction;

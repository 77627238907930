import React from 'react';

import { InteresseCredito } from './InteresseCredito';
import { TiposVendas } from './TiposVendas';
import { Galeria } from './Galeria';
import { QuadroSocietario } from './QuadroSocietario';
import { Documentos } from './Documentos';
import { ContaBancaria } from './ContaBancaria';
import { Contato } from './Contato';
import { SobreEmpresa } from './SobreEmpresa';
import { Filiais } from './Filiais';
import { Video } from './Video';

export function PagePerfil() {
  return (
    <>
      <InteresseCredito />
      <TiposVendas />
      <QuadroSocietario />
      <Filiais />
      <Documentos />
      <Contato />
      <ContaBancaria />
      <SobreEmpresa />
      <Galeria />
      <Video />
    </>
  );
}

import React from 'react';

import { ReactComponent as IconFilter } from 'assets/images/icon-adjustments.svg';
import { SeparatorVertical } from 'components/FinSeparador';
import { useQueryState } from 'hooks/useQueryState';
import { useLocation } from 'react-router-dom';
import { Header, RegionFilters } from '../../style.layout';

import { MainMenu } from '../MainMenu';
import { Bullet } from '../Bullet';

import { NWButton } from '../Button';
import { NWDropdown } from '../Dropdown';
import { Search } from '../Search';

import { MinhaRedeContext } from '../../PaginaMinhaRede';

export const HeaderFilters = () => {
  const { setQueriesAdd, getQueries } = useQueryState();

  const location = useLocation();

  const { setOpenFilters } = React.useContext(MinhaRedeContext);

  let counter = 0;

  const ordem = getQueries('ordem');

  const queries = getQueries();

  const excludeCount = ['skip', 'invite', 'ordem'];

  Object.keys(queries).forEach((item) => {
    if (excludeCount.indexOf(item) === -1) {
      if (Array.isArray(queries[item])) {
        counter += queries[item].length;
      } else {
        counter += 1;
      }
    }
  });

  const isStart = location.pathname === '/minha-rede/inicio';

  const changeQuery = (e) => setQueriesAdd({ ordem: e.target.value });
  return (
    <Header>
      <Search />
      <RegionFilters>
        {!isStart && (
          <>
            <NWButton.Default onClick={() => setOpenFilters((state) => !state)}>
              <IconFilter />
              <span style={{ margin: '0 0.5rem' }}>Filtros</span>
              {counter > 0 && (
                <Bullet bgColor="#000000" color="white">
                  {counter}
                </Bullet>
              )}
            </NWButton.Default>
            <SeparatorVertical pborderColor="#BCBCC0" />
            <NWDropdown.Select
              largura="150px"
              value={ordem || 2}
              onChange={(e) => changeQuery(e)}
            >
              <NWDropdown.Option disabled>Ordenar</NWDropdown.Option>
              <NWDropdown.Option value={2}>A - Z</NWDropdown.Option>
              <NWDropdown.Option value={1}>Z - A</NWDropdown.Option>
            </NWDropdown.Select>
            <SeparatorVertical pborderColor="#BCBCC0" />
          </>
        )}
        <MainMenu />
      </RegionFilters>
    </Header>
  );
};

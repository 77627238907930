import { useQuery } from 'react-query';
import axios from 'axios';

import CONFIG from 'config';
import { useLocation } from 'react-router-dom';

import { useQueryState } from 'hooks/useQueryState';
import { getUserData } from 'services/getUserData';
import queryString from 'query-string';

const baseUrl = CONFIG.API_REMODELING_URL;

const typesInvites = {
  received: 2,
  sent: 1,
};

const formatFilter = (params) => {
  const filters = params;
  if (filters.perfil) {
    if (filters.perfil.indexOf('completo') !== -1) {
      filters.perfilCompleto = true;
    }

    if (filters.perfil.indexOf('docCompleto') !== -1) {
      filters.documentoCompleto = true;
    }

    delete filters.perfil;
  }

  if (filters.status) {
    const ids = {
      online: 0,
      offline: 1,
    };

    const values = filters.status.map((item) => ids[item]);
    filters.statusGrupo = values;

    delete filters.status;
  }

  if (filters.habilitacao) {
    const ids = {
      pedente: 1,
      naoHabilitado: 2,
      habilitado: 3,
    };

    const values = filters.habilitacao.map((item) => ids[item]);
    filters.statusHabilitado = values;

    delete filters.habilitacao;
  }

  if (filters.matching) {
    const ids = {
      match: 0,
      indicacao: 1,
    };

    const values = filters.matching.map((item) => ids[item]);
    filters.matching = values;
  }

  if (filters.skip) {
    delete filters.skip;
  }
  return filters;
};

const fecthInvites = async (params) => {
  const { idEmpresa } = await getUserData();

  const payload = {
    size: params.size,
    skip: params.skip,
    filter: { ...params.filter },
  };

  const response = axios.post(
    `${baseUrl}/business-api/api/matching/conexao/financiador/convites/${idEmpresa}`,
    payload
  );
  const result = await response;
  return result.data || [];
};

export const useQueryInvites = () => {
  const { getQueries } = useQueryState();
  const { search } = useLocation();

  const query = queryString.parse(search);
  const { invite } = query;

  const filters = getQueries();
  delete filters.invite;

  const payload = {
    size: 8,
    skip: filters.skip || 1,
    filter: {
      ordem: 2,
      tipoConvite: typesInvites[invite],
      ...formatFilter(filters),
    },
  };

  return useQuery(['invitations', payload], () => fecthInvites(payload), {
    keepPreviousData: false,
  });
};

export const useQueryInvitesReceived = () => {
  const payload = {
    size: 4,
    skip: 1,
    filter: {
      ordem: 2,
      tipoConvite: 2,
    },
  };

  return useQuery(
    ['invitations', 'received', payload],
    () => fecthInvites(payload),
    {
      keepPreviousData: false,
    }
  );
};

export const useQueryInvitesNotifyCount = () => {
  const payload = {
    size: 5,
    skip: 1,
    filter: {
      ordem: 2,
      tipoConvite: 2,
    },
  };

  return useQuery(
    ['invitations', 'received', 'count', payload],
    () => fecthInvites(payload),
    {
      keepPreviousData: false,
      refetchInterval: 18000,
    }
  );
};

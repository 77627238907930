/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { createActions, createReducer } from 'reduxsauce';
import { put, take, fork, cancel, cancelled } from 'redux-saga/effects';

import { Creators as uiToasterActions } from 'store/ducks/uiToaster';

/**
 * Criando action types & creators
 */

export const { Types, Creators } = createActions({
  apiGeocodeRequest: ['address'],
  apiGeocodeSuccess: ['data'],
  apiGeocodeError: [],
  apiGeocodeCancel: [],
});

/**
 * Criando os reducer handlers
 */

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: false,
};

const apiGetRequest = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: true,
    data: [],
    error: false,
  };
};

const apiGetSuccess = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: [action.data],
    error: false,
  };
};

const apiGetError = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: [],
    error: true,
  };
};

const apiGetCancel = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: [],
    error: false,
  };
};

/**
 * Criando reducer
 */

export default createReducer(INITIAL_STATE, {
  [Types.API_GEOCODE_REQUEST]: apiGetRequest,
  [Types.API_GEOCODE_SUCCESS]: apiGetSuccess,
  [Types.API_GEOCODE_ERROR]: apiGetError,
  [Types.API_GEOCODE_CANCEL]: apiGetCancel,
});

/**
 * Sagas
 */

function* sagaGetAsynResult(params) {
  const controller = new AbortController();
  const { signal } = controller;

  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${params.address}&key=AIzaSyBx1Zn0K7TEFoSGTqD4VjD2LmfmlfPwvA8`;
  try {
    const response = yield fetch(url, {
      method: 'get',
      signal,
    }).then((response) => response.json());

    yield put(Creators.apiGeocodeSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(uiToasterActions.uiToasterMessage(error, 'error'));
    yield put(Creators.apiGeocodeError());
  } finally {
    if (yield cancelled()) {
      console.log('cancel');
      controller.abort();
    }
  }
}

export function* sagaApiGetGeocode(action) {
  const params = {
    address: action.address,
  };

  const task = yield fork(sagaGetAsynResult, params);

  yield take(Types.API_GEOCODE_CANCEL);
  yield cancel(task);
}

/* eslint-disable no-console */
import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Creators } from 'store/ducks/uiNotificationContent';

import {
  hubConnectionNotificacao,
  limparConexoes,
  signalStart,
  signalrRecebeNotificacao,
} from './signalrService';

import { useNotification } from './useNotification';

export const Notification = () => {
  const toast = useNotification();
  const dispatch = useDispatch();

  useEffect(() => {
    hubConnectionNotificacao.on(signalrRecebeNotificacao, (notificacao) => {
      const values = JSON.parse(notificacao);

      console.log('[DEBUG]', 'notificacao', values);

      dispatch(Creators.save(values, values.tipo));

      if (values.tipo) {
        toast.show({ data: values, position: 'top-right' });
      }
    });

    signalStart(hubConnectionNotificacao);

    hubConnectionNotificacao.onclose(async () => {
      await signalStart(hubConnectionNotificacao);
    });

    return () => {
      hubConnectionNotificacao.off(signalrRecebeNotificacao);

      limparConexoes([hubConnectionNotificacao]);
    };
  }, [toast, dispatch]);

  return null;
};

import axios from 'axios';
import { useQuery } from 'react-query';
import CONFIG from 'config';

import { dashboardKeys } from '../queryKeyFactory';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/`;

const fetchFaturamentoDiario = async (params) => {
  const fetchData = axios.post(
    `${BASE_URL}api/cedente/titulo/faturamentos/dia/${params.idEmpresa}`
  );

  const result = await fetchData;
  const { data } = result;

  return data.data ? data.data : [];
};

export const useChartFaturamentoDiario = (params) => {
  return useQuery(
    dashboardKeys.faturamentoDiario(params),
    () => fetchFaturamentoDiario(params),
    {
      keepPreviousData: false,
    }
  );
};

import { createStitches } from '@stitches/react';

const { styled } = createStitches({
  utils: {
    icon: (value) => ({
      background: `url(${value}) no-repeat center left`,
    }),
  },
});

export const Item = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0.5rem 1rem',
  transition: 'background 0.2s ease-in-out',
  cursor: 'pointer',
  borderRadius: '10px',
  '&:hover': {
    backgroundColor: 'var(--fingray-300)',
  },
});

export const ItemLabel = styled('span', {
  position: 'relative',
  fontFamily: 'Inter',
  fontWeight: 'normal',
  fontSize: '1rem',
  textAlign: 'left',
  color: 'var(--fingray-900)',
  textIndent: '42px',
  minWidth: '140px',
  marginRight: '1.5rem',
});

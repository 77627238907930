import axios from 'axios';
import { useQuery } from 'react-query';
import CONFIG from 'config';
import { getUserData } from 'services/getUserData';
// import toast from 'react-hot-toast';

import { useDispatch, useSelector } from 'react-redux';
import { CreatorsUiMessages } from 'store/ducks/uiMessages';
import { dashboardKeys } from './queryKeyFactory';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/`;

/**
 * Faturamento mensal
 */

const fetchFaturamentoMensal = async (params) => {
  const { idEmpresa } = await getUserData();

  if (idEmpresa) {
    const fetchData = axios.post(
      `${BASE_URL}api/cedente/titulo/faturamentos/mensal/${params.idEmpresa}`,
      { ano: params.ano }
    );

    /* toast.promise(fetchData, {
      loading: 'Loading',
      success: 'Faturamento carregado sucesso',
      error: error => {
        if (error.response.status === 400) {
          return `Erro: ${error.response.data.message.toString()}`;
        }
        return `Erro: ${error.toString()}`;
      },
    }); */

    const result = await fetchData;
    const { data } = result;

    return data.data ? data.data : [];
  }

  return [];
};

export const useFaturamentoMensal = (params) => {
  return useQuery(
    dashboardKeys.faturamentoMensal(params),
    () => fetchFaturamentoMensal(params),
    {
      keepPreviousData: false,
      // eslint-disable-next-line no-unused-vars
      onSuccess: (data) => {
        // console.log(data);
      },
    }
  );
};

/**
 * Faturamento dia
 */

const fetchFaturamentoDiario = async (params) => {
  const { idEmpresa } = await getUserData();

  if (idEmpresa) {
    const fetchData = axios.post(
      `${BASE_URL}api/cedente/titulo/faturamentos/dia/${params.idEmpresa}`
    );

    /* toast.promise(fetchData, {
      loading: 'Loading',
      success: 'Faturamento carregado sucesso',
      error: error => {
        if (error.response.status === 400) {
          return `Erro: ${error.response.data.message.toString()}`;
        }
        return `Erro: ${error.toString()}`;
      },
    }); */

    const result = await fetchData;
    const { data } = result;

    return data.data ? data.data : [];
  }

  return [];
};

export const useFaturamentoDiario = (params) => {
  return useQuery(
    dashboardKeys.faturamentoDiario(params),
    () => fetchFaturamentoDiario(params),
    {
      keepPreviousData: false,
      // eslint-disable-next-line no-unused-vars
      onSuccess: (data) => {
        // console.log(data);
      },
    }
  );
};

/**
 * Concentração Sacado
 */

const fetchConcentracaoSacado = async (params) => {
  const { idEmpresa } = await getUserData();

  if (idEmpresa) {
    const fetchData = axios.post(
      `${BASE_URL}api/cedente/titulo/faturamentos/sacados/${params.idEmpresa}`
    );

    /* toast.promise(fetchData, {
      loading: 'Loading',
      success: 'Faturamento carregado sucesso',
      error: error => {
        if (error.response.status === 400) {
          return `Erro: ${error.response.data.message.toString()}`;
        }
        return `Erro: ${error.toString()}`;
      },
    }); */

    const result = await fetchData;
    const { data } = result;

    return data.data ? data.data : [];
  }

  return [];
};

export const useConcentracaoSacado = (params) => {
  return useQuery(
    dashboardKeys.sacadoConcentracao(params),
    () => fetchConcentracaoSacado(params),
    {
      keepPreviousData: false,
      // eslint-disable-next-line no-unused-vars
      onSuccess: (data) => {
        // console.log(data);
      },
    }
  );
};

const fetchListFiliais = async (IdGrupo) => {
  const { idEmpresa } = await getUserData();

  const fetchData = axios.get(
    `${BASE_URL}api/matching/convenio/perfil/empresas/grupo/${IdGrupo}/${idEmpresa}`
  );

  const result = await fetchData;
  const { data } = result;

  return data.data ? data.data : [];
};

export const useListFiliais = (params) => {
  return useQuery(['filiais', params], () => fetchListFiliais(params), {
    keepPreviousData: false,
    // eslint-disable-next-line no-unused-vars
    onSuccess: (data) => {
      // console.log(data);
    },
  });
};

const fetchPeril = async (params) => {
  const { idEmpresa } = await getUserData();

  const values = {
    idGrupo: Number(params.value.split('-')[1]),
    idConvenio: idEmpresa,
    idCedente: Number(params.value.split('-')[0]),
  };

  const fetchData = axios.post(
    `${BASE_URL}api/matching/convenio/perfil/empreendedores`,
    values
  );

  const result = await fetchData;
  const { data } = result;

  return data.data ? data.data : [];
};

export const usePerfil = (params) => {
  const dispatch = useDispatch();
  const { messages } = useSelector((state) => state.uiMessages);

  return useQuery(['filiais', params], () => fetchPeril(params), {
    keepPreviousData: true,
    enabled: !!params,
    // eslint-disable-next-line no-unused-vars
    onSuccess: (data) => {
      if (messages.length > 0) {
        dispatch(CreatorsUiMessages.uiRemoveMessage(messages));
      }
    },
  });
};

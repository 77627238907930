/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { createActions, createReducer } from 'reduxsauce';

/**
 * Criando action types & creators
 */

export const { Types, Creators } = createActions({
  uiPagination: ['data'],
});

/**
 * Criando os reducer handlers
 */

const INITIAL_STATE = {
  data: [],
};

const setUiPagination = (state = INITIAL_STATE, action) => {
  return {
    data: [action.data],
  };
};

/**
 * Criando reducer
 */

export default createReducer(INITIAL_STATE, {
  [Types.UI_PAGINATION]: setUiPagination,
});

import React from 'react';

import { useQueryState } from 'hooks/useQueryState';
import { useRenderResponseData } from 'hooks/useRenderResponseData';
import { NWBreadcrumb, NWBreadcrumbItem } from 'components/NWBreadcrumb';
import { RegionListCards } from '../style.layout';

import { Card, List, ItemList } from '../Comps/Card';
import { NWPagination } from '../Comps/Pagination';

import { useQueryClientsBlocked } from '../hook/useQueryClients';

export function Bloqueados({ executeScroll }) {
  const { getQueries, setQueriesAdd } = useQueryState();

  const skip = getQueries('skip') || 1;

  const { data, isLoading, error } = useQueryClientsBlocked();

  const { PlaceNoResult, PlaceLoader, PlaceError } = useRenderResponseData({
    data,
    isLoading,
    error,
  });

  const withData = data && data.data;

  const changePage = (page) => {
    setQueriesAdd({ skip: page });
    executeScroll();
  };

  return (
    <>
      <RegionListCards>
        <NWBreadcrumb>
          <NWBreadcrumbItem to="/minha-rede">Minha Rede</NWBreadcrumbItem>
          <NWBreadcrumbItem noIcon>Bloqueados</NWBreadcrumbItem>
        </NWBreadcrumb>
        <PlaceNoResult />
        <PlaceLoader />
        <PlaceError />
        <List direction="vertical">
          {withData &&
            data.data.map((item) => {
              return (
                <ItemList key={item.idGrupo}>
                  <Card isLocked data={item} removeMenuContext />
                </ItemList>
              );
            })}
        </List>
      </RegionListCards>
      <NWPagination
        totalPages={data && data.totalPages}
        page={skip}
        changePage={({ page }) => changePage(page)}
        place="center"
      />
    </>
  );
}

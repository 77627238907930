import React from 'react';
import ApprovedCredit from 'assets/icones/icone-money-step.svg';
import RefusedStep from 'assets/icones/icone-refused-step.svg';

import { Step } from './Step';

export const CreditoRecusado = () => {
  return (
    <Step
      bgcolor="#FDDCDC"
      icon={ApprovedCredit}
      statuicon={RefusedStep}
      label="Crédito"
      labelsecundaria="Recusado"
    />
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useContext } from 'react';

import { SeparatorHorizontal } from 'components/FinSeparador';
import { NWButton } from 'pages/MinhaRede/Comps/Button';
import { ReactComponent as IconError } from 'assets/images/icon-close-flat.svg';
import { ReactComponent as IconCheck } from 'assets/images/icon-checkcircle.svg';
import { Ring } from '@uiball/loaders';
import { ReactComponent as IconWarning } from 'assets/images/WarningFlat.svg';
import { TargetStep, RenderSteps } from 'components/FinWizard';
import { Step1, Step2, Step3 } from 'components/FinWizard/Fincredit';
import { Limite } from '../Comps/Limite';
import { Tranche } from '../Comps/Tranche';
import { Motivo } from '../Comps/Motivo';
import { ConcentracaoMaxima } from '../Comps/ConcentracaoMaxima';
import { Title, Text, Flex, Accordion } from './style.credito';
import { ButtonStretch } from '../Comps/ButtonStretch';

import { CreditoContext, statusCondition } from './reducer';
import { useErrosForm } from '../../hooks/useErrosForm';

import {
  useMutateAprovacaoCredito,
  useMutateRecusarCredito,
} from '../../hooks/mutate/useMutateAprovacaoCredito';

export const CreditoPendente = (props) => {
  const { idGrupo } = props;
  const inputLimite = useRef();
  const inputTranche = useRef();
  const inputConcentracao = useRef();
  const inputMotivo = useRef();

  const { erros, changeErros } = useErrosForm();
  const aprovacaoCredito = useMutateAprovacaoCredito();
  const recusarCredito = useMutateRecusarCredito();

  const [values, actions] = useContext(CreditoContext);

  const { changeAccordion, changeCondition } = actions;

  const {
    fields,
    active: { condition, accordionOpen },
  } = values;

  const validation = React.useCallback(
    (list) => {
      if (Array.isArray(list)) {
        const ls = [];
        list.forEach((res) => {
          if (fields[res] === 0 || fields[res] === '') {
            ls.push({ name: res, id: 1 });
          }
        });
        changeErros(ls);
        return ls.length > 0;
      }

      return null;
    },
    [changeErros, fields]
  );

  const aceitar = React.useCallback(() => {
    const erro = validation(['limite', 'tranche', 'concentracao', 'motivo']);
    if (!erro) {
      aprovacaoCredito.mutate({
        ...fields,
        idGrupo,
        changeCondition: () => changeCondition(statusCondition.reading),
      });
    }
  }, [changeCondition, idGrupo, aprovacaoCredito, validation, fields]);

  const recusar = React.useCallback(() => {
    const erro = validation(['motivo']);
    if (!erro) {
      recusarCredito.mutate({
        motivo: fields.motivo,
        idGrupo,
        changeCondition: () => changeCondition(statusCondition.reading),
      });
    }
  }, [changeCondition, idGrupo, recusarCredito, validation]);

  const isDisabled = condition === statusCondition.reading;

  React.useEffect(() => {
    changeCondition(statusCondition.editing);
  }, []);

  return (
    <>
      <RenderSteps
        steps={[<Step1 position="top" />, <Step2 position="top" />, <Step3 />]}
      />
      <TargetStep id="target3" style={{ width: '100%' }}>
        <Flex justify="space-between">
          <Flex justify="flex-start">
            <IconWarning />
            <Title
              style={{
                marginLeft: '0.8rem' /* color: 'var(--finyellow-500)' */,
              }}
            >
              Crédito não definido
            </Title>
          </Flex>
          <ButtonStretch
            action={() => changeAccordion(!accordionOpen)}
            isOpen={accordionOpen}
          />
        </Flex>
        <Accordion open={accordionOpen}>
          <Flex column>
            <Text>
              Gerencie o status de aprovação e limite de crédito de seus
              clientes.
            </Text>
            <SeparatorHorizontal ppadding="0.4rem" />
            <Limite
              ref={inputLimite}
              value={fields.limite}
              disabled={isDisabled}
              error={erros && erros.limite}
              onChangeEvent={(e, m, v) => actions.setLimite(v)}
              onFocus={() => changeErros([])}
            />
            <Tranche
              ref={inputTranche}
              value={fields.tranche}
              disabled={isDisabled}
              error={erros && erros.tranche}
              onChangeEvent={(e, m, v) => actions.setTranche(v)}
              onFocus={() => changeErros([])}
            />
            <ConcentracaoMaxima
              ref={inputConcentracao}
              value={fields.concentracao}
              disabled={isDisabled}
              error={erros && erros.concentracao}
              onChangeEvent={(e, m, v) => actions.setConcentracao(v)}
              onFocus={() => changeErros([])}
            />
            <Motivo
              ref={inputMotivo}
              value={fields.motivo}
              disabled={isDisabled}
              error={erros && erros.motivo}
              onChange={(e) => actions.setMotivo(e.target.value)}
              onFocus={() => changeErros([])}
            />
            <SeparatorHorizontal ppadding="0.4rem" />

            <NWButton.Primary
              largura="total"
              onClick={() => aceitar()}
              disabled={aprovacaoCredito.isLoading || recusarCredito.isLoading}
            >
              {aprovacaoCredito.isLoading ? (
                <Ring size={20} lineWeight={3} speed={2} color="white" />
              ) : (
                <>
                  <IconCheck />
                  <span style={{ marginLeft: '0.5rem' }}>Salvar/Aprovar</span>
                </>
              )}
            </NWButton.Primary>
            <SeparatorHorizontal ppadding="0.3rem" />

            <NWButton.Error
              onClick={() => recusar()}
              largura="total"
              disabled={recusarCredito.isLoading || aprovacaoCredito.isLoading}
            >
              {recusarCredito.isLoading ? (
                <Ring size={20} lineWeight={3} speed={2} color="white" />
              ) : (
                <>
                  <IconError />
                  <span style={{ marginLeft: '0.5rem' }}>Recusar</span>
                </>
              )}
            </NWButton.Error>
          </Flex>
        </Accordion>
      </TargetStep>
    </>
  );
};

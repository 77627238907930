import config from '../config';

export const oidcConfig = {
  authority: config.IDENTITY_BASE_URL,
  client_id: config.IS_CLIENT_ID,
  redirect_uri: `${config.IS_CLIENT_ROOT}signin-callback`,
  silent_redirect_uri: `${config.IS_CLIENT_ROOT}signin-callback`,
  post_logout_redirect_uri: `${config.POST_LOGOUT_REDIRECT_URI}`,
  response_type: 'code',
  scope: config.IS_CLIENT_SCOPE,
  automaticSilentRenew: false,
};

import React, { useContext } from 'react';
import { FinPlayer } from 'components/FinPlayer';
import { ReactComponent as IconPlay } from 'assets/images/icon-play-white.svg';
import placeholder from 'assets/images/video-placeholder.png';
import { ImageRenderer } from 'components/FinImageRenderer';
import { useGetImage } from 'hooks/useGetImage';
import previewVideo from 'assets/images/image-video.png';
import {
  Flex,
  Media,
  RegionVideoPlay,
  WrapperButton,
  ButtonPlay,
  LabelVideo,
  Thumbnail,
} from './style.video';
import { TitleVideo } from '../Comps/TitleHead';
import { PerfilContext } from '../PaginaFinCredit';

export function Video() {
  const { perfil } = useContext(PerfilContext);
  const video = perfil.data && perfil.data.midia;
  const visitaVirtual = perfil.data && perfil.data.midia.videoVisitaVirtual;
  const comiteDeQualificacao =
    perfil.data && perfil.data.midia.videoComiteQualificacao;

  const fullImage = useGetImage({
    url: null,
    qualidade: 20,
    fallback: video ? previewVideo : null,
  });

  return (
    <Flex column align="flex-start" style={{ marginBottom: '48px' }}>
      {visitaVirtual !== undefined || comiteDeQualificacao !== undefined ? (
        <TitleVideo />
      ) : null}

      <Flex style={{ marginTop: '13px' }}>
        {visitaVirtual !== undefined ? (
          <Media>
            <RegionVideoPlay>
              <WrapperButton>
                <FinPlayer src={visitaVirtual.link}>
                  <ButtonPlay>
                    <IconPlay />
                  </ButtonPlay>
                </FinPlayer>
                <LabelVideo>Visita virtual</LabelVideo>
              </WrapperButton>
              <Thumbnail>
                <ImageRenderer url={fullImage} thumb={placeholder} />
              </Thumbnail>
            </RegionVideoPlay>
          </Media>
        ) : null}

        {comiteDeQualificacao !== undefined ? (
          <Media>
            <RegionVideoPlay>
              <WrapperButton>
                <FinPlayer src={comiteDeQualificacao.link}>
                  <ButtonPlay>
                    <IconPlay />
                  </ButtonPlay>
                </FinPlayer>
                <LabelVideo>Comitê de qualificação</LabelVideo>
              </WrapperButton>
              <Thumbnail>
                <ImageRenderer url={fullImage} />
              </Thumbnail>
            </RegionVideoPlay>
          </Media>
        ) : null}
      </Flex>
    </Flex>
  );
}

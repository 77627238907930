import styled, { css } from 'styled-components/macro';
import media from 'styled-media-query';
import { Link } from 'react-router-dom';

import { darken, rgba, rem } from 'polished';
import { Theme, SIZES } from '../../theme';

export const MainButton = styled.button`
  min-width: auto;
  color: ${Theme.gray6};
  background-color: ${Theme.white};
  border-color: ${Theme.gray2};
  border-width: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: calc(0.5em - 2px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 2px);
  text-align: center;
  white-space: nowrap;
  border: 2px solid transparent;
  border-radius: 2px;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  font-weight: 500;
  height: ${rem(SIZES.medium)};
  position: relative;
  vertical-align: top;
  user-select: none;
  outline: none;
  transition: all 0.2s ease-out;
  &:disabled {
    background-color: ${Theme.gray1};
    color: ${Theme.gray3};
  }
  &:hover {
    background-color: ${darken(0.2, Theme.white)};
  }
  &:focus {
    box-shadow: ${`0 0 0 0.125em ${rgba(Theme.primary, 0.4)}`};
    border-color: ${Theme.primary};
    color: ${Theme.gray6};
  }
  &:active {
    border-color: ${Theme.gray6};
    color: ${Theme.gray6};
  }
  ${media.lessThan('medium')`
  /* screen width is less than 768px (medium) */
  flex-basis: 100%;
  margin:0.1rem 0;
`}
`;

export const Button = styled(MainButton)`
  background-color: ${({ type }) => (type ? Theme[type] : Theme.primary)};
  color: ${({ color }) => color || Theme.white};
  box-shadow: 0px 1px 8px -3px rgba(0, 0, 0, 0.16);
  height: ${({ size }) => size && rem(SIZES[size])};
  font-size: ${({ size }) => size === 'small' && `0.8rem`};
  min-width: ${({ largura }) => largura && largura};
  &:hover {
    background-color: ${({ type }) =>
      type ? darken(0.1, Theme[type]) : darken(0.1, Theme.primary)};
  }
  &:focus {
    box-shadow: ${({ type }) =>
      type && `0 0 0 0.125em ${rgba(Theme[type], 0.4)}`};
    border-color: ${({ type }) => type && Theme[type]};
    color: ${({ color }) => color || Theme.white};
  }
  &:active {
    border-color: ${({ type }) => type && Theme[type]};
    color: ${({ color }) => color || Theme.white};
  }
  ${({ desabilitar }) =>
    desabilitar &&
    css`
      pointer-events: none;
      background-color: ${Theme.gray3};
      &:focus,
      &:active {
        border-color: ${Theme.gray3};
      }
    `}
`;

export const ButtonOutline = styled(MainButton)`
  background-color: transparent;
  color: ${Theme.gray6};

  &:hover {
    background-color: ${({ type }) => type && Theme[type]};
  }
  &:focus {
    box-shadow: ${({ type }) =>
      type && `0 0 0 0.125em ${rgba(Theme[type], 0.4)}`};
    border-color: ${({ type }) => type && Theme[type]};
  }
  &:active {
    border-color: ${({ type }) => type && Theme[type]};
  }
`;

export const ButtonDelete = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  pointer-events: auto;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0;
  height: 34px;
  max-height: 34px;
  max-width: 34px;
  min-height: 34px;
  min-width: 34px;
  width: 34px;
  outline: none;
  position: absolute;
  right: 5px;
  top: 5px;
  vertical-align: top;

  &::before {
    background-color: #1f1f1f;
    content: '';
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform-origin: center center;
    height: 2px;
    width: 50%;
  }
  &::after {
    background-color: #1f1f1f;
    content: '';
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform-origin: center center;
    height: 50%;
    width: 2px;
  }
`;

export const MainButtonLink = styled(Link)`
  min-width: auto;
  outline: none;
  text-decoration: none;
  color: ${Theme.gray6};
  background-color: ${Theme.white};
  border-color: ${Theme.gray2};
  border-width: 2px;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(0.5em - 2px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 2px);
  text-align: center;
  white-space: nowrap;
  align-items: center;
  border: 2px solid transparent;
  border-radius: 2px;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  font-weight: 500;
  height: ${rem(SIZES.medium)};
  justify-content: center;
  position: relative;
  vertical-align: top;
  user-select: none;
  outline: none;
  transition: all 0.2s ease-out;
  &:disabled {
    background-color: ${Theme.gray1};
    color: ${Theme.gray3};
  }
  &:hover {
    background-color: ${darken(0.2, Theme.white)};
  }
  &:focus {
    box-shadow: ${`0 0 0 0.125em ${rgba(Theme.primary, 0.4)}`};
    border-color: ${Theme.primary};
    color: ${Theme.gray6};
  }
  &:active {
    border-color: ${Theme.gray6};
    color: ${Theme.gray6};
  }
  ${media.lessThan('medium')`
  /* screen width is less than 768px (medium) */
  flex-basis: 100%;
  margin:0.1rem 0;
`}
`;

export const ButtonLink = styled(MainButtonLink)`
  background-color: ${({ type }) => type && Theme[type]};
  color: ${({ color }) => color || Theme.white};
  box-shadow: 0px 1px 8px -3px rgba(0, 0, 0, 0.16);
  height: ${({ size }) => size && rem(SIZES[size])};
  font-size: ${({ size }) => size === 'small' && `0.8rem`};
  min-width: ${({ largura }) => largura && largura};
  &:hover {
    background-color: ${({ type }) => type && darken(0.1, Theme[type])};
    color: ${({ color }) => color || Theme.white};
  }
  &:focus {
    box-shadow: ${({ type }) =>
      type && `0 0 0 0.125em ${rgba(Theme[type], 0.4)}`};
    border-color: ${({ type }) => type && Theme[type]};
    color: ${({ color }) => color || Theme.white};
  }
  &:active {
    border-color: ${({ type }) => type && Theme[type]};
    color: ${({ color }) => color || Theme.white};
  }
`;

export const ButtonSubmit = styled(MainButton)`
  background-color: ${({ tipo }) => (tipo ? Theme[tipo] : Theme.primary)};
  color: ${({ color }) => color || Theme.white};
  box-shadow: 0px 1px 8px -3px rgba(0, 0, 0, 0.16);
  height: ${({ size }) => size && rem(SIZES[size])};
  font-size: ${({ size }) => size === 'small' && `0.8rem`};
  min-width: ${({ largura }) => largura && largura};
  &:hover {
    background-color: ${({ tipo }) =>
      tipo ? darken(0.1, Theme[tipo]) : darken(0.1, Theme.primary)};
  }
  &:focus {
    box-shadow: ${({ tipo }) =>
      tipo && `0 0 0 0.125em ${rgba(Theme[tipo], 0.4)}`};
    border-color: ${({ tipo }) => tipo && Theme[tipo]};
    color: ${({ color }) => color || Theme.white};
  }
  &:active {
    border-color: ${({ tipo }) => tipo && Theme[tipo]};
    color: ${({ color }) => color || Theme.white};
  }
  ${({ desabilitar }) =>
    desabilitar &&
    css`
      pointer-events: none;
      background-color: ${Theme.gray3};
      &:focus,
      &:active {
        border-color: ${Theme.gray3};
      }
    `}
`;

import React from 'react';
import { finTheme } from 'finTheme';
import feather from 'feather-icons/dist/icons.json';
import TagFakeButton from './TagFakeButton';

const TagInviteSent = () => (
  <TagFakeButton
    bgcolor={finTheme.newtheme.color.success}
    stroke={finTheme.newtheme.color.success}
    labelColor={finTheme.newtheme.color.cinza6}
    icon={feather.check}
    vBox="0 -2 30 30"
  >
    Convite enviado
  </TagFakeButton>
);

export default TagInviteSent;

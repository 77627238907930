import styled, { css } from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { Wrapper } from 'components/FinWrappers';

export const CustonWrapper = styled(Wrapper)`
  width: 100%;
  border-bottom: ${({ theme }) => `1px solid ${theme.newtheme.color.cinza1}`};
`;

export const List = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  width: 100%;
`;

export const Item = styled.li`
  padding: 0;
  margin: 0;
`;

export const Link = styled(NavLink)`
  padding: 0 2px;
  font-size: ${({ theme }) => theme.newtheme.font.subtitulo2.tamanho};
  line-height: 30px;
  color: ${({ theme }) => theme.newtheme.color.cinza6};
  text-align: center;
  white-space: nowrap;
  background-color: initial;
  border: 0;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 0.36s ease-in;

  white-space: nowrap;
  text-decoration: none;

  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none;
    transition-timing-function: ease-out;
    transition-duration: 0.12s;
  }

  &.active span {
    font-weight: 600;
    color: ${({ theme }) => theme.newtheme.color.cinza6};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: auto;
    `}
`;

export const WithOutLink = styled.span`
  padding: 0 2px;
  font-size: ${({ theme }) => theme.newtheme.font.subtitulo2.tamanho};
  line-height: 30px;
  color: ${({ theme }) => theme.newtheme.color.cinza6};
  text-align: center;
  white-space: nowrap;
  background-color: initial;
  border: 0;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 0.36s ease-in;

  white-space: nowrap;
  text-decoration: none;

  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none;
    transition-timing-function: ease-out;
    transition-duration: 0.12s;
  }

  &.active {
    font-weight: 600;
    color: ${({ theme }) => theme.newtheme.color.cinza6};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: default;
    `}
`;

import React from 'react';

import Skeleton from 'react-loading-skeleton';
import { NWTable } from '../../Comps/Table';

export function SkeletonLoader() {
  return (
    <>
      <NWTable.Tr>
        <NWTable.Td style={{ padding: '0.5rem' }}>
          <Skeleton />
        </NWTable.Td>
        <NWTable.Td style={{ padding: '0.5rem' }}>
          <Skeleton />
        </NWTable.Td>
        <NWTable.Td style={{ padding: '0.5rem' }}>
          <Skeleton />
        </NWTable.Td>
        <NWTable.Td style={{ padding: '0.5rem' }}>
          <Skeleton />
        </NWTable.Td>
        <NWTable.Td style={{ padding: '0.5rem' }}>
          <Skeleton />
        </NWTable.Td>
        <NWTable.Td style={{ padding: '0.5rem' }}>
          <Skeleton />
        </NWTable.Td>
        <NWTable.Td style={{ padding: '0.5rem' }}>
          <Skeleton />
        </NWTable.Td>
      </NWTable.Tr>
      <NWTable.Tr>
        <NWTable.Td style={{ padding: '0.5rem' }}>
          <Skeleton />
        </NWTable.Td>
        <NWTable.Td style={{ padding: '0.5rem' }}>
          <Skeleton />
        </NWTable.Td>
        <NWTable.Td style={{ padding: '0.5rem' }}>
          <Skeleton />
        </NWTable.Td>
        <NWTable.Td style={{ padding: '0.5rem' }}>
          <Skeleton />
        </NWTable.Td>
        <NWTable.Td style={{ padding: '0.5rem' }}>
          <Skeleton />
        </NWTable.Td>
        <NWTable.Td style={{ padding: '0.5rem' }}>
          <Skeleton />
        </NWTable.Td>
        <NWTable.Td style={{ padding: '0.5rem' }}>
          <Skeleton />
        </NWTable.Td>
      </NWTable.Tr>
      <NWTable.Tr>
        <NWTable.Td style={{ padding: '0.5rem' }}>
          <Skeleton />
        </NWTable.Td>
        <NWTable.Td style={{ padding: '0.5rem' }}>
          <Skeleton />
        </NWTable.Td>
        <NWTable.Td style={{ padding: '0.5rem' }}>
          <Skeleton />
        </NWTable.Td>
        <NWTable.Td style={{ padding: '0.5rem' }}>
          <Skeleton />
        </NWTable.Td>
        <NWTable.Td style={{ padding: '0.5rem' }}>
          <Skeleton />
        </NWTable.Td>
        <NWTable.Td style={{ padding: '0.5rem' }}>
          <Skeleton />
        </NWTable.Td>
        <NWTable.Td style={{ padding: '0.5rem' }}>
          <Skeleton />
        </NWTable.Td>
      </NWTable.Tr>
    </>
  );
}

import React from 'react';
import styled from 'styled-components/macro';

import { Caption } from 'components/FinParagraph';
import { Theme } from '../../theme';

const Thead = styled.thead`
  border-bottom: 1px solid ${Theme.gray2};
`;

const Tr = styled.tr`
  background-color: transparent;
`;

const Th = styled.th`
  padding: 0.8rem 0;
  text-transform: uppercase;
`;

const Head = ({ data, caption }) => {
  const renderContent = () => {
    let title = [];
    if (!caption) {
      const item = data[0];
      if (data.length > 0) {
        Object.keys(item).forEach((res, index) =>
          title.push(
            <Th key={index}>
              <Caption style={{ fontWeight: 'bold' }}>{res}</Caption>
            </Th>
          )
        );
      }
    } else {
      title = caption.map((res, index) => <Th key={index}>{res}</Th>);
      return title;
    }
    return title;
  };
  return (
    <Thead>
      <Tr>{renderContent()}</Tr>
    </Thead>
  );
};

export default Head;

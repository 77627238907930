import React, { forwardRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { Label, Flex, Info } from './style.limite';
import { InputCurrency } from '../Input';

export const Limite = forwardRef((props, ref) => {
  const { disabled } = props;
  return (
    <Flex column style={{ marginBottom: '10px' }}>
      <ReactTooltip
        id="info-limite"
        wrapper="span"
        place="right"
        type="dark"
        effect="solid"
      >
        <div style={{ width: '200px', wordBreak: 'break-word' }}>
          O limite é o valor máximo que você aprova de crédito para uma empresa.
        </div>
      </ReactTooltip>
      <Flex
        justify="flex-start"
        style={{ marginBottom: '8px', width: 'calc(100% - 6px)' }}
      >
        <Label>Limite</Label>
        <Info aria-label="Limite" data-tip data-for="info-limite" />
      </Flex>
      <InputCurrency disabled={disabled} ref={ref} {...props} />
    </Flex>
  );
});

import React from 'react';
import { Title6 } from 'components/FinHeadings';
import { Wrapper } from 'components/FinWrappers';

import { Caption } from 'components/FinParagraph';
import { currency, dateTime } from 'components/Formatter';

import {
  CardStyle,
  CardStyleHead,
  CardStyleBody,
  CardStyleFooter,
  IconMoneyStyle,
  StatusCircle,
} from './style';

import { STATUS_PAGAMENTO, STATUS_PAY_PROPOSTAS } from '../config';
import { Circle } from '../Ofertas/ExibePreco/style';

import { Status } from './Status';

import { useFetchSelecionaConvenio } from '../queries/useFetchSelecionaConvenio';
import { CardSkeleton } from './CardSkeleton';

const STEPS = {
  victory: ['isActive', 'isDisabled', 'isDisabled'],
  youConfirmed: ['isVisited', 'isActive', 'isDisabled'],
  heConfirmed: ['isVisited', 'isVisited', 'isVisited'],
};

export const PaymentContentCobrancaSimples = ({ data }) => {
  const { data: dados, isLoading } = useFetchSelecionaConvenio({
    idOperacao: data.idOperacao,
  });

  const isExistData = !isLoading && !!dados && !!dados.data;

  const status = isExistData
    ? STATUS_PAGAMENTO.find(
        (item) => item.value.indexOf(dados.data.statusPagamento.id) !== -1
      ).name
    : 'victory';

  const firstStage = STEPS[status][0];

  const styleCustom = {
    border: `1px solid rgb(33 206 221)`,
    backgroundColor: '#e7f9fb',
  };

  const currentStatus =
    isExistData &&
    STATUS_PAY_PROPOSTAS.find(
      (item) => item.id.indexOf(dados.data.statusPagamento.id) !== -1
    );

  return isLoading ? (
    <CardSkeleton />
  ) : (
    isExistData && (
      <CardStyle style={styleCustom}>
        <CardStyleHead>
          <Wrapper
            padding="0 0 4px 0"
            pwidth="100%"
            justifyContent="flex-start"
          >
            <Circle largura="50px" altura="50px">
              <IconMoneyStyle />
            </Circle>
            <Title6 style={{ margin: '0 0 0 0.4rem', fontWeight: 'bold' }}>
              Confirmação de Operação
            </Title6>
          </Wrapper>
        </CardStyleHead>
        <CardStyleBody>
          <Wrapper
            alignItems="flex-start"
            style={{
              backgroundColor: '#fff',
              borderRadius: '7px',
              padding: '1rem',
            }}
          >
            <Wrapper
              tipo="column"
              padding="0 10px"
              alignItems="flex-start"
              style={{ width: '100%' }}
            >
              <Wrapper
                tipo="row"
                style={{
                  width: '100%',
                  padding: '0',
                  justifyContent: 'space-between',
                }}
              >
                <Caption style={{ fontWeight: 'bold', lineHeight: '24px' }}>
                  Bruto Solicitado
                </Caption>
                <Caption style={{ lineHeight: '14px' }}>
                  {currency(data.nota.face)}
                </Caption>
              </Wrapper>

              <Wrapper
                tipo="row"
                style={{
                  width: '100%',
                  padding: '0',
                  justifyContent: 'space-between',
                }}
              >
                <Caption style={{ fontWeight: 'bold', lineHeight: '24px' }}>
                  Títulos Recusados
                </Caption>
                <Caption style={{ lineHeight: '14px' }}>
                  {currency(data.bordero.valorRecusado)}
                </Caption>
              </Wrapper>

              <Wrapper
                tipo="row"
                style={{
                  width: '100%',
                  padding: '0',
                  justifyContent: 'space-between',
                }}
              >
                <Caption style={{ fontWeight: 'bold', lineHeight: '24px' }}>
                  Líquido Acordado
                </Caption>
                <Caption style={{ lineHeight: '14px' }}>
                  {currency(dados.data.liquidoAcordado)}
                </Caption>
              </Wrapper>
            </Wrapper>
          </Wrapper>

          <Wrapper
            style={{ marginTop: '2rem' }}
            padding="0"
            justifyContent="center"
            tipo="column"
          >
            {firstStage !== 'isDisabled' && (
              <Wrapper padding="0 0 8px 0" justifyContent="flex-start">
                {dados.data.dtConfPagamento && (
                  <>
                    <StatusCircle
                      bgColor={firstStage === 'isActive' && '#70e5ef'}
                    />
                    <Caption style={{ width: '260px', lineHeight: '16px' }}>
                      {`Operação finalizada em ${dateTime(
                        dados.data.dtConfPagamento
                      )}`}
                    </Caption>
                  </>
                )}
              </Wrapper>
            )}
          </Wrapper>
        </CardStyleBody>
        <CardStyleFooter>
          <Status
            tipo={
              currentStatus.name === 'pagamentoConcluido'
                ? 'operacaoConcluida'
                : null
            }
          />
        </CardStyleFooter>
      </CardStyle>
    )
  );
};

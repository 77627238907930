import { history } from '../History';

export const tipos = {
  chat: {
    label: 'Chat',
    description: 'Abrir chat de vídeo',
    action: (data) => {
      const { id } = data;
      window.open(
        `https://meet.jit.si/Finplace-${id}`,
        'Chat Finplace',
        'height=600,width=700'
      );
    },
  },
  meeting: {
    label: 'Meeting',
    description: 'Abrir chat de vídeo',
    action: (data) => {
      const { idGrupo } = data;
      window.open(
        `https://meet.jit.si/Finplace-${idGrupo}`,
        'Chat Finplace',
        'height=600,width=700'
      );
    },
  },
  oferta: {
    label: 'Oferta',
    description: 'Carregar Ofertas',
    action: () => {
      const url = `/operacoes`;
      history.push(url);
    },
  },
  operacao: {
    label: 'Operação',
    description: 'Carregar operações',
    action: () => {
      const url = `/operacoes`;
      history.push(url);
    },
  },
  proposta: {
    label: 'Propostas',
    description: 'Carregar propostas',
    action: (data) => {
      const { id } = data;
      const url = `/propostas/${id}`;
      history.push(url);
    },
  },
  pagamento: {
    label: 'Pagamento',
    description: 'Carregar propostas',
    action: (data) => {
      const { id } = data;
      const url = `/propostas/${id}`;
      history.push(url);
    },
  },
  desistencia: {
    label: 'Desistencia',
    description: 'Carregar propostas',
    action: (data) => {
      const { id } = data;
      const url = `/propostas/${id}`;
      history.push(url);
    },
  },
  cancelada: {
    label: 'Cancelada',
    description: 'Carregar propostas',
    action: (data) => {
      const { id } = data;
      const url = `/propostas/${id}`;
      history.push(url);
    },
  },
  perdida: {
    label: 'Perdida',
    description: 'Carregar propostas',
    action: (data) => {
      const { id } = data;
      const url = `/propostas/${id}`;
      history.push(url);
    },
  },
  aceite: {
    label: 'Aceite',
    description: 'Carregar propostas',
    action: (data) => {
      const { id } = data;
      const url = `/propostas/${id}`;
      history.push(url);
    },
  },
  'solicitacao-analise': {
    label: 'Solicitação',
    description: 'Meus Clientes',
    action: () => {
      const url = `/finmatching`;
      history.push(url);
    },
  },
  'acesso-negado': {
    label: 'Acesso negado',
    description: 'Meus Clientes',
    action: () => {
      const url = `/finmatching`;
      history.push(url);
    },
  },
  'acesso-aceito': {
    label: 'Acesso aceito',
    description: 'Meus Clientes',
    action: () => {
      const url = `/finmatching`;
      history.push(url);
    },
  },
  'convite-recebido': {
    label: 'Convite recebido',
    description: 'Abrir convites',
    action: (data) => {
      const { idGrupo } = data;
      const url = `/fincredit/${idGrupo}/perfil`;
      history.push(url);
    },
  },
  'convite-negado': {
    label: 'Convite negado',
    description: 'Abrir cliente',
    action: (data) => {
      const { idGrupo } = data;
      const url = `/fincredit/${idGrupo}/perfil`;
      history.push(url);
    },
  },
  'convite-aceito': {
    label: 'Convite aceito',
    description: 'Abrir cliente',
    action: (data) => {
      const { idGrupo } = data;
      const url = `/fincredit/${idGrupo}/perfil`;
      history.push(url);
    },
  },
};

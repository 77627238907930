import React from 'react';

import { useHistory } from 'react-router-dom';

import iconUsers from 'assets/images/icon-users-outline.svg';
import iconEnvelop from 'assets/images/icon-envelope-open.svg';
import iconSend from 'assets/images/icon-paper-plane-right.svg';
import iconGraph from 'assets/images/icon-graph-small.svg';
import iconLocked from 'assets/images/icon-lock-key.svg';

import { ReactComponent as IconUserFlat } from 'assets/images/icon-users-flat.svg';
import { Popover, PopoverTrigger, PopoverContent } from '../Popover';
import { Item, ItemLabel } from './style.mainmenu';
import { Bullet } from '../Bullet';

import { routesPages } from '../../config';

import { useQueryCount } from '../../hook/useQueryCount';

export function MainMenu() {
  const [showPopover, setShowPopover] = React.useState(false);

  const { data, refetch } = useQueryCount();

  const withData = data && data.data ? data.data : null;

  const history = useHistory();
  const gotoPage = (path) => {
    history.push(path);
    setShowPopover(false);
  };

  React.useEffect(() => {
    refetch();
  }, [showPopover, refetch]);

  return (
    <Popover open={showPopover} onOpenChange={setShowPopover}>
      <PopoverTrigger>
        <IconUserFlat />
        <span style={{ marginLeft: '0.5rem' }}>Minha Rede</span>
      </PopoverTrigger>
      <PopoverContent align="end">
        <Item onClick={() => gotoPage(routesPages.clientes)}>
          <ItemLabel css={{ icon: iconUsers }}>Clientes</ItemLabel>
          {withData && <Bullet>{withData.clientes}</Bullet>}
        </Item>
        <Item onClick={() => gotoPage(routesPages.conviteRecebido)}>
          <ItemLabel css={{ icon: iconEnvelop }}>Convites Recebidos</ItemLabel>
          {withData && <Bullet>{withData.convitesRecebidos}</Bullet>}
        </Item>
        <Item onClick={() => gotoPage(routesPages.conviteEnviado)}>
          <ItemLabel css={{ icon: iconSend }}>Convites Enviados</ItemLabel>
          {withData && <Bullet>{withData.convitesEnviados}</Bullet>}
        </Item>
        <Item onClick={() => gotoPage(routesPages.conexoes)}>
          <ItemLabel css={{ icon: iconGraph }}>Minhas Conexões</ItemLabel>
          {withData && <Bullet>{withData.conexoes}</Bullet>}
        </Item>
        <Item onClick={() => gotoPage(routesPages.bloqueados)}>
          <ItemLabel css={{ icon: iconLocked }}>Bloqueados</ItemLabel>
          {withData && <Bullet>{withData.bloqueados}</Bullet>}
        </Item>
      </PopoverContent>
    </Popover>
  );
}

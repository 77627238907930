/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { createActions, createReducer } from 'reduxsauce';
import { call, put, take, fork, cancel, cancelled } from 'redux-saga/effects';
import axios, { CancelToken } from 'axios';

import { getUserData } from 'services/getUserData';
import { Creators as uiToasterActions } from 'store/ducks/uiToaster';
import { Creators as apiTermosAssinarActions } from './apiTermosAssinar';

import CONFIG from '../../config';

/**
 * Criando action types & creators
 */

export const { Types, Creators } = createActions({
  apiTermosNaoAssinadoRequest: [],
  apiTermosNaoAssinadoSuccess: ['data'],
  apiTermosNaoAssinadoError: [],
  apiTermosNaoAssinadoCancel: [],
});

/**
 * Criando os reducer handlers
 */

const INITIAL_STATE = {
  isLoading: null,
  data: null,
  error: false,
};

const apiNaoAssinadoRequest = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: true,
    data: null,
    error: false,
  };
};

const apiNaoAssinadoSuccess = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: action.data,
    error: false,
  };
};

const apiNaoAssinadoError = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: null,
    error: true,
  };
};

const apiNaoAssinadoCancel = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: null,
    error: false,
  };
};

/**
 * Criando reducer
 */

export default createReducer(INITIAL_STATE, {
  [Types.API_TERMOS_NAO_ASSINADO_REQUEST]: apiNaoAssinadoRequest,
  [Types.API_TERMOS_NAO_ASSINADO_SUCCESS]: apiNaoAssinadoSuccess,
  [Types.API_TERMOS_NAO_ASSINADO_ERROR]: apiNaoAssinadoError,
  [Types.API_TERMOS_NAO_ASSINADO_CANCEL]: apiNaoAssinadoCancel,
});

/**
 * Sagas
 */

function* sagaGetAsynResult(params, endpoint) {
  const source = CancelToken.source();
  const url = `${CONFIG.API_REMODELING_URL}/user-api${endpoint}/${params.idUsuario}`;
  try {
    const response = yield axios.get(url, {
      cancelToken: source.token,
    });

    console.log('[DEBUG]', 'response', response);

    if (response.data.data) {
      const current = response.data.data[0];

      yield put(Creators.apiTermosNaoAssinadoSuccess(current));

      yield put(apiTermosAssinarActions.apiTermosAssinarCancel());
    }
  } catch (error) {
    console.log(error);
    yield put(uiToasterActions.uiToasterMessage(error, 'error'));
    yield put(Creators.apiTermosNaoAssinadoError());
  } finally {
    if (yield cancelled()) {
      console.log('cancel');
      source.cancel();
    }
  }
}

export function* sagaApiTermosNaoAssinado(action) {
  const user = yield call(getUserData);

  if (user) {
    const params = {
      idUsuario: user.idUsuario,
    };

    const task = yield fork(sagaGetAsynResult, params, '/api/termo');

    yield take(Types.API_TERMOS_NAO_ASSINADO_CANCEL);
    yield cancel(task);
  } else {
    yield put(Creators.apiTermosNaoAssinadoError());
  }
}

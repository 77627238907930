import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Wrapper } from 'components/FinWrappers';
import { StringToSvg } from 'helpers/svgFiles/StringToSvg';
import feather from 'feather-icons/dist/icons.json';

import { finTheme } from 'finTheme';

const CustonWrapper = styled(Wrapper)`
  background-color: ${({ theme }) =>
    theme.newtheme.backgroundColor.azulPrimario};
  height: 56px;
  width: 70px;
  cursor: pointer;
`;

const ButtonHamburguer = ({ onClick }) => {
  return (
    <CustonWrapper onClick={onClick}>
      <StringToSvg
        viewBox="0 0 24 24"
        width="100%"
        height="100%"
        stroke={finTheme.newtheme.color.branco}
      >
        {feather.menu}
      </StringToSvg>
    </CustonWrapper>
  );
};

export default ButtonHamburguer;

/* eslint-disable no-console */
import axios from 'axios';
import { format } from 'date-fns';

import { put, call, select } from 'redux-saga/effects';

import { getUserData } from 'services/getUserData';
import {
  loadDataPregoesSuccess,
  loadDataPregoesFailure,
  aceitaPregoesSuccess,
  aceitaPregoesFailure,
  rejeitaPregoesSuccess,
  rejeitaPregoesFailure,
  loadCountRecorsDataSuccess,
  loadCountRecorsDataFailure,
  showLoading,
  hideLoading,
  showNotification,
  contentNotification,
  loadExibePrecoSucess,
  loadExibePrecoFailure,
} from 'store/old_actions/actions';

import { getPregoes /* , getDateMainSearch */ } from './selectors';

import config from '../../config';

/** *********************** Load pregoes saga ***************************** */
export function* loadPregoes(action) {
  // let user;
  try {
    yield put(showLoading());

    const dataUser = yield call(getUserData);

    /**
     * new endpoint from remodeling
     */

    const ordem = action.orderBy || { ordemDataInicio: 1 };
    const tipoAba = action.target ? action.target : 1;
    const size = action.quantidade || 5;
    const skip = action.page || 1;

    /**
     * Filters => ["busca": "string",  "dataInicioVigencia": "2021-08-16T19:59:03.436Z", "tipoPregao": "1 = BoletoPreImpresso"]
     */

    const payload = {
      size,
      skip,
      filter: {
        idConvenio: dataUser.idEmpresa,
        tipoAba,
        ...ordem,
        ...action.filters,
      },
    };
    const res = yield axios.post(
      `${config.API_REMODELING_URL}/business-api/api/pregao/proposta/ofertas/convenios`,
      payload
    );

    yield put(loadDataPregoesSuccess(res.data || []));

    // yield put(setDataMainSearch(action.dataInicial));
    yield put({
      type: 'LOAD_COUNT_RECORDS_DATA_REQUEST',
      tipoAba,
      filters: action.filters,
    });

    yield put(hideLoading());
  } catch (err) {
    console.log(err);
    yield put(
      contentNotification(
        `Erro!!! Name: ${err.name}Message :${err.message}`,
        2000
      )
    );
    yield put(showNotification());
    yield put(loadDataPregoesFailure(err.response));
    yield put(hideLoading());
  }
}

/** ***********************  Aceita pregoes saga ***************************** */
export function* aceitaPregoes(action) {
  try {
    yield put(showLoading());

    const dataUser = yield call(getUserData);

    const reqData = {
      params: {
        idPregao: action.value,
        idConvenio: dataUser.idEmpresa,
        idPessoaConvenio: dataUser.idUsuario,
      },
    };

    const res = yield axios.post(
      `${config.API_BASE_URL}/api/pregao/aceita`,
      null,
      reqData
    );

    // console.log(res.data);

    // Load Data pregoes
    const reqData2 = {
      params: {
        idConvenio: dataUser.idEmpresa,
        idPessoaConvenio: dataUser.idUsuario,
        dtInicio: format(new Date(), 'yyyy-MM-dd'),
      },
    };

    // console.log('Load Pregoes Post:' + JSON.stringify(reqData));

    /* const res2 = yield axios.post(
      config.API_BASE_URL + '/api/pregao/lista-convenio',
      null,
      { headers, ...reqData2 }
    ); */

    const res2 = yield axios.post(
      `${config.API_BASE_URL}/api/pregao/lista-convenio`,
      null,
      reqData2
    );

    // console.log(res2.data);
    yield put(loadDataPregoesSuccess(res2.data));

    yield put(aceitaPregoesSuccess(res.data));
    yield put(hideLoading());

    // Download Bordero
    const pregoes = yield select(getPregoes);
    const pregao = pregoes.filter((res) => {
      return res.idPregao === action.value;
    });

    const linkUrl = pregao[0].link;

    // console.log(linkUrl);

    if (linkUrl) {
      const a = document.createElement('a');
      a.href = linkUrl;
      a.download = linkUrl.substr(linkUrl.lastIndexOf('/') + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // Notification
      yield put(contentNotification('Download Concluido com sucesso!!!', 2000));
      yield put(showNotification());
    } else {
      throw new Error('Erro durante Download!!');
    }
  } catch (err) {
    console.log(err);
    yield put(
      contentNotification(
        `Erro!!! Name: ${err.name}Message :${err.message}`,
        2000
      )
    );
    yield put(showNotification());
    yield put(aceitaPregoesFailure(err.response));
    yield put(hideLoading());
  }
}

/** *********************** Rejeita pregoes saga ***************************** */
export function* rejeitaPregoes(action) {
  try {
    yield put(showLoading());

    const dataUser = yield call(getUserData);

    // console.log(dataUser);

    const reqData = {
      params: {
        idPregao: action.value,
        idConvenio: dataUser.idEmpresa,
        idPessoaConvenio: dataUser.idUsuario,
      },
    };

    const res = yield axios.post(
      `${config.API_BASE_URL}/api/pregao/rejeita`,
      null,
      reqData
    );

    // Load Data pregoes
    const reqData2 = {
      params: {
        idConvenio: dataUser.idEmpresa,
        idPessoaConvenio: dataUser.idUsuario,
        dtInicio: '2019-08-01',
      },
    };

    const res2 = yield axios.post(
      `${config.API_BASE_URL}/api/pregao/lista-convenio`,
      null,
      reqData2
    );

    // console.log(res2.data);
    yield put(loadDataPregoesSuccess(res2.data));

    yield put(rejeitaPregoesSuccess(res.data));
    yield put(hideLoading());
  } catch (err) {
    console.log(err);
    yield put(
      contentNotification(
        `Erro!!! Name: ${err.name}Message :${err.message}`,
        2000
      )
    );
    yield put(showNotification());
    yield put(rejeitaPregoesFailure(err.response));
    yield put(hideLoading());
  }
}

/** *********************** Contagem abas saga ***************************** */
// eslint-disable-next-line no-unused-vars
export function* loadRecordsCount(action) {
  try {
    const user = yield call(getUserData);

    const request = {
      idConvenio: user.idEmpresa,
      tipoAba: action.tipoAba,
      ...action.filters,
    };

    const res = yield axios.post(
      `${config.API_REMODELING_URL}/business-api/api/pregao/proposta/ofertas/abas/convenios`,
      request
    );

    yield put(loadCountRecorsDataSuccess(res.data.data));
  } catch (err) {
    console.log(err);
    yield put(loadCountRecorsDataFailure(err.response));
    yield put(
      contentNotification(
        `Erro!!! Name: ${err.name}Message :${err.message}`,
        2000
      )
    );
    yield put(showNotification());
  }
}

/** ***************************** Get exibe preco ******************************** */
export function* getExibepreco(action) {
  try {
    const reqData = {
      params: { idOperacao: action.idOperacao },
    };

    const res = yield axios.get(
      `${config.API_BASE_URL}/api/Faturamento/ExibePreco`,
      reqData
    );

    yield put(loadExibePrecoSucess(res.data));
  } catch (err) {
    yield put(loadExibePrecoFailure(err.response));
    yield put(
      contentNotification(
        `Erro!!! Name: ${err.name}Message :${err.message}`,
        2000
      )
    );
    yield put(showNotification());
  }
}

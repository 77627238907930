import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components/macro';
import { finTheme } from 'finTheme';

const Line = styled.div`
  background-color: ${({ theme, color }) =>
    color || theme.newtheme.color.cinza3};
  height: 100%;
  width: 5px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  border-color: ${({ theme, color }) => color || theme.newtheme.color.cinza3};
  border-radius: ${({ theme }) =>
    `${theme.newtheme.borderRadius} 0 0 ${theme.newtheme.borderRadius}`};
  opacity: 1;
  transition: opacity 300ms ease, background-color 300ms ease;
`;

const LineLeft = ({ tipo }) => {
  switch (tipo) {
    case 'primary':
      return <Line color={finTheme.newtheme.color.vermelhoPrimario} />;
    case 'success':
      return <Line color={finTheme.newtheme.color.success} />;
    case 'alert':
      return <Line color={finTheme.newtheme.color.alert} />;
    case 'error':
      return <Line color={finTheme.newtheme.color.error} />;
    case 'secondary':
      return <Line color={finTheme.newtheme.color.cinza3} />;
    case 'financier':
      return <Line color={finTheme.newtheme.color.azulPrimario} />;
    case 'hidden':
      return <Line color="transparent" />;
    default:
      return <Line />;
  }
};

LineLeft.propTypes = {
  tipo: PropTypes.string.isRequired,
};

export default LineLeft;

import styled, { css } from 'styled-components/macro';
import { Wrapper } from 'components/FinWrappers';
import { Box } from 'components/FinFrame/Box';

export const Container = styled(Wrapper)`
  padding: 0;
  position: absolute;
  left: 0;
  z-index: ${({ theme }) => theme.newtheme.zIndex.menuSearch};
  ${({ pos }) =>
    pos &&
    css`
      top: ${pos};
    `}
`;

export const BoxCuston = styled(Box)`
  box-shadow: ${({ theme }) => theme.newtheme.dropShadow.dropShadow5};
  min-height: 150px;
`;

export const ButtonClose = styled.button`
  background-color: transparent;
  border: none;
  text-decoration: none;
  outline: none;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 1rem;
  z-index: 2;
`;

export const More = styled.button`
  background-color: transparent;
  border: ${({ theme }) => `2px solid ${theme.newtheme.color.azulPrimario}`};
  text-decoration: none;
  outline: none;
  cursor: pointer;
  padding: 0.2rem 1rem;
  color: ${({ theme }) => theme.newtheme.color.azulPrimario};
  border-radius: 2.8rem;
  font-weight: 500;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;

  &:hover svg {
    stroke: ${({ theme }) => theme.newtheme.color.branco};
  }

  &:hover {
    background-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
    color: ${({ theme }) => theme.newtheme.color.branco};
  }
`;

import React from 'react';
import { MainPaymentContent } from './MainPaymentContent';
import { PaymentContentCobrancaSimples } from './PaymentContentCobrancaSimples';

import { TIPO_PREGAO } from '../config';

export const CardPagamento = ({ data }) => {
  if (TIPO_PREGAO.cobrancaSimples.includes(data.tipoPregao)) {
    return <PaymentContentCobrancaSimples data={data} />;
  }

  return <MainPaymentContent data={data} />;
};

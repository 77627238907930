import React from 'react';

import { PaginationButton, Arrow, PaginationLinkNext } from './styles';

export const LastButton = ({ onclick, last, visibility }) => (
  <PaginationButton visibility={visibility}>
    <PaginationLinkNext
      style={{ width: '36px' }}
      onClick={(e) => onclick(e, last)}
    >
      <Arrow />
      <Arrow />
    </PaginationLinkNext>
  </PaginationButton>
);

import React from 'react';
import styled from 'styled-components/macro';
import AsyncSelect from 'react-select/async';

import { Caption } from 'components/FinParagraph';

import { customStyles } from './CustonStyleDropDown';

const CustonCaption = styled(Caption)`
  font-weight: bold;
  padding: 0 0 7px 2px;
`;

export const ListaFiliais = ({
  setSelectedValue,
  loadedProfile,
  setInputValue,
  options,
  isLoading,
}) => {
  const filterData = (inputValue) => {
    return options.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptions = (inputValue) =>
    new Promise((resolve) => {
      resolve(filterData(inputValue));
    });

  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, '');
    setInputValue({ inputValue });
    return inputValue;
  };

  return (
    <div style={{ width: '100%', padding: '0 4rem' }}>
      {loadedProfile() && !isLoading && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <CustonCaption>Selecionar Filial</CustonCaption>
          <AsyncSelect
            defaultValue={options.filter((res) => res.matriz)}
            placeholder="Selecionar Filial"
            styles={customStyles}
            cacheOptions
            loadOptions={loadOptions}
            defaultOptions={options}
            onInputChange={handleInputChange}
            onChange={(e) => {
              setSelectedValue(e);
            }}
          />
        </div>
      )}
    </div>
  );
};

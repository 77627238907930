import { User } from 'oidc-client-ts';

import { oidcConfig } from 'auth/oidcConfig';

export async function getUserData() {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`
  );

  if (!oidcStorage) {
    return null;
  }

  const value = localStorage.getItem('userData');
  const user = JSON.parse(value);

  if (value) {
    const userData = {
      ...User.fromStorageString(oidcStorage),
      user,
    };

    return {
      ...userData,
      idUsuario: userData.user.idUsuario,
      idEmpresa: userData.user.idEmpresa,
    };
  }

  return null;
}

// import AuthService from './authService';

// const authUser = new AuthService();

// export async function getUserData() {
//   const usuario = await authUser.getUser();

//   if (usuario !== null) {
//     const value = localStorage.getItem('userData');
//     const user = JSON.parse(value);

//     const userData = {
//       ...usuario,
//       user,
//     };

//     return {
//       ...userData,
//       idUsuario: userData.user.idUsuario,
//       idEmpresa: userData.user.idEmpresa,
//     };
//   }
//   return null;
// }

/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  loadListCedenteRequest,
  setfilterdCedenteDefault,
  createCondicDefault,
} from 'store/old_actions/actions';
import { Theme } from '../../theme';

import ItemCedente from './ItemCedente';
import Inputdate from './InputDate';
import InputValue from './InputValue';

import { GraphicsInline } from '../Graphics';
import { Input, TextArea } from '../Input';

import {
  Container,
  Wrapper,
  WrapperRow,
  Label,
  SeparatorVertical,
  Loading,
  LoadingBar,
  SeparatorHorizontal,
} from '../Elements';

const Loader = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        height: '100vh',
      }}
    >
      <Loading lWidth="2rem" aHeight="2rem">
        <LoadingBar />
        <LoadingBar />
        <LoadingBar />
        <LoadingBar />
      </Loading>
    </div>
  );
};

const EditCondicionante = (props) => {
  useEffect(() => {
    const values = {
      idCedente: 0,
      texto: '',
      valor: 0,
      dtValidade: '',
    };

    props.createCondicionante(values);

    return () => {
      props.createCondicionante([]);
      props.setfilterdCedenteDefault();
    };
  }, []);

  // eslint-disable-next-line consistent-return
  const changeValueInput = (e) => {
    if (
      (e.target.value.trim() === '' && e.keyCode === 32) ||
      (e.target.value.trim() === '' && e.keyCode === 8)
    ) {
      console.log(e.target.value.trim());
      console.log(e.keyCode);
      return null;
    }
    console.log(e.target.value);
    if (e.target.value.trim() !== '') {
      props.action('SEARCH', e.target.value);
    } else {
      props.setfilterdCedenteDefault();
    }
  };

  return (
    <Container
      borderLeft={'transparent'}
      mbottom="2px"
      style={{
        flexDirection: 'column',
        boxShadow: 'none',
      }}
    >
      <SeparatorHorizontal hsize="0.5rem 0" />
      <WrapperRow lwidth="100%" style={{ alignItems: 'stretch' }}>
        <Wrapper lwidth="50%">
          <WrapperRow>
            <div className="level-right" style={{ width: '100%' }}>
              <div className="field" style={{ width: '100%' }}>
                <p className="control has-icons-left has-icons-right">
                  <Input
                    onChange={(e) => changeValueInput(e)}
                    placeholder="Pesquise aqui o empreendedor para selecioná-lo abaixo"
                    style={{ paddingLeft: '2.25em' }}
                  />
                  <span className="icon is-small is-left">
                    <i className="ico-feather ico-feather-primary ico-feather-search" />
                  </span>
                </p>
              </div>
            </div>
          </WrapperRow>
          <SeparatorHorizontal hsize="0.5rem 0" />
          <Wrapper
            aheight="300px"
            style={{
              overflowY: 'auto',
              borderRadius: '0.3rem',
              border: `1px solid ${Theme.gray2}`,
              maxHeight: '300px',
              justifyContent: 'flex-start',
              padding: '0.5rem 1rem',
            }}
          >
            {!props.isLoadingFilterd &&
              props.dataFiltered.map((res, key) => {
                return (
                  <ItemCedente key={key} action={props.action} data={res} />
                );
              })}

            {props.isLoadingFilterd && <Loader />}

            {props.isLoadingFilterd === false &&
              props.dataFiltered.length === 0 && (
                <GraphicsInline image="undraw" />
              )}
          </Wrapper>
        </Wrapper>
        <SeparatorVertical wsize="2rem" />
        <Wrapper lwidth="50%">
          <Wrapper>
            <TextArea
              placeholder="Descrição"
              onChange={(e) => props.action('CHANGE_TEXT_AREA', e.target.value)}
            />
          </Wrapper>
          <SeparatorHorizontal hsize="0.5rem 0" />
          <Wrapper>
            <Label style={{ width: '100%', textAlign: 'left' }}>
              Valor (opcional)
            </Label>
            <InputValue action={props.action} />
          </Wrapper>
          <SeparatorHorizontal hsize="0.5rem 0" />
          <Wrapper>
            <Label style={{ width: '100%', textAlign: 'left' }}>
              Data validade{' '}
              <span style={{ fontSize: '0.8rem' }}>(máximo 60 dias)</span>
            </Label>
            <Inputdate action={props.action} />
          </Wrapper>
        </Wrapper>
      </WrapperRow>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    dataFiltered: state.condicionante.dataFiltered,
    isLoadingFilterd: state.condicionante.isLoadingFilterd,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadListCedenteCondicionante: () => dispatch(loadListCedenteRequest()),
    setfilterdCedenteDefault: () => dispatch(setfilterdCedenteDefault()),
    createCondicionante: (data) => dispatch(createCondicDefault(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCondicionante);

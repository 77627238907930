import React, { useEffect, useCallback, useRef } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { dotToCommaDecimals } from 'helpers/format';
import { oddOrEven } from 'helpers/number';
import { truncate } from 'helpers/string';

import LoadingBar from 'react-top-loading-bar';
import { useQueryClient } from 'react-query';
import { ReactComponent as IcoRestaurar } from 'assets/images/icon-resturar.svg';
import { ButtonCircle } from 'components/FinButton/ButtonCircle';
import { finTheme } from 'finTheme';
import { theme } from './theme';
import { WrapperButton } from './styles.dashboard';
import { dashboardKeys } from '../hooks/queryKeyFactory';

/* const data = [
  {
    sacado: 'SANTA LUIZA CONDUTORES',
    faturamento: 11.36,
    faturamentoColor: '#FFD7D2',
  },
  {
    sacado: 'JCM NITEROI REFRIGERACAO',
    faturamento: 6.06,
    faturamentoColor: '#F6EAE8',
  },
  {
    sacado: 'CONDVOLT INDUSTRIA',
    faturamento: 5.19,
    faturamentoColor: '#FFD7D2',
  },
  {
    sacado: 'CONDEX IND E COM',
    faturamento: 5.06,
    faturamentoColor: '#F6EAE8',
  },
  {
    sacado: 'P.W INDUSTRIA',
    faturamento: 4.83,
    faturamentoColor: '#FFD7D2',
  },
  {
    sacado: 'EUROBRAS IND. E COM',
    faturamento: 4.08,
    faturamentoColor: '#F6EAE8',
  },
  {
    sacado: 'CONDUPAR CONDUTORES',
    faturamento: 2.75,
    faturamentoColor: '#FFD7D2',
  },
  {
    sacado: 'LOUZA FIOS E CABOS LTDA - ME',
    faturamento: 2.69,
    faturamentoColor: '#F6EAE8',
  },
  {
    sacado: 'DISPARCON DISTRIBUIDORA',
    faturamento: 2.56,
    faturamentoColor: '#FFD7D2',
  },
  {
    sacado: 'CORDEIRO CABOS ELETRICOS S.A',
    faturamento: 2.55,
    faturamentoColor: '#F6EAE8',
  },
]; */

export const BarChartConcentracao = (props) => {
  const ref = useRef(null);
  const queryClient = useQueryClient();
  const {
    concentracaoSacado: { data, isSuccess, isError, isLoading },
    idEmpreendedor,
  } = props;

  const reloadChart = () => {
    queryClient.resetQueries(
      dashboardKeys.sacadoConcentracao({
        idEmpresa: idEmpreendedor,
      })
    );
  };

  const getData = useCallback(() => {
    let fill = {};
    if (isSuccess) {
      const values = data.map((res) => res.pecentoFaturamento);
      const higherValue = Math.max(...values);

      const custonData = data.map((res, index) => {
        if (res.pecentoFaturamento === higherValue) {
          fill = {
            match: (d) => {
              return d.data.value === higherValue;
            },
            id: 'lines',
          };
          return {
            sacado: truncate(res.sacado, 20),
            faturamento: res.pecentoFaturamento,
            faturamentoColor: '#007A87',
          };
        }
        let values = {};
        if (oddOrEven(index) === 'even') {
          values = {
            sacado: truncate(res.sacado, 20),
            faturamento: res.pecentoFaturamento,
            faturamentoColor: '#8ad4d2',
          };
        } else {
          values = {
            sacado: truncate(res.sacado, 20),
            faturamento: res.pecentoFaturamento,
            faturamentoColor: '#8ad4d2',
          };
        }
        return values;
      });

      return { data: custonData, fill };
    }
    return { data: [], fill };
  }, [data, isSuccess]);

  useEffect(() => {
    if (ref.current) {
      if (isLoading) {
        ref.current.continuousStart();
      }
      if (isSuccess || isError) {
        ref.current.complete();
      }
    }
  }, [isError, isLoading, isSuccess]);

  const getDataFill = getData();

  return (
    <>
      <LoadingBar
        color={finTheme.newtheme.color.azulPrimario}
        ref={ref}
        containerStyle={{ position: 'absoulte', top: '0' }}
        shadow={false}
      />
      <WrapperButton>
        <ButtonCircle
          ariaLabel="reload-chart-3"
          size="large"
          tooltipText="Recarregar"
          tooltipPlace="top"
          tipo="button"
          id="reload-chart-3"
          onClick={(e) => {
            e.stopPropagation();
            reloadChart();
          }}
        >
          <IcoRestaurar />
        </ButtonCircle>
      </WrapperButton>
      <ResponsiveBar
        theme={theme}
        data={getDataFill.data}
        keys={['faturamento']}
        indexBy="sacado"
        margin={{ top: 50, right: 130, bottom: 150, left: 200 }}
        padding={0.3}
        groupMode="grouped"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={({ id, data }) => String(data[`${id}Color`])}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: '#006874',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: '#006874',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        fill={[getDataFill.fill]}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        valueFormat={(value) => dotToCommaDecimals(value, 2, true)}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -25,
          legend: 'Sacados',
          legendPosition: 'middle',
          legendOffset: 115,
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 12,
          tickRotation: 0,
          legend: 'Faturamento',
          legendPosition: 'middle',
          legendOffset: -120,
          format: (value) => dotToCommaDecimals(value, 2, true),
        }}
        enableLabel={true}
        labelSkipWidth={80}
        labelSkipHeight={15}
        labelTextColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        // legends={[
        //   {
        //     dataFrom: 'keys',
        //     anchor: 'bottom-right',
        //     direction: 'column',
        //     justify: false,
        //     translateX: 120,
        //     translateY: 0,
        //     itemsSpacing: 2,
        //     itemWidth: 100,
        //     itemHeight: 20,
        //     itemDirection: 'left-to-right',
        //     itemOpacity: 0.85,
        //     symbolSize: 20,
        //     effects: [
        //       {
        //         on: 'hover',
        //         style: {
        //           itemOpacity: 1,
        //         },
        //       },
        //     ],
        //   },
        // ]}
        legends={[]}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={(e) => {
          return `${e.id}: ${e.formattedValue} in country: ${e.indexValue}`;
        }}
      />
    </>
  );
};

/* eslint-disable no-unused-vars */
import { useInfiniteQuery } from 'react-query';
import axios from 'axios';

import CONFIG from 'config';
import { getUserData } from 'services/getUserData';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/`;

const apiSearch = async (params, { pageParam }) => {
  const { idEmpresa } = await getUserData();

  const values = pageParam || { ...params, size: 5, skip: 1 };

  const payload = {
    size: values.size,
    skip: values.skip,
    filter: { search: values.search },
  };

  const response = axios.post(
    `${BASE_URL}api/matching/convenio/pesquisa/${idEmpresa}`,
    payload
  );
  const result = await response;
  return result.data || [];
};

export const useQuerySearch = (params) => {
  return useInfiniteQuery(
    ['search', 'empreendedor', params],
    (pageParam) => apiSearch(params, pageParam),
    {
      getNextPageParam: (lastPage, pages) => lastPage.nextCursor,
    }
  );
};

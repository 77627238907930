/* eslint-disable no-console */
import axios from 'axios';
import { put, call, select } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
// import { Creators as apiInformacaoesActions } from 'store/ducks/apiInformacoes';
import { getUserData } from 'services/getUserData';
import {
  loadListSuccess,
  loadListFailure,
  downloadDocSuccess,
  downloadDocFailure,
  habilitaSuccess,
  habilitaFailure,
  infoBasicaSuccess,
  infoBasicaFailure,
  showLoading,
  hideLoading,
  showNotification,
  contentNotification,
  loadPaginacao,
  saveDocFileZipSuccess,
  saveDocFileZipFailure,
  clearAllItemsListDownload,
  generatesMeetingSuccess,
  generatesMeetingFailure,
  permissoesSucess,
  permissoesFailure,
  loadListFieldsSucess,
  loadListFieldsFailure,
  loadCnaeSucess,
  loadCnaeFailure,
  clearCnaeList,
} from 'store/old_actions/actions';
import config from '../../config';

import { getListDocs, getFilters, getFiltersList } from './selectors';

/* function* setFiltersList() {
  const filters = yield select(getFiltersList);

  const cnae = filters.cnae.map((res) => {
    const c = JSON.parse(res);
    return c.cnae;
  });

  const values = {
    faturamento: [filters.faturamento],
    cnae,
    estados: filters.estados,
    segmento: filters.segmento,
    status: filters.status,
    habilitacao: filters.habilitacao,
    acesso: filters.acesso,
  };

  const vals = Object.keys(filters).filter((res) =>
    Array.isArray(filters[res])
      ? filters[res].length > 0
      : filters[res].toString() !== '0'
  );

  const list = {};
  vals.forEach((r) => {
    list[r] = values[r];
  });

  return list;
} */

/** ***************************** List  ******************************** */
export function* listFinbox(action) {
  try {
    const user = yield call(getUserData);

    yield put(showLoading());

    const filters = yield select(getFiltersList);

    const cnae = filters.cnae.map((res) => {
      const c = JSON.parse(res);
      return c.cnae;
    });

    const values = {
      faturamento: [filters.faturamento],
      cnae,
      estados: filters.estados,
      segmento: filters.segmento,
      status: filters.status,
      habilitacao: filters.habilitacao,
      acesso: filters.acesso,
    };

    // console.log(values);

    const vals = Object.keys(filters).filter((res) =>
      Array.isArray(filters[res])
        ? filters[res].length > 0
        : filters[res].toString() !== '0'
    );

    // console.log(vals);

    const list = {};
    vals.forEach((r) => {
      list[r] = values[r];
    });

    // console.log(list);

    const reqData = {
      idConvenio: user.idEmpresa,
      idPessoaConvenio: user.idUsuario,
      pagina: action.pagina,
      quantidade: action.quantidade,
      ordem: action.ordem,
      ordemCampo: action.ordemCampo,
      pesquisa: action.search.toString().toLowerCase(),
      ...list,
    };

    // console.log(JSON.stringify(reqData));

    // Request data
    const res = yield axios.post(
      `${config.API_BASE_URL}/api/cedente/lista`,
      reqData
    );

    // console.log(res.data);

    yield put({ type: 'SET_FILTERED_DATA_FINBOX_CLEAR' });
    yield put({ type: 'LOAD_PAGINACAO_CLEAR' });

    yield put(loadListSuccess(res.data.result));
    yield put(loadPaginacao(res.data.paging));
    yield put(hideLoading());
  } catch (err) {
    console.log(err);
    yield put(loadListFailure(err.response));
    yield put(contentNotification(`Mensagem: ${err.message}`, 2000));
    yield put(showNotification());
    yield put(hideLoading());
  }
}

/** ************************ Download Bordero Operacao ******************************* */
export function* downloadDocuments(action) {
  try {
    const user = yield call(getUserData);

    // console.log(user);

    yield put(showLoading());

    const reqData = {
      params: {
        idDocumento: action.idDocumento,
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
      },
    };

    // console.log(action.url);

    // Post api download
    const res = yield axios.post(
      `${config.API_BASE_URL}/api/documento/download`,
      null,
      reqData
    );
    // console.log(res.data);
    yield put(downloadDocSuccess(res.data));

    if (action.url) {
      const a = document.createElement('a');
      // a.target = '_blank';
      a.href = action.url;
      a.download = action.url.substr(action.url.lastIndexOf('/') + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      throw new Error('Erro durante Download!!');
    }

    const reqData2 = {
      params: {
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
      },
    };
    // console.log(JSON.stringify(reqData2));
    // Request data
    const res2 = yield axios.post(
      `${config.API_BASE_URL}/api/cedente/lista`,
      null,
      reqData2
    );
    // console.log(JSON.stringify(res2.data));
    yield put(loadListSuccess(res2.data));

    // Notification
    yield put(
      contentNotification('Download do documento realizado com sucesso!', 2000)
    );
    yield put(showNotification());
    yield put(hideLoading());
  } catch (err) {
    console.log(err);
    yield put(downloadDocFailure(err.response));
    yield put(hideLoading());
    yield put(
      contentNotification(
        `Erro!!! Name: ${err.name}Message :${err.message}`,
        2000
      )
    );
    yield put(showNotification());
  }
}

/** ***************************** Habilita  ******************************** */
export function* listHabilita(action) {
  try {
    const user = yield call(getUserData);

    // yield put(showLoading());

    const reqData = {
      params: {
        idCedente: action.idCedente,
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
        habilitado: action.habilitado,
      },
    };

    // console.log(JSON.stringify(reqData));

    // Request data
    const res = yield axios.post(
      `${config.API_BASE_URL}/api/cedente/habilita`,
      null,
      reqData
    );

    // console.log(JSON.stringify(res.data));

    yield put(habilitaSuccess(res.data));
    // yield put(hideLoading());
  } catch (err) {
    console.log(err);
    yield put(habilitaFailure(err.response));
    yield put(contentNotification(`Mensagem: ${err.message}`, 2000));
    yield put(showNotification());
    // yield put(hideLoading());
  }
}

/** ***************************** Info Basica  ******************************** */
export function* infoBasica(action) {
  try {
    const user = yield call(getUserData);

    yield put({ type: 'INFOBASICA_CLEAR' });

    const reqData = {
      params: {
        idCedente: action.idCedente,
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
      },
    };

    // console.log(JSON.stringify(reqData));

    // Request data
    const res = yield axios.post(
      `${config.API_BASE_URL}/api/cedente/informacoes`,
      null,
      reqData
    );

    // console.log(JSON.stringify(res.data));

    yield put(infoBasicaSuccess(res.data));
  } catch (err) {
    console.log(err);
    yield put(infoBasicaFailure(err.response));
    yield put(contentNotification(`Mensagem: ${err.message}`, 2000));
    yield put(showNotification());
  }
}

/** ***************************** Download docsZip  ******************************** */
export function* downloadDocsZip(action) {
  try {
    const listaDocs = yield select(getListDocs);
    const user = yield call(getUserData);

    const reqData = {
      idUsuario: user.idUsuario,
      idGrupo: action.value.idGrupo,
      zipName: action.value.name,
      idDocumentos: listaDocs.map((res) => res.idDocumento),
    };

    // console.log(JSON.stringify(reqData));

    const axiosOptions = {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const BASE_URL = `${config.API_REMODELING_URL}/business-api/`;

    // Request data
    const res = yield axios.post(
      `${BASE_URL}api/matching/cliente/perfil/download`,
      reqData,
      axiosOptions
    );

    /**
     * Change date last download
     */
    // const reqData2 = {
    //   idConvenio: user.idEmpresa,
    //   idPessoaConvenio: user.idUsuario,
    //   fileUrls: listaDocs.map((res) => res.url),
    // };

    // const res2 = yield axios.post(
    //   `${config.API_BASE_URL}/api/documento/atualiza-download`,
    //   reqData2
    // );

    // const route = (state) => state.router.location;
    // const location = yield select(route);

    // const paramId = location.pathname.split('/')[2];
    // yield put(apiInformacaoesActions.apiInformacoesRequest(paramId, true));

    /** *********************************************** */

    yield put(saveDocFileZipSuccess());
    yield put(clearAllItemsListDownload());

    const blob = new Blob([res.data], {
      type: 'application/octet-stream',
    });
    const filename = `${reqData.zipName}.zip`;
    saveAs(blob, filename);
  } catch (err) {
    console.log(err);
    yield put(saveDocFileZipFailure());
    yield put(contentNotification(`Mensagem: ${err.message}`, 2000));
    yield put(showNotification());
  }
}

/** ***************************** Meeting  ******************************** */
export function* generatesMeeting(action) {
  try {
    const user = yield call(getUserData);

    yield put(contentNotification('Mensagem: Criando sala', 2000));
    yield put(showNotification());

    const reqData = {
      params: {
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
        idCedente: action.idCedente,
      },
    };

    // console.log('Request: ' + JSON.stringify(reqData));

    // Request data
    const res = yield axios.get(
      `${config.API_BASE_URL}/api/meeting/convida-empreendedor`,
      reqData
    );

    // console.log(res.data);
    // console.log('Response: ' + JSON.stringify(res.data));

    yield put(generatesMeetingSuccess(res.data));
    if (res.data) {
      window.open(res.data, 'Chat Finplace', 'height=600,width=700');
    }
  } catch (err) {
    console.log(err);
    yield put(generatesMeetingFailure(err.response));
    yield put(contentNotification(`Mensagem: ${err.message}`, 2000));
    yield put(showNotification());
  }
}

function setActionUrl(value) {
  switch (value) {
    case 'solicitar':
      return '/api/documento/solicita-acesso/';
    case 'aceitarAnalise':
      return '/api/documento/aceita-analise/';
    case 'recusarAnalise':
      return '/api/documento/recusa-analise/';
    default:
      return '';
  }
}

/** ***************************** Permissoes  ******************************** */
export function* permissoesActions(action) {
  try {
    const user = yield call(getUserData);

    const dataPage = yield select(getFilters);
    // console.log(dataPage);

    const reqData = {
      params: {
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
        idCedente: action.idCedente,
      },
    };

    // console.log(action.tipo);

    const url = setActionUrl(action.tipo);
    const res = yield axios.post(config.API_BASE_URL + url, null, reqData);

    // console.log(res.data);
    // console.log('Response: ' + JSON.stringify(res.data));

    /**
     * reload content list clients
     */
    const reqData2 = {
      idConvenio: user.idEmpresa,
      idPessoaConvenio: user.idUsuario,
      ...dataPage,
    };

    // console.log(JSON.stringify(reqData2));

    // Request data
    const res2 = yield axios.post(
      `${config.API_BASE_URL}/api/cedente/lista`,
      reqData2
    );

    yield put(loadListSuccess(res2.data.result));

    yield put(permissoesSucess(res.data));
  } catch (err) {
    console.log(err);
    yield put(permissoesFailure(err.response));
    yield put(contentNotification(`Mensagem: ${err.message}`, 2000));
    yield put(showNotification());
  }
}

/** ***************************** Filtros  ******************************** */
// eslint-disable-next-line no-unused-vars
export function* loadListFiltros(action) {
  try {
    // const user = yield call(getUserData);

    /* const reqData = {
      params: {
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
      },
    }; */

    const url = `${config.API_BASE_URL}/api/filtro/convenio`;
    const res = yield axios.get(url);

    // console.log(res.data);
    // console.log('Response: ' + JSON.stringify(res.data));

    yield put(loadListFieldsSucess(res.data));
  } catch (err) {
    console.log(err);
    yield put(loadListFieldsFailure(err.response));
    yield put(contentNotification(`Mensagem: ${err.message}`, 2000));
    yield put(showNotification());
  }
}

/** ***************************** CNAE  ******************************** */
export function* loadListCnae(axios, action) {
  try {
    yield put(clearCnaeList());

    // const reqData = {
    //   params: {
    //     pesquisa: action.pesquisa,
    //   },
    // };

    // const url = `${config.API_BASE_URL}/api/filtro/cnae`;

    const url = `${config.API_REMODELING_URL}/business-api/api/matching/filtros/cnae?filtroPesquisa=${action.pesquisa}`;
    const res = yield axios.get(url);

    // console.log(res.data);
    // console.log('Response: ' + JSON.stringify(res.data));

    yield put(loadCnaeSucess(res.data.data));
  } catch (err) {
    console.log(err);
    yield put(loadCnaeFailure(err.response));
    yield put(contentNotification(`Mensagem: ${err.message}`, 2000));
    yield put(showNotification());
  }
}

import React from 'react';
import ApprovedCredit from 'assets/icones/icone-money-step.svg';
import PendingStep from 'assets/icones/icone-pending-step.svg';

import { Step } from './Step';

export const CreditoPendente = () => {
  return (
    <Step
      bgcolor="#FCE9C7"
      icon={ApprovedCredit}
      statuicon={PendingStep}
      label="Crédito"
      labelsecundaria="não definido"
    />
  );
};

import React from 'react';

import { Caption } from 'components/FinParagraph';
import { PodiumStyle, PodiumWarpBarsStyle, PodiumBarsStyle } from './style';

export const Podium = ({ position }) => {
  const colors = {
    0: ['#d6dfe0', '#d6dfe0', '#d6dfe0', '#d6dfe0'],
    1: ['#70e5ef', '#70e5ef', '#70e5ef', '#70e5ef'],
    2: ['#70e5ef', '#70e5ef', '#70e5ef', '#d6dfe0'],
    3: ['#70e5ef', '#70e5ef', '#d6dfe0', '#d6dfe0'],
    4: ['#70e5ef', '#d6dfe0', '#d6dfe0', '#d6dfe0'],
  };
  return (
    <PodiumStyle>
      <PodiumWarpBarsStyle>
        <PodiumBarsStyle
          bgColor={colors[position][0]}
          percent="50%"
        ></PodiumBarsStyle>
        <PodiumBarsStyle
          bgColor={colors[position][1]}
          percent="60%"
        ></PodiumBarsStyle>
        <PodiumBarsStyle
          bgColor={colors[position][2]}
          percent="80%"
        ></PodiumBarsStyle>
        <PodiumBarsStyle
          bgColor={colors[position][3]}
          percent="100%"
        ></PodiumBarsStyle>
      </PodiumWarpBarsStyle>
      <Caption style={{ textAlign: 'end', marginRight: '0.5rem' }}>
        {`${position} Lugar`}
      </Caption>
    </PodiumStyle>
  );
};

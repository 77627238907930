import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Creators as apiFilterActions } from 'store/ducks/apiFiltro';
import { Creators as searchRouterActions } from 'store/ducks/searchRouter';

import { push } from 'connected-react-router';

import { LoadingInfo } from 'components/FinLoading';
import Button from 'components/FinButton';
import { finTheme } from 'finTheme';
// import { Select } from 'components/FinSelect';
import {
  SeparatorHorizontal,
  SeparatorVertical,
} from 'components/FinSeparador';
import { Checkbox } from 'components/FinCheckboxCuston';

import { Wrapper } from 'components/FinWrappers';
import { Caption } from 'components/FinParagraph';
import { stringToBoolean } from 'helpers/string';
import FeedbackInfo from 'components/FinFeedbackInfo';

import { StringToSvg } from 'helpers/svgFiles/StringToSvg';
import feather from 'feather-icons/dist/icons.json';
import { InputFormatMaterial } from 'components/FinInput/InputMaterial';

import {
  ContainerFilter,
  FilterFooter,
  ContentFilter,
  BtnOpenMenu,
} from './Styles';

import SubMenuFilter from './SubMenuFilter';

import Cnae from './Cnae';

const FilterMenu = ({
  query,
  pathname,
  searchRouterAction,
  apiFiltroState,
  open,
  push,
}) => {
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [openSubMenuSegmento, setOpenSubMenuSegmento] = useState(false);
  // const [errorEndividamento, setErrorEndividamento] = useState(false);
  const [errorFaturamento, setErrorFaturamento] = useState(false);

  const onChangeCheckbox = useCallback(
    (field, value) => {
      searchRouterAction.addSearchRoute({
        [field]: value.toString(),
        page: '1',
        qtd: '10',
        order: 'a-z',
        busca: '',
      });
    },
    [searchRouterAction]
  );

  const onChangeCheckbox2 = useCallback(
    (field, value) => {
      let values = [];

      if (query.segmentos) {
        const parseArray = JSON.parse(decodeURIComponent(query.segmentos));
        values = parseArray.includes(value)
          ? parseArray.filter((res) => res !== value)
          : [...parseArray, value];
      } else {
        values = [value];
      }

      searchRouterAction.addSearchRoute({
        [field]: values,
        page: '1',
        qtd: '10',
        order: 'a-z',
        busca: '',
      });
    },
    [query, searchRouterAction]
  );

  const checkSegmento = useCallback(
    (value) => {
      if (query.segmentos) {
        const parseArray = JSON.parse(decodeURIComponent(query.segmentos));
        if (parseArray.includes(value)) return true;

        return false;
      }

      return false;
    },
    [query]
  );

  /* const onChangeSelect = (field, e) => {
    searchRouterAction.addSearchRoute({
      [field]: e.target.value,
      page: '1',
      qtd: '10',
      order: 'a-z',
      busca: '',
    });
  }; */

  const onChangeEstado = useCallback(
    (field, value) => {
      let values = [];

      if (query.estado) {
        const parseArray = JSON.parse(decodeURIComponent(query.estado));
        values = parseArray.includes(value)
          ? parseArray.filter((res) => res !== value)
          : [...parseArray, value];
      } else {
        values = [value];
      }

      searchRouterAction.addSearchRoute({
        [field]: values,
        page: '1',
        qtd: '10',
        order: 'a-z',
        busca: '',
      });
    },
    [query, searchRouterAction]
  );

  const checkEstado = useCallback(
    (value) => {
      if (query.estado) {
        const parseArray = JSON.parse(decodeURIComponent(query.estado));
        if (parseArray.includes(value)) return true;

        return false;
      }

      return false;
    },
    [query]
  );

  const changeFaturamento = useCallback(
    (event, maskedvalue, floatvalue) => {
      const { id } = event.target;

      const faturamentode = Number(query.faturamentode) || 0;
      const faturamentoate = Number(query.faturamentoate) || 0;

      const de = id === 'faturamentode' ? floatvalue : faturamentode;
      const ate = id === 'faturamentoate' ? floatvalue : faturamentoate;

      if (ate !== 0 && de > ate) {
        setErrorFaturamento(true);
      } else {
        setErrorFaturamento(false);
      }

      searchRouterAction.addSearchRoute({
        [id]: floatvalue,
        page: '1',
        qtd: '10',
        order: 'a-z',
        busca: '',
      });
    },
    [searchRouterAction, setErrorFaturamento, query]
  );

  const checkInputs = useCallback(
    (tipo) => {
      return Number(query[tipo]) || 0;
    },
    [query]
  );

  // const valueFieldDecoded = (val) => {
  //   const value = val.toLowerCase();

  //   const decoded = Object.keys(query).map((res) => ({
  //     [decodeURIComponent(res)]: query[res],
  //   }));

  //   const [data] = decoded.filter((res) => res[value]);

  //   return data ? stringToBoolean(data[value]) : false;
  // };

  useEffect(() => {
    checkInputs();
  }, [checkInputs]);

  return (
    <ContainerFilter display={open ? 1 : 0}>
      <LoadingInfo loading={apiFiltroState.isLoading} />
      {apiFiltroState.error && <FeedbackInfo tipo="error" />}
      {apiFiltroState.data.length > 0 && (
        <>
          <ContentFilter>
            <Cnae />
            {/* <Wrapper
              padding="32px"
              tipo="column"
              pwidth="100%"
              alignItems="flex-start"
            >
              <Caption fontWeigth="600">Endividamento</Caption>
              <Wrapper padding="0">
                <InputFormatMaterial
                  id="endividamentode"
                  value={checkInputs('endividamentode')}
                  label="De"
                  handleChange={changeEndividamento}
                />
                <SeparatorVertical />
                <InputFormatMaterial
                  id="endividamentoate"
                  value={checkInputs('endividamentoate')}
                  label="Até"
                  handleChange={changeEndividamento}
                  error={errorEndividamento}
                  errormessage="Valor tem que ser maior que o inicial!!"
                />
              </Wrapper>
            </Wrapper> */}

            <Wrapper
              padding="32px"
              tipo="column"
              pwidth="100%"
              alignItems="flex-start"
            >
              <Caption fontWeigth="600">Faturamento anual</Caption>
              <Wrapper padding="0">
                <InputFormatMaterial
                  id="faturamentode"
                  value={checkInputs('faturamentode')}
                  label="De"
                  handleChange={changeFaturamento}
                />
                <SeparatorVertical />
                <InputFormatMaterial
                  id="faturamentoate"
                  value={checkInputs('faturamentoate')}
                  label="Até"
                  handleChange={changeFaturamento}
                  error={errorFaturamento}
                  errormessage="Valor tem que ser maior que o inicial!!"
                />
              </Wrapper>
            </Wrapper>

            <Wrapper
              pwidth="100%"
              padding="1rem 0rem 2rem 2rem"
              justifyContent="flex-start"
              alignItems="flex-end"
            >
              <Wrapper
                padding="0"
                pwidth="100%"
                tipo="column"
                alignItems="flex-start"
              >
                <Wrapper padding="0" tipo="column" alignItems="flex-start">
                  <Caption fontWeigth="600">Segmento</Caption>
                  {apiFiltroState.data[0].segmento
                    .filter((res) => [1, 2, 3].includes(res.id))
                    .sort()
                    .reverse()
                    .map((val) => (
                      <Checkbox
                        key={`check-${val.id}`}
                        name={`check-${val.nome}`}
                        value={val.nome}
                        label={val.nome}
                        checked={checkSegmento(val.id)}
                        padding="0"
                        handleChange={() =>
                          onChangeCheckbox2('segmentos', val.id)
                        }
                      />
                    ))}
                </Wrapper>
                <Wrapper padding="0" tipo="column" alignItems="flex-start">
                  {apiFiltroState.data[0].segmento
                    .filter((res) => [4, 5].includes(res.id))
                    .sort()
                    .reverse()
                    .map((val) => (
                      <Checkbox
                        key={`check-${val.id}`}
                        name={`check-${val.nome}`}
                        value={val.nome}
                        label={val.nome}
                        checked={checkSegmento(val.id)}
                        padding="0"
                        handleChange={() =>
                          onChangeCheckbox2('segmentos', val.id)
                        }
                      />
                    ))}
                </Wrapper>

                <SubMenuFilter
                  open={openSubMenuSegmento}
                  close={() => setOpenSubMenuSegmento(false)}
                  title="Segmentos"
                >
                  {apiFiltroState.data[0].segmento
                    .filter((res) => ![1, 2, 3, 4, 5].includes(res.id))
                    .map((val) => (
                      <Checkbox
                        key={`check-${val.id}`}
                        name={`check-${val.nome}`}
                        value={val.nome}
                        label={val.nome}
                        checked={checkSegmento(val.id)}
                        padding="0"
                        handleChange={() =>
                          onChangeCheckbox2('segmentos', val.id)
                        }
                      />
                    ))}
                </SubMenuFilter>
                <Wrapper
                  pwidth="100%"
                  justifyContent="flex-end"
                  padding="0 0.8rem"
                >
                  <BtnOpenMenu onClick={() => setOpenSubMenuSegmento(true)}>
                    <Caption>Ver todos os segmentos</Caption>
                    <Wrapper padding="0" pwidth="20px" pheight="23px">
                      <StringToSvg
                        width="100%"
                        height="100%"
                        stroke={finTheme.newtheme.color.azulPrimario}
                      >
                        {feather['chevron-right']}
                      </StringToSvg>
                    </Wrapper>
                  </BtnOpenMenu>
                </Wrapper>
              </Wrapper>
            </Wrapper>

            <Wrapper
              pwidth="100%"
              padding="1rem 2rem 2rem 2rem"
              justifyContent="flex-start"
              alignItems="flex-end"
            >
              <Wrapper
                padding="0"
                pwidth="126px"
                tipo="column"
                alignItems="flex-start"
              >
                <Caption fontWeigth="600">Estado</Caption>
                {apiFiltroState.data[0].estados
                  .filter((res) => ['MG', 'SP'].includes(res.nome))
                  .sort()
                  .reverse()
                  .map((val) => (
                    <Checkbox
                      key={`check-${val.id}`}
                      name={`check-${val.nome}`}
                      value={val.nome}
                      label={val.nome}
                      checked={checkEstado(val.nome)}
                      padding="0"
                      handleChange={() => onChangeEstado('estado', val.nome)}
                    />
                  ))}
              </Wrapper>
              <Wrapper padding="0" tipo="column" alignItems="flex-start">
                {apiFiltroState.data[0].estados
                  .filter((res) => ['RJ', 'SC'].includes(res.nome))
                  .map((val) => (
                    <Checkbox
                      key={`check-${val.id}`}
                      name={`check-${val.nome}`}
                      value={val.nome}
                      label={val.nome}
                      checked={checkEstado(val.nome)}
                      padding="0"
                      handleChange={() => onChangeEstado('estado', val.nome)}
                    />
                  ))}
              </Wrapper>
            </Wrapper>

            <SubMenuFilter
              open={openSubMenu}
              close={() => setOpenSubMenu(false)}
              title="Estados"
            >
              {apiFiltroState.data[0].estados
                .filter((res) => !['SP', 'MG', 'RJ', 'SC'].includes(res.nome))
                .map((val) => (
                  <Checkbox
                    key={`check-${val.id}`}
                    name={`check-${val.nome}`}
                    value={val.nome}
                    label={val.nome}
                    checked={checkEstado(val.nome)}
                    padding="0"
                    handleChange={() => onChangeEstado('estado', val.nome)}
                  />
                ))}
            </SubMenuFilter>

            <Wrapper pwidth="100%" justifyContent="flex-end" padding="0 0.8rem">
              <BtnOpenMenu onClick={() => setOpenSubMenu(true)}>
                <Caption>Ver todos os estados</Caption>
                <Wrapper padding="0" pwidth="20px" pheight="23px">
                  <StringToSvg
                    width="100%"
                    height="100%"
                    stroke={finTheme.newtheme.color.azulPrimario}
                  >
                    {feather['chevron-right']}
                  </StringToSvg>
                </Wrapper>
              </BtnOpenMenu>
            </Wrapper>

            <SeparatorHorizontal ppadding="0.5rem" />

            <Wrapper
              pwidth="100%"
              padding="1rem 2rem 2rem 2rem"
              justifyContent="flex-start"
            >
              <Wrapper
                padding="0"
                tipo="column"
                pwidth="126px"
                alignItems="flex-start"
              >
                <Caption fontWeigth="600">Status</Caption>
                {apiFiltroState.data[0].status.map((val) => (
                  <Checkbox
                    key={`check-${val.id}`}
                    name={`check-${val.nome}`}
                    value={val.nome}
                    label={val.nome}
                    checked={stringToBoolean(query[val.nome.toLowerCase()])}
                    padding="0"
                    handleChange={() =>
                      onChangeCheckbox(
                        val.nome.toLowerCase(),
                        !stringToBoolean(query[val.nome.toLowerCase()])
                      )
                    }
                  />
                ))}
              </Wrapper>
              <Wrapper padding="0" tipo="column" alignItems="flex-start">
                <Caption fontWeigth="600">Habilitação</Caption>
                {apiFiltroState.data[0].habilitacao.map((val) => (
                  <Checkbox
                    key={`check-${val.id}`}
                    name={`check-${val.nome}`}
                    value={val.nome}
                    label={val.nome}
                    checked={stringToBoolean(query[val.nome.toLowerCase()])}
                    padding="0"
                    handleChange={() =>
                      onChangeCheckbox(
                        val.nome.toLowerCase(),
                        !stringToBoolean(query[val.nome.toLowerCase()])
                      )
                    }
                  />
                ))}
              </Wrapper>
            </Wrapper>

            <Wrapper
              pwidth="100%"
              padding="1rem 2rem 2rem 2rem"
              justifyContent="flex-start"
            >
              <Wrapper
                padding="0"
                tipo="column"
                pwidth="100%"
                alignItems="flex-start"
              >
                <Caption fontWeigth="600">Cadastro</Caption>
                <Checkbox
                  key={`check-perfil-completo`}
                  name={`check-perfil-completo`}
                  value={'perfilcompleto'}
                  label={'Perfil completo'}
                  checked={stringToBoolean(query.perfilcompleto)}
                  padding="0"
                  handleChange={() =>
                    onChangeCheckbox(
                      `perfilcompleto`,
                      !stringToBoolean(query.perfilcompleto)
                    )
                  }
                />
                <Checkbox
                  key={`check-docs-completo`}
                  name={`check-docs-comnpleto`}
                  value={'docscompleto'}
                  label={'Documentos básicos completos'}
                  checked={stringToBoolean(query.docscompleto)}
                  padding="0"
                  handleChange={() =>
                    onChangeCheckbox(
                      `docscompleto`,
                      !stringToBoolean(query.docscompleto)
                    )
                  }
                />
                {/* apiFiltroState.data[0].documentos.map((val) => (
                  <Checkbox
                    key={`check-docs-${val.id}`}
                    name={`check-docs-${val.nome}`}
                    value={val.nome}
                    label={val.nome}
                    checked={stringToBoolean(
                      query[`docs${val.nome.toLowerCase()}`]
                    )}
                    padding="0"
                    handleChange={() =>
                      onChangeCheckbox(
                        `docs${val.nome.toLowerCase()}`,
                        !stringToBoolean(query[`docs${val.nome.toLowerCase()}`])
                      )
                    }
                  />
                  )) */}
              </Wrapper>
              {/* <Wrapper padding="0" tipo="column" alignItems="flex-start">
                <Caption fontWeigth="600">Perfil</Caption>
                {apiFiltroState.data[0].perfil.map((val) => (
                  <Checkbox
                    key={`check-perfil-${val.id}`}
                    name={`check-perfil-${val.nome}`}
                    value={val.nome}
                    label={val.nome}
                    checked={stringToBoolean(
                      query[`perfil${val.nome.toLowerCase()}`]
                    )}
                    padding="0"
                    handleChange={() =>
                      onChangeCheckbox(
                        `perfil${val.nome.toLowerCase()}`,
                        !stringToBoolean(
                          query[`perfil${val.nome.toLowerCase()}`]
                        )
                      )
                    }
                  />
                ))}
                  </Wrapper> */}
            </Wrapper>
          </ContentFilter>

          <FilterFooter>
            {/* <Button
              tipo="primary"
              size={finTheme.newtheme.buttons.sizes.smallM}
              onClick={() => console.log('teste')}
            >
              Aplicar
            </Button>
           &nbsp;&nbsp; */}
            <Button
              tipo="primary"
              preenchimento="outline"
              size={finTheme.newtheme.buttons.sizes.smallM}
              onClick={() =>
                push(`${pathname}?page=1&qtd=10&order=a-z&aba=${query.aba}`)
              }
            >
              Limpar filtros
            </Button>
          </FilterFooter>
        </>
      )}
    </ContainerFilter>
  );
};

FilterMenu.propTypes = {
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  apiFiltroState: state.apiFiltro,
  search: state.router.location.search,
  query: state.router.location.query,
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch) => {
  return {
    apiFilterAction: bindActionCreators(apiFilterActions, dispatch),
    searchRouterAction: bindActionCreators(searchRouterActions, dispatch),
    push: (path) => dispatch(push(path)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterMenu);

import React from 'react';

import messages from 'helpers/messages/index.json';
import { Flex, Title, Text } from './style.dialog';
import { NWButton } from '../Button';

export function Unblock({ unBlock, onClose }) {
  return (
    <Flex direction="column" style={{ padding: '2rem' }}>
      <Title>{messages.dialog.desbloquear.titulo}</Title>
      <Text>{messages.dialog.desbloquear.texto}</Text>
      <Flex
        justify="flex-end"
        style={{ width: '100%', gap: '10px', marginTop: '2rem' }}
      >
        <NWButton.Secondary
          onClick={() => {
            onClose();
          }}
        >
          {messages.dialog.desbloquear.labelCancelar}
        </NWButton.Secondary>
        <NWButton.Primary
          largura="padrao"
          onClick={() => {
            unBlock();
            onClose();
          }}
        >
          {messages.dialog.desbloquear.labelConfirme}
        </NWButton.Primary>
      </Flex>
    </Flex>
  );
}

import { styled, keyframes } from '@stitches/react';
import * as Dialog from '@radix-ui/react-dialog';
import { darken } from 'polished';

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

export const Root = styled(Dialog.Root, {});

export const Trigger = styled(Dialog.Trigger, {});

export const Portal = styled(Dialog.Portal, {});

export const Overlay = styled(Dialog.Overlay, {
  backgroundColor: 'rgba(0, 0, 0, 0.44)',
  position: 'fixed',
  zIndex: '999',
  inset: 0,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
});

export const Content = styled(Dialog.Content, {
  backgroundColor: 'white',
  borderRadius: 6,
  boxShadow:
    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  position: 'fixed',
  top: '50%',
  left: '50%',
  zIndex: '999',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  maxWidth: '750px',
  maxHeight: '85vh',
  padding: 0,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
  '&:focus': { outline: 'none' },
  variants: {
    size: {
      small: { maxWidth: '450px' },
      medium: { maxWidth: '750px' },
      large: { maxWidth: '1050px' },
    },
  },
});

export const Title = styled(Dialog.Title, {
  margin: 0,
  fontWeight: 500,
  color: '#000000',
  fontSize: 17,
});

export const Description = styled(Dialog.Description, {
  margin: '10px 0 20px',
  color: '#000000',
  fontSize: 15,
  lineHeight: 1.5,
});

export const Close = styled(Dialog.Close, {});

export const IconButton = styled('button', {
  borderRadius: '10px',
  height: 35,
  width: 35,
  padding: '6px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'none',
  color: 'var(--fingray-900)',
  backgroundColor: 'var(--fingray-300)',
  cursor: 'pointer',
  position: 'absolute',
  top: 10,
  right: 10,
  zIndex: '999',

  '&:enabled:hover': { backgroundColor: darken(0.1, '#f2f2f3') },
  '&:focus': { boxShadow: `0 0 0 2px var(--fingray-900)` },

  '& svg path': {
    strokeWidth: '2px',
    stroke: 'var(--fingray-900)',
  },
});

import styled from 'styled-components/macro';
// import ApprovedCredit from 'assets/icones/icone-money-step.svg';
// import { ReactComponent as icon } from 'assets/icones/icone-money-step.svg';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 166px;
  height: 42px;
  background: ${({ bgcolor }) => bgcolor || 'pink'};
  border-radius: 42px;
  margin-right: 11px;
`;

export const Label = styled.span`
  display: flex;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 13px;
  color: var(--fingray-900);

  &::before {
    content: '';
    position: absolute;
    left: 10px;
    width: 32px;
    height: 32px;
    background: url(${({ icon }) => icon}) no-repeat center center;
  }

  &::after {
    content: '';
    position: absolute;
    right: 10px;
    width: 32px;
    height: 32px;
    background: url(${({ statuicon }) => statuicon}) no-repeat center center;
    border-radius: 50%;
`;

export const Theme = {
  primary: '#008894',
  secondary: '#00DBD5',
  greenLight: '#9CF7F6',
  greenLight2: '#EAFDFF',
  greenHardLight: '#D0F8F8',
  greenStr: '#337F8A',
  greenMed: '#378894',
  greenSm: 'rgba(243, 246, 245, 0.62)',
  orange: '#FF4D35',
  greenFlo: '#6dd400',
  orangeStr: '#E55848',
  white: '#FFF',
  black: '#000000',
  orangeLight: '#FFEEEC',
  gray1: '#F4F4F4',
  gray2: '#D8D8D8',
  gray3: '#B8B8B8',
  gray4: '#969696',
  gray5: '#646464',
  gray6: '#1F1F1F',
  gray7: '#E8EEED',
  yellow: '#FFCA00',
  yellowLight: '#FFF5CE',
  fontPrimary: 'Roboto, sans-serif',
  corMatch: 'rgba(141,205,71,1)',
  corAnalisar: 'rgba(240,180,28,1)',
  corNoMatch: 'rgba(248,95,77,1)',
  corMatch50: 'rgba(141,205,71,0.5)',
  corAnalisar50: 'rgba(240,180,28,0.5)',
  corNoMatch50: 'rgba(248,95,77,0.5)',
};

// Button sizes
export const SIZES = {
  small: '34px',
  medium: '40px',
  large: '48px',
};

import axios from 'axios';
import { useQuery } from 'react-query';
import CONFIG from 'config';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/user-api/`;

const fetchInteresseCredito = async (params) => {
  const fetchData = axios.get(
    `${BASE_URL}api/cedente/perfil/credito/grupo/${params.idGrupo}`
  );

  const result = await fetchData;
  const { data } = result;

  return data.data ? data.data : [];
};

export const useInteresseCredito = (params) => {
  return useQuery(
    ['interesseCredito', params],
    () => fetchInteresseCredito(params),
    {
      keepPreviousData: false,
    }
  );
};

import React from 'react';
import { finTheme } from 'finTheme';

import { icons } from 'assets/icones/finplaceIcons';
import TagStatus from './TagStatus';

const TagPerfil = ({ active, id }) => {
  return (
    <TagStatus
      viewTooltip
      id={`perfil-${id}`}
      tooltipText={active ? 'Perfil completo' : 'Perfil incompleto'}
      vbox="-5 -2 24 24"
      bgcolor={
        active
          ? finTheme.newtheme.color.success
          : finTheme.newtheme.color.cinza3
      }
      textColor={finTheme.newtheme.color.branco}
      iconColor={finTheme.newtheme.color.branco}
      icon={icons.userCircleFlat}
    >
      Perfil
    </TagStatus>
  );
};

export default TagPerfil;

import React from 'react';

import { Paragraph2 } from 'components/FinParagraph';
import { SeparatorHorizontal } from 'components/FinSeparador';
import { finTheme } from 'finTheme';
import BlurryText from 'components/FinBlurryText';
import Map from 'components/FinMap';
import { Line, Column, CustonSubtitle2 } from './Styles';

const Endereco = ({ data }) => {
  return (
    <>
      <SeparatorHorizontal />
      <Line>
        <Column pwidth="50%">
          <CustonSubtitle2>Endereço</CustonSubtitle2>
          <Paragraph2 pmargin="0.4rem 0">
            <BlurryText blurry={data.criptografia}>
              {data.logradouro && `${data.logradouro} , ${data.numero}`}
            </BlurryText>
          </Paragraph2>
        </Column>
      </Line>
      <SeparatorHorizontal ppadding="0.5rem" />
      <Line>
        <Column pwidth="50%">
          <CustonSubtitle2>Complemento</CustonSubtitle2>
          <Paragraph2 pmargin="0.4rem 0">
            <BlurryText blurry={data.criptografia}>
              {data.complemento || ''}
            </BlurryText>
          </Paragraph2>
        </Column>
        <Column pwidth="50%">
          <CustonSubtitle2>Bairro</CustonSubtitle2>
          <Paragraph2 pmargin="0.4rem 0">
            <BlurryText blurry={data.criptografia}>
              {data.bairro || ''}
            </BlurryText>
          </Paragraph2>
        </Column>
      </Line>
      <SeparatorHorizontal ppadding="0.5rem" />
      <Line>
        <Column pwidth="50%">
          <CustonSubtitle2>Cidade</CustonSubtitle2>
          <Paragraph2 pmargin="0.4rem 0">
            <BlurryText blurry={false}>{data.cidade || ''}</BlurryText>
          </Paragraph2>
        </Column>
        <Column pwidth="50%">
          <CustonSubtitle2>UF</CustonSubtitle2>
          <Paragraph2 pmargin="0.4rem 0">
            <BlurryText blurry={false}>{data.estado || ''}</BlurryText>
          </Paragraph2>
        </Column>
      </Line>
      <SeparatorHorizontal ppadding="0.5rem" />
      <Line>
        <Column pwidth="100%">
          <CustonSubtitle2>CEP</CustonSubtitle2>
          <Paragraph2 pmargin="0.4rem 0">
            <BlurryText blurry={data.criptografia}>{data.cep || ''}</BlurryText>
          </Paragraph2>
        </Column>
      </Line>
      <SeparatorHorizontal
        ppadding="2rem"
        pborderColor={finTheme.newtheme.color.cinza4}
      />
      <Line>
        <Map
          locked={data.criptografia}
          text="Meu Local"
          address={`${data.logradouro} - ${data.numero}`}
        />
      </Line>
    </>
  );
};

export default Endereco;

import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
  background-color: #ffffff;
  max-width: 852px;
  padding: 1.5rem;
`;

export const Text = styled.span`
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;
  color: var(--fingray-900);
`;

export const Flex = styled.div`
  display: flex;
  ${({ row }) =>
    row &&
    css`
      flex-direction: row;
    `}

  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
    `}

  justify-content: ${({ justify }) => justify || `center`};
  align-items: ${({ align }) => align || `center`};
  width: 100%;
`;

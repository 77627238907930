import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const InputMask = styled.span`
  background-color: #ffffff;
  border: 2px solid #6a6464;
  box-sizing: border-box;
  height: 18px;
  margin-right: 8px;
  min-width: 18px;
  position: relative;
  width: 18px;
  border-radius: 90rem;
`;

const CheckboxComponente = styled.label`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  position: relative;
  user-select: none;
  vertical-align: middle;
`;

const Input = styled.input`
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;

  &:checked ~ ${InputMask} {
    background-color: ${({ theme }) => theme.newtheme.color.vermelhoPrimario};
  }
`;

const Label = styled.span`
  font-size: 1rem;
`;

const Checkbox = ({ aoSelecionar, disabled, label, name, value, checked }) => {
  return (
    <CheckboxComponente htmlFor={`${name}-${value}`}>
      <Input
        type="checkbox"
        name={name}
        value={value}
        id={`${name}-${value}`}
        disabled={disabled}
        onChange={aoSelecionar}
        checked={checked}
      />
      <InputMask />
      {label && <Label>{label}</Label>}
    </CheckboxComponente>
  );
};

Checkbox.propTypes = {
  aoSelecionar: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

Checkbox.defaultProps = {
  aoSelecionar: null,
  disabled: false,
  label: '',
};

export default Checkbox;

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { Theme } from '../../theme';

const CheckboxComponente = styled.label`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  position: relative;
  user-select: none;
  vertical-align: middle;
`;

const InputMask = styled.span`
  background-color: #ffffff;
  border: 2px solid #6a6464;
  box-sizing: border-box;
  height: 18px;
  margin-right: 8px;
  min-width: 18px;
  position: relative;
  width: 18px;
  ${({ rounded }) =>
    rounded === 'active' &&
    css`
      border-radius: 90rem;
    `}
`;

const Input = styled.input`
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;

  &:checked ~ ${InputMask} {
    background-color: ${Theme.primary};
  }
`;

const Label = styled.span`
  font-size: 0.8rem;
`;

const CheckboxComp = ({
  aoSelecionar,
  disabled,
  label,
  name,
  value,
  checked,
  rounded,
}) => {
  return (
    <CheckboxComponente htmlFor={`${name}-${value}`}>
      <Input
        type="checkbox"
        name={name}
        value={value}
        id={`${name}-${value}`}
        disabled={disabled}
        onChange={aoSelecionar}
        checked={checked}
      />
      <InputMask rounded={rounded} />
      {label && <Label>{label}</Label>}
    </CheckboxComponente>
  );
};

CheckboxComp.propTypes = {
  aoSelecionar: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

CheckboxComp.defaultProps = {
  aoSelecionar: null,
  disabled: false,
  label: '',
};

export default CheckboxComp;

/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import CONFIG from 'config';
import toast from 'react-hot-toast';
import { getUserData } from 'services/getUserData';
import { handleErrors } from 'helpers/requisicoes/handleErrors';
import { OperacoesContext } from '../provider';

import { TIPO_PREGAO } from '../config';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const enviar = async (params) => {
  const { idEmpresa, idUsuario } = await getUserData();

  const payload = {
    idUsuario,
    idConvenio: idEmpresa,
    ...params,
  };

  const mutateData = axios.post(
    `${BASE_URL}/proposta/contra/convenio`,
    payload
  );

  const result = await mutateData;
  const { data } = result;

  return data || [];
};

export const useMutateEnviarProposta = () => {
  const queryClient = useQueryClient();
  const [state, actions] = useContext(OperacoesContext);

  const {
    propostas: { nova },
    bordero: { selecionados, nãoSelecionados },
  } = state;

  let params = {};
  if (nova.tipoPregao) {
    const source = nova.nota || nova.semNota;

    const tipoAnteciCobr =
      TIPO_PREGAO.antecipacao.includes(nova.tipoPregao) ||
      TIPO_PREGAO.cobrancaSimples.includes(nova.tipoPregao);

    const titulos = tipoAnteciCobr ? {
      idBordero: nova.bordero.idBordero,
      titulosSelecionados: [...selecionados],
      titulosRecusados: [...nãoSelecionados],
    } : {};

    const _parcelas = {
      idSubTipoPregao: source.propostaSubTipo,
      parcelas: source.parcelas && [...source.parcelas],
    };

    const parcelas = TIPO_PREGAO.emprestimo.includes(nova.tipoPregao)
      ? _parcelas
      : {};

    params = {
      idGrupo: nova.idGrupo,
      idProposta: nova.idPropostaOriginal,
      idOperacao: nova.idOperacao,
      obs: nova.observacao,
      idTipoPregao: nova.tipoPregao,
      idPregao: nova.idPregao,
      acertos: {
        recompra: source.recompra,
        tarifa: source.tarifa,
        pendencias: source.pendencias,
      },
      valoresProposta: {
        liquido: source.liquido,
        pmp: source.pmp,
        vop: source.vop,
        valorBruto: source.valorBruto,
        taxa: source.taxa,
        vfloat: source.vFloat,
      },
      ...titulos,
      ...parcelas,
    };
  }

  return useMutation(() => enviar(params), {
    onMutate: async (params) => {
      await queryClient.cancelQueries(['propostas']);

      return { params };
    },
    onError: (error) => {
      const message = handleErrors(error);
      toast.error(message, { duration: 4000 });
    },
    onSuccess: (data, variables, context) => {
      actions.setResetProposta();
      queryClient.invalidateQueries('propostas');
    },
  });
};

/* eslint-disable no-unused-vars */
import React, { useEffect, useCallback, useRef } from 'react';
import { currency } from 'helpers/format';

import { linearGradientDef } from '@nivo/core';
import { ResponsiveLine } from '@nivo/line';
import { ButtonCircle } from 'components/FinButton/ButtonCircle';
import LoadingBar from 'react-top-loading-bar';
import { finTheme } from 'finTheme';
import { ReactComponent as IcoRestaurar } from 'assets/images/icon-resturar.svg';
import { useQueryClient } from 'react-query';
import { BoxLegendSvg } from '@nivo/legends';
import { WrapperButton } from 'pages/Empreendedores/DetalheEmpreendedor/styles.dashboard';
import { useParams } from 'react-router-dom';
import { theme } from './theme';

import { dashboardKeys } from '../queryKeyFactory';

import { useChartFaturamentoDiario } from '../hooks';

export const FaturamentoDiario = () => {
  const { idGrupo } = useParams();

  const ref = useRef(null);
  const queryClient = useQueryClient();

  const faturamentoDiario = useChartFaturamentoDiario({
    idEmpresa: idGrupo,
  });

  const { data, isSuccess, isLoading, isError } = faturamentoDiario;

  const reloadChart = () => {
    queryClient.resetQueries(
      dashboardKeys.faturamentoDiario({
        idEmpresa: idGrupo,
      })
    );
  };

  const rankingColors = [
    '#007A87',
    '#61CDBB',
    '#E8A838',
    '#F1E15B',
    '#F47560',
    '#E8C1A0',
  ];

  const CustomSymbol = ({
    size = 10,
    color,
    borderWidth = 1,
    borderColor,
    ranking,
  }) => {
    return (
      <g>
        <circle
          fill={ranking === 0 ? '#fff' : color}
          r={size / 2}
          strokeWidth={borderWidth}
          stroke={color}
        />
        <circle
          r={size / 5}
          strokeWidth={borderWidth}
          stroke={color}
          fill={color}
          fillOpacity={ranking === 0 ? 0.35 : 1}
        />
      </g>
    );
  };

  function SymbolRect({
    fill,
    opacity = 1,
    borderWidth = 0,
    borderColor = 'transparent',
  }) {
    return (
      <rect
        width="20"
        height="20"
        rx="0"
        ry="0"
        fill={fill}
        opacity={opacity}
        strokeWidth={borderWidth}
        stroke={borderColor}
        style={{
          pointerEvents: 'none',
        }}
      />
    );
  }

  const RenderPoint = ({ color }) => <SymbolRect fill={color} />;

  const CustonTooltip = ({ slice }) => {
    return (
      <div
        style={{
          background: 'white',
          padding: '9px 12px',
          border: '1px solid #ccc',
        }}
      >
        {slice.points.map((point) => (
          <div
            key={point.id}
            style={{
              color: point.serieColor,
              padding: '3px 0',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                role="img"
              >
                <g transform="translate(0,0)">
                  <RenderPoint color={point.data.color} />
                </g>
              </svg>
              <div
                style={{ marginLeft: '5px', color: '#000', fontSize: '12px' }}
              >
                {point.serieId.replace('f', 'F')}{' '}
                <strong>{point.data.yFormatted}</strong>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const getData = useCallback(() => {
    if (isSuccess) {
      const keys = [];

      const custonData =
        data.length > 0 &&
        data.map((res) => {
          if (res.ranking >= 1 && res.ranking <= 5) {
            keys[res.ranking] = `${res.ranking} - Dia ${res.dia} - ${currency(
              res.valorFaturamento
            )}`;
          }
          return {
            x: res.dia,
            y: res.valorFaturamento,
            ranking: res.ranking || 0,
            color: res.ranking ? rankingColors[res.ranking] : rankingColors[0],
          };
        });

      const values = [
        {
          id: 'faturamento',
          color: '#007A87',
          data: custonData,
          keys,
        },
      ];

      return values;
    }

    return [{ data: [], keys: [] }];
  }, [data, isSuccess, rankingColors]);

  const getDataFill = getData();

  useEffect(() => {
    if (ref.current) {
      if (isLoading) {
        ref.current.continuousStart();
      }
      if (isSuccess || isError) {
        ref.current.complete();
      }
    }
  }, [isError, isLoading, isSuccess]);

  const [dados] = getDataFill;

  const LegendCuston = ({ height, legends, width }) =>
    legends.map((legend) => (
      <BoxLegendSvg
        key={JSON.stringify(
          legend.data.map(({ id }) => {
            return id;
          })
        )}
        {...legend}
        containerHeight={height}
        containerWidth={width}
      />
    ));

  return (
    <>
      <LoadingBar
        color={finTheme.newtheme.color.azulPrimario}
        ref={ref}
        containerStyle={{ position: 'absoulte', top: '0' }}
        shadow={false}
      />
      <WrapperButton>
        <ButtonCircle
          ariaLabel="reload-chart-2"
          size="large"
          tooltipText="Recarregar"
          tooltipPlace="top"
          tipo="button"
          id="reload-chart-2"
          onClick={(e) => {
            e.stopPropagation();
            reloadChart();
          }}
        >
          <IcoRestaurar />
        </ButtonCircle>
      </WrapperButton>

      <ResponsiveLine
        theme={theme}
        data={getDataFill}
        margin={{ top: 60, right: 50, bottom: 50, left: 100 }}
        enableSlices={'x'}
        enableArea={true}
        colors={({ color }) => String(color)}
        curve={'linear'}
        defs={[
          linearGradientDef('faturamento', [
            { offset: 0, color: 'inherit' },
            { offset: 100, color: 'inherit', opacity: 0 },
          ]),
        ]}
        fill={[{ match: '*', id: 'faturamento' }]}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: true,
          reverse: false,
        }}
        yFormat={(value) => currency(value)}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          // legend: 'Dias',
          legendOffset: 36,
          legendPosition: 'middle',
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 12,
          tickRotation: 0,
          // legend: 'Faturamento',
          legendOffset: -120,
          legendPosition: 'middle',
          format: (value) => currency(value),
        }}
        markers={[
          {
            axis: 'y',
            value:
              dados.data.length > 0
                ? dados.data.filter((res) => res.ranking === 5)[0].y
                : 0,
            lineStyle: { stroke: '#646464', strokeWidth: 2 },
            legend: 'Top 5',
            legendOrientation: 'vertical',
          },
        ]}
        layers={[
          'grid',
          'axes',
          'areas',
          'lines',
          'markers',
          'crosshair',
          'slices',
          'mesh',
          'points',
          LegendCuston,
        ]}
        legends={[
          {
            dataFrom: 'keys',
            data: dados.keys.map((id, index) => ({
              color: rankingColors[index],
              id,
              label: id,
              // label: id?.length > 13 ? `${id.slice(0, 12)}...` : id,
            })),
            anchor: 'top-left',
            itemDirection: 'top-to-bottom',
            direction: 'row',
            translateX: -210,
            translateY: -50,
            itemsSpacing: 45,
            itemWidth: 100,
            itemHeight: 20,
            symbolSize: 10,
          },
        ]}
        pointSymbol={(props) => {
          const { datum } = props;
          return <CustomSymbol color={datum.color} ranking={datum.ranking} />;
        }}
        sliceTooltip={({ slice }) => {
          return <CustonTooltip slice={slice} />;
        }}
        pointSize={10}
        pointBorderWidth={1}
        pointBorderColor={{
          from: 'color',
          modifiers: [['darker', 0.3]],
        }}
        pointLabelYOffset={-12}
        useMesh={true}
        animate={true}
        motionConfig="stiff"
      />
    </>
  );
};

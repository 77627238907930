/* eslint-disable no-unused-vars */
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import CONFIG from 'config';
import toast from 'react-hot-toast';
import { handleErrors } from 'helpers/requisicoes/handleErrors';
import { getUserData } from 'services/getUserData';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const confirmar = async (params) => {
  const { idEmpresa, idUsuario } = await getUserData();

  const payload = {
    idConvenio: idEmpresa,
    idUsuario,
    ...params,
  };

  const mutateData = axios.put(
    `${BASE_URL}/proposta/confirma-convenio`,
    payload
  );

  const result = await mutateData;
  const { data } = result;

  return data || [];
};

export const useMutateConfirmarPagamento = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => confirmar(params), {
    onMutate: async (params) => {
      await queryClient.cancelQueries(['pagamento']);

      return { params };
    },
    onError: (error) => {
      const message = handleErrors(error);
      toast.error(message, { duration: 4000 });
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('pagamento');
    },
  });
};

import styled, { css } from 'styled-components/macro';

export const FieldStyle = styled.div`
  position: relative;
  width: ${({ largura }) => largura || '50%'};
  display: flex;
  flex-direction: ${({ direction }) => direction || 'column'};
  align-items: center;
`;

export const Label = styled.label`
  font-family: 'Roboto', sans-serif;
  color: #646464;
  letter-spacing: 0.4px;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 24px;
  text-transform: uppercase;
  margin: 0 0 0.4rem 6px;
  width: 100%;
  text-align: left;
`;

export const OptionStyle = styled.option`
  color: #1f1f1f;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.4px;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 24px;
`;

export const Focus = styled.span``;

export const StandardSelect = styled.select`
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  z-index: 1;
  outline: none;
  height: inherit;

  &:focus + ${Focus} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    box-shadow: 0 0 0 3px rgb(0 136 148 / 50%);
    border-color: #d8d8d8;

    border-radius: inherit;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
      background-color: #eee;
      background-image: linear-gradient(to top, #ddd, #eee 33%);
    `}

  &::-ms-expand {
    display: none;
  }
`;

export const Select = styled.div`
  display: grid;
  grid-template-areas: 'select';
  align-items: center;
  position: relative;

  min-width: 15ch;

  height: 30px;
  width: calc(100% - 6px);

  border: 1px solid #d8d8d8;
  border-radius: 0.25em;
  padding: 0 1rem;

  cursor: pointer;
  background-color: rgba(244, 244, 244, 0.3);
  z-index: 1;

  color: #1f1f1f;
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  font-size: 0.75rem;

  & + ${Label} {
    margin-top: 2rem;
  }

  select,
  &::after {
    grid-area: select;
  }

  &:after {
    content: '';
    justify-self: end;
    width: 0.8em;
    height: 0.5em;
    background-color: var(--select-arrow);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  }
`;

import React from 'react';

import ReactTooltip from 'react-tooltip';

import {
  SeparatorHorizontal,
  SeparatorVertical,
} from 'components/FinSeparador';
import { FinAvatar } from 'components/FinAvatar';
import { ImageRenderer } from 'components/FinImageRenderer';

import mini from 'assets/images/cover-placeholder.png';
import { currency } from 'helpers/format';
import { useGetImage } from 'hooks/useGetImage';
import { capitalizeWords } from 'helpers/string';
import { useLocation } from 'react-router-dom';
import { MenuContext } from '../MainMenu';

import { RenderIconMatch } from './RenderIconMatch';

import { useSelectTag } from '../../hook/useSelectTag';
import { RegionButtons } from './RegionButtons';
import { RegionSteps } from './RegionSteps';

import * as NWCard from './style.card';

export function CardHorizontal({
  isInvitation,
  isLocked,
  isConnection,
  showButtons,
  removeMenuContext,
  data,
}) {
  const { comp: TagMember } = useSelectTag({
    tipo: 'finscanner',
    pos: { left: 16, top: 16 },
  });

  const location = useLocation();

  const withImage = data.caminhoArquivo || null;
  const imageFallback = data.caminhoArquivo || mini;

  const fullImage = useGetImage({
    url: withImage,
    largura: 400,
    altura: 0,
    qualidade: 20,
    fallback: imageFallback,
  });

  const capitalizedName = capitalizeWords(data.nome);
  const capitalizedCity = capitalizeWords(data.cidade);

  return (
    <NWCard.Wrapper style={{ minWidth: '1130px' }}>
      <NWCard.Head largura="278px">
        <NWCard.Cover>
          {data.finscannerAtivo && <TagMember />}
          <ImageRenderer
            url={fullImage}
            thumb={mini}
            objfit={withImage ? 'cover' : 'fill'}
          />
        </NWCard.Cover>
      </NWCard.Head>
      <NWCard.Body
        style={{
          flexDirection: 'row',
          padding: '1rem 2rem',
          width: 'calc(100% - 278px)',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <NWCard.RegionIconMatch style={{ top: '16px', left: '317px' }}>
          <RenderIconMatch data={data} />
        </NWCard.RegionIconMatch>

        <NWCard.Flex direction="column" style={{ minWidth: '400px' }}>
          <ReactTooltip
            id={`card-name-financiador-${data.idGrupo}`}
            wrapper="span"
            place="right"
            type="dark"
            effect="solid"
          >
            {capitalizedName}
          </ReactTooltip>
          <NWCard.Flex>
            <FinAvatar
              bgColor="#f2f2f3"
              online={data.online}
              name={capitalizedName}
            />
            <SeparatorVertical pwidth="0.5rem" />
            <NWCard.Flex direction="column">
              <NWCard.CompanyName
                limite="20ch"
                to={{
                  pathname: `/fincredit/${data.idGrupo}`,
                  state: location.pathname,
                }}
                data-tip
                data-for={`card-name-financiador-${data.idGrupo}`}
              >
                {capitalizedName}
              </NWCard.CompanyName>
              <NWCard.CompanySegment>{data.segmento}</NWCard.CompanySegment>
            </NWCard.Flex>
          </NWCard.Flex>

          <SeparatorHorizontal ppadding="0.4rem" />
          <NWCard.Flex>
            <NWCard.Flex direction="column">
              <NWCard.Location
                style={{ marginBottom: '4px' }}
                isActive={!!data.cidade}
              >
                {data.cidade ? (
                  <>
                    {capitalizedCity}
                    <span
                      style={{ textTransform: 'uppercase' }}
                    >{`, ${data.estado}`}</span>
                  </>
                ) : (
                  'Localização'
                )}
              </NWCard.Location>
              <NWCard.Invoicing isActive={!!data.faturamento}>
                {data.faturamento ? currency(data.faturamento) : 'Faturamento'}
              </NWCard.Invoicing>
            </NWCard.Flex>
            <SeparatorVertical />
            <NWCard.Flex direction="column">
              <NWCard.FullProfile
                style={{ marginBottom: '4px' }}
                isActive={data.perfil}
              >
                Perfil
              </NWCard.FullProfile>
              <NWCard.FullProfile isActive={data.documento}>
                Documentos
              </NWCard.FullProfile>
            </NWCard.Flex>
          </NWCard.Flex>
        </NWCard.Flex>

        {showButtons ? (
          <RegionButtons
            largura="316px"
            isInvitation={isInvitation}
            isConnection={isConnection}
            isLocked={isLocked}
            data={data}
          />
        ) : (
          <RegionSteps data={data} />
        )}
      </NWCard.Body>
      {!removeMenuContext && <MenuContext fillColor="#646464" data={data} />}
    </NWCard.Wrapper>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { rgba } from 'polished';
import { Theme } from '../../theme';

const InputContainer = styled.div`
  align-items: center;
  background-color: #ffffff;
  border-radius: 3px;
  display: flex;
  padding: 0 10px;
  width: 100%;
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus-within {
    &:active {
      border-color: ${Theme.primary};
      box-shadow: ${`0 0 0 0.125em ${rgba(Theme.primary, 0.4)}`};
    }
    &:focus {
      border-color: ${Theme.primary};
      box-shadow: ${`0 0 0 0.125em ${rgba(Theme.primary, 0.4)}`};
    }
    &:hover {
      border-color: ${Theme.primary};
    }
  }

  ${(props) =>
    props.preenchimento === 'outline' &&
    css`
      border: 1px solid #e5e4e4;
    `};

  ${(props) =>
    props.preenchimento === 'flat' &&
    css`
      border: none;
    `};
`;

const Icone = styled.span`
  pointer-events: none;
`;

const InputComponente = styled.input`
  background: none;
  border: none;
  color: #6a6464;
  display: inline-block;
  flex: 1;
  font-size: 0.8rem;
  font-weight: normal;
  line-height: normal;
  margin: 0;
  outline: none;
  padding: 16px 0;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }

  ${(props) =>
    props.icone &&
    css`
      padding-left: 10px;
    `};

  ${(props) =>
    props.alinhamento === 'esquerda' &&
    css`
      text-align: left;
    `};

  ${(props) =>
    props.alinhamento === 'centro' &&
    css`
      text-align: center;
    `};

  ${(props) =>
    props.alinhamento === 'direita' &&
    css`
      text-align: right;
    `};
`;

const Input = ({
  alinhamento,
  disabled,
  icone,
  name,
  onChange,
  onFocus,
  onKeyDown,
  placeholder,
  preenchimento,
  type,
  value,
  custonStyles,
}) => {
  return (
    <InputContainer preenchimento={preenchimento}>
      {icone && <Icone data-testid="icone-input">{icone}</Icone>}
      <InputComponente
        alinhamento={alinhamento}
        disabled={disabled}
        name={name}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        icone={icone}
        type={type}
        data-testid="input"
        value={value}
        style={{ ...custonStyles }}
      />
    </InputContainer>
  );
};

Input.propTypes = {
  alinhamento: PropTypes.oneOf(['esquerda', 'centro', 'direita']),
  disabled: PropTypes.bool,
  icone: PropTypes.node,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  preenchimento: PropTypes.oneOf(['flat', 'outline']),
  type: PropTypes.oneOf([
    'text',
    'password',
    'number',
    'email',
    'phone',
    'tel',
  ]),
  value: PropTypes.string,
};

Input.defaultProps = {
  alinhamento: 'esquerda',
  disabled: false,
  icone: null,
  name: '',
  onChange: () => null,
  placeholder: '',
  preenchimento: 'outline',
  type: 'text',
  value: '',
};

export default Input;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Wrapper } from 'components/FinWrappers';
import { Paragraph2 } from 'components/FinParagraph';
import { Checkbox } from 'components/FinCheckboxCuston';

import { SeparatorHorizontal } from 'components/FinSeparador';

import { DialogCuston } from './DialogCuston';

const DialogHabilitacao = () => {
  const [checkValid1, setCheckValid1] = useState(false);
  const [checkValid2, setCheckValid2] = useState(false);
  const [checkValid3, setCheckValid3] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  const uiDialogState = useSelector((state) => state.uiDialog);

  useEffect(() => {
    setDisableButton(true);
    return () => setDisableButton(false);
  }, [setDisableButton]);

  useEffect(() => {
    if (checkValid1 && checkValid2 && checkValid3) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [checkValid1, checkValid2, checkValid3, disableButton]);

  return (
    <DialogCuston disablePrimaryButton={disableButton}>
      <Wrapper
        padding="32px 0"
        justifyContent="flex-start"
        alignItems="flex-start"
        tipo="column"
      >
        <Paragraph2>{uiDialogState.text}</Paragraph2>
        <SeparatorHorizontal ppadding="1rem" />
        <Checkbox
          key={`check-valid1`}
          name={`check-valid1`}
          value={`check-valid1`}
          label="O Contrato Mãe foi assinado entre as partes;"
          checked={checkValid1}
          padding="0"
          handleChange={() => setCheckValid1(!checkValid1)}
        />
        <Checkbox
          key={`check-valid2`}
          name={`check-valid2`}
          value={`check-valid2`}
          label="O Limite de Crédito foi aprovado, e;"
          checked={checkValid2}
          padding="0"
          handleChange={() => setCheckValid2(!checkValid2)}
        />
        <Checkbox
          key={`check-valid3`}
          name={`check-valid3`}
          value={`check-valid3`}
          label="O mesmo está APTO à operar."
          checked={checkValid3}
          padding="0"
          handleChange={() => setCheckValid3(!checkValid3)}
        />
      </Wrapper>
    </DialogCuston>
  );
};

export default DialogHabilitacao;

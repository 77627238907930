/* eslint-disable no-unused-vars */
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import CONFIG from 'config';
import { handleErrors } from 'helpers/requisicoes/handleErrors';
import toast from 'react-hot-toast';

import { getUserData } from 'services/getUserData';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const retorna = async (params) => {
  const { idUsuario } = await getUserData();

  const mutateData = axios.patch(
    `${BASE_URL}/agenda/retorna-valor/${params.idAgenda}/${idUsuario}`
  );

  const result = await mutateData;
  const { data } = result;

  return data || [];
};

export const useMutateRetornaValor = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => retorna(params), {
    onMutate: async (params) => {
      return { params };
    },
    onError: (error) => {
      const message = handleErrors(error);
      toast.error(message, { duration: 4000 });
    },
    onSuccess: (params, data, variables, context) => {
      queryClient.invalidateQueries('agenda');
    },
  });
};

/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { createActions, createReducer } from 'reduxsauce';

import msg from 'helpers/messages/index.json';

const messages = msg;

/**
 * Criando action types & creators
 */

export const { Types, Creators } = createActions({
  uiDialogOpen: ['tipo', 'confirm', 'cancel', 'values'],
  uiDialogClose: ['show'],
});

/**
 * Criando os reducer handlers
 */

const INITIAL_STATE = {
  tipo: '',
  title: '',
  text: '',
  buttons: [
    { name: '', action: '' },
    { name: 'Cancelar', action: Types.UI_DIALOG_CLOSE, show: true },
  ],
  show: false,
  listaMotivo: false,
  values: [],
};

const motivo = (tipoData, tipo) => {
  if (tipoData) {
    return false;
  }
  if (messages.dialog[tipo].listaMotivo) {
    return tipo;
  }
  return false;
};

const openDialog = (state = INITIAL_STATE, action) => {
  let tipoData = false;

  if (Array.isArray(action.tipo)) {
    tipoData = true;
  }

  return {
    tipo: action.tipo,
    title: tipoData
      ? action.tipo[0].title
      : messages.dialog[action.tipo].titulo,
    text: tipoData ? action.tipo[0].text : messages.dialog[action.tipo].texto,
    buttons: [
      {
        name: tipoData
          ? action.tipo[0].labelConfirme
          : messages.dialog[action.tipo].labelConfirme,
        action: action.confirm,
        show: tipoData
          ? action.tipo[0].labelConfirme !== undefined
          : messages.dialog[action.tipo].labelConfirme !== undefined,
      },
      {
        name: tipoData
          ? action.tipo[0].labelCancelar
          : messages.dialog[action.tipo].labelCancelar,
        action: action.cancel || { type: Types.UI_DIALOG_CLOSE },
        show: tipoData
          ? action.tipo[0].labelCancelar !== undefined
          : messages.dialog[action.tipo].labelCancelar !== undefined,
      },
    ],
    show: true,
    listaMotivo: motivo(tipoData, action.tipo),
    values: action.values,
  };
};

const closeDialog = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    tipo: '',
    title: '',
    text: '',
    buttons: [
      { name: '', action: '' },
      { name: 'Cancelar', action: { type: Types.UI_DIALOG_CLOSE }, show: true },
    ],
    show: false,
    listaMotivo: false,
    values: [],
  };
};

/**
 * Criando reducer
 */

export default createReducer(INITIAL_STATE, {
  [Types.UI_DIALOG_OPEN]: openDialog,
  [Types.UI_DIALOG_CLOSE]: closeDialog,
});

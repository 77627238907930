export const dashboardKeys = {
  all: ['dashboard'],
  faturamento: () => [...dashboardKeys.all, 'faturamento'],
  faturamentoMensal: (filters) => [
    ...dashboardKeys.faturamento(),
    'mensal',
    filters,
  ],
  faturamentoDiario: (filters) => [
    ...dashboardKeys.faturamento(),
    'diario',
    filters,
  ],
  sacado: () => [...dashboardKeys.all, 'sacado'],
  sacadoConcentracao: (filters) => [
    ...dashboardKeys.sacado(),
    'concentracao',
    filters,
  ],
};

import React from 'react';

import {
  PaginationButton,
  ArrowTitle,
  Arrow,
  PaginationLinkNext,
} from './style.pagination';

export const NextButton = ({ onclick, sum, visibility, disabled }) => (
  <PaginationButton visibility={visibility}>
    <PaginationLinkNext disabled={disabled} onClick={(e) => onclick(e, sum)}>
      <ArrowTitle></ArrowTitle>
      <Arrow />
    </PaginationLinkNext>
  </PaginationButton>
);

/* eslint-disable prettier/prettier */
import { createStore, compose, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { history } from '../components/History';

import indexSaga from './sagas';
import createRootReducer from './ducks';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['uiPopUp', 'uiNotificationContent'],
};

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
);

const sagaMonitor =
  process.env.NODE_ENV === 'development'
    ? console.tron.createSagaMonitor()
    : null;

const sagaMiddleware = createSagaMiddleware({
  sagaMonitor,
});

const enhancer =
  process.env.NODE_ENV === 'development'
    ? compose(
      applyMiddleware(routerMiddleware(history), sagaMiddleware),
      console.tron.createEnhancer()
    )
    : compose(applyMiddleware(routerMiddleware(history), sagaMiddleware));

function configureStore(preloadedState) {
  const store = createStore(persistedReducer, preloadedState, enhancer);
  return store;
}

const store = configureStore();
const persistor = persistStore(store);

sagaMiddleware.run(indexSaga);

export { store, persistor };

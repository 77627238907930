import { useQuery } from 'react-query';
import axios from 'axios';
import CONFIG from 'config';
import { getUserData } from 'services/getUserData';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const fetchSelecionaConvenio = async (params) => {
  const { idUsuario, idEmpresa } = await getUserData();
  const fetchData = axios.post(`${BASE_URL}/proposta/seleciona/convenio`, {
    ...params,
    idUsuario,
    idConvenio: idEmpresa,
  });

  const result = await fetchData;
  const { data } = result;

  return data || [];
};

export const useFetchSelecionaConvenio = (params) => {
  return useQuery(
    ['pagamento', 'list', params],
    () => fetchSelecionaConvenio(params),
    {
      keepPreviousData: false,
    }
  );
};

import React from 'react';
import { ReactComponent as ImageError } from 'assets/images/undraw_bug_fixing_oc-7-a.svg';
import { Title, Text, WrapperImage, Container } from './style.placeholder';

export function NWError() {
  return (
    <Container>
      <WrapperImage>
        <ImageError />
      </WrapperImage>
      <Title>Algo deu errado</Title>
      <Text>Erro inesperado</Text>
    </Container>
  );
}

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Wrapper } from 'components/FinWrappers';
import { Paragraph2 } from 'components/FinParagraph';
import { InputTextAreaMaterial } from 'components/FinInput/InputMaterial';

import { DialogCuston } from './DialogCuston';

const DialogDesistirOperacao = () => {
  const [observacao, setObservacao] = useState('');
  const [error, setError] = useState(null);
  const uiDialogState = useSelector((state) => state.uiDialog);

  const clear = () => {
    setObservacao('');
  };

  const onChange = (e) => {
    if (e.target.value === '') {
      setError(true);
    } else {
      setError(false);
    }
    setObservacao(e.target.value);
  };

  useEffect(() => {
    return () => setError(true);
  }, []);

  return (
    <DialogCuston
      disablePrimaryButton={error === null || error}
      whenClosing={clear}
      whenConfirming={clear}
      aditionalPayloadToAction={{ observacao }}
      customTitle={`${uiDialogState.title}${uiDialogState.values}`}
    >
      <Wrapper
        padding="32px 0"
        justifyContent="flex-start"
        alignItems="flex-start"
        tipo="column"
      >
        <Paragraph2>{uiDialogState.text}</Paragraph2>
        <InputTextAreaMaterial
          id="observacao"
          label="Observação"
          handleChange={onChange}
          largura="100%"
          value={observacao}
          error={error === true}
          errormessage="Preencha o campo"
        />
      </Wrapper>
    </DialogCuston>
  );
};

export default DialogDesistirOperacao;

import styled from 'styled-components/macro';

import { Wrapper } from 'components/FinWrappers';

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.newtheme.color.branco};
`;

export const BoxWrapper = styled(Wrapper)`
  position: relative;
  width: ${({ largura }) => largura || `1000px`};
  height: ${({ altura }) => altura || `347px`};
  flex-direction: column;
  border: ${({ theme }) => `1px solid ${theme.newtheme.color.cinza2}`};
  border-bottom: ${({ theme }) => `3px solid ${theme.newtheme.color.cinza2}`};
`;

export const WrapperChart = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const WrapperButton = styled(Wrapper)`
  position: absolute;
  top: -64px;
  right: 0;
  z-index: 9;
`;

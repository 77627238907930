import React from 'react';

import { Provider, idStatusCredito } from './reducer';

import { CreditoAprovado } from './CreditoAprovado';
import { CreditoPendente } from './CreditoPendente';
import { CreditoRecusado } from './CreditoRecusado';

const Main = (props) => {
  const { status, ...rest } = props;
  switch (idStatusCredito[status]) {
    case 'creditoPendente':
      return <CreditoPendente {...rest} />;
    case 'creditoAprovado':
      return <CreditoAprovado {...rest} />;
    case 'creditoRecusado':
      return <CreditoRecusado {...rest} />;
    default:
      return <CreditoPendente {...rest} />;
  }
};

export function RenderCredito(props) {
  return (
    <Provider>
      <Main {...props} />
    </Provider>
  );
}

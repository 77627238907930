import axios from 'axios';

import config from '../config';

const api = axios.create({
  baseURL: config.API_BASE_URL + '/api/',
});

const api2 = axios.create({
  baseURL: 'https://json-server-convenio.herokuapp.com/',
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const apis = {
  getInfoDocs: requestOptions =>
    api.post('cedente/info-basica', requestOptions),
  loginUser: requestOptions => api.post('acesso/loga', null, requestOptions),
  retornaProposta: requestOptions =>
    api
      .post('Proposta/Retorna', requestOptions, {
        cancelToken: source.token,
      })
      .catch(function(thrown) {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
          return null;
        } else {
          // handle error
        }
      }),
  getPerfil: requestOptions => api.post('convenio/perfil', requestOptions),
  getLisDocs: requestOptions => api.post('documento/lista', requestOptions),
  sourceCancel: () => source.cancel('Operation canceled by the user.'),
  loadProposta: requestOptions =>
    api.post('proposta/lista-propostas', requestOptions),
  loadMatchs: () =>
    api2
      .get('matchs', {
        cancelToken: source.token,
      })
      .catch(function(thrown) {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
          return null;
        } else {
          // handle error
        }
      }),
  loadTodas: () =>
    api2
      .get('todas', {
        cancelToken: source.token,
      })
      .catch(function(thrown) {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
          return null;
        } else {
          // handle error
        }
      }),
  loadBorderos: requestOptions => api.post('proposta/bordero', requestOptions),
  downloadBorderos: requestOptions =>
    api.get('http://api-xml-02.azurewebsites.net/api/NFe/read?idCedente=7'),
};

export default apis;

import React from 'react';
import { SeparatorVertical } from 'components/FinSeparador';
import { Paragraph2 } from 'components/FinParagraph';
import { WrapperStatusStyle } from './style';
import { Circle } from '../../Ofertas/ExibePreco/style';

export const WrapperStatus = ({ label, bgColor, children }) => {
  return (
    <WrapperStatusStyle bgColor={bgColor}>
      <Circle largura="50px" altura="50px">
        {children}
      </Circle>
      <SeparatorVertical pwidth="0.8rem" />
      <Paragraph2
        style={{
          width: '180px',
          lineHeight: '18px',
        }}
      >
        {label}
      </Paragraph2>
    </WrapperStatusStyle>
  );
};

import React from 'react';

import { Bar } from '@nivo/bar';

import { capitalizeFirstLetter } from 'helpers/string';

import { ReactComponent as IconBuild } from 'assets/images/Buildings.svg';
import { ReactComponent as IconFiles } from 'assets/images/Files.svg';
import { ReactComponent as IconCalendar } from 'assets/images/CalendarCheck.svg';
import { ReactComponent as IconCalendarX } from 'assets/images/CalendarX.svg';
import { ReactComponent as IconChart } from 'assets/images/ChartPieSliceGrey.svg';

import { currency } from 'helpers/format';

import {
  Lagend,
  Container,
  Label,
  TooltipRoot,
  Title,
  Bullet,
  Box,
  NumberBox,
  TextBox,
  Header,
} from './style.bar-chart';
import { CustomBar } from './CustonBar';
import { theme } from '../../Metricas/theme';

const commonProps = {
  theme,
  width: 750,
  height: 400,
  padding: 0.1,
  margin: { top: 60, right: 130, bottom: 30, left: 60 },
  groupMode: 'grouped',
  innerPadding: 1,
  indexBy: 'mes',
  labelTextColor: 'inherit:darker(1.4)',
  labelSkipWidth: 16,
  labelSkipHeight: 16,
};

const divergingCommonProps = {
  ...commonProps,
  enableGridX: true,
  enableGridY: true,
  valueFormat: (value) => `${Math.abs(value)}`,
  axisTop: {
    tickSize: 0,
    tickPadding: 12,
  },
  // axisBottom: {
  //   legend: 'Endividamento Anual',
  //   legendPosition: 'middle',
  //   legendOffset: 50,
  //   tickSize: 0,
  //   tickPadding: 12,
  // },
  axisLeft: null,
  axisRight: {
    format: (v) => currency(v, 0, ''),
  },
  markers: [
    {
      axis: 'y',
      value: 0,
      lineStyle: { strokeOpacity: 0 },
      textStyle: { fill: '#2ebca6' },
      legend: 'À Vencer',
      legendPosition: 'top-left',
      legendOrientation: 'vertical',
      legendOffsetY: 65,
      legendOffsetX: -20,
    },
    {
      axis: 'y',
      value: 0,
      lineStyle: { stroke: '#f47560', strokeWidth: 1 },
      textStyle: { fill: '#e25c3b' },
      legend: 'Vencido',
      legendPosition: 'bottom-left',
      legendOrientation: 'vertical',
      legendOffsetY: 65,
      legendOffsetX: -20,
    },
  ],
};

const ignore = ['mes', 'label'];
const found = (value) => ignore.some((item) => value.includes(item));

const dias = {
  curtoPrazoVencer: '(até 60 dias)',
  medioPrazoVencer: '(até 180 dias)',
  longoPrazoVencer: '(até 360 dias)',
  curtoPrazoVencido: '(até 60 dias)',
  medioPrazoVencido: '(até 180 dias)',
  longoPrazoVencido: '(até 360 dias)',
};

export function ChartEndividamento({ data }) {
  const { endividamentoAnual, consolidado } = data;

  const { carteiraAvencer, carteiraVencido, instituicoes, operações } =
    consolidado;

  const dataKeys = Object.keys(endividamentoAnual[0]);
  const keys = dataKeys.filter((res) => !found(res));

  return (
    <Container style={{ marginTop: '1rem', padding: '1rem' }}>
      <Header>
        <Box css={{ bgColor: '#D6FBFF' }}>
          <IconBuild width={24} height={24} style={{ marginBottom: '8px' }} />
          <NumberBox>{instituicoes}</NumberBox>
          <TextBox>Instituições</TextBox>
        </Box>
        <Box css={{ bgColor: '#D6FBFF' }}>
          <IconFiles width={24} height={24} style={{ marginBottom: '8px' }} />
          <NumberBox>{operações}</NumberBox>
          <TextBox>Operações</TextBox>
        </Box>
        <Box css={{ bgColor: '#05B6C8' }}>
          <IconCalendar
            width={24}
            height={24}
            style={{ marginBottom: '8px' }}
          />
          <NumberBox>{currency(carteiraAvencer)}</NumberBox>
          <TextBox>Carteira à Vencer</TextBox>
        </Box>
        <Box css={{ bgColor: '#FBDFDF' }}>
          <IconCalendarX
            width={24}
            height={24}
            style={{ marginBottom: '8px' }}
          />
          <NumberBox>{currency(carteiraVencido)}</NumberBox>
          <TextBox>Carteira Vencido</TextBox>
        </Box>
        <Box css={{ bgColor: '#EDEDED' }}>
          <IconChart
            width={24}
            height={24}
            style={{ marginBottom: '8px', fill: 'black' }}
          />
          <NumberBox>{`${
            consolidado.porcentagemCarteiraFaturamento || '-'
          } %`}</NumberBox>
          <TextBox>% sobre o Faturamento</TextBox>
        </Box>
      </Header>

      <Bar
        {...divergingCommonProps}
        keys={keys}
        data={endividamentoAnual}
        barComponent={CustomBar}
        colors={[
          '#77F2FF',
          '#06CAE0',
          '#03707C',
          '#FCBDB6',
          '#F64F3C',
          '#7A1106',
        ]}
        valueFormat={(v) => currency(v)}
        tooltip={(props) => {
          const { data, id, color } = props;

          const label = data[`label${capitalizeFirstLetter(id)}`];
          return (
            <TooltipRoot>
              <Bullet css={{ bgColor: color }} />
              <Title>{`${label} ${dias[id]}`}</Title>
              <Label>{currency(props.value)}</Label>
            </TooltipRoot>
          );
        }}
        // valueScale={{ type: 'symlog' }}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
      />
      <Lagend>$ Valores em Reais</Lagend>
    </Container>
  );
}

import React from 'react';

import { ReactComponent as IconGlobe } from 'assets/images/icon-globe-alt.svg';
import { ReactComponent as IconRede } from 'assets/images/icon-rede.svg';
import { ReactComponent as IconMail } from 'assets/images/icon-mail-flat.svg';
import { ReactComponent as IconGraph } from 'assets/images/icon-graph-flat.svg';
import { ReactComponent as IconLocked } from 'assets/images/icon-lock-key-flat.svg';

import { ReactComponent as IconCheck } from 'assets/images/CheckCircleFlat.svg';
import { ReactComponent as IconWarning } from 'assets/images/WarningFlat.svg';
import { ReactComponent as IconError } from 'assets/images/XCircleFlat.svg';

export const routesPages = {
  convite: '/minha-rede/convites?invite=received',
  conviteRecebido: '/minha-rede/convites?invite=received',
  conviteEnviado: '/minha-rede/convites?invite=sent',
  clientes: '/minha-rede/clientes',
  conexoes: '/minha-rede/conexoes',
  bloqueados: '/minha-rede/bloqueados',
};

export const pagesLabel = (value) => {
  const label = {
    '/minha-rede/convites': 'Convites',
    '/minha-rede/clientes': 'Clientes',
    '/minha-rede/conexoes': 'Minhas Conexões',
    '/minha-rede/bloqueados': 'Bloqueados',
  };

  return label[value] || null;
};

export const iconsTitle = {
  '/minha-rede': { icon: <IconGlobe />, label: 'Minha Rede' },
  '/minha-rede/': { icon: <IconGlobe />, label: 'Minha Rede' },
  '/minha-rede/inicio': { icon: <IconGlobe />, label: 'Minha Rede' },
  '/minha-rede/convites': { icon: <IconMail />, label: 'Convites' },
  '/minha-rede/clientes': { icon: <IconRede />, label: 'Clientes' },
  '/minha-rede/conexoes': { icon: <IconGraph />, label: 'Minhas Conexões' },
  '/minha-rede/bloqueados': { icon: <IconLocked />, label: 'Bloqueados' },
};

export const statusConexao = {
  pendente: 1,
  aceita: 2,
  recusada: 3,
  cancelada: 4,
};

export const statusHabilitacao = {
  pendente: 1,
  recusado: 2,
  habilitado: 3,
};

export const habilitacaoTextIcon = {
  1: {
    label: 'Habilitação Pendente',
    icon: <IconWarning />,
    color: 'var(--finyellow-500)',
  },
  2: {
    label: 'Não Habilitado',
    icon: <IconError />,
    color: 'var(--finred-500)',
  },
  3: { label: 'Habilitado', icon: <IconCheck />, color: 'var(--fingreen-300)' },
};

import React, { useContext } from 'react';

import ReactSpeedometer from 'react-d3-speedometer';
import { FinMatchContext } from '../PageFinmatch';

export function Speedometer() {
  const { data } = useContext(FinMatchContext);
  const { matchPorcentagem } = data.informacoes_Match;
  const valor = `${matchPorcentagem.toFixed(0)}%`;

  return (
    <div
      style={{
        padding: '2rem 2.813rem 1rem',
        width: '50%',
        justifyContent: 'flex-start',
      }}
    >
      <ReactSpeedometer
        ringWidth={56}
        width={220}
        height={165}
        minValue={0}
        maxValue={100}
        value={matchPorcentagem}
        needleTransitionDuration={1500}
        needleColor="#000"
        currentValueText={valor}
        labelFontSize="12px"
        valueTextFontSize={16}
        valueTextFontWeight="bold"
        paddingHorizontal={5}
        paddingVertical={5}
        segments={10}
        needleTransition="easeElastic"
        segmentColors={[
          '#8C1F28',
          '#D92525',
          '#D92525',
          '#F2A71B',
          '#F2A71B',
          '#F2A71B',
          '#F2CB05',
          '#89D99D',
          '#3B8C6E',
          '#3B8C6E',
        ]}
        textColor="var(--fingray-900)"
      />
    </div>
  );
}

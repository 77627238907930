import { useContext } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { getUserData } from 'services/getUserData';
import CONFIG from 'config';

import { OperacoesContext } from '../provider';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const fetchOperacoes = async (params) => {
  const { idEmpresa } = await getUserData();

  if (idEmpresa) {
    const date =
      params.tipoAba !== 1
        ? { dataInicioVigencia: params.dataInicioVigencia }
        : {};

    const tipo =
      Number(params.tipoPregao) === 0
        ? null
        : { tipoPregao: Number(params.tipoPregao) };

    const payload = {
      size: params.size || 5,
      skip: params.skip || 1,
      filter: {
        idConvenio: idEmpresa,
        tipoAba: params.tipoAba,
        busca: params.busca,
        ordemDataInicio: 1,
        ...date,
        ...tipo,
      },
    };

    const fetchData = axios.post(
      `${BASE_URL}/pregao/proposta/ofertas/convenios`,
      payload
    );

    const result = await fetchData;
    const { data } = result;

    return data || [];
  }

  return [];
};

const ABAS = {
  emAberto: 1,
  todas: 2,
  realizadas: 3,
  canceladas: 4,
  desistencias: 5,
  perdidas: 6,
};

export const useFetchOperacoes = () => {
  const [state] = useContext(OperacoesContext);
  const {
    navigation: { tabActive, tipoPregao },
    pagination: { skip },
    pagination: { size },
    search,
    date,
  } = state;

  const params = {
    size,
    skip,
    busca: search,
    dataInicioVigencia: date,
    tipoAba: ABAS[tabActive],
    tipoPregao,
  };

  return useQuery(['operacoes', 'list', params], () => fetchOperacoes(params), {
    keepPreviousData: false,
  });
};

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { useFeedback } from 'hooks/useFeedback';
import { useActions } from 'hooks/useActions';

import { Creators as apiPesquisaActions } from 'store/ducks/apiPesquisa';

import { LoadingInfo } from 'components/FinLoading';
import FeedbackInfo from 'components/FinFeedbackInfo';

import { SearchMenu, ButtonMore } from 'components/FinSearchMenu';
import { SeparatorHorizontal } from 'components/FinSeparador';

import { TagLoadingInfo } from 'components/FinTag';
import { finTheme } from 'finTheme';
import { Wrapper } from 'components/FinWrappers';
import ItemListSearch from './ItemListSearch';

import { Btn } from './Styles';

const ResultListSearch = ({ open, close, moreItens, page }) => {
  const pesquisaState = useSelector((state) => state.apiPesquisa);
  const { ready, waiting, error, empty } = useFeedback(pesquisaState);

  const apiPesquisa = useActions(apiPesquisaActions);

  const pesquisaClear = useCallback(() => {
    apiPesquisa.apiPesquisaCancel();
  }, [apiPesquisa]);

  return (
    <SearchMenu position="50px" open={open} onClose={close}>
      {ready && (
        <Wrapper justifyContent="flex-start" pwidth="100%" padding="0 0 1rem 0">
          <Btn onClick={pesquisaClear}>Limpar pesquisa</Btn>
        </Wrapper>
      )}
      {waiting && <LoadingInfo loading={waiting} />}
      {error && <FeedbackInfo tipo="error" />}
      {empty && <FeedbackInfo tipo="empty" text="Conteúdo não disponível" />}
      {ready &&
        pesquisaState.data.map((res, index) => (
          <ItemListSearch key={index} onClose={close} data={res} />
        ))}
      {ready &&
        !pesquisaState.isLoadingMore &&
        page < pesquisaState.totalPages && <ButtonMore onclick={moreItens} />}
      {ready && pesquisaState.isLoadingMore && (
        <TagLoadingInfo bgcolor={finTheme.newtheme.color.success}>
          Aguarde...
        </TagLoadingInfo>
      )}
      <SeparatorHorizontal ppadding="0.8rem" />
    </SearchMenu>
  );
};

ResultListSearch.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default ResultListSearch;

/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { createActions, createReducer } from 'reduxsauce';
import {
  call,
  put,
  take,
  fork,
  cancel,
  cancelled,
  select,
} from 'redux-saga/effects';
import axios from 'axios';

import { getUserData } from 'services/getUserData';
import { Creators as uiToasterActions } from 'store/ducks/uiToaster';
import { Creators as uiPaginationActions } from 'store/ducks/uiPagination';

import { toLowerCaseTrim, stringToBoolean } from 'helpers/string';
import { RemoveUndefined } from 'helpers/Object';

import { push } from 'connected-react-router';
import CONFIG from '../../config';

const { CancelToken } = axios;

/**
 * Criando action types & creators
 */

export const { Types, Creators } = createActions({
  apiListClientesRequest: ['idCedente', 'reloadBkg'],
  apiListClientesSuccess: ['data'],
  apiListClientesError: [],
  apiListClientesCancel: [],
  apiListClientesInviteSent: ['data'],
  apiListClientesEnableSent: ['data'],
  apiListClientesDisableSent: ['data'],
  apiListClientesBlockSent: ['data'],
  apiListClientesUnlockSent: ['data'],
  apiListClientesCancelInviteSent: ['data'],
  apiListClientesAcceptInviteSent: ['data'],
  apiListClientesRefuseInviteSent: ['data'],
  apiListClientesClear: [],
});

/**
 * Criando os reducer handlers
 */

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: false,
};

const editData = (data, id, field) => {
  const updateData = data.map((res) => {
    if (res.idGrupo === id) {
      return {
        id: res.id,
        idGrupo: res.idGrupo,
        idconexao: res.idconexao,
        nome: res.nome,
        cnpj: res.cnpj,
        segmento: res.segmento,
        faturamento: res.faturamento,
        online: res.online,
        bloqueado: res.bloqueado,
        documento: res.documento,
        perfil: res.perfil,
        criptografia: res.criptografia,
        statusConexao: res.statusConexao,
        statusHabilitado: res.statusHabilitado,
        ...field,
      };
    }
    return res;
  });

  return updateData;
};

const apiListRequest = (state = INITIAL_STATE, action) => {
  if (action.reloadBkg) {
    return {
      ...state,
      isLoading: false,
    };
  }

  return {
    isLoading: true,
    data: [],
    error: false,
  };
};

const apiListSuccess = (state = INITIAL_STATE, action) => {
  return {
    isLoading: false,
    data: action.data,
    error: false,
  };
};

const apiListError = (state = INITIAL_STATE, action) => {
  return {
    isLoading: false,
    data: [],
    error: true,
  };
};

const apiListCancel = (state = INITIAL_STATE, action) => {
  return {
    isLoading: false,
    data: [],
    error: false,
  };
};

const apiListClear = (state = INITIAL_STATE, action) => {
  return {
    isLoading: false,
    data: [],
    error: false,
  };
};

const apiInviteSent = (state = INITIAL_STATE, action) => {
  const { ready, id, remove } = action.data;
  const field = remove
    ? null
    : { localChangedReady: ready, localChangedMessage: 'enviando-convite' };

  const updateData = editData(state.data, id, field);
  console.log(updateData);

  return {
    ...state,
    data: updateData,
  };
};

const apiEnableSent = (state = INITIAL_STATE, action) => {
  const { ready, id, remove } = action.data;

  let changed = {
    localChangedReady: ready,
    localChangedMessage: 'habilitando',
  };

  if (ready) {
    changed = {
      statusHabilitado: 3,
      localChangedReady: ready,
      localChangedMessage: 'habilitando',
    };
  }

  const field = remove ? null : changed;

  const updateData = editData(state.data, id, field);
  console.log(updateData);

  return {
    ...state,
    data: updateData,
  };
};

const apiDisableSent = (state = INITIAL_STATE, action) => {
  const { ready, id, remove } = action.data;

  let changed = {
    localChangedReady: ready,
    localChangedMessage: 'desabilitando',
  };

  if (ready) {
    changed = {
      statusHabilitado: 2,
      localChangedReady: ready,
      localChangedMessage: 'desabilitando',
    };
  }

  const field = remove ? null : changed;

  const updateData = editData(state.data, id, field);
  console.log(updateData);

  return {
    ...state,
    data: updateData,
  };
};

const apiBlockSent = (state = INITIAL_STATE, action) => {
  const { ready, id, remove } = action.data;

  let changed = {
    localChangedReady: ready,
    localChangedMessage: 'bloqueando',
  };

  if (ready) {
    changed = {
      bloqueado: true,
      localChangedReady: ready,
      localChangedMessage: 'bloqueando',
      localChangedIsHide: true,
    };
  }

  let field = changed;

  if (remove) {
    field = null;
  }

  const blocked = editData(state.data, id, field);
  console.log(blocked);

  return {
    ...state,
    data: blocked,
  };
};

const apiUnlockSent = (state = INITIAL_STATE, action) => {
  const { ready, id, remove } = action.data;

  let changed = {
    localChangedReady: ready,
    localChangedMessage: 'desbloqueando',
  };

  if (ready) {
    changed = {
      bloqueado: false,
      localChangedReady: ready,
      localChangedMessage: 'desbloqueando',
      localChangedIsHide: true,
    };
  }

  let field = changed;

  if (remove) {
    field = null;
  }

  const unlocked = editData(state.data, id, field);
  console.log(unlocked);

  return {
    ...state,
    data: unlocked,
  };
};

const apiCancelInviteSent = (state = INITIAL_STATE, action) => {
  const { ready, id, remove } = action.data;
  const field = remove
    ? null
    : { localChangedReady: ready, localChangedMessage: 'cancelando' };

  const updateData = editData(state.data, id, field);

  console.log(updateData);

  return {
    ...state,
    data: updateData,
  };
};

const apiAcceptInviteSent = (state = INITIAL_STATE, action) => {
  const { ready, id, remove } = action.data;

  let changed = {
    localChangedReady: ready,
    localChangedMessage: 'aceitando',
  };

  if (ready) {
    changed = {
      statusConexao: 2,
      statusHabilitado: 1,
      localChangedReady: ready,
      localChangedMessage: 'aceitando',
    };
  }

  const field = remove ? null : changed;

  const updateData = editData(state.data, id, field);
  console.log(updateData);

  return {
    ...state,
    data: updateData,
  };
};

const apiRefuseInviteSent = (state = INITIAL_STATE, action) => {
  const { ready, id, remove } = action.data;

  let changed = {
    localChangedReady: ready,
    localChangedMessage: 'aceitando',
  };

  if (ready) {
    changed = {
      statusConexao: 3,
      statusHabilitado: null,
      localChangedReady: ready,
      localChangedMessage: 'aceitando',
    };
  }

  const field = remove ? null : changed;

  const updateData = editData(state.data, id, field);
  console.log(updateData);

  return {
    ...state,
    data: updateData,
  };
};

/**
 * Criando reducer
 */

export default createReducer(INITIAL_STATE, {
  [Types.API_LIST_CLIENTES_REQUEST]: apiListRequest,
  [Types.API_LIST_CLIENTES_SUCCESS]: apiListSuccess,
  [Types.API_LIST_CLIENTES_ERROR]: apiListError,
  [Types.API_LIST_CLIENTES_CANCEL]: apiListCancel,
  [Types.API_LIST_CLIENTES_INVITE_SENT]: apiInviteSent,
  [Types.API_LIST_CLIENTES_ENABLE_SENT]: apiEnableSent,
  [Types.API_LIST_CLIENTES_DISABLE_SENT]: apiDisableSent,
  [Types.API_LIST_CLIENTES_BLOCK_SENT]: apiBlockSent,
  [Types.API_LIST_CLIENTES_UNLOCK_SENT]: apiUnlockSent,
  [Types.API_LIST_CLIENTES_CANCEL_INVITE_SENT]: apiCancelInviteSent,
  [Types.API_LIST_CLIENTES_ACCEPT_INVITE_SENT]: apiAcceptInviteSent,
  [Types.API_LIST_CLIENTES_REFUSE_INVITE_SENT]: apiRefuseInviteSent,
  [Types.API_LIST_CLIENTES_CLEAR]: apiListClear,
});

/**
 * Sagas
 */

function* sagaGetAsynResult(params, endpoint) {
  const source = CancelToken.source();
  const url = `${CONFIG.API_REMODELING_URL}${endpoint}`;
  // const url = `${CONFIG.API_BASE_URL}${endpoint}`;

  try {
    const response = yield axios.post(url, params, {
      cancelToken: source.token,
    });

    yield put(Creators.apiListClientesSuccess(response.data.data || []));
    const { totalRecords, totalPages } = response.data;

    yield put(uiPaginationActions.uiPagination({ totalRecords, totalPages }));
  } catch (error) {
    console.log(error);
    yield put(uiToasterActions.uiToasterMessage(error, 'error'));
    yield put(Creators.apiListClientesError());
  } finally {
    if (yield cancelled()) {
      console.log('cancel apiListClientes');
      source.cancel();
    }
  }
}

function* emptyQuerySet() {
  const route = (state) => state.router.location;
  const location = yield select(route);
  if (location.pathname === '/clientes') {
    const query = [];
    Object.keys(location.query).forEach((res) => {
      if (['page', 'qtd', 'order', 'aba'].includes(res)) {
        query.push(res);
      }
    });

    if (query.length !== 4) {
      yield put(push(`?page=1&qtd=10&order=a-z&aba=todos`));
    }
  }
}

function clearCnae(cnae) {
  if (cnae) {
    const cnaeParsed = JSON.parse(decodeURIComponent(cnae));
    const values = [];
    cnaeParsed.forEach((res) => {
      values.push(JSON.parse(res));
    });

    const codigo = values.map((res) => res.cnae);
    return codigo;
  }
  return false;
}

function* getParams(user, action) {
  try {
    const route = (state) => state.router.location;
    const location = yield select(route);

    const abas = {
      todos: 1,
      conexoes: 2,
      bloqueado: 3,
      pesquisa: 4,
    };

    const { query } = location;

    const decodedQuery = Object.keys(query).map((res) => ({
      [decodeURIComponent(res)]: query[res],
    }));

    let objectQuery = {};
    decodedQuery.forEach((res) => {
      objectQuery = { ...objectQuery, ...res };
    });

    const {
      busca,
      cnae,
      segmentos,
      docscompleto,
      docsincompleto,
      endividamentode,
      endividamentoate,
      faturamentode,
      faturamentoate,
      perfilcompleto,
      perfilincompleto,
      estado,
      online,
      offline,
      habilitado,
      desabilitado,
      idcedente,
      serviço,
      comércio,
      indústria,
      order,
    } = objectQuery;

    let idEmpreendedor = idcedente;
    let aba = abas[objectQuery.aba] || 1;
    let page = objectQuery.page || 1;
    let size = objectQuery.qtd || 10;

    if (action.idCedente) {
      const idgrupo = action.idCedente.split('-')[1];
      idEmpreendedor = idgrupo;
      aba = 4;
      page = 1;
      size = 10;
    }

    const data = {
      busca: busca ? toLowerCaseTrim(decodeURIComponent(busca)) : undefined,
      CNAE: cnae ? clearCnae(cnae) : undefined,
      segmentos: segmentos
        ? JSON.parse(decodeURIComponent(segmentos))
        : undefined,
      // segindustria: indústria ? stringToBoolean(indústria) : undefined,
      // segcomercio: comércio ? stringToBoolean(comércio) : undefined,
      // segservico: serviço ? stringToBoolean(serviço) : undefined,
      documentocompleto: docscompleto
        ? stringToBoolean(docscompleto)
        : undefined,
      documentoincompleto: docsincompleto
        ? stringToBoolean(docsincompleto)
        : undefined,
      perfilcompleto: perfilcompleto
        ? stringToBoolean(perfilcompleto)
        : undefined,
      perfilincompleto: perfilincompleto
        ? stringToBoolean(perfilincompleto)
        : undefined,
      faturamento_de:
        faturamentode !== undefined ? Number(faturamentode) : undefined,
      faturamento_ate:
        faturamentoate !== undefined ? Number(faturamentoate) : undefined,
      endividamento_de:
        endividamentode !== undefined ? endividamentode : undefined,
      endividamento_ate:
        endividamentoate !== undefined ? endividamentoate : undefined,
      online: online ? stringToBoolean(online) : undefined,
      offline: offline ? stringToBoolean(offline) : undefined,
      estado: estado ? JSON.parse(decodeURIComponent(estado)) : undefined,
      habilitado: habilitado ? stringToBoolean(habilitado) : undefined,
      desabilitado: desabilitado ? stringToBoolean(desabilitado) : undefined,
      idcedente: idEmpreendedor ? Number(idEmpreendedor) : undefined,
      ordem: order === 'a-z' ? 2 : 1,
    };

    RemoveUndefined(data);

    const values = {
      skip: Number(page),
      size: Number(size),
      filter: {
        tipoaba: aba,
        // Idconvenio: user.idEmpresa,
        ...data,
      },
    };

    return values;
  } catch (error) {
    yield put(uiToasterActions.uiToasterMessage(error, 'error'));
    yield put(Creators.apiListClientesError());
  }

  return null;
}

export function* sagaListClientes(action) {
  const user = yield call(getUserData);

  if (user) {
    yield call(emptyQuerySet);

    const params = yield call(getParams, user, action);

    const task = yield fork(
      sagaGetAsynResult,
      params,
      `/business-api/api/matching/cliente/cedente/${user.idEmpresa}`
    );

    /* const task = yield fork(
      sagaGetAsynResult,
      params,
      '/api/matching/cliente_cedentes'
    ); */

    yield take(Types.API_LIST_CLIENTES_CANCEL);
    yield cancel(task);
  } else {
    yield put(Creators.apiListClientesError());
  }
}

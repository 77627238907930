import React from 'react';
import * as NW from './style.popever';

export const PopoverContent = React.forwardRef(
  ({ children, ...props }, forwardedRef) => {
    return (
      <NW.PopoverContent {...props} ref={forwardedRef}>
        {children}
      </NW.PopoverContent>
    );
  }
);

import React, { useContext } from 'react';

import { ReactComponent as IconUser } from 'assets/images/icon-user-f.svg';
import { ReactComponent as IconFinscanner } from 'assets/images/icon-file-arrow-up.svg';
import { ReactComponent as IconFinmatch } from 'assets/images/icon-finmatch.svg';
import { ReactComponent as IconCoins } from 'assets/images/icon-coins-money.svg';
import { ReactComponent as IconUserSquare } from 'assets/images/UserSquare.svg';

import { statusConexao } from 'pages/MinhaRede/config';
import { TargetStep } from 'components/FinWizard';

import { PerfilContext } from '../../PaginaFinCredit';
import { Flex, Label } from './styles.tab';
import { Tab, Item, ItemTabLink } from './index';

export function TabsFincredit({ match }) {
  const { perfil } = useContext(PerfilContext);

  const conexao =
    perfil.data && perfil.data.statusConexao ? perfil.data.statusConexao : null;

  const {
    params: { idGrupo },
  } = match;

  return (
    <Flex style={{ padding: '1.5rem 0', marginTop: '1.5rem' }}>
      <Tab>
        <ItemTabLink to={`/fincredit/${idGrupo}/perfil`}>
          <Item>
            <IconUser />
            <Label>Perfil</Label>
          </Item>
        </ItemTabLink>
        {conexao === statusConexao.aceita && (
          <ItemTabLink to={`/fincredit/${idGrupo}/endividamento`}>
            <TargetStep id="target1">
              <div>
                <Item>
                  <IconCoins />
                  <Label>Endividamento (SCR)</Label>
                </Item>
              </div>
            </TargetStep>
          </ItemTabLink>
        )}
        {conexao === statusConexao.aceita && (
          <ItemTabLink to={`/fincredit/${idGrupo}/socios`}>
            <TargetStep id="target2">
              <Item>
                <IconUserSquare />
                <Label>Sócios</Label>
              </Item>
            </TargetStep>
          </ItemTabLink>
        )}
        {conexao === statusConexao.aceita && (
          <ItemTabLink to={`/fincredit/${idGrupo}/finscanner`}>
            <Item>
              <IconFinscanner />
              <Label>Finscanner</Label>
            </Item>
          </ItemTabLink>
        )}
        <ItemTabLink to={`/fincredit/${idGrupo}/finmatch`}>
          <Item>
            <IconFinmatch />
            <Label>Finmatch</Label>
          </Item>
        </ItemTabLink>
      </Tab>
    </Flex>
  );
}

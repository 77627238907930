import React from 'react';
import styled, { css } from 'styled-components/macro';
import { useField } from 'formik';
import CurrencyInput from 'react-currency-input';
import { TooltipDefault } from 'components/TooltipDefault';
import ImageIco from 'images/iconsSvg/info.svg';
import { rgba } from 'polished';
import { finTheme } from 'finTheme';

export const Input = styled.input`
  background-color: ${({ theme }) => theme.newtheme.color.branco};
  border: ${({ theme }) => `2px solid  ${theme.newtheme.color.cinza2}`};
  border-radius: 2px;
  align-items: center;
  text-align: left;
  box-shadow: none;
  display: inline-flex;
  font-size: 0.9rem;
  height: 2.5rem;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.375em - 2px);
  padding-left: calc(0.625em - 2px);
  padding-right: calc(0.625em - 2px);
  padding-top: calc(0.375em - 2px);
  position: relative;
  vertical-align: top;
  width: 100%;
  color: ${({ theme }) => theme.newtheme.color.cinza5};
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:active {
    border-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
    box-shadow: ${({ theme }) =>
      `0 0 0 0.125em ${rgba(theme.newtheme.color.azulPrimario, 0.4)}`};
  }
  &:focus {
    border-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
    box-shadow: ${({ theme }) =>
      `0 0 0 0.125em ${rgba(theme.newtheme.color.azulPrimario, 0.4)}`};
  }
  &:hover {
    border-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
  }
`;

export const TextArea = styled.textarea`
  background-color: ${({ theme }) => theme.newtheme.color.branco};
  border: ${({ theme }) => `2px solid  ${theme.newtheme.color.cinza2}`};
  border-radius: 2px;
  align-items: center;
  text-align: left;
  box-shadow: none;
  display: inline-flex;
  font-size: 0.9rem;
  height: 6rem;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.375em - 2px);
  padding-left: calc(0.625em - 2px);
  padding-right: calc(0.625em - 2px);
  padding-top: calc(0.375em - 2px);
  position: relative;
  vertical-align: top;
  width: 100%;
  color: ${({ theme }) => theme.newtheme.color.cinza5};
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:active {
    border-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
    box-shadow: ${({ theme }) =>
      `0 0 0 0.125em ${rgba(theme.newtheme.color.azulPrimario, 0.4)}`};
  }
  &:focus {
    border-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
    box-shadow: ${({ theme }) =>
      `0 0 0 0.125em ${rgba(theme.newtheme.color.azulPrimario, 0.4)}`};
  }
  &:hover {
    border-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
  }
`;

export const InputCurrency = styled(CurrencyInput)`
  background-color: ${({ theme }) => theme.newtheme.color.branco};
  border: ${({ theme }) => `2px solid  ${theme.newtheme.color.cinza2}`};
  border-radius: 2px;
  align-items: center;
  text-align: left;
  box-shadow: none;
  display: inline-flex;
  font-size: 0.9rem;
  height: 2.5rem;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.375em - 2px);
  padding-left: calc(0.625em - 2px);
  padding-right: calc(0.625em - 2px);
  padding-top: calc(0.375em - 2px);
  position: relative;
  vertical-align: top;
  width: 100%;
  color: ${({ theme }) => theme.newtheme.color.cinza5};
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:active {
    border-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
    box-shadow: ${({ theme }) =>
      `0 0 0 0.125em ${rgba(theme.newtheme.color.azulPrimario, 0.4)}`};
  }
  &:focus {
    border-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
    box-shadow: ${({ theme }) =>
      `0 0 0 0.125em ${rgba(theme.newtheme.color.azulPrimario, 0.4)}`};
  }
  &:hover {
    border-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
  }
`;

const StatusBar = styled.span`
  display: block;
  position: relative;
  margin-top: -1px;
  height: 2px;
  width: 100%;
  &:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    transform: scaleX(0);
    transition: transform 0.25s ease, transform 0.25s ease;
  }
`;

const Label = styled.span`
  color: ${({ theme, color }) => color || theme.newtheme.color.cinza6};
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  letter-spacing: ${({ theme }) => theme.newtheme.font.caption.espacamento};
  font-weight: ${({ fontweight, theme }) =>
    fontweight || theme.newtheme.font.caption.peso};
  font-size: ${({ theme, fontSize }) =>
    fontSize || theme.newtheme.font.caption.tamanho};
  line-height: 24px;
  ${({ marginbottom }) =>
    marginbottom &&
    css`
      margin-bottom: ${marginbottom};
    `}

  .tooltip_match {
    float: right;
    left: 5px;
    top: 2px;
  }
`;

export const InputMainMaterial = styled.input.attrs(
  (props) =>
    props.placeholder && {
      placeholder: props.placeholder,
    }
)`
  padding-left: 0;
  padding-bottom: 5px;
  background: hsla(0, 0%, 100%, 0);
  /* font-size: 12px; */
  font-size: ${({ fontSize }) => fontSize || '12px'};
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  letter-spacing: ${({ theme }) => theme.newtheme.font.caption.espacamento};
  font-weight: 400;
  color: #333;
  line-height: 1.35;
  border: 0;
  border-bottom: 1px solid #ccc;
  height: 30px;
  border-radius: 0;
  width: 100%;
  outline: 0;
  font-weight: 400;
`;

export const InputNumberFormat = styled(CurrencyInput).attrs(
  (props) =>
    props.placeholder && {
      placeholder: props.placeholder,
    }
)`
  padding-left: 0;
  padding-bottom: 5px;
  background: hsla(0, 0%, 100%, 0);
  font-size: ${({ fontSize }) => fontSize || '12px'};
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  letter-spacing: ${({ theme }) => theme.newtheme.font.caption.espacamento};
  font-weight: 400;
  color: #333;
  line-height: 1.35;
  border: 0;
  border-bottom: 1px solid #ccc;
  height: 30px;
  border-radius: 0;
  width: 100%;
  outline: 0;
  font-weight: 400;
`;

export const InputMainTextAreaMaterial = styled.textarea`
  padding-left: 0;
  padding-bottom: 5px;
  background: hsla(0, 0%, 100%, 0);
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  letter-spacing: ${({ theme }) => theme.newtheme.font.caption.espacamento};
  font-weight: 400;
  color: #333;
  line-height: 1.35;
  border: 0;
  border-bottom: 1px solid #ccc;
  height: 6rem;
  border-radius: 0;
  width: 100%;
  outline: 0;
  font-weight: 400;
  background-color: ${({ theme }) => theme.newtheme.color.cinza1};
`;

const Field = styled.div`
  width: ${({ largura }) => largura || '50%'};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 45px;
  &:hover ${StatusBar}:after {
    background-color: rgba(0, 0, 0, 0.45);
    transform: scaleX(1);
  }
  & ${InputMainMaterial}:focus + ${StatusBar}:after {
    background-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
    transform: scaleX(1);
  }
  ${({ error }) =>
    error &&
    css`
      & ${InputMainMaterial}:focus + ${StatusBar}:after {
        background-color: ${({ theme }) => theme.newtheme.color.error};
        transform: scaleX(1);
      }
    `}
  & ${InputMainTextAreaMaterial}:focus + ${StatusBar}:after {
    background-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
    transform: scaleX(1);
  }
  ${({ error }) =>
    error &&
    css`
      & ${InputMainTextAreaMaterial}:focus + ${StatusBar}:after {
        background-color: ${({ theme }) => theme.newtheme.color.error};
        transform: scaleX(1);
      }
    `}

  & ${InputNumberFormat}:focus + ${StatusBar}:after {
    background-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
    transform: scaleX(1);
  }
  ${({ error }) =>
    error &&
    css`
      & ${InputNumberFormat}:focus + ${StatusBar}:after {
        background-color: ${({ theme }) => theme.newtheme.color.error};
        transform: scaleX(1);
      }
    `}
`;

const InputFeedback = styled.div`
  color: ${({ cor, theme }) => cor || theme.newtheme.color.error};
  margin-top: 5px;

  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  letter-spacing: ${({ theme }) => theme.newtheme.font.caption.espacamento};
  font-weight: ${({ fontweight, theme }) =>
    fontweight || theme.newtheme.font.caption.peso};
  font-size: ${({ theme }) => theme.newtheme.font.caption.tamanho};
  line-height: 14px;
`;

export const InputMaterial = ({
  label,
  value,
  handleBlur,
  id,
  error,
  handleChange,
  errormessage,
  largura,
  colorLabel,
  weightLabel,
  fontSize,
  placeholder,
  handleClick,
  tooltip,
}) => {
  return (
    <Field error={error} largura={largura}>
      <Label
        fontweight={weightLabel || '400'}
        marginbottom="0.5rem"
        fontSize={fontSize}
        color={colorLabel || finTheme.newtheme.color.cinza6}
      >
        {label}
        {tooltip && (
          <TooltipDefault
            style={{ float: 'right', left: '5px', top: '2px' }}
            position="left"
            data={tooltip}
          >
            <img src={ImageIco} width="18" alt="Icon" />
          </TooltipDefault>
        )}
      </Label>

      <InputMainMaterial
        id={id}
        onChange={handleChange}
        value={value}
        onBlur={handleBlur}
        fontSize={fontSize}
        placeholder={placeholder}
        onClick={handleClick}
      />

      <StatusBar />
      {error && <InputFeedback>{errormessage}</InputFeedback>}
    </Field>
  );
};

export const InputFormatMaterial = ({
  label,
  value,
  id,
  error,
  handleChange,
  errormessage,
  largura,
  colorLabel,
  fontSize,
  weightLabel,
  placeholder,
  retornaValor,
  setFieldValue,
  tooltip,
  prefix,
}) => {
  const handleCuston = (event, maskedvalue, floatvalue) => {
    setFieldValue(id, floatvalue);
  };
  return (
    <Field error={error} largura={largura}>
      <Label
        color={colorLabel || finTheme.newtheme.color.cinza6}
        fontweight={weightLabel || '400'}
        marginbottom="0.5rem"
        fontSize={fontSize}
      >
        {label}
        {tooltip && (
          <TooltipDefault
            style={{ float: 'right', left: '5px', top: '2px' }}
            position="left"
            data={tooltip}
          >
            <img src={ImageIco} width="18" alt="Icon" />
          </TooltipDefault>
        )}
      </Label>

      <InputNumberFormat
        id={id}
        placeholder={placeholder}
        prefix={prefix || 'R$ '}
        decimalSeparator=","
        thousandSeparator="."
        precision={2}
        // onChangeEvent={handleChange}
        onChangeEvent={retornaValor ? handleCuston : handleChange}
        value={value}
        fontSize={fontSize}
      />

      <StatusBar />
      {error && <InputFeedback>{errormessage}</InputFeedback>}
    </Field>
  );
};

export const InputFormatNumberMaterial = ({
  label,
  value,
  id,
  error,
  handleChange,
  errormessage,
  largura,
  colorLabel,
  fontSize,
  weightLabel,
  placeholder,
  retornaValor,
  setFieldValue,
  tooltip,
}) => {
  const handleCuston = (event, maskedvalue, floatvalue) => {
    setFieldValue(id, floatvalue);
  };

  return (
    <Field error={error} largura={largura}>
      <Label
        color={colorLabel || finTheme.newtheme.color.cinza6}
        fontweight={weightLabel || '400'}
        marginbottom="0.5rem"
        fontSize={fontSize}
      >
        {label}
        {tooltip && (
          <TooltipDefault
            style={{ float: 'right', left: '5px', top: '2px' }}
            position="left"
            data={tooltip}
          >
            <img src={ImageIco} width="18" alt="Icon" />
          </TooltipDefault>
        )}
      </Label>

      <InputNumberFormat
        id={id}
        placeholder={placeholder}
        // prefix={prefix || 'R$ '}
        decimalSeparator=","
        thousandSeparator="."
        precision={2}
        maxLenght="3"
        // onChangeEvent={handleChange}
        onChangeEvent={retornaValor ? handleCuston : handleChange}
        value={value}
        fontSize={fontSize}
      />

      <StatusBar />
      {error && <InputFeedback>{errormessage}</InputFeedback>}
    </Field>
  );
};

export const InputTextAreaMaterial = ({
  label,
  value,
  handleBlur,
  id,
  error,
  handleChange,
  errormessage,
  largura,
  fontSize,
}) => {
  return (
    <Field error={error} largura={largura}>
      <Label
        color={finTheme.newtheme.color.cinza6}
        fontweight="600"
        marginbottom="0.5rem"
      >
        {label}
      </Label>
      <InputMainTextAreaMaterial
        id={id}
        onChange={handleChange}
        value={value}
        onBlur={handleBlur}
        fontSize={fontSize}
      />
      <StatusBar />
      {error && <InputFeedback>{errormessage}</InputFeedback>}
    </Field>
  );
};

/**
 * useField create components
 */

const TextareaMainInput = InputMainMaterial.withComponent('textarea');
export const TextareaInputFormik = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <Field error={meta.error} largura={props.largura}>
      <Label htmlFor={props.id || props.name}>{label}</Label>
      <TextareaMainInput {...field} {...props} rows={5} />
      <StatusBar />
      {meta.touched && meta.error ? (
        <InputFeedback>{meta.error}</InputFeedback>
      ) : null}
    </Field>
  );
};

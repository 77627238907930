/* eslint-disable no-unused-vars */
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import CONFIG from 'config';
import { handleErrors } from 'helpers/requisicoes/handleErrors';
import toast from 'react-hot-toast';
import { getUserData } from 'services/getUserData';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const updateAprovacaoCredito = async (params) => {
  const { idEmpresa } = await getUserData();

  const payload = {
    idGrupo: params.idGrupo,
    idConvenio: idEmpresa,
    limite: params.limite,
    tranche: params.tranche,
    concentracaoMaxima: params.concentracao,
    motivo: params.motivo,
    idAprovacaoStatus: params.status,
  };

  const mutateData = axios.post(`${BASE_URL}/aprovacao-credito`, payload);

  const result = await mutateData;
  const { data } = result;

  return data || [];
};

export const useMutateAprovacaoCredito = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params) => updateAprovacaoCredito({ ...params, status: 2 }),
    {
      onMutate: async (params) => {
        return { params };
      },
      onError: (error) => {
        const message = handleErrors(error);
        toast.error(message, { duration: 4000 });
      },
      onSuccess: (params, data, variables, context) => {
        variables.params.changeCondition();
        queryClient.invalidateQueries('fluxo');
      },
    }
  );
};

export const useMutateRecusarCredito = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params) => updateAprovacaoCredito({ ...params, status: 3 }),
    {
      onMutate: async (params) => {
        return { params };
      },
      onError: (error) => {
        const message = handleErrors(error);
        toast.error(message, { duration: 4000 });
      },
      onSuccess: (params, data, variables, context) => {
        variables.params.changeCondition();
        queryClient.invalidateQueries('fluxo');
      },
    }
  );
};

import React from 'react';
import styled from 'styled-components/macro';
import { rem, rgba } from 'polished';

const Doth = styled.span`
  margin: 0 8px;
  color: ${({ theme }) => rgba(theme.newtheme.color.cinza6, 0.4)};
  font-size: ${rem('16px')};
  font-weight: 800;
`;

const Bullet = () => {
  return <Doth>•</Doth>;
};

export default Bullet;

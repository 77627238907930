import { useQuery } from 'react-query';
import axios from 'axios';
import CONFIG from 'config';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const fetchListaPropostas = async (params) => {
  const fetchData = axios.get(
    `${BASE_URL}/proposta/convenio/lista/${params.idOperacao}`
  );

  const result = await fetchData;
  const { data } = result;

  return data || [];
};

export const useFetchListaPropostas = (params) => {
  return useQuery(
    ['propostas', 'lista', params],
    () => fetchListaPropostas(params),
    {
      keepPreviousData: false,
    }
  );
};

import { createStitches } from '@stitches/react';

const { styled } = createStitches({
  utils: {
    bgColor: (value) => ({
      backgroundColor: value,
    }),
  },
});

export const Background = styled('div', {
  minWidth: '27px',
  height: '27px',
  borderRadius: '30px',
  padding: '0 0.3rem',
  bgColor: 'var(--finblue-300)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const Label = styled('span', {
  fontFamily: 'Inter',
  fontWeight: '700',
  fontSize: '1rem',
  textAlign: 'center',
  color: 'var(--fingray-900)',
  variants: {
    color: {
      white: { color: '#ffffff' },
    },
  },
  overflow: 'hidden',

  textRendering: 'geometricprecision',
  transform: 'translate(0, 0) scale(0.85)',
  whiteSpace: 'nowrap',
  userSelect: 'none',
});

import React from 'react';
import styled from 'styled-components/macro';

const WrapperLetter = styled.div`
  font-size: ${(props) => (props.fsize ? props.fsize : 'inherit')};
`;

const InitialLetters = (props) => {
  const initLetters = () => {
    if (props.letters) {
      let res = props.letters;
      res = res.toUpperCase();
      res = res.split(' ');
      let letters = null;
      if (res.length > 1) {
        letters = {
          first: res[0].charAt(0),
          last: res[1].charAt(0),
        };
      } else {
        letters = {
          first: res[0].charAt(0),
          last: '',
        };
      }
      return letters;
    }
    const letters = {
      first: 'D',
      last: 'F',
    };
    return letters;
  };
  const lettersIni = initLetters();
  return (
    <WrapperLetter fsize={props.fsize}>
      <span>{lettersIni.first}</span>
      <span>{lettersIni.last}</span>
    </WrapperLetter>
  );
};

export default InitialLetters;

/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { createActions, createReducer } from 'reduxsauce';
import {
  call,
  put,
  take,
  fork,
  cancel,
  cancelled,
  select,
} from 'redux-saga/effects';
import axios, { CancelToken } from 'axios';

import { getUserData } from 'services/getUserData';
import { Creators as uiToasterActions } from 'store/ducks/uiToaster';
import { Creators as apiListClientesActions } from 'store/ducks/apiListClientes';
import { CreatorsUiMessages } from 'store/ducks/uiMessages';

import {
  Creators as uiDialogActions,
  Types as uiDialogsTypes,
} from 'store/ducks/uiDialog';

import CONFIG from '../../config';

/**
 * Criando action types & creators
 */

export const { Types, Creators } = createActions({
  apiConvidarRequest: ['idCedente'],
  apiConvidarSuccess: ['data'],
  apiConvidarError: [],
  apiConvidarCancel: [],
});

/**
 * Criando os reducer handlers
 */

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: false,
};

const apiSetRequest = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: true,
    data: [],
    error: false,
  };
};

const apiSetSuccess = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: action.data,
    error: false,
  };
};

const apiSetError = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: [],
    error: true,
  };
};

const apiSetCancel = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: [],
    error: false,
  };
};

/**
 * Criando reducer
 */

export default createReducer(INITIAL_STATE, {
  [Types.API_CONVIDAR_REQUEST]: apiSetRequest,
  [Types.API_CONVIDAR_SUCCESS]: apiSetSuccess,
  [Types.API_CONVIDAR_ERROR]: apiSetError,
  [Types.API_CONVIDAR_CANCEL]: apiSetCancel,
});

/**
 * Sagas
 */

function* sagaGetAsynResult(params, endpoint) {
  const source = CancelToken.source();
  const url = `${CONFIG.API_REMODELING_URL}${endpoint}`;
  try {
    const response = yield axios.post(url, params, {
      cancelToken: source.token,
    });

    yield put(Creators.apiConvidarSuccess(response.data.data));

    const stateRouter = (state) => state.router;
    const routes = yield select(stateRouter);

    if (routes.location.pathname.indexOf('detalhe') !== -1) {
      yield put(
        CreatorsUiMessages.uiSendMessage('invitation_sent_sucessfully')
      );
    }

    yield put(
      apiListClientesActions.apiListClientesInviteSent({
        ready: true,
        id: params.idGrupo,
      })
    );

    yield put(
      uiToasterActions.uiToasterMessage(
        { message: 'Enviado com sucesso!!' },
        'success'
      )
    );
  } catch (error) {
    console.log('[ERROR]', error.response);

    if (error.response.status === 400) {
      yield put(
        uiDialogActions.uiDialogOpen(
          [
            {
              title: 'Atenção',
              text: error.response.data.message,
              labelConfirme: 'OK',
            },
          ],
          {
            type: uiDialogsTypes.UI_DIALOG_CLOSE,
          },
          false
        )
      );
    }

    yield put(
      apiListClientesActions.apiListClientesInviteSent({
        remove: true,
        id: params.idGrupo,
      })
    );
    if (error.response.status === 500) {
      yield put(uiToasterActions.uiToasterMessage(error, 'error'));
    }

    yield put(Creators.apiConvidarError());
  } finally {
    if (yield cancelled()) {
      console.log('cancel');
      source.cancel();
    }
  }
}

export function* sagaConvidar(action) {
  const user = yield call(getUserData);

  console.log(action);

  if (user) {
    // const params = {
    //   idCedente: action.idCedente,
    //   idPessoa: user.idUsuario,
    //   idConvenio: user.idEmpresa,
    // };

    const params = {
      idGrupo: action.idCedente,
      idConvenio: user.idEmpresa,
      idUsuario: user.idUsuario,
    };

    yield put(
      apiListClientesActions.apiListClientesInviteSent({
        ready: false,
        id: params.idGrupo,
      })
    );

    console.log(params);

    const task = yield fork(
      sagaGetAsynResult,
      params,
      '/business-api/api/matching/conexao/financiador/grupo'
    );

    yield take(Types.API_CONVIDAR_CANCEL);
    yield cancel(task);
  } else {
    yield put(Creators.apiConvidarError());
  }
}

import axios from 'axios';
import { useQuery } from 'react-query';
import CONFIG from 'config';
import { getUserData } from 'services/getUserData';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/`;

const apiSocios = async (params) => {
  const { idEmpresa } = await getUserData();

  const payload = {
    size: 200,
    skip: 1,
    filter: {
      idGrupo: Number(params.idGrupo),
      idConvenio: idEmpresa,
    },
  };

  const fetchData = axios.post(
    `${BASE_URL}api/cedente/perfil/socios/financiador`,
    payload
  );

  const result = await fetchData;
  const { data } = result;

  return data.data ? data.data : [];
};

export const useQueryQuadroSocietario = (params) => {
  return useQuery(['socios', params], () => apiSocios(params), {
    keepPreviousData: false,
  });
};

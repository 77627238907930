import { useQuery } from 'react-query';
import axios from 'axios';
import CONFIG from 'config';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const fetchHeader = async (params) => {
  const fetchData = axios.get(
    `${BASE_URL}/operacao/header/${params.idOperacao}`
  );

  const result = await fetchData;
  const { data } = result;

  return data || [];
};

export const useFetchHeader = (params) => {
  return useQuery(['operacoes', 'header', params], () => fetchHeader(params), {
    keepPreviousData: false,
  });
};

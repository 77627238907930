import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
  background-color: #ffffff;
  max-width: 852px;
  padding: 1.5rem;

  & span:last-child {
    padding-bottom: 27px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  ${({ largura }) => largura && `width: ${largura}`};
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight}`};
`;

export const SubTitle = styled.span`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 16px;
  color: var(--fingray-900);
  line-height: 36px;
`;

export const InformationAdd = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--fingray-900);
`;

export const Flex = styled.div`
  display: flex;
  ${({ row }) =>
    row &&
    css`
      flex-direction: row;
    `}

  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
    `}

  justify-content: ${({ justify }) => justify || `center`};
  align-items: ${({ align }) => align || `center`};
  width: 100%;
`;

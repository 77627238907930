import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { device } from 'devices';
import { flexUnit } from 'mixins';

import Button from 'components/FinButton';
import { Wrapper } from 'components/FinWrappers';

import { currency } from 'helpers/format';

const WrapperFooter = styled.footer`
  width: 100vw;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.newtheme.color.vermelhoPrimario};
  padding: 0px 6px;
  ${flexUnit(5, 12, 18, 'vw', 'font-size')}

  @media ${device.laptop} {
    padding: 0px 6px;
  }

  @media ${device.desktop} {
    padding: 0px 6px;
  }
`;

const TextValue = styled.span`
  /*font-size: ${({ size }) => size || '1rem'};*/
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  width: ${({ largura }) => largura || '200px'};
  font-weight: ${({ bold }) => bold || '400'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ cor, theme }) => cor || theme.newtheme.color.branco};
  ${flexUnit(2, 9, 16, 'vw', 'font-size')}
`;

const Footer = ({ antecipacao, emprestimo, total }) => {
  return (
    <WrapperFooter>
      <Wrapper tipo="column">
        <TextValue size="0.9rem">Líquido Antecipação</TextValue>
        <TextValue bold="600">{currency(antecipacao)}</TextValue>
      </Wrapper>
      <Wrapper tipo="column">
        <TextValue size="0.9rem">Líquido Empréstimo</TextValue>
        <TextValue bold="600">{currency(emprestimo)}</TextValue>
      </Wrapper>
      <Wrapper tipo="column">
        <TextValue size="0.9rem">Líquido Total</TextValue>
        <TextValue bold="600">{currency(total)}</TextValue>
      </Wrapper>
      <Button tipo="light">Criar oferta</Button>
    </WrapperFooter>
  );
};

Footer.propTypes = {
  antecipacao: PropTypes.number,
  emprestimo: PropTypes.number,
  total: PropTypes.number,
};

Footer.defaultProps = {
  antecipacao: 90043.04,
  emprestimo: 40,
  total: 10000,
};

export default Footer;

import React from 'react';

import { useHistory } from 'react-router-dom';
import { useRenderResponseData } from 'hooks/useRenderResponseData';
import {
  Heading,
  RegionListCards,
  TitleSmall,
  RegionTitle,
  RegionAction,
} from '../style.layout';

import { routesPages } from '../config';

import { Card, List, ItemList } from '../Comps/Card';
import { NWButton } from '../Comps/Button';

import { EmptyInvite } from '../Convites';

import { useQueryClientsPotential } from '../hook/useQueryClients';
import { useQueryInvitesReceived } from '../hook/useQueryInvites';

export function Inicio() {
  const { data, isLoading, error } = useQueryClientsPotential();
  const received = useQueryInvitesReceived();

  const withData = data && data.data;

  const history = useHistory();
  const gotoPage = (path) => {
    history.push(path);
  };

  const { PlaceNoResult, PlaceLoader, PlaceError } = useRenderResponseData({
    data,
    isLoading,
    error,
  });

  const emptyInvites = received.data && received.data.data.length === 0;

  return (
    <>
      <RegionListCards>
        {emptyInvites && <EmptyInvite gotoPage={gotoPage} />}
        {received.data && received.data.data && !emptyInvites && (
          <>
            <Heading>
              <RegionTitle>
                <TitleSmall>Convites pendentes</TitleSmall>
              </RegionTitle>
              <RegionAction>
                <NWButton.Secondary
                  onClick={() => gotoPage(routesPages.convite)}
                >
                  Gerenciar Convites
                </NWButton.Secondary>
              </RegionAction>
            </Heading>
            <List direction="vertical">
              {received.data &&
                received.data.data &&
                received.data.data.map((item) => {
                  return (
                    <ItemList key={item.idGrupo}>
                      <Card isInvitation="received" data={item} />
                    </ItemList>
                  );
                })}
            </List>
          </>
        )}
      </RegionListCards>
      <RegionListCards>
        <Heading>
          <RegionTitle>
            <TitleSmall>Clientes</TitleSmall>
          </RegionTitle>
          <RegionAction>
            <NWButton.Secondary onClick={() => gotoPage(routesPages.clientes)}>
              Ver todos clientes
            </NWButton.Secondary>
          </RegionAction>
        </Heading>
        <PlaceNoResult />
        <PlaceLoader />
        <PlaceError />
        <List direction="vertical">
          {withData &&
            data.data.map((item) => {
              return (
                <ItemList key={item.idGrupo}>
                  <Card data={item} />
                </ItemList>
              );
            })}
        </List>
      </RegionListCards>
    </>
  );
}

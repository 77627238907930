import axios from 'axios';
import { put, select, call } from 'redux-saga/effects';
import { getUserData } from 'services/getUserData';
import {
  showNotification,
  contentNotification,
  setFilteredDataListSuccess,
  setFilteredDataListFailure,
  setFilteredDataFinboxSuccess,
  setFilteredDataFinboxFailure,
  showLoading,
  hideLoading,
  loadPaginacao,
  resetFilter,
} from 'store/old_actions/actions';
import config from '../../config';

import { getPregoes, getFinalizados } from './selectors';
// import { getFiltersList } from './selectors';

/** *********************** Search pregoes saga ***************************** */
export function* searchItems(action) {
  let dataSearch = [];
  let filtered = [];

  try {
    if (action.tipo === 'pregoes') {
      dataSearch = yield select(getPregoes);
    }
    if (action.tipo === 'finalizados') {
      dataSearch = yield select(getFinalizados);
    }

    if (action.value !== '') {
      dataSearch.forEach((item, index) => {
        const cedente =
          item.cedente
            .toString()
            .toLowerCase()
            .search(action.value.toString().toLowerCase()) !== -1;

        const idPregao =
          item.idPregao
            .toString()
            .toLowerCase()
            .search(action.value.toString().toLowerCase()) !== -1;

        const idOperacao =
          item.idOperacao
            .toString()
            .toLowerCase()
            .search(action.value.toString().toLowerCase()) !== -1;

        const dtInicio =
          item.dtInicio
            .toString()
            .toLowerCase()
            .search(action.value.toString().toLowerCase()) !== -1;

        const valorBruto =
          item.valorBruto
            .toString()
            .toLowerCase()
            .search(action.value.toString().toLowerCase()) !== -1;

        if (
          cedente === true ||
          idPregao === true ||
          idOperacao === true ||
          dtInicio === true ||
          valorBruto === true
        ) {
          filtered[index] = item;
        }
      });

      yield put(setFilteredDataListSuccess(filtered));
    } else {
      filtered = dataSearch;
      yield put(setFilteredDataListSuccess(filtered));
    }
  } catch (err) {
    yield put(
      contentNotification(
        `Erro!!! Name: ${err.name}Message :${err.message}`,
        2000
      )
    );
    yield put(showNotification());
    yield put(setFilteredDataListFailure(err.response));
  }
}

/** *********************** Search finbox saga ***************************** */

export function* searchItemsFinbox(action) {
  try {
    const user = yield call(getUserData);

    yield put(resetFilter());

    /**
     * filters


    const filters = yield select(getFiltersList);

    const cnae = filters.cnae.map(res => {
      const c = JSON.parse(res);
      return c.cnae;
    });

    const values = {
      faturamento: [filters.faturamento],
      cnae: cnae,
      estados: filters.estados,
      segmento: filters.segmento,
      status: filters.status,
      habilitacao: filters.habilitacao,
      acesso: filters.acesso,
    };

    console.log(values);

    const vals = Object.keys(filters).filter(res =>
      Array.isArray(filters[res])
        ? filters[res].length > 0
        : filters[res].toString() !== '0'
    );

    console.log(vals);

    const list = {};
    vals.forEach(r => {
      list[r] = values[r];
    });

    end filters */

    yield put(showLoading());

    const reqData = {
      idConvenio: user.idEmpresa,
      idPessoaConvenio: user.idUsuario,
      pagina: 1,
      quantidade: 10,
      ordem: 'nome',
      ordemCampo: 'crescente',
      pesquisa: action.value.toString().toLowerCase(),
      // ...list,
    };

    // console.log(JSON.stringify(reqData));

    // Request data
    const res = yield axios.post(
      `${config.API_BASE_URL}/api/cedente/lista`,
      reqData
    );

    // console.log(res.data);

    yield put({ type: 'SET_FILTERED_DATA_FINBOX_CLEAR' });
    yield put({ type: 'LOAD_PAGINACAO_CLEAR' });

    yield put(setFilteredDataFinboxSuccess(res.data.result));
    yield put(loadPaginacao(res.data.paging));
    yield put(hideLoading());
  } catch (err) {
    yield put(
      contentNotification(
        `Erro!!! Name: ${err.name}Message :${err.message}`,
        2000
      )
    );
    yield put(showNotification());
    yield put(setFilteredDataFinboxFailure(err.response));
  }
}

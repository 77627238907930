/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import { Theme } from '../../theme';

const Rect = styled.rect`
  x: 0;
  y: 0;
  rx: 5;
  ry: 5;
  width: ${({ value }) => (value ? `${value}%` : 0)};
  height: 6px;
  fill: ${Theme.primary};
  transition: 0.8s ease-in-out;
`;

const Rect2 = styled.rect`
  x: 0;
  y: 0;
  rx: 5;
  ry: 5;
  width: 100%;
  height: 6px;
  fill: #dfdfdf;
`;

const Svg = styled.svg`
  width: 100%;
  height: 6px;
`;

const ProgressBar = ({ value }) => {
  const [percent, setPercent] = useState(0);
  useEffect(() => {
    const timer = setTimeout(() => setPercent(value), 100);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <Svg>
      <Rect2 />
      <Rect value={percent} />
    </Svg>
  );
};

export default ProgressBar;

import styled from 'styled-components/macro';

import iconInbox from 'assets/images/icon-Inbox.svg';

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 16px;
  border: 1px solid #d9d9d9;
  padding: 1rem 1.5rem;
  width: 100%;
  margin-bottom: 2.5rem;
`;

export const Label = styled.span`
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  letter-spacing: -0.05em;
  font-weight: 400;
  letter-spacing: -0.05em;
  color: var(--fingray-900);
  font-size: 1rem;
  position: relative;
  text-indent: 3rem;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    background: url(${iconInbox}) no-repeat center center;
    width: 30px;
    height: 30px;
  }
`;

export const RegionInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
`;

export const RegionAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
`;

/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import axios from 'axios';
import { put, call, select } from 'redux-saga/effects';

import { getUserData } from 'services/getUserData';
import {
  loadListCondicionanteSuccess,
  loadListCondicionanteFailure,
  clearListCondicionante,
  concluirCondicionanteSuccess,
  concluirCondicionanteFailure,
  excluirCondicionanteSuccess,
  excluirCondicionanteFailure,
  cancelarCondicionanteSuccess,
  cancelarCondicionanteFailure,
  // loadListCedenteSuccess,
  // loadListCedenteFailure,
  // loadListCedenteClear,
  filterdListCedenteSuccess,
  filterdListCedenteFailure,
  sendCondicSuccess,
  sendCondicFailure,
  showLoading,
  hideLoading,
  showNotification,
  contentNotification,
} from 'store/old_actions/actions';
import config from '../../config';

import { /* getListCedentes, */ getCondicionante } from './selectors';

/** ***************************** List  ******************************** */
export function* listCondicionante(action) {
  try {
    const user = yield call(getUserData);

    yield put(clearListCondicionante());

    yield put(showLoading());

    const reqData = {
      params: {
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
        idStatus: action.idStatus ? action.idStatus : null,
      },
    };

    // console.log('Request: ' + JSON.stringify(reqData));

    // Request data
    const res = yield axios.post(
      `${config.API_BASE_URL}/api/Condicionante/convenio-lista`,
      null,
      reqData
    );

    // console.log(res.data);
    // console.log('Response: ' + JSON.stringify(res.data));

    yield put(loadListCondicionanteSuccess(res.data));
    yield put(hideLoading());
  } catch (err) {
    console.log(err);
    yield put(loadListCondicionanteFailure(err.response));
    yield put(contentNotification(`Mensagem: ${err.message}`, 2000));
    yield put(showNotification());
    yield put(hideLoading());
  }
}

/** ***************************** Concluir  ******************************** */
export function* concluirCondicionante(action) {
  try {
    const user = yield call(getUserData);

    yield put(clearListCondicionante());

    yield put(showLoading());

    const reqData = {
      params: {
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
        idCondicionante: action.value,
      },
    };

    // console.log('Request: ' + JSON.stringify(reqData));

    // Request data
    const res = yield axios.post(
      `${config.API_BASE_URL}/api/Condicionante/libera`,
      null,
      reqData
    );

    // console.log('Response: ' + JSON.stringify(res.data));

    yield put(concluirCondicionanteSuccess(res.data));

    yield put({ type: 'SET_LOAD_LIST_CONDIC' });

    // Load list
    const reqData2 = {
      params: {
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
        idStatus: action.idStatus ? action.idStatus : null,
      },
    };

    // console.log('Request: ' + JSON.stringify(reqData2));

    // Request data
    const res2 = yield axios.post(
      `${config.API_BASE_URL}/api/Condicionante/convenio-lista`,
      null,
      reqData2
    );

    // console.log('Response: ' + JSON.stringify(res2.data));

    yield put(loadListCondicionanteSuccess(res2.data));

    yield put(hideLoading());
  } catch (err) {
    console.log(err);
    yield put(concluirCondicionanteFailure(err.response));
    yield put(contentNotification(`Mensagem: ${err.message}`, 2000));
    yield put(showNotification());
    yield put(hideLoading());
  }
}

/** ***************************** Excluir  ******************************** */
export function* excluirCondicionante(action) {
  try {
    const user = yield call(getUserData);

    yield put(clearListCondicionante());

    yield put(showLoading());

    const reqData = {
      params: {
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
        idCondicionante: action.value,
      },
    };

    // console.log('Request: ' + JSON.stringify(reqData));

    // Request data
    const res = yield axios.post(
      `${config.API_BASE_URL}/api/Condicionante/exclui`,
      null,
      reqData
    );

    // console.log('Response: ' + JSON.stringify(res.data));

    yield put(excluirCondicionanteSuccess(res.data));

    yield put({ type: 'SET_LOAD_LIST_CONDIC' });

    // Load list
    const reqData2 = {
      params: {
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
        idStatus: action.idStatus ? action.idStatus : null,
      },
    };

    // console.log('Request: ' + JSON.stringify(reqData2));

    // Request data
    const res2 = yield axios.post(
      `${config.API_BASE_URL}/api/Condicionante/convenio-lista`,
      null,
      reqData2
    );

    // console.log('Response: ' + JSON.stringify(res2.data));

    yield put(loadListCondicionanteSuccess(res2.data));

    yield put(hideLoading());
  } catch (err) {
    console.log(err);
    yield put(excluirCondicionanteFailure(err.response));
    yield put(contentNotification(`Mensagem: ${err.message}`, 2000));
    yield put(showNotification());
    yield put(hideLoading());
  }
}

/** ***************************** Cancelar  ******************************** */
export function* cancelarCondicionante(action) {
  try {
    const user = yield call(getUserData);

    yield put(clearListCondicionante());

    yield put(showLoading());

    const reqData = {
      params: {
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
        idCondicionante: action.value,
      },
    };

    // console.log('Request: ' + JSON.stringify(reqData));

    // Request data
    const res = yield axios.post(
      `${config.API_BASE_URL}/api/condicionante/cancela`,
      null,
      reqData
    );

    // console.log('Response: ' + JSON.stringify(res.data));

    yield put(cancelarCondicionanteSuccess(res.data));

    yield put({ type: 'SET_LOAD_LIST_CONDIC' });

    // Load list
    const reqData2 = {
      params: {
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
        idStatus: action.idStatus ? action.idStatus : null,
      },
    };

    // console.log('Request: ' + JSON.stringify(reqData2));

    // Request data
    const res2 = yield axios.post(
      `${config.API_BASE_URL}/api/Condicionante/convenio-lista`,
      null,
      reqData2
    );

    // console.log('Response: ' + JSON.stringify(res2.data));

    yield put(loadListCondicionanteSuccess(res2.data));

    yield put(hideLoading());
  } catch (err) {
    console.log(err);
    yield put(cancelarCondicionanteFailure(err.response));
    yield put(contentNotification(`Mensagem: ${err.message}`, 2000));
    yield put(showNotification());
    yield put(hideLoading());
  }
}

/** ***************************** Load Cedentes  ******************************** */
/* export function* loadCedentesCondicionante(axios, action) {
  try {
    const user = yield call(getUserData);

    yield put(loadListCedenteClear());

    yield put(showLoading());

    const reqData = {
      params: {
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
      },
    };

    //console.log('Request: ' + JSON.stringify(reqData));

    //Request data
    const res = yield axios.post(
      config.API_BASE_URL + '/api/Condicionante/cedentes',
      null,
      reqData
    );

    //console.log('Response: ' + JSON.stringify(res.data));

    yield put(loadListCedenteSuccess(res.data));

    yield put(hideLoading());
  } catch (err) {
    console.log(err);
    yield put(loadListCedenteFailure(err.response));
    yield put(contentNotification('Mensagem: ' + err.message, 2000));
    yield put(showNotification());
    yield put(hideLoading());
  }
} */

/** ***************************** Search Cedentes  ******************************** */
/* export function* searchCedentes(action) {
  let dataSearch = [];
  let filtered = [];
  //console.log(action);
  try {
    dataSearch = yield select(getListCedentes);

    if (action.value !== '') {
      dataSearch.forEach((item, index) => {
        const nome =
          item.nome
            .toString()
            .toLowerCase()
            .search(action.value.toString().toLowerCase()) !== -1;

        const cnpj =
          item.cnpj
            .toString()
            .toLowerCase()
            .search(action.value.toString().toLowerCase()) !== -1;

        if (nome === true) {
          filtered[index] = item;
        }

        if (cnpj === true) {
          filtered[index] = item;
        }
      });

      //console.log(filtered);

      yield put(filterdListCedenteSuccess(filtered));
    } else {
      filtered = dataSearch;
      yield put(filterdListCedenteSuccess(filtered));
    }
  } catch (err) {
    yield put(
      contentNotification(
        'Erro!!! Name: ' + err.name + 'Message :' + err.message,
        2000
      )
    );
    yield put(showNotification());
    yield put(filterdListCedenteFailure(err.response));
  }
} */

/** ***************************** Search Cedentes  ******************************** */
export function* searchCedentes(action) {
  try {
    if (action.value.trim() !== '') {
      console.log(action.value);
      const user = yield call(getUserData);

      yield put(clearListCondicionante());

      // yield put(showLoading());

      const reqData = {
        params: {
          idConvenio: user.idEmpresa,
          idPessoaConvenio: user.idUsuario,
          pesquisa: action.value.trim().toLowerCase(),
        },
      };

      // Request data
      const res = yield axios.post(
        `${config.API_BASE_URL}/api/Condicionante/cedentes`,
        null,
        reqData
      );

      yield put(filterdListCedenteSuccess(res.data));
      yield put(hideLoading());
    }
  } catch (err) {
    console.log(err);
    yield put(filterdListCedenteFailure(err.response));
    yield put(contentNotification(`Mensagem: ${err.message}`, 2000));
    yield put(showNotification());
    // yield put(hideLoading());
  }
}

/** ***************************** Send Condicionante  ******************************** */
export function* sendCondicionante(action) {
  let reqData = null;
  let field = null;
  const VALIDATION = {
    idCedente: { message: 'Necessário incluir um cedente!!' },
    texto: { message: 'Necessário incluir uma descricão!!' },
    dtValidade: { message: 'Necessário incluir uma data de validade!!' },
  };
  try {
    const user = yield call(getUserData);

    const dataCondicionante = yield select(getCondicionante);

    // console.log(dataCondicionante);

    yield put(showLoading());

    const tested = Object.keys(dataCondicionante).some((res, index) => {
      field = res;
      return (
        (dataCondicionante[res] === '' || dataCondicionante[res] === 0) &&
        res !== 'valor'
      );
    });

    // console.log(field);

    if (!tested) {
      reqData = {
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
        ...dataCondicionante,
      };
    } else {
      throw new Error(VALIDATION[field].message);
    }

    // console.log('Request: ' + JSON.stringify(reqData));

    // Request data
    const res = yield axios.post(
      `${config.API_BASE_URL}/api/Condicionante/cria`,
      reqData
    );

    // console.log('Response: ' + JSON.stringify(res.data));

    yield put(sendCondicSuccess(res.data));

    yield put({ type: 'SET_LOAD_LIST_CONDIC' });

    // Load list
    const reqData2 = {
      params: {
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
        idStatus: action.idStatus ? action.idStatus : null,
      },
    };

    // console.log('Request: ' + JSON.stringify(reqData2));

    // Request data
    const res2 = yield axios.post(
      `${config.API_BASE_URL}/api/Condicionante/convenio-lista`,
      null,
      reqData2
    );

    // console.log('Response: ' + JSON.stringify(res2.data));

    yield put(loadListCondicionanteSuccess(res2.data));

    yield put(hideLoading());
  } catch (err) {
    console.log(err);
    yield put(sendCondicFailure(err.response));
    yield put(contentNotification(`Mensagem: ${err.message}`, 2000));
    yield put(showNotification());
    yield put(hideLoading());
  }
}

import React, { forwardRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { Label, Flex, Info } from './style.concentracao';
import { InputConcentracaoMaxima } from '../Input';

export const ConcentracaoMaxima = forwardRef((props, ref) => {
  const { disabled } = props;
  return (
    <Flex column style={{ marginBottom: '10px' }}>
      <ReactTooltip
        id="info-concentracao-maxima"
        wrapper="span"
        place="right"
        type="dark"
        effect="solid"
      >
        <div style={{ width: '200px', wordBreak: 'break-word' }}>
          O percentual máximo de operações que essa empresa pode representar em
          sua carteira total de ativos.
        </div>
      </ReactTooltip>
      <Flex
        justify="flex-start"
        style={{ marginBottom: '8px', width: 'calc(100% - 6px)' }}
      >
        <Label>Concentração Máxima</Label>
        <Info
          aria-label="Limite"
          data-tip
          data-for="info-concentracao-maxima"
        />
      </Flex>
      <InputConcentracaoMaxima disabled={disabled} ref={ref} {...props} />
    </Flex>
  );
});

import React, { useEffect, useCallback, useContext } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { LoadingInfo } from 'components/FinLoading';
import { Creators as searchRouterActions } from 'store/ducks/searchRouter';
import { Creators as apiListClientesActions } from 'store/ducks/apiListClientes';

import { Wrapper } from 'components/FinWrappers';
import Pagination from 'components/FinPagination';
import { useFeedback } from 'hooks/useFeedback';
import FeedbackInfo from 'components/FinFeedbackInfo';
import { AuthContext } from 'providers/ProviderAuth';
import List from './List';
import ItemList from './ItemList';

const ListaFinanciadores = ({
  match,
  searchRouterAction,
  listaClientesState,
  listClientesAction,
  query,
}) => {
  const { ready, waiting, error, empty } = useFeedback(listaClientesState);

  const listClientes = useCallback(
    (idCedente, reloadBkg) => {
      listClientesAction.apiListClientesRequest(idCedente, reloadBkg);
    },
    [listClientesAction]
  );

  const user = useContext(AuthContext);

  useEffect(() => {
    const querys = [];
    Object.keys(query).forEach((res) => {
      if (['page', 'qtd', 'order', 'aba'].includes(res)) {
        querys.push(res);
      }
    });
    if (querys.length === 4) {
      listClientes();
    }
  }, [query, listClientes]);

  useEffect(() => {
    return () => listClientesAction.apiListClientesCancel();
  }, [listClientesAction]);

  return (
    <Wrapper padding="0" tipo="column" pwidth="100%">
      {waiting && <LoadingInfo loading={waiting} />}
      {error && <FeedbackInfo tipo="error" />}
      {empty && <FeedbackInfo tipo="empty" text="Conteúdo não disponível" />}
      {ready && (
        <List>
          {listaClientesState.data
            .filter((f) => !f.localChangedIsHide)
            .map((res) => (
              <ItemList
                data={res}
                key={res.id}
                match={match}
                idEmpresa={user.idEmpresa}
              />
            ))}
        </List>
      )}
      <Pagination searchRouter={searchRouterAction} />
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  apiFiltroState: state.apiFiltro,
  query: state.router.location.query,
  state: state.router.location.state,
  location: state.router.location,
  listaClientesState: state.apiListClientes,
});

const mapDispatchToProps = (dispatch) => {
  return {
    searchRouterAction: bindActionCreators(searchRouterActions, dispatch),
    listClientesAction: bindActionCreators(apiListClientesActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaFinanciadores);

import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

// import { AxisTickProps } from '@nivo/axes';
// import { CircleLetters } from 'pages/Cbbox/Styles';

const tema = {
  axis: {
    domain: {
      line: {
        stroke: '#dfdfdf',
        strokeWidth: 1,
      },
    },
    legend: {
      text: {
        fontSize: 9,
      },
    },
  },
  ticks: {
    line: {
      stroke: '#fff',
      strokeWidth: 0,
    },

    text: {
      fontSize: 0,
      fill: '#333333',
    },
  },
};

const GraficoBarraMatch = (data) => {
  return (
    <ResponsiveBar
      data={data.data}
      keys={['valor']}
      theme={tema}
      indexBy="name"
      margin={{ top: 10, right: 20, bottom: 50, left: 75 }}
      padding={0.2}
      groupMode="grouped"
      layout="horizontal"
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={({ data }) => String(data.cores)}
      // markers={[
      //   {
      //     axis: 'y',
      //     position: 'right',
      //     legendOrientation: 'horizontal',
      //     lineStyle: { stroke: '#dfdfdf', strokeWidth: 1 },
      //   },
      //   {
      //     axis: 'x',
      //     position: 'right',
      //     legendOrientation: 'horizontal',
      //     lineStyle: { stroke: '#dfdfdf', strokeWidth: 1 },
      //   },
      // ]}
      axisTop={{
        tickSize: 0,
        tickPadding: 13,
        tickRotation: 0,
        legend: '',
        legendOffset: 60,
      }}
      axisRight={{
        tickSize: 0,
        tickPadding: 23,
        tickRotation: 0,
        legend: '',
        legendOffset: 60,
      }}
      axisBottom={{
        tickSize: 0,
      }}
      enableGridX={true}
      enableGridY={null}
      cornerRadius={14}
      labelSkipWidth={0}
      borderRadius={2}
      labelSkipHeight={0}
      legends={[
        {
          dataFrom: 'indexes',
          anchor: 'bottom-left',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: 45,
          symbolShape: 'circle',
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 15,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      ariaLabel="Aderencia"
      // eslint-disable-next-line func-names
      barAriaLabel={function (e) {
        return `${e.id}: ${e.formattedValue} in name: ${e.indexValue}`;
      }}
    />
  );
};

export default GraficoBarraMatch;

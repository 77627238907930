import React from 'react';

import * as Tag from './style.tagmember';

export const FinPremium = ({ style, className }) => {
  return (
    <Tag.Wrapper
      style={style}
      className={className}
      bgColor="linear-gradient(252.36deg, #FCD07D 17.79%, #F2A71B 60.42%, #B67A0B 93.7%);"
    >
      <Tag.IconCircleWaveStyle style={{ marginRight: '5px' }} />
      <Tag.Label color="#503204">Premium</Tag.Label>
    </Tag.Wrapper>
  );
};

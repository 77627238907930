import styled from 'styled-components/macro';
import { ellipsis } from 'polished';

import { device } from '../../devices';

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const capitalizeWords = (value) => {
  return value
    ? value
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
        .join(' ')
    : '';
};

export function replaceToAsteristic(value) {
  if (typeof value === 'string') {
    return value.replace(/[a-zA-Z0-9]/g, '*');
  }
  if (typeof value === 'number') {
    return Number(value.replace(/[a-zA-Z0-9]/g, '*'));
  }
  return null;
}

export const BlurryText = styled.span`
  text-shadow: 0 0 9px black;
  color: ${({ color }) => color || 'transparent'};
  padding: 0.5rem;
`;

export const Ellipsis = styled.span`
  ${({ pwidth }) => ellipsis(pwidth)}

  @media ${device.laptop} {
    ${({ pwidth }) => ellipsis(pwidth)}
  }

  @media ${device.desktop} {
    ${({ pwidth }) => ellipsis(Number(pwidth.replace('px', '')) * 4)}
  }
`;

export const stringToBoolean = (val) => {
  const a = {
    true: true,
    false: false,
  };
  return a[val];
};

export const toLowerCaseTrim = (val) => {
  return val ? val.trim().toLowerCase() : '';
};

export function truncate(str, length) {
  if (str && length) {
    if (typeof str === 'string' && typeof length === 'number') {
      const words = str.match(new RegExp(`.{1,${length}}`, 'g'));
      if (words.length > 1) {
        return `${words[0]}...`;
      }
      return words[0];
    }
    return null;
  }
  return null;
}

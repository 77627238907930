import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { StringToSvg } from 'helpers/svgFiles/StringToSvg';
import { finTheme } from 'finTheme';

import { Title4 } from 'components/FinHeadings';
import { Wrapper } from 'components/FinWrappers';

const WrapperCuston = styled(Wrapper)`
  margin: 0 1rem;
  width: 34px;
  height: 34px;
  padding: 0;
`;

const HeaderTitle = ({ icon, iconColor, viewBox, strokeWidth, children }) => {
  return (
    <Wrapper padding="0">
      <WrapperCuston>
        <StringToSvg
          viewBox={viewBox}
          width="100%"
          height="100%"
          strokeWidth={strokeWidth}
          stroke={iconColor}
        >
          {icon}
        </StringToSvg>
      </WrapperCuston>
      <Title4>{children}</Title4>
    </Wrapper>
  );
};

HeaderTitle.propTypes = {
  children: PropTypes.any.isRequired,
  icon: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
  viewBox: PropTypes.string,
  strokeWidth: PropTypes.string,
};

HeaderTitle.defaultProps = {
  iconColor: finTheme.newtheme.color.cinza6,
  viewBox: '0 0 66 66',
  strokeWidth: '1',
};

export default HeaderTitle;

import React from 'react';

import { Tabs, ItemTab, Item } from 'components/FinTabs';
import { StringToSvg } from 'helpers/svgFiles/StringToSvg';
import { finTheme } from 'finTheme';
import { icons } from 'assets/icones/finplaceIcons';
import feather from 'feather-icons/dist/icons.json';

import { Wrapper } from 'components/FinWrappers';

const NavLinksConvites = ({ isSelected, changeTab }) => {
  return (
    <Tabs ppadding="0 50px">
      <ItemTab>
        <Item
          onClick={() => changeTab('recebidos')}
          isActive={isSelected === 'recebidos'}
        >
          <Wrapper
            pwidth="21px"
            pheight="21px"
            padding="0"
            pmargin="0 0.6rem 0 0"
          >
            <StringToSvg
              viewBox="0 -2 24 24"
              width="100%"
              height="100%"
              strokeWidth={'2'}
              stroke={finTheme.newtheme.color.cinza4}
            >
              {feather.mail}
            </StringToSvg>
          </Wrapper>
          Recebidos
        </Item>
      </ItemTab>
      <ItemTab>
        <Item
          onClick={() => changeTab('enviados')}
          isActive={isSelected === 'enviados'}
        >
          <Wrapper
            pwidth="23px"
            pheight="21px"
            padding="0"
            pmargin="0 0.6rem 0 0"
          >
            <StringToSvg
              viewBox="0 -1 24 24"
              width="100%"
              height="100%"
              strokeWidth={'2'}
              stroke={finTheme.newtheme.color.cinza4}
            >
              {icons.sendOutline}
            </StringToSvg>
          </Wrapper>
          Enviados
        </Item>
      </ItemTab>
    </Tabs>
  );
};

export default NavLinksConvites;

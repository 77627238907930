import React from 'react';

// import { Indicadores } from './Indicadores';
import { Metricas } from './Metricas';

import { /* TitleIndicadores, */ TitleMetricas } from './Comps/TitleHead';

export function PageFinscanner() {
  return (
    <div style={{ margin: '3rem 0px' }}>
      {/* <TitleIndicadores />
      <Indicadores /> */}
      <TitleMetricas />
      <div>
        <Metricas />
      </div>
    </div>
  );
}

import React from 'react';

import FeedbackInfo from 'components/FinFeedbackInfo';
import { FinErrorBoundary } from 'components/FinErrorBoundary';
import { Card } from '../Ofertas/Card';
import { FiltersBar } from './FiltersBar';
import { Footer } from './Footer';
import { ContentStyle, ViewList } from './style';
import { useFetchOperacoes } from '../queries/useFetchOperacoes';
import { Loader } from './Loader';

export const Content = () => {
  const {
    data: dados,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useFetchOperacoes();

  const check = () => (dados.data.length > 0 ? dados.data : []);
  const listData = !!dados && dados.data ? check() : [];

  return (
    <ContentStyle>
      <FiltersBar isFetching={isFetching} refetch={refetch} />
      <ViewList>
        <FinErrorBoundary>
          {isLoading && <Loader />}
          {error && <FeedbackInfo tipo="error" />}
          {!!dados && listData.length === 0 && (
            <FeedbackInfo tipo="empty" text="Conteúdo não disponível" />
          )}
          {!!dados &&
            listData.map((item) => <Card key={item.idOperacao} data={item} />)}

          <Footer totalPages={!!dados && dados.totalPages} />
        </FinErrorBoundary>
      </ViewList>
    </ContentStyle>
  );
};

import React, { useContext } from 'react';

import { Table, Thead, Th, Tbody, Tr } from 'components/FinTable';
import { Caption } from 'components/FinParagraph';
import { OperacoesContext } from '../../provider';
import { Parcela } from './Parcela';

export const Parcelas = ({
  data,
  deleteParcela,
  onChangeVencimento,
  onChangeValor,
}) => {
  const [state] = useContext(OperacoesContext);

  const {
    modal: { values },
  } = state;

  return (
    <Table>
      <Thead>
        <Tr>
          <Th padding="4px 4px">
            <Caption style={{ fontWeight: 'bold' }}>PARCELA</Caption>
          </Th>
          <Th padding="4px 4px">
            <Caption style={{ fontWeight: 'bold' }}>VALOR</Caption>
          </Th>
          <Th padding="4px 4px">
            <Caption style={{ fontWeight: 'bold' }}>VENCIMENTO</Caption>
          </Th>
          <Th padding="4px 4px">
            {values.rules.indexOf('edit') !== -1 && (
              <Caption style={{ fontWeight: 'bold' }}>Remover</Caption>
            )}
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((item, index) => (
          <Parcela
            data={item}
            key={item.idNumero}
            index={index}
            deleteParcela={deleteParcela}
            onChangeVencimento={onChangeVencimento}
            onChangeValor={onChangeValor}
          />
        ))}
      </Tbody>
    </Table>
  );
};

import styled from 'styled-components/macro';

export const Paragraph1 = styled.p`
  color: ${({ theme, color }) => color || theme.newtheme.color.cinza6};
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  letter-spacing: ${({ theme }) => theme.newtheme.font.corpo1.espacamento};
  font-weight: ${({ theme }) => theme.newtheme.font.corpo1.peso};
  font-size: ${({ theme }) => theme.newtheme.font.corpo1.tamanho};
  line-height: 30px;
  margin: ${({ pmargin }) => pmargin || 0};
`;

export const Paragraph2 = styled.p`
  color: ${({ theme, color }) => color || theme.newtheme.color.cinza6};
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  letter-spacing: ${({ theme }) => theme.newtheme.font.corpo2.espacamento};
  font-weight: ${({ theme }) => theme.newtheme.font.corpo2.peso};
  font-size: ${({ theme }) => theme.newtheme.font.corpo2.tamanho};
  line-height: 25px;
  margin: ${({ pmargin }) => pmargin || 0};
`;

export const Caption = styled.span`
  color: ${({ theme, color }) => color || theme.newtheme.color.cinza6};
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  letter-spacing: ${({ theme }) => theme.newtheme.font.caption.espacamento};
  font-weight: ${({ fontWeigth, theme }) =>
    fontWeigth || theme.newtheme.font.caption.peso};
  font-size: ${({ theme }) => theme.newtheme.font.caption.tamanho};
  line-height: 24px;
`;

export const Overline = styled.span`
  color: ${({ theme, color }) => color || theme.newtheme.color.cinza6};
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  letter-spacing: ${({ theme }) => theme.newtheme.font.overline.espacamento};
  font-weight: ${({ theme }) => theme.newtheme.font.overline.peso};
  font-size: ${({ theme }) => theme.newtheme.font.overline.tamanho};
  line-height: 22px;
`;

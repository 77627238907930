import React from 'react';

import { Navigation, Content, styles } from './Elements';

export const PaginaOperacoes = () => {
  return (
    <styles.Container>
      <Navigation />
      <Content />
    </styles.Container>
  );
};

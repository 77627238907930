import styled, { css } from 'styled-components/macro';

import { ReactComponent as StampIconGreen } from 'assets/images/icon-selo.svg';
import { ReactComponent as StampIconGrey } from 'assets/images/icon-stamp-grey.svg';

const truncate = css`
  width: ${({ limite }) => limite || `15ch`};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Flex = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify || 'space-between'};
  flex-direction: ${({ direction }) => direction || 'row'};
  border-bottom: 1px solid var(--fingray-200);
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
  border-bottom: 1px solid var(--fingray-200);
`;

export const AddInfo = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 20px;
`;

export const Icon = styled.div``;

export const Name = styled.span`
  font-family: 'Inter';
  color: var(--fingray-900);
  font-weight: 600;
  font-size: 1.3rem;
  ${truncate}
`;

export const SubText = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const Cnpj = styled.span`
  font-family: 'Inter';
  color: var(--fingray-900);
  font-weight: 400;
  font-size: 1rem;
  color: var(--fingray-500);
`;

export const Segmento = styled.span`
  font-family: 'Inter';
  color: var(--fingray-900);
  position: absolete;
  font-weight: 400;
  font-size: 0.875rem;
  color: var(--fingray-500);
  list-style-type: disc;

  & > a {
    color: color: var(--fingray-500);
  }
`;

export const Separator = styled.span`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 0 14px;
  background: var(--fingray-500);
`;

export const Operameter = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 3rem 2.813rem 2rem;
`;

export const OperameterValue = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 0 12px;
  padding: 5px 25px;
  border-radius: 12px;
  min-width: 260px;

  ${({ isActive }) =>
    isActive &&
    css`
      & > span {
        font-weight: 700;
      }
      background: var(--finblue-secondary);
    `}
`;

export const Marker = styled.span`
  font-family: 'Inter';
  color: var(--fingray-900);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  font-weight: 400;
  font-size: 1rem;
  &::before {
    content: '';
    display: inline-flex;
    margin-right: 1rem;
    width: 11px;
    height: 11px;
    background: ${({ backgroundMarker }) => backgroundMarker || '#3B8C6E'};
    border-radius: 3px;
  }
`;

export const Value = styled.span`
  font-family: 'Inter';
  color: var(--fingray-900);
  font-weight: 700;
  font-size: 1.3rem;
  letter-spacing: -1px;
`;

export const Label = styled.span`
  font-family: 'Inter';
  color: var(--fingray-900);
  font-weight: 400;
  font-size: 1rem;
`;

export const ContentIdicator = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
`;

export const WrapperMarketData = styled.div`
  width: 100%;
  padding-top: 1rem;
`;

export const ContainerMarketData = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: ${({ justify }) => justify || 'flex-start'};
`;

export const ContentMarketData = styled.div`
  display: flex;
  width: calc(50% - 1rem);
  height: 52px;
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  margin-bottom: 0.5rem;
`;

export const IconMarket = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 50px;
  background: ${({ backgroundwarning }) =>
    backgroundwarning ? 'var(--finorange-100)' : 'var(--fingreen-100)'};
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
`;

export const InfoMarket = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
`;

export const NameMarket = styled.span`
  font-family: 'Inter';
  color: var(--fingray-900);
  font-weight: 500;
  font-size: 1rem;
  line-height: 15px;
`;

export const SubNameMarket = styled.span`
  font-family: 'Inter';
  color: var(--fingray-900);
  font-weight: 500;
  font-size: 0.75rem;
  color: var(--fingray-400);
`;

export const IconStampGreen = styled(StampIconGreen)`
  width: 50px;
`;

export const IconStampGrey = styled(StampIconGrey)`
  width: 50px;
`;

export const Message = styled.span`
  font-family: 'Inter';
  color: var(--fingray-900);
  font-weight: 400;
  font-size: 1rem;
`;

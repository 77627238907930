import axios from 'axios';
import { useQuery } from 'react-query';
import CONFIG from 'config';
import { getUserData } from 'services/getUserData';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/`;

const fetchFinMatch = async (params) => {
  const { idEmpresa } = await getUserData();
  const fetchData = axios.post(
    `${BASE_URL}api/finmatch/obter-consulta-finmatch?idconvenio=${idEmpresa}&idGrupoCedente=${params.idGrupo}`
  );

  const result = await fetchData;
  const { data } = result;

  return data.data ? data.data : [];
};

export const useFinMatch = (params) => {
  return useQuery(['finmatch', params], () => fetchFinMatch(params), {
    keepPreviousData: false,
  });
};

import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import { Wrapper } from 'components/FinWrappers';
import { Box, Bullet } from 'components/FinFrame';
import UserInfo from 'components/FinUserInfo';
import { rgba } from 'polished';
import { finTheme } from 'finTheme';
import BlurryText from 'components/FinBlurryText';
import { Ellipsis } from 'helpers/string';
import { maskCNPJ } from 'helpers/format';

import { SubTitle1 } from 'components/FinSubTitles';
import { TagPerfil, TagDocumento } from 'components/FinTag';

export const ItemLink = styled(NavLink)`
  padding: 0;
  white-space: nowrap;
  background-color: initial;
  border: 0;
  transition: border-bottom-color 0.36s ease-in;
  text-decoration: none;
  width: 100%;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
      cursor: auto;
    `}
`;

const BoxWrapper = styled(Box)`
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0 0 1rem 0;
  overflow-y: initial;
  overflow: initial;
  cursor: pointer;

  border: 3px solid rgba(184, 184, 184, 0.2);
  box-shadow: none;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      box-shadow: none;
      border-radius: 0;
      cursor: auto;
      &:hover {
        box-shadow: none;
      }

      & ${ItemLink} {
        cursor: auto;
      }
    `}
`;

const ItemListSearch = ({ data, onClose }) => {
  const location = useLocation();
  // const uiDialog = useActions(uiDialogActions);

  return (
    <BoxWrapper>
      <ItemLink
        exact
        onClick={onClose}
        to={{
          pathname: `/clientes/${data.id_Grupo}/detalhe`,
          state: { background: location },
        }}
      >
        <Wrapper
          pwidth="100%"
          justifyContent="space-between"
          padding="1rem 1rem 1rem 1.5rem"
        >
          <Wrapper padding="0">
            <UserInfo
              name={data.nome}
              image={null}
              pbgColor={rgba(finTheme.newtheme.color.cinza2, 0.5)}
              colorText={finTheme.newtheme.color.azulPrimario}
              size="medium"
              viewUserOnline
              online={data.online ? 1 : 0}
              infoDisabled
            />
            <Wrapper
              tipo="column"
              padding="0"
              alignItems="flex-start"
              pmargin="0 2rem"
            >
              <Wrapper padding="0" alignItems="flex-start">
                <ReactTooltip
                  id={`info-name-financiador-${data.id}`}
                  wrapper="span"
                  place="right"
                  type="dark"
                  effect="solid"
                >
                  {data.nome}
                </ReactTooltip>

                <SubTitle1
                  data-tip
                  data-for={`info-name-financiador-${data.id}`}
                  pweight="600"
                >
                  <Ellipsis pwidth="200px">
                    <BlurryText blurry={false}>{data.nome}</BlurryText>
                  </Ellipsis>
                </SubTitle1>
                <SubTitle1
                  style={{
                    color: finTheme.newtheme.color.cinza4,
                    marginLeft: '1rem',
                  }}
                >
                  <BlurryText blurry={data.criptografia}>
                    {data.criptografia ? data.cnpj : maskCNPJ(data.cnpj)}
                  </BlurryText>
                </SubTitle1>
              </Wrapper>
              <Wrapper padding="0">
                <TagDocumento active={data.documento} id={data.id} />
                <Bullet />
                <TagPerfil active={data.perfil} id={data.id} />
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </ItemLink>
    </BoxWrapper>
  );
};

ItemListSearch.propTypes = {
  onClose: PropTypes.func,
  tipo: PropTypes.string,
};

export default ItemListSearch;

/* eslint-disable no-console */
import immer from 'immer';
import React, { createContext, useReducer } from 'react';

const initialState = {
  size: 10,
  skip: 1,
  filter: {
    buscar: '',
    prioridade: null,
    ordemAgenda: {
      campo: 1,
      ordem: 2,
    },
    mesAnoVigencia: {
      ano: null,
      mes: null,
    },
  },
};

const actions = {
  SIZE: 'SIZE',
  SKIP: 'SKIP',
  SEARCH: 'SEARCH',
  PRIORITY: 'PRIORITY',
  ORDER: 'ORDER',
  PERIOD_YEAR: 'PERIOD_YEAR',
  PERIOD_MONTH: 'PERIOD_MONTH',
};

const reducerActions = {
  SIZE: (state, payload) => {
    state.size = payload.value;
  },
  SKIP: (state, payload) => {
    state.skip = payload.value;
  },
  SEARCH: (state, payload) => {
    state.filter.buscar = payload.value;
    state.skip = 1;
  },
  PRIORITY: (state, payload) => {
    state.filter.prioridade = payload.value;
    state.skip = 1;
  },
  ORDER: (state, payload) => {
    state.filter.ordemAgenda = payload.value;
  },
  PERIOD_YEAR: (state, payload) => {
    state.filter.mesAnoVigencia.mes = 0;

    state.filter.mesAnoVigencia.ano = Number(payload.value);
    state.skip = 1;
  },
  PERIOD_MONTH: (state, payload) => {
    state.filter.mesAnoVigencia.mes = payload.value;
    state.skip = 1;
  },
};

const reducer = (state, action) => {
  const fn = reducerActions[action.type];
  if (fn) {
    return immer(state, (draftState) => {
      fn(draftState, action.payload);
    });
  }
  console.log('[WARNING] Action without reducer:', action);
  return state;
};

export const AgendaContext = createContext();

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const acoes = {
    setSize: (size) => {
      dispatch({ type: actions.SIZE, payload: { value: size } });
    },
    setSkip: (skip) => {
      dispatch({ type: actions.SKIP, payload: { value: skip } });
    },
    setSearch: (search) => {
      dispatch({ type: actions.SEARCH, payload: { value: search } });
    },
    setPriority: (priority) => {
      dispatch({ type: actions.PRIORITY, payload: { value: priority } });
    },
    setOrder: (order) => {
      dispatch({ type: actions.ORDER, payload: { value: order } });
    },
    setPeriodYear: (year) => {
      dispatch({ type: actions.PERIOD_YEAR, payload: { value: year } });
    },
    setPeriodMonth: (month) => {
      dispatch({ type: actions.PERIOD_MONTH, payload: { value: month } });
    },
  };

  return (
    <AgendaContext.Provider value={[state, acoes]}>
      {children}
    </AgendaContext.Provider>
  );
};

import styled, { css } from 'styled-components/macro';

export const ContainerPaginacao = styled.div`
  white-space: nowrap;
  width: 100%;
  display: flex;
  justify-content: ${({ place }) => place || 'flex-end'};

  ${({ estilo }) => {
    if (estilo === 'rounded') {
      return css`
        & > ul > li > a {
          border-radius: 50px;
        }
      `;
    }
    return null;
  }}

  @media (max-width: 800px) {
    overflow: auto;
    justify-content: flex-start;
    margin-top: 1rem;
  }
`;

export const Pagination = styled.ul`
  margin: 0 50px;
  display: flex;
  text-align: center;
  list-style: none;
  padding: 0;

  @media (max-width: 420px) {
    margin: 0 7px;
  }
`;

export const PaginationButton = styled.li`
  color: ${({ theme }) => theme.newtheme.backgroundColor.azulPrimario};
  margin-left: 4px;
  ${({ visibility }) =>
    visibility &&
    css`
      visibility: ${visibility};
    `}
`;

export const ArrowTitle = styled.span`
  margin-right: 4px;
`;

export const Arrow = styled.span`
  width: 7px;
  height: 7px;
  display: inline-block;
  border-style: solid;
  border-width: 1px 1px 0 0;
  border-color: #212529;
  transform: rotate(45deg);
`;

export const PaginationLink = styled.a`
  text-decoration: none;
  outline: none;
  width: 36px;
  height: 36px;
  position: relative;
  background-color: transparent;
  font-size: 12px;
  font-weight: 700;
  display: block;
  line-height: 1;
  cursor: pointer;
  border-radius: 2px;
  transition: background-color 250ms ease;
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #212529;
  ${({ current }) =>
    current === 'active' &&
    css`
      pointer-events: none;
      background-color: ${({ theme }) =>
        theme.newtheme.backgroundColor.azulPrimario};
      font-weight: 600;
      color: #ffffff;
    `}
  &:hover {
    background-color: ${({ theme }) =>
      theme.newtheme.backgroundColor.azulPrimario};
    border: 2px solid transparent;
    color: #ffffff;
    text-decoration: none;
  }
  &:hover ${Arrow} {
    border-color: #ffffff;
  }
`;

export const PaginationLinkNext = styled(PaginationLink)`
  width: 100px;
`;

export const PaginationLinkBack = styled(PaginationLink)`
  width: 100px;
  & ${Arrow} {
    transform: rotate(225deg);
  }
  & ${ArrowTitle} {
    margin-left: 4px;
    margin-right: 0px;
  }
`;

/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { createActions, createReducer } from 'reduxsauce';
import { call, put, take, fork, cancel, cancelled } from 'redux-saga/effects';
import axios, { CancelToken } from 'axios';

import { getUserData } from 'services/getUserData';
import { Creators as uiToasterActions } from 'store/ducks/uiToaster';

import CONFIG from '../../config';

/**
 * Criando action types & creators
 */

export const { Types, Creators } = createActions({
  apiCreateMeetingRequest: ['idCedente'],
  apiCreateMeetingSuccess: ['data'],
  apiCreateMeetingError: [],
  apiCreateMeetingCancel: [],
});

/**
 * Criando os reducer handlers
 */

const INITIAL_STATE = {
  isLoading: false,
  data: [[{ none: 'none' }]],
  error: false,
};

const apiCreateRequest = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: true,
    data: [],
    error: false,
  };
};

const apiCreateSuccess = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: action.data,
    error: false,
  };
};

const apiCreateError = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: [],
    error: true,
  };
};

const apiCreateCancel = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: [],
    error: false,
  };
};

/**
 * Criando reducer
 */

export default createReducer(INITIAL_STATE, {
  [Types.API_CREATE_MEETING_REQUEST]: apiCreateRequest,
  [Types.API_CREATE_MEETING_SUCCESS]: apiCreateSuccess,
  [Types.API_CREATE_MEETING_ERROR]: apiCreateError,
  [Types.API_CREATE_MEETING_CANCEL]: apiCreateCancel,
});

/**
 * Sagas
 */

function* sagaGetAsynResult(params, endpoint) {
  const source = CancelToken.source();
  const url = `${CONFIG.API_REMODELING_URL}${endpoint}`;
  try {
    const response = yield axios.post(url, params, {
      cancelToken: source.token,
    });

    yield put(Creators.apiCreateMeetingSuccess(response.data.data));

    if (response.data.data) {
      window.open(response.data.data, 'Chat Finplace', 'height=600,width=700');
    }

    yield put(
      uiToasterActions.uiToasterMessage(
        { message: 'Sala criada com sucesso!!' },
        'success'
      )
    );
  } catch (error) {
    console.log(error);
    yield put(uiToasterActions.uiToasterMessage(error, 'error'));
    yield put(Creators.apiCreateMeetingError());
  } finally {
    if (yield cancelled()) {
      console.log('cancel');
      source.cancel();
    }
  }
}

export function* sagaCreateMeeting(action) {
  const user = yield call(getUserData);

  if (user) {
    // const params = {
    //   idConvenio: user.idEmpresa,
    //   idPessoaConvenio: user.idUsuario,
    //   idCedente: action.idCedente,
    // };

    const params = {
      idConvenio: user.idEmpresa,
      idGrupo: action.idCedente,
    };

    const task = yield fork(
      sagaGetAsynResult,
      params,
      '/user-api/api/meeting/convidar/grupo'
    );

    yield take(Types.API_CREATE_MEETING_CANCEL);
    yield cancel(task);
  } else {
    yield put(Creators.apiCreateMeetingError());
  }
}

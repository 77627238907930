export const getPregoes = (state) => state.pregoes.data;

export const getBordero = (state) => state.borderos.data;

export const getNewProposta = (state) => state.propostas.newProposal;

export const getOperacaoReasonCancel = (state) => state.operacoes.reasonCancel;

export const getFinalizados = (state) => state.operacoes.dataFinal;

export const getDateMainSearch = (state) => state.operacoes.dataGeralBusca;

export const getFinboxData = (state) => state.finbox.data;

export const getPropostaView = (state) => state.propostas.viewActive;

export const getPropostaNewSemNota = (state) =>
  state.propostas.newProposalSemNota;

export const getPropostaSimulado = (state) => state.propostas.dataCalculado;

export const getListCedentes = (state) => state.condicionante.cedenteDataCondic;

export const getCondicionante = (state) => state.condicionante.dataCreate;

export const getListDocs = (state) => state.listDownload.data;

export const getFilters = (state) => state.filters.data;

export const getFiltersList = (state) => state.filters.filters;

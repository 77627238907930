import styled from 'styled-components/macro';
import { rgba } from 'polished';
import { motion } from 'framer-motion';
import { device } from 'devices';

import { Wrapper } from 'components/FinWrappers';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  width: 100%;
  height: 184px;
  border-bottom: 1px solid ${({ theme }) => theme.newtheme.color.cinza2};
  flex: 1;
`;

export const WrapperLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex: 6;
  overflow: hidden;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 3;
  height: 100%;
  overflow-y: auto;
  min-width: 900px;
  @media ${device.desktop} {
    flex: 4;
  }
`;

export const TopBar = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  background-color: ${({ theme }) => theme.newtheme.color.branco};
  border-bottom: 1px solid ${({ theme }) => theme.newtheme.color.cinza2};
  display: flex;
  justify-content: space-between;
  z-index: ${({ theme }) => theme.newtheme.zIndex.topBarContent};
  box-shadow: ${({ theme }) => theme.newtheme.dropShadow.dropShadow2};
`;

export const ContainerFilter = styled.div`
  flex: ${({ display }) => (!display ? 'none' : 1)};
  height: 100%;
  display: ${({ display }) => (!display ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.newtheme.color.cinza2};
  background-color: ${rgba('#ffffff', 1)};
  overflow: hidden;
  transition: flex 0.2s ease;
  min-width: 300px;
  position: relative;
`;

export const FilterFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid ${({ theme }) => theme.newtheme.color.cinza2};
  width: 100%;
  height: 80px;
  background-color: ${rgba('#ffffff', 0.5)};
`;

export const ContentFilter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  width: 100%;
  height: calc(100% - 80px);
  overflow-y: auto;
`;

export const BtnOpenMenu = styled(Wrapper)`
  cursor: pointer;
  padding: 0;
`;

export const TagCountSelected = styled.div`
  color: ${({ theme }) => theme.newtheme.color.branco};
  background-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
  border-radius: 2rem;
  width: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 33px;
  left: 78px;
  font-size: 0.8rem;
  font-weight: 600;
`;

export const ContentList = styled(motion.div)`
  width: 100%;
  background-color: ${({ theme }) => theme.newtheme.color.branco};
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: absolute;
  top: 94px;
  left: 0;
  z-index: 9;
  overflow-y: auto;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.31);
  opacity: 0;
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  height: 300px;
`;

export const TextList = styled.label.attrs(({ para }) => ({
  htmlFor: para,
}))`
  font-size: 12px;
  color: ${({ theme }) => theme.newtheme.color.cinza6};
  max-width: ${(props) => (props.lwidth ? props.lwidth : '137px')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`;

export const Feedback = styled.div`
  color: ${({ theme }) => theme.newtheme.color.cinza6};
  padding: 2rem;
`;

export const WrapperSelected = styled.div`
  border-bottom: ${({ theme }) => ` 1px solid ${theme.newtheme.color.cinza2}`};
  padding: 1rem 0;
  margin-bottom: 1rem;
  & h4 {
    color: ${({ theme }) => theme.newtheme.color.cinza6};
    font-weight: 600;
    margin-bottom: 1rem;
  }
`;

export const BtnClose = styled.button`
  background-color: transparent;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  cursor: pointer;
  outline: none;
`;

export const Btn = styled.button`
  background-color: transparent;
  border: ${({ theme }) => `2px solid ${theme.newtheme.color.azulPrimario}`};
  text-decoration: none;
  outline: none;
  cursor: pointer;
  padding: 0.2rem 1rem;
  color: ${({ theme }) => theme.newtheme.color.azulPrimario};
  border-radius: 2.8rem;
  font-weight: 500;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
    color: ${({ theme }) => theme.newtheme.color.branco};
  }
`;

export const BtnWrapper = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 46px;
  height: 49px;
  padding: 0;
  justify-content: center;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  border: none;
  outline: none;
  &:hover svg {
    fill: ${({ theme }) => theme.newtheme.color.azulTerceario};
  }
`;

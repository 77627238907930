import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { flexUnit } from 'mixins';

import { FramePic } from 'components/FinFrame';

const WrapperUserInitial = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme, colorText }) => colorText || theme.newtheme.color.branco};
  font-weight: 600;
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  ${flexUnit(5, 12, 16, 'vw', 'font-size')}
`;

const UserInitial = ({ texto, colorText, pbgColor, size }) => {
  const initLetters = () => {
    if (texto) {
      let res = texto;
      res = res.toUpperCase();
      res = res.split(' ');
      let letters = null;
      if (res.length > 1) {
        letters = {
          first: res[0].charAt(0),
          last: res[1].charAt(0),
        };
      } else {
        letters = {
          first: res[0].charAt(0),
          last: '',
        };
      }
      return letters;
    }
    const letters = {
      first: 'D',
      last: 'F',
    };
    return letters;
  };
  const lettersIni = initLetters();

  let tamanho = '40px';

  if (size === 'small') {
    tamanho = '38px';
  }

  if (size === 'medium') {
    tamanho = '50px';
  } else if (size === 'large') tamanho = '60px';

  return (
    <FramePic pwidth={tamanho} pheight={tamanho} pbgColor={pbgColor}>
      <WrapperUserInitial colorText={colorText}>
        {lettersIni.first}
      </WrapperUserInitial>
      <WrapperUserInitial colorText={colorText}>
        {lettersIni.last}
      </WrapperUserInitial>
    </FramePic>
  );
};

UserInitial.propTypes = {
  texto: PropTypes.string,
};

UserInitial.defaultProps = {
  texto: 'Richard S. Vignelli.',
};

export default UserInitial;

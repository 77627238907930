import React from 'react';

import { SeparatorHorizontal } from 'components/FinSeparador';
import { FinAvatar } from 'components/FinAvatar';
import { ImageRenderer } from 'components/FinImageRenderer';
import { useLocation } from 'react-router-dom';

import ReactTooltip from 'react-tooltip';
import mini from 'assets/images/cover-placeholder.png';

import { currency } from 'helpers/format';
import { useGetImage } from 'hooks/useGetImage';
import { capitalizeWords } from 'helpers/string';
import * as NWCard from './style.card';

import { useSelectTag } from '../../hook/useSelectTag';
import { RegionButtons } from './RegionButtons';

import { MenuContext } from '../MainMenu';

import { RenderIconMatch } from './RenderIconMatch';

export function CardVertical({
  data,
  isInvitation,
  isConnection,
  isLocked,
  removeMenuContext,
}) {
  const { comp: TagMember } = useSelectTag({
    tipo: 'finscanner',
    pos: { left: 16, top: 67 },
  });

  const location = useLocation();

  const withImage = data.caminhoArquivo || null;

  const imageFallback = data.caminhoArquivo || mini;

  const fullImage = useGetImage({
    url: withImage,
    largura: 400,
    altura: 0,
    qualidade: 20,
    fallback: imageFallback,
  });

  const capitalizedName = capitalizeWords(data.nome);
  const capitalizedCity = capitalizeWords(data.cidade);

  return (
    <NWCard.Wrapper direction="column">
      <NWCard.Head>
        <NWCard.Cover>
          <FinAvatar
            online={data.online}
            name={data.nome}
            withShadow
            style={{
              position: 'absolute',
              top: 16,
              left: 16,
              zIndex: 5,
            }}
          />
          {data.finscannerAtivo && <TagMember />}
          {!removeMenuContext && <MenuContext data={data} />}
          <ImageRenderer
            url={fullImage}
            thumb={mini}
            objfit={withImage ? 'cover' : 'fill'}
          />
        </NWCard.Cover>
      </NWCard.Head>
      <NWCard.Body>
        <ReactTooltip
          id={`card-name-financiador-${data.idGrupo}`}
          wrapper="span"
          place="right"
          type="dark"
          effect="solid"
        >
          {capitalizedName}
        </ReactTooltip>
        <NWCard.RegionIconMatch>
          <RenderIconMatch data={data} />
        </NWCard.RegionIconMatch>
        <NWCard.CompanyName
          limite="16ch"
          to={{
            pathname: `/fincredit/${data.idGrupo}`,
            state: location.pathname,
          }}
          data-tip
          data-for={`card-name-financiador-${data.idGrupo}`}
        >
          {capitalizedName}
        </NWCard.CompanyName>
        <NWCard.CompanySegment limite="23ch">
          {data.segmento}
        </NWCard.CompanySegment>
        <SeparatorHorizontal ppadding="0.4rem" />
        <NWCard.Location isActive={!!data.cidade} limite="23ch">
          {data.cidade ? (
            <>
              {capitalizedCity}
              <span
                style={{ textTransform: 'uppercase' }}
              >{`, ${data.estado}`}</span>
            </>
          ) : (
            'Localização'
          )}
        </NWCard.Location>
        <NWCard.Invoicing isActive={!!data.faturamento}>
          {data.faturamento ? currency(data.faturamento) : 'Faturamento'}
        </NWCard.Invoicing>
        <NWCard.FullProfile isActive={data.perfil}>Perfil</NWCard.FullProfile>
        <NWCard.FullProfile isActive={data.documento}>
          Documentos
        </NWCard.FullProfile>
        <SeparatorHorizontal />
        <RegionButtons
          isInvitation={isInvitation}
          isLocked={isLocked}
          isConnection={isConnection}
          data={data}
        />
      </NWCard.Body>
    </NWCard.Wrapper>
  );
}

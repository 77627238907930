import React from 'react';

import { Step } from '../Step';

import { Header, Body, Emoji } from '../style.wizard';

export const Step1 = React.forwardRef((props, ref) => {
  return (
    <Step {...props} ref={ref} position="top" viewAction={['close', 'next']}>
      <Header>
        <Emoji>🔥</Emoji> Novidade - Endividamento
      </Header>
      <Body>
        <p>Entenda como está o endividamento da empresa.</p>
      </Body>
    </Step>
  );
});

import styled from 'styled-components/macro';

import { SubTitle2 } from 'components/FinSubTitles';
import { Wrapper } from 'components/FinWrappers';
// import InitialLetters from 'components/FinInitialLetters';
// import { finTheme } from 'finTheme';

// console.log('ANDREW');
// console.log(finTheme.newtheme.color.corAnalisar);

export const AreaImpressao = styled.div`
  @media print {
    box-sizing: border-box;
    padding: 2%;
    width: 80vw;
    /* border: 1px solid #999; */
    margin: 5% auto;
    background-image: url('../../../images/Logo_Finmatch_Vermelho.png');
    background-repeat: no-repeat;
    background-color: #fff;
    border-radius: 14px;
  }
`;
export const LogoImpressao = styled.h2`
  text-align: center;
  display: none;
  @media print {
    display: block;
  }
`;
export const CustomH2 = styled.h2`
  position: relative;
  box-sizing: border-box;
  padding-left: 25px;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5rem;
  display: block;
  color: #000;
  width: 100%;

  &.primeiro {
    margin-top: 30px;
  }

  margin: 10px auto 20px auto;
  &:before {
    position: absolute;
    left: 0px;
    transform: translateY(75%);
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
  }
  span {
    display: block;
    font-weight: normal;
    font-size: 0.85rem;
    color: #646464;
  }
`;
export const CustomH3 = styled.h3`
  position: relative;
  box-sizing: border-box;
  padding: 8px 0px;
  font-weight: bold;
  font-size: 1rem;
  display: block;
  color: #000;
  width: 100%;
  text-align: center;
  font-weight: medium;
  border-bottom: 1px dashed #d8d8d8;
`;
export const CustonSubtitle2 = styled(SubTitle2)`
  color: ${({ theme }) => theme.newtheme.color.azulPrimario};
  line-height: 0.8rem;
`;

export const TituloModalNovo = styled.span`
  color: ${({ theme }) => theme.newtheme.color.azulPrimario};

  text-transform: uppercase;
  font-size: 2.25rem;
  font-weight: bold;
  padding-left: 10px;
  background-image: url('../../../images/Logo_Finmatch_Vermelho.png');
  background-repeat: no-repeat;
  text-indent: -9000px;
  background-size: cover;
`;

export const TextBlur = styled.span`
  color: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 1);
`;

export const CircleLetters = styled.div`
  position: relative;
  width: 60px;
  height: 59px;
  border-radius: 90rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #646464;
  font-weight: bold;
  box-sizing: border-box;
  font-size: 1.45rem;
  margin: 15px auto;
  p {
    display: block;
    min-width: 60px;
    height: 59px;
    border-radius: 90rem;
    background: #f1f1f1;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  &:before {
    position: absolute;
    width: 66px;
    height: 65px;
    border-radius: 90rem;
    background: linear-gradient(
      295.18deg,
      ${({ theme }) => theme.newtheme.color.azulPrimario} 34.63%,
      ${({ theme }) => theme.newtheme.color.azulPrimario} 63.69%,
      rgba(247, 94, 76, 0) 86.23%
    );
    content: '';
    z-index: 0;
  }
`;

export const SituacaoEmpresa = styled.h5`
  position: absolute;
  bottom: 10%;
  right: -20px;
  font-size: 00.6rem;
  z-index: 10;
  color: #fff;
  border-radius: 50px;
  width: auto;
  box-sizing: border-box;
  padding: 3px 5px 1px 5px;
  text-transform: uppercase;
  background-color: Green;
  font-weight: bold;
  text-align: center;
  &.inativo {
    background-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
  }
`;

export const CircleRating = styled(CircleLetters)`
  width: 80px;
  height: 80px;
  font-size: 0.8rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border: 3px solid #000;
  color: #000;
`;

export const CustomWrapper = styled(Wrapper)`
  flex-direction: column;
  width: auto;
  padding: 10px;
  align-items: stretch;

  &.CircleIconsAderencia {
    width: 49px;
    height: 49px;
    text-align: center;
    /* padding: 0px 0px 0px 15px; */

    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 100%;
    box-sizing: border-box;
  }
`;

export const CustomWrapperMensagem = styled(Wrapper)`
  flex-direction: column;
  width: auto;
  box-sizing: border-box;
  padding: 50px 50px;
  align-items: stretch;
  text-align: center;
  color: #000;
  font-size: 1.2rem;
  a {
    color: blue;
    text-decoration: underline;
  }
`;
export const CustomWrapperLinha = styled(Wrapper)`
  display: table !important;
  width: 100%;
  padding: 10px;
  align-items: stretch;
  border: 1px solid blue;
  span {
    float: left;
  }
`;

export const CustomWrapperImpeditivos = styled(Wrapper)`
  max-width: 230px;
  padding: 10px;
  width: 230px;
  border-radius: 10px;
  margin: 10px 10px;
  height: 75px;

  flex-direction: row;
  &.alerta {
    background: rgba(239, 180, 27, 0.38);
  }
  &.sem-informacao {
    background: rgba(100, 100, 100, 0.2);
  }
  &.ok {
    background: rgba(109, 212, 0, 0.35) !important;
  }
  align-items: center;
  flex-grow: 1;
  /* width: initial !important; */
  /* min-width: calc(100% / 4.35); */

  span {
    display: inline-block;
    width: 25%;
  }
  .paragrafoImpeditivos {
    display: inline-block;
    width: 70%;

    height: 35px;
    min-height: auto;
    padding: 0px;
    line-height: initial;
    vertical-align: middle;
    padding: 0px;
  }
`;
export const Break = styled.div`
  flex-basis: 100% !important;
  height: 0;
  flex-grow: 4;
  min-width: 100%;
`;
export const Column = styled(Wrapper)`
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  flex-direction: column;
`;

export const Line = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #f1f1f1;
`;

export const CustonDisclaimer1 = styled(SubTitle2)`
  color: ${({ theme }) => theme.newtheme.color.cinza4};
  font-size: 0.7rem;
  text-align: left;
`;

export const CustomWrapperCircleLetters = styled(Wrapper)`
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: #fff;
  margin: 10px 0px;
`;
export const BlocoConteudo = styled.div`
  width: ${({ pwidth }) => pwidth || 'auto'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  display: flex;
  flex-direction: ${({ tipo }) => tipo || 'row'};
  margin: 10px auto;
  border-radius: 4px;
  padding: 2px 10px;
  box-sizing: border-box;
  .linhaEsquerda {
    border-left: 2px solid #f1f1f1;
    padding-bottom: 30px;
    padding-top: 50px;
  }

  &.blocoInfoValores {
    width: 96%;
    margin: 0px auto;
  }
`;
export const AreaAcao = styled.div`
  position: absolute;
  right: 60px;
  display: flex;
  width: 100%;
  justify-content: end;
  flex-direction: row;
  box-sizing: border-box;

  padding: 10px 0px 0px 0px;
  @media print {
    display: none;
  }
  .LinkAcao {
    display: ${({ display }) => display || 'initial'};
    transition: all 0.2s ease-in-out;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    background-color: #e8e8e8;
    text-align: center;
    margin: 0px 1px 0px 1px;
    &:hover {
      background-color: ${({ theme }) =>
        theme.backgroundColor.azulSecundarioEscuro};
    }
    svg {
      fill: orange;
    }
  }
`;
export const Paragrafo = styled.p`
  font-size: ${({ theme }) => theme.newtheme.font.subtitulo1.tamanho};
  padding: 5px 10px;
  box-sizing: border-box;
`;

export const TextoDisclaimer = styled.p`
  display: none;
  color: #646464;
  font-size: 0.56rem;
`;

export const CustomParagrafo = styled(Paragrafo)`
  position: relative;
  font-size: 0.85rem;
  display: block;
  box-sizing: border-box;
  padding: 0px 10px;
  color: #646464;
  span {
    font-size: 0.85rem;
    display: block;
  }
`;

export const Bloco1Paragrafo = styled(Paragrafo)`
  font-size: ${({ theme }) => theme.newtheme.font.corpo1.tamanho};
  font-size: 0.8rem;
  font-weight: regular;
  width: 100%;
  color: #646464;
  padding: 0px 5px;
  text-align: center;
`;
export const Bloco1ParagrafoDestaque = styled(Paragrafo)`
  font-size: ${({ theme }) => theme.newtheme.font.corpo1.tamanho};
  font-size: 0.8rem;
  font-weight: bold;
  width: 100%;
  color: #000;
  padding: 0px 5px;
  text-align: center;
`;
export const Titulo4 = styled.h4`
  display: block;
  font-size: ${({ theme }) => theme.newtheme.font.titulo5.tamanho};
`;
export const Titulo3 = styled.h3`
  display: block;
  font-size: ${({ theme }) => theme.newtheme.font.titulo4.tamanho};
`;

export const CustomWrapperInativo = styled(Wrapper)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 5px;
  width: 100%;
  height: 99%;
`;

/* pagina Valores Aderencia */
export const BlocoNivelAderencia = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  box-sizing: border-box;
  padding: 0px 20px 0px 0px;
  justify-content: center;

  div {
    &:nth-of-type(1) {
      flex: 5;

      justify-content: center;
      align-items: center;
      div {
        flex: 1;
      }
    }
    &:nth-of-type(2) {
      flex: 4;
    }
    &:nth-of-type(3) {
      align-items: flex-end;
      flex: 1;
      justify-content: center;
    }
    &:nth-of-type(4) {
      flex: 3;
      justify-content: center;
      align-items: center;

      div {
        flex: 2;
        max-height: 90px;
        height: 90px;
        width: 90px;
      }
    }
  }
`;
export const BlocoValoresAderencia = styled.div`
  box-sizing: border-box;
  padding: 23px 10px 0px 10px;
`;

export const ParagrafoRespostaValores = styled(CustomParagrafo)`
  position: relative;
  box-sizing: border-box;
  padding: 2px 0px 2px 35px;
  width: 100%;
  height: 25px;
  margin: 5px 0px;
  font-size: 0.85rem;
  color: #646464;
  display: block;
  border-radius: 25px;

  &:before {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    content: '';
    border-radius: 100%;
    background-color: #000;
    width: 7px;
    height: 7px;
  }
  &.DeuMatch {
    background-color: #f1f0f0;
    font-weight: bold;
  }
  &:nth-of-type(1) {
    &:before {
      background-color: #8dcd07;
    }
  }
  &:nth-of-type(2) {
    &:before {
      background-color: #f0b41c;
    }
  }
  &:nth-of-type(3) {
    &:before {
      background-color: #f85f4d;
    }
  }
`;

export const RespostaMatch = styled.div`
  position: relative;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  padding: 10% 0px 0px 0px;
  width: 90px;
  height: 90px;
  border: 2px solid #000;
  color: #000;
  border-radius: 4px;
  vertical-align: middle;
  text-align: center;
  &:before {
    position: absolute;
    text-align: center;
    content: '';
    line-height: 0.9rem;
    font-size: 0.8rem;
    font-weight: bold;
    display: block;
    bottom: 5px;
    width: 100%;
    background-position: center;
    background-size: cover;
  }
  &.Match {
    border-color: ${({ theme }) => theme.newtheme.color.corMatch};
    background-color: ${({ theme }) => theme.newtheme.color.corMatch50};

    &:before {
      color: ${({ theme }) => theme.newtheme.color.corMatch};
      content: 'MATCH';
      bottom: 15px;
    }
  }
  &.Analisar {
    background-color: ${({ theme }) => theme.newtheme.color.corAnalisar50};
    border-color: ${({ theme }) => theme.newtheme.color.corAnalisar};
    &:before {
      color: ${({ theme }) => theme.newtheme.color.corAnalisar};
      content: 'ANALISAR';
      bottom: 15px;
    }
  }
  &.NoMatch {
    background-color: ${({ theme }) => theme.newtheme.color.corNoMatch50};
    border-color: ${({ theme }) => theme.newtheme.color.corNoMatch};
    &:before {
      color: ${({ theme }) => theme.newtheme.color.corNoMatch};
      content: 'NAO DEU MATCH';
    }
  }
  &.SemValor {
    background-color: ${({ theme }) => theme.newtheme.backgroundColor.preto10};
    border-color: ${({ theme }) => theme.newtheme.color.preto50};
    &:before {
      top: 50%;
      transform: translateY(-20%);
      color: ${({ theme }) => theme.newtheme.color.preto50};
      content: 'SEM VALOR';
    }
  }
`;

export const useInitialLetters = (texto) => {
  const initLetters = () => {
    if (texto) {
      let res = texto;
      res = res.toUpperCase();
      res = res.split(' ');
      let letters = null;
      if (res.length > 1) {
        letters = {
          first: res[0].charAt(0),
          last: res[1].charAt(0),
        };
      } else {
        letters = {
          first: res[0].charAt(0),
          last: '',
        };
      }
      return letters;
    }
    const letters = {
      first: 'D',
      last: 'F',
    };
    return letters;
  };
  const lettersIni = initLetters();

  return { first: lettersIni.first, last: lettersIni.last };
};

import React from 'react';
import { finTheme } from 'finTheme';

import { icons } from 'assets/icones/finplaceIcons';
import TagStatus from './TagStatus';

const TagHabilitacaoPendente = ({ id }) => {
  return (
    <TagStatus
      viewTooltip
      id={`analise-andamento-${id}`}
      tooltipText="Habilitação pendente!!"
      bgcolor={finTheme.newtheme.color.alert}
      textColor={finTheme.newtheme.color.branco}
      iconColor={finTheme.newtheme.color.branco}
      icon={icons.exclamationFlat}
    >
      Habilitação pendente
    </TagStatus>
  );
};

export default TagHabilitacaoPendente;

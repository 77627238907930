import React from 'react';
import { icons } from 'assets/icones/finplaceIcons';
import { StringToSvg } from 'helpers/svgFiles/StringToSvg';
import { Speedometer } from 'components/Graficos/Speedometer/';
import { RatingCedente } from './cedenteRating';
import {
  BlocoNivelAderencia,
  BlocoValoresAderencia,
  ParagrafoRespostaValores,
  CustomWrapper,
} from './Styles';

export const NivelAderencia = ({ dados }) => {
  return (
    <BlocoNivelAderencia>
      <CustomWrapper>
        <Speedometer id="dial5" value={dados} title="Acceleration X" />
      </CustomWrapper>

      <BlocoValoresAderencia>
        <ParagrafoRespostaValores className={dados >= 70 ? 'DeuMatch' : ''}>
          &gt; 70% deu Match
        </ParagrafoRespostaValores>
        <ParagrafoRespostaValores
          className={dados < 70 && dados >= 50 ? 'DeuMatch' : ''}
        >
          &gt; 50% e &lt; 70% análisar
        </ParagrafoRespostaValores>
        <ParagrafoRespostaValores
          className={dados <= 49 && dados >= 0 ? 'DeuMatch' : ''}
        >
          &lt; 50% não deu Match
        </ParagrafoRespostaValores>
      </BlocoValoresAderencia>

      <CustomWrapper>
        <StringToSvg
          viewBox="0 0 25 25"
          width="25px"
          height="25px"
          strokeWidth="0"
        >
          {icons.setaDireita}
        </StringToSvg>
      </CustomWrapper>
      <RatingCedente dados={dados} />
    </BlocoNivelAderencia>
  );
};

import React, { useContext } from 'react';

import { Wrapper } from 'components/FinWrappers';
import { AuthContext } from 'providers/ProviderAuth';
import ItemList from '../ItemList';

const Header = ({ data }) => {
  const user = useContext(AuthContext);

  return (
    <Wrapper padding="36px 40px 0 40px" pwidth="100%">
      {!!data && (
        <ItemList isDisabledLink data={data} idEmpresa={user.idEmpresa} />
      )}
    </Wrapper>
  );
};

export default Header;

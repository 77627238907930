import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { finTheme } from 'finTheme';

import ReactTooltip from 'react-tooltip';

import { Wrapper } from 'components/FinWrappers';
import { Caption } from 'components/FinParagraph';

import { StringToSvg } from 'helpers/svgFiles/StringToSvg';

const Tag = styled(Wrapper)`
  background-color: ${({ bgcolor }) => bgcolor};
  padding: ${({ bgcolor }) => (bgcolor !== 'transparent' ? '0 9px' : 0)};
  border-radius: 100px;
  cursor: auto;
  ${({ larguraW, alturaH }) => css`
    width: ${larguraW};
    height: ${alturaH};
  `}
  ${({ border, theme }) =>
    border &&
    css`
      box-shadow: inset 0px 0px 0px 1px ${theme.newtheme.color.cinza3};
      padding: 0 0.7rem;
    `}
`;

const TagStatus = ({
  textColor,
  bgcolor,
  iconColor,
  icon,
  viewTooltip,
  id,
  tooltipText,
  vbox,
  border,
  children,
  alturaH,
  larguraW,
}) => {
  return (
    <>
      {viewTooltip && (
        <ReactTooltip
          id={`info-${id}`}
          wrapper="span"
          place="bottom"
          type="dark"
          effect="solid"
        >
          {tooltipText}
        </ReactTooltip>
      )}
      <Tag
        border={border ? 1 : 0}
        bgcolor={bgcolor}
        data-tip
        data-for={`info-${id}`}
        alturaH={alturaH}
        larguraW={larguraW}
      >
        {icon && (
          <>
            <Wrapper pwidth="18px" pheight="18px" padding="0">
              <StringToSvg
                viewBox={vbox || '-5 -3 24 24'}
                width="100%"
                height="100%"
                stroke="none"
                fill={iconColor}
              >
                {icon}
              </StringToSvg>
            </Wrapper>
            &nbsp;
          </>
        )}
        <Caption
          fontWeigth="500"
          color={textColor}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {children}
        </Caption>
      </Tag>
    </>
  );
};

TagStatus.propTypes = {
  bgcolor: PropTypes.string,
  textColor: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  viewTooltip: PropTypes.bool,
  id: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  vbox: PropTypes.string,
  children: PropTypes.any.isRequired,
  alturaH: PropTypes.string,
  larguraW: PropTypes.string,
};

TagStatus.defaultProps = {
  bgcolor: 'transparent',
  textColor: 'Lorem ipsum dor lorem',
  iconColor: finTheme.newtheme.color.cinza3,
  viewTooltip: false,
  alturaH: 'auto',
  larguraW: 'auto',
};

export default TagStatus;

import axios from 'axios';
import { useQuery } from 'react-query';
import CONFIG from 'config';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/`;

const fetchGetSocios = async (params) => {
  const fetchData = axios.get(
    `${BASE_URL}api/graficos/socios/${params.idGrupo}`
  );

  const result = await fetchData;
  const { data } = result;

  return data.data ? data.data : [];
};

export const useGetSocios = (params) => {
  return useQuery(['socios', 'nodes', params], () => fetchGetSocios(params), {
    keepPreviousData: false,
  });
};

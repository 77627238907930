import React, { useContext } from 'react';

import { Card } from '../Comps/Card';
import { NWTable } from '../Comps/Table';

import { TitleContaBancaria } from '../Comps/TitleHead';
import { PerfilContext } from '../PaginaFinCredit';

export function ContaBancaria() {
  const { perfil } = useContext(PerfilContext);

  if (perfil.data) {
    return (
      perfil.data.criptografia === false && (
        <>
          <TitleContaBancaria />
          <Card style={{ margin: '13px 0 48px' }}>
            <NWTable.Table>
              <NWTable.Thead>
                <NWTable.Tr>
                  <NWTable.Th borderRadiusLeft largura="420px">
                    BANCO
                  </NWTable.Th>
                  <NWTable.Th>AGÊNCIA</NWTable.Th>
                  <NWTable.Th borderRadiusRight>CONTA</NWTable.Th>
                </NWTable.Tr>
              </NWTable.Thead>
              <NWTable.Tbody>
                <NWTable.Tr>
                  <NWTable.Td>
                    {perfil && perfil.data && perfil.data.contaPrincipal.banco}
                  </NWTable.Td>
                  <NWTable.Td>
                    {perfil &&
                      perfil.data &&
                      perfil.data.contaPrincipal.agencia}
                  </NWTable.Td>
                  <NWTable.Td>
                    {perfil &&
                      perfil.data &&
                      `${perfil.data.contaPrincipal.conta} ${
                        perfil.data.contaPrincipal.digito &&
                        ` - ${perfil.data.contaPrincipal.digito}`
                      }`}
                  </NWTable.Td>
                </NWTable.Tr>
              </NWTable.Tbody>
            </NWTable.Table>
          </Card>
        </>
      )
    );
  }

  return null;
}

/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Route, useLocation, Switch } from 'react-router-dom';

import { PaginaEmpreendedores } from 'pages/Empreendedores';
import { PaginaOperacoes } from 'pages/Operacoes/PaginaOperacoes';

import { PaginaMinhaRede } from 'pages/MinhaRede';

import { PaginaFinCredit } from 'pages/FinCredit';
import Home from 'pages/home';
import MeusDados from 'pages/MeusDados';
import Finmatch from 'pages/Finmatch';

import { PaginaLogout } from 'pages/Logout';
import { OnSigninCallback } from 'auth/OnSigninCallback';
import { OnSigninSilentCallback } from 'auth/OnSigninSilentCallback';
import { PaginaLogin } from 'pages/FinLogin';
import { Pagina404 } from 'pages/404';
import { AgendaPage } from 'pages/Agenda';
import { Modals, modalFallback } from './Modals';

export function Routes() {
  const location = useLocation();
  const background = location.state && location.state.background;

  React.useLayoutEffect(() => {
    document.querySelector('body').scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Modals background={background} />
      <Switch location={background || location}>
        <Route exact path="/login" component={PaginaLogin} />
        <Route exact path="/signin-callback" component={OnSigninCallback} />
        <Route
          exact
          path="/signin-silent-callback"
          component={OnSigninSilentCallback}
        />
        <Route exact path="/logout" component={PaginaLogout} />

        <Route exact path="/" component={Home} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/meusdados" component={MeusDados} />
        <Route exact path="/Finmatch" component={Finmatch} />
        <Route exact path="/operacoes" component={PaginaOperacoes} />
        <Route
          exact
          path="/clientes"
          render={(props) => <PaginaEmpreendedores {...props} />}
        />
        <Route
          path="/minha-rede"
          render={(props) => <PaginaMinhaRede {...props} />}
        />
        <Route
          path="/fincredit/:idGrupo"
          render={(props) => <PaginaFinCredit {...props} />}
        />
        <Route path="/agenda" render={(props) => <AgendaPage {...props} />} />
        {modalFallback.map((route, index) => (
          <Route key={index} {...route} />
        ))}
        <Route component={Pagina404} />
      </Switch>
    </>
  );
}

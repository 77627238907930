import React from 'react';

import { useParams } from 'react-router-dom';
import { Card } from '../Comps/Card';
import { NWTable } from '../Comps/Table';
import { TableRow } from './TableRow';
import { Content } from './styles.user';

import { TitleQuadroSocietario } from '../Comps/TitleHead';

import { useQueryQuadroSocietario } from '../hooks';

export function QuadroSocietario() {
  const { idGrupo } = useParams();

  const { data } = useQueryQuadroSocietario({ idGrupo });

  return data && data.length > 0 ? (
    <>
      <TitleQuadroSocietario />
      <Card style={{ margin: '13px 0 48px' }}>
        <Content>
          <NWTable.Table>
            <NWTable.Thead>
              <NWTable.Tr>
                <NWTable.Th borderRadiusLeft largura="380px">
                  NOME
                </NWTable.Th>
                <NWTable.Th>CPF</NWTable.Th>
                <NWTable.Th borderRadiusRight>FUNÇÃO</NWTable.Th>
              </NWTable.Tr>
            </NWTable.Thead>
            <NWTable.Tbody>
              {data.map((item, index) => (
                <TableRow data={item} key={index} />
              ))}
            </NWTable.Tbody>
          </NWTable.Table>
        </Content>
      </Card>
    </>
  ) : null;
}

import axios from 'axios';
import { put, select /* , call */ } from 'redux-saga/effects';

import {
  loadDataBorderosSuccess,
  loadDataBorderosFailure,
  updateDataBorderosSuccess,
  addBorderoPropostasSucceeded,
  showNotification,
  contentNotification,
} from 'store/old_actions/actions';

import config from '../../config';

/** *********************** Load bordero saga ***************************** */
export function* getBorderos(action) {
  try {
    const res = yield axios.get(
      `${config.API_REMODELING_URL}/business-api/api/bordero/sacados/titulos/${action.idBordero}`
    );

    yield put(loadDataBorderosSuccess(res.data.data));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield put(
      contentNotification(
        `Erro!!! Name: ${err.name}Message :${err.message}`,
        2000
      )
    );
    yield put(showNotification());
    yield put(loadDataBorderosFailure(err.response));
  }
}

export function* updateBorderos(action) {
  // console.log(action);

  try {
    yield put(updateDataBorderosSuccess(action.titulos));
    const data = (state) => state.borderos.data;
    const bord = yield select(data);
    yield put(addBorderoPropostasSucceeded(bord));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

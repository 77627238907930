import styled from 'styled-components/macro';
import { rgba, darken } from 'polished';

import { ReactComponent as IconeCheck } from 'assets/images/icon-checked.svg';
import { ReactComponent as IconeCirclePlus } from 'assets/images/icon-plus-circle.svg';
import { ReactComponent as IconeSend } from 'assets/images/icon-send.svg';
import { ReactComponent as IconeCalc } from 'assets/images/icon-calculator.svg';

export const StatusStyle = styled.div`
  display: flex;
  width: 100%;
  height: 82px;
`;

export const IconCheckStyle = styled(IconeCheck)`
  stroke: #fff;
  & path {
    stroke: #fff;
  }
`;

export const IconCirclePlusStyle = styled(IconeCirclePlus)`
  stroke: ${({ theme }) => theme.newtheme.buttons.secondary.background};
  & path {
    stroke: ${({ theme }) => theme.newtheme.buttons.secondary.background};
  }
`;

export const IconSendStyle = styled(IconeSend)`
  stroke: #fff;
  & path {
    stroke: #fff;
  }
`;

export const IconCalcStyle = styled(IconeCalc)`
  stroke: ${({ theme }) => theme.newtheme.buttons.secondary.background};
  & path {
    stroke: ${({ theme }) => theme.newtheme.buttons.secondary.background};
  }
`;

export const WrapperStatusStyle = styled.div`
  display: flex;
  width: 100%;
  padding: 0;
  align-items: center;
  justify-content: center;
  background-color: ${({ bgColor }) =>
    bgColor ? rgba(bgColor, 0.2) : rgba('#008894', 0.2)};
  border-radius: 8px;
  padding: 13px;
  border-left: 7px solid
    ${({ bgColor }) =>
      bgColor ? darken(0.06, bgColor) : darken(0.06, '#008894')}; ;
`;

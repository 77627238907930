import React from 'react';
import ReactTooltip from 'react-tooltip';

import { Info } from '@phosphor-icons/react';

import {
  Input,
  InputNumber,
  InputLabel,
  Container,
  LabelValue,
  Row,
  Col,
} from './style';

export function InputConfigurationNetworkChart({ config, setConfig }) {
  const handleChange = (event) => {
    event.persist();

    setConfig((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <Container>
      <Row>
        <ReactTooltip id="centeringStrength">
          Controle o quanto a força de centralização afeta o posicionamento dos
          nós.
        </ReactTooltip>
        <Col>
          <InputLabel>
            Centralização Forçada
            <Info size={16} data-tip data-for="centeringStrength" />
          </InputLabel>
          <Input
            name="centeringStrength"
            type="range"
            min="0"
            max="2"
            step="0.1"
            value={config.centeringStrength}
            onChange={handleChange}
          />
        </Col>
        <LabelValue>{config.centeringStrength}</LabelValue>
      </Row>

      <Row>
        <ReactTooltip id="repulsivity">
          Controle como os nós se repelem.
        </ReactTooltip>
        <Col>
          <InputLabel>
            Repulsividade
            <Info size={16} data-tip data-for="repulsivity" />
          </InputLabel>
          <Input
            name="repulsivity"
            type="range"
            min="1"
            max="100"
            value={config.repulsivity}
            onChange={handleChange}
          />
        </Col>
        <LabelValue>{config.repulsivity}</LabelValue>
      </Row>

      <Row>
        <ReactTooltip id="distanceMin">
          Define a distância mínima entre nós para a força de muitos corpos.
        </ReactTooltip>
        <Col>
          <InputLabel>
            Distância Min
            <Info size={16} data-tip data-for="distanceMin" />
          </InputLabel>
        </Col>
        <InputNumber
          name="distanceMin"
          type="text"
          value={config.distanceMin}
          onChange={handleChange}
        />
      </Row>

      <Row>
        <ReactTooltip id="distanceMax">
          Define a distância máxima entre nós para a força de muitos corpos.
        </ReactTooltip>
        <Col>
          <InputLabel>
            Distância Max
            <Info size={16} data-tip data-for="distanceMax" />
          </InputLabel>
        </Col>
        <InputNumber
          name="distanceMax"
          type="text"
          value={config.distanceMax}
          onChange={handleChange}
        />
      </Row>

      <Row>
        <Col>
          <ReactTooltip id="iterations">
            Ajuste a qualidade da simulação.
          </ReactTooltip>
          <InputLabel>
            Iterações
            <Info size={16} data-tip data-for="iterations" />
          </InputLabel>
          <Input
            name="iterations"
            type="range"
            min="30"
            max="260"
            value={config.iterations}
            onChange={handleChange}
          />
        </Col>
        <LabelValue>{config.iterations}</LabelValue>
      </Row>
    </Container>
  );
}

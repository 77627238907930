import React from 'react';

import { FinCarousel, FinCarouselItem } from 'components/FinCarousel';
import { ImageRenderer } from 'components/FinImageRenderer';
import mini from 'assets/images/cover-placeholder.png';
import { useParams } from 'react-router-dom';
import CONFIG from 'config';
import { Flex, Media } from './style.galeria';
import { TitleGaleria } from '../Comps/TitleHead';

import { Tag } from '../QuadroSocietario/styles.user';

import { useQueryGaleria } from '../hooks';

const URL = `${CONFIG.API_FINIMAGE}/api/imagem`;

const tagStyle = {
  position: 'absolute',
  top: '15px',
  left: '15px',
  zIndex: '99',
};

export function Galeria() {
  const { idGrupo } = useParams();

  const { data } = useQueryGaleria({ idGrupo });

  const withGaleria = data && data.galeria;

  const getImage = (url) => {
    return `${URL}?caminho=${url}&largura=400&altura=0&qualidade=30`;
  };

  const getImageBg = (url) => {
    return `${URL}?caminho=${url}&largura=100&altura=0&qualidade=5`;
  };

  return withGaleria && withGaleria.length > 0 ? (
    <Flex column align="flex-start" style={{ marginBottom: '48px' }}>
      <TitleGaleria />
      <Flex style={{ marginTop: '13px' }}>
        <FinCarousel>
          {data.galeria.map((item) => {
            const image = getImage(item.caminhoArquivo);
            const bg = getImageBg(item.caminhoArquivo);
            return (
              <FinCarouselItem>
                <Media>
                  {item.descricao && (
                    <Tag style={tagStyle}>{item.descricao}</Tag>
                  )}
                  <ImageRenderer url={image} thumb={bg} imgStart={mini} />
                </Media>
              </FinCarouselItem>
            );
          })}
        </FinCarousel>
      </Flex>
    </Flex>
  ) : null;
}

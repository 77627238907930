import styled from 'styled-components/macro';
import iconGlobe from 'assets/images/GlobeSimple.svg';
import { Theme } from '../../theme';

import icoHousePricing from '../../images/iconsSvg/house-pricing.svg';
import icohandshake from '../../images/iconsSvg/handshake.svg';
import icoCurrencyExchange from '../../images/iconsSvg/currency-exchange-2.svg';
import icoBCheck from '../../images/iconsSvg/b-check.svg';
import icoBmeeting from '../../images/iconsSvg/b-meeting.svg';
import icoIconeExit from '../../images/iconsSvg/icone-exit.svg';
import icoStockMarket from '../../images/iconsSvg/stock-market.svg';
import icoFolderNoAccess from '../../images/iconsSvg/folder-no-access.svg';
import IcoInfo from '../../images/feather/info.svg';
import IcoTrash from '../../images/feather/trash.svg';
import icoCheckCircle from '../../images/feather/check-circle.svg';
import icoHandsOk from '../../images/handsOk.svg';
import icoTrofeu from '../../images/trofeu.svg';
import icoCashBack from '../../images/cashBack.svg';
import icoTouchId from '../../images/iconsSvg/touch-id.svg';
import icoCamera from '../../images/feather/camera.svg';
import icoChevronDown from '../../images/feather/chevron-down.svg';
import icoChat from '../../images/feather/chat.svg';
import icoTagMoney from '../../images/tag_money.svg';
import icoSifrao from '../../images/sifrao.svg';
import icoUserPlus from '../../images/feather/user-plus.svg';
import icofileText from '../../images/feather/file-text.svg';
import icoUserCheck from '../../images/feather/user-check.svg';
import icoClock from '../../images/feather/clock.svg';
import icoFilter from '../../images/filter.svg';
import icoX from '../../images/feather/x.svg';
import icoCheckOnly from '../../images/checkOnly.svg';
import icoCheckDouble from '../../images/checkDouble.svg';
import FinmatchSymbol from '../../images/iconsSvg/finmatch_symbol_skin.svg';

export const GRAPHICS = {
  housePricing: {
    image: icoHousePricing,
    label: 'House Pricing',
  },
  handshake: {
    image: icohandshake,
    label: 'Handshake',
  },
  currencyExchange: {
    image: icoCurrencyExchange,
    label: 'Currency Exchange',
  },
  iGlobe: {
    image: iconGlobe,
    label: 'iGlobe',
  },
  bCheck: {
    image: icoBCheck,
    label: 'BCheck',
  },
  bMeeting: {
    image: icoBmeeting,
    label: 'Bmeeting',
  },
  iconeExit: {
    image: icoIconeExit,
    label: 'Icone Exit',
  },
  stockMarket: {
    image: icoStockMarket,
    label: 'Stock Market',
  },
  folderNoAccess: {
    image: icoFolderNoAccess,
    label: 'Folder No Access',
  },
  info: {
    image: IcoInfo,
    label: 'Ico Info',
  },
  trash: {
    image: IcoTrash,
    label: 'Ico Trash',
  },
  checkCircle: {
    image: icoCheckCircle,
    label: 'Check Circle',
  },
  handsOk: {
    image: icoHandsOk,
    label: 'Hands OK',
  },
  trofeu: {
    image: icoTrofeu,
    label: 'Trofeu',
  },
  cashBack: {
    image: icoCashBack,
    label: 'Cashback',
  },
  touchId: {
    image: icoTouchId,
    label: 'Touche Id',
  },
  camera: {
    image: icoCamera,
    label: 'Camera',
  },
  chevronDown: {
    image: icoChevronDown,
    label: 'Chevron down',
  },
  chat: {
    image: icoChat,
    label: 'Chat',
  },
  tagMoney: {
    image: icoTagMoney,
    label: 'Tag money',
  },
  sifrao: {
    image: icoSifrao,
    label: 'Sifrao',
  },
  userPlus: {
    image: icoUserPlus,
    label: 'User plus',
  },
  fileText: {
    image: icofileText,
    label: 'File text',
  },
  userCheck: {
    image: icoUserCheck,
    label: 'User check',
  },
  clock: {
    image: icoClock,
    label: 'User check',
  },
  filter: {
    image: icoFilter,
    label: 'Filter',
  },
  X: {
    image: icoX,
    label: 'icon X',
  },
  checkOnly: {
    image: icoCheckOnly,
    label: 'Check Only',
  },
  checkDouble: {
    image: icoCheckDouble,
    label: 'Check Double',
  },
  FinmatchSymbol: {
    image: FinmatchSymbol,
    label: 'Finmatch',
  },
};

export const IconInclude = styled.i`
  background-color: ${({ bgcolor }) => bgcolor || Theme.gray2};
  mask-image: url(${({ icon }) => icon && GRAPHICS[icon].image});
  mask-size: ${({ size }) => size || '16px'};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${({ padding }) => padding || '1rem'};
`;

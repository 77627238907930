import React from 'react';
import PropTypes from 'prop-types';
import { BlurryText as BlurryComp } from 'helpers/string';

const BlurryText = ({ blurry, children }) => {
  if (blurry) {
    return <BlurryComp>{children}</BlurryComp>;
  }
  return children;
};

BlurryText.propTypes = {
  blurry: PropTypes.bool.isRequired,
  children: PropTypes.any,
};

export default BlurryText;

import styled from 'styled-components/macro';
// import media from 'styled-media-query';

import { /* darken, rgba, */ rem } from 'polished';
import { Theme } from '../../theme';

export const H1 = styled.h1`
  font-family: Roboto;
  font-size: ${rem('64px')};
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  color: ${({ color }) => color || Theme.gray6};
  letter-spacing: -0.8px;
  line-height: 1;
  ${({ truncatetex }) =>
    truncatetex &&
    `width: ${truncatetex};
    padding-right: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    `}
`;

export const H2 = styled.h2`
  font-family: Roboto;
  font-size: ${rem('48px')};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: ${({ color }) => color || Theme.gray6};
  letter-spacing: -0.8px;
  line-height: normal;
  ${({ truncatetex }) =>
    truncatetex &&
    `width: ${truncatetex};
    padding-right: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    `}
`;

export const H3 = styled.h3`
  font-family: Roboto;
  font-size: ${rem('40px')};
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  color: ${({ color }) => color || Theme.gray6};
  letter-spacing: -0.8px;
  line-height: 1.2;
  ${({ truncatetex }) =>
    truncatetex &&
    `width: ${truncatetex};
    padding-right: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    `}
`;

export const H4 = styled.h4`
  font-family: Roboto;
  font-size: ${rem('32px')};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: ${({ color }) => color || Theme.gray6};
  letter-spacing: normal;
  line-height: 1.25;
  ${({ truncatetex }) =>
    truncatetex &&
    `width: ${truncatetex};
    padding-right: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    `}
`;

export const H5 = styled.h5`
  font-family: Roboto;
  font-size: ${rem('24px')};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: ${({ color }) => color || Theme.gray6};
  letter-spacing: -0.8px;
  line-height: normal;
  ${({ truncatetex }) =>
    truncatetex &&
    `width: ${truncatetex};
    padding-right: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    `}
`;

export const H6 = styled.h6`
  font-family: Roboto;
  font-size: ${rem('20px')};
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  color: ${({ color }) => color || Theme.gray6};
  letter-spacing: -0.4px;
  line-height: 1.4;
  ${({ truncatetex }) =>
    truncatetex &&
    `width: ${truncatetex};
    padding-right: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    `}
`;

export const Paragraph = styled.p`
  font-family: Roboto;
  font-size: ${rem('16px')};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: ${({ color }) => color || Theme.gray6};
  letter-spacing: normal;
  line-height: 1.5;
  ${({ truncatetex }) =>
    truncatetex &&
    `width: ${truncatetex};
    padding-right: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    `}
`;

export const Title = styled.span`
  margin-bottom: 0.4rem;
  font-size: ${rem('22.4px')};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.8px;
  color: ${Theme.orange};
`;

export const TitleBold = styled.span`
  margin-bottom: 0.4rem;
  font-size: ${rem('20px')};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.8px;
  color: ${Theme.gray6};
`;

export const TitlePages = styled.span`
  font-weight: 600;
  color: ${Theme.gray6};
  font-size: ${rem('36.8px')};
  &::before {
    background: url(${(props) => props.icon}) no-repeat;
    content: '';
    padding: 2rem;
    position: relative;
    left: 2px;
    top: 23px;
    margin-right: 27px;
  }
`;

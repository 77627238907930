import React from 'react';

import { NwDialog } from 'components/NwDialog';

import { date, currency } from 'helpers/format';
import { truncate } from 'helpers/string';

import { useQueryAprovacaoHistorico } from '../../hooks/useQueryAprovacaoHistorico';

import { Card } from '../../Comps/Card';
import { NWTable } from '../../Comps/Table';

import { SkeletonLoader } from './Skeleton';

import { TitleHistorico } from '../../Comps/TitleHead';

export function Historico({ idGrupo, children }) {
  const { data, isLoading } = useQueryAprovacaoHistorico({ idGrupo });

  return (
    <NwDialog trigger={children} size="large">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '1rem 2rem 2rem 2rem',
        }}
      >
        <TitleHistorico />
        <Card
          style={{
            overflow: 'auto',
            maxHeight: '400px',
          }}
        >
          <NWTable.Table>
            <NWTable.Thead>
              <NWTable.Tr>
                <NWTable.Th borderRadiusLeft largura="100px">
                  ID
                </NWTable.Th>
                <NWTable.Th>LIMITE</NWTable.Th>
                <NWTable.Th>TRANCHE</NWTable.Th>
                <NWTable.Th>CONCENTRAÇÃO</NWTable.Th>
                <NWTable.Th>MOTIVO</NWTable.Th>
                <NWTable.Th>STATUS</NWTable.Th>
                <NWTable.Th borderRadiusRight>DATA</NWTable.Th>
              </NWTable.Tr>
            </NWTable.Thead>
            <NWTable.Tbody>
              {isLoading && <SkeletonLoader />}
              {data &&
                data.map((res) => {
                  return (
                    <NWTable.Tr>
                      <NWTable.Td>{res.idAprovacao}</NWTable.Td>
                      <NWTable.Td>{currency(res.limite)}</NWTable.Td>
                      <NWTable.Td>{currency(res.tranche)}</NWTable.Td>
                      <NWTable.Td>{`${res.concentracaoMaxima} %`}</NWTable.Td>
                      <NWTable.Td>{truncate(res.motivo, 10)}</NWTable.Td>
                      <NWTable.Td
                        style={{
                          fontWeight: '700',
                          color:
                            res.idAprovacaoStatus === 2
                              ? `var(--fingreen-300)`
                              : `var(--finred-500)`,
                        }}
                      >
                        {res.descricaoStatusAprovacao}
                      </NWTable.Td>
                      <NWTable.Td>{date(res.dtOrigem)}</NWTable.Td>
                    </NWTable.Tr>
                  );
                })}
            </NWTable.Tbody>
          </NWTable.Table>
        </Card>
      </div>
    </NwDialog>
  );
}

import React from 'react';
import iconWarning from 'assets/images/icon-circle-wavy-warning.svg';
import { ButtonPdf } from './ButtonPdf';

export function ButtonAnalise({ onClick }) {
  return (
    <ButtonPdf
      label="Analisar"
      icon={iconWarning}
      iconColor="rgba(242, 167, 27, 1)"
      bgColor="#FEEAC5"
      borderColor="#FFD380"
      onClick={onClick}
    />
  );
}

import styled from 'styled-components/macro';

export const Title = styled.div`
  font-family: 'Inter';
  color: var(--fingray-900);
  font-size: 0.875rem;
  font-weight: 500;
  text-rendering: geometricprecision;
  white-space: nowrap;
  width: 16ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SubTitle = styled.div`
  font-family: 'Inter';
  color: var(--fingray-500);
  font-size: 0.875rem;
  font-weight: 400;
  text-rendering: geometricprecision;
  white-space: nowrap;
`;

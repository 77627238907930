/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useWizard } from 'react-use-wizard';

import { ButtonPrimary, ButtonSecondary, Footer } from './style.wizard';

import { StepsContext } from './StepsWizard';

export const Step = React.forwardRef((props, ref) => {
  const {
    setShow,
    positionWrapper,
    position = 'left',
    margin = 20,
    actionsBefore,
    delay,
    viewAction = ['next', 'previous', 'close'],
    children,
  } = props;

  const [itemsRef] = React.useContext(StepsContext);

  const { previousStep, nextStep, isLastStep, isFirstStep, activeStep } =
    useWizard();

  React.useEffect(() => {
    let current = null;

    if (itemsRef.current) {
      [current] = itemsRef.current.filter(
        (i) => i.id === `target${activeStep + 1}`
      );

      if (current) {
        current.style.zIndex = '99999999';
        current.style.backgroundColor = '#ffffff';

        const parent = current.getBoundingClientRect();
        const { width, height } = ref.current.getBoundingClientRect();

        const pos = {
          left: {
            left: parent.left - (width + margin),
            top: parent.top - height / 2 + parent.height / 2,
          },
          right: {
            left: parent.left + (parent.width + margin),
            top: parent.top - height / 2 + parent.height / 2,
          },
          top: {
            left: parent.x,
            top: parent.top - (height + margin),
          },
          bottom: {
            left: parent.x,
            top: parent.top + parent.height + margin,
          },
        };

        positionWrapper({
          ...pos[position],
        });
      }
    }
    return () => {
      current.style.zIndex = '0';
      current.style.backgroundColor = 'transparent';
    };
  }, [activeStep]);

  return (
    <>
      {children}
      <Footer>
        {viewAction.includes('close') && (
          <ButtonSecondary
            bgColor="var(--finblue-secondary)"
            onClick={() => setShow(false)}
          >
            Fechar
          </ButtonSecondary>
        )}
        {viewAction.includes('previous') && (
          <ButtonPrimary
            bgColor="var(--finblue-primary)"
            onClick={() => previousStep()}
          >
            {`<< Anterior`}
          </ButtonPrimary>
        )}
        {viewAction.includes('next') && (
          <ButtonPrimary
            bgColor="var(--finblue-primary)"
            onClick={() => nextStep()}
          >
            {`Próximo >>`}
          </ButtonPrimary>
        )}
      </Footer>
    </>
  );
});

import React from 'react';

import { ReactComponent as IconHandbagNf } from 'assets/images/icon-handbag-nf.svg';
import { ReactComponent as IconServiceNf } from 'assets/images/icon-service-nf.svg';
import { ReactComponent as IconCardholderCredit } from 'assets/images/icon-cardholder-credit.svg';
import { ReactComponent as IconMoneyCash } from 'assets/images/icon-money-cash.svg';
import { useParams } from 'react-router-dom';
import { Flex } from './style.venda';

import { useTiposVendas } from '../hooks';

import { CardTypes } from '../Comps/CardTypes';
import { TitleTipoVenda } from '../Comps/TitleHead';

import { SkeletonLoading } from '../Comps/Skeleton';

const tipos = {
  1: { icon: <IconHandbagNf />, label: 'NF Produto' },
  2: { icon: <IconServiceNf />, label: 'NF Serviço' },
  3: { icon: <IconCardholderCredit />, label: 'Cartão de Crédito' },
  4: { icon: <IconMoneyCash />, label: 'À vista' },
};

export function TiposVendas() {
  const { idGrupo } = useParams();
  const { data, isLoading } = useTiposVendas({ idGrupo });
  return (
    <Flex column align="flex-start" style={{ marginBottom: '48px' }}>
      <TitleTipoVenda />
      <Flex
        justify="flex-start"
        style={{ marginTop: '13px', position: 'relative' }}
      >
        {isLoading && <SkeletonLoading />}
        {data &&
          !isLoading &&
          data.map((item) => {
            return (
              <CardTypes
                isActive={item.escolhido}
                key={item.idTipoNota}
                label={tipos[item.idTipoNota].label}
                icon={tipos[item.idTipoNota].icon}
              />
            );
          })}
      </Flex>
    </Flex>
  );
}

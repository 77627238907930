import React, { useContext, useCallback } from 'react';
import ReactTooltip from 'react-tooltip';

import { CheckboxFlat } from 'components/FinCheckboxCuston/CheckboxFlat';
import { Caption } from 'components/FinParagraph';
import { Td, Tr } from 'components/FinTable';
import { maskCNPJ, currency, date } from 'helpers/format';
import {
  WrapperCustom,
  IconeCoinStyle,
  IconeCoinOffStyle,
  IconeCalendarStyle,
  IconeCalendarOffStyle,
  IconeCircleDashStyle,
  IconeCheckStyle,
  IconeCircleDCheckStyle,
} from './style.bordero';

import { OperacoesContext } from '../provider';

export const Titulo = ({ data }) => {
  const [state, actions] = useContext(OperacoesContext);

  const {
    modal: { values },
    bordero: { selecionados },
  } = state;

  const tituloSelecionado = useCallback(() => {
    if (values.rules.indexOf('edit') !== -1) {
      const s = selecionados.map((s) => s.id);
      return s.indexOf(data.id) !== -1;
    }
    return data.selecionado;
  }, [data, selecionados, values]);

  const selecionaTitulo = useCallback(
    (event) => {
      const titulo = { id: data.id, valor: data.valorAlterado || data.valor };

      if (event.target.checked) {
        actions.setSelecao({ value: titulo });
      } else {
        const countRemaining = selecionados.length - 1;

        if (countRemaining < 1) {
          // eslint-disable-next-line no-console
          console.log('Pelo menos um titulo selecionado');
        } else {
          actions.removeSelecao({ value: titulo });
        }
      }
    },
    [actions, selecionados, data]
  );

  return (
    <Tr>
      <Td>
        <WrapperCustom largura="34px" justifyContent="center">
          <CheckboxFlat
            ariaLabel="Checkbox row"
            onChange={(e) => selecionaTitulo(e)}
            checked={tituloSelecionado()}
            disabled={values.rules.indexOf('view') !== -1}
            name={`item-row-checkbox-${data.id}`}
          />
        </WrapperCustom>
      </Td>
      <Td>
        <WrapperCustom>
          <Caption>{data.nf}</Caption>
        </WrapperCustom>
      </Td>
      <Td>
        <WrapperCustom>
          <Caption>{data.serie}</Caption>
        </WrapperCustom>
      </Td>
      <Td>
        <WrapperCustom>
          <Caption>{data.idDuplicata.split('-')[1]}</Caption>
        </WrapperCustom>
      </Td>
      <Td>
        <WrapperCustom>
          <Caption>{data.cfop}</Caption>
        </WrapperCustom>
      </Td>
      <Td>
        <WrapperCustom>
          <Caption>{data.prazo}</Caption>
        </WrapperCustom>
      </Td>
      <Td>
        <WrapperCustom>
          <Caption>{date(data.emissao)}</Caption>
        </WrapperCustom>
      </Td>
      <Td>
        <WrapperCustom>
          <Caption>{date(data.venctoAlterado || data.vencto)}</Caption>
        </WrapperCustom>
      </Td>
      <Td>
        <WrapperCustom>
          <Caption>{currency(data.valorAlterado || data.valor)}</Caption>
        </WrapperCustom>
      </Td>
      <Td>
        <WrapperCustom>
          <Caption>{maskCNPJ(data.cnpjEmissor)}</Caption>
        </WrapperCustom>
      </Td>
      <Td>
        <WrapperCustom>
          {!!data.valorAlterado && (
            <ReactTooltip
              id={`valor-alterado-${data.id}`}
              wrapper="span"
              place="top"
              type="dark"
              effect="solid"
            >
              <>
                <div>
                  <span>{`Valor Original: ${currency(data.valor)}`}</span>
                </div>
                <div>
                  <span>{`Valor Alterado: ${currency(
                    data.valorAlterado
                  )}`}</span>
                </div>
              </>
            </ReactTooltip>
          )}
          {!!data.venctoAlterado && (
            <ReactTooltip
              id={`vencimento-alterado-${data.id}`}
              wrapper="span"
              place="top"
              type="dark"
              effect="solid"
            >
              <>
                <div>
                  <span>{`Vencimento Original: ${date(data.vencto)}`}</span>
                </div>
                <div>
                  <span>{`Vencimento Alterado: ${date(
                    data.venctoAlterado
                  )}`}</span>
                </div>
              </>
            </ReactTooltip>
          )}
          {data.valorAlterado ? (
            <IconeCoinStyle data-tip data-for={`valor-alterado-${data.id}`} />
          ) : (
            <IconeCoinOffStyle />
          )}

          {data.venctoAlterado ? (
            <IconeCalendarStyle
              data-tip
              data-for={`vencimento-alterado-${data.id}`}
            />
          ) : (
            <IconeCalendarOffStyle />
          )}
        </WrapperCustom>
      </Td>
      <Td>
        <WrapperCustom>
          <ReactTooltip
            id={`pedido-confirmado-${data.id}`}
            wrapper="span"
            place="top"
            type="dark"
            effect="solid"
          >
            Pedido Confirmado
          </ReactTooltip>
          <ReactTooltip
            id={`titulo-confirmado-${data.id}`}
            wrapper="span"
            place="top"
            type="dark"
            effect="solid"
          >
            Aceite do título confirmado
          </ReactTooltip>
          {data.pedidoConfirmado ? (
            <IconeCheckStyle
              data-tip
              data-for={`pedido-confirmado-${data.id}`}
            />
          ) : (
            <IconeCircleDashStyle />
          )}

          {data.mercadoriaConfirmada ? (
            <IconeCircleDCheckStyle
              data-tip
              data-for={`titulo-confirmado-${data.id}`}
            />
          ) : (
            <IconeCircleDashStyle />
          )}
        </WrapperCustom>
      </Td>
    </Tr>
  );
};

/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  addAllItemsListDownload,
  clearAllItemsListDownload,
} from 'store/old_actions/actions';

import {
  WrapperRow,
  TextValue,
  SeparatorHorizontal,
  SeparatorVertical,
} from 'components/Elements';
import { Theme } from 'theme';

import CheckboxComp from 'components/CheckboxComp';

const DocHeader = (props) => {
  const [checked, setChecked] = useState(false);
  const aoSelecionarTitulo = () => {
    setChecked(!checked);
    if (checked) {
      props.clearAllItems();
    } else {
      const values = props.docs.map((res) => ({
        idDocumento: res.idDocumento,
        url: res.link,
      }));
      props.addAllItems(values);
    }
  };
  useEffect(() => {
    console.log(props.data);
    if (props.data.length === 0) setChecked(false);
    // else setChecked(true);
  }, [props.data]);
  return (
    <div>
      <WrapperRow lwidth="100%">
        <WrapperRow>
          <CheckboxComp
            checked={checked}
            aoSelecionar={() => aoSelecionarTitulo()}
            name="titulo"
            value={`header`}
            rounded="active"
          />
        </WrapperRow>
        <WrapperRow lwidth="260px" justifiy="flex-start">
          <TextValue size="14px" color="#1F1F1F">
            Documento
          </TextValue>
        </WrapperRow>
        <SeparatorVertical wsize="1rem" />
        <WrapperRow lwidth="260px" justifiy="flex-start">
          <TextValue size="14px" color="#1F1F1F">
            Última Atualização
          </TextValue>
        </WrapperRow>
        <SeparatorVertical wsize="1rem" />
        <WrapperRow lwidth="260px" justifiy="flex-start">
          <TextValue size="14px" color="#1F1F1F">
            Último Download
          </TextValue>
        </WrapperRow>
        {/* <WrapperRow lwidth="300px">
          <TextValue>Ação</TextValue>
        </WrapperRow> */}
      </WrapperRow>
      <SeparatorHorizontal borderColor={Theme.gray1} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.listDownload.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAllItems: (data) => dispatch(addAllItemsListDownload(data)),
    clearAllItems: () => dispatch(clearAllItemsListDownload()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocHeader);

import React from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components/macro';

import { MakeSvgUri, ATTRS } from './index';

const Svg = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
})`
  width: ${({ width }) => width || `${ATTRS.width}px`};
  height: ${({ height }) => height || `${ATTRS.height}px`};
  fill: ${({ fill }) => fill || ATTRS.fill};
  stroke: ${({ stroke }) => stroke || ATTRS.stroke};
  stroke-width: ${({ strokeWidth }) => strokeWidth || ATTRS.strokeWidth};
  stroke-linecap: ${({ strokeLinecap }) =>
    strokeLinecap || ATTRS.strokeLinecap};
  stroke-linejoin: ${({ strokeLinejoin }) =>
    strokeLinejoin || ATTRS.strokeLinejoin};
`;

export const StringToSvg = ({ className, viewBox, children, ...rest }) => {
  if (typeof children === 'string') {
    const svg = MakeSvgUri({
      data: children,
      viewBox,
      className,
      ...rest,
    });
    return <SVG src={svg} className={className} />;
  }
  return (
    <Svg viewBox={viewBox || ATTRS.viewBox} className={className} {...rest}>
      {children}
    </Svg>
  );
};

import styled from 'styled-components/macro';
import { Caption } from 'components/FinParagraph';
import faixa from 'assets/images/faixa-isento.png';

export const Text = styled.span`
  font-weight: 500;
  color: #000000;
  padding-top: 1rem;
  font-size: 1rem;
  line-height: 13px;
`;

export const FaixaIsento = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: url(${faixa}) no-repeat center center;
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.4));
  background-size: cover;
  width: 100%;
  height: 100%;
`;

export const CardPrecoStyle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 190px;
  height: 200px;
`;

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  width: ${({ largura }) => largura || '64px'};
  height: ${({ altura }) => altura || '64px'};
  padding: 0.8em;
  box-shadow: -1px 2px 10px -7px rgb(0 0 0 / 65%);
`;

export const Sifrao = styled.span`
  font-weight: 500;
  color: ${({ color }) => color && color};
  padding: 0 0.5rem 0 0;
  font-size: 1rem;
  line-height: 13px;
`;

export const FirstPartMoney = styled.span`
  font-weight: 600;
  color: ${({ color }) => color && color};
  font-size: 4rem;
  line-height: 50px;
  letter-spacing: -2px;
`;

export const SecondPartMoney = styled.span`
  font-weight: 500;
  color: ${({ color }) => color && color};
  padding: 0 0.2rem 0 0;
  font-size: 1rem;
  line-height: 13px;
`;

export const Line = styled.span`
  display: flex;
  align-items: flex-start;
`;

export const CardStyle = styled(Caption)`
  line-height: 14px;
  text-align: center;
`;

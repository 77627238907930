import React from 'react';

import { Caption } from 'components/FinParagraph';
import { currency } from 'helpers/format';
import { dotToComma } from 'components/Formatter';
import { CardRowStyle, CaptionCustom } from '../style';

import { LIST_SUB_TIPO_EMPRESTIMO } from '../../config';

export const TipoEmprestimo = ({ data }) => {
  return (
    <>
      <CardRowStyle>
        <Caption>Bruto Proposto</Caption>
        <CaptionCustom>{currency(data.semNota.valorBruto)}</CaptionCustom>
      </CardRowStyle>

      <CardRowStyle>
        <Caption>Total Parcelas</Caption>
        <CaptionCustom>
          {currency(data.semNota.valorTotalParcelas)}
        </CaptionCustom>
      </CardRowStyle>

      <CardRowStyle>
        <Caption>Tipo</Caption>
        <CaptionCustom>
          {
            LIST_SUB_TIPO_EMPRESTIMO.find(
              (item) => item.id === data.semNota.propostaSubTipo
            ).label
          }
        </CaptionCustom>
      </CardRowStyle>

      <CardRowStyle>
        <Caption>PMP (dias)</Caption>
        <CaptionCustom>{dotToComma(data.semNota.pmp.toFixed(2))}</CaptionCustom>
      </CardRowStyle>

      <CardRowStyle>
        <Caption>Taxa (%)</Caption>
        <CaptionCustom>{dotToComma(data.semNota.taxa)}</CaptionCustom>
      </CardRowStyle>

      {/* <CardRowStyle>
        <Caption>Float (dias)</Caption>
        <CaptionCustom>{data.semNota.vFloat}</CaptionCustom>
      </CardRowStyle> */}

      <CardRowStyle>
        <Caption>Acertos</Caption>
        <CaptionCustom>{currency(data.semNota.acertos)}</CaptionCustom>
      </CardRowStyle>
      <CardRowStyle>
        <Caption>Líquido</Caption>
        <CaptionCustom>{currency(data.semNota.liquido)}</CaptionCustom>
      </CardRowStyle>
    </>
  );
};

/* eslint-disable prettier/prettier */
import styled, { css, keyframes } from 'styled-components/macro';
import { darken } from 'polished';
import { Wrapper } from 'components/FinWrappers';

import { ReactComponent as IconProcess } from 'assets/images/icon-process.svg';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const IconProcessStyle = styled(IconProcess)`
  stroke: #008894;
  margin-right: 0.5rem;
  & path {
    stroke: #008894;
  }
  animation: ${rotate} 2s linear infinite;
`;

export const IconUpdateStyle = styled(IconProcess)`
  stroke: #fff;
  & path {
    stroke: #fff;
  }

  ${({ isloading }) =>
    isloading &&
    css`
      animation: ${rotate} 2s linear infinite;
    `}
`;

export const CardStyle = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  background-color: #fff;
  overflow: hidden;
  min-width: 1112px;
  min-height: 100px;
  margin-bottom: 1rem;
  transition: all 0.2s;

  &:hover {
    box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.29);
  }
`;

export const BorderLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background-color: #70e5ef;
`;

export const WrapperCaption = styled.div`
    display: 'flex'
    display: flex;
    align-items: flex-start;
    min-height: 34px;
   align-items: flex-start;
`;

export const ButtonWrapper = styled(Wrapper)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  justify-content: space-between;
  width: 83px;

  &:after {
    content: '';
    width: 40px;
    height: 4px;
    position: absolute;
    top: 20px;
    left: 62px;
    ${({ status }) =>
    status === 'isVisited' &&
    css`
        /* border: 2px solid #008894; */
        background-color: #008894;
      `}

    ${({ status }) =>
    (status === 'isActive' || status === 'isDisabled') &&
    css`
        /* border: 2px solid #e6e3e3; */
        background-color: #e6e3e3;
      `}

      ${({ borderHidden }) =>
    borderHidden &&
    css`
        /* border: 2px solid transparent; */
        background-color: transparent;
      `}
  }
`;

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.6);
	}

	70% {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
	}

	100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
`;

export const ButtonStyle = styled.button`
  position: absolute;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: none;
  outline: none;
  padding: 10px;
  transition: all 0.2s;

  ${({ status }) =>
    status === 'isVisited' &&
    css`
      background-color: #008894;
      cursor: pointer;
      & svg {
        stroke: #fff;
        & path {
          stroke: #fff;
        }
      }
      &:hover {
        background-color: ${darken(0.08, '#008894')};
      }
    `}

  ${({ status }) =>
    status === 'isActive' &&
    css`
      background-color: #70e5ef;
      cursor: pointer;

      animation: ${pulse} 2s infinite;
      &:hover {
        background-color: ${darken(0.08, '#70e5ef')};
      }
    `}

  ${({ status }) =>
    status === 'isDisabled' &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      background-color: #e6e3e3;
      & svg {
        stroke: #979797;
        & path {
          stroke: #979797;
        }
      }
    `}


  ${({ status }) =>
    (status === 'isCanceled' ||
      status === 'isGivenUp' ||
      status === 'isLost') &&
    css`
      cursor: pointer;
      background-color: #ffeeec;
      &:hover {
        background-color: ${darken(0.08, '#ffeeec')};
      }

      & svg {
        stroke: #ff4d35;
        & path {
          stroke: #ff4d35;
        }
      }
    `}
`;

export const ButtonRoundedStyle = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ size }) => css`
    width: ${size};
    height: ${size};
  `}

  border-radius: 50%;
  border: none;
  outline: none;
  padding: 5px;
  cursor: pointer;
  transition: all 0.2s;
  z-index: 99;

  ${({ positionButton }) =>
    positionButton.map(
      (item) =>
        css`
          ${item}
        `
    )}

  ${({ bgColor }) => css`
    background-color: ${bgColor};
  `}

  ${({ hoverColor, bgColor }) => css`
    &:hover {
      background-color: ${darken(0.08, bgColor)};
      & svg {
        stroke: ${hoverColor};
        & path {
          stroke: ${hoverColor};
        }
      }
    }
  `}
`;

export const TagStatusStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  height: 40px;
  border-radius: 50px;
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  color: #000;
  font-size: 0.9rem;
`;

export const NotifyItem = styled.div`
  position: absolute;
  top: 0;
  left: 33px;
  width: 14px;
  height: 14px;
  border-radius: 10vw;
  border: 2px solid #ffffff;
  background-color: #F7B500;
`;

/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { createActions, createReducer } from 'reduxsauce';

/**
 * Criando action types & creators
 */

export const { Types, Creators } = createActions({
  save: ['data', 'tipo'],
  remove: ['idPregao', 'tipo'],
  clear: null,
});

/**
 * Criando os reducer handlers
 */

const INITIAL_STATE = {
  notifications: [],
};

const save = (state = INITIAL_STATE, action) => {
  console.log('[DEBUG]', 'action-save', action);

  switch (action.tipo) {
    case 'proposta':
      return (() => {
        const found = state.notifications.filter(
          (item) => item.idPregao === action.data.idPregao
        );
        if (found.length > 0) {
          return {
            notifications: state.notifications,
          };
        }
        const added = [...state.notifications, action.data];
        return {
          notifications: added,
        };
      })();
    default:
      return {
        notifications: state.notifications,
      };
  }
};

const remove = (state = INITIAL_STATE, action) => {
  console.log('[DEBUG]', 'action-remove', action);

  switch (action.tipo) {
    case 'proposta':
      return (() => {
        const removed = state.notifications.filter(
          (item) => item.idPregao !== action.idPregao
        );
        return {
          notifications: removed,
        };
      })();
    default:
      return {
        notifications: state.notifications,
      };
  }
};

const clear = (state = INITIAL_STATE, action) => {
  console.log('[DEBUG]', 'action-clear', action);

  return {
    notifications: state.notifications,
  };
};

/**
 * Criando reducer
 */

export default createReducer(INITIAL_STATE, {
  [Types.SAVE]: save,
  [Types.REMOVE]: remove,
  [Types.CLEAR]: clear,
});

import { createStitches, keyframes } from '@stitches/react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { darken } from 'polished';

const slideDown = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-10px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const { styled } = createStitches({
  utils: {
    icon: (value) => ({
      background: `url(${value}) no-repeat center left`,
    }),
  },
});

export const Popover = PopoverPrimitive.Root;

export const PopoverTriggerTransparent = styled(PopoverPrimitive.Trigger, {
  position: 'relative',
  padding: 0,
  border: 'none',
  cursor: 'pointer',
  backgroundColor: 'transparent',
});

export const PopoverTriggerButtonFloat = styled(PopoverPrimitive.Trigger, {
  fontFamily: 'Inter',
  borderRadius: '10px',
  height: '40px',
  fontSize: '0.9rem',
  fontWeight: 'normal',
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  '&:hover': { backgroundColor: darken(0.1, '#f2f2f3') },
  padding: '0 6px',
  position: 'absolute',
  right: '4px',
  top: '4px',
  width: '40px',
  zIndex: '2',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const PopoverTrigger = styled(PopoverPrimitive.Trigger, {
  fontFamily: 'Inter',
  borderRadius: '10px',
  height: '40px',
  fontSize: '0.9rem',
  fontWeight: 'normal',
  backgroundColor: 'var(--fingray-300)',
  border: 'none',
  cursor: 'pointer',
  '&:hover': { backgroundColor: darken(0.1, '#f2f2f3') },
  padding: '0 24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const PopoverTriggerFiliais = styled(PopoverPrimitive.Trigger, {
  fontFamily: 'Inter',
  borderRadius: '10px',
  width: '100%',
  height: '68px',
  fontSize: '0.9rem',
  fontWeight: 'normal',
  backgroundColor: 'var(--fingray-300)',
  border: 'none',
  cursor: 'pointer',
  '&:hover': { backgroundColor: darken(0.1, '#f2f2f3') },
  padding: '0 24px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const PopoverAnchor = styled(PopoverPrimitive.Anchor, {});

export const PopoverContent = styled(PopoverPrimitive.Content, {
  animationDuration: '0.6s',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  '&[data-side="bottom"]': { animationName: slideDown },
  borderRadius: '17px',
  backgroundColor: '#fff',
  border: '1px solid #ECECEC',
  boxShadow: '0px 3px 4px 3px rgba(0, 0, 0, 0.05)',
  padding: '1rem',
  zIndex: '999',
});

import { useQuery } from 'react-query';
import axios from 'axios';
import { getUserData } from 'services/getUserData';
import CONFIG from 'config';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const fetchConvitesEnviados = async (params) => {
  const { idEmpresa } = await getUserData();

  if (idEmpresa) {
    const payload = {
      size: params.size || 5,
      skip: params.skip || 1,
      filter: {
        idConvenio: idEmpresa,
      },
    };

    const fetchData = axios.post(
      `${BASE_URL}/matching/conexao/financiador/pendentes/enviados`,
      payload
    );

    const result = await fetchData;
    const { data } = result;

    return data || [];
  }

  return [];
};

export const useFetchConvitesEnviados = (params) => {
  return useQuery(
    ['convites', 'enviados', params],
    () => fetchConvitesEnviados(params),
    {
      keepPreviousData: false,
      staleTime: 1000,
    }
  );
};

import React from 'react';

const Radio = props => {
  return (
    <div className="control">
      <label className="radio">
        <input
          type="radio"
          name="answer"
          onChange={e => props.action('CHANGE_OPTION_CEDENTE', props.id)}
        />
      </label>
    </div>
  );
};

export default Radio;

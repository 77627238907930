import React from 'react';
import { Box, Label, RegionInfo, RegionAction } from './style.invite';
import { NWButton } from '../Comps/Button';

import { routesPages } from '../config';

export const EmptyInvite = ({ gotoPage }) => {
  return (
    <Box>
      <RegionInfo>
        <Label>Nenhum convite pendente</Label>
      </RegionInfo>
      <RegionAction>
        <NWButton.Secondary onClick={() => gotoPage(routesPages.convite)}>
          Gerenciar Convites
        </NWButton.Secondary>
      </RegionAction>
    </Box>
  );
};

import styled from 'styled-components/macro';

export const Carousel = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
`;

export const Inner = styled.div`
  transform: translateX(${({ translateX }) => translateX});
  white-space: nowrap;
  transition: transform 0.3s;
`;

export const CarouselItem = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 210px;
  background-color: #ffffff;
  color: #ffffff;
  width: ${({ width }) => width};
`;

export const ButtonControl = styled.button`
  outline: none;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: background 0.3s;
  cursor: pointer;
  &:hover {
    background: #dedede;
  }
`;

import React from 'react';
import { useSelector } from 'react-redux';

import DialogWithSelect from './DialogWithSelect';
import DialogDefault from './DialogDefault';
import DialogHabilitacao from './DialogHabilitacao';
import DialogDesistirOperacao from './DialogDesistirOperacao';
import DialogResumo from './DialogResumo';
import DialogParticiparOperacao from './DialogParticiparOperacao';
import DialogEnviarProposta from './DialogEnviarProposta';

const Dialog = () => {
  const uiDialogState = useSelector((state) => state.uiDialog);

  const renderContent = () => {
    switch (uiDialogState.tipo) {
      case 'recusarConvite':
        return <DialogWithSelect />;
      case 'habilitar':
        return <DialogHabilitacao />;
      case 'desistirOperacao':
        return <DialogDesistirOperacao />;
      case 'resumoOperacao':
        return <DialogResumo />;
      case 'participarOperacao':
        return <DialogParticiparOperacao />;
      case 'enviarProposta':
        return <DialogEnviarProposta />;
      default:
        return <DialogDefault />;
    }
  };

  return uiDialogState.show && renderContent();
};

export default Dialog;

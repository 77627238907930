/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { createActions, createReducer } from 'reduxsauce';
import {
  call,
  put,
  take,
  fork,
  cancel,
  cancelled,
  select,
} from 'redux-saga/effects';
import axios from 'axios';

import { getUserData } from 'services/getUserData';

import { Creators as uiToasterActions } from 'store/ducks/uiToaster';
import { Creators as apiConvitesRecebidosActions } from 'store/ducks/apiConvitesRecebidos';
import { Creators as apiListClientesActions } from 'store/ducks/apiListClientes';
import { CreatorsUiMessages } from 'store/ducks/uiMessages';

import {
  Creators as uiDialogActions,
  Types as uiDialogsTypes,
} from 'store/ducks/uiDialog';

import CONFIG from '../../config';

const { CancelToken } = axios;

/**
 * Criando action types & creators
 */

export const { Types, Creators } = createActions({
  apiAceitarRequest: ['idConvite', 'idCedente'],
  apiAceitarSuccess: ['data'],
  apiAceitarError: [],
  apiAceitarCancel: [],
});

/**
 * Criando os reducer handlers
 */

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: false,
};

const apiSetRequest = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: true,
    data: [],
    error: false,
  };
};

const apiSetSuccess = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: action.data,
    error: false,
  };
};

const apiSetError = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: [],
    error: true,
  };
};

const apiSetCancel = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: [],
    error: false,
  };
};

/**
 * Criando reducer
 */

export default createReducer(INITIAL_STATE, {
  [Types.API_ACEITAR_REQUEST]: apiSetRequest,
  [Types.API_ACEITAR_SUCCESS]: apiSetSuccess,
  [Types.API_ACEITAR_ERROR]: apiSetError,
  [Types.API_ACEITAR_CANCEL]: apiSetCancel,
});

/**
 * Sagas
 */

function* sagaGetAsynResult(params, endpoint, idCedente) {
  const source = CancelToken.source();
  const url = `${CONFIG.API_REMODELING_URL}${endpoint}/${params.idConexao}`;
  try {
    const response = yield axios.put(url, {
      cancelToken: source.token,
    });

    if (response.data.isSuccessStatusCode) {
      yield put(Creators.apiAceitarSuccess(response.data.data));

      const stateRouter = (state) => state.router;
      const routes = yield select(stateRouter);

      if (routes.location.pathname.indexOf('detalhe') !== -1) {
        yield put(CreatorsUiMessages.uiSendMessage('accepted_sucessfully'));
      }

      console.log(response.data.data);

      yield put(
        apiConvitesRecebidosActions.apiConvitesRecebidosAcceptSent({
          ready: true,
          id: params.idConexao,
        })
      );

      if (idCedente) {
        yield put(
          apiListClientesActions.apiListClientesAcceptInviteSent({
            ready: true,
            id: idCedente,
          })
        );
      }

      yield put(
        uiToasterActions.uiToasterMessage(
          { message: 'Aceito com sucesso!!' },
          'success'
        )
      );
    } else {
      yield put(
        apiConvitesRecebidosActions.apiConvitesRecebidosAcceptSent({
          remove: true,
          id: params.idConexao,
        })
      );
      if (idCedente) {
        yield put(
          apiListClientesActions.apiListClientesAcceptInviteSent({
            remove: true,
            id: idCedente,
          })
        );
      }

      yield put(
        uiDialogActions.uiDialogOpen(
          [
            {
              title: 'Atenção',
              text: response.data.data,
              labelConfirme: 'OK',
            },
          ],
          {
            type: uiDialogsTypes.UI_DIALOG_CLOSE,
          },
          false
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      apiConvitesRecebidosActions.apiConvitesRecebidosAcceptSent({
        remove: true,
        id: params.idConexao,
      })
    );
    if (idCedente) {
      yield put(
        apiListClientesActions.apiListClientesAcceptInviteSent({
          remove: true,
          id: idCedente,
        })
      );
    }
    yield put(uiToasterActions.uiToasterMessage(error, 'error'));
    yield put(Creators.apiAceitarError());
  } finally {
    if (yield cancelled()) {
      console.log('cancel');
      source.cancel();
    }
  }
}

export function* sagaAceitar(action) {
  const user = yield call(getUserData);

  console.log(action);

  if (user) {
    // const params = {
    //   idEmpresa: user.idEmpresa,
    //   idPessoa: user.idUsuario,
    //   idConvite: action.idConvite,
    // };

    const params = {
      idConexao: action.idConvite,
    };

    yield put(
      apiConvitesRecebidosActions.apiConvitesRecebidosAcceptSent({
        ready: false,
        id: params.idConexao,
      })
    );

    if (action.idCedente) {
      yield put(
        apiListClientesActions.apiListClientesAcceptInviteSent({
          ready: false,
          id: action.idCedente,
        })
      );
    }

    const task = yield fork(
      sagaGetAsynResult,
      params,
      '/business-api/api/matching/conexao/aceitar',
      action.idCedente
    );

    yield take(Types.API_ACEITAR_CANCEL);
    yield cancel(task);
  } else {
    yield put(Creators.apiAceitarError());
  }
}

import React, { createContext } from 'react';

import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

import { Page404 } from 'pages/FinCredit/Page404';

import {
  FullSize,
  Container,
  TitlePage,
  IconFrame,
  WrapperTitle,
} from './style.layout';

import { MinhasConexoes } from './MinhasConexoes';
import { Bloqueados } from './Bloqueados';
import { Convites } from './Convites';
import { Clientes } from './Clientes';
import { Inicio } from './Inicio';

import { ModalFiltros } from './ModalFiltros';
import { iconsTitle } from './config';

import { HeaderFilters } from './Comps/Header';

export const MinhaRedeContext = createContext();

export function PaginaMinhaRede() {
  const [openFilters, setOpenFilters] = React.useState(false);

  const refScroll = React.useRef();

  const executeScroll = () => refScroll.current.scrollIntoView();

  const location = useLocation();

  return (
    <MinhaRedeContext.Provider value={{ openFilters, setOpenFilters }}>
      <FullSize ref={refScroll}>
        {openFilters && <ModalFiltros />}
        <Container>
          <WrapperTitle>
            <IconFrame>{iconsTitle[location.pathname].icon}</IconFrame>
            <TitlePage>{iconsTitle[location.pathname].label}</TitlePage>
          </WrapperTitle>
          <HeaderFilters />
          <Switch>
            <Route exact path="/minha-rede">
              <Redirect to="/minha-rede/inicio" />
            </Route>
            <Route
              path="/minha-rede/inicio"
              render={(props) => <Inicio {...props} />}
            />
            <Route
              path="/minha-rede/conexoes"
              render={(props) => (
                <MinhasConexoes executeScroll={executeScroll} {...props} />
              )}
            />
            <Route
              path="/minha-rede/convites"
              render={(props) => (
                <Convites executeScroll={executeScroll} {...props} />
              )}
            />
            <Route
              path="/minha-rede/clientes"
              render={(props) => (
                <Clientes executeScroll={executeScroll} {...props} />
              )}
            />
            <Route
              path="/minha-rede/bloqueados"
              render={(props) => (
                <Bloqueados executeScroll={executeScroll} {...props} />
              )}
            />
            <Route path="" render={(props) => <Page404 {...props} />} />
          </Switch>
        </Container>
      </FullSize>
    </MinhaRedeContext.Provider>
  );
}

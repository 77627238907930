import React from 'react';

import { Ring } from '@uiball/loaders';

export const Spinner = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '0',
        right: '0',
        backgroundColor: 'transparent',
        widht: '28px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        zIndex: '9',
      }}
    >
      <Ring size={20} lineWeight={7} speed={2} color="#008894" />
    </div>
  );
};

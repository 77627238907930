import React from 'react';

import { Wrapper } from 'components/FinWrappers';

import Geral from './Geral';
import Endereco from './Endereco';
import Contato from './Contato';
import Documentos from './Documentos';
import { PaginaDataCharts } from './Dashboard';

const Content = ({ tabActive, idEmpreendedor, data, refetch }) => {
  const RenderContent = () => {
    switch (tabActive) {
      case 'geral':
        return <Geral data={data} />;
      case 'endereco':
        return <Endereco data={data} />;
      case 'contatos':
        return <Contato data={data} />;
      case 'documentos':
        return <Documentos data={data} refetch={refetch} />;
      case 'dashboard':
        return <PaginaDataCharts idEmpreendedor={idEmpreendedor} />;
      default:
        return <Geral data={data} />;
    }
  };

  return (
    <Wrapper padding="0 74px 70px" tipo="column" pwidth="100%">
      <RenderContent />
    </Wrapper>
  );
};

export default Content;

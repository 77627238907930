import React from 'react';

import { Wizard } from 'react-use-wizard';
import { getItem, setItem } from 'helpers/armazenamento';
import formatDistance from 'date-fns/formatDistance';
import { Wrapper } from './Wrapper';

import { StepsContext } from './StepsWizard';

const periodo = ['year', 'years'];

const START_DATE = '2023-01-20T13:06:02.910Z';
const LIMITE = 3;

export function RenderSteps({ steps }) {
  const [show, setShow] = React.useState(true);
  const [position, setPosition] = React.useState(null);

  const refWrapper = React.useRef();

  if (!Array.isArray(steps)) {
    throw new Error('Parameter is not a array!');
  }

  React.useEffect(() => {
    (() => {
      const data = getItem('view-wizard');

      if (data) {
        return setShow(false);
      }

      const result = formatDistance(new Date(START_DATE), new Date());
      const found = (value) => periodo.some((item) => value.includes(item));

      if (found(result)) {
        return setShow(false);
      }

      if (result) {
        const months = result.split(' ');

        if (months[1] === 'months' && Number(months[0]) >= LIMITE) {
          return setShow(false);
        }
      }

      return setItem('view-wizard', JSON.stringify({ viewIn: new Date() }));
    })();
  }, []);

  return (
    <Wizard
      startIndex={0}
      wrapper={<Wrapper show={show} ref={refWrapper} position={position} />}
    >
      {steps.map((child) => {
        return React.cloneElement(child, {
          setShow,
          show,
          ref: refWrapper,
          positionWrapper: setPosition,
        });
      })}
    </Wizard>
  );
}

export function TargetStep({ id, children, style }) {
  const [, addToRefs] = React.useContext(StepsContext);

  return (
    <div
      ref={addToRefs}
      id={id}
      style={{
        position: 'relative',
        borderRadius: '6px',
        overflow: 'hidden',
        ...style,
      }}
    >
      {children}
    </div>
  );
}

import React from 'react';
import './style.scss';
//import { Theme } from '../../theme';

import {
  //BtnIcoAction,
  //Plus,
  //Wrapper,
  //Send,
  //X,
  SeparatorVertical,
} from '../../components/Elements';

import { Button } from '../../components/Button';
import { H3 } from '../../components/Headings';

const Modal = props => {
  return (
    <div className="modal generic-md">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <H3>{props.title}</H3>
          <button
            style={{
              position: 'absolute',
              right: '5px',
              top: '5px',
            }}
            className="delete"
            aria-label="close"
            onClick={() => {
              props.action('CLOSE');
            }}
          ></button>
        </header>
        <section className="modal-card-body">{props.children}</section>
        <footer className="modal-card-foot">
          {!props.edit && (
            <Button
              largura="160px"
              type="primary"
              onClick={() => props.action('ADD')}
            >
              Adicionar
            </Button>
          )}
          {props.edit && (
            <Button
              largura="160px"
              type="primary"
              onClick={() => props.action('SAVE')}
            >
              Enviar
            </Button>
          )}
          <SeparatorVertical wsize="1rem" />
          {props.edit && (
            <Button
              largura="160px"
              type="gray3"
              onClick={() => props.action('CANCEL')}
            >
              Cancelar
            </Button>
          )}
        </footer>
      </div>
    </div>
  );
};

export default Modal;

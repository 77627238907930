import React from 'react';
import ApprovedCredit from 'assets/icones/icone-money-step.svg';
import CompletedStep from 'assets/icones/icone-completed-step.svg';

import { Step } from './Step';

export const CreditoAprovado = () => {
  return (
    <Step
      bgcolor="#D4FFDF"
      icon={ApprovedCredit}
      statuicon={CompletedStep}
      label="Crédito"
      labelsecundaria="Aprovado"
    />
  );
};

import React, { useRef, useEffect } from 'react';

import { TaxInput } from './tableStyles';

export function InputTax({ value, setUpdateTax, data, disabled }) {
  const [tax, setTax] = React.useState(value);

  const refInput = useRef();

  const callUpdate = () => {
    setUpdateTax(tax, data.idItemAgenda);
  };

  const modifyEnterKeyPressAsTab = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      refInput.current.theInput.blur();
      e.preventDefault();
    }
  };

  useEffect(() => {
    setTax(value);
  }, [value]);

  return (
    <TaxInput
      onKeyDown={modifyEnterKeyPressAsTab}
      ref={refInput}
      onBlur={() => callUpdate()}
      largura={'72px'}
      aligntext="center"
      tabIndex="-1"
      value={tax}
      onChangeEvent={(event, maskedvalue, floatvalue) => setTax(floatvalue)}
      name="taxa"
      prefix=""
      decimalSeparator=","
      thousandSeparator=""
      disabled={disabled}
    />
  );
}

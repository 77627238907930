const INITIAL_STATE = {
  messageData: 'MESSAGE_DEFAULT',
};

const messagesComps = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SEND_MESSAGE':
      console.log(action);
      return {
        ...state,
        messageData: action.data,
      };
    default:
      return state;
  }
};

export default messagesComps;

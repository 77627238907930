/* eslint-disable no-nested-ternary */
import React from 'react';

import {
  PopoverTrigger,
  Popover,
  Option,
  PriorityId,
  PriorityName,
  ArrowDown,
} from './priorityStyles';
import { PopoverContent } from './Popover';

export const MassPriorityPopover = ({
  priorityVars,
  prioritySelect,
  setPrioritySelect,
}) => {
  const [popoverOpen, setPopoverOpen] = React.useState(false);

  const labels = {
    0: '0 (MÁXIMA)',
    1: '1 (MUITO ALTA)',
    2: '2 (ALTA) ',
    3: '3 (PADRÃO)',
    4: 'Selecionar',
  };

  return (
    <Popover open={popoverOpen}>
      <PopoverTrigger
        priority={priorityVars[prioritySelect]}
        onClick={() => setPopoverOpen(true)}
      >
        {labels[prioritySelect]}
        <ArrowDown />
      </PopoverTrigger>
      <PopoverContent onPointerDownOutside={() => setPopoverOpen(false)}>
        <Option
          data-priority="0"
          priority={prioritySelect === 0 ? 'max' : ''}
          onClick={() => {
            setPopoverOpen(false);
            setPrioritySelect(0);
          }}
        >
          <PriorityId>0</PriorityId>
          <PriorityName>(MÁXIMA)</PriorityName>
        </Option>
        <Option
          data-priority="1"
          priority={prioritySelect === 1 ? 'higher' : ''}
          onClick={() => {
            setPopoverOpen(false);
            setPrioritySelect(1);
          }}
        >
          <PriorityId>1</PriorityId>
          <PriorityName>(MUITO ALTA)</PriorityName>
        </Option>
        <Option
          data-priority="2"
          priority={prioritySelect === 2 ? 'high' : ''}
          onClick={() => {
            setPopoverOpen(false);
            setPrioritySelect(2);
          }}
        >
          <PriorityId>2</PriorityId>
          <PriorityName>(ALTA)</PriorityName>
        </Option>
        <Option
          data-priority="3"
          priority={prioritySelect === 3 ? 'standard' : ''}
          onClick={() => {
            setPopoverOpen(false);
            setPrioritySelect(3);
          }}
        >
          <PriorityId>3</PriorityId>
          <PriorityName>(PADRÃO)</PriorityName>
        </Option>
      </PopoverContent>
    </Popover>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from 'react';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import { Wrapper } from 'components/FinWrappers';
import { Box, LineLeft, Bullet } from 'components/FinFrame';
import UserInfo from 'components/FinUserInfo';
import { rgba } from 'polished';
import { finTheme } from 'finTheme';
import BlurryText from 'components/FinBlurryText';
import { Ellipsis } from 'helpers/string';
import MoreButton from 'components/FinDotButton';

import { Creators as uiDialogActions } from 'store/ducks/uiDialog';
import { Types as TypesBloquear } from 'store/ducks/apiBloquear';
import { useActions } from 'hooks/useActions';

import { SubTitle1 } from 'components/FinSubTitles';
import { MenuBloquear } from 'components/FinMenu';
import { maskCNPJ } from 'utils/formatValues';

import { TagPerfil, TagDocumento, TagLoadingSmall } from 'components/FinTag';

import Button from 'components/FinButton';
import { icons } from 'assets/icones/finplaceIcons';
import { StringToSvg } from 'helpers/svgFiles/StringToSvg';

import ButtonsChange from './ButtonsChange';
import TagActive from './TagActive';

// import CONFIG from '../../config';

import { checkStatus, checkStatusConexao } from './CheckStatusConexao';

// const idsCbbox = window.config.CBBOX_EMPREENDEDORES.split(' ').map(Number);
// const idsCbboxFinanciadores = CONFIG.CBBOX_FINANCIADORES.split(' ').map(Number);

export const ItemLink = styled(NavLink)`
  padding: 0;
  white-space: nowrap;
  background-color: initial;
  border: 0;
  transition: border-bottom-color 0.36s ease-in;
  text-decoration: none;
  width: 100%;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
      cursor: auto;
    `}
`;

const BoxWrapper = styled(Box)`
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0 0 1rem 0;
  overflow-y: initial;
  overflow: initial;
  cursor: pointer;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      box-shadow: none;
      border-radius: 0;
      cursor: auto;
      &:hover {
        box-shadow: none;
      }

      & ${ItemLink} {
        cursor: auto;
      }
    `}
`;

const WrapperBtn = styled(Wrapper)`
  padding: 0;
  min-width: 10px;
`;

const ItemList = ({ isDisabledLink, data }) => {
  const [dropdown, setDropdown] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const uiDialog = useActions(uiDialogActions);

  const uiDialogOpen = useCallback(
    (tipo, dispatch) => {
      uiDialog.uiDialogOpen(tipo, dispatch);
    },
    [uiDialog]
  );

  const onClickCuston = useCallback(
    (event) => {
      if (isDisabledLink) {
        event.preventDefault();
      }
    },
    [isDisabledLink]
  );

  const closeDropdown = () => {
    setDropdown(false);
  };

  const changeClick = useCallback(
    (e) => {
      e.preventDefault();
      setDropdown(!dropdown);
    },
    [dropdown]
  );

  const checkHabilitacao = useCallback(() => {
    if (data.localChangedMessage !== undefined) {
      if (
        data.localChangedMessage === 'habilitando' ||
        data.localChangedMessage === 'desabilitando'
      ) {
        if (!data.localChangedReady) {
          return true;
        }
      }
    }
    return false;
  }, [data]);

  const VerificaCbbox = () => {
    // if (idsCbboxFinanciadores.indexOf(idEmpresa) >= 0) {
    return (
      <Button
        tipo="primary"
        preenchimento="outline"
        onClick={(e) => openCbbox(e)}
      >
        <StringToSvg
          viewBox="0 -6 200 60"
          width="100px"
          height="50px"
          strokeWidth="1"
          stroke={finTheme.newtheme.color.primary}
        >
          {icons.finmatch}
        </StringToSvg>
        &nbsp; Finmatch
      </Button>
    );
    // }
    // return null;
  };

  const openCbbox = useCallback((e) => {
    e.preventDefault();
    history.push({
      pathname: `/finmatch/${data.idGrupo}`,
      state: { background: location },
    });
  }, []);

  return (
    <BoxWrapper isDisabled={isDisabledLink ? 1 : 0}>
      <LineLeft tipo={isDisabledLink ? 'hidden' : checkStatus(data).tipo} />
      <ItemLink
        exact
        onClick={onClickCuston}
        to={{
          pathname: `/clientes/${data.idGrupo}/detalhe`,
          state: { background: location },
        }}
      >
        <Wrapper
          pwidth="100%"
          justifyContent="space-between"
          padding="1rem 1rem 1rem 1.5rem"
        >
          <Wrapper padding="0">
            <UserInfo
              name={data.nome}
              image={null}
              pbgColor={rgba(finTheme.newtheme.color.cinza2, 0.5)}
              colorText={finTheme.newtheme.color.azulPrimario}
              size="medium"
              viewUserOnline
              online={data.online ? 1 : 0}
              infoDisabled
            />
            <Wrapper
              tipo="column"
              padding="0"
              alignItems="flex-start"
              pmargin="0 2rem"
            >
              <Wrapper padding="0" alignItems="flex-start">
                <ReactTooltip
                  id={`info-name-financiador-${data.id}`}
                  wrapper="span"
                  place="right"
                  type="dark"
                  effect="solid"
                >
                  {data.nome}
                </ReactTooltip>

                <SubTitle1
                  data-tip
                  data-for={`info-name-financiador-${data.id}`}
                  pweight="600"
                >
                  <Ellipsis pwidth="200px">
                    <BlurryText blurry={false}>{data.nome}</BlurryText>
                  </Ellipsis>
                </SubTitle1>
                <SubTitle1
                  style={{
                    color: finTheme.newtheme.color.cinza4,
                    marginLeft: '1rem',
                  }}
                >
                  <BlurryText blurry={data.criptografia}>
                    {data.criptografia ? data.cnpj : maskCNPJ(data.cnpj)}
                  </BlurryText>
                </SubTitle1>
              </Wrapper>
              <Wrapper padding="0">
                <TagDocumento active={data.documento} id={data.id} />
                <Bullet />
                <TagPerfil active={data.perfil} id={data.id} />
                {checkHabilitacao() ? (
                  <TagLoadingSmall bgcolor={finTheme.newtheme.color.success}>
                    Aguarde...
                  </TagLoadingSmall>
                ) : (
                  <TagActive status={checkStatus(data).name} id={data.id} />
                )}
              </Wrapper>
            </Wrapper>
          </Wrapper>
          <Wrapper padding="1rem 0 1rem">
            {checkStatusConexao(data) !== 'bloqueado' && VerificaCbbox()}
            &nbsp;&nbsp;
            <ButtonsChange
              tipo={checkStatusConexao(data)}
              online={data.online}
              id={data.idGrupo}
              idConvite={data.idconexao}
              statusHabilitado={data.statusHabilitado}
            />
            <WrapperBtn>
              {checkStatusConexao(data) === 'não-conectado' && (
                <MoreButton tipo="vertical" onclick={changeClick}>
                  <MenuBloquear
                    open={dropdown}
                    close={closeDropdown}
                    onclick={() =>
                      uiDialogOpen('bloqueio', {
                        type: TypesBloquear.API_BLOQUEAR_REQUEST,
                        idCedente: data.idGrupo,
                      })
                    }
                  />
                </MoreButton>
              )}
            </WrapperBtn>
          </Wrapper>
        </Wrapper>
      </ItemLink>
    </BoxWrapper>
  );
};

ItemList.propTypes = {
  isDisabledLink: PropTypes.bool,
  tipo: PropTypes.string,
};

export default ItemList;

import React from 'react';
import styled from 'styled-components/macro';

const Tfooter = styled.tfoot`
  border-bottom: 1px solid transparent;
`;
const Tr = styled.tr`
  background-color: transparent;
`;

const Td = styled.th`
  padding: 0.8rem 0;
  text-transform: uppercase;
  font-weight: 600;
`;

const Footer = ({ data, footPosition, footContent }) => {
  const renderContent = () => {
    const item = data[0];
    let caption = [];
    if (data.length > 0) {
      Object.keys(item).forEach((res, index) =>
        caption.push(
          <Td key={index}>{index === footPosition && footContent}</Td>
        )
      );
    }
    return caption;
  };
  return (
    <Tfooter>
      <Tr>{renderContent()}</Tr>
    </Tfooter>
  );
};

export default Footer;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { finTheme } from 'finTheme';
import { StringToSvg } from 'helpers/svgFiles/StringToSvg';
import feather from 'feather-icons/dist/icons.json';
import Button from 'components/FinButton';
import { SeparatorVertical } from 'components/FinSeparador';

import { Creators as uiDialogActions } from 'store/ducks/uiDialog';

import { useActions } from 'hooks/useActions';
import { TagInviteAccepted, TagLoadingInfo } from 'components/FinTag';

import { useMutateConviteAceite } from '../mutates/useMutateConviteAceite';
import { useMutateConviteCancelar } from '../mutates/useMutateConviteCancelar';
import { useMutateConviteRecusa } from '../mutates/useMutateConviteRecusa';

const ButtonAccept = ({ onAccept }) => (
  <Button
    tipo="primary"
    size={finTheme.newtheme.buttons.sizes.smallM}
    onClick={onAccept}
  >
    <StringToSvg
      viewBox="0 -2 30 30"
      width="23"
      height="23"
      stroke={finTheme.newtheme.color.branco}
    >
      {feather.check}
    </StringToSvg>
    &nbsp;&nbsp;Aceitar
  </Button>
);

const ButtonRefuse = ({ onRefuse }) => (
  <Button
    tipo="primary"
    preenchimento="outline"
    size={finTheme.newtheme.buttons.sizes.smallM}
    onClick={onRefuse}
  >
    <StringToSvg
      viewBox="0 -2 30 30"
      width="23"
      height="23"
      stroke={finTheme.newtheme.color.azulPrimario}
    >
      {feather.x}
    </StringToSvg>
    &nbsp;&nbsp;Recusar
  </Button>
);

const ButtonRemove = ({ onRemove }) => (
  <Button
    tipo="primary"
    preenchimento="outline"
    size={finTheme.newtheme.buttons.sizes.smallM}
    onClick={onRemove}
  >
    <StringToSvg
      viewBox="0 -2 30 30"
      width="23"
      height="23"
      stroke={finTheme.newtheme.color.azulPrimario}
    >
      {feather.x}
    </StringToSvg>
    &nbsp;&nbsp;Remover
  </Button>
);

const Buttons = ({ tipo, idConvite }) => {
  const uiDialog = useActions(uiDialogActions);

  const aceitar = useMutateConviteAceite();
  const cancelar = useMutateConviteCancelar();
  const recusar = useMutateConviteRecusa();

  const uiDialogOpen = useCallback(
    (e, tipo, dispatch) => {
      e.preventDefault();
      uiDialog.uiDialogOpen(tipo, dispatch);
    },
    [uiDialog]
  );
  switch (tipo) {
    case 'enviados':
      return (
        <ButtonRemove
          onRemove={(e) => {
            e.preventDefault();
            cancelar.mutate({
              idConexao: idConvite,
            });
          }}
        />
      );
    case 'recebidos':
      return (
        <>
          <ButtonAccept
            onAccept={(e) =>
              uiDialogOpen(e, 'aceitarConvite', () =>
                aceitar.mutate({
                  idConexao: idConvite,
                })
              )
            }
          />
          <SeparatorVertical pwidth="0.7rem" />
          <ButtonRefuse
            onRefuse={(e) =>
              uiDialogOpen(e, 'recusarConvite', (aditionalPayloadToAction) =>
                recusar.mutate({
                  idConexao: idConvite,
                  ...aditionalPayloadToAction,
                })
              )
            }
          />
        </>
      );
    case 'aguardando':
      return (
        <TagLoadingInfo bgcolor={finTheme.newtheme.color.success}>
          Aguarde...
        </TagLoadingInfo>
      );
    case 'aceito':
      return <TagInviteAccepted />;
    default:
      return null;
  }
};

Buttons.propTypes = {
  tipo: PropTypes.string.isRequired,
};

export default Buttons;

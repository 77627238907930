import React from 'react';

const config = (id) => {
  const values = {
    limite: [{ code: 1, msg: 'Campo Obrigatório' }],
    tranche: [{ code: 1, msg: 'Campo Obrigatório' }],
    concentracao: [{ code: 1, msg: 'Campo Obrigatório' }],
    motivo: [{ code: 1, msg: 'Campo Obrigatório' }],
  };
  return values[id] || [{ code: 0, msg: '' }];
};

export function useErrosForm() {
  const [erros, setErros] = React.useState(null);
  return {
    changeErros: (listErros) => {
      if (Array.isArray(listErros)) {
        if (listErros.length > 0) {
          const lista = {};
          listErros.forEach((res) => {
            const { name, id } = res;
            if (name && id) {
              const [message] = config(name).filter((res) => res.code === id);

              if (message.code > 0) {
                lista[name] = message.msg;
              }
            }
          });
          setErros(lista);
        } else {
          setErros(null);
        }
      } else {
        throw new Error('[ERROR]: Parameter is not a array!');
      }
    },
    erros,
  };
}

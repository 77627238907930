import React from 'react';
import styled from 'styled-components/macro';
import { rgba } from 'polished';

import { StringToSvg } from 'helpers/svgFiles/StringToSvg';
import { Wrapper } from 'components/FinWrappers';
import { Caption } from 'components/FinParagraph';

const WrapperCuston = styled(Wrapper)`
  background-color: ${({ bgcolor }) => rgba(bgcolor, 0.1)};
  border-radius: 4px;
  padding: 7.8px 20px;
`;

const TagFakeButton = ({
  icon,
  children,
  fill,
  stroke,
  vbox,
  labelColor,
  bgcolor,
}) => (
  <WrapperCuston bgcolor={bgcolor}>
    <StringToSvg
      viewBox={vbox || '0 -2 30 30'}
      width="23"
      height="23"
      stroke={fill ? 'none' : stroke}
      fill={stroke ? 'none' : fill}
    >
      {icon}
    </StringToSvg>
    &nbsp;&nbsp;
    <Caption color={labelColor}>{children}</Caption>
  </WrapperCuston>
);

export default TagFakeButton;

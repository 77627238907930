import React from 'react';
import { GRAPHICS } from '../../graphicsImages';
import styled from 'styled-components/macro';

const GraphicsInline = props => {
  const ImageBg = styled.div`
    width: 100%;
    width: 100%;
    height: 200px;
    background-repeat: no-repeat;
    background-size: 200px;
    background-image: url(${props => props.image});
    background-position: center center;
  `;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ImageBg image={GRAPHICS[props.image].image} />
      <span style={{ width: '100%', textAlign: 'center' }}>
        {GRAPHICS[props.image].label}
      </span>
    </div>
  );
};

export default GraphicsInline;

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { StringToSvg } from 'helpers/svgFiles/StringToSvg';
import feather from 'feather-icons/dist/icons.json';
import { Wrapper } from 'components/FinWrappers';

import { finTheme } from 'finTheme';

import {
  Shadown,
  Container,
  ButtonClose,
  Header,
  Footer,
  Title,
} from './styles';

export const Head = () => (
  <Wrapper padding="36px 40px">
    <Title>Convites</Title>
  </Wrapper>
);

export const Body = ({ children }) => (
  <Wrapper padding="32px 40px" justifyContent="flex-start">
    {children}
  </Wrapper>
);

const ModalCuston = ({
  children,
  open,
  scrolling,
  onClose,
  title,
  boxShadow,
  header,
  footer,
  ppadding,
  width,
  zIndex,
  center,
  bgColor,
  margin,
  minHeight,
  customStyle,
  withoutClickShadow,
}) => {
  const [marginCustom, setMarginCustom] = useState(null);
  const ref = useRef(null);
  const intervals = useRef([]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (ref.current) {
        const limiter = intervals.current ? intervals.current.length : 0;
        const boundingTop = ref.current.getBoundingClientRect().top;
        intervals.current = [...intervals.current, interval];
        if (boundingTop < 0) {
          setMarginCustom(`63px 0 10vh 56px`);
          intervals.current.forEach((timer) => clearInterval(timer));
        } else if (limiter === 100) {
          intervals.current.forEach((timer) => clearInterval(timer));
        }
      }
    }, 10);
  }, []);

  return (
    <Shadown
      open={open}
      onClick={!withoutClickShadow ? onClose : null}
      scrolling={scrolling}
      zIndex={zIndex}
    >
      <Container
        onClick={(e) => e.stopPropagation()}
        boxShadow={boxShadow}
        scrolling={scrolling}
        ppadding={ppadding}
        pwidth={width}
        center={center ? 1 : 0}
        bgColor={bgColor}
        margin={marginCustom || margin}
        minHeight={minHeight}
        ref={ref}
        customStyle={customStyle}
      >
        <Header>
          {header || <Title>{title}</Title>}
          <ButtonClose onClick={onClose}>
            <StringToSvg viewBox="0 0 24 24" width="30px" height="27px">
              {feather.x}
            </StringToSvg>
          </ButtonClose>
        </Header>
        {children}
        <Footer>{footer}</Footer>
      </Container>
    </Shadown>
  );
};

ModalCuston.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  boxShadow: PropTypes.bool,
  scrolling: PropTypes.string,
  children: PropTypes.any,
  title: PropTypes.string,
  header: PropTypes.any,
  footer: PropTypes.any,
  ppadding: PropTypes.string,
  width: PropTypes.string,
  zIndex: PropTypes.number,
  center: PropTypes.bool,
  margin: PropTypes.string,
  minHeight: PropTypes.string,
};

ModalCuston.defaultProps = {
  boxShadow: false,
  scrolling: 'false',
  ppadding: '36px 40px',
  width: '980px',
  zIndex: finTheme.newtheme.zIndex.modal.overlay,
  center: false,
};

export default ModalCuston;

/* eslint-disable no-console */
import { useContext } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import CONFIG from 'config';
import { getUserData } from 'services/getUserData';

import { AgendaContext } from '../reducer';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const fetchHabilitacaoEdicao = async () => {
  const { idEmpresa } = await getUserData();

  const fetchData = axios.get(
    `${BASE_URL}/agenda/habilitacao-edicao/${idEmpresa}`
  );

  const result = await fetchData;
  const { data } = result;

  const formatDate = data.data.calendario.map((res) => ({
    ...res,
    meses: res.meses.map((m) => (String(m).length === 1 ? `0${m}` : String(m))),
  }));
  return data
    ? { ...data, data: { ...data.data, calendario: formatDate } }
    : [];
};

export const useHabilitacaoEdicao = () => {
  const [, actions] = useContext(AgendaContext);

  return useQuery(['agenda', 'habilitacao'], fetchHabilitacaoEdicao, {
    keepPreviousData: false,
    onSuccess: (data) => {
      try {
        actions.setPeriodYear(data.data.dataAtual.split('-')[0]);
        actions.setPeriodMonth(data.data.dataAtual.split('-')[1]);
      } catch (error) {
        console.log('[ERROR]', error);
      }
    },
  });
};

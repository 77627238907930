import React from 'react';

import { Ring } from '@uiball/loaders';

export const Loader = () => {
  return (
    <div
      style={{
        backgroundColor: 'transparent',
        width: '100%',
        height: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Ring size={40} lineWeight={5} speed={2} color="#008894" />
    </div>
  );
};

/* eslint-disable no-unused-vars */
import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import axios from 'axios';
import CONFIG from 'config';
import { getUserData } from 'services/getUserData';
import { handleErrors } from 'helpers/requisicoes/handleErrors';
import toast from 'react-hot-toast';
import { OperacoesContext } from '../provider';
import { TIPO_PREGAO } from '../config';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const simular = async (params) => {
  const { idEmpresa } = await getUserData();

  const mutateData = axios.post(`${BASE_URL}/proposta/simulacao/convenio`, {
    ...params,
    idConvenio: idEmpresa,
  });

  const result = await mutateData;
  const { data } = result;

  return data.data || [];
};

export const useMutateSimular = () => {
  const queryClient = useQueryClient();
  const [state, actions] = useContext(OperacoesContext);

  const {
    propostas: { nova },
    bordero: { selecionados, nãoSelecionados },
  } = state;

  let params = {};
  if (nova.tipoPregao) {
    const source = nova.nota || nova.semNota;

    const tipoAnteciCobr =
      TIPO_PREGAO.antecipacao.includes(nova.tipoPregao) ||
      TIPO_PREGAO.cobrancaSimples.includes(nova.tipoPregao);

    const _titulos = {
      titulosSelecionados: [...selecionados],
      titulosRecusados: [...nãoSelecionados],
    };

    const titulos = tipoAnteciCobr ? _titulos : {};

    const tipoEmprestimo = TIPO_PREGAO.emprestimo.includes(nova.tipoPregao);

    const _parcelas = {
      parcelas: source.parcelas && [...source.parcelas],
    };

    const parcelas = tipoEmprestimo ? _parcelas : {};

    params = {
      idTipoPregao: nova.tipoPregao,
      idPregao: nova.idPregao,
      taxa: source.taxa,
      pmp: source.pmp,
      recompra: source.recompra,
      tarifa: source.tarifa,
      pendencias: source.pendencias,
      float: source.vFloat,
      ...titulos,
      ...parcelas,
    };
  }

  const updateValues = (data) => {
    const comNotaData = {
      bordero: {
        ...nova.bordero,
        quantidadeTitulosRecusados: data.quantidadeTitulosRecusados,
        valorRecusado: data.valorRecusado,
      },
      nota: {
        ...nova.nota,
        pmp: data.pmp,
        vop: data.vop,
        liquido: data.liquido,
      },
    };

    const tipoAnteciCobr =
      TIPO_PREGAO.antecipacao.includes(nova.tipoPregao) ||
      TIPO_PREGAO.cobrancaSimples.includes(nova.tipoPregao);

    const comNota = tipoAnteciCobr ? comNotaData : {};

    const chequeData = {
      semNota: {
        ...nova.semNota,
        pmp: data.pmp,
        vop: data.vop,
        liquido: data.liquido,
        face: data.valorBrutoSemNota,
      },
    };

    const cheque = TIPO_PREGAO.emprestimo.includes(nova.tipoPregao)
      ? chequeData
      : {};

    const emprestimoData = {
      semNota: {
        ...nova.semNota,
        pmp: data.pmp,
        vop: data.vop,
        liquido: data.liquido,
        face: data.valorBrutoSemNota,
        quantidadeParcelas: data.quantidadeParcelas,
      },
    };

    const emprestimo = TIPO_PREGAO.cheque.includes(nova.tipoPregao)
      ? emprestimoData
      : {};

    const upadateData = {
      ...nova,
      ...comNota,
      ...cheque,
      ...emprestimo,
      podium: {
        posicao: data.podium.posicao,
        quantidadePropostas: data.podium.quantidadePropostas,
      },
    };

    return upadateData;
  };

  return useMutation(() => simular(params), {
    onMutate: async (variables) => {
      await queryClient.cancelQueries(['propostas']);
      return { variables };
    },
    onError: (error) => {
      const message = handleErrors(error);
      toast.error(message, { duration: 4000 });
    },
    onSuccess: (data, variables, context) => {
      const newData = updateValues(data);

      actions.setNovaProposta({ value: { ...newData } });
    },
  });
};

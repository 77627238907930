import React from 'react';

import styled from 'styled-components/macro';

const MainList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  width: 100%;
`;

const List = ({ children }) => {
  return <MainList>{children}</MainList>;
};

export default List;

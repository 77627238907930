/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from 'react';

import { Container, Box, Title, Details, Button } from './style.error';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, hasError: false };
  }

  // static getDerivedStateFromError(error) {
  //   // Update state so the next render will show the fallback UI.
  //   return { hasError: true };
  // }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.children !== this.props.children)
      this.setState({ error: null, errorInfo: null, hasError: false });
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log('[ERROR]', '', { error, errorInfo });

    this.setState({
      error,
      errorInfo,
    });

    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.errorInfo) {
      // You can render any custom fallback UI
      return (
        <Container>
          <Box column justify="flex-start" align="flex-start">
            <Title>Algo deu errado.</Title>
            <Details>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </Details>
            <Button
              onClick={() =>
                this.setState({ error: null, errorInfo: null, hasError: false })
              }
            >
              Recarregar
            </Button>
          </Box>
        </Container>
      );
    }

    return this.props.children;
  }
}

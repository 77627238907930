const INITIAL_STATE = {
  dataCard: [],
  dataChart: [],
  isLoadingCard: false,
  isLoadingChart: false,
  errorCard: false,
  errorChart: false,
};

const dashboard = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    /********************** CARDS **********************/
    case 'LOAD_DATA_CARDS_REQUEST':
      return {
        ...state,
        isLoadingCard: true,
      };
    case 'LOAD_DATA_CARDS_SUCCESS':
      return {
        ...state,
        dataCard: action.data,
        isLoadingCard: false,
      };
    case 'LOAD_DATA_CARDS_FAILURE':
      return {
        ...state,
        dataCard: action.data,
        errorCard: true,
      };
    /********************** CHARTS **********************/
    case 'LOAD_DATA_CHARTS_REQUEST':
      return {
        ...state,
        isLoadingChart: true,
      };
    case 'LOAD_DATA_CHARTS_SUCCESS':
      return {
        ...state,
        dataChart: action.data,
        isLoadingChart: false,
      };
    case 'LOAD_DATA_CHARTS_FAILURE':
      return {
        ...state,
        dataChart: action.data,
        errorChart: true,
      };
    default:
      return state;
  }
};

export default dashboard;

import React from 'react';
import { ReactComponent as IconError } from 'assets/images/icon-close-flat.svg';
import { ReactComponent as IconCheck } from 'assets/images/icon-checkcircle.svg';
import { ReactComponent as IconUserPlus } from 'assets/images/icon-user-plus.svg';
import { ReactComponent as IconRemove } from 'assets/images/icon-remove-flat.svg';
import { ReactComponent as IconUnlock } from 'assets/images/icon-lock-key-open-flat.svg';
import { Ring } from '@uiball/loaders';

import { NwDialog } from 'components/NwDialog';
import * as NWCard from './style.card';
import { NWButton } from '../Button';
import {
  ConnectionDialog,
  AcceptInvite,
  RefuseInvite,
  Unblock,
} from '../Dialogs';

import {
  useMutateInvite,
  useMutateAcceptInvite,
  useMutateRefuseInvite,
  useMutateRemoveInvite,
  useMutateUnBlock,
} from '../../hook/mutates';

import { Habilitação } from './Habilitação';

export function RegionButtons({
  largura /*= '316px' */,
  isInvitation,
  isLocked,
  isConnection,
  data,
}) {
  const invite = useMutateInvite();
  const accept = useMutateAcceptInvite();
  const refuse = useMutateRefuseInvite();
  const remove = useMutateRemoveInvite();
  const unBlock = useMutateUnBlock();

  const style = {
    width: largura || '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
  };

  if (isInvitation === 'received') {
    return (
      <NWCard.RegionButtons style={style}>
        <NwDialog
          size="small"
          trigger={
            <NWButton.Error
              largura="total"
              style={{ padding: '0' }}
              disabled={refuse.isLoading}
            >
              {refuse.isLoading ? (
                <Ring size={20} lineWeight={3} speed={2} color="white" />
              ) : (
                <>
                  <IconError />
                  <span style={{ marginLeft: '0.5rem' }}>Recusar</span>
                </>
              )}
            </NWButton.Error>
          }
        >
          <RefuseInvite
            refuse={(params) =>
              refuse.mutate({
                idConvite: data.idConvite || data.idconexao,
                ...params,
              })
            }
          />
        </NwDialog>

        <NwDialog
          size="small"
          trigger={
            <NWButton.Primary
              largura="total"
              style={{ padding: '0' }}
              disabled={accept.isLoading}
            >
              {accept.isLoading ? (
                <Ring size={20} lineWeight={3} speed={2} color="white" />
              ) : (
                <>
                  <IconCheck />
                  <span style={{ marginLeft: '0.5rem' }}>Aceitar</span>
                </>
              )}
            </NWButton.Primary>
          }
        >
          <AcceptInvite
            accept={() =>
              accept.mutate({ idConvite: data.idConvite || data.idconexao })
            }
          />
        </NwDialog>
      </NWCard.RegionButtons>
    );
  }

  if (isInvitation === 'sent') {
    return (
      <NWCard.RegionButtons
        disabled={remove.isLoading}
        style={style}
        onClick={() =>
          remove.mutate({ idConvite: data.idConvite || data.idconexao })
        }
      >
        <NWButton.Error largura="total" disabled={remove.isLoading}>
          {remove.isLoading ? (
            <Ring size={20} lineWeight={3} speed={2} color="white" />
          ) : (
            <>
              <IconRemove />
              <span style={{ marginLeft: '0.5rem' }}>Remover Convite</span>
            </>
          )}
        </NWButton.Error>
      </NWCard.RegionButtons>
    );
  }

  if (isLocked) {
    return (
      <NWCard.RegionButtons style={style}>
        <NwDialog
          size="small"
          trigger={
            <NWButton.Secondary largura="total" disabled={unBlock.isLoading}>
              {unBlock.isLoading ? (
                <Ring size={20} lineWeight={3} speed={2} color="white" />
              ) : (
                <>
                  <IconUnlock width={20} height={20} />
                  <span style={{ marginLeft: '0.5rem' }}>Desbloquear</span>
                </>
              )}
            </NWButton.Secondary>
          }
        >
          <Unblock unBlock={() => unBlock.mutate({ idGrupo: data.idGrupo })} />
        </NwDialog>
      </NWCard.RegionButtons>
    );
  }

  if (isConnection) {
    return <Habilitação data={data} largura={largura} />;
  }

  return (
    <NWCard.RegionButtons style={style}>
      <NwDialog
        size="small"
        trigger={
          <NWButton.Primary largura="total" disabled={invite.isLoading}>
            {invite.isLoading ? (
              <Ring size={20} lineWeight={3} speed={2} color="white" />
            ) : (
              <>
                <IconUserPlus />
                <span style={{ marginLeft: '0.5rem' }}>Conectar</span>
              </>
            )}
          </NWButton.Primary>
        }
      >
        <ConnectionDialog
          invite={() => invite.mutate({ idGrupo: data.idGrupo })}
        />
      </NwDialog>
    </NWCard.RegionButtons>
  );
}

import { styled } from '@stitches/react';
import { Link } from 'react-router-dom';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';

export const Root = styled(AspectRatio.Root, {});

export const Container = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: '9999',
});

export const Wrapper = styled('div', {
  position: 'relative',
  width: '760px',
  borderRadius: '6px',
  overflow: 'hidden',
  boxShadow: 'rgb(0 0 0 / 14%) 0px 2px 10px',
});

export const LinkExternal = styled('a', {
  textDecoration: 'none',
  outline: 'none',
  color: 'transparent',
  width: '100%',
  height: '100%',
  cursor: 'pointer',
});

export const LinkInternal = styled(Link, {
  textDecoration: 'none',
  outline: 'none',
  color: 'transparent',
  width: '100%',
  height: '100%',
  cursor: 'pointer',
});

export const Image = styled('img', {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  cursor: 'pointer',
});

export const Button = styled('button', {
  background: 'transparent',
  outline: 'none',
  width: '26px',
  height: '26px',
  position: 'absolute',
  top: '12px',
  right: '20px',
  zIndex: 99,
  border: 'none',
  cursor: 'pointer',
});

import styled, { css } from 'styled-components/macro';
import { device } from 'devices';

export const Box = styled.div`
  position: relative;
  background: ${({ theme }) => theme.newtheme.framer.box.backgroundColor};
  box-shadow: ${({ theme }) => theme.newtheme.dropShadow.dropShadow2};
  border-radius: ${({ theme }) => theme.newtheme.borderRadius};
  width: ${({ pwidth }) => pwidth || 'inherit'};
  height: ${({ pheight }) => pheight || 'auto'};
  max-height: ${({ maxHeight }) => maxHeight || '300px'};
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  font-size: ${({ theme }) => theme.newtheme.font.corpo2.tamanho};
  letter-spacing: ${({ theme }) => theme.newtheme.font.corpo2.espacamento};
  font-weight: ${({ theme }) => theme.newtheme.font.corpo2.peso};
  line-height: 1.5;
  color: ${({ theme }) => theme.newtheme.framer.box.color};
  border-width: 1px;
  overflow-y: ${({ overflow }) => overflow || 'auto'};
  transition: color 300ms ease, background-color 300ms ease,
    border-color 300ms ease, box-shadow 300ms ease;

  ${({ noHover }) =>
    !noHover &&
    css`
      &:hover {
        box-shadow: ${({ theme }) => theme.newtheme.dropShadow.dropShadow4};
      }
    `}/*
  @media ${device.laptop} {
    width: ${({ pwidth }) => pwidth || '30px'};
    height: ${({ pheight }) => pheight || '30px'};
  }
  @media ${device.desktop} {
    width: ${({ pwidth }) => pwidth || '60px'};
    height: ${({ pheight }) => pheight || '60px'};
  }*/
`;

import React from 'react';

import * as NWTab from './styles.tab';

export function Tab({ children }) {
  return (
    <NWTab.Tab>
      <NWTab.UnderlineNav>{children}</NWTab.UnderlineNav>
    </NWTab.Tab>
  );
}

export function ItemTab({ isActive, children }) {
  return (
    <NWTab.ItemTab>
      {React.cloneElement(children, { isActive })}
      <NWTab.BorderBottom isActive={isActive} />
    </NWTab.ItemTab>
  );
}

export function ItemTabLink({ to, children }) {
  return (
    <NWTab.ItemLink to={to}>
      {children}
      <NWTab.BorderBottom />
    </NWTab.ItemLink>
  );
}

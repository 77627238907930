import React from 'react';
import AbleStep from 'assets/icones/icone-able-step.svg';
import CompletedStep from 'assets/icones/icone-completed-step.svg';

import { Step } from './Step';

export const HabilitacaoAprovada = () => {
  return (
    <Step
      bgcolor="#CAF9D6"
      icon={AbleStep}
      statuicon={CompletedStep}
      label="Habilitação"
      labelsecundaria="Aprovada"
    />
  );
};

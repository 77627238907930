import React from 'react';

import { ReactComponent as IconX } from 'assets/images/icon-x.svg';
import useDebounce from 'hooks/useDebounce';

import { RegionSearch } from '../../style.layout';
import { NWInput } from '../Input';
import { ResultSearch } from './ResultSearch';
import { Shadow } from './style.resultsearch';

import { useQuerySearch } from '../../hook/useQuerySearch';

export const Search = () => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [openResult, setOpenResult] = React.useState(false);
  const [inputFocus, setInputFocus] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');

  const termo = useDebounce(searchTerm, 800);

  const { data, fetchNextPage, isFetching, status } = useQuerySearch({
    search: termo,
  });

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFocus = () => {
    setInputFocus(true);
  };

  const loadMoreItems = () => {
    fetchNextPage({
      pageParam: {
        size: 5,
        skip: currentPage + 1,
        search: termo,
      },
    });
    setCurrentPage((state) => state + 1);
  };

  const clear = () => {
    setInputFocus(false);
    setOpenResult(false);
    setSearchTerm('');
  };

  React.useEffect(() => {
    if (searchTerm !== '') {
      setOpenResult(true);
    }
  }, [searchTerm]);

  return (
    <RegionSearch>
      <NWInput.Wrapper>
        <NWInput.ButtonClose onClick={clear}>
          <IconX />
        </NWInput.ButtonClose>
        <NWInput.Search
          value={searchTerm}
          onChange={handleChange}
          placeholder="Pesquisar"
          onFocus={handleFocus}
        />
      </NWInput.Wrapper>
      <Shadow show={inputFocus} onClick={clear} />
      {openResult && searchTerm && (
        <ResultSearch
          currentPage={currentPage}
          search={termo}
          isFetching={isFetching}
          status={status}
          data={data}
          loadMoreItems={loadMoreItems}
          setSearchTerm={setSearchTerm}
        />
      )}
    </RegionSearch>
  );
};

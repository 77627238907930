import React from 'react';

import Skeleton from 'react-loading-skeleton';

export function SkeletonHeading() {
  return (
    <div
      style={{
        width: 100,
        display: 'flex',
        gap: '15px',
        alignItems: 'center',
      }}
    >
      <div>
        <Skeleton width="50px" height="50px" circle />
        <Skeleton width="70px" />
      </div>
      <div>
        <Skeleton width="550px" height="20px" />
        <Skeleton width="350px" height="20px" />
      </div>
    </div>
  );
}

/* eslint-disable no-console */
import immer from 'immer';
import React, { createContext, useReducer } from 'react';

export const idStatusCredito = {
  1: 'creditoPendente',
  2: 'creditoAprovado',
  3: 'creditoRecusado',
};

export const statusCondition = {
  editing: 'editing',
  reading: 'reading',
};

const initialState = {
  fields: {
    limite: 0,
    tranche: 0,
    concentracao: 0,
    motivo: '',
  },
  active: {
    condition: statusCondition.reading,
    accordionOpen: false,
  },
};

const actions = {
  SET_FIELD_LIMITE: 'SET_FIELD_LIMITE',
  SET_FIELD_TRANCHE: 'SET_FIELD_TRANCHE',
  SET_FIELD_CONCENTRACAO: 'SET_FIELD_CONCENTRACAO',
  SET_FIELD_MOTIVO: 'SET_FIELD_MOTIVO',
  RESET_FIELDS: 'RESET_FIELDS',

  CHANGE_ACTIVE_CONDITION: 'CHANGE_ACTIVE_CONDITION',
  CHANGE_ACTIVE_ACCORDION: 'CHANGE_ACTIVE_ACCORDION',
};

const reducerActions = {
  CHANGE_ACTIVE_CONDITION: (state, payload) => {
    state.active.condition = payload.value;
  },
  CHANGE_ACTIVE_ACCORDION: (state, payload) => {
    state.active.accordionOpen = payload.value;
  },
  SET_FIELD_LIMITE: (state, payload) => {
    state.fields.limite = payload.value;
  },
  SET_FIELD_TRANCHE: (state, payload) => {
    state.fields.tranche = payload.value;
  },
  SET_FIELD_CONCENTRACAO: (state, payload) => {
    state.fields.concentracao = payload.value;
  },
  SET_FIELD_MOTIVO: (state, payload) => {
    state.fields.motivo = payload.value;
  },
  RESET_FIELDS: (state) => {
    state.fields.limite = 0;
    state.fields.tranche = 0;
    state.fields.concentracao = 0;
    state.fields.motivo = '';
  },
};

const reducer = (state, action) => {
  const fn = reducerActions[action.type];
  if (fn) {
    return immer(state, (draftState) => {
      fn(draftState, action.payload);
    });
  }
  console.log('[WARNING] Action without reducer:', action);
  return state;
};

export const CreditoContext = createContext();

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const acoes = {
    changeCondition: (value) => {
      dispatch({
        type: actions.CHANGE_ACTIVE_CONDITION,
        payload: { value },
      });
    },
    changeAccordion: (value) => {
      dispatch({
        type: actions.CHANGE_ACTIVE_ACCORDION,
        payload: { value },
      });
    },
    setLimite: (value) => {
      dispatch({
        type: actions.SET_FIELD_LIMITE,
        payload: { value },
      });
    },
    setTranche: (value) => {
      dispatch({
        type: actions.SET_FIELD_TRANCHE,
        payload: { value },
      });
    },
    setConcentracao: (value) => {
      dispatch({
        type: actions.SET_FIELD_CONCENTRACAO,
        payload: { value },
      });
    },
    setMotivo: (value) => {
      dispatch({
        type: actions.SET_FIELD_MOTIVO,
        payload: { value },
      });
    },
    resetFields: () => {
      dispatch({
        type: actions.RESET_FIELDS,
      });
    },
  };

  return (
    <CreditoContext.Provider value={[state, acoes]}>
      {children}
    </CreditoContext.Provider>
  );
};

import styled from 'styled-components/macro';

import { ReactComponent as IconCircleWave } from 'assets/images/icon-circle-wavy-check.svg';

export const IconCircleWaveStyle = styled(IconCircleWave)`
  & path {
    fill: ${({ fillColor }) => fillColor};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  overflow: hidden;
  padding: 5px 10px;
  height: 20px;
  background: ${({ bgColor }) => bgColor || `var(--fingray-300)`};
`;

export const Label = styled.span`
  text-rendering: geometricprecision;
  font-weight: 700;
  letter-spacing: -0.05em;
  font-family: 'Inter', sans-serif;
  text-align: center;
  text-transform: uppercase;
  color: ${({ color }) => color || `var(--fingray-900)`};
  font-size: 0.75rem;
`;

import React, { useState, useCallback } from 'react';
import { InputMaterial } from 'components/FinInput/InputMaterial';

import { Wrapper } from 'components/FinWrappers';
import { finTheme } from 'finTheme';
import { useActions } from 'hooks/useActions';

import { Creators as searchRouterActions } from 'store/ducks/searchRouter';

import { useSelector, useDispatch } from 'react-redux';
import {
  loadCnaeRequest,
  removeCnaeItem,
  addCnaeItem,
  clearCnaeList,
  removeCnaeSelectedItem,
  addCnaeSelectedItem,
} from 'store/old_actions/actions';
import { TagCountSelected } from './Styles';
import BuscaCnae from './BuscaCnae';

const Cnae = () => {
  const [verBusca, setVerBusca] = useState(false);
  const [textBusca, setTextBausca] = useState('');

  const searchRoute = useActions(searchRouterActions);

  const dispatch = useDispatch();
  const query = useSelector((state) => state.router.location.query);

  // eslint-disable-next-line no-unused-vars
  const addSelectedCnae = useCallback(
    (data) => {
      dispatch(addCnaeSelectedItem(data));
    },
    [dispatch]
  );

  // eslint-disable-next-line no-unused-vars
  const removeSelectedCnae = useCallback(
    (cnae) => {
      dispatch(removeCnaeSelectedItem(cnae));
    },
    [dispatch]
  );

  const addSearch = useCallback(
    (value) => {
      let values = [];

      if (query.cnae) {
        const parseArray = JSON.parse(decodeURIComponent(query.cnae));
        values = parseArray.includes(value)
          ? parseArray.filter((res) => res !== value)
          : [...parseArray, value];
      } else {
        values = [value];
      }

      // console.log(value);
      // addSelectedCnae(value)

      searchRoute.addSearchRoute({
        cnae: values,
        page: '1',
        qtd: '10',
        order: 'a-z',
        busca: '',
      });
    },
    [searchRoute, query]
  );

  const closeBusca = useCallback(() => {
    setTextBausca('');
    setVerBusca(false);
  }, []);

  const cunstonFocus = useCallback(() => {
    setVerBusca(true);
  }, []);

  const cunstonClick = useCallback(
    (event) => {
      setVerBusca(true);
      dispatch(loadCnaeRequest(event.target.value));
    },
    [dispatch]
  );

  const showBusca = useCallback(
    (event) => {
      setVerBusca(true);
      setTextBausca(event.target.value);
      dispatch(loadCnaeRequest(event.target.value));
    },
    [dispatch]
  );

  const removeCnae = useCallback(
    (cnae, descricao) => {
      dispatch(removeCnaeItem(cnae, descricao));
    },
    [dispatch]
  );

  const addCnae = useCallback(
    (data) => {
      dispatch(addCnaeItem(data));
    },
    [dispatch]
  );

  const clearCnae = useCallback(() => {
    dispatch(clearCnaeList());
  }, [dispatch]);

  return (
    <Wrapper pposition="relative" padding="32px" pwidth="100%">
      <Wrapper padding="0" pwidth="100%">
        <InputMaterial
          id="input-cnae"
          label="CNAE"
          value={textBusca}
          handleChange={showBusca}
          handleFocus={cunstonFocus}
          handleClick={cunstonClick}
          largura="100%"
          colorLabel={finTheme.newtheme.color.cinza6}
          weightLabel="600"
          placeholder="Digite uma palavra chave para pesquisar"
        />
        <TagCountSelected>{`Qtd: ${
          query.cnae ? JSON.parse(decodeURIComponent(query.cnae)).length : 0
        }`}</TagCountSelected>
      </Wrapper>
      {verBusca && (
        <BuscaCnae
          fieldName="cnae"
          value={query.cnae ? JSON.parse(decodeURIComponent(query.cnae)) : []}
          close={closeBusca}
          removeCnae={removeCnae}
          addCnaeItem={addCnae}
          clearCnaeList={clearCnae}
          addSearch={addSearch}
        />
      )}
    </Wrapper>
  );
};

export default Cnae;

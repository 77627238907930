import React from 'react';
import { useSelector } from 'react-redux';

import { Wrapper } from 'components/FinWrappers';
import { Paragraph2 } from 'components/FinParagraph';
import { DialogCuston } from './DialogCuston';

const DialogDefault = () => {
  const uiDialogState = useSelector((state) => state.uiDialog);

  return (
    <DialogCuston disablePrimaryButton={false}>
      <Wrapper
        padding="32px 0"
        justifyContent="flex-start"
        alignItems="flex-start"
        tipo="column"
      >
        <Paragraph2>{uiDialogState.text}</Paragraph2>
      </Wrapper>
    </DialogCuston>
  );
};

export default DialogDefault;

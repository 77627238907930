const INITIAL_STATE = {
  data: {
    pagina: 1,
    quantidade: 0,
    ordem: 'nome',
    ordemCampo: 'crescente',
    pesquisa: '',
  },
  filters: {
    faturamento: 0,
    cnae: [],
    estados: [],
    segmento: [],
    status: [],
    habilitacao: [],
    acesso: [],
  },
  isloadingFields: null,
  dataFields: [],
  errorFields: null,
  isloadingCnae: null,
  dataCnae: [],
  errorCnae: null,
  listSelectedCnae: [],
};

const filter = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'RESET_ALL':
      return {
        ...state,
        data: {
          pagina: 1,
          quantidade: 0,
          ordem: 'nome',
          ordemCampo: 'crescente',
          pesquisa: '',
        },
        filters: {
          faturamento: 0,
          cnae: [],
          estados: [],
          segmento: [],
          status: [],
          habilitacao: [],
          acesso: [],
        },
      };
    case 'UPDATE_FIELD':
      // console.log(action);
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data,
        },
      };
    case 'LOAD_LIST_FIELDS_REQUEST':
      return {
        ...state,
        isloadingFields: true,
      };
    case 'LOAD_LIST_FIELDS_SUCESS':
      return {
        ...state,
        isloadingFields: false,
        dataFields: action.data,
        errorFields: false,
      };
    case 'LOAD_LIST_FIELDS_FAILURE':
      return {
        ...state,
        isloadingFields: false,
        dataFields: action.data,
        errorFields: true,
      };
    case 'SET_FILTER':
      console.log(action);
      return {
        ...state,
        filters: action.value,
      };
    case 'ADD_FILTER_ITEM':
      return {
        ...state,
        filters: [...state.filters, action.value],
      };
    case 'UPDATE_FILTER_ITEM':
      return {
        ...state,
        filters: { ...state.filters, [action.field]: action.value },
      };
    case 'RESET_FILTER':
      console.log(action);
      return {
        ...state,
        filters: {
          faturamento: 0,
          cnae: [],
          estados: [],
          segmento: [],
          status: [],
          habilitacao: [],
          acesso: [],
        },
      };
    case 'LOAD_CNAE_REQUEST':
      return {
        ...state,
        isloadingCnae: true,
      };
    case 'LOAD_CNAE_SUCESS':
      return {
        ...state,
        isloadingCnae: false,
        dataCnae: action.data,
        errorCnae: false,
      };
    case 'LOAD_CNAE_FAILURE':
      return {
        ...state,
        isloadingCnae: false,
        dataCnae: action.data,
        errorCnae: true,
      };
    case 'REMOVE_CNAE_ITEM':
      console.log(action);
      // eslint-disable-next-line no-case-declarations
      const excluded = state.dataCnae.filter((res) => {
        let v = false;
        if (Array.isArray(action.cnae)) {
          action.cnae.forEach((r) => {
            // eslint-disable-next-line no-unused-expressions
            res.cnae.toString() !== r.toString() ? (v = true) : (v = false);
          });
          return v;
        }
        return res.cnae.toString() !== action.cnae.toString();
      });
      // console.log(excluded);
      return {
        ...state,
        dataCnae: excluded,
      };
    case 'ADD_CNAE_ITEM':
      console.log(action);
      return {
        ...state,
        dataCnae: [...state.dataCnae, action.data],
      };
    case 'CLEAR_CNAE_LIST':
      return {
        ...state,
        dataCnae: [],
      };
    case 'ADD_CNAE_SELECTED_ITEM':
      console.log(action);
      return {
        ...state,
        listSelectedCnae: [...state.listSelectedCnae, action.data],
      };
    case 'REMOVE_CNAE_SELECTED_ITEM':
      console.log(action);
      // eslint-disable-next-line no-case-declarations
      const excludedSelect = state.listSelectedCnae.filter((res) => {
        let v = false;
        if (Array.isArray(action.cnae)) {
          action.cnae.forEach((r) => {
            // eslint-disable-next-line no-unused-expressions
            res.cnae.toString() !== r.toString() ? (v = true) : (v = false);
          });
          return v;
        }
        return res.cnae.toString() !== action.cnae.toString();
      });
      // console.log(excluded);
      return {
        ...state,
        listSelectedCnae: excludedSelect,
      };
    default:
      return state;
  }
};

export default filter;

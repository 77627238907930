import React from 'react';

import * as NWBullet from './style.bullet';

export function Bullet({ bgColor, color, children }) {
  return (
    <NWBullet.Background css={{ bgColor }}>
      <NWBullet.Label color={color}>{children}</NWBullet.Label>
    </NWBullet.Background>
  );
}

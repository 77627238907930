import React from 'react';
import { Container, Label } from './style.step';

export const Step = ({
  bgcolor,
  icon,
  label,
  statuicon,
  labelsecundaria,
  bgcoloricone,
  bgpositionx,
}) => {
  return (
    <Container bgcolor={bgcolor}>
      <Label
        icon={icon}
        statuicon={statuicon}
        bgcoloricone={bgcoloricone}
        bgpositionx={bgpositionx}
      >
        {label} <br /> {labelsecundaria}
      </Label>
    </Container>
  );
};

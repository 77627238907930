import styled, { css } from 'styled-components/macro';

export const RegionSteps = styled.div`
  display: flex;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 4px;
  width: 93px;
`;

const IndicatorDefault = css`
  background-color: #f2f2f3;
  border: 3px solid #aba6a6;
  & > span {
    color: #646464;
  }
`;

export const Indicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background-color: var(--finblue-primary);
  position: relative;
  border-radius: 12px;
  position: relative;
  border: 3px solid #008894;

  ${({ noLine, dashed }) =>
    noLine
      ? ``
      : css`
          &::before {
            content: '';
            position: absolute;
            width: 112px;
            left: 12px;
            top: 50%;
            text-align: center;
            transform: translate(0%, -50%) scale(0.65);
            ${dashed
              ? css`
                  border: 2px dashed #aba6a6;
                  border-width: 2px;
                `
              : css`
                  border: 2px solid #008894;
                `};
          }
        `}

  ${({ padrao }) => padrao && IndicatorDefault}
`;

export const IndicatorIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background-color: ${({ bgColor }) => bgColor || `var(--finblue-primary)`};
  position: relative;
  border-radius: 50%;

  ${({ noLine, dashed }) =>
    noLine
      ? ''
      : css`
          &::before {
            content: '';
            position: absolute;
            width: 112px;
            left: 15px;
            top: 50%;
            text-align: center;
            transform: translate(0%, -50%) scale(0.65);
            ${dashed
              ? css`
                  border: 2px dashed #aba6a6;
                  border-width: 2px;
                `
              : css`
                  border: 2px solid #008894;
                `};
          }
        `}
`;

export const Number = styled.span`
  text-rendering: geometricprecision;
  position: absolute;
  font-size: 1.8rem;
  font-weight: 500;
  z-index: 3;
  left: 50%;
  top: 50%;
  text-align: center;
  color: #ffffff;
  transform: translate(-50%, -50%) scale(0.65);
  white-space: nowrap;
  user-select: none;
`;

export const BgLabel = styled.span`
  background-color: var(--fingray-300);
  border-radius: 4px;
  overflow: hidden;
`;

export const Label = styled.span`
  text-rendering: geometricprecision;
  font-size: 0.875rem;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  user-select: none;
  background-color: ${({ bgColor }) => bgColor || `var(--fingray-300)`};
  color: ${({ color }) => color || `var(--fingray-500)`};
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  padding: 0 2px;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    border-left: 5px solid ${({ borderColor }) => borderColor || `transparent`};
  }
`;

/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';

import { ReactComponent as IconPlay } from 'assets/images/icon-play-white.svg';
import { ImageRenderer } from 'components/FinImageRenderer';
// import ReactTooltip from 'react-tooltip';
import { useSelectTag } from 'pages/MinhaRede/hook/useSelectTag';

import emptyVideo from 'assets/images/image-placeholder-empty.png';

import previewVideo from 'assets/images/image-video.png';
import placeholder from 'assets/images/video-placeholder.png';

import Skeleton from 'react-loading-skeleton';

import * as NWCard from 'pages/MinhaRede/Comps/Card/style.card';
import { SeparatorHorizontal } from 'components/FinSeparador';
import { FinPlayer } from 'components/FinPlayer';

import { capitalizeWords } from 'helpers/string';

import { currency } from 'helpers/format';
import { RegionButtons } from 'pages/MinhaRede/Comps/Card/';
import { statusConexao } from 'pages/MinhaRede/config';
import { useGetImage } from 'hooks/useGetImage';
import { RenderIconMatch } from 'pages/MinhaRede/Comps/Card/RenderIconMatch';

import { useQueryAprovacaoCredito } from '../hooks/useQueryAprovacaoCredito';

import {
  CardContainer,
  Card,
  RegionVideoPlay,
  ButtonPlay,
  Thumbnail,
  LabelVideo,
  WrapperButton,
  Flex,
} from './style.maincard';

import { RenderCredito } from './Credito';
import { PerfilContext } from '../PaginaFinCredit';

export function MainCardAction({ idGrupo }) {
  const { perfil } = useContext(PerfilContext);

  const { data, isLoading } = useQueryAprovacaoCredito({ idGrupo });

  const { comp: TagMember } = useSelectTag({
    tipo: 'finscanner',
  });

  const withVideo = perfil.data && perfil.data.midia && perfil.data.midia.video;

  const noVideo =
    (perfil.data && !perfil.data.midia) ||
    (perfil.data && perfil.data.midia && !perfil.data.midia.video);

  const fullImage = useGetImage({
    url: null,
    qualidade: 20,
    fallback: withVideo ? previewVideo : noVideo ? emptyVideo : null,
  });

  const typeInvite = () =>
    perfil.data.tipoConvite === 1 ? 'sent' : 'received';
  const isInvite = perfil.data && perfil.data.tipoConvite ? typeInvite() : null;

  // const capitalizedName = capitalizeWords(perfil.data && perfil.data.nome);
  const capitalizedCity = capitalizeWords(
    perfil.data && perfil.data.cidadeMatriz
  );

  return (
    <CardContainer>
      <RegionVideoPlay>
        {withVideo && (
          <WrapperButton>
            <FinPlayer src={perfil.data.midia.video.link}>
              <ButtonPlay>
                <IconPlay />
              </ButtonPlay>
            </FinPlayer>
            <LabelVideo>Vídeo institucional</LabelVideo>
          </WrapperButton>
        )}
        <Thumbnail>
          <ImageRenderer url={fullImage} thumb={placeholder} />
        </Thumbnail>
      </RegionVideoPlay>
      <Card>
        {perfil.isLoading && (
          <div
            style={{
              width: '100%',
            }}
          >
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton width={250} />
            <Skeleton width={150} />
            <Skeleton width={150} />
          </div>
        )}
        {perfil.data && (
          <Flex column align="flex-start">
            {perfil.data.finscannerAtivo && <TagMember />}
            {/* <SeparatorHorizontal ppadding="0.2rem" /> */}
            <NWCard.RegionIconMatch style={{ right: '0', top: '0.2rem' }}>
              <RenderIconMatch data={perfil.data} />
            </NWCard.RegionIconMatch>

            {/* <ReactTooltip
              id={`card-name-financiador-${perfil.data.idGrupo}`}
              wrapper="span"
              place="right"
              type="dark"
              effect="solid"
            >
              {capitalizedName}
            </ReactTooltip>

            <NWCard.CompanyName
              limite="20ch"
              data-tip
              data-for={`card-name-financiador-${perfil.data.idGrupo}`}
            >
              {capitalizedName}
            </NWCard.CompanyName> */}

            {/* <NWCard.CompanySegment limite="23ch">
              {capitalizeWords(perfil.data.segmentoMatriz)}
            </NWCard.CompanySegment> */}

            {/* <SeparatorHorizontal ppadding="0.4rem" /> */}

            <NWCard.Location
              isActive={!!perfil.data.cidadeMatriz}
              limite="23ch"
            >
              {perfil.data.cidadeMatriz ? (
                <>
                  {capitalizedCity}
                  <span
                    style={{ textTransform: 'uppercase' }}
                  >{`, ${perfil.data.estadoMatriz}`}</span>
                </>
              ) : (
                'Localização'
              )}
            </NWCard.Location>

            <NWCard.Invoicing isActive={!!perfil.data.faturamento}>
              {perfil.data.faturamento
                ? currency(perfil.data.faturamento)
                : 'Faturamento'}
            </NWCard.Invoicing>

            <NWCard.FullProfile isActive={perfil.data.perfil}>
              Perfil
            </NWCard.FullProfile>

            <NWCard.FullProfile isActive={perfil.data.documento}>
              Documentos
            </NWCard.FullProfile>

            <SeparatorHorizontal pborderColor="#A7A7A7" />
            {!isLoading &&
              data &&
              perfil.data.statusConexao === statusConexao.aceita && (
                <>
                  <RenderCredito
                    status={data.idAprovacaoStatus}
                    data={data}
                    idGrupo={idGrupo}
                  />
                  <SeparatorHorizontal pborderColor="#A7A7A7" />
                </>
              )}

            <RegionButtons
              isInvitation={isInvite}
              isLocked={perfil.data.bloqueado}
              isConnection={perfil.data.statusConexao === statusConexao.aceita}
              data={perfil.data}
            />
          </Flex>
        )}
      </Card>
    </CardContainer>
  );
}

import React from 'react';
import styled, { css } from 'styled-components/macro';

export const ApplyColorSvg = ({ color, strokeWidth, tipo, comp: Comp }) => {
  const strokeCss = css`
    ${({ color }) =>
      color &&
      css`
        stroke: ${color};
        & path {
          stroke: ${color};
        }
      `}

    ${({ strokeWidth }) => strokeWidth && `stroke-width: ${strokeWidth}`}
  `;

  const fillCss = css`
    fill: ${({ color }) => color};
    & path {
      fill: ${({ color }) => color};
    }
  `;

  const Icon = styled(Comp)`
    ${({ tipo }) => tipo === 'stroke' && strokeCss}
    ${({ tipo }) => tipo === 'fill' && fillCss}
  `;

  if (!tipo && !color) {
    return <Comp />;
  }

  return <Icon tipo={tipo} color={color} strokeWidth={strokeWidth} />;
};

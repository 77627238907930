/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { createActions, createReducer } from 'reduxsauce';

/**
 * Criando action types & creators
 */

export const { Types, Creators } = createActions({
  routeSave: ['data'],
  routeCancelSave: [],
});

/**
 * Criando os reducer handlers
 */

const INITIAL_STATE = {
  data: [],
};

const save = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    data: [action.data],
  };
};

const cancel = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    data: [action.data],
  };
};

/**
 * Criando reducer
 */

export default createReducer(INITIAL_STATE, {
  [Types.ROUTE_SAVE]: save,
  [Types.ROUTE_CANCEL_SAVE]: cancel,
});

import axios from 'axios';
import { useQuery } from 'react-query';
import CONFIG from 'config';
import { getUserData } from 'services/getUserData';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/`;

const fetchPerfil = async (params) => {
  const { idEmpresa } = await getUserData();
  const payload = { idConvenio: idEmpresa, ...params };
  const fetchData = axios.post(
    `${BASE_URL}api/matching/convenio/perfil/empreendedores`,
    payload
  );

  const result = await fetchData;
  const { data } = result;

  return data.data ? data.data : [];
};

export const usePerfil = (params) => {
  return useQuery(['perfil', params], () => fetchPerfil(params), {
    keepPreviousData: false,
    enabled: !!params.idCedente,
  });
};

import styled, { css } from 'styled-components/macro';

import feather from 'feather-icons/dist/icons.json';
import { MakeSvgUri } from 'helpers/svgFiles';
import { finTheme } from 'finTheme';

const chevronDownRed = MakeSvgUri({
  viewBox: '0 0 18 18',
  width: '18',
  heigth: '18',
  stroke: finTheme.newtheme.color.azulPrimario,
  data: feather['chevron-down'],
});

const chevronDownGray = MakeSvgUri({
  viewBox: '0 0 18 18',
  width: '18',
  heigth: '18',
  stroke: finTheme.newtheme.color.cinza3,
  data: feather['chevron-down'],
});

export const ContentList = styled.div`
  width: max-content;
  background-color: #ffffff;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: absolute;
  top: -57px;
  left: 0;
  z-index: 9;
  visibility: hidden;
  overflow-y: auto;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.31);
  opacity: 0;
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`;

export const BaseMultiSelect = styled.div`
  background: hsla(0, 0%, 100%, 0);
  padding-bottom: 5px;
  border: 0;
  border-bottom: 1px solid #ccc;
  height: 30px;
  width: 100%;
  outline: 0;
  font-weight: 400;
  font-size: 14px;
  padding-right: 24px;
  cursor: pointer;
  min-width: 16px;
  position: relative;
  &:before {
    background: url(${chevronDownRed}) no-repeat;
    content: '';
    padding: 1rem;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }
  &:hover ${ContentList} {
    visibility: visible;
    top: 0;
    opacity: 100;
  }
`;

export const TextList = styled.label.attrs(({ para }) => ({
  htmlFor: para,
}))`
  font-size: 18px;
  color: ${({ theme }) => theme.newtheme.color.cinza3};
`;

export const ListCheckds = styled.span`
  display: inline-block;
  width: 100%;
  color: ${({ theme }) => theme.newtheme.color.cinza6};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

/**
 * select
 */

export const Option = styled.option`
  background-color: ${({ theme }) => theme.newtheme.color.cinza6};
  font-weight: normal;
  display: block;
  white-space: pre;
  min-height: 1.2em;
  padding: 0px 2px 1px;
  color: ${({ theme }) => theme.newtheme.color.branco};
`;

export const SelectComp = styled.select`
  background: hsla(0, 0%, 100%, 0);
  padding-bottom: 5px;
  border: 0;
  border-bottom: 1px solid #ccc;
  height: 30px;
  width: 100%;
  outline: 0;

  color: ${({ theme, color }) => color || theme.newtheme.color.cinza6};
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  letter-spacing: ${({ theme }) => theme.newtheme.font.corpo2.espacamento};
  font-weight: ${({ theme }) => theme.newtheme.font.corpo2.peso};
  font-size: ${({ theme }) => theme.newtheme.font.corpo2.tamanho};
  line-height: 27px;

  padding-right: 40px;
  cursor: pointer;
  min-width: 16px;
  user-select: none;
  border-radius: 0;
  margin: 0;
  display: block;
  min-width: 0;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  ${({ disabled }) =>
    disabled
      ? css`
          background-image: url(${chevronDownGray});
        `
      : css`
          background-image: url(${chevronDownRed});
        `}
  background-position: 100% 50%;
  background-repeat: no-repeat;
  &::-ms-expand {
    display: none; /* Hide the default arrow in Internet Explorer 10 and Internet Explorer 11 */
  }
`;

export const StatusBar = styled.span`
  display: block;
  position: relative;
  margin-top: -1px;
  height: 2px;
  width: 100%;
  &:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    transform: scaleX(0);
    transition: transform 0.25s ease, transform 0.25s ease;
  }
`;

export const Label = styled.span`
  color: ${({ theme, color }) => color || theme.newtheme.color.cinza6};
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  letter-spacing: ${({ theme }) => theme.newtheme.font.caption.espacamento};
  font-weight: ${({ fontweight, theme }) =>
    fontweight || theme.newtheme.font.caption.peso};
  font-size: ${({ theme }) => theme.newtheme.font.caption.tamanho};
  line-height: 24px;

  ${({ marginbottom }) =>
    marginbottom &&
    css`
      margin-bottom: ${marginbottom};
    `}
`;

export const Field = styled.div`
  min-width: fit-content;
  width: ${({ largura }) => largura || '50%'};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: ${({ minHeight }) => minHeight && minHeight};
  ${({ marginLeft }) =>
    marginLeft &&
    css`
      margin-left: ${marginLeft};
    `}
  ${({ marginRight }) =>
    marginRight &&
    css`
      margin-right: ${marginRight};
    `}
  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover ${StatusBar}:after {
        background-color: rgba(0, 0, 0, 0.45);
        transform: scaleX(1);
      }
      & ${SelectComp}:focus + ${StatusBar}:after {
        background-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
        transform: scaleX(1);
      }
    `}

  ${({ error }) =>
    error &&
    css`
      & ${SelectComp}:focus + ${StatusBar}:after {
        background-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
        transform: scaleX(1);
      }
    `}
`;

export const InputFeedback = styled.div`
  color: ${({ theme }) => theme.newtheme.color.alert};
  font-weight: 400;
  margin-top: 5px;
`;

import { styled } from '../../../../stitches.config';

export const Input = styled('input', {
  accentColor: '#008894',
});

export const InputNumber = styled('input', { width: '80px' });

export const InputLabel = styled('label', {
  color: '#ffffff',
  fontSize: '0.875rem',
});

export const Container = styled('div', {
  position: 'absolute',
  right: '-315px',
  top: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: '0.53rem',
  zIndex: '999',
  maxWidth: '284px',
  backgroundColor: 'rgb(0 0 0 / 85%)',
  padding: '1rem',
  borderRadius: '10px',
});

export const LabelValue = styled('span', {
  color: '#000000',
  border: '1px solid gray',
  height: '24px',
  backgroundColor: 'rgb(226 226 226)',
  textAlign: 'center',
  display: 'inline-flex',
  width: '80px',
  flexShrink: 0,
});

export const Row = styled('div', {
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  gap: '1rem',
});

export const Col = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '250px',
});

export const Button = styled('button', {
  position: 'absolute',
  right: '20px',
  top: '20px',
  zIndex: '999',
  backgroundColor: '#000000',
  color: '#ffffff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '36px',
  height: '36px',
  borderRadius: '8px',
  cursor: 'pointer',
  border: 'none',
});

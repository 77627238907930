import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import { connect } from 'react-redux';
import { currency, dateTime, date } from '../Formatter';
import { CONDICIONANTE } from '../../utils/groupColorsConst';
import InitialLetters from '../InitialLetters';
import ButtonChevron from './ButtonChevron';
import { Theme } from '../../theme';

import {
  Title,
  WrapperProfiler,
  // PicProfiler,
  // User,
  LettersIn,
  Wrapper,
  Label,
  TextValue,
  SeparatorVertical,
  // LabelText,
  Loading,
  LoadingBar,
  // BtnIcoAction,
  // Send,
  // Check,
  // Trash2,
  Tag,
} from '../Elements';

import { Button } from '../Button';

const WrapperContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 1rem;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 0;
  height: 12vh;
  border-top: 1px solid ${Theme.gray2};
  border-bottom: 1px solid ${Theme.gray2};
`;

const Loader = () => {
  return (
    <div
      style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
    >
      <Loading lWidth="2rem" aHeight="2rem">
        <LoadingBar />
        <LoadingBar />
        <LoadingBar />
        <LoadingBar />
      </Loading>
    </div>
  );
};

const Content = (props) => {
  return (
    <div
      style={{
        backgroundColor: `${Theme.gray1}`,
        padding: '1.5rem',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
      }}
    >
      <Wrapper>
        <TextValue hAlign="left">Descrição</TextValue>
        <Label hAlign="left">{props.texto}</Label>
      </Wrapper>
      <Wrapper>
        <TextValue hAlign="left">Observação do empreendedor</TextValue>
        <Label hAlign="left">{props.obs}</Label>
      </Wrapper>
    </div>
  );
};

const ItemCondicionante = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [color, setColor] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [label, setLabel] = useState(props.status);
  const [showContent, setShowContent] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setColor(
      !CONDICIONANTE[props.status]
        ? CONDICIONANTE.default
        : CONDICIONANTE[props.status]
    );
  }, []);

  const controlAppereanceContent = () => {
    setShowContent(!showContent);
  };

  const concluir = () => {
    // setLoading(true);
    props.action('CONCLUIR', props.data.idCondicionante);
  };

  const excluir = () => {
    // setLoading(true);
    props.action('EXCLUIR', props.data.idCondicionante);
  };

  const cancelar = () => {
    // setLoading(true);
    props.action('CANCELAR', props.data.idCondicionante);
  };

  return (
    <div>
      <WrapperContent>
        <div
          style={{
            display: 'flex',
            position: 'absolute',
            zIndex: 99,
            alignItems: 'center',
            height: '100%',
          }}
        >
          <WrapperProfiler valign="center">
            {/* <PicProfiler bgcolor={color.colorBorder}>
              <User size="14px" color="#FFF" />
            </PicProfiler> */}
            <LettersIn
              bgcolor={'white'}
              color={Theme.primary}
              style={{
                fontWeight: '400',
                boxShadow: '0px 3px 7px -1px rgba(0,0,0,0.23)',
              }}
            >
              <InitialLetters letters={props.data.cedente} />
            </LettersIn>
          </WrapperProfiler>
          <SeparatorVertical wsize="1rem" />
          <Wrapper style={{ width: '220px' }}>
            <Tag
              size="0.7rem"
              alheight="1rem"
              style={{ borderRadius: '0.1rem' }}
            >
              {props.status}
            </Tag>
            <Title
              mgright="0"
              lwidth="180px"
              bolder="400"
              colorText={Theme.gray6}
            >
              {props.data.cedente}
            </Title>
            <Label size="1rem" hAlign="left">
              {'78.520.532/0001-47'}
            </Label>
          </Wrapper>
          {/* <SeparatorVertical wsize="2rem" />
          <Wrapper style={{ width: '130px' }}>
            <Label fsize="1rem">Descrição</Label>
            <LabelText
              size="1.4rem"
              hAlign="left"
              lwidth="127px"
              color={Theme.gray6}
            >
              {props.data.texto}
            </LabelText>
            </Wrapper> */}
          <SeparatorVertical wsize="2rem" />
          <Wrapper style={{ width: '150px' }}>
            <Label fsize="1rem">Valor</Label>
            <Label size="1.4rem" hAlign="left" color={Theme.gray6}>
              {currency(props.data.valor === null ? 0 : props.data.valor)}
            </Label>
          </Wrapper>
          <SeparatorVertical wsize="2rem" />
          <Wrapper>
            <Label fsize="1rem">Data criação</Label>
            <Label size="1.4rem" hAlign="left" color={Theme.gray6}>
              {dateTime(props.data.dtInicial)}
            </Label>
          </Wrapper>
          <SeparatorVertical wsize="2rem" />
          <Wrapper>
            <Label fsize="1rem">Validade</Label>
            <Label size="1.4rem" hAlign="left" color={Theme.gray6}>
              {date(props.data.dtValidade)}
            </Label>
          </Wrapper>
          <SeparatorVertical wsize="2rem" />
          <Wrapper lwidth="220px" style={{ alignItems: 'flex-end' }}>
            {props.status === 'ativo' && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => concluir()}
                  style={{ width: '100px' }}
                >
                  {loading && <Loader />}
                  Concluir
                </Button>
                <Button
                  style={{ marginLeft: '1rem', width: '100px' }}
                  size="small"
                  type="orange"
                  onClick={() => cancelar()}
                >
                  {loading && <Loader />}
                  Cancelar
                </Button>
              </div>
            )}
            {props.status === 'pendente' && (
              <Button
                size="small"
                type="orange"
                style={{ width: '100px' }}
                onClick={() => excluir()}
              >
                {loading && <Loader />}
                Excluir
              </Button>
            )}
          </Wrapper>
        </div>
        <ButtonChevron
          type={showContent}
          appereanceContent={controlAppereanceContent}
          color={Theme.primary}
        />
      </WrapperContent>
      {showContent && <Content texto={props.data.texto} obs={props.data.obs} />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoadingCondic: state.condicionante.isLoadingCondic,
  };
};

export default connect(mapStateToProps)(ItemCondicionante);

const INITIAL_STATE = {
  data: [],
  isLoading: false,
  error: false,
};

const user = (state = INITIAL_STATE, action) => {
  //console.log(action);
  switch (action.type) {
    case 'SET_DATA_USER':
      return {
        ...state,
        data: action.data,
        isLoading: false,
      };
    case 'REQUEST_DATA_USER':
      return {
        ...state,
        isLoading: true,
      };
    case 'DATA_USER_SUCESS':
      return {
        ...state,
        data: action.data,
        isLoading: false,
      };
    case 'DATA_USER_FAILURE':
      return {
        ...state,
        data: action.data,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default user;

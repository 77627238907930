import React from 'react';

import { NWNoResultFound, NWError } from 'components/NWPlaceholder';
import { Ring } from '@uiball/loaders';
import { Container } from 'components/NWPlaceholder/style.placeholder';

const checkArray = (value) => {
  return Array.isArray(value) ? value.length === 0 : !value;
};

export function useRenderResponseData({ ...props }) {
  const { data, isLoading, error } = props;

  return React.useMemo(() => {
    let PlaceNoResult = () => null;
    let PlaceLoader = () => null;
    let PlaceError = () => null;

    if (isLoading) {
      PlaceLoader = () => (
        <Container>
          <Ring size={40} lineWeight={5} speed={2} color="#008894" />
        </Container>
      );
    }

    if (error) {
      PlaceError = () => <NWError />;
    }

    const dados = data && checkArray(data.data || data);

    if (dados) {
      PlaceNoResult = () => <NWNoResultFound />;
    }

    return { PlaceNoResult, PlaceLoader, PlaceError };
  }, [data, isLoading, error]);
}

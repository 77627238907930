import { connect } from 'react-redux';
import './stylePwa.scss';
import './App.scss';
import 'react-loading-skeleton/dist/skeleton.css';

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
body{
  /*overflow: ${(props) => (props.scrollingBody ? 'initial' : 'hidden')};*/
  -webkit-font-smoothing: antialiased !important;
}



:root {
  --select-border: #777;
  --select-focus: blue;
  --select-arrow: var(--select-border);
  --fingray-900: #2e2e2e;
  --fingray-500:#646464;
  --fingray-400: #8D8D91;
  --fingray-300: #f2f2f3;
  --fingray-200: #EAEAEB;
  --fingray-100: #f6f6f6;
  --finborder-color:  #e3e3e7;
  --finblue-secondary: #D6FBFF;
  --finblue-300: #CCE7EA;
  --finblue-primary: #008894;
  --finblue-tertiary: #EBFDFF;
  --fingreen300: #3B5C44;
  --finerror: #D92525;
  --finred-500: #F75E4C;
  --finred-600: #f54a36;
  --finred100: #FFEEEE;
  --finorange-100: #FCE4BB;
  --fingreen-100: #CDF9D8;
  --fingreen-200: #89D99D;
  --fingreen-300: #80D094;
  --finwhite-100: #ffffff;
  --finyellow-500: hsl(37, 100%, 56%);
}

.sk-player-container {
  max-width: 750px;
  max-height: 420px;
}

`;

const mapStateToProps = (state) => {
  return {
    scrollingBody: state.reducerUI.scrollingBody,
  };
};

export default connect(mapStateToProps)(GlobalStyle);

import React from 'react';
import styled from 'styled-components/macro';

import { Caption } from 'components/FinParagraph';
import { Theme } from '../../theme';

const Tbody = styled.tbody`
  color: ${Theme.gray3};
`;
const Tr = styled.tr`
  background-color: transparent;
`;
const Td = styled.td`
  padding: 0.3rem 0;
  color: #000;
  font-size: 0.9rem;
`;

const Body = ({ data }) => {
  const renderContent = () => {
    return (
      data.length > 0 &&
      data.map((res, index) => {
        return (
          <Tr key={index}>
            {Object.keys(res).map((r, i) => (
              <Td key={i}>
                <Caption>{res[r]}</Caption>
              </Td>
            ))}
          </Tr>
        );
      })
    );
  };
  return <Tbody>{renderContent()}</Tbody>;
};

export default Body;

import React from 'react';
import AbleStep from 'assets/icones/icone-able-step.svg';
import RefusedStep from 'assets/icones/icone-refused-step.svg';

import { Step } from './Step';

export const HabilitacaoRecusada = () => {
  return (
    <Step
      bgcolor="#FDDCDC"
      icon={AbleStep}
      statuicon={RefusedStep}
      label="Habilitação"
      labelsecundaria="Recusada"
    />
  );
};

import React, { createElement, useMemo } from 'react';

import { useTooltip } from '@nivo/tooltip';

import { Bar } from '@nivo/bar';
import { currency } from 'helpers/format';
import { capitalizeFirstLetter } from 'helpers/string';
import { theme } from '../../Metricas/theme';
import {
  Container,
  Label,
  Lagend,
  TooltipRoot,
  Title,
  Bullet,
} from './style.bar-chart';

const commonProps = {
  theme,
  width: 780,
  height: 300,
  padding: 0.5,
  margin: { top: 50, right: 130, bottom: 100, left: 80 },
  innerPadding: 1,
  groupMode: 'grouped',
  layout: 'horizontal',
  indexBy: 'tipo',
  labelTextColor: 'inherit:darker(1.4)',
  labelSkipWidth: 16,
  labelSkipHeight: 16,
};

const CustomBarComponent = (props) => {
  const { showTooltipFromEvent, hideTooltip } = useTooltip();

  const { bar, tooltip } = props;
  const { x, y, width, height, color, data } = bar;

  const renderTooltip = useMemo(
    () => () => createElement(tooltip, { ...bar, ...data }),
    [tooltip, bar, data]
  );

  const d = `M${x},${y} l0,0 l-${0},0 l-0,${height} l${width},0 c${10},0 ${10},-${height} 0 -${height}`;

  return (
    <path
      d={d}
      fill={color}
      onMouseEnter={(event) => showTooltipFromEvent(renderTooltip(), event)}
      onMouseMove={(event) => showTooltipFromEvent(renderTooltip(), event)}
      onMouseLeave={() => hideTooltip()}
    />
  );
};

function CustomSymbol({
  x,
  y,
  size,
  fill,
  opacity = 1,
  borderWidth = 0,
  borderColor = 'transparent',
}) {
  return (
    <circle
      r={size / 2}
      cx={x + size / 2}
      cy={y + size / 2}
      fill={fill}
      opacity={opacity}
      strokeWidth={borderWidth}
      stroke={borderColor}
      style={{
        pointerEvents: 'none',
      }}
    />
  );
}

const legend = [
  {
    dataFrom: 'keys',
    anchor: 'bottom',
    direction: 'row',
    justify: false,
    translateX: 0,
    translateY: 90,
    itemsSpacing: 2,
    itemWidth: 100,
    itemHeight: 20,
    itemDirection: 'left-to-right',
    itemOpacity: 0.85,
    symbolSize: 18,
    symbolShape: (props) => <CustomSymbol {...props} />,
    effects: [
      {
        on: 'hover',
        style: {
          itemOpacity: 1,
        },
      },
    ],
  },
];

const ignore = ['tipo', 'label'];
const found = (value) => ignore.some((item) => value.includes(item));

export function ChartPrazo({ data }) {
  const { prazos } = data;

  const dataKeys = Object.keys(prazos[0]);
  const keys = dataKeys.filter((res) => !found(res));

  const divergingCommonProps = {
    ...commonProps,
    valueScale: { type: 'linear' },
    indexScale: { type: 'band', round: true },
    enableGridX: true,
    enableGridY: false,
    colors: { scheme: 'nivo' },
    axisTop: null,
    axisBottom: {
      legend: '',
      legendPosition: 'middle',
      legendOffset: 50,
      tickSize: 0,
      tickPadding: 12,
      tickRotation: -15,
      format: (value) => `${currency(value)}`,
    },
    axisLeft: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Endividamento',
      legendPosition: 'middle',
      legendOffset: -90,
      format: (value) =>
        `${prazos.filter((res) => res.tipo === value)[0].label}`,
    },
  };

  return (
    <Container style={{ padding: '8px' }}>
      <Bar
        {...divergingCommonProps}
        keys={keys}
        data={prazos}
        barComponent={CustomBarComponent}
        // colors={({ id, data }) => String(data[`${id}Color`])}
        colors={['#77F2FF', '#06CAE0', '#03707C', '#89D99D', '#F2A71B']}
        valueFormat={(v) => currency(v)}
        tooltip={(props) => {
          const { data, id, color } = props;
          const label = data[`label${capitalizeFirstLetter(id)}`];
          return (
            <TooltipRoot>
              <Bullet css={{ bgColor: color }} />
              <Title>{data.label}</Title>
              <Label>{label}</Label>
              <Label>{currency(props.value)}</Label>
            </TooltipRoot>
          );
        }}
        legends={legend}
        legendLabel={(datum) => {
          const { data, id } = datum;
          const label = data[`label${capitalizeFirstLetter(id)}`];
          return label;
        }}
      />
      <Lagend>$ Valores em milhares de Reais</Lagend>
    </Container>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Wrapper } from 'components/FinWrappers';

import styled, { css } from 'styled-components/macro';
import { darken } from 'polished';

export const ButtonRoundedStyle = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ size }) => css`
    width: ${size};
    height: ${size};
  `}

  border-radius: 50%;
  border: none;
  outline: none;
  padding: 5px;
  cursor: pointer;
  transition: all 0.2s;
  z-index: 99;

  ${({ positionButton }) =>
    positionButton.map(
      (item) =>
        css`
          ${item}
        `
    )}

  ${({ bgColor }) => css`
    background-color: ${bgColor};
  `}

  ${({ hoverColor, bgColor }) => css`
    &:hover {
      background-color: ${darken(0.08, bgColor)};
      & svg {
        stroke: ${hoverColor};
        & path {
          stroke: ${hoverColor};
        }
      }
    }
  `}
`;

export const ButtonRounded = ({
  onClick,
  hoverColor,
  bgColor,
  id,
  tooltipText,
  children,
  positionButton,
  placeTooltip,
  size,
  label,
  className,
}) => {
  const SIZES = {
    padrao: '30px',
    small: '28px',
    tiny: '25px',
    medium: '40px',
    large: '44px',
  };
  const withLabelStyles = label
    ? {
        minWidth: 'fit-content',
        paddingRight: '12px',
        borderRadius: '50px',
      }
    : {};
  return (
    <>
      {tooltipText && (
        <ReactTooltip
          id={`tip-${id}`}
          wrapper="span"
          place={placeTooltip}
          type="dark"
          effect="solid"
        >
          {tooltipText}
        </ReactTooltip>
      )}
      <ButtonRoundedStyle
        positionButton={positionButton}
        bgColor={bgColor}
        hoverColor={hoverColor}
        onClick={onClick}
        data-tip
        data-for={`tip-${id}`}
        size={SIZES[size]}
        style={withLabelStyles}
        className={className}
      >
        <Wrapper pwidth="inherit" pheight="inherit" padding="4px">
          {children}
        </Wrapper>
        {label && <div>{label}</div>}
      </ButtonRoundedStyle>
    </>
  );
};

ButtonRounded.propTypes = {
  id: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  children: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  bgColor: PropTypes.string,
  positionButton: PropTypes.array,
  onClick: PropTypes.func,
  hoverColor: PropTypes.string,
  placeTooltip: PropTypes.string,
  size: PropTypes.oneOf(['padrao', 'small', 'tiny', 'medium', 'large']),
  label: PropTypes.string,
};

ButtonRounded.defaultProps = {
  disabled: false,
  onClick: null,
  bgColor: '#fff',
  hoverColor: '#000000',
  positionButton: ['position: relative'],
  placeTooltip: 'bottom',
  size: 'padrao',
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Wrapper } from 'components/FinWrappers';

const CheckboxComponent = styled.input`
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    background: white;
    border: 2px solid ${({ theme }) => theme.newtheme.color.cinza2};
    border-radius: 0.2rem;
    position: relative;
    top: -1px;
  }

  &:hover + label:before {
    background: ${({ theme }) => theme.newtheme.color.azulPrimario};
  }

  &:focus + label:before {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.12);
  }

  &:checked + label:before {
    background: ${({ theme }) => theme.newtheme.color.azulPrimario};
    border: 2px solid ${({ theme }) => theme.newtheme.color.azulPrimario};
  }

  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    left: 3px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
`;

const Label = styled.label.attrs(({ para }) => ({
  htmlFor: para,
}))`
  color: ${({ theme, color }) => color || theme.newtheme.color.cinza6};
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  letter-spacing: ${({ theme }) => theme.newtheme.font.caption.espacamento};
  font-weight: ${({ theme }) => theme.newtheme.font.caption.peso};
  font-size: ${({ theme }) => theme.newtheme.font.caption.tamanho};
  line-height: 21px;
`;

const CustonWrapper = styled(Wrapper)`
  position: relative;
`;

const Checkbox = ({
  handleChange,
  disabled,
  label,
  name,
  value,
  checked,
  padding,
}) => {
  return (
    <CustonWrapper tipo="column" padding={padding}>
      <CheckboxComponent
        type="checkbox"
        name={name}
        value={value}
        id={`${name}-${value}`}
        disabled={disabled}
        onChange={handleChange}
        checked={checked}
      ></CheckboxComponent>
      {label && <Label para={`${name}-${value}`}>{label}</Label>}
    </CustonWrapper>
  );
};

Checkbox.propTypes = {
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.any,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
};

Checkbox.defaultProps = {
  handleChange: null,
  disabled: false,
  label: '',
  name: null,
  value: '',
  checked: false,
};

export default Checkbox;

import React from 'react';

import { ReactComponent as IconReceivables } from 'assets/images/icon-receivables.svg';
import { ReactComponent as IconLoan } from 'assets/images/icon-loan.svg';
import { ReactComponent as IconCoins } from 'assets/images/icon-coins.svg';
import { ReactComponent as IconIventment } from 'assets/images/icon-investment.svg';
import { useParams } from 'react-router-dom';
import { Flex } from './style.credito';
import { TitleInteresseCredito } from '../Comps/TitleHead';
import { CardTypes } from '../Comps/CardTypes';
import { useInteresseCredito } from '../hooks';

import { SkeletonLoading } from '../Comps/Skeleton';

const tipos = {
  1: <IconReceivables />,
  2: <IconLoan />,
  3: <IconCoins />,
  4: <IconIventment />,
};

export function InteresseCredito() {
  const { idGrupo } = useParams();
  const { data, isLoading } = useInteresseCredito({ idGrupo });

  return (
    <Flex column align="flex-start" style={{ margin: '3rem 0px' }}>
      <TitleInteresseCredito />
      <Flex
        justify="flex-start"
        style={{ marginTop: '0.813rem', position: 'relative' }}
      >
        {isLoading && <SkeletonLoading />}
        {data &&
          !isLoading &&
          data.map((item) => {
            return (
              <CardTypes
                isActive={item.escolhido}
                key={item.idTipoCredito}
                label={item.descricao}
                icon={tipos[item.idTipoCredito]}
              />
            );
          })}
      </Flex>
    </Flex>
  );
}

import { useQuery } from 'react-query';
import axios from 'axios';

import CONFIG from 'config';

const baseUrl = CONFIG.API_REMODELING_URL;

const fecthFilters = async () => {
  const response = axios.get(
    `${baseUrl}/business-api/api/matching/filtros/financiador`
  );
  const result = await response;
  return result.data || [];
};

export const useQueryFilters = () => {
  return useQuery(['filters', 'list'], fecthFilters, {
    keepPreviousData: false,
  });
};

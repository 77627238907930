import React, { useContext } from 'react';

import { Flex } from './styles.infocontato';
import { InfoContato } from './InfoContato';
import { TitleContato } from '../Comps/TitleHead';
import { PerfilContext } from '../PaginaFinCredit';

export function Contato() {
  const { perfil } = useContext(PerfilContext);

  if (perfil.data) {
    return (
      perfil.data.criptografia === false && (
        <Flex
          column
          align="flex-start"
          style={{
            marginBottom: '48px',
          }}
        >
          <TitleContato />
          <Flex
            column
            align="flex-start"
            style={{
              marginTop: '13px',
            }}
          >
            <InfoContato
              label="Site"
              text={
                perfil && perfil.data && perfil.data.site
                  ? perfil.data.site
                  : ''
              }
            />
            <InfoContato
              label="E-mail"
              text={
                perfil && perfil.data && perfil.data.email
                  ? perfil.data.email
                  : ''
              }
            />
            <InfoContato
              label="Telefone"
              text={
                perfil && perfil.data && perfil.data.telefone
                  ? perfil.data.telefone
                  : ''
              }
            />
          </Flex>
        </Flex>
      )
    );
  }

  return null;
}

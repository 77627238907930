/* eslint-disable no-console */
import React from 'react';
// import { genericSigninCallback, getUser } from 'auth/nwUserManager';

import { Redirect } from 'react-router-dom';

export function OnSigninCallback() {
  // const [user, setUser] = React.useState(undefined);
  // const [redirecionar, setRedirecionar] = React.useState(false);

  // React.useEffect(() => {
  //   const url = (window && window.location && window.location.href) || '';
  //   genericSigninCallback(url)
  //     .then(() => getUser().then((loggedUser) => setUser(loggedUser)))
  //     .catch(() => setRedirecionar(true));
  // }, []);

  // if (!user) {
  //   return null;
  // }

  // if (redirecionar) {
  //   return <Redirect to="/login" push />;
  // }

  return (
    <Redirect to={localStorage.getItem('historico_navegacao') || '/'} push />
  );
}

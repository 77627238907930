import styled, { css } from 'styled-components/macro';

export const Flex = styled.div`
  display: flex;
  position: relative;
  ${({ row }) =>
    row &&
    css`
      flex-direction: row;
    `}

  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
    `}

  justify-content: ${({ justify }) => justify || `center`};
  align-items: ${({ align }) => align || `center`};
  width: 100%;
`;

export const CardContainer = styled.div`
  position: sticky;
  /* top: 30px; */
  top: -180px;
  z-index: 180;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  border-radius: 18px;
  margin-top: -225px;
  /* margin-top: -50px; */
  transition: margin-top 0.4s ease-in-out 0s;
`;

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 1.5rem;
  max-height: 600px;
  overflow-y: scroll;

  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 18px;
`;

export const RegionVideoPlay = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  background: #ffffff;
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
  height: 200px;
`;

export const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 99;
  transform: translate(-50%, -50%);
  background: linear-gradient(
    180deg,
    rgba(28, 29, 31, 0) 0%,
    rgba(28, 29, 31, 0.9) 100%
  );
`;

export const LabelVideo = styled.span`
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 700;
  text-rendering: geometricprecision;
  white-space: nowrap;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  bottom: 20px;
`;

export const ButtonPlay = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  outline: none;
  border: none;
  background: var(--finblue-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    filter: brightness(80%);
  }
  &:active {
    filter: brightness(100%);
  }
`;

export const Thumbnail = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  overflow: hidden;
`;

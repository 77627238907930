import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// import { useFeedback } from 'hooks/useFeedback';

import * as Yup from 'yup';
import { Formik } from 'formik';

import styled, { css } from 'styled-components/macro';
import { rgba } from 'polished';
import ScaleLoader from 'react-spinners/ScaleLoader';

// import Match from './MeusDados/Match';

// import DashboardMatching from './MeusDados/DashboardMatching';
import {
  loadCepRequest,
  clearCep,
  loadPerfilRequest,
  postPerfilRequest,
  clearPerfil,
  loadCampanhaRequest,
  postCampanhaRequest,
  clearCampanha,

  // loadDataCNAE,
} from 'store/old_actions/actions';
import { Theme } from '../theme';
import ImageIco from '../images/iconsSvg/touch-id.svg';
// import { IconInclude } from '../components/Elements/icons';
// import profile from '../images/profile.jpeg';

import {
  SeparatorVertical,
  SeparatorHorizontal,
  LettersIn,
} from '../components/Elements';

import { maskCNPJ } from '../utils/formatValues';
import InitialLetters from '../components/InitialLetters';

import { InputMaterial, InputTextAreaMaterial } from '../components/Input';
import { /* Button */ ButtonSubmit } from '../components/Button';
import { Select, MultiSelect } from '../components/Select';
import CalendarsDates from '../utils/calendar';

import TextInputFormikRedux from '../components/Input/localizacao';

// import Campanha from '../components/CampanhaCodigo';
// import { values } from '../../fake-server/operacao/data';

const FullContainerLoad = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${rgba('#ffffff', 0.8)};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  padding: 10rem 0;
`;

const Container = styled.div`
  margin: 0 auto;
  width: 78%;
  padding-top: 1rem;
  padding-bottom: 10rem;
`;

const Wrapper = styled.div`
  padding: 4rem 0 3rem 0;
`;

const Title = styled.span`
  font-weight: 600;
  color: ${Theme.gray6};
  font-size: 2.3rem;
  &::before {
    background: url(${ImageIco}) no-repeat;
    content: '';
    padding: 2rem;
    position: relative;
    left: 2px;
    top: 23px;
    margin-right: 27px;
  }
`;

const Card = styled.div`
  position: relative;
  width: 100%;
  padding: 3rem;

  border-radius: 0.3rem;
  background-color: #ffffff;
  min-height: 350px;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 1rem;
`;

const ImgeProfiler = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  background-color: #ffffff;
  border-radius: 90rem;
  box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
`;

/* const IconButton = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 90rem;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: ${Theme.primary};
  transform-origin: center;
  transition: all 195ms ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const Image = styled.div`
  background: url(${profile}) no-repeat 0 0;
  width: 100%;
  height: 100%;
  border-radius: 90rem;
  background-size: cover;
`; */

const StatusBar = styled.span`
  display: block;
  position: relative;
  margin-top: -1px;
  height: 2px;
  width: 100%;
  &:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    transform: scaleX(0);
    transition: transform 0.25s ease, transform 0.25s ease;
  }
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &:hover ${StatusBar}:after {
    background-color: rgba(0, 0, 0, 0.45);
    transform: scaleX(1);
  }
`;

const Label = styled.span`
  color: ${({ color }) => color};
  font-weight: ${({ fontweight }) => fontweight};
  ${({ marginbottom }) =>
    marginbottom &&
    css`
      margin-bottom: ${marginbottom};
    `}
`;

const Text = styled.span`
  color: ${Theme.gray6};
  font-weight: 400;
`;

const MeusDados = (props) => {
  const [verCarregando, setVerCarregando] = useState(true);
  const [initialVal, setInitialVal] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [porte, setPorte] = useState([]);
  const [capital, setCapital] = useState([]);

  const phoneRegExp = /(?:\()[0-9]{2}(?:\))\s?[0-9]{4,5}(?:-)[0-9]{4}$/;
  const cepRegRex = /^[0-9]{5,5}(?:-)?[0-9]{3,3}$/;

  const schema = Yup.object().shape({
    razao: Yup.string().trim().required('Preencha esse dado.'),
    usuario: Yup.string().required('Preencha esse dado.'),
    sobrenome: Yup.string().required('Preencha esse dado.'),
    cep: Yup.string()
      .matches(cepRegRex, 'Cep inválido')
      .required('Preencha esse dado.'),
    estado: Yup.string().required('Preencha esse dado.'),
    cidade: Yup.string().required('Preencha esse dado.'),
    bairro: Yup.string().required('Preencha esse dado.'),
    rua: Yup.string().required('Preencha esse dado.'),
    numero: Yup.string().required('Preencha esse dado.'),
    complemento: Yup.string().nullable(),
    site: Yup.string()
      .url('Url inválida ex(http://www.finplace.com.br)')
      .required('Preencha esse dado.'),
    emailEmpresa: Yup.string()
      .email('Preencha esse dado.')
      .required('Preencha esse dado.'),
    telefoneEmpresa: Yup.string()
      .matches(phoneRegExp, 'Telefone inválido')
      .required('Preencha esse dado.'),
    telefone: Yup.string()
      .trim()
      .matches(phoneRegExp, 'Telefone inválido')
      .required('Preencha esse dado.'),
    fundacaoDia: Yup.string()
      .notOneOf(['0'], 'Preencha esse dado.')
      .required('Obrigatório'),
    fundacaoMes: Yup.string()
      .notOneOf(['0'], 'Preencha esse dado.')
      .required('Obrigatório'),
    fundacaoAno: Yup.string()
      .notOneOf(['0'], 'Preencha esse dado.')
      .required('Obrigatório'),
    porte: Yup.string()
      .notOneOf(['0'], 'Preencha esse dado.')
      .required('Preencha esse dado.'),
    capital: Yup.string()
      .notOneOf(['0'], 'Preencha esse dado.')
      .required('Preencha esse dado.'),
    // produtos: Yup.array().required('Escolha uma opção'),
    produtos: Yup.array()
      .of(
        Yup.object().shape({
          idProduto: Yup.number(),
          nome: Yup.string(),
          status: Yup.boolean(),
        })
      )
      .compact((v) => !v.status)
      .required('Escolha uma opção'),
    sobre: Yup.string().trim().nullable().required('Preencha esse dado.'),
  });

  const submitContent = (payload, { setSubmitting }) => {
    // eslint-disable-next-line no-console
    console.log(payload);

    const fields = {
      nome: payload.usuario,
      sobrenome: payload.sobrenome,
      fundacao: `${CalendarsDates.convertYears(
        payload.fundacaoAno
      )}-${CalendarsDates.convertMonth(
        payload.fundacaoMes,
        'id'
      )}-${CalendarsDates.convertDay(payload.fundacaoDia)}`,
      logradouro: payload.rua,
      numero: payload.numero,
      complemento: payload.complemento,
      bairro: payload.bairro,
      cidade: payload.cidade,
      uf: payload.estado,
      cep: payload.cep,
      idPorte: Number(payload.porte),
      idCapital: Number(payload.capital),
      site: payload.site,
      email: payload.razao,
      emailEmpresa: payload.emailEmpresa,
      telefone: payload.telefone,
      telefoneEmpresa: payload.telefoneEmpresa,
      sobre: payload.sobre,
      produtos: payload.produtos,
    };

    // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setVerCarregando(true);
    props.postPerfilRequest(fields);

    setSubmitting(false);
  };

  const buscaCep = (value) => {
    props.loadCep(value);
  };

  useEffect(() => {
    if (Object.keys(props.dataPerfil).length > 0) {
      const initialValues = {
        razao: props.dataPerfil.razaoSocial || '',
        usuario: props.dataPerfil.nome || '',
        sobrenome: props.dataPerfil.sobrenome || '',
        cep: props.dataPerfil.cep || '',
        estado: props.dataPerfil.uf || '',
        cidade: props.dataPerfil.cidade || '',
        bairro: props.dataPerfil.bairro || '',
        rua: props.dataPerfil.logradouro || '',
        numero: props.dataPerfil.numero || '',
        complemento: props.dataPerfil.complemento || '',
        site: props.dataPerfil.site || '',
        telefone: props.dataPerfil.telefone || '',
        emailEmpresa: props.dataPerfil.emailEmpresa || '',
        telefoneEmpresa: props.dataPerfil.telefoneEmpresa || '',
        fundacaoDia: CalendarsDates.convertDay(
          props.dataPerfil.fundacao || '00/00/0000',
          'id'
        ),
        fundacaoMes: CalendarsDates.convertMonth(
          props.dataPerfil.fundacao || '00/00/0000',
          'id'
        ),
        fundacaoAno: CalendarsDates.convertYears(
          props.dataPerfil.fundacao || '00/00/0000',
          'id'
        ),
        porte:
          props.dataPerfil.optionsPorte
            .filter((res) => res.selecionado)
            .map((r) => r.id)[0] || 0,
        capital:
          props.dataPerfil.optionsCapital
            .filter((res) => res.selecionado)
            .map((r) => r.id)[0] || 0,
        produtos: props.dataPerfil.produtos,
        sobre: props.dataPerfil.sobre || '',
      };

      setPorte([
        { id: 0, nome: 'Selecionar', selecionado: false },
        ...props.dataPerfil.optionsPorte,
      ]);
      setCapital([
        {
          id: 0,
          nome: 'Selecionar',
          selecionado: false,
        },
        ...props.dataPerfil.optionsCapital,
      ]);
      setInitialVal(initialValues);
      const produtos = props.dataPerfil.produtos.map((res, index) => ({
        id: index,
        name: res.nome.split(' ')[0].toLowerCase(),
        produtos: res.nome.split(' ')[0],
      }));

      setProdutos(produtos);
      setVerCarregando(false);
    }
    return () => setInitialVal([]);
  }, [props.dataPerfil]);

  useEffect(() => {
    props.clearCeps();
    props.clearPerfil();
    props.loadPerfilRequest();
    return () => {
      props.clearCeps();
      props.clearPerfil();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!props.isLoadingPerfil && props.isLoadingPerfil !== null) {
      setVerCarregando(false);
    }
  }, [props.isLoadingPerfil]);

  return (
    <>
      <Container>
        <Wrapper>
          <Title>Meus Dados</Title>
        </Wrapper>

        <Card>
          {verCarregando && (
            <FullContainerLoad>
              <ScaleLoader
                css=""
                size={150}
                color={Theme.primary}
                loading={true}
              />
            </FullContainerLoad>
          )}
          {Object.keys(initialVal).length > 0 && (
            <>
              <Row style={{ marginBottom: '3rem' }}>
                <ImgeProfiler>
                  {/* <Image />
                <IconButton>
                  <IconInclude
                    icon="camera"
                    bgcolor="#FFFFFF"
                    padding="0.5rem"
                  />
                </IconButton> */}
                  <LettersIn
                    bgcolor={Theme.primary}
                    color={Theme.white}
                    style={{
                      position: 'relative',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <InitialLetters
                      letters={`${props.dataPerfil.nome} ${props.dataPerfil.sobrenome}`}
                    />
                  </LettersIn>
                </ImgeProfiler>
                <SeparatorVertical borderColor={Theme.gray1} wsize="3rem" />
                <Field>
                  <Label color={Theme.gray6} fontweight="600">
                    CNPJ:
                  </Label>
                  <Text>{maskCNPJ(props.dataPerfil.cnpj)}</Text>
                  <SeparatorHorizontal hsize="0.5rem 0" />
                  <Label color={Theme.gray6} fontweight="600">
                    Razão social:
                  </Label>
                  <Text>{props.dataPerfil.razaoSocial}</Text>
                </Field>
                <SeparatorVertical borderColor={Theme.gray1} wsize="3rem" />
                <Field>
                  <Label color={Theme.gray6} fontweight="600">
                    E-mail de acesso:
                  </Label>
                  <Text>{props.dataPerfil.email}</Text>
                </Field>
              </Row>
              <Formik
                initialValues={initialVal}
                onSubmit={submitContent}
                validationSchema={schema}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    // dirty,
                    // isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    // handleReset,
                    setFieldValue,
                  } = props;
                  const handleCustonBlurCep = () => {
                    if (!errors.cep) {
                      buscaCep(values.cep);
                    }
                  };
                  return (
                    <form onSubmit={handleSubmit}>
                      <Row>
                        <InputMaterial
                          id="usuario"
                          label="Nome"
                          value={values.usuario}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          error={errors.usuario && touched.usuario}
                          errormessage={errors.usuario}
                        />
                      </Row>

                      <Row>
                        <InputMaterial
                          id="sobrenome"
                          label="Sobrenome"
                          value={values.sobrenome}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          error={errors.sobrenome && touched.sobrenome}
                          errormessage={errors.sobrenome}
                        />
                      </Row>

                      <Row>
                        <TextInputFormikRedux
                          label="CEP"
                          name="cep"
                          type="text"
                          largura="35%"
                          custonBlur={handleCustonBlurCep}
                          statusApi={true}
                        />
                      </Row>

                      <Row>
                        <TextInputFormikRedux
                          label="Estado"
                          name="estado"
                          type="text"
                          largura="35%"
                        />
                        <SeparatorVertical
                          borderColor={Theme.gray1}
                          wsize="2rem"
                        />
                        <TextInputFormikRedux
                          label="Cidade"
                          name="cidade"
                          type="text"
                          largura="35%"
                        />
                      </Row>

                      <Row>
                        <TextInputFormikRedux
                          label="Bairro"
                          name="bairro"
                          type="text"
                          largura="35%"
                        />
                      </Row>

                      <Row>
                        <TextInputFormikRedux
                          label="Rua/Avenida"
                          name="rua"
                          type="text"
                          largura="35%"
                        />
                        <SeparatorVertical
                          borderColor={Theme.gray1}
                          wsize="2rem"
                        />
                        <InputMaterial
                          id="numero"
                          label="Numero"
                          value={values.numero}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          error={errors.numero && touched.numero}
                          errormessage={errors.numero}
                          largura="35%"
                        />
                      </Row>

                      <Row>
                        <InputMaterial
                          id="complemento"
                          label="Complemento"
                          value={values.complemento}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          error={errors.complemento && touched.complemento}
                          errormessage={errors.complemento}
                          largura="35%"
                        />
                      </Row>

                      <SeparatorHorizontal hsize="2rem 0" />

                      <Row>
                        <InputMaterial
                          id="emailEmpresa"
                          label="Email empresa"
                          value={values.emailEmpresa}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          error={errors.emailEmpresa && touched.emailEmpresa}
                          errormessage={errors.emailEmpresa}
                          largura="35%"
                        />
                        <SeparatorVertical
                          borderColor={Theme.gray1}
                          wsize="2rem"
                        />
                        <InputMaterial
                          id="telefoneEmpresa"
                          label="Telefone empresa"
                          value={values.telefoneEmpresa}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          error={
                            errors.telefoneEmpresa && touched.telefoneEmpresa
                          }
                          errormessage={errors.telefoneEmpresa}
                          largura="35%"
                          format={true}
                          setFieldValue={setFieldValue}
                        />
                      </Row>

                      <Row>
                        <InputMaterial
                          id="site"
                          label="Site"
                          value={values.site}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          error={errors.site && touched.site}
                          errormessage={errors.site}
                          largura="35%"
                        />
                        <SeparatorVertical
                          borderColor={Theme.gray1}
                          wsize="2rem"
                        />
                        <InputMaterial
                          id="telefone"
                          label="Telefone contato"
                          value={values.telefone}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          error={errors.telefone && touched.telefone}
                          errormessage={errors.telefone}
                          largura="35%"
                          format={true}
                          setFieldValue={setFieldValue}
                        />
                      </Row>
                      <Label
                        color={Theme.gray3}
                        fontweight="400"
                        marginbottom="0.5rem"
                      >
                        Fundação
                      </Label>
                      <SeparatorHorizontal hsize="0.5rem 0" />
                      <Row>
                        <Select
                          id="fundacaoDia"
                          field="day"
                          label="Dia"
                          largura="10%"
                          data={CalendarsDates.getDays()}
                          value={values.fundacaoDia}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          error={errors.fundacaoDia && touched.fundacaoDia}
                          errormessage={errors.fundacaoDia}
                        />
                        <SeparatorVertical
                          borderColor={Theme.gray1}
                          wsize="1rem"
                        />
                        <Select
                          id="fundacaoMes"
                          field="mount"
                          label="Mês"
                          largura="12%"
                          data={CalendarsDates.getMonths()}
                          value={values.fundacaoMes}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          error={errors.fundacaoMes && touched.fundacaoMes}
                          errormessage={errors.fundacaoMes}
                        />
                        <SeparatorVertical
                          borderColor={Theme.gray1}
                          wsize="1rem"
                        />
                        <Select
                          id="fundacaoAno"
                          field="year"
                          label="Ano"
                          largura="10%"
                          data={CalendarsDates.getYears()}
                          value={values.fundacaoAno}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          error={errors.fundacaoAno && touched.fundacaoAno}
                          errormessage={errors.fundacaoAno}
                        />

                        <SeparatorVertical
                          borderColor={Theme.gray1}
                          wsize="2rem"
                        />

                        <Select
                          id="porte"
                          field="nome"
                          label="Porte"
                          largura="35%"
                          data={porte}
                          value={values.porte}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          error={errors.porte && touched.porte}
                          errormessage={errors.porte}
                          setFieldValue={setFieldValue}
                        />
                      </Row>

                      <Row>
                        <Select
                          id="capital"
                          field="nome"
                          label="Capital de operação"
                          largura="35%"
                          data={capital}
                          value={values.capital}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          error={errors.capital && touched.capital}
                          errormessage={errors.capital}
                          setFieldValue={setFieldValue}
                        />

                        <SeparatorVertical
                          borderColor={Theme.gray1}
                          wsize="2rem"
                        />

                        <MultiSelect
                          field="produtos"
                          label="Produtos"
                          largura="35%"
                          data={produtos}
                          value={values.produtos}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          error={errors.produtos && touched.produtos}
                          errormessage={errors.produtos}
                          setFieldValue={setFieldValue}
                        />
                      </Row>

                      <Row>
                        <InputTextAreaMaterial
                          id="sobre"
                          label="Sobre a empresa"
                          value={values.sobre}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          error={errors.sobre && touched.sobre}
                          errormessage={errors.sobre}
                          largura="74%"
                        />
                      </Row>

                      <SeparatorHorizontal hsize="1rem 0" />

                      {/* <Button
                      onClick={handleReset}
                      disabled={!dirty || isSubmitting}
                      type="gray2"
                      style={{ marginRight: '1rem' }}
                    >
                      Limpar
                    </Button> */}
                      <ButtonSubmit type="submit" tipo="primary">
                        Salvar
                      </ButtonSubmit>
                    </form>
                  );
                }}
              </Formik>
            </>
          )}
        </Card>

        {/* <Campanha
          getCampanha={props.loadCampanhaRequest}
          postCampanha={props.postCampanhaRequest}
          campanhaData={props.campanhaData}
          isLoadingCampanha={props.isLoadingCampanha}
          errorCampanha={props.errorCampanha}
          clearCampanha={props.clearCampanha}
          statusAtivacao={props.statusAtivacao}
        /> */}
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.cep.isLoading,
    data: state.cep.data,
    error: state.cep.error,
    isLoadingPerfil: state.perfil.isLoading,
    dataPerfil: state.perfil.data,
    errorPerfil: state.perfil.error,
    campanhaData: state.perfil.campanhaData,
    isLoadingCampanha: state.perfil.isLoadingCampanha,
    errorCampanha: state.perfil.errorCampanha,
    statusAtivacao: state.perfil.statusAtivacao,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadCep: (cep) => dispatch(loadCepRequest(cep)),
    clearCeps: () => dispatch(clearCep()),
    loadPerfilRequest: () => dispatch(loadPerfilRequest()),
    postPerfilRequest: (form) => dispatch(postPerfilRequest(form)),
    clearPerfil: () => dispatch(clearPerfil()),
    loadCampanhaRequest: () => dispatch(loadCampanhaRequest()),
    postCampanhaRequest: (code) => dispatch(postCampanhaRequest(code)),
    clearCampanha: () => dispatch(clearCampanha()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MeusDados);

import React from 'react';
// import { useSelector } from 'react-redux';

import { Paragraph2 } from 'components/FinParagraph';
import { SeparatorHorizontal } from 'components/FinSeparador';

import BlurryText from 'components/FinBlurryText';

import { Line, Column, CustonSubtitle2 } from './Styles';

const Contato = ({ data }) => {
  // const apiInformacoesState = useSelector(
  //   (state) => state.apiInformacoes.data[0]
  // );
  return (
    <>
      <SeparatorHorizontal />
      <Line>
        <Column pwidth="100%">
          <CustonSubtitle2>Site</CustonSubtitle2>
          <Paragraph2 pmargin="0.4rem 0">
            <BlurryText blurry={false}>{data.site}</BlurryText>
          </Paragraph2>
        </Column>
      </Line>
      <SeparatorHorizontal ppadding="0.5rem" />
      <Line>
        <Column pwidth="100%">
          <CustonSubtitle2>E-mail</CustonSubtitle2>
          <Paragraph2 pmargin="0.4rem 0">
            <BlurryText blurry={false}>{data.email}</BlurryText>
          </Paragraph2>
        </Column>
      </Line>
      <SeparatorHorizontal ppadding="0.5rem" />
      <Line>
        <Column pwidth="100%">
          <CustonSubtitle2>Telefone</CustonSubtitle2>
          <Paragraph2 pmargin="0.4rem 0">
            <BlurryText blurry={false}>{data.telefone}</BlurryText>
          </Paragraph2>
        </Column>
      </Line>
      <SeparatorHorizontal ppadding="0.5rem" />
      {/* <Line>
        <Column pwidth="100%">
          <CustonSubtitle2>Linkedin</CustonSubtitle2>
          <Paragraph2 pmargin="0.4rem 0">
            <BlurryText blurry={false}>
              www.linkedin.com/company/hollytec-comercio-de-materiais-eletricos
            </BlurryText>
          </Paragraph2>
        </Column>
      </Line> */}
    </>
  );
};

export default Contato;

import React, { createContext, useMemo, useState, useEffect } from 'react';
import { maskCNPJ } from 'helpers/format';
import { PaginaPrincipal } from './PaginaPrincipal';

import { usePerfil, useFiliais } from './hooks';

export const PerfilContext = createContext();

export function PaginaFinCredit(props) {
  const [empreendedor, setEmpreendedor] = useState(null);

  const {
    match: { params },
  } = props;

  const filiais = useFiliais({ idGrupo: params.idGrupo });
  const perfil = usePerfil({
    idGrupo: Number(params.idGrupo),
    idCedente: empreendedor && empreendedor.idCedente,
  });

  const options = useMemo(
    () =>
      filiais.data
        ? filiais.data.cedentes.map((res) => {
            const value = filiais.data.criptografia
              ? ``
              : `${maskCNPJ(res.cnpj)}`;
            return {
              idCedente: res.id,
              idGrupo: res.idGrupo,
              cnpj: value,
              name: res.nome,
              matriz: res.matriz,
            };
          })
        : [],
    [filiais.data]
  );

  useEffect(() => {
    if (!filiais.isLoading && options && options.length > 0) {
      setEmpreendedor(options.filter((item) => item.matriz)[0]);
    }
  }, [filiais.isLoading, options]);

  return (
    <PerfilContext.Provider
      value={{ perfil, filiais: options, setEmpreendedor, empreendedor }}
    >
      <PaginaPrincipal {...props} />
    </PerfilContext.Provider>
  );
}

import React from 'react';
import styled from 'styled-components/macro';
import { rgba } from 'polished';
import BeatLoader from 'react-spinners/BeatLoader';

import { Wrapper } from 'components/FinWrappers';
import { Caption } from 'components/FinParagraph';

const WrapperCuston = styled(Wrapper)`
  background-color: ${({ bgcolor }) => rgba(bgcolor, 0.1)};
  border-radius: 4px;
  padding: 7.8px 20px;
`;

const TagLoadingInfo = ({ children, labelColor, bgcolor }) => (
  <WrapperCuston bgcolor={bgcolor}>
    <Wrapper padding="0">
      <BeatLoader size={7} margin="2px" color={bgcolor} loading={true} />
    </Wrapper>
    &nbsp;&nbsp;
    <Caption color={labelColor}>{children}</Caption>
  </WrapperCuston>
);

export default TagLoadingInfo;

import axios from 'axios';
import { useQuery } from 'react-query';
import CONFIG from 'config';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/`;

const fetchSocios = async (params) => {
  const fetchData = axios.get(
    `${BASE_URL}api/graficos/socios/${params.idGrupo}/lista?cpfCnpj=${params.cpfcnpj}
    `
  );

  const result = await fetchData;
  const { data } = result;

  return data.data ? data.data : [];
};

export const useListSocios = (params) => {
  return useQuery(['socios', 'list', params], () => fetchSocios(params), {
    keepPreviousData: false,
    enabled: !!params.cpfcnpj,
  });
};

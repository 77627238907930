import styled from 'styled-components/macro';

import { SubTitle2 } from 'components/FinSubTitles';
import { Wrapper } from 'components/FinWrappers';

export const CustonSubtitle2 = styled(SubTitle2)`
  color: ${({ theme }) => theme.newtheme.color.cinza4};
`;

export const Column = styled(Wrapper)`
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  flex-direction: column;
`;

export const Line = styled(Wrapper)`
  padding: 0;
  justify-content: space-between;
  width: 100%;
`;

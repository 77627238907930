import { Workbox } from 'workbox-window';

const skipWaitingMessage = { type: 'SKIP_WAITING' };

if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
  const wb = new Workbox('/sw.js');
  const footer = document.querySelector('#footer-body');

  wb.addEventListener('waiting', () => {
    const wrapper = document.createElement('div');
    wrapper.classList.add('footer-container');

    const message = document.createElement('span');
    message.className = 'text-value';
    message.innerHTML = 'Nova versão disponível';

    const link = document.createElement('a');
    link.text = 'Atualizar';
    link.className = 'btn-reload';

    const dismiss = document.createElement('a');
    dismiss.text = 'Dispensar';
    dismiss.className = 'btn-exit';

    wrapper.appendChild(message);
    wrapper.appendChild(link);
    wrapper.appendChild(dismiss);

    link.onclick = () => {
      wb.addEventListener('controlling', () => {
        window.location.reload();
      });

      wb.messageSW(skipWaitingMessage);
    };

    dismiss.onclick = () => {
      footer.style.visibility = 'hidden';
    };

    // https://www.igvita.com/2015/11/20/dont-lose-user-and-app-state-use-page-visibility/
    // Refresh para Chrome e Firefox.
    window.addEventListener('beforeunload', () => {
      wb.messageSW(skipWaitingMessage);
    });

    // Refresh para Internet Explorer e Edge
    window.addEventListener('unload', () => {
      wb.messageSW(skipWaitingMessage);
    });

    // Refresh para Safari
    document.addEventListener('visibilitychange', function () {
      wb.messageSW(skipWaitingMessage);
    });

    footer.textContent = '';
    footer.appendChild(wrapper);
  });

  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js');
  });

  wb.register();
}

import React from 'react';

import {
  Popover,
  PopoverTriggerTransparent,
  PopoverContent,
} from 'pages/MinhaRede/Comps/Popover';

import { FinAvatar } from 'components/FinAvatar';
import { SeparatorHorizontal } from 'components/FinSeparador';
import { maskCNPJ } from 'helpers/format';
import iconSignOut from 'assets/images/icon-sign-out.svg';
import { useHistory } from 'react-router-dom';
import { CompanyName, Flex, ItemMenu, ItemLabel, Cnpj } from './stye.menuuser';

export function MenuUser({ data }) {
  const history = useHistory();

  const goToPage = (value) => history.push(value);

  return (
    <Popover>
      <PopoverTriggerTransparent>
        <FinAvatar
          bgColor="var(--finblue-300)"
          online
          name={data.empresa}
          fontSize="1.4rem"
          style={{ borderColor: '#008894', width: '40px', height: '40px' }}
        />
      </PopoverTriggerTransparent>
      <PopoverContent align="end">
        <Flex align="center" direction="column">
          <Flex align="center">
            <FinAvatar
              bgColor="var(--finblue-300)"
              online
              fontSize="1.4rem"
              name={data.empresa}
              style={{ borderColor: '#008894', width: '40px', height: '40px' }}
            />
            <Flex
              align="flex-start"
              direction="column"
              style={{ marginLeft: '0.8rem' }}
            >
              <CompanyName onClick={() => goToPage('/meusdados')}>
                {data.empresa}
              </CompanyName>
              <Cnpj>{maskCNPJ(data.cnpj)}</Cnpj>
            </Flex>
          </Flex>
          <SeparatorHorizontal pborderColor="#ececec" />
          <ItemMenu onClick={() => goToPage('/logout')}>
            <ItemLabel icon={iconSignOut}>Sair</ItemLabel>
          </ItemMenu>
        </Flex>
      </PopoverContent>
    </Popover>
  );
}

/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { createActions, createReducer } from 'reduxsauce';
import { call, put, take, fork, cancel, cancelled } from 'redux-saga/effects';
import axios, { CancelToken } from 'axios';

import { getUserData } from 'services/getUserData';
import { Creators as uiToasterActions } from 'store/ducks/uiToaster';

import CONFIG from '../../config';

/**
 * Criando action types & creators
 */

export const { Types, Creators } = createActions({
  apiConvitesEnviadosRequest: ['reloadBkg', 'skip', 'size'],
  apiConvitesEnviadosSuccess: ['data'],
  apiConvitesEnviadosError: [],
  apiConvitesEnviadosCancel: [],
  apiConvitesEnviadosRemoveSent: ['data'],
  apiConvitesEnviadosBlockSent: ['data'],
});

/**
 * Criando os reducer handlers
 */

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: false,
};

const editData = (data, id, field, tipoId = 'idConvite') => {
  console.log(data, id, field, tipoId);

  const updateData =
    data.data &&
    data.data.map((res) => {
      if (res[tipoId] === id) {
        return {
          idGrupo: res.idGrupo,
          nome: res.nome,
          cnpj: res.cnpj,
          online: res.online,
          idConvite: res.idConvite,
          segmento: res.segmento,
          criptografia: res.criptografia,
          ...field,
        };
      }
      return res;
    });

  return { ...data, data: updateData };
};

const apiListRequest = (state = INITIAL_STATE, action) => {
  console.log(action);

  if (action.reloadBkg) {
    return {
      ...state,
      isLoading: false,
    };
  }

  return {
    isLoading: true,
    data: [],
    error: false,
  };
};

const apiListSuccess = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: action.data,
    error: false,
  };
};

const apiListError = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: [],
    error: true,
  };
};

const apiListCancel = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: [],
    error: false,
  };
};

const apiListRemoveSent = (state = INITIAL_STATE, action) => {
  console.log(action);
  const { ready, id, remove } = action.data;

  let field = ready
    ? { localChangedIsHide: true }
    : { localChangedReady: ready, localChangedMessage: 'removendo' };

  if (remove) {
    field = null;
  }

  const updateData = editData(state.data, id, field);
  console.log(updateData);

  return {
    ...state,
    data: updateData,
  };
};

const apiListBlockSent = (state = INITIAL_STATE, action) => {
  console.log(action);
  const { ready, id, remove } = action.data;

  let field = ready
    ? { localChangedIsHide: true }
    : { localChangedReady: ready, localChangedMessage: 'bloqueando' };

  if (remove) {
    field = null;
  }

  const updateData = editData(state.data, id, field, 'idGrupo');
  console.log(updateData);

  return {
    ...state,
    data: updateData,
  };
};

/**
 * Criando reducer
 */

export default createReducer(INITIAL_STATE, {
  [Types.API_CONVITES_ENVIADOS_REQUEST]: apiListRequest,
  [Types.API_CONVITES_ENVIADOS_SUCCESS]: apiListSuccess,
  [Types.API_CONVITES_ENVIADOS_ERROR]: apiListError,
  [Types.API_CONVITES_ENVIADOS_CANCEL]: apiListCancel,
  [Types.API_CONVITES_ENVIADOS_REMOVE_SENT]: apiListRemoveSent,
  [Types.API_CONVITES_ENVIADOS_BLOCK_SENT]: apiListBlockSent,
});

/**
 * Sagas
 */

function* sagaGetAsynResult(params, endpoint) {
  const source = CancelToken.source();
  const url = `${CONFIG.API_REMODELING_URL}${endpoint}`;
  try {
    const response = yield axios.post(url, params, {
      cancelToken: source.token,
    });

    yield put(Creators.apiConvitesEnviadosSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(uiToasterActions.uiToasterMessage(error, 'error'));
    yield put(Creators.apiConvitesEnviadosError());
  } finally {
    if (yield cancelled()) {
      console.log('cancel');
      source.cancel();
    }
  }
}

export function* sagaConvitesEnviados(action) {
  const user = yield call(getUserData);

  if (user) {
    // const params = {
    //   params: {
    //     idPessoa: user.idUsuario,
    //     idConvenio: user.idEmpresa,
    //   },
    // };

    const params = {
      size: action.size || 5,
      skip: action.skip || 1,
      filter: {
        idConvenio: user.idEmpresa,
      },
    };

    const task = yield fork(
      sagaGetAsynResult,
      params,
      '/business-api/api/matching/conexao/financiador/pendentes/enviados'
    );

    yield take(Types.API_CONVITES_ENVIADOS_CANCEL);
    yield cancel(task);
  } else {
    yield put(Creators.apiConvitesEnviadosError());
  }
}

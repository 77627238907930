import * as Switch from '@radix-ui/react-switch';
import { styled } from '@stitches/react';
import { blackA } from '@radix-ui/colors';

export const SwitchRoot = styled(Switch.Root, {
  all: 'unset',
  width: 42,
  height: 25,
  backgroundColor: `var(--fingray-400)`,
  borderRadius: '9999px',
  position: 'relative',
  boxShadow: `0 2px 10px ${blackA.blackA7}`,
  WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
  '&:focus': { boxShadow: `0 0 0 2px rgb(0 136 148 / 50%)` },
  '&[data-state="checked"]': { backgroundColor: 'var(--finblue-primary)' },
});

export const SwitchThumb = styled(Switch.Thumb, {
  display: 'block',
  width: 21,
  height: 21,
  backgroundColor: 'white',
  borderRadius: '9999px',
  boxShadow: `0 2px 2px ${blackA.blackA7}`,
  transition: 'transform 100ms',
  transform: 'translateX(2px)',
  willChange: 'transform',
  cursor: 'pointer',
  '&[data-state="checked"]': { transform: 'translateX(19px)' },
});

export const Flex = styled('div', { display: 'flex' });

export const Label = styled('label', {
  fontFamily: 'Inter',
  color: 'var(--fingray-900)',
  fontSize: 15,
  lineHeight: 1,
  userSelect: 'none',
});

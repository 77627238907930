// ACTIONS OPERACOES
export const loadDataOperacoesRequest = (idConvenio, idPessoaConvenio) => {
  return {
    type: 'LOAD_DATA_OPERACOES_REQUEST',
    idConvenio,
    idPessoaConvenio,
  };
};

export const loadDataOperacoesSuccess = (data) => {
  return {
    type: 'LOAD_DATA_OPERACOES_SUCCESS',
    data,
  };
};

export const loadDataOperacoesFailure = (data) => {
  return {
    type: 'LOAD_DATA_OPERACOES_FAILURE',
    data,
  };
};

export const setDefaultDataOperacoes = () => {
  return {
    type: 'SET_DEFAULT_DATA_OPERACOES',
  };
};

export const setReloadOperacoes = (value) => {
  return {
    type: 'SET_RELOAD_OPERACOES',
    reload: value,
  };
};

export const setDataReasonCancel = (data) => {
  return {
    type: 'SET_DATA_REASON_CANCEl',
    data,
  };
};

/** *************************************************** */

// ACTIONS DONWLOAD
export const downloadOperacoesRequest = (idOperacao, typeUrl) => {
  return {
    type: 'DOWNLOAD_OPERACOES_REQUEST',
    idOperacao,
    typeUrl,
  };
};

export const downloadOperacoesSuccess = (data) => {
  return {
    type: 'DOWNLOAD_OPERACOES_SUCCESS',
    data,
  };
};

export const downloadOperacoesFailure = (data) => {
  return {
    type: 'DOWNLOAD_OPERACOES_FAILURE',
    data,
  };
};
/** *************************************************** */

// ACTIONS DESISTIR
export const desistirDataOperacoesRequest = (value) => {
  return {
    type: 'DESISTIR_OPERACOES_REQUEST',
    value,
  };
};
export const desistirDataOperacoesSuccess = (data) => {
  return {
    type: 'DESISTIR_OPERACOES_SUCCESS',
    data,
  };
};
export const desistirDataOperacoesFailure = (data) => {
  return {
    type: 'DESISTIR_OPERACOES_FAILURE',
    data,
  };
};

/** *************************************************** */

// ACTIONS PROPOSTAS
export const loadDataPropostasRequest = (idOperacao) => {
  return {
    type: 'LOAD_DATA_PROPOSTAS_REQUEST',
    idOperacao,
  };
};
export const loadDataPropostasSuccess = (data) => {
  return {
    type: 'LOAD_DATA_PROPOSTAS_SUCCESS',
    data,
  };
};
export const loadDataPropostasFailure = (data) => {
  return {
    type: 'LOAD_DATA_PROPOSTAS_FAILURE',
    data,
  };
};
/** ************************************************************ */
export const setDefaultDataPropostas = () => {
  return {
    type: 'SET_DEFAULT_DATA_PROPOSTAS',
  };
};
/** ************************************************************ */

export const addBorderoPropostasRequested = (borderos) => {
  // eslint-disable-next-line no-console
  console.log(borderos);
  return {
    type: 'ADD_BORDERO_PROPOSTAS_REQUEST',
  };
};
export const addBorderoPropostasSucceeded = (borderos) => {
  // console.log(borderos);
  return {
    type: 'ADD_BORDERO_PROPOSTAS_SUCCESS',
    borderos,
  };
};

/** ************************************************************ */

// Calcula propostas
export const calculaPropostasRequest = (value) => {
  return {
    type: 'CALCULA_PROPOSTAS_REQUEST',
    values: value,
  };
};
export const calculaPropostasSuccess = (data) => {
  return {
    type: 'CALCULA_PROPOSTAS_SUCCESS',
    data,
  };
};
export const calculaPropostasFailure = (data) => {
  return {
    type: 'CALCULA_PROPOSTAS_FAILURE',
    data,
  };
};

/** ************************************************************ */

// CONTRA PROPOSTAS
export const incluirContraPropostasRequest = (idOperacao) => {
  return {
    type: 'INCLUIR_CONTRA_PROPOSTAS_REQUEST',
    idOperacao,
  };
};
export const incluirContraPropostasSuccess = (data) => {
  return {
    type: 'INCLUIR_CONTRA_PROPOSTAS_SUCCESS',
    data,
  };
};
export const incluirContraPropostasFailure = (data) => {
  return {
    type: 'INCLUIR_CONTRA_PROPOSTAS_FAILURE',
    data,
  };
};

/** ************************************************************ */

// ACEITAR PROPOSTAS
export const aceitarPropostaRequest = (idProposta, idOperacao) => {
  return {
    type: 'ACEITAR_PROPOSTA_REQUEST',
    idProposta,
    idOperacao,
  };
};
export const aceitarPropostaSuccess = (data) => {
  return {
    type: 'ACEITAR_PROPOSTA_SUCCESS',
    data,
  };
};
export const aceitarPropostaFailure = (data) => {
  return {
    type: 'ACEITAR_PROPOSTAS_FAILURE',
    data,
  };
};
export const aceitarPropostaDefault = (data) => {
  return {
    type: 'ACEITAR_PROPOSTAS_DEFAULT',
    data,
  };
};

/** ************************************************************ */

// APROVADA PROPOSTA
export const aprovadaPropostaRequest = (idProposta, idOperacao) => {
  return {
    type: 'APROVADA_PROPOSTA_REQUEST',
    idProposta,
    idOperacao,
  };
};
export const aprovadaPropostaSuccess = (data) => {
  return {
    type: 'APROVADA_PROPOSTA_SUCCESS',
    data,
  };
};
export const aprovadaPropostaFailure = (data) => {
  return {
    type: 'APROVADA_PROPOSTAS_FAILURE',
    data,
  };
};

/** ************************************************************ */

// GET INFO PAGAMENTO PROPOSTA
export const InfoPagPropostaRequest = (idOperacao) => {
  return {
    type: 'INFO_PAG_PROPOSTA_REQUEST',
    idOperacao,
  };
};
export const InfoPagPropostaSuccess = (data) => {
  return {
    type: 'INFO_PAG_PROPOSTA_SUCCESS',
    data,
  };
};
export const InfoPagPropostaFailure = (data) => {
  return {
    type: 'INFO_PAG_PROPOSTAS_FAILURE',
    data,
  };
};

// PAGAMENTO PROPOSTA
export const PagamentoPropostaRequest = (value) => {
  return {
    type: 'PAGAMENTO_PROPOSTA_REQUEST',
    value,
  };
};
export const PagamentoPropostaSuccess = (data) => {
  return {
    type: 'PAGAMENTO_PROPOSTA_SUCCESS',
    data,
  };
};
export const PagamentoPropostaFailure = (data) => {
  return {
    type: 'PAGAMENTO_PROPOSTAS_FAILURE',
    data,
  };
};

// New proposal

export const newProposalAdd = (data) => {
  return {
    type: 'NEW_PROPOSAL_ADD',
    data,
  };
};

export const newProposalChangeItem = (field, value) => {
  return {
    type: 'NEW_PROPOSAL_CHANGE_ITEM',
    field,
    value,
  };
};

/** ************************************************************ */

// ACTIONS BORDEROS
export const loadDataBorderosRequest = (idBordero) => {
  return {
    type: 'LOAD_DATA_BORDEROS_REQUEST',
    idBordero,
  };
};
export const loadDataBorderosSuccess = (data) => {
  return {
    type: 'LOAD_DATA_BORDEROS_SUCCESS',
    data,
  };
};
export const loadDataBorderosFailure = (data) => {
  return {
    type: 'LOAD_DATA_BORDEROS_FAILURE',
    data,
  };
};
/** ************************************************************ */

export const setDefaultDataBorderos = () => {
  return {
    type: 'SET_DEFAULT_DATA_BORDEROS',
    data: null,
  };
};

export const updateDataBorderosRequest = (titulos) => {
  return {
    type: 'UPDATE_DATA_BORDEROS_REQUEST',
    titulos,
  };
};

export const updateDataBorderosSuccess = (titulos) => {
  return {
    type: 'UPDATE_DATA_BORDEROS_SUCCESS',
    titulos,
  };
};

export const updateGroupSacados = (titulos) => {
  return {
    type: 'UPDATE_GROUP_SACADOS',
    titulos,
  };
};

export const updateItemTituloSacados = (titulos) => {
  return {
    type: 'UPDATE_ITEM_TITULO_SACADOS',
    titulos,
  };
};

export const updateItemSacados = (titulos) => {
  return {
    type: 'UPDATE_ITEM_SACADOS',
    titulos,
  };
};

/** *************************************************** */

// ACTIONS DASHBOARD

// CARDS
export const loadDataCardsRequest = (idEmpresa) => {
  return {
    type: 'LOAD_DATA_CARDS_REQUEST',
    idEmpresa,
  };
};
export const loadDataCardsSuccess = (data) => {
  return {
    type: 'LOAD_DATA_CARDS_SUCCESS',
    data,
  };
};
export const loadDataCardsFailure = (data) => {
  return {
    type: 'LOAD_DATA_CARDS_FAILURE',
    data,
  };
};

/** *************************************************** */
// CHARTS
export const loadDataChartsRequest = (idEmpresa) => {
  return {
    type: 'LOAD_DATA_CHARTS_REQUEST',
    idEmpresa,
  };
};
export const loadDataChartsSuccess = (data) => {
  return {
    type: 'LOAD_DATA_CHARTS_SUCCESS',
    data,
  };
};
export const loadDataChartsFailure = (data) => {
  return {
    type: 'LOAD_DATA_CHARTS_FAILURE',
    data,
  };
};

/** *************************************************** */
// ACTIONS FINALIZADOS
export const loadDataFinalizadosRequest = (
  orderBy,
  target,
  quantidade,
  page,
  filters
) => {
  return {
    type: 'LOAD_DATA_FINALIZADOS_REQUEST',
    orderBy,
    target,
    quantidade,
    page,
    filters,
  };
};
export const loadDataFinalizadosSuccess = (data) => {
  return {
    type: 'LOAD_DATA_FINALIZADOS_SUCCESS',
    data,
  };
};
export const loadDataFinalizadosFailure = (data) => {
  return {
    type: 'LOAD_DATA_FINALIZADOS_FAILURE',
    data,
  };
};

/** *************************************************************************** */
// ACTIONS LISTA PREGOES
export const loadDataPregoesRequest = (
  orderBy,
  target,
  quantidade,
  page,
  filters
) => {
  return {
    type: 'LOAD_DATA_PREGOES_REQUEST',
    orderBy,
    target,
    quantidade,
    page,
    filters,
  };
};
export const loadDataPregoesSuccess = (data) => {
  return {
    type: 'LOAD_DATA_PREGOES_SUCCESS',
    data,
  };
};
export const loadDataPregoesFailure = (data) => {
  return {
    type: 'LOAD_DATA_PREGOES_FAILURE',
    data,
  };
};

// ACTIONS ACEITA PREGOES
export const aceitaPregoesRequest = (
  idPregao,
  idConvenio,
  idPessoaConvenio,
  value
) => {
  return {
    type: 'ACEITA_PREGOES_REQUEST',
    value,
  };
};
export const aceitaPregoesSuccess = (data) => {
  return {
    type: 'ACEITA_PREGOES_SUCCESS',
    data,
  };
};
export const aceitaPregoesFailure = (data) => {
  return {
    type: 'ACEITA_PREGOES_FAILURE',
    data,
  };
};

// ACTIONS REJEITA PREGOES
export const rejeitaPregoesRequest = (
  idPregao,
  idConvenio,
  idPessoaConvenio,
  value
) => {
  return {
    type: 'REJEITA_PREGOES_REQUEST',
    value,
    /* idPregao: idPregao,
    idConvenio: idConvenio,
    idPessoaConvenio: idPessoaConvenio, */
  };
};
export const rejeitaPregoesSuccess = (data) => {
  return {
    type: 'REJEITA_PREGOES_SUCCESS',
    data,
  };
};
export const rejeitaPregoesFailure = (data) => {
  return {
    type: 'REJEITA_PREGOES_FAILURE',
    data,
  };
};

/** *************************************************** */
// ACTIONS USER
export const setDataUser = (data) => {
  return {
    type: 'SET_DATA_USER',
    data,
  };
};
export const requestDataUser = (email, senha) => {
  return {
    type: 'REQUEST_DATA_USER',
    email,
    senha,
  };
};
export const dataUserSucess = (data) => {
  return {
    type: 'DATA_USER_SUCESS',
    data,
  };
};
export const dataUserFailure = (data) => {
  return {
    type: 'DATA_USER_FAILURE',
    data,
  };
};

/** *************************************************************************** */
// ACTIONS UI

export const showModal = (
  title,
  content,
  modalActions,
  modalLabelBtn1,
  modalSingleBtn,
  modalTextarea,
  modalLabelBtn2
) => {
  return {
    type: 'SHOW_MODAL',
    title,
    content,
    modalActions,
    modalLabelBtn1,
    modalSingleBtn,
    modalTextarea,
    modalLabelBtn2,
  };
};

export const closeModal = () => {
  return {
    type: 'CLOSE_MODAL',
  };
};

export const openMenu = () => {
  return {
    type: 'OPEN_MENU',
  };
};

export const closeMenu = () => {
  return {
    type: 'CLOSE_MENU',
  };
};

export const showNotification = () => {
  return {
    type: 'SHOW_NOTIFICATION',
  };
};

export const closeNotification = () => {
  return {
    type: 'CLOSE_NOTIFICATION',
  };
};

export const contentNotification = (data, timeNotification, values) => {
  return {
    type: 'CONTENT_NOTIFICATION',
    data,
    timeNotification,
    values,
  };
};

export const showLoading = () => {
  return {
    type: 'SHOW_LOADING',
  };
};

export const hideLoading = () => {
  return {
    type: 'HIDE_LOADING',
  };
};

export const offScrollingBody = () => {
  return {
    type: 'OFF_SCROLLING_BODY',
  };
};

export const onScrollingBody = () => {
  return {
    type: 'ON_SCROLLING_BODY',
  };
};

export const clearUI = () => {
  return {
    type: 'CLEAR_UI',
  };
};

export const insertNotifyData = (data) => {
  return {
    type: 'INSERT_DATA_NOTIFY',
    data,
  };
};

export const removeNotifyData = (data) => {
  return {
    type: 'REMOVE_DATA_NOTIFY',
    data,
  };
};

/** *************************************************************************** */

// ACTIONS CHAT
export const openChatRequest = (idOperacao) => {
  return {
    type: 'OPEN_CHAT_REQUEST',
    idOperacao,
  };
};
export const openChatRequestSuccess = (data) => {
  return {
    type: 'OPEN_CHAT_SUCCESS',
    data,
  };
};
export const openChatRequestFailure = (data) => {
  return {
    type: 'OPEN_CHAT_FAILURE',
    data,
  };
};

/** *************************************************************************** */

// ACTIONS SEARCHS FILTERS

export const setFilteredDataListRequest = (value, tipo) => {
  return {
    type: 'SET_FILTERED_DATA_LISTS_REQUEST',
    value,
    tipo,
  };
};
export const setFilteredDataListSuccess = (data) => {
  return {
    type: 'SET_FILTERED_DATA_LISTS_SUCESS',
    data,
  };
};
export const setFilteredDataListFailure = (data) => {
  return {
    type: 'SET_FILTERED_DATA_LISTS_ERROR',
    data,
  };
};
export const setFilteredDataDefault = () => {
  return {
    type: 'SET_FILTERED_DATA_DEFAULT',
  };
};

// FINBOX SEARCHS FILTERS

export const setFilteredDataFinboxRequest = (value, tipo) => {
  return {
    type: 'SET_FILTERED_DATA_FINBOX_REQUEST',
    value,
    tipo,
  };
};
export const setFilteredDataFinboxSuccess = (data) => {
  return {
    type: 'SET_FILTERED_DATA_FINBOX_SUCESS',
    data,
  };
};
export const setFilteredDataFinboxFailure = (data) => {
  return {
    type: 'SET_FILTERED_DATA_FINBOX_ERROR',
    data,
  };
};
export const setFilteredFinboxDefault = () => {
  return {
    type: 'SET_FILTERED_FINBOX_DEFAULT',
  };
};

/** *************************************************************************** */

// ACTIONS COUNT ITENS ABAS

export const loadCountRecorsDataRequest = (tipoAba, filters) => {
  return {
    type: 'LOAD_COUNT_RECORDS_DATA_REQUEST',
    tipoAba,
    filters,
  };
};
export const loadCountRecorsDataSuccess = (data) => {
  return {
    type: 'LOAD_COUNT_RECORDS_DATA_SUCCESS',
    data,
  };
};
export const loadCountRecorsDataFailure = (data) => {
  return {
    type: 'LOAD_COUNT_RECORDS_DATA_FAILURE',
    data,
  };
};

/** *************************************************************************** */

// ACTIONS DATA GERAL BUSCA

export const setDataMainSearch = (data) => {
  return {
    type: 'SET_DATA_MAIN_SEARCH',
    data,
  };
};

/** *************************************************************************** */

// ACTIONS LIST POC DOCS

export const loadListPocDocRequest = (idCedente) => {
  return {
    type: 'LOAD_LIST_POC_DOC_REQUEST',
    idCedente,
  };
};
export const loadListPocDocSuccess = (data) => {
  return {
    type: 'LOAD_LIST_POC_DOC_SUCCESS',
    data,
  };
};
export const loadListPocDocFailure = (data) => {
  return {
    type: 'LOAD_LIST_POC_DOC_FAILURE',
    data,
  };
};

/** *************************************************************************** */

// ACTIONS MESSAGING TO COMPONENTS

export const sendMessage = (data) => {
  return {
    type: 'SEND_MESSAGE',
    data,
  };
};

/** *************************************************************************** */

// ACTIONS FINBOX

export const loadListRequest = (
  pagina,
  quantidade,
  ordem,
  ordemCampo,
  search
) => {
  return {
    type: 'LOAD_LIST_REQUEST',
    pagina,
    quantidade,
    ordem,
    ordemCampo,
    search,
  };
};
export const loadListSuccess = (data) => {
  return {
    type: 'LOAD_LIST_SUCCESS',
    data,
  };
};
export const loadListFailure = (data) => {
  return {
    type: 'LOAD_LIST_FAILURE',
    data,
  };
};

// Meeting
export const generatesMeetingRequest = (idCedente) => {
  return {
    type: 'GENERATES_MEETING_REQUEST',
    idCedente,
  };
};
export const generatesMeetingSuccess = (data) => {
  return {
    type: 'GENERATES_MEETING_SUCCESS',
    data,
  };
};
export const generatesMeetingFailure = (data) => {
  return {
    type: 'GENERATES_MEETING_FAILURE',
    data,
  };
};

/** *************************************************************************** */

// ACTIONS FINBOX DOWNLOAD

export const downloadDocRequest = (idDocumento, url) => {
  return {
    type: 'DOWNLOAD_DOC_REQUEST',
    idDocumento,
    url,
  };
};
export const downloadDocSuccess = (data) => {
  return {
    type: 'DOWNLOAD_DOC_SUCCESS',
    data,
  };
};
export const downloadDocFailure = (data) => {
  return {
    type: 'DOWNLOAD_DOC_FAILURE',
    data,
  };
};

/** *************************************************************************** */

// ACTIONS FINBOX HABILITA

export const habilitaRequest = (idCedente, habilitado) => {
  return {
    type: 'HABILITA_REQUEST',
    idCedente,
    habilitado,
  };
};
export const habilitaSuccess = (data) => {
  return {
    type: 'HABILITA_SUCCESS',
    data,
  };
};
export const habilitaFailure = (data) => {
  return {
    type: 'HABILITA_FAILURE',
    data,
  };
};

/** *************************************************************************** */

// ACTIONS FINBOX INFOBASICA

export const infoBasicaRequest = (idCedente) => {
  return {
    type: 'INFOBASICA_REQUEST',
    idCedente,
  };
};
export const infoBasicaSuccess = (data) => {
  return {
    type: 'INFOBASICA_SUCCESS',
    data,
  };
};
export const infoBasicaFailure = (data) => {
  return {
    type: 'INFOBASICA_FAILURE',
    data,
  };
};

/** *************************************************************************** */

// ACTIONS SEM NOTA

export const newProposalSemNotaAdd = (data) => {
  return {
    type: 'NEW_PROPOSAL_SEM_NOTA_ADD',
    data,
  };
};
export const addItemParcelaProposta = (value) => {
  return {
    type: 'ADD_ITEM_PARCELA_PROPOSTA',
    value,
  };
};
export const editItemParcelaProposta = (id, field, value) => {
  return {
    type: 'EDIT_ITEM_PARCELA_PROPOSTA',
    id,
    field,
    value,
  };
};
export const removeItemParcelaProposta = (id) => {
  return {
    type: 'REMOVE_ITEM_PARCELA_PROPOSTA',
    id,
  };
};

export const editItemDefaultProposta = (field, value) => {
  return {
    type: 'EDIT_ITEM_DEFAULT_PROPOSTA',
    field,
    value,
  };
};

export const viewActiveProposta = (viewActive) => {
  return {
    type: 'VIEW_ACTIVE_PROPOSTA',
    viewActive,
  };
};

export const simuladoPropostaSemNota = (data) => {
  return {
    type: 'SIMULADO_PROPOSTA_SEM_NOTA',
    data,
  };
};

export const resumoSetData = (data) => {
  return {
    type: 'RESUMO_SET_DATA',
    data,
  };
};

export const updateResumoSetData = (field, data) => {
  return {
    type: 'UPDATE_RESUMO_SET_DATA',
    data,
    field,
  };
};

export const changeAllResumoSetData = (data) => {
  return {
    type: 'CHANGE_ALL_RESUMO_SET_DATA',
    data,
  };
};

export const propostaChangeCommentsSemNota = (field, value) => {
  return {
    type: 'PROPOSTA_CHANGE_COMMENTS_SEM_NOTA',
    field,
    value,
  };
};

export const setCalculaProposatasDefault = () => {
  return {
    type: 'SET_CALCULA_PROPOSTAS_DEFAULT',
  };
};

export const resumoSetDataHistory = (data) => {
  return {
    type: 'RESUMO_SET_DATA_HISTORY',
    data,
  };
};

export const resumoClearDataHistory = (data) => {
  return {
    type: 'RESUMO_CLEAR_DATA_HISTORY',
    data,
  };
};

/** *************************************************************************** */

// ACTIONS CONDICIONANTE

export const showModalCondicionante = (data) => {
  return {
    type: 'SHOW_MODAL_CONDIC',
    data,
  };
};

/** *************************************************************************** */
// Loading list of condicionantes
export const loadListCondicionanteRequest = (idStatus) => {
  return {
    type: 'LOAD_LIST_CONDIC_REQUEST',
    idStatus,
  };
};

export const loadListCondicionanteSuccess = (data) => {
  return {
    type: 'LOAD_LIST_CONDIC_SUCCESS',
    data,
  };
};

export const loadListCondicionanteFailure = (data) => {
  return {
    type: 'LOAD_LIST_CONDIC_FAILURE',
    data,
  };
};

export const clearListCondicionante = () => {
  return {
    type: 'CLEAR_LIST_CONDIC',
  };
};

/** *************************************************************************** */

// Concluir condicionante
export const concluirCondicionanteRequest = (idCondicionante) => {
  return {
    type: 'CONCLUIR_CONDIC_REQUEST',
    value: idCondicionante,
  };
};

export const concluirCondicionanteSuccess = (data) => {
  return {
    type: 'CONCLUIR_CONDIC_SUCCESS',
    data,
  };
};

export const concluirCondicionanteFailure = (data) => {
  return {
    type: 'CONCLUIR_CONDIC_FAILURE',
    data,
  };
};

/** *************************************************************************** */

// Excluir condicionante
export const excluirCondicionanteRequest = (idCondicionante) => {
  return {
    type: 'EXCLUIR_CONDIC_REQUEST',
    value: idCondicionante,
  };
};

export const excluirCondicionanteSuccess = (data) => {
  return {
    type: 'EXCLUIR_CONDIC_SUCCESS',
    data,
  };
};

export const excluirCondicionanteFailure = (data) => {
  return {
    type: 'EXCLUIR_CONDIC_FAILURE',
    data,
  };
};

/** *************************************************************************** */
// Cancelar condicionante
export const cancelarCondicionanteRequest = (idCondicionante) => {
  return {
    type: 'CANCELAR_CONDIC_REQUEST',
    value: idCondicionante,
  };
};

export const cancelarCondicionanteSuccess = (data) => {
  return {
    type: 'CANCELAR_CONDIC_SUCCESS',
    data,
  };
};

export const cancelarCondicionanteFailure = (data) => {
  return {
    type: 'CANCELAR_CONDIC_FAILURE',
    data,
  };
};

/** *************************************************************************** */

// Load list cedente
export const loadListCedenteRequest = (idCondicionante) => {
  return {
    type: 'LOAD_LIST_CEDENTE_REQUEST',
    idCondicionante,
  };
};

export const loadListCedenteSuccess = (data) => {
  return {
    type: 'LOAD_LIST_CEDENTE_SUCCESS',
    data,
  };
};

export const loadListCedenteFailure = (data) => {
  return {
    type: 'LOAD_LIST_CEDENTE_FAILURE',
    data,
  };
};

export const loadListCedenteClear = () => {
  return {
    type: 'LOAD_LIST_CEDENTE_CLEAR',
  };
};

/** *************************************************************************** */

// Filterd list cedente
export const filterdListCedenteRequest = (value) => {
  return {
    type: 'FILTERD_LIST_CEDENTE_REQUEST',
    value,
  };
};

export const filterdListCedenteSuccess = (data) => {
  return {
    type: 'FILTERD_LIST_CEDENTE_SUCCESS',
    data,
  };
};

export const filterdListCedenteFailure = (data) => {
  return {
    type: 'FILTERD_LIST_CEDENTE_FAILURE',
    data,
  };
};

export const setfilterdCedenteDefault = (data) => {
  return {
    type: 'SET_FILTERED_CEDENTE_DEFAULT',
    data,
  };
};

/** *************************************************************************** */

// Create
export const sendCondicRequest = () => {
  return {
    type: 'SEND_CONDIC_REQUEST',
  };
};

export const sendCondicSuccess = (data) => {
  return {
    type: 'SEND_CONDIC_SUCCESS',
    data,
  };
};

export const sendCondicFailure = (data) => {
  return {
    type: 'SEND_CONDIC_FAILURE',
    data,
  };
};

export const createCondicDefault = (data) => {
  return {
    type: 'CREATE_CONDIC_DEFAULT',
    data,
  };
};

export const updateCondicDefault = (field, data) => {
  return {
    type: 'UPDATE_CONDIC_DEFAULT',
    field,
    data,
  };
};

/** *************************************************************************** */

// Get one operation
export const getOneOperationRequest = (idOperacao) => {
  return {
    type: 'GET_OPERATION_REQUEST',
    idOperacao,
  };
};

export const getOneOperationSuccess = (data) => {
  return {
    type: 'GET_OPERATION_SUCCESS',
    data,
  };
};

export const getOneOperationFailure = (data) => {
  return {
    type: 'GET_OPERATION_FAILURE',
    data,
  };
};

/** *************************************************************************** */

// Get resume operation
export const loadResumoRequest = (idOperacao, idCedente) => {
  return {
    type: 'LOAD_RESUMO_REQUEST',
    idOperacao,
    idCedente,
  };
};

export const loadResumoSucess = (data) => {
  return {
    type: 'LOAD_RESUMO_SUCCESS',
    data,
  };
};

export const loadResumoFailure = (data) => {
  return {
    type: 'LOAD_RESUMO_FAILURE',
    data,
  };
};

/** *************************************************************************** */

// PAGINACAO

export const loadPaginacao = (data) => {
  return {
    type: 'LOAD_PAGINACAO',
    data,
  };
};

export const loadPaginacaoClear = () => {
  return {
    type: 'LOAD_PAGINACAO_CLEAR',
  };
};

/** *************************************************************************** */

// Exibe preco
export const loadExibePrecoRequest = (idOperacao) => {
  return {
    type: 'LOAD_EXIBE_PRECO_REQUEST',
    idOperacao,
  };
};

export const loadExibePrecoSucess = (data) => {
  return {
    type: 'LOAD_EXIBE_PRECO_SUCCESS',
    data,
  };
};

export const loadExibePrecoFailure = (data) => {
  return {
    type: 'LOAD_EXIBE_PRECO_FAILURE',
    data,
  };
};

/** *************************************************************************** */

// List para download
export const addItemListDownload = (value) => {
  return {
    type: 'ADD_ITEM_LIST_DOWNLOAD',
    value,
  };
};

export const addAllItemsListDownload = (data) => {
  return {
    type: 'ADD_ALL_ITEMS_LIST_DOWNLOAD',
    data,
  };
};

export const clearAllItemsListDownload = () => {
  return {
    type: 'CLEAR_ALL_ITEMS_LIST_DOWNLOAD',
  };
};

export const removeItemListDownload = (value) => {
  return {
    type: 'REMOVER_ITEM_LIST_DOWNLOAD',
    value,
  };
};

export const saveDocFileZip = (value) => {
  return {
    type: 'SAVE_DOC_FILE_ZIP',
    value,
  };
};

export const saveDocFileZipSuccess = () => {
  return {
    type: 'SAVE_DOC_FILE_ZIP_SUCCESS',
  };
};

export const saveDocFileZipFailure = () => {
  return {
    type: 'SAVE_DOC_FILE_ZIP_FAILURE',
  };
};

export const saveDocFileZipClear = () => {
  return {
    type: 'SAVE_DOC_FILE_ZIP_CLEAR',
  };
};

/** *************************************************************************** */

// Consulta CEP
export const loadCepRequest = (cep) => {
  return {
    type: 'LOAD_CEP_REQUEST',
    cep,
  };
};

export const loadCepSucess = (data) => {
  return {
    type: 'LOAD_CEP_SUCCESS',
    data,
  };
};

export const loadCepFailure = (data) => {
  return {
    type: 'LOAD_CEP_FAILURE',
    data,
  };
};

export const clearCep = () => {
  return {
    type: 'CLEAR_CEP',
  };
};

/** *************************************************************************** */

// Perfil
export const loadPerfilRequest = () => {
  return {
    type: 'LOAD_PERFIL_REQUEST',
  };
};

export const loadPerfilSucess = (data) => {
  return {
    type: 'LOAD_PERFIL_SUCCESS',
    data,
  };
};

export const loadPerfilFailure = (data) => {
  return {
    type: 'LOAD_PERFIL_FAILURE',
    data,
  };
};

export const clearPerfil = () => {
  return {
    type: 'CLEAR_PERFIL',
  };
};

// Post
export const postPerfilRequest = (form) => {
  return {
    type: 'POST_PERFIL_REQUEST',
    form,
  };
};

export const postPerfilSucess = (data) => {
  return {
    type: 'POST_PERFIL_SUCCESS',
    data,
  };
};

export const postPerfilFailure = (data) => {
  return {
    type: 'POST_PERFIL_FAILURE',
    data,
  };
};

// Post Match
export const postMatchRequest = (form) => {
  // eslint-disable-next-line no-console
  console.log('teste Andrew');
  // eslint-disable-next-line no-console
  console.log(form);
  return {
    type: 'POST_MATCH_REQUEST',
    form,
  };
};

export const postMatchSucess = (data) => {
  return {
    type: 'POST_MATCH_SUCCESS',
    data,
  };
};

export const postMatchFailure = (data) => {
  return {
    type: 'POST_MATCH_FAILURE',
    data,
  };
};

// Campanha
export const loadCampanhaRequest = () => {
  return {
    type: 'LOAD_CAMPANHA_REQUEST',
  };
};

export const loadCampanhaSucess = (data) => {
  return {
    type: 'LOAD_CAMPANHA_SUCCESS',
    data,
  };
};

export const loadCampanhaFailure = (data) => {
  return {
    type: 'LOAD_CAMPANHA_FAILURE',
    data,
  };
};

export const clearCampanha = () => {
  return {
    type: 'CLEAR_CAMPANHA',
  };
};

// Post

export const postCampanhaRequest = (code) => {
  return {
    type: 'POST_CAMPANHA_REQUEST',
    code,
  };
};

export const postCampanhaSucess = (data) => {
  return {
    type: 'POST_CAMPANHA_SUCCESS',
    data,
  };
};

export const postCampanhaFailure = (data) => {
  return {
    type: 'POST_CAMPANHA_FAILURE',
    data,
  };
};

/** *************************************************************************** */

/**
 * Permissoões clientes
 */

export const permissoesRequest = (idCedente, tipo) => {
  return {
    type: 'PERMISSOES_REQUEST',
    idCedente,
    tipo,
  };
};

export const permissoesSucess = (data) => {
  return {
    type: 'PERMISSOES_SUCCESS',
    data,
  };
};

export const permissoesFailure = (data) => {
  return {
    type: 'PERMISSOES_FAILURE',
    data,
  };
};

/** *************************************************************************** */

/**
 * Filters
 */

export const resetAll = () => {
  return {
    type: 'RESET_ALL',
  };
};

export const updateField = (data) => {
  return {
    type: 'UPDATE_FIELD',
    data,
  };
};

export const setFilter = (value) => {
  return {
    type: 'SET_FILTER',
    value,
  };
};

export const addFilterItem = (value) => {
  return {
    type: 'ADD_FILTER_ITEM',
    value,
  };
};

export const updateFilterItem = (field, value) => {
  return {
    type: 'UPDATE_FILTER_ITEM',
    field,
    value,
  };
};

export const resetFilter = () => {
  return {
    type: 'RESET_FILTER',
  };
};

// Campos para filtros
export const loadListFieldsRequest = () => {
  return {
    type: 'LOAD_LIST_FIELDS_REQUEST',
  };
};

export const loadListFieldsSucess = (data) => {
  return {
    type: 'LOAD_LIST_FIELDS_SUCESS',
    data,
  };
};

export const loadListFieldsFailure = (data) => {
  return {
    type: 'LOAD_LIST_FIELDS_FAILURE',
    data,
  };
};

// List CNAE
export const loadCnaeRequest = (pesquisa) => {
  return {
    type: 'LOAD_CNAE_REQUEST',
    pesquisa,
  };
};

export const loadCnaeSucess = (data) => {
  return {
    type: 'LOAD_CNAE_SUCESS',
    data,
  };
};

export const loadCnaeFailure = (data) => {
  return {
    type: 'LOAD_CNAE_FAILURE',
    data,
  };
};

export const removeCnaeItem = (cnae) => {
  return {
    type: 'REMOVE_CNAE_ITEM',
    cnae,
  };
};

export const addCnaeItem = (data) => {
  return {
    type: 'ADD_CNAE_ITEM',
    data,
  };
};

export const clearCnaeList = () => {
  return {
    type: 'CLEAR_CNAE_LIST',
  };
};

export const removeCnaeSelectedItem = (cnae) => {
  return {
    type: 'REMOVE_CNAE_SELECTED_ITEM',
    cnae,
  };
};

export const addCnaeSelectedItem = (data) => {
  return {
    type: 'ADD_CNAE_SELECTED_ITEM',
    data,
  };
};

/** *************************************************************************** */

/**
 * Termos
 */

export const getTermosRequest = () => {
  return {
    type: 'GET_TERMOS_REQUEST',
  };
};

export const getTermosSucess = (data) => {
  return {
    type: 'GET_TERMOS_SUCCESS',
    data,
  };
};

export const getTermosFailure = (data) => {
  return {
    type: 'GET_TERMOS_FAILURE',
    data,
  };
};

/**
 * Set Termos
 */

export const setTermosRequest = (idTermo) => {
  return {
    type: 'SET_TERMOS_REQUEST',
    idTermo,
  };
};

export const setTermosSucess = (data) => {
  return {
    type: 'SET_TERMOS_SUCCESS',
    data,
  };
};

export const setTermosFailure = (data) => {
  return {
    type: 'SET_TERMOS_FAILURE',
    data,
  };
};

// Dados de CNAE

export const loadDataCNAE = () => {
  return {
    type: 'LOADCNAE',
  };
};

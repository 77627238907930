const INITIAL_STATE = {
  data: [],
  isLoading: null,
  error: false,
};

const precificacao = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LOAD_EXIBE_PRECO_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'LOAD_EXIBE_PRECO_SUCCESS':
      return {
        ...state,
        data: action.data,
        isLoading: false,
      };
    case 'LOAD_EXIBE_PRECO_FAILURE':
      return {
        ...state,
        data: action.data,
        isLoading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default precificacao;

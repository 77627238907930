import styled, { css } from 'styled-components/macro';

const truncate = css`
  width: ${({ limite }) => limite || `20ch`};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CompanyName = styled.span`
  color: var(--fingray-900);
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  ${truncate}
`;

export const ItemMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  transition: background 0.2s ease-in-out;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  &:hover {
    background-color: var(--fingray-300);
  },
`;

export const ItemLabel = styled.span`
  position: relative;
  font-family: Inter;
  font-weight: normal;
  font-size: 1rem;
  text-align: left;
  color: var(--fingray-900);
  text-indent: 42px;
  min-width: 140px;
  margin-right: 1.5rem;
  background: url(${({ icon }) => icon}) no-repeat center left;
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  align-items: ${({ align }) => align || 'flex-start'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
`;

export const Cnpj = styled.span`
  color: var(--fingray-400);
  font-size: 0.8rem;
  font-weight: 400;
  ${truncate}
`;

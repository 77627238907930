import React from 'react';

export const StepsContext = React.createContext();

export const StepsWizard = ({ children }) => {
  const itemsRef = React.useRef([]);

  const addToRefs = (el) => {
    if (el && !itemsRef.current.includes(el)) {
      itemsRef.current.push(el);
    }
  };

  return (
    <StepsContext.Provider value={[itemsRef, addToRefs]}>
      {children}
    </StepsContext.Provider>
  );
};

/* eslint-disable no-console */
import immer from 'immer';
import React, { createContext, useReducer } from 'react';

const initialState = {
  pagination: {
    size: 5,
    skip: 1,
  },
};

const actions = {
  SET_PAGINATION_SIZE: 'SET_PAGINATION_SIZE',
  SET_PAGINATION_SKIP: 'SET_PAGINATION_SKIP',
};

const reducerActions = {
  SET_PAGINATION_SIZE: (state, payload) => {
    state.pagination.size = payload.value;
  },
  SET_PAGINATION_SKIP: (state, payload) => {
    state.pagination.skip = payload.value;
  },
};

const reducer = (state, action) => {
  const fn = reducerActions[action.type];
  if (fn) {
    return immer(state, (draftState) => {
      fn(draftState, action.payload);
    });
  }
  console.log('[WARNING] Action without reducer:', action);
  return state;
};

export const ConvitesContext = createContext();

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const values = {
    setPage: (page) => {
      dispatch({ type: actions.SET_PAGINATION_SKIP, payload: { value: page } });
    },
    setSize: (size) => {
      dispatch({ type: actions.SET_PAGINATION_SIZE, payload: { value: size } });
    },
  };

  return (
    <ConvitesContext.Provider value={[state, values]}>
      {children}
    </ConvitesContext.Provider>
  );
};

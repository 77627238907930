/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useCallback } from 'react';
import { isAfter } from 'date-fns';
import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';

import { Title3 } from 'components/FinHeadings';
import { ReactComponent as IcoSearch } from 'assets/images/icon-search.svg';
import Input from 'components/FinInput';
import { Wrapper } from 'components/FinWrappers';
import { Input as InputDate } from 'components/Input';
import { SeparatorVertical } from 'components/FinSeparador';
import useDebounce from 'hooks/useDebounce';
import { FinSelectBox, Option } from 'components/FinSelectBox';
import Button from 'components/FinButton';
import ReactTooltip from 'react-tooltip';
import { OperacoesContext } from '../provider';
import { LIST_TIPO_PREGAO } from '../config';

import { TitleBar } from './style';

import { IconUpdateStyle } from '../Ofertas/Card/style';

const Icon = () => (
  <Wrapper padding="0" pwidth="18px" pheight="18px">
    <IcoSearch />
  </Wrapper>
);

export const FiltersBar = ({ isFetching, refetch }) => {
  const [pesquisa, setPesquisa] = useState('');

  const [state, actions] = useContext(OperacoesContext);
  const {
    date,
    navigation: { tabActive, tipoPregao },
  } = state;
  const { setSearch, setDate } = actions;
  const [dataInicial, setDataInicial] = useState(date);

  const debouncedSearchTerm = useDebounce(pesquisa, 800);

  const onHandleTipoPregao = (event) => {
    actions.setTipoPregao(event.target.value);
  };

  const changeDate = useCallback(
    (value) => {
      setDataInicial(value);
      const numbers = value.replace(/[-]/g, '');
      if (numbers.length === 8) {
        const parsed = parseISO(value);
        const valid = isValid(parsed);

        const dateLimit = '1753-01-01';
        const depoisDe = isAfter(parseISO(value), parseISO(dateLimit));

        if (valid && depoisDe) {
          setDate(value);
        }
      }
    },
    [setDate]
  );

  useEffect(() => {
    setDataInicial(date);
  }, []);

  useEffect(() => {
    setSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm, setSearch]);

  return (
    <TitleBar>
      <Wrapper padding="0">
        <Title3 style={{ fontWeight: 'bold' }}>Ofertas</Title3>
        <SeparatorVertical pwidth="1rem" />
        <ReactTooltip
          id={`tip-isFetching`}
          wrapper="span"
          place="bottom"
          type="dark"
          effect="solid"
        >
          Atualizar Ofertas
        </ReactTooltip>
        <Wrapper
          padding="0"
          pwidth="120px"
          data-tip
          data-for={`tip-isFetching`}
        >
          <Button
            rounded
            size="30px"
            largura="total"
            style={{ padding: 0 }}
            onClick={() => refetch()}
          >
            <IconUpdateStyle isloading={isFetching ? 1 : 0} />
            <SeparatorVertical pwidth="0.5rem" />
            Atualizar
          </Button>
        </Wrapper>
      </Wrapper>

      <Wrapper padding="0" pwidth="60%">
        <Input
          placeholder="Pesquisar"
          onChange={(e) => setPesquisa(e.target.value)}
          icone={<Icon />}
          value={pesquisa}
          custonStyles={{ padding: '12px', height: '30px' }}
        />
        <SeparatorVertical pwidth="1rem" />
        <FinSelectBox
          id="sub-tipo-emprestimo"
          value={tipoPregao}
          onChange={onHandleTipoPregao}
        >
          {LIST_TIPO_PREGAO.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.label}
            </Option>
          ))}
        </FinSelectBox>

        {tabActive !== 'emAberto' && (
          <>
            <SeparatorVertical pwidth="1rem" />
            <InputDate
              id="date-id"
              type="date"
              onChange={(e) => changeDate(e.target.value)}
              placeholder="DATA INICIAL"
              value={dataInicial}
              style={{ width: '300px', height: '30px' }}
            />
          </>
        )}
      </Wrapper>
    </TitleBar>
  );
};

import React from 'react';
import PropTypes from 'prop-types';

import { RegionTitle, Title } from './style.titlehead';

export function TitleHead({ title, icon }) {
  return (
    <RegionTitle>
      <Title icon={icon}>{title}</Title>
    </RegionTitle>
  );
}

TitleHead.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

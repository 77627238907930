import React from 'react';

import * as Tag from './style.tagmember';

export const FinStandard = ({ style, className }) => {
  return (
    <Tag.Wrapper
      style={style}
      className={className}
      bgColor="linear-gradient(248.84deg, #FFF9EE -0.22%, #EAE7E1 31.52%, #D8D7D6 59.97%, #949391 106.98%);"
    >
      <Tag.IconCircleWaveStyle
        style={{ marginRight: '5px' }}
        fillColor="#FFFFFF
        "
      />
      <Tag.Label color="#343333">Standard</Tag.Label>
    </Tag.Wrapper>
  );
};

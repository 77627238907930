import { rem } from 'polished';

export function flexUnit(amount, min, max, unit = 'vw', prop = 'font-size') {
  const minBreakpoint = (min / amount) * 100;
  const maxBreakpoint = max ? (max / amount) * 100 : false;
  const dimension = unit === 'vw' ? 'width' : 'height';

  return `
    @media (max-${dimension}: ${minBreakpoint}px) {
      ${prop}: ${min}px;
    }

    ${
      max
        ? `
      @media (min-${dimension}: ${maxBreakpoint}px) {
        ${prop}: ${max}px;
      }
    `
        : ''
    }

    ${prop}: ${amount}${unit}
  `;
}

function stripUnit(value) {
  return value / (value * 0 + 1);
}

export function flexFont(minWidth, maxWidth, minFontSize, maxFontSize) {
  return `
    font-size: ${rem(minFontSize)};
    @media screen and (min-width: ${minWidth}){
      font-size: calc(${minFontSize} + ${stripUnit(
    Number(maxFontSize.replace('px', '')) -
      Number(minFontSize.replace('px', ''))
  )} * ((100vw - ${minWidth}) / ${stripUnit(
    Number(maxWidth.replace('px', '')) - Number(minWidth.replace('px', ''))
  )}));
    }
    @media screen and (min-width: ${maxWidth}) {
      font-size: ${rem(maxFontSize)};
    }
  `;
}

import { useQuery } from 'react-query';
import axios from 'axios';

import CONFIG from 'config';

const baseUrl = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const apiListReason = async (params) => {
  const response = axios.get(
    `${baseUrl}/matching/conexao/motivos/recusa/convite/${params.idTipoEmpresa}`
  );
  const result = await response;
  return result.data || [];
};

export const useQueryListReason = (params) => {
  return useQuery(['motivo', 'list', params], () => apiListReason(params), {
    keepPreviousData: false,
  });
};

import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { format } from 'date-fns';
import { addDays } from 'date-fns';
import isValid from 'date-fns/isValid';
import { Theme } from '../../theme';
import { rgba } from 'polished';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import pt from 'date-fns/locale/pt-BR';
registerLocale('pt', pt);

const InputText = styled.input`
  background-color: ${Theme.white};
  border: 2px solid ${Theme.gray2};
  border-radius: 2px;
  align-items: center;
  text-align: left;
  box-shadow: none;
  display: inline-flex;
  font-size: 0.9rem;
  height: 2.5rem;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.375em - 2px);
  padding-left: calc(0.625em - 2px);
  padding-right: calc(0.625em - 2px);
  padding-top: calc(0.375em - 2px);
  position: relative;
  vertical-align: top;
  width: 100%;
  color: ${Theme.gray5};
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:active {
    border-color: ${Theme.primary};
    box-shadow: ${`0 0 0 0.125em ${rgba(Theme.primary, 0.4)}`};
  }
  &:focus {
    border-color: ${Theme.primary};
    box-shadow: ${`0 0 0 0.125em ${rgba(Theme.primary, 0.4)}`};
  }
  &:hover {
    border-color: ${Theme.primary};
  }
`;

const InputDate = props => {
  const [startDate, setStartDate] = useState(null);

  const onHandleChange = date => {
    setStartDate(date);
    const value = isValid(date) && format(date, 'yyyy-MM-dd');

    if (value) {
      props.action('CHANGE_DATE', value);
    }
  };
  return (
    <>
      <DatePicker
        selected={startDate}
        onChange={onHandleChange}
        locale="pt"
        dateFormat="dd/MM/yyyy"
        placeholderText="Selecione a data"
        minDate={new Date()}
        maxDate={addDays(new Date(), 60)}
        customInput={<InputText />}
        showPopperArrow={true}
        fixedHeight
        withPortal
        /*popperClassName="some-custom-class"
        popperPlacement="bottom-end"
        popperModifiers={{
          offset: {
            enabled: true,
            offset: '5px, 10px',
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: 'viewport',
          },
        }}*/
      />
    </>
  );
};

export default InputDate;

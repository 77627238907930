import { createActions, createReducer } from 'reduxsauce';

// createPopUp : [{'name', 'startDate', 'endDate', 'image', 'showInUrl', 'action', 'active'}]
// campaignShowed : ['name']
// campaignNotShowed : null

import diaClienteImage from 'assets/images/dia_cliente.png';

const { Types, Creators } = createActions(
  {
    createPopUp: ['campaign'],
    campaignShowed: ['name'],
    campaignNotShowedAll: null,

    reset: null,
  },
  {
    prefix: 'UI/',
  }
);

export const UiPopUpTypes = Types;
export const UiPopUpActions = Creators;

// format => mês/dia/ano 00:00:00
//  action: {
//   urlExternal: 'https://www.google.com',
//   urlInternal: '/finmatch',
//   callFunc: () => console.log('teste'),
//   finscannerShow: false
// },

const INITIAL_STATE = {
  campaign: [
    {
      name: 'dia-do-cliente',
      startDate: '09/15/2022 08:00:00',
      endDate: '09/15/2022 23:59:00',
      image: diaClienteImage,
      showInUrl: null,
      action: {},
      active: true,
      showed: false,
    },
  ],
};

const create = (state = INITIAL_STATE, action) => {
  return {
    campaign: [...state, ...action.campaign],
  };
};

const showed = (state = INITIAL_STATE, action) => {
  const campaign = state.campaign.map((item) => {
    if (item.name === action.name) {
      return { ...item, showed: true };
    }
    return item;
  });
  return {
    campaign,
  };
};

// eslint-disable-next-line no-unused-vars
const allNotShowed = (state = INITIAL_STATE, action) => {
  const campaign = state.campaign.map((item) => ({ ...item, showed: false }));
  return {
    campaign,
  };
};

/**
 * Criando reducer
 */

export default createReducer(INITIAL_STATE, {
  [Types.CREATE_POP_UP]: create,
  [Types.CAMPAIGN_SHOWED]: showed,
  [Types.CAMPAIGN_NOT_SHOWED_ALL]: allNotShowed,
});

import styled from 'styled-components/macro';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: #ffffff;
  border-radius: 8px;
  padding: 1rem;
  max-width: 380px;
`;

export const Shadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 200;
`;

export const ButtonPrimary = styled.button`
  width: calc(100% / 3);
  background: ${({ bgColor }) => bgColor || `var(--fingray-400)`};
  border: none;
  border-radius: 6px;
  cursor: pointer;
  padding: 8px 2px;
  color: #ffffff;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 0.875rem;
  &:hover {
    filter: brightness(85%);
  }
`;

export const ButtonSecondary = styled.button`
  width: calc(100% / 3);
  background: ${({ bgColor }) => bgColor || `var(--fingray-400)`};
  border: none;
  border-radius: 6px;
  cursor: pointer;
  padding: 8px 2px;
  color: var(--fingray-900);
  font-family: 'Inter';
  font-weight: 400;
  font-size: 0.875rem;
  &:hover {
    filter: brightness(85%);
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: end;
  gap: 8px;
  margin: 0.5rem 0 0;
`;

export const Header = styled.div`
  display: flex;
  margin: 0 0 0.5rem;
  color: var(--fingray-900);
  font-weight: 700;
  font-size: 1rem;
  font-family: 'Inter';
  text-rendering: geometricprecision;
  align-items: center;
  line-height: 30px;
`;

export const Body = styled.div`
  font-family: 'Inter';
  color: var(--fingray-900);
  font-size: 1rem;
  font-weight: 400;
  text-rendering: geometricprecision;
  margin: 1rem 0;
`;

export const Emoji = styled.span`
  font-size: 2rem;
  margin-right: 0.5rem;
`;

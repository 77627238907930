import React, { useState, useContext, useEffect } from 'react';
import useDebounce from 'hooks/useDebounce';

import { Option } from 'components/FinSelectBox';
import { SeparatorVertical } from 'components/Elements';
import { AgendaContext } from './reducer';
import { MassPriorityPopover } from './MassPriority';
import { useMutateFields } from './mutates/useUpdateFields';

import {
  FilterContainer,
  FilterBar,
  PriorityFilter,
  DateFilter,
  SearchContainer,
  SearchIcon,
  SearchInput,
  EditBar,
  SelectContainer,
  EditPriorityContainer,
  LeftContainer,
  RightContainer,
  EditPriorityTitle,
  SaveBtn,
  SaveIcon,
} from './filterStyles';

import { months } from './months';

export const AgendaFilter = ({
  habilitacao,
  priorityVars,
  selected,
  setSelected,
  prioritySelect,
  setPrioritySelect,
}) => {
  const [state, actions] = useContext(AgendaContext);
  const [search, setSearch] = useState('');
  const debounce = useDebounce(search, 1000);

  const updateFields = useMutateFields();

  const {
    filter: { mesAnoVigencia },
  } = state;

  const updateAllSelecteds = () => {
    const payload = selected.map((item) => ({
      idItemAgenda: item,
      idPrioridade: prioritySelect,
    }));

    updateFields.mutate(payload);
    setSelected([]);
    setPrioritySelect(4);
  };

  const { ano } = mesAnoVigencia;
  const { mes } = mesAnoVigencia;

  useEffect(() => {
    actions.setSearch(debounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce]);

  const setMonths = React.useCallback(() => {
    if (habilitacao) {
      const current = habilitacao.data.calendario.filter(
        (res) => String(res.ano) === String(ano)
      )[0];

      if (current) {
        return current.meses.map((m) => {
          return (
            <Option key={m} value={m}>
              {months[m]}
            </Option>
          );
        });
      }
    }
    return null;
  }, [habilitacao, ano]);

  return (
    <FilterContainer>
      <FilterBar>
        <SearchContainer>
          <SearchInput
            placeholder="Pesquisar"
            largura={'100%'}
            onChange={(e) => setSearch(e.target.value)}
          />
          <SearchIcon />
        </SearchContainer>
        <SeparatorVertical
          wsize={'32px'}
          aHeight={'37px'}
          borderColor={'#E3E3E7'}
        />
        <PriorityFilter
          id="priority-finselect"
          value="4"
          largura={'140px'}
          onChange={(e) => actions.setPriority(Number(e.target.value))}
        >
          <Option value="4">Prioridade</Option>
          <Option value="0">0 (MÁXIMA)</Option>
          <Option value="1">1 (MUITO ALTA)</Option>
          <Option value="2">2 (ALTA)</Option>
          <Option value="3">3 (PADRÃO)</Option>
        </PriorityFilter>
        <SeparatorVertical
          wsize={'32px'}
          aHeight={'37px'}
          borderColor={'#E3E3E7'}
        />
        <DateFilter
          id="year-finselect"
          largura={'112px'}
          value={ano}
          onChange={(e) => {
            actions.setPeriodYear(Number(e.target.value));
          }}
        >
          {habilitacao
            ? habilitacao.data.calendario.map((res) => {
                return (
                  <Option key={res.ano} value={res.ano}>
                    {res.ano}
                  </Option>
                );
              })
            : ''}
        </DateFilter>
        <DateFilter
          id="month-finselect"
          largura={'130px'}
          onChange={(e) => actions.setPeriodMonth(e.target.value)}
          value={mes}
        >
          <Option key={0} value={0}>
            Selecione
          </Option>
          {setMonths()}
        </DateFilter>
        <SeparatorVertical wsize={'10px'} />
      </FilterBar>
      {selected.length > 0 && (
        <EditBar>
          <SelectContainer>
            <span>Selecionados</span>
            <SeparatorVertical wsize={'6px'} />
            <span>{selected.length}</span>
          </SelectContainer>
          <SeparatorVertical
            wsize={'38px'}
            aHeight={'21px'}
            borderColor={'#E3E3E7'}
            style={{ marginBottom: '2px' }}
          />
          <EditPriorityContainer>
            <LeftContainer>
              <EditPriorityTitle>EDITAR PRIORIDADE</EditPriorityTitle>
              <MassPriorityPopover
                priorityVars={priorityVars}
                prioritySelect={prioritySelect}
                setPrioritySelect={setPrioritySelect}
              />
            </LeftContainer>
            <SeparatorVertical wsize={'12px'} style={{ marginBottom: '2px' }} />
            <RightContainer>
              <SaveBtn
                id="save-btn"
                tooltipText="Salvar"
                bgColor={'#008894'}
                onClick={updateAllSelecteds}
                disabled={prioritySelect === 4}
              >
                <SaveIcon />
              </SaveBtn>
            </RightContainer>
          </EditPriorityContainer>
        </EditBar>
      )}
    </FilterContainer>
  );
};

import styled, { css } from 'styled-components';

import { FinInput } from 'components/FinInput/MainInput';
import { FinSelectBox } from 'components/FinSelectBox';
import { ButtonRounded } from 'components/FinButton/ButtonRounded';

import { ReactComponent as Search } from '../../assets/icones/icone-busca.svg';
import { ReactComponent as SaveFile } from '../../assets/icones/icone-salvar-arquivo.svg';

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 73px;
  background: #fff;
  border-radius: 12px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0 20px;
`;

export const FilterBar = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 294px;
  position: relative;
`;

export const SearchInput = styled(FinInput)`
  & input {
    display: flex;
    height: 40px;
    background-color: #f2f2f3;
    border: none;
    border-radius: 10px;
    padding-left: 40px;
    font-family: 'Inter', 'sans-serif';
    font-size: 12px;
    letter-spacing: -0.128px;

    ::placeholder {
      font-family: 'Inter', 'sans-serif';
      font-size: 12px;
      letter-spacing: -0.128px;
      color: #000;
    }
  }
`;

export const SearchIcon = styled(Search)`
  display: flex;
  width: 18px;
  height: 18px;
  position: absolute;
  left: 12px;
  bottom: 12px;
`;

export const XSeparator = styled.div`
  width: 0;
  height: 2.5rem;
  border: 0.5px solid #e3e3e7;
  margin: 0 1rem;
`;

export const PriorityFilter = styled(FinSelectBox)`
  & div {
    /* width: 112px; */
    height: 40px;
    background-color: #f2f2f3;
    border-radius: 10px;
    border: none;
    position: relative;
  }

  & select {
    font-family: 'Inter', 'sans-serif';
    font-size: 12px;
    letter-spacing: -0.128px;
    color: #000;
    text-align: left;
    vertical-align: middle;
    line-height: 34px;
  }

  & ::after {
    background-color: #000;
    position: absolute;
    right: 8px;
  }
`;

export const DateFilter = styled(PriorityFilter)`
  & div {
    /* width: 80px; */
    height: 40px;
  }
`;

export const EditBar = styled.div`
  display: flex;
  flex-direction: row;
  width: 35%;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0 0 16px;
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;

  & :first-child {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 1.5;
  }

  & :last-child {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background-color: #f2f2f3;
    font-size: 16px;
    font-weight: 500;
  }
`;

export const EditPriorityContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 129px;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
`;

export const EditPriorityTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 11px;
  font-weight: 600;
  color: #646464;
  margin-bottom: 6px;
`;

export const PrioritySelect = styled(FinSelectBox)`
  & div {
    height: 20px;
    border-radius: 12px;
    border: 1px solid #c2c2c2;
    position: relative;
    color: #646464;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }

  & select {
    text-align: center;
    vertical-align: middle;
    font-family: 'Inter', 'sans-serif';
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.128px;
    color: #646464;
    line-height: 1;
  }

  & ::after {
    background-color: #646464;
    position: absolute;
    right: 4px;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 40px;
  height: 100%;
`;

export const SaveBtn = styled(ButtonRounded)`
  width: 36px;
  height: 32px;
  border-radius: 12px;

  &:hover {
    background-color: #72afb6;
    & svg {
      stroke: ${'#fff'};
      & path {
        stroke: ${'#fff'};
      }
    }
  }

  &:active {
    background-color: #008894;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed !important;
      background-color: grey;
      pointer-events: none;
    `}
`;
// ^^^falta mudar o hover!!!!

export const SaveIcon = styled(SaveFile)`
  width: 24px;
  height: 24px;
`;

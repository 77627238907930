import styled from 'styled-components/macro';

export const RegionTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0;
  width: 100%;
`;

export const Title = styled.span`
  font-family: 'Inter', sans-serif;
  color: var(--fingray-900);
  font-size: 1.5rem;
  font-weight: 700;
  text-rendering: geometricprecision;
  white-space: nowrap;
  text-indent: 16px;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    background: url(${({ icon }) => icon}) no-repeat center center;
    display: inline-flex;
    width: 32px;
    height: 32px;
  }
`;

import styled, { css } from 'styled-components/macro';

import { Caption } from 'components/FinParagraph';
import { Wrapper } from 'components/FinWrappers';

import { ReactComponent as IconeChevron } from 'assets/images/icon-chevron-down.svg';
import { ReactComponent as IconeCoin } from 'assets/images/icon-coin.svg';
import { ReactComponent as IconeCoinOff } from 'assets/images/icon-coin-off.svg';
import { ReactComponent as IconeNotes } from 'assets/images/icon-notes.svg';
import { ReactComponent as IconeCalendar } from 'assets/images/icon-calendar.svg';
import { ReactComponent as IconeCalendarOff } from 'assets/images/icon-calendar-off.svg';
import { ReactComponent as IconeCircleDash } from 'assets/images/icon-circle-dash.svg';
import { ReactComponent as IconeCircleDCheck } from 'assets/images/icon-circle-d-check.svg';
import { ReactComponent as IconeCheck } from 'assets/images/icon-checked.svg';
import { ReactComponent as IconeAcertos } from 'assets/images/icon-acertos.svg';
import { ReactComponent as IconeMessage } from 'assets/images/icon-chat-message.svg';
import { ReactComponent as IconeCalendarParc } from 'assets/images/icon-calendar-parc.svg';
import { ReactComponent as IconePlus } from 'assets/images/icon-plus.svg';
import { ReactComponent as IconeMinus } from 'assets/images/icon-minus.svg';

export const IconeMinusStyle = styled(IconeMinus)`
  stroke: ${({ theme }) => theme.newtheme.color.branco};
  & path {
    stroke: ${({ theme }) => theme.newtheme.color.branco};
  }
`;

export const IconePlusStyle = styled(IconePlus)`
  stroke: ${({ theme }) => theme.newtheme.color.branco};
  & path {
    stroke: ${({ theme }) => theme.newtheme.color.branco};
  }
`;

export const IconeCalendarParcStyle = styled(IconeCalendarParc)`
  stroke: ${({ theme }) => theme.newtheme.color.azulPrimario};
  & path {
    stroke: ${({ theme }) => theme.newtheme.color.azulPrimario};
  }
`;

export const IconeMessageStyle = styled(IconeMessage)`
  stroke: ${({ theme }) => theme.newtheme.color.azulPrimario};
  & path {
    stroke: ${({ theme }) => theme.newtheme.color.azulPrimario};
  }
`;

export const IconeAcertosStyle = styled(IconeAcertos)`
  stroke: ${({ theme }) => theme.newtheme.color.azulPrimario};
  & path {
    stroke: ${({ theme }) => theme.newtheme.color.azulPrimario};
  }
`;

export const IconeCheckStyle = styled(IconeCheck)`
  stroke: ${({ theme }) => theme.newtheme.color.success};
  & path {
    stroke: ${({ theme }) => theme.newtheme.color.success};
  }
`;

export const IconeCircleDCheckStyle = styled(IconeCircleDCheck)`
  stroke: ${({ theme }) => theme.newtheme.color.success};
  & path {
    stroke: ${({ theme }) => theme.newtheme.color.success};
  }
`;

export const IconeCircleDashStyle = styled(IconeCircleDash)`
  stroke: ${({ theme }) => theme.newtheme.color.cinza2};
  & path {
    stroke: ${({ theme }) => theme.newtheme.color.cinza2};
  }
`;

export const IconeNotesStyle = styled(IconeNotes)`
  stroke: ${({ theme }) => theme.newtheme.color.azulPrimario};
  & path {
    stroke: ${({ theme }) => theme.newtheme.color.azulPrimario};
  }
`;

export const IconeCalendarStyle = styled(IconeCalendar)`
  stroke: ${({ theme }) => theme.newtheme.color.alert};
  & path {
    stroke: ${({ theme }) => theme.newtheme.color.alert};
  }
`;

export const IconeCalendarOffStyle = styled(IconeCalendarOff)`
  stroke: ${({ theme }) => theme.newtheme.color.cinza3};
  & path {
    stroke: ${({ theme }) => theme.newtheme.color.cinza3};
  }
`;

export const IconeCoinStyle = styled(IconeCoin)`
  stroke: ${({ theme }) => theme.newtheme.color.alert};
  & path {
    stroke: ${({ theme }) => theme.newtheme.color.alert};
  }
`;

export const IconeCoinOffStyle = styled(IconeCoinOff)`
  stroke: ${({ theme }) => theme.newtheme.color.cinza3};
  & path {
    stroke: ${({ theme }) => theme.newtheme.color.cinza3};
  }
`;

export const WrapperCustom2 = styled(Wrapper)`
  height: 40px;
  width: inherit;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  ${({ align }) => align && `align-items: ${align};`}
`;

export const WrapperCustom = styled(Wrapper)`
  height: 20px;
  width: ${({ largura }) => largura || `inherit`};
  padding: 0;
  justify-content: ${({ justifyContent }) => justifyContent || `flex-start`};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem;
  width: 100%;
  min-height: 400px;
  background-color: ${({ theme }) => theme.newtheme.color.cinza1};
`;

export const CaptionLabel = styled(Caption)`
  font-weight: bold;
`;

export const IconeChevronStyle = styled(IconeChevron)`
  stroke: #000;
  & path {
    stroke: #000;
  }
  transition: all 0.3s ease-in-out;
`;

export const ContainerSacado = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 0 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: #d6f1f3;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.newtheme.color.cinza2};
      background-color: #d6f1f3;

      & > table > tbody > tr > td > div > svg {
        transform: rotate(180deg);
      }
    `}
`;

export const WrapperTitulo = styled.div`
  width: 100%;
  height: 324px;
  background-color: #fff;
  padding: 0 2rem;
  overflow-y: auto;
`;

export const ContainerCard = styled(Wrapper)`
  border-radius: 10px;
  overflow: overlay;
  flex-direction: column;
  width: 100%;
  margin: 0 0 8px 0;
  padding: 0;
  border: 1px solid #e2e1e1;
`;

export const WrapperFields = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 2rem;
  border: 1px solid #e2e1e1;
  border-radius: 11px;
`;

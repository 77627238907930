import { useQuery } from 'react-query';
import axios from 'axios';

import CONFIG from 'config';

import { useQueryState } from 'hooks/useQueryState';
import { getUserData } from 'services/getUserData';

const baseUrl = CONFIG.API_REMODELING_URL;

const queryKeyFactor = {
  all: ['empreendedores'],
  clients: (filters) => [...queryKeyFactor.all, 'clients', filters],
  potential: (filters) => [...queryKeyFactor.all, 'potential', filters],
  blocked: (filters) => [...queryKeyFactor.all, 'blocked', filters],
  connections: (filters) => [...queryKeyFactor.all, 'connections', filters],
};

const formatFilter = (params) => {
  const filters = params;
  if (filters.perfil) {
    if (filters.perfil.indexOf('completo') !== -1) {
      filters.perfilCompleto = true;
    }

    if (filters.perfil.indexOf('docCompleto') !== -1) {
      filters.documentoCompleto = true;
    }

    delete filters.perfil;
  }

  if (filters.status) {
    const ids = {
      online: 0,
      offline: 1,
    };

    const values = filters.status.map((item) => ids[item]);
    filters.statusGrupo = values;

    delete filters.status;
  }

  if (filters.habilitacao) {
    const ids = {
      pedente: 1,
      naoHabilitado: 2,
      habilitado: 3,
    };

    const values = filters.habilitacao.map((item) => ids[item]);
    filters.statusHabilitado = values;
    delete filters.habilitacao;
  }

  if (filters.credito) {
    const ids = {
      pendente: 1,
      aprovado: 2,
      naoAprovado: 3,
    };

    const values = filters.credito.map((item) => ids[item]);
    filters.statusCredito = values;
    delete filters.credito;
  }

  if (filters.matching) {
    const ids = {
      match: 0,
      indicacao: 1,
    };

    const values = filters.matching.map((item) => ids[item]);
    filters.matching = values;
  }

  if (filters.skip) {
    delete filters.skip;
  }
  return filters;
};

const fecthClients = async (params) => {
  const { idEmpresa } = await getUserData();

  const payload = {
    size: params.size,
    skip: params.skip,
    filter: { ...params.filter },
  };

  const response = axios.post(
    `${baseUrl}/business-api/api/matching/cliente/cedente/${idEmpresa}`,
    payload
  );
  const result = await response;
  return result.data || [];
};

export const useQueryClients = () => {
  const { getQueries } = useQueryState();

  const filters = getQueries();

  const payload = {
    size: 8,
    skip: filters.skip || 1,
    filter: {
      tipoAba: 1,
      ordem: 2,
      ...formatFilter(filters),
    },
  };

  return useQuery(
    queryKeyFactor.clients(payload),
    () => fecthClients(payload),
    {
      keepPreviousData: false,
    }
  );
};

export const useQueryClientsPotential = () => {
  const params = {
    size: 8,
    skip: 1,
    filter: {
      tipoAba: 1,
      ordem: 2,
      campo: 2,
    },
  };

  return useQuery(
    queryKeyFactor.potential(params),
    () => fecthClients(params),
    {
      keepPreviousData: false,
    }
  );
};

export const useQueryClientsBlocked = () => {
  const { getQueries } = useQueryState();

  const filters = getQueries();

  const payload = {
    size: 8,
    skip: filters.skip || 1,
    filter: {
      tipoAba: 3,
      ordem: 2,
      ...formatFilter(filters),
    },
  };

  return useQuery(
    queryKeyFactor.blocked(payload),
    () => fecthClients(payload),
    {
      keepPreviousData: false,
    }
  );
};

export const useQueryMyConnections = () => {
  const { getQueries } = useQueryState();

  const filters = getQueries();

  const payload = {
    size: 8,
    skip: filters.skip || 1,
    filter: {
      tipoAba: 2,
      ordem: 2,
      ...formatFilter(filters),
    },
  };

  return useQuery(
    queryKeyFactor.connections(payload),
    () => fecthClients(payload),
    {
      keepPreviousData: false,
    }
  );
};

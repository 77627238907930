import React from 'react';
import styled, { css } from 'styled-components/macro';

import { rgba } from 'polished';

const Container = styled.span`
  display: inline-block;
  min-width: 20px;
  padding: 0 6px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: ${({ theme, pcolor }) => pcolor || theme.newtheme.color.cinza6};
  text-align: center;
  background-color: ${({ theme, pbgcolor }) =>
    pbgcolor === 'white'
      ? rgba(theme.newtheme.color.branco, 0.5)
      : rgba(theme.newtheme.color.cinza2, 0.5)};
  border: 1px solid
    ${({ theme, pbgcolor }) =>
      pbgcolor === 'white'
        ? rgba(theme.newtheme.color.cinza3, 0.5)
        : 'transparent'};
  border-radius: 2em;
  margin-left: 8px;
  ${({ pbgcolor, theme }) =>
    pbgcolor === 'red' &&
    css`
      background-color: ${theme.newtheme.color.vermelhoPrimario};
      border-color: ${theme.newtheme.color.vermelhoPrimario};
    `}
  ${({ pbgcolor, theme }) =>
    pbgcolor === 'azul' &&
    css`
      background-color: ${theme.newtheme.color.azulPrimario};
      border-color: ${theme.newtheme.color.azulPrimario};
    `}
`;

const Counter = ({ pbgcolor, pcolor, children }) => {
  return (
    <Container pbgcolor={pbgcolor} pcolor={pcolor}>
      {children}
    </Container>
  );
};

export default Counter;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Box } from 'components/FinFrame/Box';
import { Wrapper } from 'components/FinWrappers';

import { Paragraph2 } from 'components/FinParagraph';

const WrapperCuston = styled(Wrapper)`
  position: absolute;
  padding: 0;
  width: 200px;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
`;

const Item = styled.li`
  margin: 0.8rem 0;
  padding: 0.5rem 0;

  &:hover {
    background-color: ${({ theme }) => theme.newtheme.color.cinza1};
  }
`;

const Icone = styled.span`
  pointer-events: none;
`;

const Button = styled.button`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  outline: none;
  cursor: pointer;
  width: 100%;
  justify-content: flex-start;
  padding: 0 2rem;
`;

export const DropdownItem = ({ icon, onclick, children }) => (
  <Item>
    <Button onClick={onclick}>
      {icon && (
        <>
          <Icone data-testid="icone-dropdown-item">{icon}</Icone>
          &nbsp;&nbsp;&nbsp;
        </>
      )}
      <Paragraph2>{children}</Paragraph2>
    </Button>
  </Item>
);

const Dropdown = ({ children }) => {
  return (
    <WrapperCuston>
      <List>
        <Box>{children}</Box>
      </List>
    </WrapperCuston>
  );
};

Dropdown.propTypes = {
  children: PropTypes.node,
  icone: PropTypes.node,
};

export default Dropdown;

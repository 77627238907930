/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { createActions, createReducer } from 'reduxsauce';
import { call, put, take, fork, cancel, cancelled } from 'redux-saga/effects';
import axios, { CancelToken } from 'axios';

import { getUserData } from 'services/getUserData';
import { Creators as uiToasterActions } from 'store/ducks/uiToaster';
import { Creators as apiTermosNaoAssinado } from './apiTermosNaoAssinado';

import CONFIG from '../../config';

/**
 * Criando action types & creators
 */

export const { Types, Creators } = createActions({
  apiTermosAssinarRequest: ['idTermo'],
  apiTermosAssinarSuccess: ['data'],
  apiTermosAssinarError: [],
  apiTermosAssinarCancel: null,
});

/**
 * Criando os reducer handlers
 */

const INITIAL_STATE = {
  isLoading: null,
  data: null,
  error: false,
};

const apiAssinarRequest = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: true,
    data: null,
    error: false,
  };
};

const apiAssinarSuccess = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: action.data,
    error: false,
  };
};

const apiAssinarError = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: null,
    error: true,
  };
};

const apiAssinarCancel = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: null,
    error: false,
  };
};

/**
 * Criando reducer
 */

export default createReducer(INITIAL_STATE, {
  [Types.API_TERMOS_ASSINAR_REQUEST]: apiAssinarRequest,
  [Types.API_TERMOS_ASSINAR_SUCCESS]: apiAssinarSuccess,
  [Types.API_TERMOS_ASSINAR_ERROR]: apiAssinarError,
  [Types.API_TERMOS_ASSINAR_CANCEL]: apiAssinarCancel,
});

/**
 * Sagas
 */

function* sagaGetAsynResult(params, endpoint) {
  const source = CancelToken.source();
  const url = `${CONFIG.API_REMODELING_URL}/user-api${endpoint}`;
  try {
    const response = yield axios.post(url, params, {
      cancelToken: source.token,
    });
    yield put(Creators.apiTermosAssinarSuccess(response.data));
    yield put(
      uiToasterActions.uiToasterMessage(
        { message: 'Aceito com sucesso!!' },
        'success'
      )
    );
    yield put(
      apiTermosNaoAssinado.apiTermosNaoAssinadoRequest({
        idUsuario: params.idUsuario,
      })
    );
  } catch (error) {
    console.log(error);
    yield put(uiToasterActions.uiToasterMessage(error, 'error'));
    yield put(Creators.apiTermosAssinarError());
  } finally {
    if (yield cancelled()) {
      console.log('cancel');
      source.cancel();
    }
  }
}

export function* sagaApiTermoAssinar(action) {
  const user = yield call(getUserData);

  if (user) {
    const params = {
      idUsuario: user.idUsuario,
      idTermo: action.idTermo,
    };

    const task = yield fork(sagaGetAsynResult, params, '/api/termo');

    yield take(Types.API_TERMOS_ASSINAR_CANCEL);
    yield cancel(task);
  } else {
    yield put(Creators.apiTermosAssinarError());
  }
}

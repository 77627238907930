import { takeLatest, takeEvery, all, debounce } from 'redux-saga/effects';

import {
  Types as TypesNaoAssinado,
  sagaApiTermosNaoAssinado,
} from 'store/ducks/apiTermosNaoAssinado';

import {
  Types as TypesAssinar,
  sagaApiTermoAssinar,
} from 'store/ducks/apiTermosAssinar';

import {
  Types as TypesGeocode,
  sagaApiGetGeocode,
} from 'store/ducks/apiGeocode';
import { Types as TypesFiltro, sagaApiGetFiltro } from 'store/ducks/apiFiltro';
import {
  Types as TypesSearchRouter,
  sagaSearchRouter,
} from 'store/ducks/searchRouter';
import {
  Types as TypesCreateMeeting,
  sagaCreateMeeting,
} from 'store/ducks/apiCreateMeeting';

import {
  Types as TypesMotivosConvite,
  sagaMotivoConvites,
} from 'store/ducks/apiMotivosConvites';

import {
  Types as TypesListClientes,
  sagaListClientes,
} from 'store/ducks/apiListClientes';

import {
  Types as TypesInformacoes,
  sagaInformacoes,
} from 'store/ducks/apiInformacoes';

import {
  Types as TypesConvitesRecebidos,
  sagaConvitesRecebidos,
} from 'store/ducks/apiConvitesRecebidos';

import {
  Types as TypesConvitesEnviados,
  sagaConvitesEnviados,
} from 'store/ducks/apiConvitesEnviados';

import { Types as TypesAceitar, sagaAceitar } from 'store/ducks/apiAceitar';
import { Types as TypesBloquear, sagaBloquear } from 'store/ducks/apiBloquear';
import { Types as TypesCancelar, sagaCancelar } from 'store/ducks/apiCancelar';
import { Types as TypesConvidar, sagaConvidar } from 'store/ducks/apiConvidar';
import {
  Types as TypesDesbloquear,
  sagaDesbloquear,
} from 'store/ducks/apiDesbloquear';

import {
  Types as TypesHabilitar,
  sagaHabilitar,
} from 'store/ducks/apiHabilitar';

import {
  Types as TypesDesabilitar,
  sagaDesabilitar,
} from 'store/ducks/apiDesabilitar';

import { Types as TypesRecusar, sagaRecusar } from 'store/ducks/apiRecusar';
import { Types as TypesPesquisa, sagaPesquisa } from 'store/ducks/apiPesquisa';

import { Types as TypesCbbox, sagaCbbox } from 'store/ducks/apiCbbox';

import { Types as TypesCNAE, sagaCNAE } from 'store/ducks/apiMatchListaCNAE';
import {
  Types as TypesMATCHADERENCIA,
  sagaMATCHADERENCIA,
} from 'store/ducks/apiMatchObtemAderencia';

import {
  Types as TypesMATCHLISTAGEM,
  sagaMATCHLISTAGEM,
} from 'store/ducks/apiMatchObtemListagem';

import {
  Types as TypesMATCHCADASTRO,
  sagaMATCHCADASTRO,
} from 'store/ducks/apiMatchCadastro';

import {
  getOperacoes,
  desistirOperacao,
  loadFiltro,
  newDownloadBodero,
  openchat,
  listPocDocs,
  getOneOperation,
  getResumeOper,
} from './operacoesSagas';

import {
  getPropostas,
  calculaPropostas,
  incluirContraPropostas,
  aceitarPropostas,
  aprovadaPropostas,
  getInfoPagProposta,
  pagamentoProposta,
} from './propostasSagas';

import { getBorderos, updateBorderos } from './borderosSaga';

import { loadCardsData, loadChartData } from './dashboardSagas';

import {
  loadPregoes,
  aceitaPregoes,
  rejeitaPregoes,
  loadRecordsCount,
  getExibepreco,
} from './pregoesSagas';

import {
  listFinbox,
  downloadDocuments,
  listHabilita,
  infoBasica,
  downloadDocsZip,
  generatesMeeting,
  permissoesActions,
  loadListFiltros,
  loadListCnae,
} from './finboxSagas';

import {
  listCondicionante,
  concluirCondicionante,
  excluirCondicionante,
  cancelarCondicionante,
  searchCedentes,
  sendCondicionante,
} from './condicionanteSagas';

import { searchItems, searchItemsFinbox } from './searchItensSaga';

import { loadCep } from './cepSagas';
import {
  listPerfil,
  postPerfil,
  listCampanha,
  postCampanha,
  getTermos,
  postTermos,
} from './perfilSagas';

function* index() {
  yield all([
    takeLatest('LOAD_DATA_OPERACOES_REQUEST', getOperacoes),
    takeLatest('LOAD_DATA_PROPOSTAS_REQUEST', getPropostas),
    takeLatest('LOAD_DATA_BORDEROS_REQUEST', getBorderos),
    takeLatest('UPDATE_DATA_BORDEROS_REQUEST', updateBorderos),
    takeLatest('CALCULA_PROPOSTAS_REQUEST', calculaPropostas),
    takeLatest('DOWNLOAD_OPERACOES_REQUEST', newDownloadBodero),
    takeLatest('DESISTIR_OPERACOES_REQUEST', desistirOperacao),
    takeLatest('INCLUIR_CONTRA_PROPOSTAS_REQUEST', incluirContraPropostas),
    takeLatest('ACEITAR_PROPOSTA_REQUEST', aceitarPropostas),
    takeLatest('APROVADA_PROPOSTA_REQUEST', aprovadaPropostas),
    takeLatest('INFO_PAG_PROPOSTA_REQUEST', getInfoPagProposta),
    takeLatest('PAGAMENTO_PROPOSTA_REQUEST', pagamentoProposta),
    takeLatest('LOAD_DATA_CARDS_REQUEST', loadCardsData),
    takeLatest('LOAD_DATA_CHARTS_REQUEST', loadChartData),
    takeLatest('LOAD_DATA_FINALIZADOS_REQUEST', loadFiltro),
    takeLatest('LOAD_DATA_PREGOES_REQUEST', loadPregoes),
    takeLatest('ACEITA_PREGOES_REQUEST', aceitaPregoes),
    takeLatest('REJEITA_PREGOES_REQUEST', rejeitaPregoes),
    takeLatest('OPEN_CHAT_REQUEST', openchat),
    debounce(200, 'SET_FILTERED_DATA_LISTS_REQUEST', searchItems),
    debounce(2000, 'SET_FILTERED_DATA_FINBOX_REQUEST', searchItemsFinbox),
    takeLatest('LOAD_COUNT_RECORDS_DATA_REQUEST', loadRecordsCount),
    takeLatest('LOAD_LIST_POC_DOC_REQUEST', listPocDocs),
    takeLatest('LOAD_LIST_REQUEST', listFinbox),
    takeLatest('DOWNLOAD_DOC_REQUEST', downloadDocuments),
    takeLatest('HABILITA_REQUEST', listHabilita),
    takeLatest('INFOBASICA_REQUEST', infoBasica),
    takeLatest('LOAD_LIST_CONDIC_REQUEST', listCondicionante),
    takeLatest('CONCLUIR_CONDIC_REQUEST', concluirCondicionante),
    takeLatest('EXCLUIR_CONDIC_REQUEST', excluirCondicionante),
    takeLatest('CANCELAR_CONDIC_REQUEST', cancelarCondicionante),
    debounce(3000, 'FILTERD_LIST_CEDENTE_REQUEST', searchCedentes),
    takeLatest('SEND_CONDIC_REQUEST', sendCondicionante),
    takeLatest('GET_OPERATION_REQUEST', getOneOperation),
    takeLatest('LOAD_RESUMO_REQUEST', getResumeOper),
    takeLatest('LOAD_EXIBE_PRECO_REQUEST', getExibepreco),
    takeLatest('SAVE_DOC_FILE_ZIP', downloadDocsZip),
    takeLatest('LOAD_CEP_REQUEST', loadCep),
    takeLatest('LOAD_PERFIL_REQUEST', listPerfil),
    takeLatest('POST_PERFIL_REQUEST', postPerfil),
    takeLatest('GENERATES_MEETING_REQUEST', generatesMeeting),
    takeLatest('LOAD_CAMPANHA_REQUEST', listCampanha),
    takeLatest('POST_CAMPANHA_REQUEST', postCampanha),
    takeLatest('PERMISSOES_REQUEST', permissoesActions),
    takeLatest('LOAD_LIST_FIELDS_REQUEST', loadListFiltros),
    debounce(500, 'LOAD_CNAE_REQUEST', loadListCnae),
    takeLatest('GET_TERMOS_REQUEST', getTermos),
    takeLatest('SET_TERMOS_REQUEST', postTermos),
    takeLatest(TypesGeocode.API_GEOCODE_REQUEST, sagaApiGetGeocode),
    takeLatest(TypesFiltro.API_FILTRO_REQUEST, sagaApiGetFiltro),
    takeLatest(TypesSearchRouter.ADD_SEARCH_ROUTE, sagaSearchRouter),
    takeLatest(
      TypesCreateMeeting.API_CREATE_MEETING_REQUEST,
      sagaCreateMeeting
    ),
    takeLatest(
      TypesMotivosConvite.API_MOTIVO_CONVITES_REQUEST,
      sagaMotivoConvites
    ),
    takeLatest(TypesListClientes.API_LIST_CLIENTES_REQUEST, sagaListClientes),
    takeLatest(TypesInformacoes.API_INFORMACOES_REQUEST, sagaInformacoes),
    takeLatest(
      TypesConvitesRecebidos.API_CONVITES_RECEBIDOS_REQUEST,
      sagaConvitesRecebidos
    ),
    takeLatest(
      TypesConvitesEnviados.API_CONVITES_ENVIADOS_REQUEST,
      sagaConvitesEnviados
    ),
    takeEvery(TypesAceitar.API_ACEITAR_REQUEST, sagaAceitar),
    takeLatest(TypesBloquear.API_BLOQUEAR_REQUEST, sagaBloquear),
    takeEvery(TypesCancelar.API_CANCELAR_REQUEST, sagaCancelar),
    takeEvery(TypesConvidar.API_CONVIDAR_REQUEST, sagaConvidar),
    takeEvery(TypesDesbloquear.API_DESBLOQUEAR_REQUEST, sagaDesbloquear),
    takeEvery(TypesHabilitar.API_HABILITAR_REQUEST, sagaHabilitar),
    takeEvery(TypesDesabilitar.API_DESABILITAR_REQUEST, sagaDesabilitar),
    takeEvery(TypesRecusar.API_RECUSAR_REQUEST, sagaRecusar),
    takeLatest(TypesPesquisa.API_PESQUISA_REQUEST, sagaPesquisa),
    takeLatest(TypesCbbox.API_CBBOX_REQUEST, sagaCbbox),
    takeLatest(TypesCNAE.API_CNAE_REQUEST, sagaCNAE),
    takeLatest(
      TypesMATCHADERENCIA.API_MATCHADERENCIA_REQUEST,
      sagaMATCHADERENCIA
    ),
    takeLatest(TypesMATCHLISTAGEM.API_MATCHLISTAGEM_REQUEST, sagaMATCHLISTAGEM),
    takeLatest(TypesMATCHCADASTRO.API_MATCHCADASTRO_REQUEST, sagaMATCHCADASTRO),
    takeLatest(
      TypesNaoAssinado.API_TERMOS_NAO_ASSINADO_REQUEST,
      sagaApiTermosNaoAssinado
    ),
    takeLatest(TypesAssinar.API_TERMOS_ASSINAR_REQUEST, sagaApiTermoAssinar),
  ]);
}

export default index;

import React from 'react';

import { ApplyColorSvg } from 'helpers/ApplyColorSvg';
import { StatusStyle } from './style';
import { WrapperStatus } from './WrapperStatus';

import { STATUS } from '../../config';

export const Status = ({ tipo, changeToAproved = [], excludeStatus = [] }) => {
  let customType = tipo;

  if (changeToAproved.indexOf(tipo) !== -1) {
    customType = 'propostaAprovada';
  }

  if (excludeStatus.indexOf(tipo) !== -1) {
    return null;
  }

  if (customType) {
    return (
      STATUS[customType].icon && (
        <StatusStyle>
          <WrapperStatus
            label={STATUS[customType].label}
            bgColor={STATUS[customType].color}
          >
            <ApplyColorSvg
              comp={STATUS[customType].icon.value}
              color={STATUS[customType].color}
              tipo={STATUS[customType].icon.tipo}
            />
          </WrapperStatus>
        </StatusStyle>
      )
    );
  }

  return null;
};

import React from 'react';
import styled from 'styled-components/macro';

import { Box } from 'components/FinFrame';
import { Wrapper } from 'components/FinWrappers';

import { StringToSvg } from 'helpers/svgFiles/StringToSvg';
import feather from 'feather-icons/dist/icons.json';
import { SubTitle2 } from 'components/FinSubTitles';
import { finTheme } from 'finTheme';

const CustonWrapper = styled(Wrapper)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  overflow: hidden;
  padding: 0;
`;

const Head = styled(Wrapper)`
  width: 100%;
  height: 56px;
  border-bottom: 1px solid ${({ theme }) => theme.newtheme.color.cinza2};
`;

const Body = styled(Wrapper)`
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 56px);
  overflow-y: auto;
  padding: 2rem;
`;

const CloseBtn = styled(Wrapper)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 20px;
  height: 23px;
  margin: 0.8rem;
  padding: 0;
`;

const BoxCuston = styled(Box)`
  height: 100%;
  max-height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const SubMenuFilter = ({ open, close, children, title }) => {
  return (
    open && (
      <CustonWrapper>
        <BoxCuston>
          <Head>
            <CloseBtn onClick={close}>
              <StringToSvg
                width="100%"
                height="100%"
                stroke={finTheme.newtheme.color.cinza5}
              >
                {feather.x}
              </StringToSvg>
            </CloseBtn>
            <SubTitle2>{title}</SubTitle2>
          </Head>
          <Body>
            <Wrapper
              tipo="column"
              pwidth="100%"
              alignItems="flex-start"
              padding="0"
            >
              {children}
            </Wrapper>
          </Body>
        </BoxCuston>
      </CustonWrapper>
    )
  );
};

export default SubMenuFilter;

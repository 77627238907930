/* eslint-disable no-console */
import { createActions } from 'reduxsauce';
import { put, select } from 'redux-saga/effects';

import { push } from 'connected-react-router';

/**
 * Criando action types & creators
 */

export const { Types, Creators } = createActions({
  addSearchRoute: ['search', 'state'],
});

/**
 * Sagas
 */

const valid = (value) => {
  let val = value;
  if (typeof value === 'number') val = value.toString();

  if (
    val !== '0' &&
    val !== '' &&
    val !== 'false' &&
    val !== false &&
    val.length > 0
  ) {
    return true;
  }

  return false;
};

const isArrayValue = (char, field, obj) => {
  if (Array.isArray(obj[field])) {
    return `${char}${field}=${JSON.stringify(obj[field])}`;
  }
  return `${char}${field}=${obj[field]}`;
};

export function* sagaSearchRouter(action) {
  const stateRouter = (state) => state.router;
  const routes = yield select(stateRouter);

  const decodedQuery = Object.keys(routes.location.query).map((res) => ({
    [decodeURIComponent(res)]: routes.location.query[res],
  }));

  let objectQuery = {};
  decodedQuery.forEach((res) => {
    objectQuery = { ...objectQuery, ...res };
  });

  const noMatch = [];
  Object.keys(objectQuery).forEach((query) => {
    if (!Object.keys(action.search).includes(query)) {
      noMatch.push(query);
    }
  });

  // console.log(noMatch);
  // console.log(noMatch.length);

  let str = '';
  noMatch.forEach((res, index) => {
    if (index === 0) {
      str += `?${res}=${objectQuery[res]}`;
    } else {
      str += `&${res}=${objectQuery[res]}`;
    }
  });

  // console.log(action.search);

  Object.keys(action.search).forEach((res) => {
    if (!valid(action.search[res])) {
      delete action.search[res];
    }
  });

  // console.log(action.search);

  Object.keys(action.search).forEach((res, index) => {
    if (index === 0 && noMatch.length === 0) {
      if (valid(action.search[res]))
        str += isArrayValue('?', res, action.search);
    } else if (valid(action.search[res]))
      str += isArrayValue('&', res, action.search);
  });

  // console.log(str);

  if (action.state) {
    yield put(push({ search: str, state: action.state }));
  } else {
    yield put(push(str));
  }
}

import React, { useContext } from 'react';

import { Speedometer } from './Speedometer';

import { Flex, Operameter, OperameterValue, Marker } from './styles.match';
import { FinMatchContext } from '../PageFinmatch';

export function RegionSpeedometer() {
  const { data } = useContext(FinMatchContext);
  const { matchPorcentagem } = data.informacoes_Match;
  return (
    <Flex direction="row">
      <Operameter>
        <OperameterValue isActive={matchPorcentagem >= 70}>
          <Marker>{'> 70% deu Match'}</Marker>
        </OperameterValue>

        <OperameterValue
          isActive={matchPorcentagem >= 50 && matchPorcentagem < 70}
        >
          <Marker backgroundMarker="#F2A71B">{'> 50% e < 70% analisar'}</Marker>
        </OperameterValue>

        <OperameterValue isActive={matchPorcentagem < 50}>
          <Marker backgroundMarker="#D92525">{'< 50% não deu Match'}</Marker>
        </OperameterValue>
      </Operameter>
      <Speedometer />
    </Flex>
  );
}

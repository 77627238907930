import { useQuery } from 'react-query';
import axios from 'axios';
import CONFIG from 'config';
import { getUserData } from 'services/getUserData';
import { useContext } from 'react';
import { OperacoesContext } from '../provider';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const ABAS = {
  emAberto: 1,
  todas: 2,
  realizadas: 3,
  canceladas: 4,
  desistencias: 5,
  perdidas: 6,
};

const totalAbas = async (params) => {
  const { idEmpresa } = await getUserData();
  const fetchData = axios.post(
    `${BASE_URL}/pregao/proposta/ofertas/abas/convenios`,
    { idConvenio: idEmpresa, ...params }
  );

  const result = await fetchData;
  const { data } = result;

  return data || [];
};

export const useFetchTotalAbas = () => {
  const [state] = useContext(OperacoesContext);
  const {
    search,
    date,
    navigation: { tabActive, tipoPregao },
  } = state;

  const tipo =
    Number(tipoPregao) === 0 ? null : { tipoPregao: Number(tipoPregao) };

  const dataVigencia =
    tabActive === 'emAberto' ? null : { dataInicioVigencia: date };

  const params = {
    busca: search,
    tipoAba: ABAS[tabActive],
    ...dataVigencia,
    ...tipo,
  };

  return useQuery(['operacoes', 'tabs', params], () => totalAbas(params), {
    keepPreviousData: true,
  });
};

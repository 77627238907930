import React, { useContext, useState, useEffect, useCallback } from 'react';

import { Wrapper } from 'components/FinWrappers';
import Button from 'components/FinButton';

import { ModalCuston } from 'components/FinModalCuston';
import { Title5, Title6 } from 'components/FinHeadings';
import {
  SeparatorVertical,
  SeparatorHorizontal,
} from 'components/FinSeparador';
import { FinInputCurrency, FinInputDate } from 'components/FinInput/MainInput';

import { currency } from 'helpers/format';
import {
  dateValidate,
  removerParcela,
  convert,
  ordenarParcelas,
} from '../provider/helpers';

import { Parcelas } from '../Propostas/Parcelas';

import {
  Container,
  IconeCalendarParcStyle,
  WrapperFields,
  IconePlusStyle,
} from './style.bordero';

import { OperacoesContext } from '../provider';
import { HeaderStyle } from '../Propostas/style';

import { useSelectProposta } from '../hooks/useSelectProposta';

const Head = ({ rules }) => {
  return (
    <HeaderStyle>
      <IconeCalendarParcStyle />
      <SeparatorVertical pwidth="0.4rem" />
      <Title5 style={{ fontWeight: 'bold', margin: '0' }}>
        {`Parcelas ${rules.indexOf('view') !== -1 ? '' : ' (Editar)'}`}
      </Title5>
    </HeaderStyle>
  );
};

export const ModalParcelas = () => {
  const [valor, setValor] = useState(0);
  const [parcelas, setParcelas] = useState([]);
  const [errorParcelas, setErrorParcelas] = useState([]);

  // {
  //   idNumero: 0,
  //   idParcela: 0,
  //   valor: 0,
  //   vencimento: '',
  // },

  const [errorVenc, setErrorVenc] = useState(null);
  const [vencimento, setVencimento] = useState('');

  const [state, actions] = useContext(OperacoesContext);

  const {
    modal: { values },
  } = state;

  const { proposta } = useSelectProposta({ idProposta: values.idProposta });

  const source = proposta.nota ? 'nota' : 'semNota';

  const onSubmit = () => {
    if (
      parcelas.filter(
        (item) => Number(item.valor) === 0 || item.vencimento === ''
      ).length === 0
    ) {
      actions.updateParcelaAllFieldsEmprestimo({ parcelas });
      actions.removeModalActive();
    }
  };

  const onChangeValor = useCallback(
    (value, idNumero) => {
      if (value !== 0) {
        const updated = parcelas.map((item) =>
          item.idNumero === idNumero ? { ...item, valor: value } : item
        );

        setParcelas(updated);
      } else {
        const updated = parcelas.map((item) =>
          item.idNumero === idNumero ? { ...item, valor: 0 } : item
        );

        setParcelas(updated);
      }
    },
    [parcelas]
  );

  const onChangeVencimento = useCallback(
    (value, idNumero) => {
      const numbers = value.replace(/[~`!@#$%^&*()+={}[\];:'"<>.,/\\?-_]/g, '');

      if (value !== '' && numbers.length === 8) {
        if (dateValidate(value, parcelas)) {
          const updated = parcelas.map((item) =>
            item.idNumero === idNumero
              ? { ...item, vencimento: `${convert(value)}T00:00:00` }
              : item
          );

          setParcelas(updated);
        } else {
          const erros = errorParcelas.filter((item) => item.id !== idNumero);
          const newErro = { id: idNumero, message: 'Data inválida' };

          setErrorParcelas([...erros, newErro]);
        }
      }
    },
    [parcelas, errorParcelas, setErrorParcelas]
  );

  const deleteParcela = useCallback(
    (indice) => {
      const rest = parcelas.length;
      if (rest > 1) {
        const newParcelas = removerParcela({ indice, parcelas });
        setParcelas(newParcelas);
        // actions.deleteParcelaEmprestimo({
        //   index: indice,
        // });
      }
    },
    [parcelas]
  );

  const createParcel = () => {
    if (Number(valor) !== 0 && vencimento !== '') {
      if (dateValidate(vencimento, parcelas)) {
        const venc = `${convert(vencimento)}T00:00:00`;

        const values = {
          valor,
          vencimento: venc,
          idNumero: parcelas.length + 1,
        };

        const novasParcelas = [...parcelas, values];
        const result = ordenarParcelas(novasParcelas);

        setParcelas(result);

        // actions.addParcelaEmprestimo({ valor, vencimento });
        setValor('');
        setVencimento('');
      } else {
        // eslint-disable-next-line no-console
        console.log(`[DEBUG] validate: Data inválida`);
        setErrorVenc('Data inválida');
      }
    }
  };

  const handleKeyPress = () => {
    setErrorVenc(null);
  };

  useEffect(() => {
    setParcelas(proposta[source].parcelas);
  }, [proposta, source]);

  const totalParcelas = parcelas.reduce((acc, curr) => acc + curr.valor, 0);

  return (
    <ModalCuston
      open={true}
      width="693px"
      title=""
      scrolling="true"
      onClose={() => actions.removeModalActive()}
      header={<Head rules={values.rules} />}
      ppadding="0"
      margin="5vh 0 10vh 0"
    >
      <Container style={{ minHeight: '336px' }}>
        <WrapperFields>
          {values.rules.indexOf('edit') !== -1 && (
            <Wrapper
              padding="1rem 3px"
              pwidth="100%"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <FinInputCurrency
                label="Valor"
                value={valor}
                name="valor-input"
                onChangeEvent={(e, m, f) => setValor(f)}
                largura="45%"
              />
              <SeparatorVertical pwidth="0.4rem" />
              <FinInputDate
                label="Vencimento"
                value={vencimento}
                name="vencimento-input"
                onValueChange={({ formattedValue }) => {
                  setVencimento(formattedValue);
                }}
                largura="45%"
                error={errorVenc}
                onKeyDown={handleKeyPress}
                onBlur={handleKeyPress}
              />
              <SeparatorVertical pwidth="0.4rem" />
              <Wrapper padding="0" pwidth="30px">
                <Button
                  rounded
                  size="30px"
                  largura="total"
                  style={{ padding: 0 }}
                  onClick={() => createParcel()}
                >
                  <IconePlusStyle width={20} height={20} />
                </Button>
              </Wrapper>
            </Wrapper>
          )}

          <Wrapper
            padding="1rem 3px"
            pwidth="100%"
            justifyContent="space-between"
          >
            {parcelas.length > 0 && (
              <Parcelas
                deleteParcela={deleteParcela}
                onChangeVencimento={onChangeVencimento}
                onChangeValor={onChangeValor}
                data={parcelas}
              />
            )}
          </Wrapper>

          <Wrapper
            padding="1rem 3px"
            pwidth="100%"
            justifyContent="space-between"
          >
            <Wrapper
              padding="5px"
              justifyContent="space-between"
              style={{
                borderRadius: '6px',
                padding: '7px 10px',
                // backgroundColor: '#d1e6e8',
                width: '100%',
                justifyContent: 'end',
              }}
            >
              <Title6 style={{ margin: '0 1rem' }}>TOTAL</Title6>
              <Title5
                style={{ fontWeight: 'bold', margin: '0', color: '#000' }}
              >
                {currency(totalParcelas)}
              </Title5>
            </Wrapper>
          </Wrapper>
          {values.rules.indexOf('edit') !== -1 && (
            <>
              <SeparatorHorizontal ppadding="0.2rem 0" />
              <Wrapper padding="0" pwidth="100%" justifyContent="flex-end">
                <Wrapper padding="0" pmargin="0 0.5rem 0 0" pwidth="100px">
                  <Button
                    largura="total"
                    preenchimento="outline"
                    onClick={() => actions.removeModalActive()}
                    rounded
                  >
                    Cancelar
                  </Button>
                </Wrapper>
                <Wrapper padding="0" pwidth="100px">
                  <Button largura="total" onClick={() => onSubmit()} rounded>
                    Salvar
                  </Button>
                </Wrapper>
              </Wrapper>
            </>
          )}
        </WrapperFields>
      </Container>
    </ModalCuston>
  );
};

import React from 'react';

import { ReactComponent as IconX } from 'assets/images/icon-x.svg';
import * as Dialog from './style.nwdialog';

export function Content({ children, ...props }) {
  return (
    <Dialog.Portal>
      <Dialog.Overlay />
      <Dialog.Content {...props}>{children}</Dialog.Content>
    </Dialog.Portal>
  );
}

export function NwDialog({
  title,
  trigger,
  description,
  size = 'medium',
  children,
}) {
  const [open, setOpen] = React.useState(false);
  const onClose = () => setOpen(false);
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
      <Content size={size}>
        {title && <Dialog.Title>{title}</Dialog.Title>}
        {description && <Dialog.Description>{description}</Dialog.Description>}

        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { open, setOpen, onClose });
          }
          return child;
        })}

        <Dialog.Close asChild>
          <Dialog.IconButton aria-label="Close">
            <IconX />
          </Dialog.IconButton>
        </Dialog.Close>
      </Content>
    </Dialog.Root>
  );
}

import styled from 'styled-components/macro';
import Button from 'components/FinButton';
import { darken } from 'polished';

const Custon = styled(Button)`
  font-family: 'Inter', sans-serif;
  border-radius: 10px;
  height: 40px;
  font-size: 0.9rem;
  font-weight: normal;
`;

export const Default = styled(Custon)`
  background-color: var(--fingray-300);
  color: var(--fingray-900);
  &:enabled:hover {
    background-color: ${darken(0.1, '#f2f2f3')};
  }
`;

export const Primary = styled(Custon)`
  background-color: var(--finblue-primary);
  color: #ffffff;
  &:enabled:hover {
    background-color: ${darken(0.1, '#008894')};
  }
`;

export const Error = styled(Custon)`
  background-color: var(--finred100);
  color: var(--finerror);
  /* padding: 0;
  width: 100%; */
  &:enabled:hover {
    background-color: ${darken(0.04, '#FFEEEE')};
  }
  &:focus {
    box-shadow: 0 0 0 0.2em rgb(148 0 0 / 20%);
  }
  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) =>
      theme.newtheme.buttons.disabled.background};
    color: #ffffff;
    & > svg {
      fill: #ffffff;
      & > path {
        fill: #ffffff;
      }
    }
  }
`;

export const ErrorHeavy = styled(Custon)`
  background-color: var(--finred-500);
  color: #ffffff;
  &:enabled:hover {
    background-color: ${darken(0.04, '#f54a36')};
  }
  &:focus {
    box-shadow: 0 0 0 0.2em rgb(148 0 0 / 20%);
  }
`;

export const Secondary = styled(Custon)`
  background-color: var(--finblue-secondary);
  color: var(--finblue-primary);

  &:enabled:hover {
    background-color: ${darken(0.1, '#D6FBFF')};
  }
`;

export const Warning = styled(Custon)`
  background-color: var(--finyellow-500);
  color: #ffffff;

  &:enabled:hover {
    background-color: ${darken(0.1, 'hsl(37, 100%, 56%)')};
  }
`;

import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { finTheme } from 'finTheme';
import { Header, Footer } from 'components/FinLayout';
import SideBarMenu from 'components/FinSideBarMenu';

import { AuthContext } from 'providers/ProviderAuth';

const Navigation = ({ header, sidebar, footer, history }) => {
  const [openMenu, setOpenMenu] = useState(false);
  document.body.style.overflow = openMenu && 'hidden';

  const user = useContext(AuthContext);

  return (
    <>
      {header && (
        <Header
          bgcolor={finTheme.newtheme.color.branco}
          history={history}
          openMenu={() => setOpenMenu(!openMenu)}
          name={user && user.empresa}
          usuario={user}
        />
      )}
      {sidebar && (
        <SideBarMenu open={openMenu} closeMenu={() => setOpenMenu(false)} />
      )}
      {footer && <Footer />}
    </>
  );
};

Navigation.propTypes = {
  footer: PropTypes.bool,
  header: PropTypes.bool,
  sidebar: PropTypes.bool,
};

Navigation.defaultProps = {
  footer: false,
  header: true,
  sidebar: true,
};

export default Navigation;

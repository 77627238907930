import React from 'react';
import AbleStep from 'assets/icones/icone-able-step.svg';
import PendingStep from 'assets/icones/icone-pending-step.svg';

import { Step } from './Step';

export const HabilitacaoPendente = () => {
  return (
    <Step
      bgcolor="#FCE9C7"
      icon={AbleStep}
      statuicon={PendingStep}
      label="Habilitação"
      labelsecundaria="Pendente"
    />
  );
};

import styled from 'styled-components/macro';

import { ReactComponent as Group } from '../../assets/icones/icone-grupo.svg';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 208px;
  height: 108px;
  font-size: 12px;
  background-color: ${({ backGroundColor }) =>
    backGroundColor ? `${backGroundColor}` : `#fff`};
  border-radius: 16px;
  padding: 11px 14px 17px 21px;
  line-height: 1;
  margin-right: 17px;
`;

export const PriTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 50%;
`;

export const PriValue = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

export const PriLevel = styled.div`
  font-weight: 600;
  line-height: 1.5;
  color: ${({ priLvlColor }) => (priLvlColor ? `${priLvlColor}` : `#000`)};
`;

export const PriBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
`;

export const TopCont = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  & > span {
    font-weight: 600;
  }
`;

export const GroupIcon = styled(Group)`
  width: 18px;
  height: 26px;

  & > path {
    fill: ${({ iconcolor }) => (iconcolor ? `${iconcolor}` : `#000`)};
  }
`;

// ^ tem q ver no finsystem como colorir o ícone!!!!!

export const BottomCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
`;

export const FilledGraphic = styled.div`
  display: flex;
  flex-direction: row;
  width: ${({ filledPercent, maxValue = 100 }) =>
    filledPercent ? `calc(calc(${filledPercent / maxValue})*100%)` : `${0}%`};
  height: 100%;
  background-color: ${({ fill }) => (fill ? `${fill}` : `#000`)};
  border-radius: 14px;
`;

export const TotalGraphic = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 6px;
  background-color: ${({ backGraphColor }) =>
    backGraphColor ? `${backGraphColor}` : `#fff`};
  border-radius: 14px;
`;

/* eslint-disable import/no-duplicates */
/* eslint-disable no-console */
import axios from 'axios';
import { put, call } from 'redux-saga/effects';

import { getUserData } from 'services/getUserData';

import {
  loadPerfilSucess,
  loadPerfilFailure,
  clearPerfil,
  showNotification,
  contentNotification,
  postPerfilSucess,
  postPerfilFailure,
  loadCampanhaSucess,
  loadCampanhaFailure,
  postCampanhaFailure,
  getTermosSucess,
  getTermosFailure,
  setTermosSucess,
  setTermosFailure,
  // eslint-disable-next-line import/no-duplicates
} from 'store/old_actions/actions';

// import { clearCampanha } from 'store/old_actions/actions';
import { postCampanhaSucess } from 'store/old_actions/actions';
import config from '../../config';

/** ***************************** List  ******************************** */
export function* listPerfil() {
  try {
    const user = yield call(getUserData);

    yield put(clearPerfil());

    // const reqData = {
    //   params: {
    //     idConvenio: user.idEmpresa,
    //     idPessoaConvenio: user.idUsuario,
    //   },
    // };

    // console.log('Request: ' + JSON.stringify(reqData));

    // Request data
    const res = yield axios.get(
      `${config.API_REMODELING_URL}/user-api/api/convenio/perfil/${user.idEmpresa}/usuario/${user.idUsuario}`
    );

    // console.log(res.data);
    // console.log('Response: ' + JSON.stringify(res.data));

    yield put(loadPerfilSucess(res.data.data));
  } catch (err) {
    // console.log(err);
    yield put(loadPerfilFailure(err.response));
    yield put(contentNotification(`Mensagem: ${err.message}`, 2000));
    yield put(showNotification());
  }
}

/** ***************************** Post  ******************************** */
export function* postPerfil(action) {
  try {
    const user = yield call(getUserData);

    const reqData = {
      idConvenio: user.idEmpresa,
      idPessoaConvenio: user.idUsuario,
      ...action.form,
    };

    // console.log('Request: ' + JSON.stringify(reqData));

    // Request data
    const res = yield axios.put(
      `${config.API_REMODELING_URL}/user-api/api/convenio/perfil`,
      reqData
    );

    // console.log(res.data);
    // console.log('Response: ' + JSON.stringify(res.data));

    yield put(postPerfilSucess(res.data));

    // Load list
    // const reqData2 = {
    //   params: {
    //     idConvenio: user.idEmpresa,
    //     idPessoaConvenio: user.idUsuario,
    //   },
    // };

    // console.log('Request: ' + JSON.stringify(reqData2));

    // Request data
    const res2 = yield axios.get(
      `${config.API_REMODELING_URL}/user-api/api/convenio/perfil/${user.idEmpresa}/usuario/${user.idUsuario}`
    );

    // console.log(res2.data);
    // console.log('Response: ' + JSON.stringify(res2.data));

    yield put(loadPerfilSucess(res2.data.data));

    if (res.data) {
      yield put(
        contentNotification('Mensagem: Perfil atualizado com sucesso!', 2000)
      );
      yield put(showNotification());
    }
  } catch (err) {
    // console.log(err);
    yield put(postPerfilFailure(err.response));
    yield put(contentNotification(`Mensagem: ${err.message}`, 2000));
    yield put(showNotification());
  }
}

/** ***************************** Campanha  ******************************** */
export function* listCampanha() {
  try {
    const user = yield call(getUserData);

    // yield put(clearCampanha());

    const reqData = {
      params: {
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
      },
    };

    // console.log('Request: ' + JSON.stringify(reqData));

    // Request data
    const res = yield axios.get(
      `${config.API_BASE_URL}/api/campanha/convenio`,
      reqData
    );

    // const res = yield axios.get('http://localhost:4000/campanha', reqData);

    // console.log(res.data);
    // console.log('Response: ' + JSON.stringify(res.data));

    yield put(loadCampanhaSucess([res.data]));
  } catch (err) {
    // console.log(err);
    yield put(loadCampanhaFailure(err.response));
    yield put(contentNotification(`Mensagem: ${err.message}`, 2000));
    yield put(showNotification());
  }
}

export function* postCampanha(axios, action) {
  try {
    const user = yield call(getUserData);

    // yield put(clearCampanha());

    const reqData = {
      params: {
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
        codigo: action.code,
      },
    };

    // console.log('Request: ' + JSON.stringify(reqData));

    // Request data
    const res = yield axios.post(
      `${config.API_BASE_URL}/api/campanha/ativa`,
      null,
      reqData
    );

    /* const res = yield axios.post(
      'http://localhost:4000/campanha',
      null,
      reqData
    ); */

    // console.log(res.data);
    // console.log('Response: ' + JSON.stringify(res.data));

    const reqData2 = {
      params: {
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
      },
    };

    const res2 = yield axios.get(
      `${config.API_BASE_URL}/api/campanha/convenio`,
      reqData2
    );
    yield put(loadCampanhaSucess([res2.data]));

    yield put(postCampanhaSucess(res.data));
  } catch (err) {
    // console.log(err);
    yield put(postCampanhaFailure(err.response));
    yield put(contentNotification(`Mensagem: ${err.message}`, 2000));
    yield put(showNotification());
  }
}

/** ***************************** Termos  ******************************** */
export function* getTermos() {
  try {
    const user = yield call(getUserData);

    const res = yield axios.get(
      `${config.API_REMODELING_URL}/user-api/api/termo/${user.idUsuario}`
    );

    // console.log(res.data);

    yield put(getTermosSucess(res.data.data));
  } catch (err) {
    // console.log(err);
    yield put(getTermosFailure(err.response));
    yield put(contentNotification(`Mensagem: ${err.message}`, 2000));
    yield put(showNotification());
  }
}

/** ***************************** Post Termos  ******************************** */
export function* postTermos(action) {
  try {
    const user = yield call(getUserData);

    const reqData = {
      idUsuario: user.idUsuario,
      idTermo: action.idTermo,
    };

    const res = yield axios.post(
      `${config.API_REMODELING_URL}/user-api/api/termo`,

      reqData
    );

    // console.log(res.data);

    yield put(setTermosSucess(res.data.data));
    yield put(contentNotification('Mensagem: Termos aceito com sucesso', 2000));
    yield put(showNotification());
  } catch (err) {
    // console.log(err);
    yield put(setTermosFailure(err.response));
    yield put(contentNotification(`Mensagem: ${err.message}`, 2000));
    yield put(showNotification());
  }
}

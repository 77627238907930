import React from 'react';
import { ReactComponent as ImageNotFound } from 'assets/images/undraw_page_not_found_re_e9o6.svg';
import { Title, Text, WrapperImage, Container } from './style.placeholder';

export function NWN404() {
  return (
    <Container>
      <WrapperImage>
        <ImageNotFound />
      </WrapperImage>
      <Title>Página não encontrada</Title>
      <Text>Desculpe, mas a página que você está procurando não existe</Text>
    </Container>
  );
}

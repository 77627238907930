import React from 'react';
// import { useSelector } from 'react-redux';

// import { finTheme } from 'finTheme';
// import { Wrapper } from 'components/FinWrappers';

import { icons } from 'assets/icones/finplaceIcons';
import { StringToSvg } from 'helpers/svgFiles/StringToSvg';
import { CustomWrapper, RespostaMatch } from './Styles';

export const RatingCedente = ({ dados }) => {
  // const apiCbboxState = useSelector((state) => state.apiCbbox.data[0]);

  // const valorRating = {
  //   A: '#E9F8D9',
  //   B: '#333333',
  //   C: '#333333',
  //   D: '#333333',
  //   E: '#333333',
  //   Ø: '#E9F8D9',
  // };
  let icone;
  const valorRating = (valor) => {
    if (valor >= 70) {
      icone = icons.Match;
      return 'Match';
    }
    if (valor >= 50 && valor < 70) {
      icone = icons.Analisar;
      return 'Analisar';
    }
    if (valor < 50 && valor >= 0) {
      icone = icons.NoMatch;
      return 'NoMatch';
    }
    // if (valor === 0) {
    //   icone = icons.NoMatchNoResults;
    //   return 'SemValor';
    // }
    return '';
  };
  return (
    <>
      <CustomWrapper>
        <RespostaMatch className={valorRating(dados)}>
          <StringToSvg
            viewBox="0 0 33 32"
            width="28px"
            height="28px"
            strokeWidth="0"
          >
            {icone}
          </StringToSvg>
        </RespostaMatch>
      </CustomWrapper>
    </>
  );
};

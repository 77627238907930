import React from 'react';

import {
  PaginationButton,
  ArrowTitle,
  Arrow,
  PaginationLinkNext,
} from './styles';

export const NextButton = ({ onclick, sum, visibility }) => (
  <PaginationButton visibility={visibility}>
    <PaginationLinkNext onClick={(e) => onclick(e, sum)}>
      <ArrowTitle>Próxima</ArrowTitle>
      <Arrow />
    </PaginationLinkNext>
  </PaginationButton>
);

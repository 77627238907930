import React, { useCallback } from 'react';

import { useSelector } from 'react-redux';
import { Creators as searchRouterActions } from 'store/ducks/searchRouter';
import { useActions } from 'hooks/useActions';

import { Tabs, ItemTab, Item } from 'components/FinTabs';
import { StringToSvg } from 'helpers/svgFiles/StringToSvg';
import { finTheme } from 'finTheme';
import Counter from 'components/FinCounter';
import { icons } from 'assets/icones/finplaceIcons';
import feather from 'feather-icons/dist/icons.json';
import { Wrapper } from 'components/FinWrappers';
import { useFeedback } from 'hooks/useFeedback';

const NavTabs = () => {
  const query = useSelector((state) => state.router.location.query);
  const uiPaginationState = useSelector((state) => state.uiPagination);
  const apiListFinanciadorState = useSelector((state) => state.apiListClientes);

  const { ready } = useFeedback(uiPaginationState);

  const search = useActions(searchRouterActions);
  const searchAction = useCallback(
    (value) => {
      search.addSearchRoute({
        aba: value,
        page: '1',
        qtd: '10',
        order: 'a-z',
        busca: '',
      });
    },
    [search]
  );
  return (
    <Tabs>
      <ItemTab>
        <Item
          onClick={() => searchAction('todos')}
          isActive={query.aba === 'todos'}
        >
          <Wrapper
            pwidth="21px"
            pheight="21px"
            padding="0"
            pmargin="0 0.6rem 0 0"
          >
            <StringToSvg
              viewBox="0 0 24 24"
              width="100%"
              height="100%"
              strokeWidth={'1'}
              stroke={finTheme.newtheme.color.cinza4}
            >
              {icons.searchCircle}
            </StringToSvg>
          </Wrapper>
          Empreendedores
          {query.aba === 'todos' &&
            ready &&
            apiListFinanciadorState.data.length > 0 && (
              <Counter pbgcolor="white">
                {uiPaginationState.data[0].totalRecords}
              </Counter>
            )}
        </Item>
      </ItemTab>
      <ItemTab>
        <Item
          onClick={() => searchAction('conexoes')}
          isActive={query.aba === 'conexoes'}
        >
          <Wrapper
            pwidth="23px"
            pheight="21px"
            padding="0"
            pmargin="0 0.6rem 0 0"
          >
            <StringToSvg
              viewBox="0 0 24 24"
              width="100%"
              height="100%"
              strokeWidth={'1'}
              stroke={finTheme.newtheme.color.cinza4}
            >
              {icons.multipleUser}
            </StringToSvg>
          </Wrapper>
          Minhas Conexões
          {query.aba === 'conexoes' &&
            ready &&
            apiListFinanciadorState.data.length > 0 && (
              <Counter pbgcolor="white">
                {uiPaginationState.data[0].totalRecords}
              </Counter>
            )}
        </Item>
      </ItemTab>
      <ItemTab>
        <Item
          onClick={() => searchAction('bloqueado')}
          isActive={query.aba === 'bloqueado'}
        >
          <Wrapper
            pwidth="23px"
            pheight="23px"
            padding="0"
            pmargin="0 0.6rem 0 0"
          >
            <StringToSvg
              viewBox="0 0 26 26"
              width="100%"
              height="100%"
              strokeWidth={'2'}
              stroke={finTheme.newtheme.color.cinza4}
            >
              {feather.slash}
            </StringToSvg>
          </Wrapper>
          Bloqueados
          {query.aba === 'bloqueado' &&
            ready &&
            apiListFinanciadorState.data.length > 0 && (
              <Counter pbgcolor="white">
                {uiPaginationState.data[0].totalRecords}
              </Counter>
            )}
        </Item>
      </ItemTab>
    </Tabs>
  );
};

export default NavTabs;

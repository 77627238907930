/* eslint-disable no-unused-vars */
import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';

import { OperacoesContext } from '../provider';

const novaProposta = async (params) => {
  return params;
};

export const useMutateNovaProposta = () => {
  const queryClient = useQueryClient();
  const [, actions] = useContext(OperacoesContext);

  return useMutation((params) => novaProposta(params), {
    onMutate: async (params) => {
      await queryClient.cancelQueries(['propostas']);

      const queryKey = [
        'propostas',
        'lista',
        { idOperacao: params.idOperacao },
      ];
      const previousData = queryClient.getQueryData([...queryKey]);

      const newProposalCount = previousData.data.filter(
        (item) => item.nomeOrigem === 'Convenio'
      ).length;

      const currentProposal = previousData.data
        .filter((item) => item.idProposta === params.idProposta)
        .map((item) => ({
          ...item,
          observacao: '',
          idPropostaOriginal: item.idProposta,
          idProposta: 9999,
          nomeOrigem: 'Convenio',
          titulo: `Proposta Financiador: (${newProposalCount + 1})`,
          statusProposta: {
            descricao: 'Nova Proposta',
            idPropostaStatus: 12,
            nome: 'novaProposta',
          },
        }));

      const newData = [...previousData.data, ...currentProposal];

      const [dataCurrentProposal] = currentProposal;

      actions.setNovaProposta({ value: { ...dataCurrentProposal } });

      // queryClient.setQueryData([...queryKey], {
      //   ...previousData,
      //   data: newData,
      // });

      return { previousData, currentProposal, params, queryKey };
    },
    onError: (err, previousData, context, queryKey) => {
      queryClient.setQueryData([...context.queryKey], context.previousData);
    },
    onSuccess: (data, variables, context) => {
      // queryClient.invalidateQueries('propostas');
    },
  });
};

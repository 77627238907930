/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { createActions, createReducer } from 'reduxsauce';
import { call, put, take, fork, cancel, cancelled } from 'redux-saga/effects';
import axios from 'axios';

import { getUserData } from 'services/getUserData';
import { Creators as uiToasterActions } from 'store/ducks/uiToaster';

import CONFIG from '../../config';

const { CancelToken } = axios;

/**
 * Criando action types & creators
 */

export const { Types, Creators } = createActions({
  apiInformacoesRequest: ['idCedente', 'reloadBkg'],
  apiInformacoesSuccess: ['data'],
  apiInformacoesError: [],
  apiInformacoesCancel: [],
});

/**
 * Criando os reducer handlers
 */

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: false,
};

const apiListRequest = (state = INITIAL_STATE, action) => {
  console.log(action);

  if (action.reloadBkg) {
    return {
      ...state,
      isLoading: false,
    };
  }

  return {
    isLoading: true,
    data: [],
    error: false,
  };
};

const apiListSuccess = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: [action.data],
    error: false,
  };
};

const apiListError = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: [],
    error: true,
  };
};

const apiListCancel = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: [],
    error: false,
  };
};

/**
 * Criando reducer
 */

export default createReducer(INITIAL_STATE, {
  [Types.API_INFORMACOES_REQUEST]: apiListRequest,
  [Types.API_INFORMACOES_SUCCESS]: apiListSuccess,
  [Types.API_INFORMACOES_ERROR]: apiListError,
  [Types.API_INFORMACOES_CANCEL]: apiListCancel,
});

/**
 * Sagas
 */

function* sagaGetAsynResult(params, endpoint) {
  const source = CancelToken.source();
  const url = `${CONFIG.API_BASE_URL}${endpoint}`;
  try {
    const response = yield axios.post(url, null, params, {
      cancelToken: source.token,
    });
    yield put(Creators.apiInformacoesSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(uiToasterActions.uiToasterMessage(error, 'error'));
    yield put(Creators.apiInformacoesError());
  } finally {
    if (yield cancelled()) {
      console.log('cancel apiInformacoes');
      source.cancel();
    }
  }
}

export function* sagaInformacoes(action) {
  const user = yield call(getUserData);

  console.log(action.idCedente);

  if (user) {
    const params = {
      params: {
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
        idCedente: action.idCedente.split('-')[0],
      },
    };

    const task = yield fork(
      sagaGetAsynResult,
      params,
      '/api/cedente/informacoes'
    );

    yield take(Types.API_INFORMACOES_CANCEL);
    yield cancel(task);
  } else {
    yield put(Creators.apiInformacoesError());
  }
}

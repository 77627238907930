import React from 'react';
import { Title6 } from 'components/FinHeadings';
import { Wrapper } from 'components/FinWrappers';
import { ReactComponent as IcoTropy } from 'assets/images/icon-trophy.svg';
import { ReactComponent as IcoCash } from 'assets/images/icon-cash.svg';
import { ReactComponent as IcoHandDollar } from 'assets/images/icon-dollar.svg';

import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';

import { Caption } from 'components/FinParagraph';
import { currency, dateTime } from 'components/Formatter';
import { truncate } from 'helpers/string';
import { PropostaButtons } from './Status/PropostaButtons';

import {
  CardStyle,
  CardStyleHead,
  CardStyleBody,
  CardStyleFooter,
  IconMoneyStyle,
  WrapperStatus,
  StatusCircle,
} from './style';

import { STATUS_PAGAMENTO, STATUS_PAY_PROPOSTAS } from '../config';
import { Circle } from '../Ofertas/ExibePreco/style';
import { StatusButton } from '../Ofertas/Card/StatusButton';
import { Status } from './Status';

import { useFetchSelecionaConvenio } from '../queries/useFetchSelecionaConvenio';
import { CardSkeleton } from './CardSkeleton';

const STEPS = {
  victory: ['isActive', 'isDisabled', 'isDisabled'],
  youConfirmed: ['isVisited', 'isActive', 'isDisabled'],
  heConfirmed: ['isVisited', 'isVisited', 'isVisited'],
};

export const MainPaymentContent = ({ data }) => {
  const { data: dados, isLoading } = useFetchSelecionaConvenio({
    idOperacao: data.idOperacao,
  });

  const isExistData = !isLoading && !!dados && !!dados.data;

  const status = isExistData
    ? STATUS_PAGAMENTO.find(
        (item) => item.value.indexOf(dados.data.statusPagamento.id) !== -1
      ).name
    : 'victory';

  const firstStage = STEPS[status][0];
  const secondStage = STEPS[status][1];
  const thirdStage = STEPS[status][2];

  const styleCustom = {
    border: `1px solid rgb(33 206 221)`,
    backgroundColor: '#e7f9fb',
  };

  const currentStatus =
    isExistData &&
    STATUS_PAY_PROPOSTAS.find(
      (item) => item.id.indexOf(dados.data.statusPagamento.id) !== -1
    );

  return isLoading ? (
    <CardSkeleton />
  ) : (
    isExistData && (
      <CardStyle style={styleCustom}>
        <CardStyleHead>
          <Wrapper
            padding="0 0 4px 0"
            pwidth="100%"
            justifyContent="flex-start"
          >
            <Circle largura="50px" altura="50px">
              <IconMoneyStyle />
            </Circle>
            <Title6 style={{ margin: '0 0 0 0.4rem', fontWeight: 'bold' }}>
              Confirmação de Pagamento
            </Title6>
          </Wrapper>
        </CardStyleHead>
        <CardStyleBody>
          <Wrapper
            alignItems="flex-start"
            style={{
              backgroundColor: '#fff',
              borderRadius: '7px',
              padding: '0.7rem 1.2rem 0.7rem 0',
            }}
          >
            <Wrapper tipo="column" padding="0 10px 0 0" alignItems="flex-start">
              <Caption style={{ fontWeight: 'bold', lineHeight: '24px' }}>
                Conta para pagamento
              </Caption>
              <Caption style={{ lineHeight: '14px' }}>
                {truncate(dados.data.contaBancaria.banco, 20)}
              </Caption>
              <Caption
                style={{ lineHeight: '17px' }}
              >{`Ag.: ${dados.data.contaBancaria.agencia}`}</Caption>
              <Caption style={{ lineHeight: '17px' }}>
                {`C/C: ${dados.data.contaBancaria.conta}`}
                {dados.data.contaBancaria.digito &&
                  `-${dados.data.contaBancaria.digito}`}
              </Caption>
            </Wrapper>

            <Wrapper tipo="column" padding="0 0 0 10px" alignItems="flex-start">
              <Caption style={{ fontWeight: 'bold', lineHeight: '24px' }}>
                Líquido Acordado
              </Caption>
              <Caption style={{ lineHeight: '14px' }}>
                {currency(dados.data.liquidoAcordado)}
              </Caption>
            </Wrapper>
          </Wrapper>

          <WrapperStatus>
            <StatusButton status={firstStage} onClick={() => {}}>
              <IcoTropy />
            </StatusButton>
            <StatusButton status={secondStage} onClick={() => {}}>
              <IcoCash />
            </StatusButton>
            <StatusButton status={thirdStage} onClick={() => {}} borderHidden>
              <IcoHandDollar />
            </StatusButton>
          </WrapperStatus>

          <Wrapper padding="0" justifyContent="center" tipo="column">
            {firstStage !== 'isDisabled' && (
              <Wrapper padding="0 0 8px 0" justifyContent="flex-start">
                <StatusCircle
                  bgColor={firstStage === 'isActive' && '#70e5ef'}
                />
                <Caption style={{ width: '260px', lineHeight: '16px' }}>
                  Parabéns! Realizamos mais uma boa negociação juntos!
                </Caption>
              </Wrapper>
            )}

            {secondStage !== 'isDisabled' &&
              isValid(parseISO(dados.data.dtConfPagamento)) && (
                <Wrapper padding="0 0 8px 0" justifyContent="flex-start">
                  <StatusCircle
                    bgColor={secondStage === 'isActive' && '#70e5ef'}
                  />
                  <Caption style={{ width: '260px', lineHeight: '16px' }}>
                    {`Você confirmou as ${dateTime(
                      dados.data.dtConfPagamento
                    )}`}
                  </Caption>
                </Wrapper>
              )}

            {thirdStage !== 'isDisabled' &&
              isValid(parseISO(dados.data.dtConfRecebimento)) && (
                <Wrapper padding="0 0 8px 0" justifyContent="flex-start">
                  <StatusCircle
                    bgColor={thirdStage === 'isActive' && '#70e5ef'}
                  />
                  <Caption style={{ width: '260px', lineHeight: '16px' }}>
                    {`Empreendedor confirmou as ${dateTime(
                      dados.data.dtConfRecebimento
                    )}`}
                  </Caption>
                </Wrapper>
              )}
          </Wrapper>
        </CardStyleBody>
        <CardStyleFooter>
          <PropostaButtons tipo={currentStatus.name} data={data} />
          <Status
            excludeStatus={['aConfirmarPagamento']}
            tipo={currentStatus.name}
          />
        </CardStyleFooter>
      </CardStyle>
    )
  );
};

import styled, { css } from 'styled-components/macro';

export const Box = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 999;
  background-color: #ffffff;
  min-height: 300px;
  max-height: 600px;
  padding: 2rem;
  border-radius: 18px;
  box-shadow: 0px 3px 7px -1px rgba(0, 0, 0, 0.15);
  border: 1px solid #e7e7e7;
  overflow-y: scroll;

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    margin: 60px 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RegionInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
`;

export const RegionAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
`;

export const TitleBar = styled.span`
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  letter-spacing: -0.05em;
  color: var(--fingray-900);
  font-size: 1.3rem;
  position: relative;
  width: 100%;
`;

export const List = styled.ul`
  display: grid;
  gap: 1.2rem;
  margin: 1.2rem 0;
  list-style-type: none;
`;

export const ItemList = styled.li`
  display: flex;
`;

export const Shadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  display: none;
  transition: opacity 2s ease-in;
  ${({ show }) =>
    show &&
    css`
      display: block;
      opacity: 1;
    `}
`;

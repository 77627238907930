import styled, { keyframes, createGlobalStyle } from 'styled-components/macro';
import media from 'styled-media-query';

import { Link } from 'react-router-dom';

import { darken } from 'polished';
import { Theme } from '../../theme';

import icoXcircle from '../../images/feather/x-circle.svg';
import icoDownload from '../../images/feather/download.svg';
import icoClock from '../../images/feather/clock.svg';
import icoUser from '../../images/feather/user.svg';
import icoChevronRight from '../../images/feather/chevron-right.svg';
import icoPlusCircle from '../../images/feather/plus-circle.svg';
import icoCheckCircle from '../../images/feather/check-circle.svg';
import icoBank from '../../images/bank.svg';
import icoSlash from '../../images/feather/slash.svg';
import icoAward from '../../images/award2.svg';
import icoWait from '../../images/wait.svg';
import icoSend from '../../images/feather/send.svg';
import icoFiletext from '../../images/feather/file-text.svg';
import icoRotateCCW from '../../images/feather/rotate-ccw.svg';
import icoTrash2 from '../../images/feather/trash-2.svg';
import icoBell from '../../images/feather/bell.svg';
import icoHandShake from '../../images/feather/hand-shake.svg';
import icoDollarSign from '../../images/feather/dollar-sign.svg';
import icoAuction from '../../images/feather/auction.svg';
import icoCedente from '../../images/feather/cedente.svg';
import icoEdit3 from '../../images/feather/edit-3.svg';
import icoStopwatch from '../../images/stopwatch.svg';
import icoNegotiate from '../../images/negotiate.svg';
import icoWallet from '../../images/wallet-w.svg';
import icoMoney from '../../images/money-w.svg';
// eslint-disable-next-line import/no-duplicates
import icoAlertCircle from '../../images/feather/alert-circle.svg';
import icoThumbsdown from '../../images/feather/thumbs-down.svg';
import icoFrown from '../../images/feather/frown.svg';
import icoSpeechBubbles from '../../images/speech-bubbles.svg';
import icoMic from '../../images/feather/mic.svg';
import icoArchive from '../../images/feather/archive.svg';
import IcoChevronDown from '../../images/feather/chevron-down.svg';
import IcoChevronUp from '../../images/feather/chevron-up.svg';
import IcoArrowDown from '../../images/feather/arrow-down.svg';
import IcoArrowUp from '../../images/feather/arrow-up.svg';
import IcoMinus from '../../images/feather/minus.svg';
import IcoPlus from '../../images/feather/plus.svg';
import IcoPlay from '../../images/feather/play.svg';
import IcoEye from '../../images/feather/eye.svg';
// eslint-disable-next-line import/no-duplicates
import IcoAlert from '../../images/feather/alert-circle.svg';
import IcoCheck from '../../images/feather/check.svg';
import IcoSearch from '../../images/feather/search.svg';
import IcoX from '../../images/feather/x.svg';
import IcoList from '../../images/feather/list.svg';
import IcoInfo from '../../images/feather/info.svg';

import LogoGraph from '../../images/logo-graph.svg';
import LogoText from '../../images/logo-text.svg';

import LogoGrafw from '../../images/logo-graf-w.svg';
import LogoBrandw from '../../images/logo-brand-w.svg';

export const GlobalStyle = createGlobalStyle`
body{
  overflow: ${(props) => (props.noscroll ? props.noscroll : 'initial')};
  line-height: 1.6;
}`;

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: ${({ paddingVal }) => paddingVal || '1rem'};
  border-radius: 0.3rem;
  border-left: ${(props) =>
    props.borderLeft ? `0.3rem solid ${props.borderLeft}` : `none`};
  border-right: ${(props) =>
    props.borderRight ? `0.3rem solid ${props.borderRight}` : `none`};
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: ${(props) => (props.mbottom ? props.mbottom : '1rem')};
  box-shadow: 0 0px 0px rgba(10, 10, 10, 0.3), 0 0 0 1px rgba(10, 10, 10, 0.1);
  height: ${(props) => (props.alheigth ? props.alheigth : 'auto')};
  ${media.lessThan('medium')`
    /* screen width is less than 768px (medium) */
    margin: 0 1rem;
    height: 45vh;
    flex-direction: column;
    flex-wrap: inherit;
    margin-bottom: 1.5rem;
  `};
`;

export const BarCharge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
`;

export const ChargeItem = styled.div`
  height: 0.3rem;
  flex-grow: 1;
  background-color: ${(props) => props.color};
`;

export const Title = styled.h3`
  color: ${({ colorText }) => colorText || Theme.gray5};
  font-weight: ${({ bolder }) => bolder || '600'};
  margin-left: ${(props) => (props.mgleft ? props.mgleft : 0)};
  margin-right: ${(props) => (props.mgright ? props.mgright : 0)};
  width: ${(props) => (props.lwidth ? props.lwidth : '137px')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${media.lessThan('medium')`
    /* screen width is less than 768px (medium) */
    flex-basis: 100%;
    margin:0;
    font-size: 1.5rem;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  line-height: 1.7rem;
  width: ${(props) => props.lwidth && props.lwidth};
  margin-right: ${(props) => (props.mRight ? props.mRight : 0)};
  margin-left: ${(props) => (props.mLeft ? props.mLeft : 0)};
  height: ${(props) => (props.aheight ? props.aheight : '100%')};
  justify-content: ${(props) => (props.valign ? props.valign : 'center')};
  ${(props) =>
    props.responsive === 'mobile' &&
    media.lessThan('medium')`
    /* screen width is less than 768px (medium) */
    flex-basis: 100%;
    margin-right: 0;
    border: 1px solid red;
  `}
`;

export const WrapperRow = styled.div`
  display: flex;
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  justify-content: ${(props) =>
    props.justifiy ? props.justifiy : 'flex-start'};
  width: ${(props) => (props.lwidth ? props.lwidth : 'auto')};
  margin-bottom: ${(props) => (props.mBotton ? props.mBotton : 0)};
`;

export const Label = styled.span`
  color: ${(props) => (props.color ? props.color : Theme.gray3)};
  font-size: ${(props) => (props.fsize ? props.fsize : '1rem')};
  text-align: ${(props) => (props.alignText ? props.alignText : 'left')};
`;

export const LabelText = styled.span`
  color: ${(props) => (props.color ? props.color : Theme.gray3)};
  font-size: ${(props) => (props.fsize ? props.fsize : '1rem')};
  text-align: ${(props) => (props.alignText ? props.alignText : 'left')};
  width: ${(props) => (props.lwidth ? props.lwidth : '137px')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${media.lessThan('medium')`
    /* screen width is less than 768px (medium) */
    flex-basis: 100%;
    margin:0;
    font-size: 1.5rem;
  `}
`;

export const TextValue = styled.span`
  color: ${(props) => (props.color ? props.color : Theme.gray5)};
  font-weight: ${(props) => (props.bolder ? props.bolder : '600')};
  margin-left: ${(props) => (props.mleft ? props.mleft : 0)};
  font-size: ${(props) => (props.size ? props.size : '1rem')};
  text-decoration: ${(props) => props.decoration && props.decoration};
  width: ${(props) => (props.lWidth ? props.lWidth : 'auto')};
  text-align: ${(props) => (props.hAlign ? props.hAlign : 'center')};
`;

export const TextValueLink = styled.span`
  color: ${(props) => (props.color ? props.color : Theme.gray5)};
  font-weight: 600;
  margin-left: ${(props) => (props.mleft ? props.mleft : 0)};
  font-size: ${(props) => (props.size ? props.size : '1rem')};
  text-decoration: ${(props) => props.decoration && props.decoration};
  width: ${(props) => (props.lWidth ? props.lWidth : 'auto')};
  text-align: ${(props) => (props.hAlign ? props.hAlign : 'center')};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const TextValLimit = styled.span`
  color: ${(props) => (props.color ? props.color : Theme.gray5)};
  font-weight: 600;
  font-size: ${(props) => (props.size ? props.size : '1rem')};
  width: ${(props) => (props.lwidth ? props.lwidth : '310px')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${media.lessThan('medium')`
    /* screen width is less than 768px (medium) */
    flex-basis: 100%;
    margin:0;
    font-size: 1.5rem;
  `}
`;

export const Tag = styled.span`
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : Theme.gray7)};
  font-size: ${(props) => (props.size ? props.size : '0.6rem')};
  text-align: center;
  text-transform: uppercase;
  border-radius: 0.1rem;
  height: ${(props) => (props.alheight ? props.alheight : '1.3rem')};
  line-height: ${(props) => (props.lineheigt ? props.lineheigt : '1.4rem')};
  padding: 0 1rem;
  display: flex;
  align-items: center;
  color: ${(props) => (props.color ? props.color : Theme.gray4)};
  cursor: ${(props) => (props.cPointer ? props.cPointer : 'initial')};
  width: fit-content;
  min-width: 80px;
  justify-content: center;
  ${(props) =>
    props.hover &&
    `&:hover {
      background-color: ${(props) =>
        props.bgcolor ? darken(0.2, props.bgcolor) : darken(0.2, Theme.gray7)};
    }`}
`;

export const CharTxt = styled.span`
  font-size: ${(props) => (props.size ? props.size : '1rem')};
  text-transform: uppercase;
`;

export const SeparatorVertical = styled.div`
  width: ${(props) => (props.wsize ? props.wsize : '2rem')};
  display: flex;
  justify-content: center;
  height: ${(props) => (props.aHeight ? props.aHeight : '100%')};
  &:before {
    background-color: ${(props) =>
      props.borderColor ? props.borderColor : 'transparent'};
    content: '';
    display: inline-block;
    width: ${(props) => (props.lWidth ? props.lWidth : '1px')};
  }
`;

export const SeparatorHorizontal = styled.div`
  padding: ${(props) => (props.hsize ? props.hsize : '1rem 0')};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  &:before {
    background-color: ${(props) =>
      props.borderColor ? props.borderColor : 'transparent'};
    content: '';
    display: inline-block;
    height: ${({ stroke }) => stroke || '1px'};
    width: 100%;
  }
`;

export const BtnLink = styled.div`
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.hover ? 'pointer' : 'default')};
  color: ${(props) => (props.color ? props.color : Theme.gray3)};
  transition: all 0.2s ease-out;
  ${(props) =>
    props.hover &&
    `&:hover {
      color: ${(props) =>
        props.color ? darken(0.2, props.color) : darken(0.2, Theme.gray3)};
      text-decoration: underline;
    }`}
`;

export const Xcircle = styled.i`
  background-color: ${Theme.orange};
  mask-image: url(${icoXcircle});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: 1rem;
`;

export const X = styled.i`
  background-color: ${Theme.orange};
  mask-image: url(${IcoX});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
`;

export const Info = styled.i`
  background-color: ${Theme.orange};
  mask-image: url(${IcoInfo});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
`;

export const Listc = styled.i`
  background-color: ${Theme.orange};
  mask-image: url(${IcoList});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
`;

export const Download = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoDownload});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
`;

export const Play = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${IcoPlay});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
`;

export const LogoGraphIco = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${LogoGraph});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
`;

export const LogoGrafwIco = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${LogoGrafw});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
`;

export const LogoBrandwIco = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${LogoBrandw});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
`;

export const LogoTextIco = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${LogoText});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
`;

export const Eye = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${IcoEye});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
`;

export const Alert = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${IcoAlert});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
`;

export const Search = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${IcoSearch});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
`;

export const Clock = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoClock});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: 1rem 1rem 1rem 0;
  margin-right: 0.3rem;
  margin-bottom: 0.1rem;
`;

export const User = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoUser});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: 1rem;
`;

export const ChevronRight = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoChevronRight});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: 1rem;
`;

export const ChevronDown = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${IcoChevronDown});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: 1rem;
  /*position: absolute;
  right: 15px;
  top: 36%;*/
`;

export const ChevronUp = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${IcoChevronUp});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: 1rem;
  /*position: absolute;
  right: 15px;
  top: 36%;*/
`;

export const PlusCircle = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoPlusCircle});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
  pointer-events: none;
`;

export const IcoCheckCircle = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoCheckCircle});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: 1rem;
  pointer-events: none;
`;

export const Check = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${IcoCheck});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: 1rem;
  pointer-events: none;
`;

export const Bank = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoBank});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: 1rem;
`;

export const Slash = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoSlash});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: 1rem;
  /*margin-right: 1rem;*/
`;

export const Award = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoAward});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
`;

export const Wait = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoWait});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
`;

export const ArrowDown = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${IcoArrowDown});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
`;

export const ArrowUp = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${IcoArrowUp});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
`;

export const Send = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoSend});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
  pointer-events: none;
`;

export const FileText = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoFiletext});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
`;

export const RotateCCW = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoRotateCCW});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
  pointer-events: none;
`;

export const Minus = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${IcoMinus});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
  pointer-events: none;
`;

export const Plus = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${IcoPlus});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
  pointer-events: none;
`;

export const Thumbsdown = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoThumbsdown});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
`;

export const Frown = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoFrown});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
`;

export const Mic = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoMic});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
`;

export const Archive = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoArchive});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
`;

export const SpeechBubbles = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoSpeechBubbles});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
`;

export const Trash2 = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoTrash2});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
  transition: all 0.2s ease-out;
`;

export const Bell = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoBell});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
  transition: all 0.2s ease-out;
`;

export const HandShake = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoHandShake});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
  transition: all 0.2s ease-out;
`;

export const DollarSign = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoDollarSign});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
  transition: all 0.2s ease-out;
`;

export const Auction = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoAuction});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
  transition: all 0.2s ease-out;
`;

export const IcoEdit3 = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoEdit3});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
  transition: all 0.2s ease-out;
`;

export const AlertCircle = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoAlertCircle});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
  transition: all 0.2s ease-out;
`;

export const Stopwatch = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoStopwatch});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
  transition: all 0.2s ease-out;
`;

export const Negotiate = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoNegotiate});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
  transition: all 0.2s ease-out;
`;

export const Wallet = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoWallet});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
  transition: all 0.2s ease-out;
`;

export const Money = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoMoney});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
  transition: all 0.2s ease-out;
`;

export const Cedente = styled.i`
  background-color: ${(props) => props.color};
  mask-image: url(${icoCedente});
  mask-size: ${(props) => props.size};
  mask-position: center;
  mask-repeat: no-repeat;
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
  transition: all 0.2s ease-out;
`;

export const BtnCircle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.bgColor ? props.bgColor : Theme.gray1)};
  border-radius: 90rem;
  width: 2rem;
  height: 2rem;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover ${Trash2} {
    background-color: ${(props) =>
      props.icoColorHover ? props.icoColorHover : Theme.gray2};
  }
`;

export const BtnCircle2 = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.bgColor ? props.bgColor : Theme.gray1)};
  border-radius: 90rem;
  width: 2rem;
  height: 2rem;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover ${FileText} {
    background-color: ${(props) =>
      props.icoColorHover ? props.icoColorHover : Theme.gray2};
  }
`;

export const NotificInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  background-color: ${(props) => (props.bgColor ? props.bgColor : Theme.gray1)};
  border-radius: 90rem;
  width: 2rem;
  padding: 0.4rem;
  height: ${(props) => (props.hsize ? props.hsize : '2rem')};
  transition: all 0.2s ease-out;
`;

export const CircleNotify = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6rem;
  width: 1.2rem;
  height: 1.2rem;
  color: ${Theme.white};
  border-radius: 90rem;
  background-color: ${Theme.orange};
`;

export const Button = styled.button`
  width: 8rem;
  color: ${({ color }) => color && color};
  margin-right: 1rem;
  background-color: ${(props) =>
    props.bgcolor ? props.bgcolor : Theme.greenLight};
  border-color: ${(props) =>
    props.bgcolor ? props.bgcolor : Theme.greenLight};
  transition: all 0.2s ease-out;
  cursor: pointer;
  height: 2rem;
  border-radius: 0.2rem;
  &:hover {
    background-color: ${(props) =>
      props.bgcolor
        ? darken(0.2, props.bgcolor)
        : darken(0.2, Theme.greenLight)};
    border-color: ${(props) =>
      props.bgcolor
        ? darken(0.2, props.bgcolor)
        : darken(0.2, Theme.greenLight)};
    box-shadow: none !important;
  }
  &:focus {
    border-color: ${(props) =>
      props.bgcolor ? props.bgcolor : Theme.greenLight};
    box-shadow: none !important;
  }
  &:active {
    border-color: ${(props) =>
      props.bgcolor ? props.bgcolor : Theme.greenLight};
    box-shadow: none !important;
  }
  ${media.lessThan('medium')`
    /* screen width is less than 768px (medium) */
    flex-basis: 100%;
    margin:0.1rem 0;
  `}
`;

export const ButtonDanger = styled.button`
  margin-right: 1rem;
  width: 6rem;
  background-color: ${Theme.orangeLight};
  border-color: ${Theme.orangeLight};
  transition: all 0.2s ease-out;
  &:hover {
    background-color: ${darken(0.1, Theme.orangeLight)};
    border-color: ${darken(0.1, Theme.orangeLight)};
    box-shadow: none !important;
  }
  &:focus {
    border-color: ${Theme.orangeLight};
    box-shadow: none !important;
  }
  &:active {
    border-color: ${Theme.orangeLight};
    box-shadow: none !important;
  }
  ${media.lessThan('medium')`
    /* screen width is less than 768px (medium) */
    flex-basis: 100%;
    margin:0.6rem 0;

  `}
`;

export const LabelBtn = styled.span`
  color: ${(props) => (props.color ? props.color : Theme.gray3)};
`;

export const PicProfiler = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 90rem;
  width: 1.7rem;
  height: 1.7rem;
  background-color: ${(props) =>
    props.bgcolor ? props.bgcolor : Theme.secondary};
  border: 1px solid #fff;
  transition: all 0.2s ease-in;
  cursor: pointer;
  &:hover {
    background-color: ${(props) =>
      props.bgcolor
        ? darken(0.1, props.bgcolor)
        : darken(0.1, Theme.secondary)};
  }
`;

export const LettersIn = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${Theme.fontPrimary};
  font-weight: 600;
  font-size: 1.4rem;
  background-color: ${(props) =>
    props.bgcolor ? props.bgcolor : Theme.greenHardLight};
  color: ${(props) => (props.color ? props.color : Theme.primary)};
  border-radius: 90rem;
  width: ${(props) => (props.lWidth ? props.lWidth : '3rem')};
  height: ${(props) => (props.aHeigth ? props.aHeigth : '3rem')};
`;

export const WrapperProfiler = styled.div`
  position: relative;
  padding: 0.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.valign ? props.valign : 'center')};
  ${media.lessThan('medium')`
    /* screen width is less than 768px (medium) */
   height:auto;
  `};
`;

export const Notify = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: ${Theme.orange};
  border: 2px solid #fff;
  border-radius: 90rem;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  font-size: 0.8rem;
`;

export const LinkCuston = styled(Link)`
  width: 10rem;
  margin-right: 1rem;
  background-color: ${(props) =>
    props.bgcolor ? props.bgcolor : Theme.greenLight};
  border-color: ${(props) =>
    props.bgcolor ? props.bgcolor : Theme.greenLight};
  transition: all 0.2s ease-out;
  &:hover {
    background-color: ${(props) =>
      props.bgcolor
        ? darken(0.2, props.bgcolor)
        : darken(0.2, Theme.greenLight)};
    border-color: ${(props) =>
      props.bgcolor
        ? darken(0.2, props.bgcolor)
        : darken(0.2, Theme.greenLight)};
    box-shadow: none !important;
  }
  &:focus {
    border-color: ${(props) =>
      props.bgcolor ? props.bgcolor : Theme.greenLight};
    box-shadow: none !important;
  }
  &:active {
    border-color: ${(props) =>
      props.bgcolor ? props.bgcolor : Theme.greenLight};
    box-shadow: none !important;
  }
  ${media.lessThan('medium')`
    /* screen width is less than 768px (medium) */
    flex-basis: 100%;
    margin:0.1rem 0;
  `}
`;

export const WrapperCard = styled.div`
  order: ${(props) => (props.order ? props.order : 0)};
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  border-radius: 0.3rem;
  box-shadow: 0 2px 5px rgba(10, 10, 10, 0.3), 0 0 0 0px rgba(10, 10, 10, 0.1);
  border-bottom: ${(props) =>
    props.borderColor ? props.borderColor : `0.3rem solid ${Theme.secondary}`};
  width: 320px;
  background-color: ${Theme.white};
  margin-right: 1rem;
  order: ${(props) => (props.order ? props.order : 0)};
  min-height: 567px;
`;

export const RowTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  border-bottom: 1px solid ${Theme.gray1};
  padding-bottom: 0.5rem;
`;

export const TitleCard = styled.h1`
  color: ${(props) => (props.color ? props.color : Theme.gray5)};
  font-weight: 600;
  font-size: 1.6rem;
  margin-left: 1rem;
`;

export const UserIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 90rem;
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid ${(props) => props.colorBorder};
`;

export const RowBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => (props.padding ? props.padding : '1rem 0')};
  border-bottom: ${(props) =>
    props.border ? `1px solid ${Theme.gray1}` : 'none'};
`;

export const RowFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const RowCelLeft = styled.span`
  display: flex;
  align-items: center;
  color: ${(props) => (props.color ? props.color : Theme.gray4)};
  font-weight: 400;
  font-size: ${(props) => (props.size ? props.size : '1rem')};
`;

export const RowCelRight = styled.span`
  display: flex;
  align-items: center;
  color: ${(props) => (props.color ? props.color : Theme.gray5)};
  font-weight: 600;
  font-size: ${(props) => (props.size ? props.size : '1rem')};
`;

export const RowComents = styled.span`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: left;
`;

export const RowLabel = styled.span`
  color: ${(props) => (props.color ? props.color : Theme.gray4)};
  font-weight: 400;
  font-size: ${(props) => (props.size ? props.size : '1rem')};
  width: 100%;
`;

export const RowText = styled.span`
  color: ${(props) => (props.color ? props.color : Theme.gray5)};
  font-size: ${(props) => (props.size ? props.size : '0.8rem')};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;

export const RowBtnView = styled.span`
  color: ${(props) => (props.color ? props.color : Theme.primary)};
  font-weight: 400;
  font-size: ${(props) => (props.size ? props.size : '1rem')};
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover {
    color: ${(props) =>
      props.color ? darken(0.2, props.color) : darken(0.2, Theme.primary)};
  }
`;

export const BtnIcoAction = styled.button`
  border: ${(props) => (props.border ? props.border : 'transparent')};
  background-color: ${(props) =>
    props.backColor ? props.backColor : 'transparent'};
  display: flex;
  border-radius: ${(props) => (props.radius ? props.radius : '0.2rem')};
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.lWidth ? props.lWidth : '100%')};
  height: 2.3rem;
  font-size: 1rem;
  font-weight: 500;
  color: ${(props) => (props.color ? props.color : Theme.primary)};
  outline: none;
  cursor: pointer;
  padding: ${(props) => (props.padding ? props.padding : '0 2rem')};
  transition: all 0.2s ease-in;
  &:disabled {
    background-color: ${Theme.gray1};
    color: ${Theme.gray3};
  }
  &[disabled]:hover {
    background-color: ${Theme.gray1};
    color: ${Theme.gray3};
    cursor: initial;
  }
  &:hover {
    border-color: ${(props) =>
      props.border ? Theme.greenLight : 'transparent'};
    background-color: ${(props) =>
      props.backColor ? darken(0.1, props.backColor) : Theme.greenLight};
  }
  margin-bottom: ${(props) => (props.mBotton ? props.mBotton : '0')};
  font-size: ${(props) => (props.fsize ? props.fsize : '1rem')};
`;

export const Separator = styled.span`
  width: ${(props) => (props.width ? props.width : '2rem')};
`;

export const ShadowBlock = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : 'rgba(255,255,255,0.85)'};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 9;
`;

export const TitleItem = styled.h1`
  color: ${(props) => (props.color ? props.color : Theme.primary)};
  font-size: ${(props) => (props.size ? props.size : '1.7rem')};
  font-weight: 700;
  width: ${(props) => (props.wid ? props.wid : '130px')};
  text-align: center;
  line-height: 1.9rem;
  padding: 1.4rem 0;
`;

export const BtnActionStep = styled(Link)`
  display: flex;
  cursor: ${(props) => (props.linkhover === 'true' ? 'pointer' : 'auto')};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : Theme.gray1)};
  border-radius: 90rem;
  transition: all 0.2s ease-in;
`;

export const BtnActionStepNoLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : Theme.gray1)};
  border-radius: 90rem;
  transition: all 0.2s ease-in;
`;

export const LabelInfoBtn = styled.span`
  text-align: center;
  color: ${(props) => (props.color ? props.color : Theme.gray3)};
  font-size: 0.8rem;
  transition: all 0.2s ease-in;
`;

export const ContentBtnStep = styled.div`
  display: flex;
  flex-direction: column;
  cursor: ${(props) => (props.hover === 'true' ? 'pointer' : 'auto')};
  ${(props) =>
    props.hover === 'true' &&
    `
    &:hover ${BtnActionStep} {
      background-color: ${
        props.bgColor ? darken(0.1, props.bgColor) : darken(0.1, Theme.gray1)
      }
    }
    &:hover ${LabelInfoBtn} {
      color: ${
        props.color ? darken(0.1, props.color) : darken(0.1, Theme.gray3)
      };
      font-weight: 700;
    }
  `}
`;

export const WrapperBtnStep = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  &:before {
    content: '';
    width: 50%;
    height: 4px;
    display: inline-block;
    background-color: ${(props) =>
      props.barLeftColor ? props.barLeftColor : Theme.gray1};
  }
  &:after {
    content: '';
    width: 50%;
    height: 4px;
    display: inline-block;
    background-color: ${(props) =>
      props.barRightColor ? props.barRightColor : Theme.gray1};
  }
`;

const keyFrames = keyframes`
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1, 2.2);
  }
  40% {
    transform: scale(1);
  }
`;

export const WrapperLoad = styled.div`
  position: relative;
  padding: ${(props) => (props.padding ? props.padding : '0.9rem')};
`;

export const Loading = styled.div`
  position: absolute;
  left: ${(props) => (props.leftPos ? props.leftPos : '50%')};
  top: ${(props) => (props.topPos ? props.topPos : 'auto')};
  width: ${(props) => (props.lWidth ? props.lWidth : '1rem')};
  height: ${(props) => (props.aHeight ? props.aHeight : '1rem')};
`;

export const LoadingBar = styled.div`
  display: inline-block;
  margin: ${(props) => (props.mMargin ? props.mMargin : '0 0.1rem')};
  width: ${(props) => (props.lWidth ? props.lWidth : '4px')};
  height: ${(props) => (props.aHeight ? props.aHeight : '23px')};
  border-radius: 7px;
  animation: ${keyFrames} 1s ease-in-out infinite;
  &:nth-child(1) {
    background-color: ${Theme.primary};
    animation-delay: 0;
  }
  &:nth-child(2) {
    background-color: ${Theme.primary};
    animation-delay: 0.09s;
  }
  &:nth-child(3) {
    background-color: ${Theme.primary};
    animation-delay: 0.18s;
  }
  &:nth-child(4) {
    background-color: ${Theme.primary};
    animation-delay: 0.27s;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
`;

export const CardBox = styled.div`
  min-width: ${(props) => (props.mWidth ? props.mWidth : '345px')};
  width: ${(props) => (props.lwidth ? props.lwidth : '345px')};
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.direction ? props.direction : 'column')};
  background-color: ${(props) => (props.bg ? props.bg : '#fff')};
  padding: 1rem;
  border-radius: 0.3rem;
  position: relative;
  margin-right: ${(props) => (props.mRight ? props.mRight : '1rem')};
  height: ${(props) => (props.aheight ? props.aheight : '526px')};
  min-height: ${(props) => (props.mHeight ? props.mHeight : '345px')};
  animation: ${fadeIn} 0.5s ease-in-out;
  border-bottom: ${(props) =>
    props.borderBottom
      ? `0.3rem solid ${props.borderBottom}`
      : `0.3rem solid ${Theme.gray3}`};
`;

export const WrapperContentBox = styled.div`
  min-width: 345px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
  position: relative;
  height: auto;
  min-height: 345px;
  border-bottom: 0.3rem solid transparent;
`;

export const WrCard = styled.div`
  height: 648px;
  min-width: ${(props) => (props.lwidth ? props.lwidth : '345px')};
  background-color: #fff;
  position: relative;
`;

export const Cardhead = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: ${(props) => (props.aHeight ? props.aHeight : 'auto')};
`;

export const Person = styled.div`
  border-radius: 90rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.wsize ? props.wsize : '3rem')};
  height: ${(props) => (props.hsize ? props.hsize : '3rem')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : Theme.gray1)};
`;

export const Position = styled.div`
  color: ${(props) => (props.color ? props.color : Theme.gray6)};
  font-weight: 700;
  font-size: 2rem;
  &:after {
    content: 'a';
    color: ${Theme.gray6};
    font-size: 1rem;
    font-weight: 400;
    position: absolute;
    line-height: 2;
  }
`;

export const LineT = styled.div`
  font-weight: 700;
  color: ${Theme.gray6};
  text-align: left;
  width: ${(props) => (props.lwidth ? props.lwidth : '100px')};
  height: 17px;
`;

export const WrapperLine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const AreaUpdate = styled.div`
  flex-basis: 80px;
  display: flex;
  justify-content: flex-end;
`;

export const Podium = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: baseline;
  width: 60px;
`;

export const BarPodium = styled.div`
  width: 15%;
  margin: 0 0.2rem;
  border-radius: 0.5rem;

  &:nth-child(1) {
    height: 20px;
    background-color: ${(props) =>
      props.bgColor1 ? props.bgColor1 : Theme.gray1};
  }
  &:nth-child(2) {
    height: 25px;
    background-color: ${(props) =>
      props.bgColor2 ? props.bgColor2 : Theme.gray1};
  }
  &:nth-child(3) {
    height: 30px;
    background-color: ${(props) =>
      props.bgColor3 ? props.bgColor3 : Theme.gray1};
  }
  &:nth-child(4) {
    height: 35px;
    background-color: ${(props) =>
      props.bgColor4 ? props.bgColor4 : Theme.gray1};
  }
`;

export const WrapperBtns = styled.div`
  display: flex;
  align-items: center;
  padding: ${(props) => (props.paddin ? props.paddin : 0)};
  width: 100%;
`;

export const WrapperInput = styled.div`
  display: flex;
  align-items: center;
`;

export const Input = styled.input`
  background-color: ${Theme.white};
  border: 1px solid ${Theme.gray2};
  align-items: center;
  text-align: center;
  box-shadow: none;
  display: inline-flex;
  font-size: 0.9rem;
  height: ${(props) => (props.aHeigth ? props.aHeigth : '1.5rem')};
  justify-content: center;
  line-height: 1.5;
  padding-bottom: calc(0.375em - 2px);
  padding-left: calc(0.625em - 2px);
  padding-right: calc(0.625em - 2px);
  padding-top: calc(0.375em - 2px);
  position: relative;
  vertical-align: top;
  width: ${(props) => (props.lwidth ? props.lwidth : '124px')};
`;

export const TextArea = styled.textarea`
  background-color: ${Theme.white};
  border: 2px solid ${Theme.gray2};
  align-items: center;
  text-align: left;
  box-shadow: none;
  display: inline-flex;
  font-size: 0.9rem;
  line-height: 1.5;
  padding-bottom: calc(0.375em - 2px);
  padding-left: calc(0.625em - 2px);
  padding-right: calc(0.625em - 2px);
  padding-top: calc(0.375em - 2px);
  position: relative;
  vertical-align: top;
  width: 100%;
  outline: none;
  height: 6rem;
  border-radius: 4px;
  &:active {
    border-color: ${Theme.primary} !important;
    box-shadow: 0 0 0 0.125em rgba(0, 136, 148, 0.25);
  }
  &:focus {
    border-color: ${Theme.primary} !important;
    box-shadow: 0 0 0 0.125em rgba(0, 136, 148, 0.25);
    outline: 0;
  }
  &:hover {
    border-color: #b5b5b5;
  }
`;

export const BtnInput = styled.button`
  background-color: ${Theme.white};
  border: 1px solid ${Theme.gray2};
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  margin-left: 0.2rem;
  padding: 1px;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  flex-basis: ${(props) => (props.flexBasic ? props.flexBasic : 'initial')};
`;

export const StatusInfoPay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : Theme.primary};
  width: 3rem;
  height: 3rem;
  border-radius: 90rem;
`;

export const WrapperAccordion = styled.div`
  background-color: ${Theme.white};
  border-radius: 0.3rem;
  width: 100%;
  margin-bottom: 0.5rem;
`;
export const HeadAccordion = styled.div`
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
  text-align: left;
  display: flex;
  align-items: center;
  background-color: ${Theme.white};
  justify-content: ${(props) =>
    props.justifiy ? props.justifiy : 'space-around'};
  margin-right: ${(props) => (props.mRight ? props.mRight : 0)};
  border-radius: 0.3rem;
`;

export const WrapperHead = styled.div`
  display: flex;
  align-items: center;
  width: 96%;
  justify-content: space-between;
`;

export const PannelAccordion = styled.div`
  padding: ${(props) => (props.padding ? props.padding : '1rem')};
  display: block;
  overflow: hidden;
  /*max-height: ${(props) => (props.mxHeight ? props.mxHeight : 0)};*/
  transition: max-height 0.2s ease-out;
  background-color: ${Theme.white};
`;

export const ItemPanel = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.justifiy ? props.justifiy : 'space-around'};
`;

export const WrapperAction = styled.div`
  position: relative;
`;

export const ButtonActionPanel = styled.div`
  position: absolute;
  width: 95%;
  margin-left: 2rem;
  top: 0;
  right: 0;
  height: 100%;
  cursor: pointer;
  z-index: 9999;
`;

export const WrapperFoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const WrapperChat = styled.button`
  width: 175px;
  height: 70px;
  border-radius: 100rem;
  /*background: linear-gradient(139.4deg, #7bd5d9 14.63%, #f08376 94.37%);*/
  background-color: ${Theme.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  border: none;
  -webkit-box-shadow: 4px 0px 26px -5px rgba(0, 0, 0, 0.19);
  box-shadow: 4px 0px 26px -5px rgba(0, 0, 0, 0.19);
  position: fixed;
  right: 29px;
  bottom: 31px;
  z-index: 100000000;
  padding: 1rem;
`;

export const WrapperPodium = styled.div`
  display: flex;
  flex-direction: column;
  width: 117px;
  align-items: flex-end;
`;

export const NumberPos = styled.div`
  color: ${(props) => (props.color ? props.color : Theme.secondary)};
  font-size: ${(props) => (props.fsize ? props.fsize : '1rem')};
  font-weight: 600;
  margin-right: 0.3rem;
  &:after {
    content: 'o';
    font-size: 0.8rem;
    color: ${(props) => (props.color ? props.color : Theme.secondary)};
    font-weight: 600;
    vertical-align: super;
  }
`;

export const SpaceAreaDivide = styled.div`
  width: ${(props) => (props.lWidth ? props.lWidth : '345px')};
  min-width: 345px;
  height: 648px;
  /*border: 1px solid red;*/
`;

export const WrapperBlacklist = styled.div`
  width: ${(props) => (props.lWidht ? props.lWidht : '270px')};
  border: 1px solid ${Theme.gray2};
  border-radius: 0.3rem;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WrapperStatusList = styled.div`
  width: ${(props) => (props.lWidht ? props.lWidht : '170px')};
  border: 1px solid
    ${(props) => (props.bdColor ? props.bdColor : 'transparent')};
  border-radius: 0.3rem;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : 'transparent'};
  transition: all 0.2s ease-in;
  cursor: ${(props) => (props.cPointer ? props.cPointer : 'auto')};

  ${(props) =>
    props.hover &&
    `&:hover {
      background-color: ${(props) =>
        props.bgcolor ? darken(0.1, props.bgcolor) : darken(0.1, Theme.gray2)};
    }`}
`;

export const LinkButtonStatus = styled(Link)`
  width: ${(props) => (props.lWidht ? props.lWidht : '170px')};
  border: 1px solid
    ${(props) => (props.bgcolor ? props.bgcolor : 'transparent')};
  border-radius: 0.3rem;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.bgcolor ? props.bgcolor : 'transparent'};
  transition: all 0.2s ease-in;
  cursor: pointer;
  &:hover {
    background-color: ${(props) =>
      props.bgcolor ? darken(0.1, props.bgcolor) : darken(0.1, Theme.gray2)};
  }
`;

export const BtnSmalRoud = styled.span`
  border-radius: 0.4rem;
  cursor: pointer;
  &:hover {
    background-color: ${Theme.gray2};
  }
`;

import styled, { css, keyframes } from 'styled-components/macro';
import media from 'styled-media-query';

import IconLocal from 'assets/images/icon-local.svg';
import IconDollar from 'assets/images/icon-currency-dollar.svg';
import IconShield from 'assets/images/icon-check-shield.svg';
import IconAlert from 'assets/images/icon-alert-flat.svg';
import { ReactComponent as IconTree } from 'assets/images/icon-tree-dots.svg';

import { Link } from 'react-router-dom';

export const IconTreeStyle = styled(IconTree)`
  & path {
    fill: ${({ fillcolor }) => fillcolor || `#ffffff`};
  }
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  align-items: ${({ align }) => align || 'flex-start'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
`;

const VerticalMediaQuery = css`
  ${media.lessThan('500px')`
    grid-template-columns: minmax(0,1fr);
  `}

  ${media.greaterThan('580px')`
  grid-template-columns: repeat(2,minmax(0,1fr));
  `}

  ${media.between('medium', 'large')`
  grid-template-columns: repeat(3,minmax(0,1fr));
  `}

  ${media.greaterThan('large')`
  grid-template-columns: repeat(4,minmax(0,1fr));
  `}
`;

const HorizontalMediaQuery = css`
  ${media.greaterThan('large')`
  grid-template-columns: repeat(1,minmax(0,1fr));
  `}
`;

export const List = styled.ul`
  display: grid;
  gap: 1.2rem;
  margin: 1.2rem 0;
  list-style-type: none;

  ${({ direction }) => direction === 'vertical' && VerticalMediaQuery}
  ${({ direction }) => direction === 'horizontal' && HorizontalMediaQuery}
`;

export const ItemList = styled.li`
  display: flex;
`;

const fadeInOut = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 50;
  }
  100% {
    opacity: 100;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: ${({ direction }) => direction || `row`};
  border-radius: 16px;
  border: 1px solid #e7e7e7;
  width: 100%;
  overflow: hidden;

  ${({ isRemoved }) =>
    isRemoved &&
    css`
      animation: ${fadeInOut} 2s linear 1 forwards;
    `}
`;

export const Head = styled.div`
  display: flex;
  width: ${({ largura }) => largura || `100%`};
  min-height: 160px;
`;

export const Body = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 1rem;
`;

export const Cover = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f2f2f3;
  overflow: hidden;
  object-fit: fill;
`;

export const MediaImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 1;
`;

const truncate = css`
  width: max-content;
  max-width: ${({ limite }) => limite || `15ch`};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CompanyName = styled(Link)`
  color: var(--fingray-900);
  font-size: 1rem;
  font-family: 'Inter';
  font-weight: 600;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  ${truncate}
`;

export const CompanySegment = styled.span`
  color: var(--fingray-400);
  font-size: 0.8rem;
  font-weight: 400;
  font-family: 'Inter';
  ${truncate}
`;

export const RegionIconMatch = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  z-index: 5;
`;

export const CompanyLabel = styled.span`
  position: relative;
  color: var(--fingreen300);
  font-family: 'Inter';
  font-size: 1rem;
  font-weight: 400;
  text-indent: 28px;
  min-height: 24px;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 18px;
    height: 25px;
  }
`;

export const Location = styled(CompanyLabel)`
  &::before {
    ${({ isActive }) =>
      isActive
        ? `background: url(${IconLocal}) no-repeat center center;`
        : `background: url(${IconAlert}) no-repeat center center;`}
  }
  ${truncate}
`;

export const Invoicing = styled(CompanyLabel)`
  &::before {
    ${({ isActive }) =>
      isActive
        ? `background: url(${IconDollar}) no-repeat center center;`
        : `background: url(${IconAlert}) no-repeat center center;`}
  }
`;

export const FullProfile = styled(CompanyLabel)`
  &::before {
    ${({ isActive }) =>
      isActive
        ? `background: url(${IconShield}) no-repeat center center;`
        : `background: url(${IconAlert}) no-repeat center center;`}
  }
`;

export const RegionButtons = styled.div`
  display: flex;
  gap: 1rem;
`;

export const StatusEnable = styled.span`
  color: var(--fingray-900);
  font-size: 1rem;
  font-family: 'Inter';
  font-weight: 600;
  text-indent: 7px;
  text-align: left;

  /* background-color: #f7f7f7; */
  border-radius: 10px;
  width: 100%;
  padding: 1px 0;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    display: inline-flex;
    width: 20px;
    height: 20px;
    background: url(${({ icon }) => icon}) no-repeat center center;
    margin-left: 1rem;
  }
`;

import { format } from 'date-fns';

export const currency = (value) => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  });
  return formatter.format(value);
};

export const currencyDigitless = (value) => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 0,
  });
  return formatter.format(value);
};

export const dateTime = (value) => {
  /* let dateTimeFormatter = new Intl.DateTimeFormat('pt-BR');
  let date = new Date(value);
  return dateTimeFormatter.format(date); */

  // const result = format(new Date(value), 'DD/MM/YYYY HH:mm:ss');

  const result = format(new Date(value), 'dd/MM/yyyy HH:mm');
  return result;
};

export const dateTimeSeconds = (value) => {
  /* let dateTimeFormatter = new Intl.DateTimeFormat('pt-BR');
  let date = new Date(value);
  return dateTimeFormatter.format(date); */

  // const result = format(new Date(value), 'DD/MM/YYYY HH:mm:ss');

  const result = format(new Date(value), 'dd/MM/yyyy HH:mm:ss');
  const v = result.split(' ');
  const r = `${v[0]} às ${v[1]}`;
  return r;
};

export const date = (value) => {
  const result = format(new Date(value), 'dd/MM/yyyy');
  return result;
};

export const dotToComma = (value) => {
  return value.toString().replace('.', ',');
};

export const dotToCommaDecimals = (value, decimal) => {
  const val = value.toFixed(decimal);
  return val.toString().replace('.', ',');
};

import React from 'react';
import { useSelector } from 'react-redux';
import { Wrapper } from 'components/FinWrappers';
import { Paragraph2 } from 'components/FinParagraph';
import { useFetchExibePrecos } from 'pages/Operacoes/queries/useFetchExibePrecos';

import { Loader } from 'pages/Operacoes/Elements/Loader';
import FeedbackInfo from 'components/FinFeedbackInfo';
import { ExibePreco } from 'pages/Operacoes/Ofertas/ExibePreco';
import { DialogCuston } from './DialogCuston';

const DialogParticiparOperacao = () => {
  const uiDialogState = useSelector((state) => state.uiDialog);

  const { data, isLoading, error } = useFetchExibePrecos({
    idOperacao: uiDialogState.values.idOperacao,
  });

  const isExistData = !isLoading && !!data && !!data.data;

  const title = `${uiDialogState.title} ${uiDialogState.values.idOperacao}`;
  const text = `${uiDialogState.text.replace(
    'XXX',
    uiDialogState.values.nome
  )} ${uiDialogState.values.idOperacao}`;

  return (
    <DialogCuston customTitle={title}>
      <Wrapper
        padding="32px 0"
        justifyContent="flex-start"
        alignItems="flex-start"
        tipo="column"
      >
        <Paragraph2>{text}</Paragraph2>
        <Wrapper
          padding="1.5rem 0"
          justifyContent="flex-start"
          alignItems="center"
          pwidth="100%"
          tipo="column"
        >
          {isLoading && <Loader />}
          {error && <FeedbackInfo tipo="error" />}
          {!!data && data.data.length === 0 && (
            <FeedbackInfo tipo="empty" text="Conteúdo não disponível" />
          )}
          {isExistData && <ExibePreco data={data.data} />}
        </Wrapper>
      </Wrapper>
    </DialogCuston>
  );
};

export default DialogParticiparOperacao;

import styled from 'styled-components/macro';
import { rgba } from 'polished';
import media from 'styled-media-query';

export const FullSize = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100%;
  background-color: #ffffff;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  letter-spacing: -0.05em;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* width: calc(100% - 10vw);*/
  margin: 10vh 0;

  ${media.lessThan('1439px')`
    width: 1000px;
  `}

  ${media.between('1440px', '1600px')`
    width: 1200px;
  `}

  ${media.between('1601px', '2000px')`
    width: 1250px;
  `}

  ${media.greaterThan('2000px')`
    width: 1450px;
  `}
`;

export const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
`;

export const TitlePage = styled.h1`
  position: relative;
  font-weight: 700;
  letter-spacing: -0.05em;
  color: var(--fingray-900);
  font-size: clamp(2.56rem, -81.313rem + 89.938vw, 3.4rem);
  /* 2.56rem  = 1300px(81.25rem) e abaixo
  scaled  = 1301px(81.313rem) - 1439px(89.938rem)
 3.4rem = 1440px e acima*/
`;

export const IconFrame = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 70px;
  margin-right: 18px;
  background-color: ${({ theme }) =>
    rgba(theme.newtheme.color.azulPrimario, 0.2)};
  border-radius: 16px;
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  border-top: 1px solid #e3e3e7;
  border-bottom: 1px solid #e3e3e7;
  padding: 1rem 0;
  margin-bottom: 3rem;

  ${media.lessThan('medium')`
    /* background: red; */
    flex-direction: column;
  `}

  ${media.between('medium', 'large')`
    /* background: green; */
  `}

  ${media.greaterThan('large')`
    /* background: blue; */
  `}
`;

export const RegionSearch = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 50%;
  padding-right: 10rem;
  ${media.lessThan('medium')`
    width: 100%;
    justify-content: center;
    padding-right: 0;
  `}
`;

export const RegionFilters = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 50%;
  ${media.lessThan('medium')`
    margin-top: 1rem;
    width: 100%;
    justify-content: center;
  `}
`;

export const RegionListCards = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
`;

export const Heading = styled.div`
  display: flex;
  width: 100%;
`;

export const TitleSmall = styled.h5`
  color: var(--fingray-900);
  font-size: 1.2rem;
  font-weight: 600;
`;

export const RegionTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 50%;
`;

export const RegionAction = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 50%;
`;

import React, { useContext } from 'react';
import { Tabs, ItemTab, Item } from 'components/FinTabs';

import { Wrapper } from 'components/FinWrappers';

import { ReactComponent as IconeFolders } from 'assets/images/icon-folders.svg';
import { ReactComponent as IconeHandDown } from 'assets/images/icon-hand-down.svg';
import { ReactComponent as IconeHandUp } from 'assets/images/icon-hand-up.svg';
import { ReactComponent as IconeTrash } from 'assets/images/icon-trash.svg';
import { ReactComponent as IconeSad } from 'assets/images/icon-sad.svg';
import { ReactComponent as IconeFolderOpen } from 'assets/images/icon-folder-open.svg';

import { OperacoesContext } from '../provider';
import { useFetchTotalAbas } from '../queries/useFetchTotalAbas';

export const Navigation = () => {
  const [state, actions] = useContext(OperacoesContext);

  const { data, isLoading, error } = useFetchTotalAbas();

  const isExistData = !isLoading && !error && !!data && !!data.data;

  const {
    navigation: { tabActive },
  } = state;
  const { setTabActive } = actions;

  return (
    <Tabs ppadding="0 50px">
      <ItemTab>
        <Item
          onClick={() => setTabActive('emAberto')}
          isActive={tabActive === 'emAberto'}
        >
          <Wrapper
            pwidth="21px"
            pheight="21px"
            padding="0"
            pmargin="0 0.6rem 0 0"
          >
            <IconeFolderOpen />
          </Wrapper>
          {isExistData
            ? `Em Aberto (${data.data.totalAbaEmAberto})`
            : `Em Aberto (0)`}
        </Item>
      </ItemTab>
      <ItemTab>
        <Item
          onClick={() => setTabActive('todas')}
          isActive={tabActive === 'todas'}
        >
          <Wrapper
            pwidth="23px"
            pheight="21px"
            padding="0"
            pmargin="0 0.6rem 0 0"
          >
            <IconeFolders />
          </Wrapper>
          {isExistData ? `Todas (${data.data.totalAbaTodas})` : `Todas (0)`}
        </Item>
      </ItemTab>

      <ItemTab>
        <Item
          onClick={() => setTabActive('realizadas')}
          isActive={tabActive === 'realizadas'}
        >
          <Wrapper
            pwidth="24px"
            pheight="24px"
            padding="0"
            pmargin="0 0.6rem 0 0"
          >
            <IconeHandUp />
          </Wrapper>
          {isExistData
            ? `Realizadas (${data.data.totalAbaRealizadas})`
            : `Realizadas (0)`}
        </Item>
      </ItemTab>

      <ItemTab>
        <Item
          onClick={() => setTabActive('canceladas')}
          isActive={tabActive === 'canceladas'}
        >
          <Wrapper
            pwidth="23px"
            pheight="21px"
            padding="0"
            pmargin="0 0.6rem 0 0"
          >
            <IconeTrash />
          </Wrapper>
          {isExistData
            ? `Canceladas (${data.data.totalAbaCancelados})`
            : `Canceladas (0)`}
        </Item>
      </ItemTab>

      <ItemTab>
        <Item
          onClick={() => setTabActive('desistencias')}
          isActive={tabActive === 'desistencias'}
        >
          <Wrapper
            pwidth="23px"
            pheight="21px"
            padding="0"
            pmargin="0 0.6rem 0 0"
          >
            <IconeSad />
          </Wrapper>
          {isExistData
            ? `Desistências (${data.data.totalAbaDesistencias})`
            : `Desistências (0)`}
        </Item>
      </ItemTab>

      <ItemTab>
        <Item
          onClick={() => setTabActive('perdidas')}
          isActive={tabActive === 'perdidas'}
        >
          <Wrapper
            pwidth="23px"
            pheight="21px"
            padding="0"
            pmargin="0 0.6rem 0 0"
          >
            <IconeHandDown />
          </Wrapper>
          {isExistData
            ? `Perdidas (${data.data.totalAbaPerdidas})`
            : `Perdidas (0)`}
        </Item>
      </ItemTab>
    </Tabs>
  );
};

/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components/macro';
import { rgba } from 'polished';

// Table

export const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  ${({ striped, color, theme }) =>
    striped
      ? striped === 'odd'
        ? css`
            & > tbody > tr:nth-child(odd) {
              background-color: ${color || theme.newtheme.color.cinza1};
            }
          `
        : css`
            & > tbody > tr:nth-child(even) {
              background-color: ${color || theme.newtheme.color.cinza1};
            }
          `
      : null}
`;

export const Tbody = styled.tbody`
  color: ${({ theme }) => theme.newtheme.color.cinza6};
`;

export const Tr = styled.tr`
  background-color: transparent;

  ${({ borderBottom }) =>
    borderBottom &&
    css`
      border-bottom: 1px solid rgba(216, 216, 216, 0.5);
    `}
`;

export const Td = styled.td`
  padding: ${({ padding }) => padding || `0.8rem 0`};
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
  ${({ borderBottom }) =>
    borderBottom &&
    css`
      border-bottom: 1px solid rgba(216, 216, 216, 0.5);
    `}
`;

export const Thead = styled.thead`
  border-bottom: ${({ theme }) =>
    `1px solid ${rgba(theme.newtheme.color.cinza2, 0.5)}`};
  ${({ sticky }) =>
    sticky &&
    css`
      position: sticky;
      z-index: 2;
      top: 0;
      box-shadow: -1px 1px 0px -1px rgb(0 0 0 / 38%);
    `}
`;

export const Th = styled.th`
  padding: ${({ padding }) => padding || `16px 4px`};
  ${({ largura }) =>
    largura &&
    css`
      width: ${largura};
    `}
`;

import { useMemo } from 'react';

export function useFeedback(state, localHide = true) {
  return useMemo(() => {
    const { isLoading, data, error } = state;

    let feedback = {
      ready: false,
      waiting: false,
      error: false,
      empty: false,
    };

    if (!isLoading && !error && data.length > 0) {
      feedback = {
        ...feedback,
        ready: true,
      };
    }

    if (!isLoading && !error && data.length === 0) {
      feedback = {
        ...feedback,
        empty: true,
      };
    }

    if (data.length > 0 && localHide) {
      const { length } = data;
      const hides = data.filter((res) => res.localChangedIsHide);

      if (length === hides.length) {
        feedback = {
          ...feedback,
          empty: true,
        };
      }
    }

    if (error && !isLoading) {
      feedback = {
        ...feedback,
        error: true,
      };
    }

    if (!error && isLoading) {
      feedback = {
        ...feedback,
        waiting: true,
      };
    }

    return feedback;
  }, [state, localHide]);
}

import styled, { css } from 'styled-components/macro';
import { rgba } from 'polished';
import { motion } from 'framer-motion';
import { device } from 'devices';
import { Wrapper } from 'components/FinWrappers';
// import { Theme } from '../../theme';
import ImageIco from '../../images/iconsSvg/gear.svg';
import ImageIcoBreve from '../../images/undraw_logistics_x-4-dc.svg';

export const AreaImpressao = styled.div`
  @media print {
    box-sizing: border-box;
    padding: 2%;
    width: 80vw;
    /* border: 1px solid #999; */
    margin: 5% auto;
    background-image: url('../../../images/Logo_Finmatch_Vermelho.png');
    background-repeat: no-repeat;
    background-color: #fff;
    border-radius: 14px;
  }
`;
export const LogoImpressao = styled.h2`
  text-align: center;
  display: none;
  margin: 0px auto 40px auto;
  @media print {
    display: block;
  }
`;

export const FullContainerLoad = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${rgba('#ffffff', 0.8)};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  padding: 10rem 0;
`;
export const AreaAcao = styled.div`
  position: absolute;
  right: 20px;
  top: 2px;
  display: flex;
  width: 100%;
  justify-content: end;
  flex-direction: row;
  box-sizing: border-box;
  @media print {
    display: none;
  }
  padding: 10px 0px 0px 0px;
  .LinkAcao {
    display: ${({ display }) => display || 'initial'};
    transition: all 0.2s ease-in-out;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    background-color: #e8e8e8;
    text-align: center;
    margin: 0px 1px 0px 1px;
    &:hover {
      background-color: ${({ theme }) =>
        theme.backgroundColor.azulSecundarioEscuro};
    }
    svg {
      fill: orange;
    }
  }
`;
export const BlocoDashboardDestaques = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px;
  padding: 0px;
  width: 100%;
  p {
    letter-spacing: 0px !important;
    color: #646464;
  }

  div {
    flex: 2;
    border: 1px solid #dbdede;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 22px;

    &:nth-of-type(2n + 2) {
      margin: 0px 10px;
    }
    &:nth-of-type(2) {
      flex: 3;
    }
    &:nth-of-type(3) {
      flex: 5;
    }
    p {
      font-size: 0.9rem;
      letter-spacing: -1px;
      strong {
        font-size: 2rem;
        color: #000;
        &:nth-of-type(2) {
          font-weight: normal;
          color: #666;
          float: right;
        }
      }
    }
    &.infoMatch {
      display: flex;
      flex-direction: column;

      div {
        border-radius: 0;
        border: none;
        padding: 0px;
        margin: 0px;
      }
      .infoMatchValores {
        display: flex;

        flex-direction: row;
        div {
          margin: 0px;
          flex: 1;
          p {
            strong {
              display: block;
              color: #000;

              position: relative;
              &:before {
                right: 25%;
                top: 50%;
                transform: translateY(-50%);
                position: absolute;
                border-right: 1px solid #969696;
                content: '';
                height: 50%;
                width: 1px;
              }
            }
            font-size: 0.6875rem;
            text-transform: uppercase;
            font-weight: bold;
            span {
              font-size: 0.5625rem;
              display: block;
              font-weight: normal;
            }
          }
          &:nth-of-type(4) {
            p {
              strong {
                &:before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const BtnExperimentar = styled.button`
  width: 280px;
  max-width: 280px;
  text-align: center;
  display: block;
  box-sizing: border-box;
  padding: 10px 30px;
  background-color: #d9991d;
  color: #fff;
  font-size: 0.85rem;
  line-height: 1rem;
  transition: all 0.2s ease-in-out;
  border: none;
  border-radius: 2px;
  &:hover {
    background-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
    color: #fff;
  }
`;

export const BtnConfigurarMatch = styled.button`
  width: 280px;
  max-width: 280px;
  text-align: center;
  display: block;
  box-sizing: border-box;
  padding: 10px 40px;
  background-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
  color: #fff;
  font-size: 0.85rem;
  line-height: 2rem;
  transition: all 0.2s ease-in-out;
  border-radius: 2px;
  border: none;
  &:hover {
    background-color: #d9991d;
    color: #fff;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  width: 100%;
  height: 184px;
  border-bottom: 1px solid ${({ theme }) => theme.newtheme.color.cinza2};
  flex: 1;
`;

export const WrapperLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex: 6;
  overflow: hidden;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 3;
  height: 100%;
  overflow-y: auto;
  min-width: 900px;
  @media ${device.desktop} {
    flex: 4;
  }
`;

export const TopBar = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  background-color: ${({ theme }) => theme.newtheme.color.branco};
  border-bottom: 1px solid ${({ theme }) => theme.newtheme.color.cinza2};
  display: flex;
  justify-content: space-between;
  z-index: ${({ theme }) => theme.newtheme.zIndex.topBarContent};
  box-shadow: ${({ theme }) => theme.newtheme.dropShadow.dropShadow2};
`;

export const ContainerFilter = styled.div`
  flex: ${({ display }) => (!display ? 'none' : 1)};
  height: 100%;
  display: ${({ display }) => (!display ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.newtheme.color.cinza2};
  background-color: ${rgba('#ffffff', 1)};
  overflow: hidden;
  transition: flex 0.2s ease;
  min-width: 300px;
  position: relative;
`;

export const FilterFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid ${({ theme }) => theme.newtheme.color.cinza2};
  width: 100%;
  height: 80px;
  background-color: ${rgba('#ffffff', 0.5)};
`;

export const ContentFilter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  width: 100%;
  height: calc(100% - 80px);
  overflow-y: auto;
`;

export const BtnOpenMenu = styled(Wrapper)`
  cursor: pointer;
  padding: 0;
`;

export const TagCountSelected = styled.div`
  color: ${({ theme }) => theme.newtheme.color.branco};
  background-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
  border-radius: 2rem;
  width: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 33px;
  left: 78px;
  font-size: 0.8rem;
  font-weight: 600;
`;

export const ContentList = styled(motion.div)`
  width: 100%;
  background-color: ${({ theme }) => theme.newtheme.color.branco};
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: absolute;
  top: 94px;
  left: 0;
  z-index: 9;
  overflow-y: auto;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.31);
  opacity: 0;
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  height: 300px;
`;

export const TextList = styled.label.attrs(({ para }) => ({
  htmlFor: para,
}))`
  font-size: 12px;
  color: ${({ theme }) => theme.newtheme.color.cinza6};
  max-width: ${(props) => (props.lwidth ? props.lwidth : '137px')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`;

export const Feedback = styled.div`
  color: ${({ theme }) => theme.newtheme.color.cinza6};
  padding: 2rem;
`;

export const WrapperSelected = styled.div`
  border-bottom: ${({ theme }) => ` 1px solid ${theme.newtheme.color.cinza2}`};
  padding: 1rem 0;
  margin-bottom: 1rem;
  & h4 {
    color: ${({ theme }) => theme.newtheme.color.cinza6};
    font-weight: 600;
    margin-bottom: 1rem;
  }
`;

export const BtnClose = styled.button`
  background-color: transparent;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  cursor: pointer;
  outline: none;
`;

export const Btn = styled.button`
  background-color: transparent;
  border: ${({ theme }) => `2px solid ${theme.newtheme.color.azulPrimario}`};
  text-decoration: none;
  outline: none;
  cursor: pointer;
  padding: 0.2rem 1rem;
  color: ${({ theme }) => theme.newtheme.color.azulPrimario};
  border-radius: 2.8rem;
  font-weight: 500;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
    color: ${({ theme }) => theme.newtheme.color.branco};
  }
`;

export const BtnWrapper = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 46px;
  height: 49px;
  padding: 0;
  justify-content: center;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  border: none;
  outline: none;
  &:hover svg {
    fill: ${({ theme }) => theme.newtheme.color.azulTerceario};
  }
`;

export const Container = styled.div`
  margin: 0 auto;
  width: 75%;
  padding-top: 1rem;
  padding-bottom: 10rem;
`;

export const Card = styled.div`
  position: relative;
  width: 100%;
  padding: 0rem;
  border-radius: 0.3rem;

  background-color: #ffffff;
  min-height: 350px;
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 1rem;
`;

export const ImgeProfiler = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  background-color: #ffffff;
  border-radius: 90rem;
  box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
`;

export const StatusBar = styled.span`
  display: block;
  position: relative;
  margin-top: -1px;
  height: 2px;
  width: 100%;
  &:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    transform: scaleX(0);
    transition: transform 0.25s ease, transform 0.25s ease;
  }
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &:hover ${StatusBar}:after {
    background-color: rgba(0, 0, 0, 0.45);
    transform: scaleX(1);
  }
`;
export const Title = styled.h2`
  font-weight: 400;
  color: #000;

  font-size: 2.25rem;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding-left: 40px;
  &::before {
    /* background: url(${ImageIco}) no-repeat; */
    -webkit-mask-image: url(${ImageIco});
    mask-image: url(${ImageIco});
    -webkit-mask-size: 32px;
    mask-size: 32px;
    background-color: #008894;
    position: absolute;

    content: '';
    display: block;
    /* padding: 2rem; */
    width: 32px;
    height: 32px;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
  }
`;
export const Label = styled.span`
  color: ${({ color }) => color};
  font-weight: ${({ fontweight }) => fontweight};
  ${({ marginbottom }) =>
    marginbottom &&
    css`
      margin-bottom: ${marginbottom};
    `}
`;

export const Text = styled.span`
  color: #666;
  font-weight: 400;
`;

// area pagina dos graficos

export const AreaTextos = styled.div`
  width: 75%;
  flex-direction: column;
  font-size: 0.85rem;
  box-sizing: border-box;
  padding: 0px 20px;

  h2 {
    margin-bottom: 20px;
  }
  ul {
    margin: 0px 0px 0px 0px;
    li {
      position: relative;
      box-sizing: border-box;
      padding: 0px 0px 0px 15px;
      font-size: 0.875rem;
      &:after {
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        display: block;

        border-radius: 100%;
        width: 11px;
        height: 11px;
        border: 1px solid #ccc;
      }
      strong {
        box-sizing: border-box;
        padding: 0px 5px;
        float: right;
        width: 22%;
      }
      &:nth-of-type(1) {
        display: none;
        &:after {
          display: none;
        }
      }
      &:nth-of-type(2) {
        &:after {
          background-color: #bce0e3;
        }
      }
      &:nth-of-type(3) {
        &:after {
          background-color: #008894;
        }
      }
    }
  }
`;

export const GraficosH2 = styled.h2`
  font-size: 1.5rem;
  font-weight: 800;
  line-height: normal;
  color: #000;
  span {
    position: relative;
    display: block;
    font-weight: normal;
    font-size: 0.6875rem;
    color: #646464;
  }
`;

export const Grafico = styled.div`
  width: 151px;
  height: 151px;
`;

export const AreaLegenda = styled.div``;

export const CustonGrafico1 = styled.div`
  display: flex;
  align-items: top;
  justify-content: center;
  flex-direction: row;
  height: 200px;
  width: calc(100% / 2);
  strong {
    font-weight: bold;
  }
`;

export const CustonGrafico2 = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: top;
  flex-direction: column;

  height: 200px;
  width: calc(100% / 2);
`;

export const LegendasAderencia = styled.ul`
  display: flex;
  box-sizing: border-box;
  padding: 5px 0px 5px 15px;
  flex-direction: row;
  li {
    position: relative;
    box-sizing: border-box;
    padding: 5px 5px 5px 25px;
    font-size: 0.8rem;
    text-align: center;
    &:after {
      position: absolute;
      left: 10%;
      top: 50%;
      transform: translateY(-50%);
      content: '';
      display: block;
      border-radius: 100%;
      width: 11px;
      height: 11px;
    }

    &:nth-of-type(1) {
      &:after {
        background-color: #f4e3a0;
      }
    }
    &:nth-of-type(2) {
      &:after {
        background-color: #8de5ed;
      }
    }
    &:nth-of-type(3) {
      &:after {
        background-color: #bce0e3;
      }
    }
    &:nth-of-type(4) {
      &:after {
        background-color: #008894;
      }
    }
  }
`;

export const ModalExperimente = styled.div`
  h3 {
    font-size: 2rem;
    text-align: left;
    color: #333;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url(${ImageIcoBreve});
    background-size: 30%;
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 25%;

    padding-top: 15px;
    padding-bottom: 15px;
    width: 70%;
  }
  position: relative;
  p {
    color: #333;
  }
  .comIcone {
    position: relative;
    padding-left: 30px;
    height: auto;
    display: block;
    margin: 20px auto;
    color: #333;
    font-weight: bold;
    span {
      color: #aaaaaa;
      display: block;
      padding-left: 4px;
    }
    &:a {
      cursor: pointer;
    }
    svg {
      top: 0px;
      left: 0px;
      position: absolute;
    }
  }
`;

import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SkeletonComp = ({ count }) => {
  return (
    <div style={{ width: '100%' }}>
      <Skeleton count={count} />
    </div>
  );
};

export const SkeletonGenerator = ({ isLoading, count }) => {
  if (!isLoading) {
    return null;
  }

  return <SkeletonComp count={count} />;
};

import axios from 'axios';
import { useQuery } from 'react-query';
import CONFIG from 'config';
import { getUserData } from 'services/getUserData';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/`;

const fetchFiliais = async (params) => {
  const { idEmpresa } = await getUserData();

  const fetchData = axios.get(
    `${BASE_URL}api/matching/convenio/perfil/empresas/grupo/${params.idGrupo}/${idEmpresa}`
  );

  const result = await fetchData;
  const { data } = result;

  return data.data ? data.data : [];
};

export const useFiliais = (params) => {
  return useQuery(['filiais', params], () => fetchFiliais(params), {
    keepPreviousData: true,
  });
};

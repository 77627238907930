/* eslint-disable no-unused-vars */
import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import CONFIG from 'config';
import toast from 'react-hot-toast';
import { getUserData } from 'services/getUserData';
import { OperacoesContext } from '../provider';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const aceitar = async (params) => {
  const { idEmpresa, idUsuario } = await getUserData();

  const payload = {
    idConvenio: idEmpresa,
    idProposta: params.idProposta,
    idPessoaConvenio: idUsuario,
    ...params,
  };

  const mutateData = axios.put(
    `${BASE_URL}/proposta/convenio/aceitar`,
    payload
  );

  toast.promise(mutateData, {
    loading: 'Loading',
    success: 'Proposta aceita com sucesso',
    error: (error) => {
      if (error.response.status === 400) {
        return `Erro: ${error.response.data.message.toString()}`;
      }
      return `Erro: ${error.toString()}`;
    },
  });

  const result = await mutateData;
  const { data } = result;

  return data || [];
};

export const useMutateAceitarProposta = () => {
  const [state, actions] = useContext(OperacoesContext);
  const queryClient = useQueryClient();
  return useMutation((params) => aceitar(params), {
    onMutate: async (params) => {
      await queryClient.cancelQueries(['propostas']);

      return { params };
    },
    onSuccess: (data, variables, context) => {
      actions.setResetProposta();
      queryClient.invalidateQueries('propostas');
    },
  });
};

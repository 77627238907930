import React, { useContext, useState, useEffect } from 'react';

import { Wrapper } from 'components/FinWrappers';

import { ModalCuston } from 'components/FinModalCuston';
import { Title5, Title6 } from 'components/FinHeadings';
import {
  SeparatorVertical,
  SeparatorHorizontal,
} from 'components/FinSeparador';
import { FinInputCurrency } from 'components/FinInput/MainInput';

import { currency } from 'helpers/format';
import Button from 'components/FinButton';
import { Container, IconeAcertosStyle, WrapperFields } from './style.bordero';

import { OperacoesContext } from '../provider';
import { HeaderStyle } from '../Propostas/style';

import { useSelectProposta } from '../hooks/useSelectProposta';

const Head = ({ rules }) => {
  return (
    <HeaderStyle>
      <IconeAcertosStyle />
      <SeparatorVertical pwidth="0.4rem" />
      <Title5 style={{ fontWeight: 'bold', margin: '0' }}>
        {`Acertos ${rules.indexOf('view') !== -1 ? '' : ' (Editar)'}`}
      </Title5>
    </HeaderStyle>
  );
};

export const ModalAcertos = () => {
  const [acertos, setAcertos] = useState({
    tarifa: 0,
    pendencias: 0,
    recompra: 0,
  });

  const [state, actions] = useContext(OperacoesContext);

  const {
    modal: { values },
  } = state;

  const { proposta } = useSelectProposta({ idProposta: values.idProposta });

  const source = proposta.nota ? 'nota' : 'semNota';

  const onSubmit = () => {
    actions.updateNovaPropostaAcertos({ field: source, value: acertos });
    actions.removeModalActive();
  };

  const changeState = (field, value) => {
    setAcertos((state) => ({ ...state, [field]: value }));
  };

  const onChange = (event, maskedvalue, floatvalue, field) => {
    changeState(field, floatvalue);
  };

  useEffect(() => {
    const { tarifa } = proposta[source];
    const { pendencias } = proposta[source];
    const { recompra } = proposta[source];

    setAcertos({ tarifa, pendencias, recompra });
  }, []);

  const totalAcertos = acertos.tarifa + acertos.pendencias + acertos.recompra;

  return (
    <ModalCuston
      open={true}
      width="693px"
      title=""
      scrolling="true"
      onClose={() => actions.removeModalActive()}
      header={<Head rules={values.rules} />}
      ppadding="0"
      margin="5vh 0 10vh 0"
    >
      <Container style={{ minHeight: '336px' }}>
        <WrapperFields>
          <FinInputCurrency
            label="Tarifas"
            value={acertos.tarifa}
            name="tarifas-input"
            onChangeEvent={(e, m, f) => onChange(e, m, f, 'tarifa')}
            largura="100%"
            disabled={values.rules.indexOf('view') !== -1}
          />
          <SeparatorHorizontal ppadding="0.2rem 0" />
          <FinInputCurrency
            label="Pendências"
            value={acertos.pendencias}
            name="pendencias-input"
            onChangeEvent={(e, m, f) => onChange(e, m, f, 'pendencias')}
            largura="100%"
            disabled={values.rules.indexOf('view') !== -1}
          />
          <SeparatorHorizontal ppadding="0.2rem 0" />
          <FinInputCurrency
            label="Recompra"
            value={acertos.recompra}
            name="recompra-input"
            onChangeEvent={(e, m, f) => onChange(e, m, f, 'recompra')}
            largura="100%"
            disabled={values.rules.indexOf('view') !== -1}
          />
          <SeparatorHorizontal ppadding="0.2rem 0" />
          <Wrapper
            padding="1rem 3px"
            pwidth="100%"
            justifyContent="space-between"
          >
            <Wrapper
              padding="5px"
              justifyContent="space-between"
              style={{
                borderRadius: '6px',
                padding: '7px 10px',
                // backgroundColor: '#d1e6e8',
                width: '100%',
                justifyContent: 'end',
              }}
            >
              <Title6 style={{ margin: '0 1rem' }}>TOTAL</Title6>
              <Title5
                style={{ fontWeight: 'bold', margin: '0', color: '#000' }}
              >
                {currency(totalAcertos)}
              </Title5>
            </Wrapper>
          </Wrapper>
          {values.rules.indexOf('edit') !== -1 && (
            <>
              <SeparatorHorizontal ppadding="0.2rem 0" />
              <Wrapper padding="0" pwidth="100%" justifyContent="flex-end">
                <Wrapper padding="0" pmargin="0 0.5rem 0 0" pwidth="100px">
                  <Button
                    largura="total"
                    preenchimento="outline"
                    onClick={() => actions.removeModalActive()}
                    rounded
                  >
                    Cancelar
                  </Button>
                </Wrapper>
                <Wrapper padding="0" pwidth="100px">
                  <Button largura="total" rounded onClick={() => onSubmit()}>
                    Salvar
                  </Button>
                </Wrapper>
              </Wrapper>
            </>
          )}
        </WrapperFields>
      </Container>
    </ModalCuston>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';

import { Step } from '../Step';

import { Header, Body, Emoji } from '../style.wizard';

export const Step3 = React.forwardRef((props, ref) => {
  return (
    <Step {...props} ref={ref} viewAction={['close', 'previous']}>
      <Header>
        <Emoji>🔥</Emoji> Novidade - Módulo de Crédito
      </Header>
      <Body>
        <p>
          Gerencie o status de aprovação e limite de crédito de seus clientes.
        </p>
      </Body>
    </Step>
  );
});

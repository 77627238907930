import React from 'react';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as IconMatch } from 'assets/images/match-stamp.svg';
import { ReactComponent as IconIndicator } from 'assets/images/indication-stamp.svg';

export function RenderIconMatch({ data }) {
  const { finmatch, indicado } = data;

  return (
    <>
      {finmatch && (
        <>
          <ReactTooltip
            id={`icon-finmath-${data.idGrupo}`}
            wrapper="span"
            place="right"
            type="dark"
            effect="solid"
          >
            Finmatch
          </ReactTooltip>
          <IconMatch data-tip data-for={`icon-finmath-${data.idGrupo}`} />
        </>
      )}

      {indicado && (
        <>
          <ReactTooltip
            id={`icon-indicado-${data.idGrupo}`}
            wrapper="span"
            place="right"
            type="dark"
            effect="solid"
          >
            Indicação
          </ReactTooltip>
          <IconIndicator data-tip data-for={`icon-indicado-${data.idGrupo}`} />
        </>
      )}
    </>
  );
}

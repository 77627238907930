import styled from 'styled-components';

import {
  FinInputCurrency,
  InputCurrencyStyle,
} from 'components/FinInput/MainInput';
import { CheckboxFlat } from 'components/FinCheckboxCuston/CheckboxFlat';

import { ReactComponent as Sequence } from '../../assets/icones/icone-ordem.svg';
import { ReactComponent as Info } from '../../assets/icones/icone-info.svg';
import { ReactComponent as ThreeDotsVertical } from '../../assets/icones/icone-tres-pontos-vertical.svg';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  border: 1px solid #e3e3e7;
  border-left: 0;
  border-right: 0;
  padding: 16px;
  font-size: 16px;
  line-height: 1.5;
`;

export const Table = styled.table`
  width: 100%;
  background-color: #fff;

  th,
  td {
    vertical-align: middle;
    text-align: left;
    padding: 16px;
    font-size: 14px;
  }

  & th {
    height: 48px;
    color: #646464;
    background-color: #f2f2f3;
    text-align: left;
    vertical-align: middle;
    font-size: 12px;
    font-weight: 600;
    line-height: 15.6px;
  }

  th:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  th:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  td:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  td:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  & tbody tr {
    height: 56px;

    &:focus-within {
      background-color: #ebfdff;
    }
  }
`;

export const Checkbox = styled(CheckboxFlat)`
  & span span {
    background-color: transparent;
    border: 2px solid #8d8d91;
    border-radius: 3px;
  }
`;

export const SequenceIcon = styled(Sequence)`
  display: inline-block;
  width: 8px;
  height: 14px;
  margin-left: 8px;
  margin-bottom: -2px;
`;

export const CorpIcon = styled.span`
  display: flex;
  flex-direction: row;
  width: 37px;
  height: 37px;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 14px;
  font-weight: 700;
  background-color: #eaeaeb;
  border: 2px solid #04a8b9;
  border-radius: 12px;
  background-clip: content-box;
  padding: 2px;
  margin-right: 10px;
`;

export const CorpName = styled.span`
  display: inline-block;
  line-height: 1.5;
  max-width: 12ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const EnterpriseCont = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const EditTaxContainer = styled.div`
  display: flex;
  width: 85px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const TaxInput = styled(InputCurrencyStyle)`
  display: flex;
  height: 32px;
  background-color: #f2f2f3;
  border: none;
  border-radius: 10px;
  text-align: left;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
`;

export const EditPriCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 129px;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
`;

export const RedText = styled.div`
  display: inline-block;
  width: 100px;
  color: #d92525;
`;

export const EditAgendaCont = styled.div`
  display: flex;
  width: 144px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const AgendaInput = styled(FinInputCurrency)`
  & input {
    display: flex;
    height: 32px;
    background-color: #f2f2f3;
    border: none;
    border-radius: 10px;
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 76px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;

export const UltimaOpCont = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 122px;
  justify-content: space-between;
`;

export const DetailsContainer = styled.span`
  display: flex;
  flex-direction: row;
  height: 24px;
  width: 18px;
  justify-content: flex-start;
  align-items: center;
  margin-left: 4px;
  position: relative;
`;

export const InfoIcon = styled(Info)``;

export const ThreeDotsIcon = styled(ThreeDotsVertical)``;

export const InfoContainer = styled.span`
  width: 9.75px;
  height: 9.75px;
  margin-bottom: 20px;
`;

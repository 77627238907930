import React, { useCallback, useEffect } from 'react';
import { /* useSelector, */ useDispatch, useSelector } from 'react-redux';

import { SeparatorHorizontal } from 'components/FinSeparador';
import Button from 'components/FinButton';
import {
  clearAllItemsListDownload,
  saveDocFileZip,
} from 'store/old_actions/actions';
import { finTheme } from 'finTheme';
import { Ring } from '@uiball/loaders';
import { Line, Column } from './Styles';
import DocList from './DocList';
import DocHeader from './DocHeader';

const Documentos = ({ data, refetch }) => {
  const dispatch = useDispatch();
  const {
    isLoading,
    complete,
    data: documents,
  } = useSelector((state) => state.listDownload);

  const clear = useCallback(() => {
    dispatch(clearAllItemsListDownload());
  }, [dispatch]);

  const download = useCallback(
    (name) => {
      dispatch(saveDocFileZip(name));
    },
    [dispatch]
  );

  const downloadDocumentos = useCallback(() => {
    const words = data.nome.split(' ');
    const first = words[0];
    const name = `${first.toString().toLowerCase()}-${data.cnpj.toString()}`;

    download({ name, idGrupo: data.idGrupo });
  }, [data, download]);

  useEffect(() => {
    if (complete) {
      refetch();
    }
  }, [complete, refetch]);

  useEffect(() => {
    clear();
  }, [clear]);

  return (
    <>
      <SeparatorHorizontal />
      <Line>
        <Column pwidth="100%">
          <DocHeader docs={data.documentos} />
          {data.documentos.map((res) => {
            return (
              <DocList
                key={res.idDocumento}
                doc={res.nome}
                firstUpload={res.dtAtualizacao}
                lastDownload={res.dtUltimoDownload}
                link={res.link}
                idDocumento={res.idDocumento}
              />
            );
          })}
        </Column>
      </Line>
      <SeparatorHorizontal ppadding="0.5rem" />
      <Line>
        <Column
          pwidth="100%"
          style={{ display: 'flex', alignItems: 'flex-end' }}
        >
          <div
            style={{
              width: '200px',
            }}
          >
            <Button
              tipo="primary"
              largura="total"
              size={finTheme.newtheme.buttons.sizes.smallM}
              onClick={() => downloadDocumentos()}
              disabled={isLoading || documents.length === 0}
            >
              {isLoading ? (
                <Ring size={25} lineWeight={5} speed={2} color={'#fff'} />
              ) : (
                'Baixar Documentos'
              )}
            </Button>
          </div>
        </Column>
      </Line>
      <SeparatorHorizontal ppadding="0.5rem" />
    </>
  );
};

export default Documentos;

import React, { useState } from 'react';
import styled from 'styled-components/macro';
//import CurrencyInput from 'react-currency-input';
import CurrencyFormatter from 'currency-formatter';

import { Theme } from '../../theme';
import './style.scss';

import { InputCurrency } from '../../components/Input';

export const Input = styled.input`
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
  max-width: 100%;
  width: 100%;
  background-color: #fff;
  border-color: #dbdbdb !important;
  color: #363636;
  align-items: center;
  border: 2px solid transparent !important;
  border-radius: 4px;
  display: inline-flex;
  font-size: 1rem;
  height: 1.8em;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
  padding-top: calc(0.5em - 1px);
  position: relative;
  vertical-align: top;
  &:active {
    border-color: ${Theme.primary} !important;
    box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
  }
  &:focus {
    border-color: ${Theme.primary} !important;
    box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
    outline: 0;
  }
  &:hover {
    border-color: #b5b5b5;
  }
`;

const InputValue = props => {
  const [value, setValue] = useState(props.value);
  //return <Input value={value} onChange={e => setValue(e.target.value)} />;
  const handleInput = value => {
    const val = CurrencyFormatter.unformat(value, { code: 'BRL' });
    setValue(val);
    props.action('CHANGE_VALUE', val);
  };
  return (
    <InputCurrency
      placeholder="Valor (Opcional)"
      prefix="R$ "
      decimalSeparator=","
      thousandSeparator="."
      value={value}
      onChangeEvent={e => handleInput(e.target.value)}
    />
  );
};

export default InputValue;

import React from 'react';

import { useQueryState } from 'hooks/useQueryState';
import { useRenderResponseData } from 'hooks/useRenderResponseData';
import { NWBreadcrumb, NWBreadcrumbItem } from 'components/NWBreadcrumb';

import { RegionListCards } from '../style.layout';

import { Card, List, ItemList } from '../Comps/Card';
import { NWPagination } from '../Comps/Pagination';
import { useQueryMyConnections } from '../hook/useQueryClients';

import { statusConexao } from '../config';

export function MinhasConexoes({ executeScroll }) {
  const { getQueries, setQueriesAdd } = useQueryState();

  const skip = getQueries('skip') || 1;

  const { data, isLoading, error } = useQueryMyConnections();

  const { PlaceNoResult, PlaceLoader, PlaceError } = useRenderResponseData({
    data,
    isLoading,
    error,
  });

  const withData = data && data.data;

  const changePage = (page) => {
    setQueriesAdd({ skip: page });
    executeScroll();
  };

  return (
    <>
      <RegionListCards>
        <NWBreadcrumb>
          <NWBreadcrumbItem to="/minha-rede">Minha Rede</NWBreadcrumbItem>
          <NWBreadcrumbItem noIcon>Minhas Conexões</NWBreadcrumbItem>
        </NWBreadcrumb>
        <PlaceNoResult />
        <PlaceLoader />
        <PlaceError />
        <List direction="horizontal">
          {withData &&
            data.data.map((item) => {
              return (
                <ItemList key={item.idGrupo}>
                  <Card
                    tipo="horizontal"
                    data={item}
                    isConnection={item.statusConexao === statusConexao.aceita}
                  />
                </ItemList>
              );
            })}
        </List>
      </RegionListCards>
      <NWPagination
        totalPages={data && data.totalPages}
        page={skip}
        changePage={({ page }) => changePage(page)}
        place="center"
      />
    </>
  );
}

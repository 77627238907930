import styled, { css } from 'styled-components/macro';

import { FinInput, FinInputCurrency } from 'components/FinInput/MainInput';
import { CheckboxFlat } from 'components/FinCheckboxCuston/CheckboxFlat';

import IconSearch from 'assets/images/search-outline.svg';
// import { darken } from 'polished';
// import Button from 'components/FinButton';

export const Fieldset = styled.div`
  padding: 5vh 0;
  display: flex;
  width: 100%;
  border-bottom: 1px solid var(--finborder-color);
`;

export const Label = styled.span`
  font-family: 'Inter', sans-serif;
  color: var(--fingray-900);
  font-weight: 600;
  font-size: 1rem;
  padding-bottom: 20px;
  letter-spacing: 0px;
  line-height: 5px;

  ${({ tag }) =>
    tag &&
    css`
      &::after {
        content: '${tag}';
        border-radius: 50px;
        background: var(--finblue-primary);
        font-family: 'Inter', sans-serif;
        font-size: 0.75rem;
        font-weight: 400;
        color: #ffffff;
        text-rendering: geometricprecision;
        letter-spacing: 0.3px;
        padding: 4px 14px;
        margin-left: 8px;
      }
    `}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
`;

export const Tag = styled.span`
  border-radius: 50px;
  background: var(--finblue-primary);
  font-size: 0.75rem;
  font-weight: 400;
  color: #ffffff;
  text-rendering: geometricprecision;
  letter-spacing: 1px;
`;

export const InputSearch = styled(FinInput)`
  width: 100%;
  & > input {
    position: relative;
    font-family: 'Inter', sans-serif;
    height: 40px;
    border-radius: 10px;
    border: none;
    background: url(${IconSearch}) no-repeat 11px center;
    background-color: var(--fingray-300);
    font-size: 0.9rem;
    text-indent: 40px;
    color: var(--fingray-900);
    z-index: 3;
    &::placeholder {
      color: var(--fingray-900);
      letter-spacing: 0;
    }
  }
`;

export const InputCurrency = styled(FinInputCurrency)`
  font-family: 'Inter', sans-serif;
  & > span {
    text-transform: capitalize;
    font-weight: 400;
    font-size: 0.875rem;
    color: var(--fingray-900);
    letter-spacing: 0px;
  }

  width: 100%;
  & > input {
    position: relative;
    font-family: 'Inter', sans-serif;
    height: 40px;
    border-radius: 10px;
    border: none;
    background-color: var(--fingray-300);
    font-size: 0.9rem;
    text-indent: 10px;
    color: var(--fingray-900);
    &::placeholder {
      color: var(--fingray-900);
      letter-spacing: 0;
    }
  }
`;

export const Checkbox = styled(CheckboxFlat)`
  margin-bottom: 10px;
  & > span:last-child {
    font-size: 0.875rem;
    color: var(--fingray-900);
    margin-left: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: ${({ ftW }) => ftW || '400'};
  }
`;

export const Paragraphy = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--fingray-900);
  text-rendering: geometricprecision;
  margin-bottom: 1rem;
`;

import React from 'react';
// import { useSelector } from 'react-redux';

import { Paragraph2 } from 'components/FinParagraph';
import { SeparatorHorizontal } from 'components/FinSeparador';
// import Rating from 'components/FinRating';
import { finTheme } from 'finTheme';
import BlurryText from 'components/FinBlurryText';
import { currency } from 'components/Formatter';
import { Line, Column, CustonSubtitle2 } from './Styles';

const Geral = ({ data }) => {
  // const apiInformacoesState = useSelector(
  //   (state) => state.apiInformacoes.data[0]
  // );
  return (
    <>
      <SeparatorHorizontal />
      {/* <Line>
        <Column pwidth="50%">
          <CustonSubtitle2>Na Finplace desde</CustonSubtitle2>
          <Paragraph2 pmargin="0.4rem 0">
            <BlurryText blurry={false}>Novembro 2019</BlurryText>
          </Paragraph2>
        </Column>
        <Column pwidth="50%">
          <CustonSubtitle2>Classificação</CustonSubtitle2>
          <Rating value={3} />
        </Column>
      </Line>
      <SeparatorHorizontal
        ppadding="2rem"
        pborderColor={finTheme.newtheme.color.cinza4}
      /> */}
      <Line>
        <Column pwidth="33.3%">
          <CustonSubtitle2>Fundação</CustonSubtitle2>
          <Paragraph2 pmargin="0.4rem 0">
            <BlurryText blurry={false}>{data.dataFundacao}</BlurryText>
          </Paragraph2>
        </Column>
        <Column pwidth="33.3%">
          <CustonSubtitle2>Segmento</CustonSubtitle2>
          <Paragraph2 pmargin="0.4rem 0">
            <BlurryText blurry={false}>{data.segmento || ''}</BlurryText>
          </Paragraph2>
        </Column>
        <Column pwidth="33.3%">
          <CustonSubtitle2>Faturamento anual (R$)</CustonSubtitle2>
          <Paragraph2 pmargin="0.4rem 0">
            <BlurryText blurry={false}>{currency(data.faturamento)}</BlurryText>
          </Paragraph2>
        </Column>
      </Line>
      <SeparatorHorizontal ppadding="0.5rem" />
      <Line>
        <Column pwidth="100%">
          <CustonSubtitle2>CNAE Principal</CustonSubtitle2>
          <Paragraph2 pmargin="0.4rem 0">
            <BlurryText blurry={false}>
              {`${data.cnaEcod} - ${data.cnaEdesc}`}
            </BlurryText>
          </Paragraph2>
        </Column>
      </Line>
      <SeparatorHorizontal ppadding="0.5rem" />
      <Line>
        <Column pwidth="100%">
          <CustonSubtitle2>Sobre a empresa</CustonSubtitle2>
          <Paragraph2 pmargin="0.4rem 0">
            <BlurryText blurry={false}>{data.sobre || ''}</BlurryText>
          </Paragraph2>
        </Column>
      </Line>
      <SeparatorHorizontal
        ppadding="2rem"
        pborderColor={finTheme.newtheme.color.cinza4}
      />
      <Line>
        <Column pwidth="33.3%">
          <CustonSubtitle2>Banco</CustonSubtitle2>
          <Paragraph2 pmargin="0.4rem 0">
            <BlurryText blurry={data.criptografia}>
              {data.contaPrincipal.banco}
            </BlurryText>
          </Paragraph2>
        </Column>
        <Column pwidth="33.3%">
          <CustonSubtitle2>Agência</CustonSubtitle2>
          <Paragraph2 pmargin="0.4rem 0">
            <BlurryText blurry={data.criptografia}>
              {data.contaPrincipal.agencia}
            </BlurryText>
          </Paragraph2>
        </Column>
        <Column pwidth="33.3%">
          <CustonSubtitle2>Conta</CustonSubtitle2>
          <Paragraph2 pmargin="0.4rem 0">
            <BlurryText blurry={data.criptografia}>
              {`${data.contaPrincipal.conta}${
                data.contaPrincipal.digito && `-${data.contaPrincipal.digito}`
              }`}
            </BlurryText>
          </Paragraph2>
        </Column>
      </Line>
    </>
  );
};

export default Geral;

import React from 'react';

import {
  BreadcrumbNav,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  ContentItem,
} from './style.breadcrumb';

export function NWBreadcrumbItem({ children, noIcon, to }) {
  return (
    <BreadcrumbItem>
      <BreadcrumbLink to={to}>
        <ContentItem noIcon={noIcon}>{children}</ContentItem>
      </BreadcrumbLink>
    </BreadcrumbItem>
  );
}

export function NWBreadcrumb({ children }) {
  return (
    <BreadcrumbNav>
      <Breadcrumb>{children}</Breadcrumb>
    </BreadcrumbNav>
  );
}

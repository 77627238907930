/* eslint-disable no-unused-vars */
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import CONFIG from 'config';
import toast from 'react-hot-toast';

import { handleErrors } from 'helpers/requisicoes/handleErrors';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const inviteRemoved = async (params) => {
  const mutateData = axios.delete(
    `${BASE_URL}/matching/conexao/cancelar/${params.idConvite}`
  );

  const result = await mutateData;
  const { data } = result;

  return data || [];
};

export const useMutateRemoveInvite = () => {
  const queryClient = useQueryClient();

  return useMutation((params) => inviteRemoved(params), {
    onMutate: async (params) => {
      await queryClient.cancelQueries(['invitations']);
    },
    onError: (error) => {
      const message = handleErrors(error);
      toast.error(message, { duration: 4000 });
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('search');
      queryClient.invalidateQueries('perfil');
      queryClient.invalidateQueries('invitations');
      queryClient.invalidateQueries('empreendedores');
    },
  });
};

import React from 'react';
import styled from 'styled-components/macro';

import { SeparatorHorizontal } from 'components/Elements';
import FeedbackInfo from 'components/FinFeedbackInfo';
import { Theme } from '../../../../theme';

import Percentage from './Percentage';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || '50%'};
`;

const ListPercentagens = ({ data, width }) => {
  return (
    <Column width={width}>
      <SeparatorHorizontal borderColor={Theme.gray2} hsize="0.5rem 0" />
      {data.length > 0 ? (
        data.map((res, index) => <Percentage key={index} data={res} />)
      ) : (
        <FeedbackInfo tipo="empty" text="Conteúdo não disponível" />
      )}
    </Column>
  );
};

export { ListPercentagens };

import styled, { css /* , keyframes */ } from 'styled-components/macro';
import { rgba, lighten } from 'polished';

import { Caption } from 'components/FinParagraph';

import { device } from 'devices';

import iconArrowDown from 'assets/images/icon-arrow-down-right.svg';
import iconArrowUp from 'assets/images/icon-arrow-up-right.svg';
import iconDot from 'assets/images/icon-dot.svg';
import iconWarning from 'assets/images/icon-warning.svg';
import { ReactComponent as IconCheck } from 'assets/images/icon-check.svg';
import { ReactComponent as IconMoney } from 'assets/images/icon-money.svg';
import { ReactComponent as IconCam } from 'assets/images/icon-cam.svg';

export const IconCamStyle = styled(IconCam)`
  stroke: #fff;
  & path {
    stroke: #fff;
  }
`;

export const IconMoneyStyle = styled(IconMoney)`
  stroke: #008894;
  & path {
    stroke: #008894;
  }
`;

export const IconCheckStyle = styled(IconCheck)`
  stroke: #646464;
  & path {
    stroke: #646464;
  }
`;

export const IconCheckedMenu = styled.span`
  display: flex;
  width: 24px;
  height: 24px;
`;

export const HeaderStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 10vh;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.newtheme.color.cinza2};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 1rem 1rem calc(1rem + 5px) 1rem;
  width: 100%;
  height: calc(100vh - 192px);
  background-color: ${({ theme }) => theme.newtheme.color.cinza1};

  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }
  @media ${device.desktop} {
    height: calc(100vh - 339px);
    padding: 2rem 2rem calc(2rem + 5px) 2rem;
  }
  @media ${device.desktopL} {
    height: calc(100vh - 425px);
    padding: 2rem 2rem calc(2rem + 5px) 2rem;
  }
`;

export const MediaQueryModal = css`
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }
  @media ${device.desktop} {
    min-height: 73vh;
  }
  @media ${device.desktopL} {
    min-height: 65vh;
  }
`;

export const CardStyle = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 350px;
  height: 100%;
  margin-right: 1rem;
  border-radius: 16px;
  overflow: hidden;
  padding: 1rem;
  background-color: #fff;
  border: 1px solid #e2e1e1;
`;

export const CardStyleHead = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.newtheme.color.cinza2};
`;

export const CardStyleBody = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
`;

export const CardStyleFooter = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PodiumStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60px;
  height: 55px;
  position: absolute;
  right: 0;
  top: 0;
`;

export const PodiumWarpBarsStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  width: 100%;
  padding: 0 0.5rem;
`;

export const PodiumBarsStyle = styled.div`
  height: ${({ percent }) => percent || '100%'};
  width: 15%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: ${({ bgColor }) => bgColor || '#70e5ef'};
`;

export const CaptionCustom = styled(Caption)`
  font-weight: bold;
  margin-right: 0;
`;

const Error = css`
  & ${CaptionCustom} {
    margin-right: 24px;
  }
  &:after {
    content: url(${iconWarning});
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
  }
`;

const SemModificacao = css`
  & ${CaptionCustom} {
    margin-right: 24px;
  }
  &:after {
    content: url(${iconDot});
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
  }
`;

const Aumentou = css`
  & ${CaptionCustom} {
    margin-right: 24px;
  }
  &:after {
    content: url(${iconArrowUp});
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
  }
`;

const Diminuiu = css`
  & ${CaptionCustom} {
    margin-right: 24px;
  }
  &:after {
    content: url(${iconArrowDown});
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
  }
`;

export const CardRowStyle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px dashed ${({ theme }) => theme.newtheme.color.cinza2};
  position: relative;

  ${({ hideIndicator, indicator }) =>
    !hideIndicator && indicator === 'semModificacao' && SemModificacao}

  ${({ hideIndicator, indicator }) =>
    !hideIndicator && indicator === 'aumentou' && Aumentou}

${({ hideIndicator, indicator }) =>
    !hideIndicator && indicator === 'diminuiu' && Diminuiu}

${({ hideIndicator, indicator }) =>
    !hideIndicator && indicator === 'calculoInvalido' && Error}
`;

export const CaptionCustomLine = styled(Caption)`
  line-height: 26px;
`;

export const ButtonRowStyle = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  outline: none;
  margin-right: 0.5rem;
  cursor: pointer;
  transition: all 0.4s ease-in-out;

  ${({ strokeSvg }) =>
    strokeSvg &&
    css`
      & svg {
        stroke: ${strokeSvg};
        & path {
          stroke: ${strokeSvg};
        }
      }
    `}

  ${({ fillSvg }) =>
    fillSvg &&
    css`
      & svg {
        fill: ${fillSvg};
        & path {
          fill: ${fillSvg};
        }
      }
    `}

  &:hover {
    background-color: ${({ colorHover }) => lighten(0.09, colorHover)};
  }
  &:active {
    background-color: ${({ colorHover }) => lighten(0.08, colorHover)};
  }
`;

export const BoxChampionStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${rgba('#ffcb04', 0.2)};
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 16px;
  border: 2px solid #ffcb04;
  z-index: 9;
`;

export const WrapperStatus = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  display: flex;
  width: 100%;
  padding: 1rem;
  min-height: 75px;
`;

export const StatusCircle = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid #fff;
  margin-right: 0.8rem;
  background-color: ${({ bgColor }) => bgColor || '#008894'};
  box-shadow: 0px 0px 5px rgb(0 0 0 / 20%);
`;

import React from 'react';

import { CardHorizontal } from './CardHorizontal';
import { CardVertical } from './CardVertical';

export function Card({ tipo, ...rest }) {
  if (tipo === 'horizontal') {
    return <CardHorizontal {...rest} />;
  }
  return <CardVertical {...rest} />;
}

import React from 'react';
import ReactSpeedometer from 'react-d3-speedometer';

const styles = {
  dial: {
    display: 'inline-block',
    width: `190px`,
    height: `auto`,
    color: '#000',
    border: '0.5px solid #fff',
    padding: '2px',
  },
  title: {
    fontSize: '2em',
    color: '#000',
  },
};

export const Speedometer = ({ id, value }) => {
  // Console.log(`Gráfico${title}`);
  const valor = `${value.toFixed(0)}%`;
  return (
    <div style={styles.dial}>
      <ReactSpeedometer
        maxValue={100}
        minValue={0}
        height={121}
        width={183}
        value={value}
        currentValueText={valor}
        ringWidth={20}
        // fontSize={0.8}
        valueTextFontSize={12}
        if={id}
        // needleTransition="easeQuadIn"
        needleTransitionDuration={1000}
        needleColor="black"
        startColor="#F85F4D"
        segments={10}
        endColor="#92CB08"
        labelFontSize="0.65rem"
      />

      {/* <div style={styles.title}>{title}</div> */}
    </div>
  );
};

import React from 'react';
import styled from 'styled-components/macro';

import { SeparatorHorizontal } from 'components/Elements';
import { ProgressBar } from 'components/ProgressBar';

import { Paragraph2 } from 'components/FinParagraph';
import { truncate } from 'helpers/string/String';

const Line = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Percentage = ({ data }) => {
  const percent = data.percentual * 100;
  return (
    <>
      <Line>
        <Paragraph2>
          {truncate(data.sacadoNome ? data.sacadoNome : data.papel, 28)}
        </Paragraph2>
        <Paragraph2>{`${percent.toFixed(2)}%`}</Paragraph2>
      </Line>
      <ProgressBar value={percent.toFixed(2)} />
      <SeparatorHorizontal hsize="0.5rem 0" />
    </>
  );
};

export default Percentage;

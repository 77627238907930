import React from 'react';

import { currency } from 'components/Formatter';
import { FinErrorBoundary } from 'components/FinErrorBoundary';
import { PriorityCard } from './PriorityCard';

import {
  CardsContainer,
  TotalCard,
  TotalValueContainer,
  TotalValue,
  TotalValueSub,
  SubsContainer,
  Subtitle,
  PriorityCardsContainer,
  PriorityTitle,
  PriorityCards,
} from './viewStyles';

export const AgendaCardsView = ({ cardsViewData }) => {
  return (
    <CardsContainer>
      <FinErrorBoundary>
        <TotalCard>
          <TotalValueContainer>
            <TotalValue>
              {currency(
                cardsViewData.graficoValoresTotaisAgenda.vlrTotalAgenda
              )}
            </TotalValue>
            <TotalValueSub>TOTAL DA AGENDA</TotalValueSub>
          </TotalValueContainer>
          <SubsContainer>
            <Subtitle>
              <span>DESÁGIO</span>
              <span>
                {' '}
                {currency(
                  cardsViewData.graficoValoresTotaisAgenda.vlrTotalSpread
                )}
              </span>
            </Subtitle>
            <Subtitle>
              <span>VOP MÊS ANTERIOR</span>
              <span>
                {' '}
                {currency(
                  cardsViewData.graficoValoresTotaisAgenda.vlrTotalMesAnterior
                )}
              </span>
            </Subtitle>
          </SubsContainer>
        </TotalCard>
        <PriorityCardsContainer>
          <PriorityTitle>Prioridades</PriorityTitle>
          <PriorityCards>
            {cardsViewData.graficoPrioridades.map((item) => {
              return (
                <React.Fragment key={item.idPrioridade}>
                  {item.idPrioridade === 0 && (
                    <PriorityCard
                      data={item}
                      backGroundColor={'#aaebba'}
                      priLvlColor={'#3b5c44'}
                      priLvlLabel={'0 (MÁXIMA)'}
                      iconcolor={'#63A974'}
                      maxPercent={20}
                      backGraphColor={'#8AC99A'}
                      fill={'#275933'}
                      limit={true}
                    />
                  )}
                  {item.idPrioridade === 1 && (
                    <PriorityCard
                      data={item}
                      backGroundColor={'#7fe8c1'}
                      priLvlColor={'#2F6C55'}
                      priLvlLabel={'1 (MUITO ALTA)'}
                      iconcolor={'#51B48F'}
                      maxPercent={20}
                      backGraphColor={'#60CFA6'}
                      fill={'#1F8D64'}
                      limit={true}
                    />
                  )}
                  {item.idPrioridade === 2 && (
                    <PriorityCard
                      data={item}
                      backGroundColor={'#f7eaa9'}
                      priLvlColor={'#BC841B'}
                      priLvlLabel={'2 (ALTA)'}
                      iconcolor={'#DEB66B'}
                      maxPercent={10}
                      backGraphColor={'#EDC985'}
                      fill={'#C38002'}
                      limit={true}
                    />
                  )}
                  {item.idPrioridade === 3 && (
                    <PriorityCard
                      data={item}
                      backGroundColor={'#edc985'}
                      priLvlColor={'#C38002'}
                      priLvlLabel={'3 (PADRÃO)'}
                      iconcolor={'#C38002'}
                      maxPercent={100}
                      backGraphColor={'#D9AC59'}
                      fill={'#9B6E19'}
                      limit={false}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </PriorityCards>
        </PriorityCardsContainer>
      </FinErrorBoundary>
    </CardsContainer>
  );
};

import React from 'react';
// import { useSelector } from 'react-redux';

// import { Wrapper } from 'components/FinWrappers';
import { icons } from 'assets/icones/finplaceIcons';

import { StringToSvg } from 'helpers/svgFiles/StringToSvg';
import { date } from 'components/FinFormatter';
import { currency } from 'helpers/format';

import { CustomWrapperImpeditivos, CustomParagrafo } from './Styles';

export const Impeditivos = ({ titulosImpeditivos, respImpeditivos }) => {
  // const apiCbboxState = useSelector((state) => state.apiCbbox.data[0]);
  let respImpeditivosValorIcones = 'ok';
  const ttImpeditivos = (titulosImpeditivos) => {
    switch (titulosImpeditivos) {
      case 'socios':
        return 'Alteração Societária';
      case 'ccf':
        return 'CCF';
      case 'liminar':
        return 'Liminar';
      case 'pefin':
        return 'Pefin';
      case 'refin':
        return 'Refin';
      default:
        return 'sem dados';
    }
  };

  const iconsImpeditivos = (titulos) => {
    switch (titulos) {
      case 'sem-informacao':
        return icons.cbboxSemInformacao;
      case 'alerta':
        return icons.cbboxAlerta2;
      case 'ok':
        return icons.cbboxCheck2;
      default:
        return icons.cbboxCheck2;
    }
  };

  let respImpeditivosValor = respImpeditivos;

  // console.log(respImpeditivosValor);

  if (
    respImpeditivos !== 'Nada Consta' &&
    respImpeditivos !== 'undefined' &&
    respImpeditivos !== undefined &&
    respImpeditivos !== null &&
    respImpeditivos !== 0
  ) {
    if (titulosImpeditivos === 'socios' && respImpeditivos !== 'undefined') {
      respImpeditivosValor = date(respImpeditivosValor);
      respImpeditivosValorIcones = 'alerta';
    }
    if (titulosImpeditivos === 'liminar' && respImpeditivos === false) {
      respImpeditivosValor = 'Nada Consta';
    } else if (titulosImpeditivos === 'liminar' && respImpeditivos === true) {
      respImpeditivosValor = 'Consta Liminiar';
      respImpeditivosValorIcones = 'alerta';
    }
    if (titulosImpeditivos === 'pefin' || titulosImpeditivos === 'refin') {
      respImpeditivosValor = currency(respImpeditivos);
      respImpeditivosValorIcones = 'alerta';
    } else if (titulosImpeditivos === 'liminar' && respImpeditivos === true) {
      respImpeditivosValor = 'Consta Liminiar';
      respImpeditivosValorIcones = 'alerta';
    }
    if (titulosImpeditivos === 'ccf') {
      respImpeditivosValor = respImpeditivos;
      respImpeditivosValorIcones = 'alerta';
    }
  } else if (respImpeditivos === null) {
    respImpeditivosValorIcones = 'sem-informacao';
    respImpeditivosValor = 'Sem Informação';
  } else {
    respImpeditivosValor = 'Nada Consta';
  }

  return (
    <CustomWrapperImpeditivos
      className={respImpeditivosValorIcones}
      pwidth="230px"
      padding="0px"
    >
      <span>
        <StringToSvg
          viewBox="0 0 55 40"
          width="49px"
          height="50px"
          strokeWidth="0"
        >
          {iconsImpeditivos(respImpeditivosValorIcones)}
        </StringToSvg>
      </span>
      <CustomParagrafo
        className="paragrafoImpeditivos"
        style={{ color: '#444444' }}
      >
        {ttImpeditivos(titulosImpeditivos)}
        <br />
        <strong>{respImpeditivosValor}</strong>
      </CustomParagrafo>
    </CustomWrapperImpeditivos>
  );
};

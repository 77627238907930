/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { Wrapper } from 'components/FinWrappers';
import { useSelector } from 'react-redux';
import { Title6 } from 'components/FinHeadings';

import { Paragraph2 } from 'components/FinParagraph';
import { OperacoesContext } from 'pages/Operacoes/provider';

import { useMutateSimular } from 'pages/Operacoes/mutates/useMutateSimular';

import { SkeletonGenerator } from 'helpers/SkeletonGenerator';

import { SeparatorHorizontal } from 'components/FinSeparador';

import {
  TipoAntecipacao,
  TipoEmprestimo,
  TipoCheque,
  TipoCobrancaSimples,
} from 'pages/Operacoes/Propostas/Dialog/';

import { TIPO_PREGAO } from 'pages/Operacoes/config';
import { DialogCuston } from './DialogCuston';

const Skeleton = () => {
  return <SkeletonGenerator isLoading={true} count={8} />;
};

const DialogEnviarProposta = () => {
  const [estado] = useContext(OperacoesContext);

  const simular = useMutateSimular();

  const uiDialogState = useSelector((state) => state.uiDialog);

  const {
    propostas: { nova },
  } = estado;

  const antecipacao = TIPO_PREGAO.antecipacao.includes(nova.tipoPregao);
  const emprestimo = TIPO_PREGAO.emprestimo.includes(nova.tipoPregao);
  const cheque = TIPO_PREGAO.cheque.includes(nova.tipoPregao);
  const cobrancaSimples = TIPO_PREGAO.cobrancaSimples.includes(nova.tipoPregao);

  useEffect(() => {
    simular.mutate();
  }, []);

  return (
    <DialogCuston largura="580px">
      <Wrapper
        padding="32px 0"
        justifyContent="flex-start"
        alignItems="center"
        pwidth="100%"
        tipo="column"
      >
        <Wrapper
          padding="0"
          pwidth="100%"
          justifyContent="flex-start"
          tipo="column"
          alignItems="flex-start"
        >
          {simular.isLoading ? (
            <>
              <SkeletonGenerator isLoading={true} count={2} />
              <SeparatorHorizontal ppadding="1rem 0" />
            </>
          ) : (
            <>
              <Paragraph2>{uiDialogState.text}</Paragraph2>
              <Title6 style={{ fontWeight: 'bold' }}>{nova.titulo}</Title6>
            </>
          )}

          {simular.isLoading && <Skeleton />}

          {antecipacao && !simular.isLoading && <TipoAntecipacao data={nova} />}
          {cobrancaSimples && !simular.isLoading && (
            <TipoCobrancaSimples data={nova} />
          )}
          {emprestimo && !simular.isLoading && <TipoEmprestimo data={nova} />}
          {cheque && !simular.isLoading && <TipoCheque data={nova} />}
        </Wrapper>
      </Wrapper>
    </DialogCuston>
  );
};

export default DialogEnviarProposta;

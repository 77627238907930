import React, { useContext, useState, useEffect } from 'react';

import { ModalCuston } from 'components/FinModalCuston';
import { Title5 } from 'components/FinHeadings';
import {
  SeparatorVertical,
  SeparatorHorizontal,
} from 'components/FinSeparador';
import { FinInputTextArea } from 'components/FinInput/MainInput';

import Button from 'components/FinButton';
import { Wrapper } from 'components/FinWrappers';
import { Container, IconeMessageStyle, WrapperFields } from './style.bordero';
import { OperacoesContext } from '../provider';
import { HeaderStyle } from '../Propostas/style';

import { useSelectProposta } from '../hooks/useSelectProposta';

const Head = ({ rules }) => {
  return (
    <HeaderStyle>
      <IconeMessageStyle width={24} height={24} />
      <SeparatorVertical pwidth="0.4rem" />
      <Title5 style={{ fontWeight: 'bold', margin: '0' }}>
        {`Comentário ${rules.indexOf('view') !== -1 ? '' : ' (Editar)'}`}
      </Title5>
    </HeaderStyle>
  );
};

export const ModalComentario = () => {
  const [observacao, setObservacao] = useState('');
  const [state, actions] = useContext(OperacoesContext);

  const {
    modal: { values },
  } = state;

  const { proposta } = useSelectProposta({ idProposta: values.idProposta });

  const onSubmit = () => {
    actions.updateNovaProposta([{ field: 'observacao', value: observacao }]);
    actions.removeModalActive();
  };

  const onChange = (event) => {
    setObservacao(event.target.value);
  };

  useEffect(() => {
    setObservacao(proposta.observacao);
  }, [proposta]);

  return (
    <ModalCuston
      open={true}
      width="693px"
      title=""
      scrolling="true"
      onClose={() => actions.removeModalActive()}
      header={<Head rules={values.rules} />}
      ppadding="0"
      margin="5vh 0 10vh 0"
    >
      <Container style={{ minHeight: '336px' }}>
        <WrapperFields>
          <FinInputTextArea
            label={
              values.rules.indexOf('edit') !== -1
                ? `Digite seu comentário`
                : `Comentário`
            }
            value={observacao}
            name="observacao-input"
            onChange={(e) => onChange(e)}
            largura="100%"
            altura="116px"
            disabled={values.rules.indexOf('view') !== -1}
          />
          <SeparatorHorizontal ppadding="1rem 0" />
          {values.rules.indexOf('edit') !== -1 && (
            <>
              <SeparatorHorizontal ppadding="0.2rem 0" />
              <Wrapper padding="0" pwidth="100%" justifyContent="flex-end">
                <Wrapper padding="0" pmargin="0 0.5rem 0 0" pwidth="100px">
                  <Button
                    largura="total"
                    preenchimento="outline"
                    onClick={() => actions.removeModalActive()}
                    rounded
                  >
                    Cancelar
                  </Button>
                </Wrapper>
                <Wrapper padding="0" pwidth="100px">
                  <Button largura="total" onClick={() => onSubmit()} rounded>
                    Salvar
                  </Button>
                </Wrapper>
              </Wrapper>
            </>
          )}
        </WrapperFields>
      </Container>
    </ModalCuston>
  );
};

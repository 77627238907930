/* eslint-disable no-console */
import React, { createContext } from 'react';

import { useFinMatch } from 'pages/FinCredit/hooks';

import { useParams } from 'react-router-dom';
import { Match } from './Match';

export const FinMatchContext = createContext();

export function PageFinmatch() {
  const { idGrupo } = useParams();

  const matchData = useFinMatch({ idGrupo });
  return (
    <FinMatchContext.Provider value={matchData}>
      <Match />
    </FinMatchContext.Provider>
  );
}

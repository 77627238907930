import React, { useContext } from 'react';

import ReactHtmlParser from 'react-html-parser';
import { Card } from '../Comps/Card';
import { RegionHeader } from './RegionHeader';
import { RegionSpeedometer } from './RegionSpeedometer';
import { RegionIndicator } from './RegionIndicator';
import { RegionMarketData } from './RegionMarketData';
import { FinMatchContext } from '../PageFinmatch';
import { Message } from './styles.match';

import { Loader } from '../Comps/Loader';

export function Match() {
  const { data, isLoading } = useContext(FinMatchContext);

  return (
    <Card
      largura="852px"
      direction="column"
      style={{ padding: '2rem 2.938rem', margin: '4rem 0px 4rem' }}
    >
      {isLoading && <Loader />}
      {data && data.mensagemLog && (
        <div style={{ minHeight: '500px' }}>
          <Message>{ReactHtmlParser(data.mensagem)}</Message>
        </div>
      )}
      {!isLoading && data && !data.mensagemLog && (
        <>
          <RegionHeader />
          <RegionSpeedometer />
          <RegionIndicator />
          <RegionMarketData />
        </>
      )}
    </Card>
  );
}

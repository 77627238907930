import React from 'react';
import { Bullet } from 'components/FinFrame';

import {
  TagHabilitado,
  TagHabilitacaoPendente,
  TagRecusado,
  TagBloqueado,
} from 'components/FinTag';

const TagActive = ({ status, id }) => {
  if (status === 'pendente')
    return (
      <>
        <Bullet />
        <TagHabilitacaoPendente id={id} />
      </>
    );

  if (status === 'recusado')
    return (
      <>
        <Bullet />
        <TagRecusado id={id} />
      </>
    );

  if (status === 'habilitado')
    return (
      <>
        <Bullet />
        <TagHabilitado id={id} />
      </>
    );

  if (status === 'bloqueado')
    return (
      <>
        <Bullet />
        <TagBloqueado id={id} />
      </>
    );

  return null;
};

export default TagActive;

import { format } from 'date-fns';

const INITIAL_STATE = {
  data: [],
  isLoading: false,
  error: false,
  acType: null,
  dataBordero: [],
  reload: null,
  dataFinal: [],
  isLoadingFinal: false,
  errorFinal: false,
  reasonCancel: '',
  dataFiltered: [],
  dataFilterdLoad: null,
  dataFilterdError: false,
  dataFilteredFinBox: [],
  dataFilterdLoadFinBox: null,
  dataFilterdErrorFinBox: false,
  dataGeralBusca: format(new Date(), 'yyyy-MM-dd'),
  dataListPoc: [],
  listPocLoading: false,
  erroListPoc: false,
  dataOneOper: {},
  dataOneOperIsloading: false,
  dataOneOperError: false,
};

const operacoes = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'DATA_OPERACOES_DEFAULT':
      return {
        ...state,
        isLoading: false,
      };
    case 'LOAD_DATA_OPERACOES_REQUEST':
      //console.log(action);
      return {
        ...state,
        isLoading: true,
        acType: true,
      };
    case 'LOAD_DATA_OPERACOES_SUCCESS':
      //console.log(action);
      return {
        ...state,
        isLoading: false,
        data: action.data,
        acType: false,
        desisteModal: false,
      };
    case 'LOAD_DATA_OPERACOES_FAILURE':
      return {
        ...state,
        data: action.data,
        isLoading: false,
        error: true,
        acType: false,
      };
    //*************************************** */
    case 'SET_DEFAULT_DATA_OPERACOES':
      return {
        ...state,
        data: [],
      };
    case 'SET_RELOAD_OPERACOES':
      //console.log(action);
      return {
        ...state,
        reload: action.reload,
      };
    //*************************************** */
    case 'DOWNLOAD_OPERACOES_REQUEST':
      return {
        ...state,
        isLoading: false,
        acType: true,
      };
    case 'DOWNLOAD_OPERACOES_SUCCESS':
      //console.log(action);
      return {
        ...state,
        isLoading: false,
        acType: true,
        dataBordero: action.data,
      };
    case 'DOWNLOAD_OPERACOES_FAILURE':
      return {
        ...state,
        error: true,
        isLoading: false,
        acType: false,
      };
    //*************************************** */
    case 'DESISTIR_OPERACOES_REQUEST':
      //console.log(action);
      return {
        ...state,
        isLoading: false,
        acType: true,
      };
    case 'DESISTIR_OPERACOES_SUCCESS':
      //console.log(action);
      return {
        ...state,
        isLoading: false,
        acType: true,
      };
    case 'DESISTIR_OPERACOES_FAILURE':
      //console.log(action);
      return {
        ...state,
        isLoading: false,
        error: true,
        acType: false,
      };
    //*************************************** */
    case 'LOAD_DATA_FINALIZADOS_REQUEST':
      //console.log(action);
      return {
        ...state,
        isLoadingFinal: true,
      };
    case 'LOAD_DATA_FINALIZADOS_SUCCESS':
      //console.log(action);
      return {
        ...state,
        isLoadingFinal: false,
        dataFinal: action.data,
      };
    case 'LOAD_DATA_FINALIZADOS_FAILURE':
      //console.log(action);
      return {
        ...state,
        isLoadingFinal: false,
        errorFinal: true,
      };
    //*************************************** */
    case 'SET_DATA_REASON_CANCEl':
      //console.log(action);
      return {
        ...state,
        reasonCancel: action.data,
      };
    case 'SET_FILTERED_DATA_LISTS_REQUEST':
      //console.log(action);
      return {
        ...state,
        dataFilterdLoad: true,
      };
    case 'SET_FILTERED_DATA_LISTS_SUCESS':
      //console.log(action);
      return {
        ...state,
        dataFiltered: action.data,
        dataFilterdLoad: false,
      };
    case 'SET_FILTERED_DATA_LISTS_ERROR':
      //console.log(action);
      return {
        ...state,
        dataFilterdError: action.data,
        dataFilterdLoad: false,
      };
    case 'SET_FILTERED_DATA_DEFAULT':
      //console.log(action);
      return {
        ...state,
        dataFilterdLoad: null,
      };
    case 'SET_DATA_MAIN_SEARCH':
      //console.log(action);
      return {
        ...state,
        dataGeralBusca: action.data,
      };
    //***********************FINBOX********************* */
    case 'SET_FILTERED_DATA_FINBOX_REQUEST':
      //console.log(action);
      return {
        ...state,
        dataFilterdLoadFinBox: true,
      };
    case 'SET_FILTERED_DATA_FINBOX_SUCESS':
      //console.log(action);
      return {
        ...state,
        dataFilteredFinBox: action.data,
        dataFilterdLoadFinBox: false,
      };
    case 'SET_FILTERED_DATA_FINBOX_ERROR':
      //console.log(action);
      return {
        ...state,
        dataFilterdErrorFinBox: action.data,
        dataFilterdLoadFinBox: false,
      };
    case 'SET_FILTERED_DATA_FINBOX_CLEAR':
      //console.log(action);
      return {
        ...state,
        dataFilteredFinBox: [],
      };
    //*************************************** */
    case 'LOAD_LIST_POC_DOC_REQUEST':
      //console.log(action);
      return {
        ...state,
        listPocLoading: true,
      };
    case 'LOAD_LIST_POC_DOC_SUCCESS':
      //console.log(action);
      return {
        ...state,
        listPocLoading: false,
        dataListPoc: action.data,
      };
    case 'LOAD_LIST_POC_DOC_FAILURE':
      //console.log(action);
      return {
        ...state,
        listPocLoading: false,
        dataListPoc: action.data,
        erroListPoc: true,
      };
    //*************************************** */
    case 'GET_OPERATION_REQUEST':
      //console.log(action);
      return {
        ...state,
        dataOneOperIsloading: true,
      };
    case 'GET_OPERATION_SUCCESS':
      //console.log(action);
      return {
        ...state,
        dataOneOperIsloading: false,
        dataOneOper: action.data,
        dataOneOperError: false,
      };
    case 'GET_OPERATION_FAILURE':
      //console.log(action);
      return {
        ...state,
        dataOneOperIsloading: false,
        dataOneOperError: true,
      };
    default:
      return state;
  }
};

export default operacoes;

/* eslint-disable no-unused-vars */
import { useQuery } from 'react-query';
import axios from 'axios';

import CONFIG from 'config';
import { getUserData } from 'services/getUserData';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/`;

const apiAprovacaoCreditoHistorico = async (params) => {
  const { idEmpresa } = await getUserData();

  const response = axios.get(
    `${BASE_URL}api/aprovacao-credito/historico?idConvenio=${idEmpresa}&idGrupo=${params.idGrupo}`
  );
  const result = await response;
  return result.data ? result.data.data : [];
};

export const useQueryAprovacaoHistorico = (params) => {
  return useQuery(
    ['fluxo', 'credito', 'historico', params],
    () => apiAprovacaoCreditoHistorico(params),
    {
      keepPreviousData: false,
    }
  );
};

/* eslint-disable no-unused-vars */
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import CONFIG from 'config';
import toast from 'react-hot-toast';
import { saveAs } from 'file-saver';
import { handleErrors } from 'helpers/requisicoes/handleErrors';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const download = async (params) => {
  const axiosOptions = {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const mutateData = axios.get(
    `${BASE_URL}/bordero/download/${params.idOperacao}`,
    axiosOptions
  );

  toast.promise(mutateData, {
    loading: 'Loading',
    success: 'Download com sucesso',
    error: (error) => {
      const msg = handleErrors(error);
      return `${msg}`;
    },
  });

  const result = await mutateData;
  const { data } = result;

  let filename = '';
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(result.headers['content-disposition']);
  if (matches != null && matches[1]) {
    filename = matches[1].replace(/['"]/g, '');
  }

  const blob = new Blob([data], {
    type: 'application/octet-stream',
  });

  saveAs(blob, filename);

  return data || [];
};

export const useMutateBorderoDownload = () => {
  const queryClient = useQueryClient();
  return useMutation((params) => download(params), {
    onMutate: async (params) => {
      await queryClient.cancelQueries(['operacoes']);

      return { params };
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('operacoes');
    },
  });
};

import React, { useState, useRef } from 'react';
import { useIntersection } from 'hooks/useIntersection';

import styled, { css } from 'styled-components/macro';

const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;

  object-fit: ${({ objfit }) => objfit || `contain`};
  object-position: 50% 50%;
  border-style: none;

  opacity: 0;

  ${({ thumb, isLoaded }) =>
    thumb &&
    css`
      opacity: 1;
      filter: blur(10px);
      transition: opacity 1s ease-in-out;
      position: absolute;
      /* ${isLoaded &&
      css`
        opacity: 0;
      `} */
    `}

  ${({ isLoaded }) =>
    isLoaded &&
    css`
      transition: opacity 1s ease-in-out;
      opacity: 1;
    `}

    ${({ thumb, bgHide }) =>
    thumb &&
    bgHide &&
    css`
      opacity: 0;
    `}
`;

export const ImageRenderer = ({ url, thumb, objfit, imgStart }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const imgRef = useRef();
  useIntersection(imgRef, () => {
    setIsInView(true);
  });

  const handleOnLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div ref={imgRef}>
      {isInView && (
        <>
          <Image
            isLoaded={!!isLoaded}
            thumb
            src={thumb}
            objfit="cover"
            alt="Fallback image"
          />
          <Image
            isLoaded={!!isLoaded}
            src={url}
            onLoad={handleOnLoad}
            objfit={objfit}
            alt="Full image"
          />
          {imgStart && (
            <Image thumb bgHide={!!isLoaded} src={imgStart} objfit="fill" />
          )}
        </>
      )}
    </div>
  );
};

import React from 'react';

import Recebidos from './Recebidos';
import Enviados from './Enviados';

const Content = ({ tabActive }) => {
  switch (tabActive) {
    case 'recebidos':
      return <Recebidos tabActive={tabActive} />;
    case 'enviados':
      return <Enviados tabActive={tabActive} />;
    default:
      return null;
  }
};

export default Content;

/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { createActions, createReducer } from 'reduxsauce';
import { call, put, take, fork, cancel, cancelled } from 'redux-saga/effects';
import axios, { CancelToken } from 'axios';

import { getUserData } from 'services/getUserData';
import { Creators as uiToasterActions } from 'store/ducks/uiToaster';

import { Creators as ActionsAderencia } from 'store/ducks/apiMatchObtemAderencia';

import CONFIG from '../../config';

/**
 * Criando action types & creators
 */

export const { Types, Creators } = createActions({
  apiMATCHCADASTRORequest: ['valores'],
  apiMATCHCADASTROSuccess: ['data'],
  apiMATCHCADASTROError: [],
  apiMATCHCADASTROCancel: [],
});

/**
 * Criando os reducer handlers
 */

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: false,
};

const apiListRequest = (state = INITIAL_STATE, action) => {
  // console.log(action);
  return {
    isLoading: true,
    data: [],
    error: false,
  };
};

const apiListSuccess = (state = INITIAL_STATE, action) => {
  // console.log(action);
  return {
    isLoading: false,
    data: [action.data],
    error: false,
  };
};

const apiListError = (state = INITIAL_STATE, action) => {
  // console.log(action);
  return {
    isLoading: false,
    data: [],
    error: true,
  };
};

const apiListCancel = (state = INITIAL_STATE, action) => {
  // console.log(action);
  return {
    isLoading: false,
    data: [],
    error: false,
  };
};

/**
 * Criando reducer
 */

export default createReducer(INITIAL_STATE, {
  [Types.API_MATCHCADASTRO_REQUEST]: apiListRequest,
  [Types.API_MATCHCADASTRO_SUCCESS]: apiListSuccess,
  [Types.API_MATCHCADASTRO_ERROR]: apiListError,
  [Types.API_MATCHCADASTRO_CANCEL]: apiListCancel,
});

/**
 * Sagas
 */

function* sagaGetAsynResult(params, endpoint) {
  const source = CancelToken.source();

  const url = `${CONFIG.API_REMODELING_URL}${endpoint}`;

  try {
    const response = yield axios.post(url, params, {
      // cancelToken: source.token,
    });

    yield put(Creators.apiMATCHCADASTROSuccess(response.data));
    yield put(ActionsAderencia.apiMATCHADERENCIARequest());
  } catch (error) {
    yield put(uiToasterActions.uiToasterMessage(error, 'error'));
    yield put(Creators.apiMATCHCADASTROError());
  } finally {
    if (yield cancelled()) {
      console.log('cancel');
      source.cancel();
    }
  }
}

export function* sagaMATCHCADASTRO(action) {
  // if (user) {
  // const params2 = JSON.stringify(action.valores);
  const params = action.valores;
  // params = params.replace('"', '');

  // console.log('params');
  // console.log(params);

  console.log('params2');
  console.log(params);

  if (params) {
    const task = yield fork(
      sagaGetAsynResult,
      params,
      `/business-api/api/finmatch/incluir-filtro-finmatch`
    );

    yield take(Types.API_MATCHCADASTRO_CANCEL);
    yield cancel(task);
  } else {
    yield put(Creators.apiMATCHCADASTROError());
  }
  // }
}

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { useHistory } from 'react-router-dom';

import { device } from 'devices';
import { flexUnit } from 'mixins';
import { Wrapper } from 'components/FinWrappers';
import { StringToSvg } from 'helpers/svgFiles/StringToSvg';
import StatusButton from 'components/FinStatusButton';
import { icons } from 'assets/icones/finplaceIcons';
import feather from 'feather-icons/dist/icons.json';
import ButtonHamburguer from 'components/FinSideBarMenu/ButtonHamburguer';
import Counter from 'components/FinCounter';
import { SeparadorVertical } from 'components/FinSeparador';
import { finTheme } from 'finTheme';

import { useQueryInvitesNotifyCount } from 'pages/MinhaRede/hook/useQueryInvites';

import { MenuUser } from './MenuUser';

const CounterWrapper = styled(Wrapper)`
  position: absolute;
  bottom: -4px;
  left: -30px;
  padding: 0;
  z-index: 9;
`;

const CustonWrapper = styled(Wrapper)`
  width: 100%;
  align-items: flex-start;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.newtheme.zIndex.header};
`;

const ImageLogo = styled(Wrapper)`
  padding: 0 1rem;
  width: 179px;
  height: 45px;
  cursor: pointer;
`;

const Head = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  padding: 12px;
  justify-content: space-between;
  background-color: ${({ bgcolor }) => bgcolor};
  box-shadow: ${({ theme }) => theme.newtheme.dropShadow.dropShadow2};
  ${flexUnit(5, 12, 16, 'vw', 'font-size')}

  & div {
    button:first-child {
      margin-right: 32px;
    }
  }

  @media ${device.laptop} {
    margin-bottom: 40px;
  }

  @media ${device.desktop} {
    margin-bottom: 50px;
  }
`;

const Header = ({ bgcolor, openMenu, usuario }) => {
  const { data: dados } = useQueryInvitesNotifyCount();

  const history = useHistory();

  const toHome = () => history.push('/');

  return (
    <CustonWrapper>
      <ButtonHamburguer onClick={openMenu} />
      <Head bgcolor={bgcolor}>
        <ImageLogo onClick={toHome}>
          <StringToSvg
            viewBox="0 0 179 45"
            width="80%"
            height="80%"
            fill={finTheme.newtheme.color.azulPrimario}
            stroke="none"
          >
            {icons.finplace2}
          </StringToSvg>
        </ImageLogo>

        <Wrapper padding="0">
          <Wrapper pposition="relative" padding="0">
            <CounterWrapper>
              {!!dados && dados.totalRecords > 0 && (
                <Counter pbgcolor="red" pcolor={finTheme.newtheme.color.branco}>
                  {dados.totalRecords}
                </Counter>
              )}
            </CounterWrapper>
            <StatusButton
              pbgSize="30px"
              pwidth="28px"
              pheight="28px"
              icon={feather['user-plus']}
              pbgPosition="3px 3px"
              viewBox="0 0 32 32"
              onClick={() => history.push('/minha-rede/convites')}
              tooltipText="Convites"
              id="infoConvites"
            />
          </Wrapper>

          <SeparadorVertical />
          <MenuUser data={usuario} />

          <SeparadorVertical />
        </Wrapper>
      </Head>
    </CustonWrapper>
  );
};

Header.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  bgcolor: PropTypes.string,
};

Header.defaultProps = {
  name: 'Richard S. Vignelli.',
  bgcolor: 'transparent',
};

export default Header;

import React from 'react';
import Radio from './Radio';
import InitialLetters from '../InitialLetters';
import { Theme } from '../../theme';

import {
  //Container,
  Title,
  WrapperProfiler,
  //PicProfiler,
  //User,
  LettersIn,
  Wrapper,
  WrapperRow,
  Label,
  //TextValue,
  SeparatorVertical,
  //LabelText,
  //Loading,
  //LoadingBar,
  //BtnIcoAction,
  //Send,
  //Check,
  //Trash2,
  //Tag,
  //Input,
  //Search,
  //SeparatorHorizontal,
} from '../Elements';

const ItemCedente = props => {
  return (
    <WrapperRow
      style={{ borderBottom: '1px solid ' + Theme.gray2, minHeight: '8vh' }}
    >
      <SeparatorVertical wsize="1rem" />
      <Radio action={props.action} id={props.data.idCedente} />
      <SeparatorVertical wsize="1rem" />
      <WrapperProfiler valign="center">
        <LettersIn
          bgcolor={'white'}
          color={Theme.primary}
          style={{
            width: '2.5rem',
            height: '2.5rem',
            fontWeight: '400',
            boxShadow: '0px 3px 7px -1px rgba(0,0,0,0.23)',
          }}
        >
          <InitialLetters letters={props.data.nome} />
        </LettersIn>
      </WrapperProfiler>
      <SeparatorVertical wsize="1rem" />
      <Wrapper>
        <Title mgright="0" lwidth="270px" style={{ fontSize: '0.9rem' }}>
          {props.data.nome}
        </Title>
        <Label
          size="1rem"
          hAlign="left"
          style={{ fontSize: '0.9rem', lineHeight: '1rem' }}
        >
          {props.data.cnpj}
        </Label>
      </Wrapper>
      <SeparatorVertical wsize="2rem" />
    </WrapperRow>
  );
};

export default ItemCedente;

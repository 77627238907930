import axios from 'axios';
import { useQuery } from 'react-query';
import CONFIG from 'config';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/`;

const apiVideo = async (params) => {
  const fetchData = axios.get(
    `${BASE_URL}api/matching/convenio/perfil/empreendedores`,
    params
  );

  const result = await fetchData;
  const { data } = result;

  return data.data ? data.data : [];
};

export const useVideo = (params) => {
  return useQuery(['video', params], () => apiVideo(params), {
    keepPreviousData: false,
  });
};

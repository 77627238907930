import styled, { css } from 'styled-components/macro';

export const Title = styled.span`
  color: var(--fingray-900);
  font-size: 1rem;
  font-weight: 600;
  text-rendering: geometricprecision;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  line-height: 44.2px;
`;

export const Text = styled.span`
  color: var(--fingray-900);
  font-size: 1rem;
  font-weight: 400;
  text-rendering: geometricprecision;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  line-height: 19.2px;
`;

export const Flex = styled.div`
  display: flex;
  ${({ row }) =>
    row &&
    css`
      flex-direction: row;
    `}

  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
    `}

  justify-content: ${({ justify }) => justify || `center`};
  align-items: ${({ align }) => align || `center`};
  width: 100%;
`;

export const Accordion = styled(Flex)`
  flex-direction: column;
  height: 0;
  display: none;
  transition: height 0.3s easy-in-out;
  margin-bottom: 1rem;
  ${({ open }) =>
    open &&
    css`
      display: flex;
      height: 100%;
    `}
`;

/* eslint-disable no-console */
import CONFIG from 'config';

export const handleErrors = (error) => {
  let message = 'Erro inesperado!';

  const { IS_CLIENT_ROOT } = CONFIG;
  const isDevelopment = IS_CLIENT_ROOT === 'http://localhost:3000/';

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx

    if (isDevelopment) {
      console.log('[ERROR-RESPONSE]', 'data :', error.response.data);
      console.log('[ERROR-RESPONSE]', 'status :', error.response.data);
      console.log('[ERROR-RESPONSE]', 'headers :', error.response.data);
    }

    const { data, status } = error.response;

    if (Number(status) === 400) {
      if (data.message) message = `${data.message}`;
      return message;
    }
    return `${status}: ${message}`;
  }
  if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    if (isDevelopment) {
      console.log('[ERROR-REQUEST]', error.request);
    }
    return `req: ${message}`;
  }
  // Something happened in setting up the request that triggered an Error
  if (isDevelopment) {
    console.log('[ERROR-CONFIG-REQUEST]', error.message);
    console.log('[ERROR]', error.config);
  }
  return !error.request ? `sett-req: ${message}` : message;
};

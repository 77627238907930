import React, { useState } from 'react';
import { connect } from 'react-redux';
import { dateTimeSeconds } from 'components/Formatter';
import {
  downloadDocRequest,
  addItemListDownload,
  removeItemListDownload,
  clearAllItemsListDownload,
} from 'store/old_actions/actions';

import {
  WrapperRow,
  SeparatorHorizontal,
  SeparatorVertical,
  Label,
} from 'components/Elements';
import { Theme } from 'theme';

import CheckboxComp from 'components/CheckboxComp';

const DocList = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [url, setUrl] = useState(props.data.link);
  // eslint-disable-next-line no-unused-vars
  const [idDoc, setIdDoc] = useState(props.data.idDocumento);

  const verificaSelecionado = (idDocumento) => {
    const indiceTitulo = props.data.filter(
      (res) => res.idDocumento === idDocumento
    );
    return indiceTitulo.length;
  };

  const adicionarDocs = (idDocumento, link) => {
    const indiceTitulo = props.data.filter(
      (res) => res.idDocumento === idDocumento
    );

    if (indiceTitulo.length > 0) {
      props.removeItems(idDocumento);
    } else {
      props.addItem({ idDocumento, url: link });
    }
  };

  return (
    <div>
      <WrapperRow lwidth="100%">
        <WrapperRow>
          <CheckboxComp
            checked={verificaSelecionado(props.idDocumento) > 0}
            aoSelecionar={() => adicionarDocs(props.idDocumento, props.link)}
            name="titulo"
            value={`${props.idDocumento}`}
            rounded="active"
          />
        </WrapperRow>
        <WrapperRow lwidth="260px" justifiy="flex-start">
          <Label fsize="14px" color="gray">
            {props.doc}
          </Label>
        </WrapperRow>
        <SeparatorVertical wsize="1rem" />
        <WrapperRow lwidth="260px" justifiy="flex-start">
          <Label fsize="14px" color="gray">
            {dateTimeSeconds(props.firstUpload)}
          </Label>
        </WrapperRow>
        <SeparatorVertical wsize="1rem" />
        <WrapperRow lwidth="260px" justifiy="flex-start">
          <Label fsize="14px" color="gray">
            {!props.lastDownload
              ? '---------------'
              : dateTimeSeconds(props.lastDownload)}
          </Label>
        </WrapperRow>
      </WrapperRow>
      <SeparatorHorizontal hsize="0.5rem 0" borderColor={Theme.gray1} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.listDownload.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    downloadDocs: (idDocumento, url) =>
      dispatch(downloadDocRequest(idDocumento, url)),
    addItem: (value) => dispatch(addItemListDownload(value)),
    removeItems: (value) => dispatch(removeItemListDownload(value)),
    clearItems: () => dispatch(clearAllItemsListDownload()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocList);

import React from 'react';

import { ReactComponent as IconeTrophy } from 'assets/images/icon-trophy-c.svg';
import { ReactComponent as IconeHandShake } from 'assets/images/icon-hand-shake-c.svg';
import { ReactComponent as IconeDollar } from 'assets/images/icon-money-dollar-c.svg';

import { Wrapper } from 'components/FinWrappers';
import { currency } from 'components/Formatter';
import {
  SeparatorHorizontal,
  SeparatorVertical,
} from 'components/FinSeparador';
import {
  CardPrecoStyle,
  Circle,
  Sifrao,
  FirstPartMoney,
  SecondPartMoney,
  Line,
  CardStyle,
  FaixaIsento,
  Text,
} from './style';

const renderMoney = (value, color) => {
  const values = currency(value).replace(/\s/g, '');
  const strings = values.split('R$');
  const money = strings[1].toString().split(/,/g);
  return (
    <>
      <Sifrao color={color}>R$</Sifrao>
      <FirstPartMoney color={color}>{`${money[0]}`}</FirstPartMoney>
      <SecondPartMoney color={color}>{`,${money[1]}`}</SecondPartMoney>
    </>
  );
};

const ExibePreco = ({ data }) => {
  return (
    <>
      <Wrapper
        padding="0"
        justifyContent="center"
        alignItems="center"
        pwidth="100%"
        style={{ position: 'relative' }}
      >
        {data.fl_isencao && <FaixaIsento />}
        <CardPrecoStyle>
          <Circle>
            <IconeHandShake />
          </Circle>
          <SeparatorHorizontal ppadding="0.6rem 0" />
          <CardStyle>Tarifa de Participação</CardStyle>
          <SeparatorHorizontal ppadding="0.8rem 0" />
          <Line>{renderMoney(data.download, '#008894')}</Line>
        </CardPrecoStyle>
        <SeparatorVertical pwidth="0.6rem" />
        <CardPrecoStyle>
          <Circle>
            <IconeTrophy />
          </Circle>
          <SeparatorHorizontal ppadding="0.6rem 0" />
          <CardStyle>Tarifa de Proposta Vencedora</CardStyle>
          <SeparatorHorizontal ppadding="0.8rem 0" />
          <Line>{renderMoney(data.vencedor, '#FFCA00')}</Line>
        </CardPrecoStyle>
        <SeparatorVertical pwidth="0.6rem" />
        <CardPrecoStyle>
          <Circle>
            <IconeDollar />
          </Circle>
          <SeparatorHorizontal ppadding="0.6rem 0" />
          <CardStyle>Cashback de Proposta Vencedora</CardStyle>
          <SeparatorHorizontal ppadding="0.8rem 0" />
          <Line>{renderMoney(data.cashback, '#00DBD5')}</Line>
        </CardPrecoStyle>
      </Wrapper>
      {data.fl_isencao && <Text>Isenção de tarifa nesta operação</Text>}
    </>
  );
};

export { ExibePreco };

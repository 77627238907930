import React from 'react';
import { ResponsivePie } from '@nivo/pie';

const GraficoPizzaMatch = (data) => {
  const dataEmpresasMatch = [
    {
      name: 'Deram Match',
      value: data.data.empresasMatchPorcentagem / 100,
      cores: '#008894',
      fontes: '#ffffff',
    },
    {
      name: 'Empresas Livres',
      value: data.data.totalEmpresaPorcentagem / 100,
      cores: '#DFFAF9',
      fontes: '#000000',
    },
  ];

  return (
    <ResponsivePie
      data={dataEmpresasMatch}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      fit={false}
      // activeOuterRadiusOffset={8}
      colors={{ datum: 'data.cores' }}
      enableArcLinkLabels={false}
      isInteractive={false}
      arcLinkLabelsSkipAngle={10}
      valueFormat=" >-.0%"
      // arcLabelsTextColor="#ffffff,#008894"
      arcLabelsTextColor={{ from: 'data.fontes' }}
      // arcLinkLabelsTextColor="red"
      // arcLinkLabelsThickness={2}
      // arcLinkLabelsColor={{ from: data.cores }}
      arcLabelsRadiusOffset={0.5}
      legends={[]}
    />
  );
};

export default GraficoPizzaMatch;

/* eslint-disable camelcase */
import React, { useEffect, useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Wrapper } from 'components/FinWrappers';
import { icons } from 'assets/icones/finplaceIcons';
import { StringToSvg } from 'helpers/svgFiles/StringToSvg';
import { useSelector, useDispatch } from 'react-redux';
import { useFeedback } from 'hooks/useFeedback';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { Creators as apiMATCHADERENCIAAction } from 'store/ducks/apiMatchObtemAderencia';
import { ModalCuston } from 'components/FinModalCuston';
// import { SeparatorHorizontal } from 'components/FinSeparador';
import ModalConteutoExperimente from './ModalConteutoExperimente';

import { Theme } from '../../../theme';
import {
  Content,
  BtnExperimentar,
  BtnConfigurarMatch,
  BlocoDashboardDestaques,
  FullContainerLoad,
  AreaAcao,
  AreaImpressao,
  LogoImpressao,
} from '../Styles';

import Graficos from './Graficos';

// import // SeparatorVertical,
// // SeparatorHorizontal,
// '../../../components/Elements';
import { SeparatorVertical } from '../../../components/Elements';

const DashboardMatching = ({ acaoConfigurarMatch }) => {
  const apiMATCHADERENCIAState = useSelector(
    (state) => state.apiMATCHADERENCIA
  );
  const [modalExperimente, setModalExperimente] = useState(false);
  const [verCarregandoMatch, setVerCarregandoMatch] = useState(true);
  const dispatch = useDispatch((state) => state.router.location.query);
  const { ready, waiting, error, empty } = useFeedback(apiMATCHADERENCIAState);

  const valorEmpresaMatchPorcentagem = (valor) => {
    let valorTrocado = valor.toString();
    valorTrocado = valorTrocado.replace('.', ',');
    return valorTrocado;
  };

  const abreModal = () => {
    setModalExperimente(!modalExperimente);
  };
  // const rolagemConfMatch = () => {
  //   document
  //     .getElementById('areaRolegem')
  //     .scrollTo({ top: 1000, left: 0, behavior: 'smooth' });
  // };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    dispatch(apiMATCHADERENCIAAction.apiMATCHADERENCIARequest());
    setVerCarregandoMatch(false);
  }, [dispatch]);

  return (
    <>
      {verCarregandoMatch && (
        <FullContainerLoad>
          <ScaleLoader css="" size={150} color={Theme.primary} loading={true} />
        </FullContainerLoad>
      )}
      {empty && <p>{empty}</p>}
      {error && <p>{error}</p>}
      {waiting && (
        <FullContainerLoad>
          <ScaleLoader css="" size={150} color={Theme.primary} loading={true} />
        </FullContainerLoad>
      )}
      {ready && (
        <AreaImpressao className="areaImpressaoPaisagem" ref={componentRef}>
          <LogoImpressao>
            <>
              <StringToSvg
                strokeWidth="0"
                viewBox="0 0 200 36"
                width="200px"
                height="50px"
                fill="#007A87"
              >
                {icons.finmatch}
              </StringToSvg>
              {/* <TituloModalNovo>Finmatch</TituloModalNovo> */}
            </>
          </LogoImpressao>
          <ModalCuston
            open={modalExperimente}
            onClose={abreModal}
            title="Finmatch"
            children={<ModalConteutoExperimente />}
          />
          <Wrapper style={{ padding: '0px' }}>
            <AreaAcao>
              <a
                // eslint-disable-next-line no-script-url
                href="/"
                className="LinkAcao"
                onClick={(e) => {
                  e.preventDefault();
                  handlePrint();
                }}
              >
                <StringToSvg
                  viewBox="0 2 21 15"
                  width="21px"
                  height="34px"
                  strokeWidth="0"
                >
                  {icons.acaoExportarPDF}
                </StringToSvg>
              </a>
            </AreaAcao>
            <BlocoDashboardDestaques>
              <div>
                <StringToSvg
                  viewBox="-2 4 55 40"
                  width="46px"
                  height="46px"
                  strokeWidth="0"
                >
                  {icons.MatchTotalEmpresas}
                </StringToSvg>
                <p>Total de empresas</p>
                <p>
                  <strong>{apiMATCHADERENCIAState.data[0].totalEmpresa}</strong>
                </p>
              </div>
              <div>
                <StringToSvg
                  viewBox="-2 4 55 40"
                  width="46px"
                  height="46px"
                  strokeWidth="0"
                >
                  {icons.MatchEmpresasMatch}
                </StringToSvg>
                <p>Empresas que deram Match</p>
                <p>
                  <strong>
                    {apiMATCHADERENCIAState.data[0].empresasMatch}
                  </strong>
                  <strong>
                    {valorEmpresaMatchPorcentagem(
                      apiMATCHADERENCIAState.data[0].empresasMatchPorcentagem.toFixed(
                        0
                      )
                    )}
                    %
                  </strong>
                </p>
              </div>
              <div className="infoMatch">
                <div>
                  <StringToSvg
                    viewBox="-2 4 55 40"
                    width="46px"
                    height="46px"
                    strokeWidth="0"
                  >
                    {icons.MatchAderencia}
                  </StringToSvg>
                  <p>Empresas com aderência</p>
                </div>
                <div className="infoMatchValores">
                  <div>
                    <p>
                      <strong>
                        {apiMATCHADERENCIAState.data[0].empresasMatch90_100}
                      </strong>
                      Match <span>90% a 100%</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <strong>
                        {apiMATCHADERENCIAState.data[0].empresasMatch80_90}
                      </strong>
                      Match <span> 80% a 90%</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <strong>
                        {apiMATCHADERENCIAState.data[0].empresasMatch70_80}
                      </strong>
                      Match <span> 70% a 80%</span>
                    </p>
                  </div>

                  <div>
                    <p>
                      <strong>
                        {apiMATCHADERENCIAState.data[0].empresasMatch50_70}
                      </strong>
                      Analisar
                      <span> 50% a 70%</span>
                    </p>
                  </div>
                </div>
              </div>
            </BlocoDashboardDestaques>
          </Wrapper>

          <Graficos dados={apiMATCHADERENCIAState.data[0]} />

          <Wrapper style={{ padding: ' 40px 0px 20px 0px' }}>
            <Content
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <BtnExperimentar onClick={abreModal}>
                Quer saber quais clientes estão aderentes à sua politíca de
                crédito?
              </BtnExperimentar>
              <SeparatorVertical wsize="30%" />
              <BtnConfigurarMatch
                // eslint-disable-next-line no-script-url
                onClick={() => acaoConfigurarMatch()}
              >
                Configurar Finmatch
              </BtnConfigurarMatch>
            </Content>
          </Wrapper>
        </AreaImpressao>
      )}
    </>
  );
};

export default DashboardMatching;

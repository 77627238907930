/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { createActions, createReducer } from 'reduxsauce';
import { call, put, take, fork, cancel, cancelled } from 'redux-saga/effects';
import axios, { CancelToken } from 'axios';

import { getUserData } from 'services/getUserData';
import { Creators as uiToasterActions } from 'store/ducks/uiToaster';

import CONFIG from '../../config';

/**
 * Criando action types & creators
 */

export const { Types, Creators } = createActions({
  apiMATCHADERENCIARequest: [],
  apiMATCHADERENCIASuccess: ['data'],
  apiMATCHADERENCIAError: [],
  apiMATCHADERENCIACancel: [],
});

/**
 * Criando os reducer handlers
 */

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: false,
};

const apiListRequest = (state = INITIAL_STATE, action) => {
  // console.log(action);
  return {
    isLoading: true,
    data: [],
    error: false,
  };
};

const apiListSuccess = (state = INITIAL_STATE, action) => {
  // console.log(action);
  return {
    isLoading: false,
    data: [action.data],
    error: false,
  };
};

const apiListError = (state = INITIAL_STATE, action) => {
  // console.log(action);
  return {
    isLoading: false,
    data: [],
    error: true,
  };
};

const apiListCancel = (state = INITIAL_STATE, action) => {
  // console.log(action);
  return {
    isLoading: false,
    data: [],
    error: false,
  };
};

/**
 * Criando reducer
 */

export default createReducer(INITIAL_STATE, {
  [Types.API_MATCHADERENCIA_REQUEST]: apiListRequest,
  [Types.API_MATCHADERENCIA_SUCCESS]: apiListSuccess,
  [Types.API_MATCHADERENCIA_ERROR]: apiListError,
  [Types.API_MATCHADERENCIA_CANCEL]: apiListCancel,
});

/**
 * Sagas
 */

function changeData(data) {
  if (data.length > 0) {
    const values = data.map((res) => ({
      id: res.id,
      name: res.nome,
    }));
    values.unshift({ id: 0, name: '' });
    return values;
  }
  return [];
}

function* sagaGetAsynResult(params, endpoint) {
  const source = CancelToken.source();

  const url = `${CONFIG.API_REMODELING_URL}${endpoint}`;

  try {
    const response = yield axios.get(url, {
      // cancelToken: source.token,
    });

    yield put(Creators.apiMATCHADERENCIASuccess(response.data.data));
  } catch (error) {
    yield put(uiToasterActions.uiToasterMessage(error, 'error'));
    yield put(Creators.apiMATCHADERENCIAError());
  } finally {
    if (yield cancelled()) {
      console.log('cancel');
      source.cancel();
    }
  }
}

export function* sagaMATCHADERENCIA(action) {
  const user = yield call(getUserData);

  if (user) {
    const params = {
      // idcedente: action.idCedente,
      // idusuario: user.idUsuario,
      idconvenio: user.idEmpresa,
    };
    const params2 = `?idconvenio=${user.idEmpresa}`;
    if (params) {
      const task = yield fork(
        sagaGetAsynResult,
        params,
        `/business-api/api/finmatch/obter-aderencia-finmatch${params2}`
      );

      yield take(Types.API_MATCHADERENCIA_CANCEL);
      yield cancel(task);
    } else {
      yield put(Creators.apiMATCHADERENCIAError());
    }
  }
}

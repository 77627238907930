import React from 'react';
import { Theme } from '../theme';

import {
  PlusCircle,
  IcoCheckCircle,
  RotateCCW,
  Send,
  DollarSign,
} from '../components/Elements';

export const OFERTA = {
  ativo: {
    colorBorder: Theme.yellow,
    colorText: Theme.yellow,
    colorBg: Theme.yellowLight,
  },
  cancelado: {
    colorBorder: Theme.gray3,
    colorText: Theme.gray3,
    colorBg: Theme.gray1,
  },
  finalizado: {
    colorBorder: Theme.gray3,
    colorText: Theme.gray3,
    colorBg: Theme.gray1,
  },
  blacklist: {
    colorBorder: Theme.gray3,
    colorText: Theme.gray3,
    colorBg: Theme.gray1,
  },
  default: {
    colorBorder: Theme.gray3,
    colorText: Theme.gray3,
    colorBg: Theme.gray1,
  },
};

export const OPERACAO = {
  download: {
    colorBorder: Theme.secondary,
    colorText: Theme.primary,
    colorBg: Theme.greenHardLight,
  },
  negociando: {
    colorBorder: Theme.secondary,
    colorText: Theme.primary,
    colorBg: Theme.greenHardLight,
  },
  pagamento: {
    colorBorder: Theme.secondary,
    colorText: Theme.primary,
    colorBg: Theme.greenHardLight,
  },
  cancelado: {
    colorBorder: Theme.gray3,
    colorText: Theme.gray3,
    colorBg: Theme.gray1,
  },
  finalizado: {
    colorBorder: Theme.gray3,
    colorText: Theme.gray3,
    colorBg: Theme.gray1,
  },
  realizada: {
    colorBorder: Theme.gray3,
    colorText: Theme.gray3,
    colorBg: Theme.gray1,
  },
  desistencia: {
    colorBorder: Theme.gray3,
    colorText: Theme.gray3,
    colorBg: Theme.gray1,
  },
  perdida: {
    colorBorder: Theme.gray3,
    colorText: Theme.gray3,
    colorBg: Theme.gray1,
  },
  default: {
    colorBorder: Theme.secondary,
    colorText: Theme.primary,
    colorBg: Theme.greenHardLight,
  },
};

export const PROPOSTA = {
  aceitar: {
    colorBase: Theme.yellow,
    colorLight: Theme.yellowLight,
    buttonLeft: {
      icon: <PlusCircle color={Theme.secondary} size="20px" />,
      label: 'Negociar',
      action: 'OPEN_EDIT_PROPOSAL',
    },
    buttonRigth: {
      icon: <IcoCheckCircle color={Theme.white} size="20px" />,
      label: 'Aceitar',
      action: 'ACCEPT_PROPOSAL',
    },
    button: {
      icon: <IcoCheckCircle color={Theme.white} size="20px" />,
      label: 'Aceitar',
      action: 'ACCEPT_PROPOSAL',
    },
  },
  aceitarEdit: {
    colorBase: Theme.yellow,
    colorLight: Theme.yellowLight,
    buttonLeft: {
      icon: <RotateCCW color={Theme.secondary} size="20px" />,
      label: 'Recalcular',
      action: 'RECALC_PROPOSAL',
    },
    buttonRigth: {
      icon: <Send color={Theme.white} size="20px" />,
      label: 'Enviar',
      action: 'SEND_PROPOSAL',
    },
    button: {
      icon: <RotateCCW color={Theme.white} size="20px" />,
      label: 'Recalcular',
      action: 'RECALC_PROPOSAL',
    },
  },
  aceitarEditSimula: {
    buttonRigth: {
      icon: <RotateCCW color={Theme.white} size="20px" />,
      label: 'Recalcular',
      action: 'RECALC_PROPOSAL',
    },
  },
  aguardando: {
    colorBase: Theme.gray3,
    colorLight: Theme.gray1,
  },
  recusado: {
    colorBase: Theme.gray3,
    colorLight: Theme.gray1,
  },
  aprovado: {
    action: 'PROPOSAL_APROVED',
  },
  iniciado: {
    colorBase: Theme.yellow,
    colorLight: Theme.yellowLight,
    button: {
      icon: <DollarSign color={Theme.white} size="20px" />,
      label: 'Confirmar Pagamento',
      action: 'CONFIRM_PAYMENT_PROPOSAL',
    },
  },
  pago: {
    colorBase: Theme.gray3,
    colorLight: Theme.gray1,
  },
  concluido: {
    colorBase: Theme.gray3,
    colorLight: Theme.gray1,
  },
  default: {
    colorBase: Theme.gray3,
    colorLight: Theme.gray1,
  },
};
export const FINBOX = {
  ativo: {
    colorBorder: Theme.primary,
    colorText: Theme.primary,
    colorBg: Theme.white,
  },
  cancelado: {
    colorBorder: Theme.gray3,
    colorText: Theme.gray3,
    colorBg: Theme.white,
  },
  default: {
    colorBorder: Theme.gray3,
    colorText: Theme.gray3,
    colorBg: Theme.white,
  },
};

export const CONDICIONANTE = {
  ativo: {
    colorBorder: Theme.secondary,
    colorText: Theme.secondary,
    colorBg: Theme.greenHardLight,
  },
  pendente: {
    colorBorder: Theme.primary,
    colorText: Theme.primary,
    colorBg: Theme.greenHardLight,
  },
  expirado: {
    colorBorder: Theme.primary,
    colorText: Theme.primary,
    colorBg: Theme.white,
  },
  concluido: {
    colorBorder: Theme.primary,
    colorText: Theme.primary,
    colorBg: Theme.white,
  },
  recusado: {
    colorBorder: Theme.orange,
    colorText: Theme.orange,
    colorBg: Theme.orangeLight,
  },
  default: {
    colorBorder: Theme.gray3,
    colorText: Theme.gray3,
    colorBg: Theme.white,
  },
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from 'components/FinCheckboxCuston';
import {
  Field,
  Label,
  BaseMultiSelect,
  ContentList,
  StatusBar,
  InputFeedback,
  TextList,
  ListCheckds,
} from './Styles';

export const MultiSelect = ({
  label,
  largura,
  data,
  value,
  handleBlur,
  error,
  setFieldValue,
  theme,
}) => {
  const [valuesCheck, setValuesCheck] = useState([]);
  useEffect(() => {
    setValuesCheck(value);
  }, [value]);
  const handleCuston = (e, res) => {
    const values = {
      id: res.id,
      name: res.name,
      checked: !!e.target.checked,
    };
    const field = `${res.name}.${res.id - 1}`;
    setFieldValue(field, values);
  };
  const renderOptions = () => {
    return data.map((res) => {
      return (
        <li key={res.id}>
          <Checkbox
            id={res.id}
            type="checkbox"
            name={res.name}
            checked={
              value.filter((e) => e.id === res.id && e.checked).length > 0
            }
            onBlur={handleBlur}
            onChange={(e) => handleCuston(e, res)}
          />
          <TextList para={res.idProduto}>{res.name}</TextList>
        </li>
      );
    });
  };
  return (
    <Field error={error} largura={largura}>
      <Label color={theme.color.cinza3} fontweight="400" marginbottom="0.5rem">
        {label}
      </Label>
      <BaseMultiSelect>
        <ContentList>
          <ul>{renderOptions()}</ul>
        </ContentList>
        <ListCheckds>
          {valuesCheck
            .filter((item) => item.checked)
            .map((res) => `${res.name.split(' ')[0]}, `)}
        </ListCheckds>
      </BaseMultiSelect>
      <StatusBar />
      {error && <InputFeedback>{error}</InputFeedback>}
    </Field>
  );
};

MultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  largura: PropTypes.string,
  data: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

MultiSelect.defaultProps = {
  name: null,
  field: false,
  label: '',
  largura: '50%',
  data: null,
  value: null,
  handleChange: null,
  handleBlur: null,
  error: null,
  disabled: false,
};

export default MultiSelect;

import React from 'react';

import { ReactComponent as IcoHandShake } from 'assets/images/hand-shake.svg';
import { ReactComponent as IcoHandDowbload } from 'assets/images/icon-download.svg';
import { ReactComponent as IcoHandDollar } from 'assets/images/icon-dollar.svg';
import { ReactComponent as IcoTropy } from 'assets/images/icon-trophy.svg';

import { ReactComponent as IconeTrash } from 'assets/images/icon-trash.svg';
import { ReactComponent as IconeSad } from 'assets/images/icon-sad.svg';
import { ReactComponent as IconeHandDown } from 'assets/images/icon-hand-down.svg';

import { StatusButton } from './StatusButton';

const STEPS = {
  download: ['isActive', 'isDisabled', 'isDisabled', 'isHidden'],
  negociando: ['isVisited', 'isActive', 'isDisabled', 'isHidden'],
  pagamento: ['isVisited', 'isVisited', 'isActive', 'isHidden'],
  finalizado: ['isVisited', 'isVisited', 'isVisited', 'isHidden'],
  cancelado: ['isHidden', 'isHidden', 'isHidden', 'isCanceled'],
  desistencia: ['isHidden', 'isHidden', 'isHidden', 'isGivenUp'],
  perdido: ['isHidden', 'isHidden', 'isHidden', 'isLost'],
};

const labels = {
  negociando: 'Negociando',
  pagamento: 'Pagamento',
  finalizado: 'Finalizado',
  cancelado: 'Cancelado',
  desistencia: 'Desistência',
  perdido: 'Perdido',
};

export const Steps = ({ data, openPropostas, dialogOpen, downloadDirect }) => {
  const firstStage = STEPS[data.step.nome][0];
  const secondStage = STEPS[data.step.nome][1];
  const thirdStage = STEPS[data.step.nome][2];
  const fourthStage = STEPS[data.step.nome][3];

  return (
    <>
      {firstStage === 'isVisited' ? (
        <StatusButton
          status={firstStage}
          label="Download"
          onClick={(e) => downloadDirect(e)}
        >
          <IcoHandDowbload />
        </StatusButton>
      ) : (
        <StatusButton
          status={firstStage}
          label="Download"
          onClick={(e) => dialogOpen(e)}
        >
          <IcoHandDowbload />
        </StatusButton>
      )}

      {secondStage === 'isVisited' ? (
        <StatusButton
          status={secondStage}
          label="Negociação"
          onClick={() => openPropostas(data.idOperacao)}
        >
          <IcoTropy />
        </StatusButton>
      ) : (
        <StatusButton
          data={data}
          status={secondStage}
          label="Negociação"
          onClick={() => openPropostas(data.idOperacao)}
        >
          <IcoHandShake />
        </StatusButton>
      )}

      <StatusButton
        status={thirdStage}
        borderHidden
        label="Pagamento"
        onClick={() => openPropostas(data.idOperacao)}
      >
        <IcoHandDollar />
      </StatusButton>

      <StatusButton
        status={fourthStage}
        label={labels[data.step.descricao]}
        onClick={() => openPropostas(data.idOperacao)}
      >
        {fourthStage === 'isCanceled' && <IconeTrash />}
        {fourthStage === 'isGivenUp' && <IconeSad />}
        {fourthStage === 'isLost' && <IconeHandDown />}
      </StatusButton>
    </>
  );
};

import styled from 'styled-components/macro';
import { FinSelectBox, Option as Opt } from 'components/FinSelectBox';

export const Select = styled(FinSelectBox)`
  & > div {
    background-color: var(--fingray-300);
    border-radius: 10px;
    border: none;
    height: 40px;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    color: var(--fingray-900);
  }
  & select {
    height: inherit;
  }
  & :after {
    background-color: var(--fingray-900);
  }
`;

export const Option = styled(Opt)``;

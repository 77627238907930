import React from 'react';

import { SeparatorVertical } from 'components/FinSeparador';
import { capitalizeWords } from 'helpers/string';
import { Name, Cnpj, ButtonItem, FinAvatarCuston } from './styles.filiais';
import { Separator, IconLockStyle } from '../style.layout';

export function ItemMenu({ data, onClick }) {
  return (
    <ButtonItem justify="flex-start" onClick={onClick}>
      <FinAvatarCuston online name={data.name} />
      <SeparatorVertical pwidth="1rem" />
      <Name>{capitalizeWords(data.name)}</Name>
      <Separator color="#2e2e2e" />
      {data.cnpj ? <Cnpj>{data.cnpj}</Cnpj> : <IconLockStyle />}
    </ButtonItem>
  );
}

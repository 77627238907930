import styled, { css } from 'styled-components/macro';

const Truncate = css`
  width: 20ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 300px;
  flex: 1;

  &::-webkit-scrollbar-track {
    margin-top: 60px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d8d8d8;
    border-radius: 10px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const BgIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 33px;
  background-color: var(--fingray-200);
  border-radius: 12px;
  margin-right: 1.125rem;
`;

export const ContentDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.span`
  font-family: 'Inter';
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--fingray-900);
  ${Truncate}
`;

export const Email = styled.span`
  font-family: 'Inter';
  font-weight: 400;
  font-size: 0.875rem;
  color: var(--fingray-500);
  line-height: 1;
`;

export const Tag = styled.span`
  background-color: var(--fingray-900);
  text-align: center;
  padding: 0.3rem 0.8rem;
  color: #ffffff;
  border-radius: 36px;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 0.75rem;
`;

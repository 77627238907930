/* eslint-disable no-console */
import { useCallback, useRef } from 'react';
import { useAuth } from 'react-oidc-context';
import axios from 'axios';
import config from 'config';
import { history } from 'components/History';

export function useGetDataAccess() {
  const auth = useAuth();
  const refSync = useRef(null);

  const getDataAccess = () => {
    const value = localStorage.getItem('userData');
    return JSON.parse(value);
  };

  const fetchDataAccess = useCallback(async () => {
    try {
      // const user = getDataAccess();

      if (auth.isAuthenticated && !auth.isLoading && !refSync.current) {
        const token =
          auth.user && auth.user.access_token && auth.user.access_token;
        const url = `${config.API_REMODELING_URL}/user-api/api/acesso/login`;

        const response = axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        refSync.current = response;
        const usuario = await response;

        if (usuario) {
          localStorage.setItem('userData', JSON.stringify(usuario.data.data));
        }
      }
    } catch (e) {
      console.log('[ERROR]', 'acesso/login', e);
      auth.removeUser().then(() => history.push('/login'));
    }
    return null;
  }, [auth]);

  return { getDataAccess, fetchDataAccess };
}

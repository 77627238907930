import React from 'react';

import { Title5 } from 'components/FinHeadings';
import { SeparatorVertical } from 'components/FinSeparador';
import { HeaderStyle } from '../Propostas/style';
import { IconeNotesStyle } from './style.bordero';

export const Head = ({ rules }) => {
  return (
    <HeaderStyle>
      <IconeNotesStyle />
      <SeparatorVertical pwidth="0.4rem" />
      <Title5 style={{ fontWeight: 'bold', margin: '0' }}>
        {`Seleção de Notas Fiscais ${
          rules.indexOf('view') !== -1 ? '' : ' (Editar)'
        }`}
      </Title5>
    </HeaderStyle>
  );
};

import styled, { css } from 'styled-components/macro';

export const Content = styled.div`
  border-radius: 16px;
  overflow: hidden;
  width: 44px;
  height: 44px;
  text-rendering: geometricprecision;
  border-style: solid;
  border-width: 2px;
  padding: 3px;
  transition: border 250ms ease 0ms, box-shadow 0.25s ease 0s;
  cursor: pointer;
  border-color: ${({ online }) =>
    online ? ` var(--fingreen-200)` : ` var(--finerror)`};

  &:hover {
    border: none};
  }

  ${({ withShadow }) =>
    withShadow &&
    css`
      filter: drop-shadow(0px 0px 9px rgba(0, 0, 0, 0.25));
    `}
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
`;

export const Background = styled.div`
  position: absolute;
  background-color: ${({ bgColor }) => bgColor || `#ffffff`};
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  outline: none;
  object-fit: cover;
  border-radius: 12px;
  max-width: 100%;
  opacity: 1;
`;

export const Name = styled.span`
  position: absolute;
  font-size: ${({ fontSize }) => fontSize || `1.7rem`};
  font-weight: 700;
  z-index: 3;
  left: 50%;
  top: 50%;
  text-align: center;
  color: var(--fingray-900);
  transform: translate(-50%, -50%) scale(0.65);
  white-space: nowrap;
  user-select: none;
`;

import undraw from 'assets/images/feedback-graphics/undraw_no_data_qbuo.svg';
import error from 'assets/images/feedback-graphics/undraw_warning_cyit.svg';

export const GRAPHICS = {
  undraw: {
    image: undraw,
    label: 'Nenhum resultado encontrado..',
  },
  error: {
    image: error,
    label: 'Erro inesperado..',
  },
};

import styled from 'styled-components/macro';

export const Title = styled.h2`
  color: var(--fingray-900);
  font-size: 2rem;
  font-family: 'Inter';
  font-weight: 600;
  text-align: center;
  text-rendering: geometricprecision;
`;

export const Text = styled.p`
  color: var(--fingray-400);
  font-size: 1rem;
  font-family: 'Inter';
  font-weight: 400;
  text-align: center;
  text-rendering: geometricprecision;
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  align-items: ${({ align }) => align || 'flex-start'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
`;

export const WrapperImage = styled(Flex)`
  align-items: center;
  width: 300px;
  height: 262px;
`;

export const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 500px;
  padding: 2rem;
`;

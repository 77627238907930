import styled, { css } from 'styled-components/macro';

export const Flex = styled.div`
  display: flex;
  ${({ row }) =>
    row &&
    css`
      flex-direction: row;
    `}

  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
    `}

  justify-content: ${({ justify }) => justify || `center`};
  align-items: ${({ align }) => align || `center`};
  width: 100%;
`;

export const Container = styled(Flex)`
  height: 400px;
  padding-top: 1rem;
  width: 100%;
  align-items: flex-start;
`;

export const Box = styled(Flex)`
  background-color: var(--finred100);
  border: 1px solid var(--finred-500);
  min-height: 100px;
  max-height: 200px;
  padding: 1rem;
  overflow-y: scroll;
  margin: 1rem;
`;

export const Title = styled.h3`
  color: var(--fingray-900);
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

export const Details = styled.details`
  color: var(--fingray-900);
  font-size: 0.8rem;
  font-weight: 400;
  white-space: pre-wrap;
`;

export const Button = styled.button`
  color: var(--fingray-900);
  font-size: 0.8rem;
  background: #ffffff;
  border-radius: 5px;
  padding: 3px 10px;
  border: 1px solid #a5a5a5;
  margin-top: 0.5rem;
  cursor: pointer;
  &:hover {
    background-color: #e1e1e1;
  }
  &:active {
    background-color: #ffffff;
  }
`;

/* eslint-disable no-unused-vars */
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import CONFIG from 'config';
import toast from 'react-hot-toast';

import { handleErrors } from 'helpers/requisicoes/handleErrors';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const apiRefuseinvite = async (params) => {
  const payload = {
    idConexao: params.idConvite,
    idTipoRecusa: params.idTipoRecusa,
    obs: params.obs,
  };

  const mutateData = axios.put(`${BASE_URL}/matching/conexao/recusar`, payload);

  const result = await mutateData;
  const { data } = result;

  return data || [];
};

export const useMutateRefuseInvite = () => {
  const queryClient = useQueryClient();

  return useMutation((params) => apiRefuseinvite(params), {
    onMutate: async (params) => {
      await queryClient.cancelQueries(['invitations']);
    },
    onError: (error) => {
      const message = handleErrors(error);
      toast.error(message, { duration: 4000 });
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('search');
      queryClient.invalidateQueries('perfil');
      queryClient.invalidateQueries('invitations');
      queryClient.invalidateQueries('empreendedores');
    },
  });
};

import React, { useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { icons } from 'assets/icones/finplaceIcons';
import { finTheme } from 'finTheme';
import { StringToSvg } from 'helpers/svgFiles/StringToSvg';
import { SeparatorHorizontal } from 'components/FinSeparador';

import { Creators as uiDialogActions } from 'store/ducks/uiDialog';
import { Types as TypesApiHabilitar } from 'store/ducks/apiHabilitar';
import { Types as TypesApiDesabilitar } from 'store/ducks/apiDesabilitar';

import { useActions } from 'hooks/useActions';
import Dropdown, { DropdownItem } from './Dropdown';

const Container = styled.div`
  position: absolute;
  z-index: ${({ theme }) => theme.newtheme.zIndex.dropdownMenu};
  bottom: -22%;
  right: 11%;
  margin-right: 117px;

  opacity: ${({ opacity }) => opacity || '0'};
  transition: opacity 0.4s ease-in-out;
`;

const iconError = (
  <StringToSvg
    viewBox="0 -1 18 18"
    width="18"
    height="18"
    fill={finTheme.newtheme.color.error}
    stroke="none"
  >
    {icons.errorFlat}
  </StringToSvg>
);

const iconCheck = (
  <StringToSvg
    viewBox="0 -1 18 18"
    width="18"
    height="18"
    fill={finTheme.newtheme.color.success}
    stroke="none"
  >
    {icons.checkFlat}
  </StringToSvg>
);

const MenuHabilitar = ({ open, close, id, statusHabilitado }) => {
  const refMenu = useRef();

  const uiDialog = useActions(uiDialogActions);

  const uiDialogOpen = useCallback(
    (tipo, dispatch) => {
      uiDialog.uiDialogOpen(tipo, dispatch);
    },
    [uiDialog]
  );

  const clickListener = useCallback(
    (e) => {
      if (e) {
        if (refMenu.current && !refMenu.current.contains(e.target)) {
          close();
        }
      }
    },
    [close]
  );

  useEffect(() => {
    document.addEventListener('click', clickListener);
    return () => {
      document.removeEventListener('click', clickListener);
    };
  }, [clickListener]);

  return (
    open && (
      <Container opacity={open ? 1 : 0} ref={refMenu}>
        <Dropdown>
          {(statusHabilitado === 1 || statusHabilitado === 2) && (
            <DropdownItem
              icon={iconCheck}
              onclick={(e) => {
                e.preventDefault();
                uiDialogOpen('habilitar', {
                  type: TypesApiHabilitar.API_HABILITAR_REQUEST,
                  idCedente: id,
                });
                close();
              }}
            >
              Sim
            </DropdownItem>
          )}
          <SeparatorHorizontal
            ppadding="0.1rem 0"
            pborderColor={finTheme.newtheme.color.cinza2}
          />
          {(statusHabilitado === 1 || statusHabilitado === 3) && (
            <DropdownItem
              icon={iconError}
              onclick={(e) => {
                e.preventDefault();
                uiDialogOpen(
                  statusHabilitado === 1 ? 'recusarHabilitacao' : 'desabilitar',
                  {
                    type: TypesApiDesabilitar.API_DESABILITAR_REQUEST,
                    idCedente: id,
                  }
                );
                close();
              }}
            >
              Não
            </DropdownItem>
          )}
        </Dropdown>
      </Container>
    )
  );
};

MenuHabilitar.propTypes = {
  onclick: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

export default MenuHabilitar;

import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: row
  align-items: center;
  border-bottom: 3px solid var(--fingray-200);
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: ${({ justify }) => justify || 'center'};
  align-items: ${({ align }) => align || 'center'};
  width: 100%;
  position: relative;
`;

export const Label = styled.span`
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;
  color: var(--fingray-900);
`;

export const WrapperChart = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

import * as R from 'ramda';
import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import isBefore from 'date-fns/isBefore';
import { format } from 'date-fns';

const ordenarParcelasPorData = (parcelaA, parcelaB) =>
  new Date(parcelaA.vencimento) - new Date(parcelaB.vencimento);

export const ordenarParcelas = (parcelas) =>
  parcelas.sort(ordenarParcelasPorData).map((p, i) => ({
    idNumero: i + 1,
    valor: p.valor,
    vencimento: p.vencimento,
  }));

export const removerParcela = ({ indice, parcelas }) => {
  const novasParcelas = R.remove(indice, 1, parcelas);
  const result = ordenarParcelas(novasParcelas);

  return result;
};

export const convert = (data) => {
  const values = data.split('/');
  return `${values[2]}-${values[1]}-${values[0]}`;
};

export const dateValidate = (data, parcelas) => {
  const numbers = data.replace(/[~`!@#$%^&*()+={}[\];:'"<>.,/\\?-_]/g, '');

  if (numbers.length === 8) {
    const dataJaExiste = parcelas
      .map((res) => format(parseISO(res.vencimento), 'dd/MM/yyyy'))
      .filter((v) => v === data).length;

    if (dataJaExiste > 0) return false;

    const date = convert(data);
    const parsed = parseISO(date);
    const valid = isValid(parsed);

    const result = valid ? isBefore(new Date(), parsed) : false;
    return result;
  }
  return false;
};

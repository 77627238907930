import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
// import media from 'styled-media-query';
/* eslint-disable */
import { Field as Fields, useField } from 'formik';
import ImageIco from 'images/iconsSvg/info.svg';
import { TooltipDefault } from 'components/TooltipDefault';
//import { darken, rgba, rem } from 'polished';
import { Theme /*, SIZES*/ } from '../../theme';
import icoChevronDown from '../../images/feather/chevron-down.svg';

const Option = styled.option`
  background-color: #424242;
  font-weight: normal;
  display: block;
  white-space: pre;
  min-height: 1.2em;
  padding: 0px 2px 1px;
  color: ${Theme.white};
`;

const SelectComp = styled.select`
  background: hsla(0, 0%, 100%, 0);
  padding-bottom: 5px;
  border: 0;
  border-bottom: 1px solid #ccc;
  height: 30px;
  width: 100%;
  outline: 0;
  font-weight: 400;
  font-size: 16px;
  padding-right: 24px;
  cursor: pointer;
  min-width: 16px;
  user-select: none;
  border-radius: 0;
  margin: 0;
  display: block;
  min-width: 0;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  background-image: url(${icoChevronDown});
  background-position: 100% 50%;
  background-repeat: no-repeat;
  &::-ms-expand {
    display: none; /* Hide the default arrow in Internet Explorer 10 and Internet Explorer 11 */
  }
`;

const StatusBar = styled.span`
  display: block;
  position: relative;
  margin-top: -1px;
  height: 2px;
  width: 100%;
  &:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    transform: scaleX(0);
    transition: transform 0.25s ease, transform 0.25s ease;
  }
`;

const Label = styled.span`
  color: ${({ color }) => color};
  font-weight: ${({ fontweight }) => fontweight};
  ${({ marginbottom }) =>
    marginbottom &&
    css`
      margin-bottom: ${marginbottom};
    `}
`;

const Field = styled.div`
  width: ${({ largura }) => (largura ? largura : '50%')};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 98px;
  &:hover ${StatusBar}:after {
    background-color: rgba(0, 0, 0, 0.45);
    transform: scaleX(1);
  }
  & ${SelectComp}:focus + ${StatusBar}:after {
    background-color: ${Theme.primary};
    transform: scaleX(1);
  }
  ${({ error }) =>
    error &&
    css`
      & ${SelectComp}:focus + ${StatusBar}:after {
        background-color: ${Theme.orange};
        transform: scaleX(1);
      }
    `}
`;

const InputFeedback = styled.div`
  color: ${Theme.orange};
  font-weight: 400;
  margin-top: 5px;
`;

export const Select = ({
  id,
  field,
  label,
  largura,
  data,
  value,
  handleChange,
  handleBlur,
  error,
  errormessage,
  colorLabel,
  weightLabel,
}) => {
  const renderOptions = () => {
    return data.map(res => {
      return <Option value={res.id} key={res.id} label={res[field]} />;
    });
  };
  return (
    <Field error={error} largura={largura}>
      <Label
        color={colorLabel ? colorLabel : Theme.gray3}
        fontweight={weightLabel ? weightLabel : '400'}
        marginbottom="0.5rem"
      >
        {label}
      </Label>
      <SelectComp
        name={id}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        {renderOptions()}
      </SelectComp>
      <StatusBar />
      {error && <InputFeedback>{errormessage}</InputFeedback>}
    </Field>
  );
};



export const SelectMatch = ({
  id,
  field,
  label,
  largura,
  data,
  value,
  handleChange,
  handleBlur,
  error,
  errormessage,
  colorLabel,
  weightLabel,
  tooltip,
}) => {
  return (
    <Field  largura={largura}>
      <Label
        color={colorLabel ? colorLabel : Theme.gray3}
        fontweight={weightLabel ? weightLabel : '400'}
        marginbottom="0.5rem"
      >
        {label}
        {tooltip && (
          <TooltipDefault
            style={{ float: 'right', left: '5px', top: '2px' }}
            position="left"
            data={tooltip}
          >
            <img src={ImageIco} width="18" />
          </TooltipDefault>
        )}
      </Label>
      <SelectComp
        name={id}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
      >
      if(data === true){
        <Option value='true' label="Sim" selected='true' />
      }else if(data=== false){<Option value='false' label="Não" selected='true' />}
        <Option value='null' label="Indiferente"  selected='true' />
      </SelectComp>
      <StatusBar />
      {/* {error && <InputFeedback>{errormessage}</InputFeedback>} */}
    </Field>
  );
};

const ContentList = styled.div`
  width: max-content;
  background-color: #ffffff;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: absolute;
  top: -57px;
  left: 0;
  z-index: 9;
  visibility: hidden;
  overflow-y: auto;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.31);
  opacity: 0;
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`;

const BaseMultiSelect = styled.div`
  background: hsla(0, 0%, 100%, 0);
  padding-bottom: 5px;
  border: 0;
  border-bottom: 1px solid #ccc;
  height: 30px;
  width: 100%;
  outline: 0;
  font-weight: 400;
  font-size: 18px;
  padding-right: 24px;
  cursor: pointer;
  min-width: 16px;
  position: relative;
  &:before {
    background: url(${icoChevronDown}) no-repeat;
    content: '';
    padding: 1rem;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }
  &:hover ${ContentList} {
    visibility: visible;
    top: 0;
    opacity: 100;
  }
`;



const CheckBox = styled.input`
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 2px solid ${Theme.gray2};
  }

  &:hover + label:before {
    background: ${Theme.primary};
  }

  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  &:checked + label:before {
    background: ${Theme.primary};
    border: 2px solid ${Theme.primary};
  }

  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
`;

const ListCheckds = styled.span`
  display: inline-block;
  width: 100%;
  color: ${Theme.gray6};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${({ fontSize }) => fontSize || 'initial'};
`;

export const MultiSelect = ({
  field,
  label,
  largura,
  data,
  value,
  handleChange,
  handleBlur,
  error,
  errormessage,
  setFieldValue,
  colorLabel,
  weightLabel,


}) => {
  const [valuesCheck, setValuesCheck] = useState([]);
  useEffect(() => {
    setValuesCheck(value);
  }, [value]);
  const handleCuston = (e, res) => {
    const values = {
      idProduto: res.idProduto,
      nome: res.nome,
      status: e.target.checked ? true : false,
    };
    const field = `produtos.${res.idProduto - 1}`;
    setFieldValue(field, values);
  };
  const renderOptions = () => {
    return value.map(res => {
      return (
        <li key={res.idProduto}>
          <CheckBox
            id={res.idProduto}
            type="checkbox"
            name="produtos"
            checked={
              value.filter(e => e.idProduto === res.idProduto && e.status)
                .length > 0
                ? true
                : false
            }
            onBlur={handleBlur}
            onChange={e => handleCuston(e, res)}
          />
          <TextList para={res.idProduto}>{res.nome}</TextList>
        </li>
      );
    });
  };
  return (
    <Field error={error} largura={largura}>
      <Label
        color={colorLabel ? colorLabel : Theme.gray3}
        fontweight={weightLabel ? weightLabel : '400'}
        marginbottom="0.5rem"
      >
        {label}
      </Label>
      <BaseMultiSelect>
        <ContentList>
          <ul>{renderOptions()}</ul>
        </ContentList>
        <ListCheckds>
          {valuesCheck
            .filter(item => item.status)
            .map(res => `${res.nome.split(' ')[0]}, `)}
        </ListCheckds>
      </BaseMultiSelect>
      <StatusBar />
      {error && <InputFeedback>{errormessage}</InputFeedback>}
    </Field>
  );
};


export const MultiSelectSegmento = ({
  field,
  label,
  largura,
  data,
  value,
  handleChange,
  handleBlur,
  error,
  errormessage,
  setFieldValue,
  fontSize,
  colorLabel,
  weightLabel,
  tooltip,

}) => {
  const [valuesCheck, setValuesCheck] = useState([]);
  let [nValuesCheck, setNValuesCheck] = useState(0);
  useEffect(() => {
    setNValuesCheck(value.filter(e =>  e.selecionado === true).length)
    setValuesCheck(value);
  }, [value]);
  const handleCuston = (e, res) => {
    const values = {
      idsegmento: res.idsegmento,
      desc_segmento: res.desc_segmento,
      selecionado: e.target.checked ? true : false,
    };
    const field = `segmentos.${res.idsegmento - 1}`;
    setFieldValue(field, values);

  };
  const renderOptions = () => {

    return value.map(res => {
      return (

        <li key={res.idsegmento} style={{position:'relative'}}>
          <CheckBox
            id={res.idsegmento}
            type="checkbox"
            name="segmentos"
            style={{width:'18px', height:'18px', zIndex: '999'}}
            checked={
              value.filter(e => e.idsegmento === res.idsegmento && e.selecionado)
                .length > 0
                ? true
                : false
            }
            onBlur={handleBlur}
            onChange={e => handleCuston(e, res)}
          />
          <TextList para={res.idsegmento}>{res.desc_segmento}</TextList>
        </li>
      );
    });

  };
  return (
    <Field error={error} largura={largura}>
      <Label
        color={colorLabel ? colorLabel : Theme.gray3}
        fontweight={weightLabel ? weightLabel : '400'}
        marginbottom="0.5rem"
      >
        {label}
        {tooltip && (
          <TooltipDefault
            style={{ float: 'right', left: '5px', top: '2px' }}
            position="left"
            data={tooltip}
          >
            <img src={ImageIco} width="18" />
          </TooltipDefault>
        )}
      </Label>
      <BaseMultiSelect>
        <ContentList>
          <ul>{renderOptions()}</ul>
        </ContentList>
        <ListCheckds fontSize={fontSize}>
        QTD de Segmentos Selecionados: {nValuesCheck}
          {/* {valuesCheck
            .filter(item => item.selecionado)
            .map(res => `${res.desc_segmento.split(' ')[0]}, `)} */}
        </ListCheckds>
      </BaseMultiSelect>
      <StatusBar />
      {error && <InputFeedback>{errormessage}</InputFeedback>}
    </Field>
  );
};


/**
 * Multi select checkbox
 */
export const MultiSelectCheckbox = ({
  label,
  largura,
  data,
  value,
  handleChange,
  handleBlur,
  colorLabel,
  weightLabel,
  fieldName,
  error,
  errormessage,
}) => {
  const [valuesCheck, setValuesCheck] = useState([]);
  useEffect(() => {
    setValuesCheck(value);
  }, [value]);
  const renderOptions = () => {
    return data.map(res => {
      return (
        <li key={res.id}>
          <CheckBox
            id={`${fieldName}.${res.id}`}
            type="checkbox"
            name={fieldName}
            value={res.id}
            checked={value.includes(res.id.toString())}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <TextList para={`${fieldName}.${res.id}`}>{res.nome}</TextList>
        </li>
      );
    });
  };
  return (
    <Field error={error} largura={largura}>
      <Label
        color={colorLabel ? colorLabel : Theme.gray3}
        fontweight={weightLabel ? weightLabel : '400'}
        marginbottom="0.5rem"
      >
        {label}
      </Label>
      <BaseMultiSelect>
        <ContentList style={{ height: '280px' }}>
          <ul>{renderOptions()}</ul>
        </ContentList>
        <ListCheckds>
          {valuesCheck.map(res =>
            data.filter(r => r.id.toString() === res).map(e => e.nome)
          )}
        </ListCheckds>
      </BaseMultiSelect>
      <StatusBar />
      {error && <InputFeedback>{errormessage}</InputFeedback>}
    </Field>
  );
};

/**
 * useField create components
 */

const MySelect = ({ label, data, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <Field error={meta.error} largura={props.largura}>
      <Label
        htmlFor={props.id || props.name}
        color={Theme.gray3}
        fontweight="400"
        marginbottom="0.5rem"
      >
        {label}
      </Label>
      <SelectComp {...field} {...props}>
        {data.map(res => {
          return <Option value={res[field]} key={res.id} label={res[field]} />;
        })}
      </SelectComp>
      <StatusBar />
      {meta.touched && meta.error ? (
        <InputFeedback>{meta.error}</InputFeedback>
      ) : null}
    </Field>
  );
};
const TextList = styled.label.attrs(({ para }) => ({
  htmlFor: para,
}))`
  font-size: 16px;
  color: ${Theme.gray6};
`;

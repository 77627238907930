import React from 'react';

import { ReactComponent as IconAward } from 'assets/images/icon-award.svg';

import { Title5 } from 'components/FinHeadings';
import Button from 'components/FinButton';
import { Wrapper } from 'components/FinWrappers';
import { Ring } from '@uiball/loaders';
import { BoxChampionStyle } from './style';
import { useMutateConvenioVitoria } from '../mutates/useMutateConvenioVitoria';

export const BoxChampion = ({ data }) => {
  const convenioVitoria = useMutateConvenioVitoria();
  return (
    <BoxChampionStyle>
      <Wrapper
        tipo="column"
        style={{
          backgroundColor: '#fff',
          borderRadius: '16px',
          padding: '2rem',
        }}
      >
        <Wrapper tipo="column" padding="5px" pheight="72px">
          <IconAward />
        </Wrapper>
        <Title5 style={{ fontWeight: 'bold' }}>Proposta Aprovada</Title5>
        <Button
          preenchimento="outline"
          onClick={() =>
            convenioVitoria.mutate({ idProposta: data.idProposta })
          }
          disabled={convenioVitoria.isLoading}
          rounded
        >
          {convenioVitoria.isLoading ? (
            <Ring size={20} lineWeight={5} speed={2} color="black" />
          ) : (
            'Clique aqui para continuar'
          )}
        </Button>
      </Wrapper>
    </BoxChampionStyle>
  );
};

/* eslint-disable no-plusplus */
/* eslint-disable no-case-declarations */
const INITIAL_STATE = {
  data: [],
  isLoading: false,
  isLoader: null,
  error: false,
  calculado: [],
  infoPagamento: [],
  loadInfoPag: false,
  statusProp: 'success',
  newProposal: [],
  isLoadingCalcula: false,
  dataCalculado: [],
  dataBanco: [],
  fromBorderoCalc: false,
  newProposalSemNota: [],
  viewActive: null,
  simuladoSemNota: [],
  resumoData: [],
  resumoDataHistory: [],
};

const propostas = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LOAD_DATA_PROPOSTAS_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'LOAD_DATA_PROPOSTAS_SUCCESS':
      return {
        ...state,
        data: action.data,
        isLoading: false,
      };
    case 'LOAD_DATA_PROPOSTAS_FAILURE':
      return {
        ...state,
        data: action.data,
        error: true,
      };
    /** **************************************** */
    case 'SET_DEFAULT_DATA_PROPOSTAS':
      return {
        ...state,
        data: [],
      };
    /** **************************************** */
    case 'ADD_BORDERO_PROPOSTAS_REQUEST':
      return {
        ...state,
        error: false,
      };
    case 'ADD_BORDERO_PROPOSTAS_SUCCESS':
      // console.log(action);
      const a = {
        ...state,
        isLoading: false,
        borderos: action.borderos,
      };
      return a;
    /** **************************************** */
    case 'CALCULA_PROPOSTAS_DEFAULT':
      return {
        ...state,
        isLoading: false,
        calculado: [],
      };
    case 'CALCULA_PROPOSTAS_REQUEST':
      return {
        ...state,
        isLoadingCalcula: true,
        isLoadingSimulaSemNota: true,
      };
    case 'CALCULA_PROPOSTAS_SUCCESS':
      return {
        ...state,
        isLoadingCalcula: false,
        dataCalculado: action.data,
      };
    case 'CALCULA_PROPOSTAS_FAILURE':
      return {
        ...state,
        error: true,
        isLoadingCalcula: false,
      };
    case 'SET_CALCULA_PROPOSTAS_DEFAULT':
      return {
        ...state,
        dataCalculado: [],
      };
    case 'CALCULA_PROPOSTAS_FROM_BORDERO':
      return {
        ...state,
        fromBorderoCalc: action.data,
      };
    /** **************************************** */
    case 'INCLUIR_CONTRA_PROPOSTAS_REQUEST':
      return {
        ...state,
        isLoading: true,
        isLoader: true,
      };
    case 'INCLUIR_CONTRA_PROPOSTAS_SUCCESS':
      return {
        ...state,
        isLoader: false,
        isLoading: false,
      };
    case 'INCLUIR_CONTRA_PROPOSTAS_FAILURE':
      return {
        ...state,
        error: true,
      };
    /** **************************************** */
    case 'ACEITAR_PROPOSTA_REQUEST':
      return {
        ...state,
        isLoading: true,
        isLoader: true,
      };
    case 'ACEITAR_PROPOSTA_SUCCESS':
      return {
        ...state,
        isLoader: false,
        isLoading: false,
        statusProp: action.data,
      };
    case 'ACEITAR_PROPOSTAS_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case 'ACEITAR_PROPOSTAS_DEFAULT':
      return {
        ...state,
        statusProp: action.data,
      };
    /** **************************************** */
    case 'APROVADA_PROPOSTA_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'APROVADA_PROPOSTA_SUCCESS':
      return {
        ...state,
        isLoading: false,
        dataBanco: action.data,
      };
    case 'APROVADA_PROPOSTAS_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    /** **************************************** */
    case 'INFO_PAG_PROPOSTA_REQUEST':
      return {
        ...state,
        loadInfoPag: true,
      };
    case 'INFO_PAG_PROPOSTA_SUCCESS':
      return {
        ...state,
        loadInfoPag: false,
        infoPagamento: action.data,
      };
    case 'INFO_PAG_PROPOSTAS_FAILURE':
      return {
        ...state,
        loadInfoPag: false,
        error: true,
      };
    /** **************************************** */
    case 'PAGAMENTO_PROPOSTA_REQUEST':
      return {
        ...state,
        loadInfoPag: true,
      };
    case 'PAGAMENTO_PROPOSTA_SUCCESS':
      return {
        ...state,
        loadInfoPag: false,
        infoPagamento: action.data,
      };
    case 'PAGAMENTO_PROPOSTAS_FAILURE':
      return {
        ...state,
        loadInfoPag: false,
        error: true,
      };
    /** ************************************************* */
    case 'NEW_PROPOSAL_ADD':
      return {
        ...state,
        newProposal: action.data,
      };
    case 'NEW_PROPOSAL_CHANGE_ITEM':
      return {
        ...state,
        newProposal: {
          ...state.newProposal,
          [action.field]: action.value,
        },
      };
    /** ***********************SEM NOTA*************************** */
    case 'NEW_PROPOSAL_SEM_NOTA_ADD':
      return {
        ...state,
        newProposalSemNota: action.data,
      };
    case 'ADD_ITEM_PARCELA_PROPOSTA':
      return {
        ...state,
        newProposalSemNota: {
          ...state.newProposalSemNota,
          parcelas: [...state.newProposalSemNota.parcelas, action.value],
        },
      };
    case 'EDIT_ITEM_PARCELA_PROPOSTA':
      let reord = null;
      let equalsDates = null;
      // eslint-disable-next-line no-unused-vars
      let edit = state.newProposalSemNota.parcelas.map((item, index) => {
        if (item.numero === action.id) {
          if (action.field === 'vencimento') {
            for (let i = 0; i < state.newProposalSemNota.parcelas.length; i++) {
              equalsDates =
                state.newProposalSemNota.parcelas[i].vencimento
                  .toString()
                  .search(action.value.toString()) !== -1;

              if (equalsDates) break;
            }
          }
          return {
            ...item,
            [action.field]: equalsDates ? item.vencimento : action.value,
          };
        }
        return item;
      });

      if (action.field === 'vencimento') {
        edit = edit.sort((a, b) => {
          const c = new Date(a.vencimento);
          const d = new Date(b.vencimento);
          return c - d;
        });

        reord = edit.map((item, index) => {
          return {
            ...item,
            numero: index + 1,
          };
        });
      }

      return {
        ...state,
        newProposalSemNota: {
          ...state.newProposalSemNota,
          parcelas: reord || edit,
        },
      };
    case 'REMOVE_ITEM_PARCELA_PROPOSTA':
      const exclude = state.newProposalSemNota.parcelas.filter(
        // eslint-disable-next-line no-unused-vars
        (item, index) => {
          if (item.numero === action.id) {
            return false;
          }
          return true;
        }
      );

      const reorder = exclude.map((item, index) => {
        return {
          ...item,
          numero: index + 1,
        };
      });

      return {
        ...state,
        newProposalSemNota: {
          ...state.newProposalSemNota,
          parcelas: reorder,
        },
      };
    case 'EDIT_ITEM_DEFAULT_PROPOSTA':
      return {
        ...state,
        newProposalSemNota: {
          ...state.newProposalSemNota,
          [action.field]: action.value,
        },
      };
    case 'SIMULADO_PROPOSTA_SEM_NOTA':
      return {
        ...state,
        simuladoSemNota: action.data,
        isLoadingCalcula: false,
      };
    case 'VIEW_ACTIVE_PROPOSTA':
      return {
        ...state,
        viewActive: action.viewActive,
      };
    case 'RESUMO_SET_DATA':
      return {
        ...state,
        resumoData: action.data,
      };
    case 'UPDATE_RESUMO_SET_DATA':
      return {
        ...state,
        resumoData: {
          ...state.resumoData,
          [action.field]: action.data,
        },
      };
    case 'CHANGE_ALL_RESUMO_SET_DATA':
      return {
        ...state,
        resumoData: action.data,
      };
    case 'PROPOSTA_CHANGE_COMMENTS_SEM_NOTA':
      return {
        ...state,
        newProposalSemNota: {
          ...state.newProposalSemNota,
          [action.field]: action.value,
        },
      };
    case 'RESUMO_SET_DATA_HISTORY':
      return {
        ...state,
        resumoDataHistory: [...state.resumoDataHistory, action.data],
      };
    case 'RESUMO_CLEAR_DATA_HISTORY':
      return {
        ...state,
        resumoDataHistory: action.data,
      };
    default:
      return state;
  }
};

export default propostas;

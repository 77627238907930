import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const NoHover = styled.div`
  opacity: 1;
`;
const OnHover = styled.div`
  position: absolute;
  opacity: 0;
  visibility: hidden;
  z-index: 999;
  transition: all 0.2s ease-in-out;

  ${({ position }) =>
    position === 'left' &&
    `
    top: 50%;
    right: 105%;
    transform: translate(0, -50%);
  `}

  ${({ position }) =>
    position === 'right' &&
    `
    top: 50%;
    left: 105%;
    transform: translate(0, -50%);
  `}

  ${({ align, position }) =>
    position === 'down' &&
    `
    top: 115%;
    ${align === 'left' ? 'left: 0' : ''};
    ${align === 'right' ? 'right: 0' : ''};
    ${align === 'center' ? `left: 50%;transform: translate(-50%, 0);` : ''}
  `}

  ${({ align, position }) =>
    position === 'top' &&
    `
    bottom: 115%;
    ${align === 'left' ? 'left: 0' : ''};
    ${align === 'right' ? 'right: 0' : ''};
    ${align === 'center' ? `left: 50%;transform: translate(-50%, 0);` : ''}
  `}

`;

const Container = styled.div`
  position: relative;

  cursor: pointer;
  &:hover ${NoHover} {
    opacity: 1;
  }
  &:hover ${OnHover} {
    opacity: 1;
    visibility: visible;
  }
  & ${OnHover}:hover {
    opacity: 0;
    visibility: hidden;
  }
`;

const Hover = ({ onHover, position, align, children, style }) => (
  <Container style={style}>
    <NoHover>{children}</NoHover>
    <OnHover position={position} align={align}>
      {onHover}
    </OnHover>
  </Container>
);

Hover.propTypes = {
  onHover: PropTypes.any.isRequired,
  position: PropTypes.string.isRequired,
  align: PropTypes.string,
  children: PropTypes.any.isRequired,
};

Hover.defaultProps = {
  onHover: null,
  position: 'left',
  align: 'center',
  children: null,
};

export default Hover;

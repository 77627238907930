import React, { useContext } from 'react';

import { maskCNPJ } from 'helpers/format';
import { capitalizeWords } from 'helpers/string';
import { ButtonMatch, ButtonAnalise, ButtonNoMatch } from '../Comps/ButtonPdf';
import { FinMatchContext } from '../PageFinmatch';

import {
  Header,
  Icon,
  AddInfo,
  Name,
  SubText,
  Separator,
  Cnpj,
  Segmento,
  IconStampGreen,
  IconStampGrey,
} from './styles.match';

import { IconLockStyle } from '../style.layout';

const statusIcon = (expr) => {
  switch (true) {
    case expr >= 70:
      return <IconStampGreen />;
    case expr >= 50 && expr < 70:
      return <IconStampGrey />;
    case expr < 50:
      return <IconStampGrey />;
    default:
      return <IconStampGrey />;
  }
};

const statusButton = (expr) => {
  switch (true) {
    case expr >= 70:
      return <ButtonMatch onClick={() => {}} />;
    case expr >= 50 && expr < 70:
      return <ButtonAnalise onClick={() => {}} />;
    case expr < 50:
      return <ButtonNoMatch onClick={() => {}} />;
    default:
      return <ButtonNoMatch onClick={() => {}} />;
  }
};

export function RegionHeader() {
  const { data } = useContext(FinMatchContext);

  const capitalizedName = capitalizeWords(data.informacoes_Match.nome);

  return (
    <Header>
      <div style={{ display: 'flex' }}>
        <Icon>{statusIcon(data.informacoes_Match.matchPorcentagem)}</Icon>
        <AddInfo>
          <Name limite="21ch">{capitalizedName}</Name>
          <SubText>
            <Cnpj>
              {data.informacoes_Match.cnpj.indexOf('*') === -1 ? (
                maskCNPJ(data.informacoes_Match.cnpj)
              ) : (
                <IconLockStyle />
              )}
            </Cnpj>
            {data.informacoes_Match.cedente_Segmento && <Separator />}
            <Segmento>
              {capitalizeWords(data.informacoes_Match.cedente_Segmento)}
            </Segmento>
          </SubText>
        </AddInfo>
      </div>
      {statusButton(data.informacoes_Match.matchPorcentagem)}
    </Header>
  );
}

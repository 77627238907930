import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { useSelector } from 'react-redux';

import { useFeedback } from 'hooks/useFeedback';

const ContainerPaginacao = styled.div`
  white-space: nowrap;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Pagination = styled.ul`
  margin: 32px 0 0;
  display: flex;
  text-align: center;
  list-style: none;
`;

const PaginationButton = styled.li`
  color: ${({ theme }) => theme.newtheme.color.azulPrimario};
  margin-left: 4px;
`;

const ArrowTitle = styled.span`
  margin-right: 4px;
`;

const Arrow = styled.span`
  width: 7px;
  height: 7px;
  display: inline-block;
  border-style: solid;
  border-width: 1px 1px 0 0;
  border-color: ${({ theme }) => theme.newtheme.color.cinza6};
  transform: rotate(45deg);
`;

const PaginationLink = styled.a`
  text-decoration: none;
  outline: none;
  width: 36px;
  height: 36px;
  position: relative;
  background-color: transparent;
  font-size: 16px;
  display: block;
  line-height: 1;
  cursor: pointer;
  border-radius: 2px;
  transition: background-color 250ms ease;
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.newtheme.color.cinza6};
  ${({ current }) =>
    current === 'active' &&
    css`
      pointer-events: none;
      background-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
      font-weight: 600;
      color: #ffffff;
    `}
  &:hover {
    background-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
    border: 2px solid transparent;
    color: #ffffff;
  }
  &:hover ${Arrow} {
    border-color: #ffffff;
  }
`;

const PaginationLinkNext = styled(PaginationLink)`
  width: 100px;
`;

const PaginationLinkBack = styled(PaginationLink)`
  width: 100px;
  & ${Arrow} {
    transform: rotate(225deg);
  }
  & ${ArrowTitle} {
    margin-left: 4px;
    margin-right: 0px;
  }
`;

export const NextButton = ({ onclick, sum }) => (
  <PaginationButton>
    <PaginationLinkNext onClick={(e) => onclick(e, sum)}>
      <ArrowTitle>Próxima</ArrowTitle>
      <Arrow></Arrow>
    </PaginationLinkNext>
  </PaginationButton>
);

export const PreviousButton = ({ onclick, subtract }) => (
  <PaginationButton>
    <PaginationLinkBack onClick={(e) => onclick(e, subtract)}>
      <Arrow></Arrow>
      <ArrowTitle>Anterior</ArrowTitle>
    </PaginationLinkBack>
  </PaginationButton>
);

export const FirstButton = ({ onclick, first }) => (
  <PaginationButton>
    <PaginationLinkBack
      style={{ width: '36px' }}
      onClick={(e) => onclick(e, first)}
    >
      <Arrow></Arrow>
      <Arrow></Arrow>
    </PaginationLinkBack>
  </PaginationButton>
);

export const LastButton = ({ onclick, last }) => (
  <PaginationButton>
    <PaginationLinkNext
      style={{ width: '36px' }}
      onClick={(e) => onclick(e, last)}
    >
      <Arrow></Arrow>
      <Arrow></Arrow>
    </PaginationLinkNext>
  </PaginationButton>
);

const NumberButton = ({ onclick, current, value }) => (
  <PaginationButton>
    <PaginationLink
      onClick={(e) => onclick(e, value)}
      current={current && 'active'}
    >
      {value}
    </PaginationLink>
  </PaginationButton>
);

const filterItems = (paginacao, items) => {
  const { totalPaginas, pagina } = paginacao;
  const quantidadeItems = 10;

  const midlle = Math.round(quantidadeItems / 2);

  if (totalPaginas > quantidadeItems) {
    // Pegar elemento maior que a metade do total
    const a = ((pagina / quantidadeItems) * 100).toFixed(3);
    if (a > 50) {
      const val = items.filter(
        (res, index) =>
          index >= pagina - midlle - 1 && index <= pagina + midlle - 1
      );
      return val;
    }
    const filtered = items.filter((res, index) => index < quantidadeItems);
    return filtered;
  }
  return items;
};

const RenderItens = ({ paginacao, onclick }) => {
  const { totalPaginas, pagina } = paginacao;

  const listItems = [];
  for (let i = 1; i <= totalPaginas; i += 1) {
    listItems.push(
      <NumberButton
        key={i}
        onclick={onclick}
        current={pagina === i && 'active'}
        value={i}
      />
    );
  }
  return filterItems(paginacao, listItems);
};

const Paginacao = ({ searchRouter }) => {
  const [totalPages, setTotalPages] = useState(0);
  const uiPaginationState = useSelector((state) => state.uiPagination);
  const query = useSelector((state) => state.router.location.query);

  const { ready } = useFeedback(uiPaginationState);

  const goPage = (e, i) => {
    e.preventDefault();
    e.stopPropagation();
    window.scrollTo(0, 0);

    searchRouter.addSearchRoute({
      page: i,
    });
  };

  const setPages = useCallback(() => {
    const { totalPages } = uiPaginationState.data[0];
    setTotalPages(totalPages);
  }, [uiPaginationState.data]);

  useEffect(() => {
    if (ready) setPages();
  }, [ready, setPages]);

  return (
    ready &&
    totalPages > 1 && (
      <ContainerPaginacao>
        <Pagination>
          {Number(query.page) > 1 && (
            <>
              <FirstButton onclick={goPage} first={1} />
              <PreviousButton
                onclick={goPage}
                subtract={Number(query.page) - 1}
              />
            </>
          )}
          <RenderItens
            paginacao={{ pagina: Number(query.page), totalPaginas: totalPages }}
            onclick={goPage}
          />
          {Number(query.page) < totalPages && (
            <>
              <NextButton onclick={goPage} sum={Number(query.page) + 1} />
              <LastButton onclick={goPage} last={totalPages} />
            </>
          )}
        </Pagination>
      </ContainerPaginacao>
    )
  );
};

export default Paginacao;

import React, { forwardRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { Label, Flex, Info } from './style.tranche';
import { InputCurrency } from '../Input';

export const Tranche = forwardRef((props, ref) => {
  const { disabled } = props;
  return (
    <Flex column style={{ marginBottom: '10px' }}>
      <ReactTooltip
        id="info-tranche"
        wrapper="span"
        place="right"
        type="dark"
        effect="solid"
      >
        <div style={{ width: '200px', wordBreak: 'break-word' }}>
          Tranche é a parcela do limite de crédito disponibilizado
          periodicamente a uma empresa.
        </div>
      </ReactTooltip>
      <Flex
        justify="flex-start"
        style={{ marginBottom: '8px', width: 'calc(100% - 6px)' }}
      >
        <Label>Tranche</Label>
        <Info aria-label="Limite" data-tip data-for="info-tranche" />
      </Flex>
      <InputCurrency disabled={disabled} ref={ref} {...props} />
    </Flex>
  );
});

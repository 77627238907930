/* eslint-disable no-unused-vars */
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import CONFIG from 'config';
import toast from 'react-hot-toast';

import { getUserData } from 'services/getUserData';
import { handleErrors } from 'helpers/requisicoes/handleErrors';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const invite = async (params) => {
  const { idEmpresa, idUsuario } = await getUserData();

  const payload = {
    idGrupo: params.idGrupo,
    idConvenio: idEmpresa,
    idUsuario,
  };

  const mutateData = axios.post(
    `${BASE_URL}/matching/conexao/financiador/grupo`,
    payload
  );

  const result = await mutateData;
  const { data } = result;

  return data || [];
};

export const useMutateInvite = () => {
  const queryClient = useQueryClient();

  return useMutation((params) => invite(params), {
    onMutate: async (params) => {
      await queryClient.cancelQueries(['invitations']);
    },
    onError: (error) => {
      const message = handleErrors(error);
      toast.error(message, { duration: 4000 });
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('search');
      queryClient.invalidateQueries('perfil');
      queryClient.invalidateQueries('invitations');
      queryClient.invalidateQueries('empreendedores');
    },
  });
};

import React from 'react';
import styled from 'styled-components/macro';
import { ModalCuston } from 'components/FinModalCuston';
import { Condicionante } from 'components/Condicionante';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const PaginaCondicionante = ({ history, isModal }) => {
  const toBack = () => history.goBack();
  return isModal ? (
    <ModalCuston
      open={true}
      title="Condicionantes"
      scrolling="true"
      onClose={toBack}
    >
      <Container>
        <Condicionante />
      </Container>
    </ModalCuston>
  ) : (
    <Container style={{ marginTop: '8rem' }}>
      <Condicionante />
    </Container>
  );
};

export default PaginaCondicionante;

const INITIAL_STATE = {
  data: [],
  isLoading: null,
  complete: false,
};

const listDownload = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ADD_ITEM_LIST_DOWNLOAD':
      return {
        data: [...state.data, action.value],
      };
    case 'ADD_ALL_ITEMS_LIST_DOWNLOAD':
      return {
        data: [...action.data],
      };
    case 'REMOVER_ITEM_LIST_DOWNLOAD':
      // eslint-disable-next-line no-case-declarations
      const mantem = state.data.filter((item) => {
        if (item.idDocumento === action.value) {
          return false;
        }
        return true;
      });
      return {
        data: [...mantem],
      };
    case 'CLEAR_ALL_ITEMS_LIST_DOWNLOAD':
      return {
        data: [],
      };
    case 'SAVE_DOC_FILE_ZIP':
      return {
        ...state,
        isLoading: true,
      };
    case 'SAVE_DOC_FILE_ZIP_SUCCESS':
      return {
        ...state,
        isLoading: false,
        complete: true,
      };
    case 'SAVE_DOC_FILE_ZIP_FAILURE':
      return {
        ...state,
        isLoading: false,
      };
    case 'SAVE_DOC_FILE_ZIP_CLEAR':
      return {
        data: [],
        isLoading: null,
        complete: false,
      };
    default:
      return state;
  }
};

export default listDownload;

import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Head = styled.div`
  display: flex;
  width: 310px;
  justify-content: flex-start;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

import axios from 'axios';

import { getUserData } from 'services/getUserData';
import config from 'config';

import { iframeSignin, removeUser } from 'auth/nwUserManager';

const UNAUTHORIZED_URLS = [
  'https://finplaceidentityproviderdev.azurewebsites.net/connect/token',
  'http://localhost:4000/acesso/loga',
  `${config.API_REMODELING_URL}/user-api/api/acesso/login`,
];

const NOT_RENEW_TOKEN_URLS = [
  `${config.API_REMODELING_URL}/business-api/api/matching/conexao/financiador/pendentes/recebidos`,
];

export async function addAuthentication(config) {
  const user = await getUserData();

  if (!UNAUTHORIZED_URLS.includes(config.url) && user) {
    config.headers.Accept = 'application/json';
    config.headers.Authorization = `Bearer ${user.access_token}`;
  }

  return config;
}

const createAxiosResponseInterceptor = () => {
  const interceptor = axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error && error.config;

      if (NOT_RENEW_TOKEN_URLS.includes(error.response.request.responseURL)) {
        return Promise.reject(error);
      }

      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;

        /*
         * When response code is 401, try to refresh the token.
         * Eject the interceptor so it doesn't loop in case
         * token refresh causes the 401 response
         */
        axios.interceptors.response.eject(interceptor);

        return iframeSignin()
          .then((user) => {
            error.response.config.headers.Authorization = `Bearer ${user.access_token}`;
            return axios(error.response.config);
          })
          .catch((error) => {
            removeUser();
            return Promise.reject(error);
          })
          .finally(createAxiosResponseInterceptor);
      }
      return Promise.reject(error);
    }
  );
};

axios.interceptors.request.use((config) => {
  return addAuthentication(config);
});

createAxiosResponseInterceptor();

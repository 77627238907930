import React from 'react';

import { truncate } from 'helpers/string';
import { User } from './User';
import { Tag } from './styles.user';
import { NWTable } from '../Comps/Table';

export function TableRow({ data }) {
  return (
    <NWTable.Tr>
      <NWTable.Td>
        <User data={data} />
      </NWTable.Td>
      <NWTable.Td style={{ fontSize: '0.875rem' }}>
        {data.cpfCnpj && data.cpfCnpj}
      </NWTable.Td>
      <NWTable.Td>
        {data.socioTipo && <Tag>{truncate(data.socioTipo, 20)}</Tag>}
      </NWTable.Td>
    </NWTable.Tr>
  );
}

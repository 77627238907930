import { useContext } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import CONFIG from 'config';
import { flatten } from 'ramda';
import { OperacoesContext } from '../provider';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const fetchListaBordero = async (params) => {
  const fetchData = axios.get(
    `${BASE_URL}/bordero/sacados/titulos/${params.idBordero}`
  );

  const result = await fetchData;
  const { data } = result;

  return data.data || [];
};

export const useFetchListaBordero = (params) => {
  const [state, actions] = useContext(OperacoesContext);

  const {
    modal: { values },
    bordero: { selecionados },
  } = state;

  const heComesFromModal = values ? !!values.rules : false;

  const generatesSelecteds = (data) => {
    const { sacados } = data;

    const titulosNotSelected = flatten(
      sacados.map((sacado) => sacado).map((titulos) => titulos.titulos)
    )
      .filter((ti) => !ti.selecionado)
      .map((t) => ({ id: t.id, valor: t.valorAlterado || t.valor }));

    const titulos = flatten(
      sacados.map((sacado) => sacado).map((titulos) => titulos.titulos)
    ).map((t) => ({ id: t.id, valor: t.valorAlterado || t.valor }));

    actions.setSelecao({ value: titulos });
    if (titulosNotSelected.length > 0) {
      actions.removeSelecao({ value: titulosNotSelected });
    }
  };

  return useQuery(
    ['bordero', 'lista', params],
    () => fetchListaBordero(params),
    {
      keepPreviousData: false,
      onSuccess: (data) => {
        if (selecionados.length === 0 && !heComesFromModal) {
          // console.log('[DEBUG] listaBordero', data);
          generatesSelecteds(data);
        }
      },
    }
  );
};

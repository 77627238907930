import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import iconChevron from 'assets/images/icon-caret-right.svg';

export const BreadcrumbNav = styled.nav`
  padding: 1rem 0;
`;

export const Breadcrumb = styled.ol`
  display: flex;
`;

export const BreadcrumbItem = styled.li`
  list-style: none;
`;

export const BreadcrumbLink = styled(Link)`
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4rem;
  text-rendering: geometricprecision;
  color: var(--finblue-primary);

  ${({ to }) =>
    !to &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      color: var(--fingray-900);
    `}

  &:hover {
    color: var(--fingreen300);
  }
`;

export const ContentItem = styled.span`
  display: flex;
  align-items: center;
  ${({ noIcon }) =>
    !noIcon &&
    css`
      &::after {
        content: '';
        width: 18px;
        height: 18px;
        margin: 0 0.5rem;
        display: inline-flex;
        background: url(${iconChevron}) no-repeat center center;
      }
    `}
`;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Step } from '../Step';

import { Header, Body, Emoji } from '../style.wizard';

export const Step2 = React.forwardRef((props, ref) => {
  return (
    <Step {...props} ref={ref} position="top">
      <Header>
        <Emoji>🔥</Emoji> Novidade - Sócios
      </Header>
      <Body>
        <p>Tenha acesso ao quadro societário e as empresas relacionadas.</p>
      </Body>
    </Step>
  );
});

import styled, { css } from 'styled-components/macro';

import { FinAvatar } from 'components/FinAvatar';
import { Content } from '../Contato/styles.infocontato';

const truncate = css`
  width: max-content;
  max-width: ${({ limite }) => limite || `23ch`};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Container = styled(Content)`
  display: flex;
  flex-direction: row;

  & span:first-child {
    width: 400px;
  }

  & span:last-child {
    padding-bottom: 0.625rem;
  }
`;

export const Flex = styled.div`
  display: flex;
  ${({ row }) =>
    row &&
    css`
      flex-direction: row;
    `}

  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
    `}

  justify-content: ${({ justify }) => justify || `center`};
  align-items: ${({ align }) => align || `center`};
  width: 100%;
`;

export const Name = styled.span`
  font-family: 'Inter', sans-serif;
  color: var(--fingray-900);
  font-size: 1rem;
  font-weight: 400;
  text-rendering: geometricprecision;
  white-space: nowrap;
  text-align: left;
  ${truncate}
`;

export const Cnpj = styled.span`
  font-family: 'Inter', sans-serif;
  color: var(--fingray-900);
  font-size: 1rem;
  font-weight: 400;
  text-rendering: geometricprecision;
  white-space: nowrap;
`;

export const FinAvatarCuston = styled(FinAvatar)`
  border-color: var(--finblue-primary);
`;

export const ButtonItem = styled(Flex)`
  border-radius: 12px;
  cursor: pointer;
  height: 58px;
  padding: 0 1rem;

  &:hover {
    background: var(--finblue-tertiary);
  }
`;

// eslint-disable-next-line no-console

import React, { useEffect, useState, useCallback } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';

import { useFeedback } from 'hooks/useFeedback';

import { Creators as apiMATCHLISTAGEMAction } from 'store/ducks/apiMatchObtemListagem';
import { Creators as apiMATCHCADASTROAction } from 'store/ducks/apiMatchCadastro';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { SeparatorVertical, SeparatorHorizontal } from 'components/Elements';
import {
  InputFormatMaterial,
  // InputFormatNumberMaterial,
} from 'components/FinInput/InputMaterial';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { InputMaterial } from 'components/Input';
// import { maskCNPJ } from '../utils/formatValues';

import { /* Button */ ButtonSubmit } from 'components/Button';
import { MultiSelectSegmento, SelectMatch } from 'components/Select';

// import Cnae from './Cnae';
import { postMatchRequest } from 'store/old_actions/actions';

import { Card, Title, Row, FullContainerLoad } from '../Styles';
// import CalendarsDates from '../utils/calendar';
import { Theme } from '../../../theme';

// import TextInputFormikRedux from '../components/Input/localizacao';

const Match = ({ acaoConfigurarMatch }) => {
  // const [verBusca, setVerBusca] = useState(false);

  // const [buscaCnae, setBuscaCnae] = useState(false);
  const user = JSON.parse(window.localStorage.getItem('userData'));

  const [verCarregandoMatch, setVerCarregandoMatch] = useState(true);

  // eslint-disable-next-line no-unused-vars
  const [salvar, setSalvar] = useState(false);
  // const [liminar, setLiminar] = useState([]);

  const apiMATCHLISTAGEMState = useSelector((state) => state.apiMATCHLISTAGEM);
  // const apiMATCHCADASTROState = useSelector((state) => state.apiMATCHCADASTRO);

  const { ready, waiting, error, empty } = useFeedback(apiMATCHLISTAGEMState);

  const dispatch = useDispatch();

  const resquestDados = useCallback(() => {
    // if (!salvar) {
    dispatch(apiMATCHLISTAGEMAction.apiMATCHLISTAGEMRequest());
    setVerCarregandoMatch(false);
    setSalvar(false);
    // }
  }, [dispatch]);

  const salvaDados = useCallback(
    (fields) => {
      dispatch(apiMATCHCADASTROAction.apiMATCHCADASTRORequest(fields));

      setSalvar(true);
      setTimeout(() => {
        resquestDados();
        acaoConfigurarMatch();

        document
          .getElementById('areaRolegem')
          .scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }, 3000);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  // let dadosCnaeRemovidos = [];
  const setInitialValues = useCallback(() => {
    const [data] = apiMATCHLISTAGEMState.data;

    let initialValues = {
      segmentos: [
        {
          desc_segmento: '',
          idsegmento: '',
          selecionado: false,
        },
      ],
      filtroFinMatchConvenio: [
        {
          faturamento: 0,
          fundacao: 0,
          alteracao_societaria: 0,
          liminar: null,
          refin: 0,
          pefin: 0,
          ccf: 0,
        },
      ],
      // cnaes: [],
    };

    if (data && Object.keys(data).length > 0 && verCarregandoMatch === false) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      // dadosCnaeRemovidos = data.data.cnaes;
      // const filtroFinMatchConvenioDados = data.data.filtroFinMatchConvenio;

      initialValues = {
        // filtroFinMatchConvenio: filtroFinMatchConvenioDados,
        segmentos: data.data.segmentos,
        faturamento: data.data.filtroFinMatchConvenio.faturamento,
        fundacao: data.data.filtroFinMatchConvenio.fundacao,
        alteracao_societaria:
          data.data.filtroFinMatchConvenio.alteracao_societaria,
        // cnaes: data.data.cnaes,
        liminar: data.data.filtroFinMatchConvenio.liminar,
        refin: data.data.filtroFinMatchConvenio.refin,
        pefin: data.data.filtroFinMatchConvenio.pefin,
        ccf: data.data.filtroFinMatchConvenio.ccf,
      };

      return initialValues;
    }

    return initialValues;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiMATCHLISTAGEMState]);

  const submitContent = (payload, { setSubmitting }) => {
    setVerCarregandoMatch(true);
    // setVerBusca(false);
    setSubmitting(true);
    document
      .getElementById('areaRolegem')
      .scrollTo({ top: 890, left: 0, behavior: 'smooth' });
    setSalvar(true);

    // eslint-disable-next-line no-console
    console.log('payload');
    // eslint-disable-next-line no-console
    console.log(payload);

    const valor2 = payload.segmentos;

    const segmentos2 = valor2.filter((valor2) => valor2.selecionado === true);

    // let cnaesRemovidos2 = payload.cnaesRemovidos;
    // const liminiar = payload.liminar === 'null' ? null : payload.liminar;
    // cnaesRemovidos2 = cnaesRemovidos2
    //   .filter((item) => item.removido === true)
    //   .map(({ cnaE_cod }) => ({ cnaE_cod }));

    let vlLiminar = payload.liminar;

    if (vlLiminar === 'true' || vlLiminar === true) {
      vlLiminar = true;
    } else if (vlLiminar === 'false' || vlLiminar === false) {
      vlLiminar = false;
    } else {
      vlLiminar = null;
    }

    const filtroFinMatchConvenio = {
      idconvenio: user.idEmpresa,
      idusuarioconvenio: user.idUsuario,
      faturamento: payload.faturamento,
      fundacao: payload.fundacao,
      alteracao_societaria: payload.alteracao_societaria,
      liminar: vlLiminar,
      refin: payload.refin,
      pefin: payload.pefin,
      // eslint-disable-next-line radix
      ccf: parseInt(payload.ccf),
    };

    const fields = {
      filtroFinMatchConvenio,
      // cnaes: payload.cnaes,
      segmentos: segmentos2,
    };

    // props.postMatchRequest(fields);

    setSubmitting(false);

    salvaDados(fields);
  };

  const schema = Yup.object().shape({
    faturamento: Yup.string().required('Preencha esse dado.'),
    fundacao: Yup.string().required('Preencha esse dado.'),
    alteracao_societaria: Yup.string().required('Preencha esse dado.'),
    // liminar: Yup.string().required('Preencha esse dado.'),
    refin: Yup.string().required('Preencha esse dado.'),
    pefin: Yup.string().required('Preencha esse dado.'),
    ccf: Yup.string().required('Preencha esse dado.'),
  });

  useEffect(() => {
    // setBuscaCnae(false);
    resquestDados();
  }, [resquestDados]);

  const dadosIniciais = setInitialValues();

  return (
    <>
      <Card>
        <Title>Configuração Match</Title>
        <p>
          Preencha os campos abaixo de acordo com a política de crédito
          utilizada pela sua empresa. A partir desses parâmetros, iremos usar o
          motor de inteligência do Finmatch para informar quantas empresas na
          nossa base estão aderentes ao seu perfil.
        </p>
        <SeparatorHorizontal hsize="1.5rem 0" />
        {verCarregandoMatch && (
          <FullContainerLoad>
            <ScaleLoader
              css=""
              size={150}
              color={Theme.primary}
              loading={true}
            />
          </FullContainerLoad>
        )}
        {empty && <p>{empty}</p>}
        {error && <p>{error}</p>}
        {waiting && (
          <FullContainerLoad>
            <ScaleLoader
              css=""
              size={150}
              color={Theme.primary}
              loading={true}
            />
          </FullContainerLoad>
        )}
        {ready && (
          <Formik
            initialValues={dadosIniciais}
            onSubmit={submitContent}
            validationSchema={schema}
          >
            {(props) => {
              const {
                values,
                handleSubmit,
                setFieldValue,
                handleChange,
                handleBlur,
                // errors,
                // touched,
              } = props;
              return (
                // eslint-disable-next-line no-script-url
                <form onSubmit={handleSubmit}>
                  <Row>
                    <MultiSelectSegmento
                      id="segmentos"
                      field="codigodescricao"
                      tooltip="Quais segmentos você tem interesse?"
                      label="Segmentos"
                      largura="35%"
                      // data={values.segmento}
                      value={values.segmentos}
                      handleChange={handleChange}
                      error=""
                      fontSize="1rem"
                      errormessage=""
                      setFieldValue={setFieldValue}
                    />
                    <SeparatorVertical borderColor={Theme.gray1} wsize="2rem" />
                    <InputFormatMaterial
                      id="faturamento"
                      label="Faturamento"
                      prefix="R$ "
                      handleChange={handleChange}
                      retornaValor={handleChange}
                      handleBlur={handleBlur}
                      tooltip="Qual o faturamento mínimo anual a empresa precisa ter?"
                      value={values.faturamento}
                      // value={checkInputs('faturamento')}
                      largura="35%"
                      colorLabel="#B8B8B8"
                      fontSize="1rem"
                      setFieldValue={setFieldValue}
                    />
                  </Row>
                  <Row>
                    <InputMaterial
                      id="fundacao"
                      label="Fundação"
                      handleChange={handleChange}
                      tooltip="Qual o tempo mínimo de fundação?"
                      handleBlur={handleBlur}
                      value={values.fundacao}
                      largura="35%"
                      setFieldValue={setFieldValue}
                      sufixo="meses"
                    />
                    <SeparatorVertical borderColor={Theme.gray1} wsize="2rem" />

                    <InputMaterial
                      id="alteracao_societaria"
                      label="Alteração Societária"
                      largura="35%"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      tooltip="Não poderá haver alteração no quadro societário menor que quantos meses?"
                      value={values.alteracao_societaria}
                      setFieldValue={setFieldValue}
                      sufixo="meses"
                    />
                  </Row>
                  <Row>
                    {/* {Object.keys(dadosCnaeRemovidos).length > 0 && (
                      <Cnae
                        fechar={verBusca}
                        cnaesRemovidos={dadosCnaeRemovidos}
                      />
                    )} */}

                    {/* <SeparatorVertical borderColor={Theme.gray1} wsize="2rem" /> */}
                    <SelectMatch
                      id="liminar"
                      label="Liminar"
                      largura="35%"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      tooltip="Você atende clientes com Liminar?"
                      setFieldValue={setFieldValue}
                      value={values.liminar}
                      data={values.liminar}
                    />
                    <SeparatorVertical borderColor={Theme.gray1} wsize="2rem" />

                    <InputMaterial
                      id="refin"
                      label="Refin"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      largura="35%"
                      tooltip="Qual o % máximo de REFIN sobre o faturamento a empresa poderá ter?"
                      setFieldValue={setFieldValue}
                      value={values.refin}
                      sufixo="%"
                      colorLabel="#B8B8B8"
                      fontSize="1rem"
                    />
                  </Row>
                  <Row>
                    <InputMaterial
                      id="pefin"
                      label="Pefin"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      largura="35%"
                      tooltip="Qual o % máximo de PEFIN sobre o faturamento a empresa poderá ter?"
                      value={values.pefin}
                      setFieldValue={setFieldValue}
                      sufixo="%"
                      colorLabel="#B8B8B8"
                      fontSize="1rem"
                    />
                    <SeparatorVertical borderColor={Theme.gray1} wsize="2rem" />

                    <InputMaterial
                      id="ccf"
                      label="CCF"
                      largura="35%"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.ccf}
                      tooltip="Qual a quantidade máxima de CCF (cheque devolvido) a empresa poderá ter?"
                      setFieldValue={setFieldValue}
                    />
                  </Row>

                  <ButtonSubmit type="submit" tipo="primary">
                    Salvar
                  </ButtonSubmit>
                </form>
              );
            }}
          </Formik>
        )}
      </Card>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    postMatchRequest: (form) => dispatch(postMatchRequest(form)),
    // loadMatchRequest: () => dispatch(loadMatchRequest()),
  };
};

export default connect(mapDispatchToProps)(Match);

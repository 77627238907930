import React from 'react';

import messages from 'helpers/messages/index.json';

import { SeparatorHorizontal } from 'components/FinSeparador';
import { Flex, Title, Text, InputTextArea } from './style.dialog';
import { NWButton } from '../Button';

import { useQueryListReason } from '../../hook/useQueryListReason';
import { NWDropdown } from '../Dropdown';

export function RefuseInvite({ refuse, onClose }) {
  const [observacao, setObservacao] = React.useState('');
  const [motivo, setMotivo] = React.useState(0);

  const { data } = useQueryListReason({ idTipoEmpresa: 1 });

  const withData = data && data.data;

  const handleChange = (e) => {
    setMotivo(Number(e.target.value));
  };

  const values =
    withData && data.data ? [{ id: 0, nome: '' }, ...data.data] : [];

  React.useEffect(() => {
    if (withData) {
      setMotivo(0);
    }
  }, [withData]);

  return (
    <Flex direction="column" style={{ padding: '2rem' }}>
      <Title>{messages.dialog.recusarConvite.titulo}</Title>
      <Text>{messages.dialog.recusarConvite.texto}</Text>
      <SeparatorHorizontal ppadding="1rem 0" />
      <Flex direction="column" style={{ width: '100%' }}>
        <NWDropdown.Select
          label="Ah! Por favor, nos informe o motivo da sua recusa:"
          largura="100%"
          value={motivo}
          onChange={handleChange}
        >
          {withData &&
            values.map((item) => {
              return (
                <NWDropdown.Option key={item.id} value={item.id}>
                  {item.nome}
                </NWDropdown.Option>
              );
            })}
        </NWDropdown.Select>
        <SeparatorHorizontal ppadding="0.5rem 0" />
        <InputTextArea
          label="Observação"
          // error={motivo === 9 && observacao === ''}
          onChange={(e) => setObservacao(e.target.value)}
          errormessage="Preencha o campo"
        />
      </Flex>
      <SeparatorHorizontal ppadding="0.5rem 0" />
      <Flex
        justify="flex-end"
        style={{ width: '100%', gap: '10px', marginTop: '2rem' }}
      >
        <NWButton.Secondary onClick={onClose}>
          {messages.dialog.recusarConvite.labelCancelar}
        </NWButton.Secondary>
        <NWButton.Error
          disabled={(motivo === 9 && observacao === '') || motivo === 0}
          largura="padrao"
          onClick={() => {
            refuse({
              idTipoRecusa: motivo,
              obs: observacao,
            });
            onClose();
          }}
        >
          {messages.dialog.recusarConvite.labelConfirme}
        </NWButton.Error>
      </Flex>
    </Flex>
  );
}

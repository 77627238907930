const INITIAL_STATE = {
  data: [],
  isLoading: false,
  error: false,
  isLoadingCondic: false,
  errorCondic: false,
  dataCondic: [],
  isLoadingExcluir: false,
  errorExcluir: false,
  dataExcluir: [],
  errorCedente: false,
  isLoadingCedente: false,
  cedenteDataCondic: [],
  isLoadingFilterd: false,
  errorFiltered: false,
  dataFiltered: [],
  isLoadingSend: false,
  errorSend: false,
  dataSend: [],
  dataCreate: [],
  isLoadingCancelar: false,
  dataCancelar: [],
  errorCancelar: false,
};

const condicionante = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    /********************** LIST **********************/
    case 'LOAD_LIST_CONDIC_REQUEST':
      console.log(action);
      return {
        ...state,
        isLoading: true,
      };
    case 'LOAD_LIST_CONDIC_SUCCESS':
      console.log(action);
      return {
        ...state,
        data: action.data,
        isLoading: false,
      };
    case 'LOAD_LIST_CONDIC_FAILURE':
      console.log(action);
      return {
        ...state,
        data: action.data,
        isLoading: false,
        error: true,
      };
    case 'CLEAR_LIST_CONDIC':
      console.log(action);
      return {
        ...state,
        data: [],
      };
    case 'SET_LOAD_LIST_CONDIC':
      console.log(action);
      return {
        ...state,
        isLoading: true,
      };
    /********************** Concluir **********************/
    case 'CONCLUIR_CONDIC_REQUEST':
      console.log(action);
      return {
        ...state,
        isLoadingCondic: true,
      };
    case 'CONCLUIR_CONDIC_SUCCESS':
      console.log(action);
      return {
        ...state,
        dataCondic: action.data,
        isLoadingCondic: false,
      };
    case 'CONCLUIR_CONDIC_FAILURE':
      console.log(action);
      return {
        ...state,
        dataCondic: action.data,
        isLoadingCondic: false,
        errorCondic: true,
      };
    /********************** Excluir **********************/
    case 'EXCLUIR_CONDIC_REQUEST':
      console.log(action);
      return {
        ...state,
        isLoadingExcluir: true,
      };
    case 'EXCLUIR_CONDIC_SUCCESS':
      console.log(action);
      return {
        ...state,
        dataExcluir: action.data,
        isLoadingExcluir: false,
      };
    case 'EXCLUIR_CONDIC_FAILURE':
      console.log(action);
      return {
        ...state,
        dataExcluir: action.data,
        isLoadingExcluir: false,
        errorExcluir: true,
      };
    /********************** Cancelar **********************/
    case 'CANCELAR_CONDIC_REQUEST':
      console.log(action);
      return {
        ...state,
        isLoadingCancelar: true,
      };
    case 'CANCELAR_CONDIC_SUCCESS':
      console.log(action);
      return {
        ...state,
        dataCancelar: action.data,
        isLoadingCancelar: false,
      };
    case 'CANCELAR_CONDIC_FAILURE':
      console.log(action);
      return {
        ...state,
        dataCancelar: action.data,
        isLoadingCancelar: false,
        errorCancelar: true,
      };
    /********************** Load Cedente **********************/
    case 'LOAD_LIST_CEDENTE_REQUEST':
      console.log(action);
      return {
        ...state,
        isLoadingCedente: true,
      };
    case 'LOAD_LIST_CEDENTE_SUCCESS':
      console.log(action.data);
      return {
        ...state,
        isLoadingCedente: false,
        cedenteDataCondic: action.data,
      };
    case 'LOAD_LIST_CEDENTE_FAILURE':
      console.log(action);
      return {
        ...state,
        cedenteDataCondic: action.data,
        isLoadingCedente: false,
        errorCedente: true,
      };
    case 'LOAD_LIST_CEDENTE_CLEAR':
      console.log(action);
      return {
        ...state,
        cedenteDataCondic: [],
      };
    /********************** Filterd Cedente **********************/
    case 'FILTERD_LIST_CEDENTE_REQUEST':
      console.log(action);
      return {
        ...state,
        isLoadingFilterd: true,
      };
    case 'FILTERD_LIST_CEDENTE_SUCCESS':
      console.log(action.data);
      return {
        ...state,
        isLoadingFilterd: false,
        dataFiltered: action.data,
      };
    case 'FILTERD_LIST_CEDENTE_FAILURE':
      console.log(action);
      return {
        ...state,
        dataFiltered: action.data,
        isLoadingFilterd: false,
        errorFiltered: true,
      };
    case 'SET_FILTERED_CEDENTE_DEFAULT':
      console.log(action);
      return {
        ...state,
        dataFiltered: [],
      };
    /********************** Criar  **********************/
    case 'SEND_CONDIC_REQUEST':
      console.log(action);
      return {
        ...state,
        isLoadingSend: true,
        errorSend: false,
      };
    case 'SEND_CONDIC_SUCCESS':
      console.log(action.data);
      return {
        ...state,
        isLoadingSend: false,
        dataSend: action.data,
        errorSend: false,
      };
    case 'SEND_CONDIC_FAILURE':
      console.log(action);
      return {
        ...state,
        dataSend: action.data,
        isLoadingSend: false,
        errorSend: true,
      };
    case 'CREATE_CONDIC_DEFAULT':
      console.log(action);
      return {
        ...state,
        dataCreate: action.data,
      };
    case 'UPDATE_CONDIC_DEFAULT':
      console.log(action);
      return {
        ...state,
        dataCreate: { ...state.dataCreate, [action.field]: action.data },
      };

    default:
      return state;
  }
};

export default condicionante;

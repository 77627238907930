import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as uiDialogActions } from 'store/ducks/uiDialog';
import { ModalCuston } from 'components/FinModalCuston';
import { useActions } from 'hooks/useActions';
import Button from 'components/FinButton';
import { finTheme } from 'finTheme';
import { SeparatorVertical } from 'components/FinSeparador';
import { isFunction } from 'lodash';

export const DialogCuston = ({
  disablePrimaryButton,
  whenConfirming,
  whenClosing,
  aditionalPayloadToAction,
  actionPrimaryCuston,
  actionSecondaryCuston,
  customTitle,
  largura,
  children,
}) => {
  const uiDialogState = useSelector((state) => state.uiDialog);
  const uiDialog = useActions(uiDialogActions);
  const dispatch = useDispatch();

  const uiDialogClose = useCallback(() => {
    uiDialog.uiDialogClose();
    if (whenClosing) {
      whenClosing();
    }
  }, [uiDialog, whenClosing]);

  const dispatchAction = useCallback(
    (type) => {
      dispatch(type);
      uiDialogClose();
      if (whenConfirming) {
        whenConfirming();
      }
    },
    [dispatch, uiDialogClose, whenConfirming]
  );

  const callActions = ({ action }) => {
    if (isFunction(action)) {
      action(aditionalPayloadToAction);
      uiDialogClose();
    } else {
      dispatchAction({
        ...action,
        ...aditionalPayloadToAction,
      });
    }
  };

  const ButtonComp = () => (
    <>
      {uiDialogState.buttons[1].show && (
        <>
          <Button
            rounded
            tipo="secondary"
            size={finTheme.newtheme.buttons.sizes.smallM}
            onClick={() => {
              callActions({
                action:
                  actionSecondaryCuston || uiDialogState.buttons[1].action,
              });
            }}
          >
            {uiDialogState.buttons[1].name}
          </Button>
        </>
      )}

      {uiDialogState.buttons[0].show && (
        <>
          <SeparatorVertical pwidth="0.5rem" />
          <Button
            rounded
            tipo="primary"
            disabled={disablePrimaryButton}
            size={finTheme.newtheme.buttons.sizes.smallM}
            onClick={() => {
              callActions({
                action: actionPrimaryCuston || uiDialogState.buttons[0].action,
              });
            }}
          >
            {uiDialogState.buttons[0].name}
          </Button>
        </>
      )}
    </>
  );
  return (
    uiDialogState.show && (
      <ModalCuston
        center={true}
        zIndex={700}
        width={largura || '650px'}
        title={customTitle || uiDialogState.title}
        open={uiDialogState.show}
        scrolling="true"
        onClose={() => uiDialogClose()}
        footer={<ButtonComp />}
      >
        {children}
      </ModalCuston>
    )
  );
};

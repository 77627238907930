import React, { useState } from 'react';
import styled from 'styled-components/macro';

// import { SeparatorHorizontal } from 'components/Elements';
// import { Theme } from 'theme';
// import ImageIco from 'images/iconsSvg/finmatch_symbol.svg';
import ImageIco from 'images/iconsSvg/finmatch.svg';
import DashboardMatching from './Finmatch/DashboardMatching';
import Match from './Finmatch/Match';
// import ModalNovaFuncionalidade from './Finmatch/ModalNovaFuncionalidade';

const Container = styled.div`
  margin: 0 auto;
  width: 78%;
  padding-top: 0rem;
  padding-bottom: 10rem;
`;

const Wrapper = styled.div`
  padding: 3rem 0 1rem 0;
`;

const Title = styled.span`
  font-weight: 600;
  color: rgba(0, 0, 0, 0);
  font-size: 2.3rem;
  display: block;
  background: url(${ImageIco}) no-repeat;
  background-size: contain;
  width: 250px;
  height: 50px;
`;
const TitleH3 = styled.h3`
  margin: 0rem 0 1.5rem 0;
  font-size: 1rem;
  color: #646464;
`;
const Card = styled.div`
  position: relative;
  width: 100%;
  padding: 3rem;

  border-radius: 0.3rem;
  background-color: #ffffff;
  min-height: 350px;
`;

const FinmatchConteudo = () => {
  const [configurarMatch, setConfigurarMatch] = useState(false);

  const testeAndrew = () => {
    setConfigurarMatch(!configurarMatch);
  };
  return (
    <>
      <Container>
        <Wrapper>
          <Title>Finmatch</Title>
        </Wrapper>

        {!configurarMatch && (
          <Card>
            <TitleH3>
              Confira o percentual de adesão da base de clientes da Finplace em
              relação a política de crédito da sua empresa.
            </TitleH3>
            <DashboardMatching acaoConfigurarMatch={testeAndrew} />
          </Card>
        )}

        {/* <SeparatorHorizontal hsize="1.5rem 0" /> */}
        {configurarMatch && (
          <Card>
            <Match acaoConfigurarMatch={testeAndrew} />
          </Card>
        )}
      </Container>
    </>
  );
};

export default FinmatchConteudo;

import React from 'react';
import styled from 'styled-components/macro';
import SVG from 'react-inlinesvg';

export const ATTRS = {
  width: '16',
  height: '16',
  fill: 'none',
  stroke: 'currentColor',
  strokeWidth: '2',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  viewBox: '0 0 16 16',
};

/**
 * Retorna uri svg
 */
export const MakeSvgUri = props => {
  const {
    viewBox,
    width,
    height,
    fill,
    stroke,
    strokeWidth,
    strokeLinecap,
    strokeLinejoin,
    classe,
    data,
  } = props;
  /* eslint-disable */
  const svg = `
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        ${viewBox ? `viewBox=${'"' + viewBox + '"'}` : ''}
        width=${width ? '"' + width + '"' : '"' + ATTRS.width + '"'}
        height=${height ? '"' + height + '"' : '"' + ATTRS.height + '"'}
        fill=${fill ? '"' + fill + '"' : '"' + ATTRS.fill + '"'}
        stroke=${stroke ? '"' + stroke + '"' : '"' + ATTRS.stroke + '"'}
        stroke-width=${
          strokeWidth ? '"' + strokeWidth + '"' : '"' + ATTRS.strokeWidth + '"'
        }
        stroke-linecap=${
          strokeLinecap
            ? '"' + strokeLinecap + '"'
            : '"' + ATTRS.strokeLinecap + '"'
        }
        stroke-linejoin=${
          strokeLinejoin
            ? '"' + strokeLinejoin + '"'
            : '"' + ATTRS.strokeLinejoin + '"'
        }
        ${classe ? 'class="' + classe + '"' : ''}
      >${data}</svg>
    `;
  return `data:image/svg+xml;base64,${btoa(svg)}`;
};

/**
 * Retorna component svg
 */
const Svg = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
})`
  width: ${({ width }) => width || ATTRS.width + 'px'};
  height: ${({ height }) => height || ATTRS.height + 'px'};
  fill: ${({ fill }) => fill || ATTRS.fill};
  stroke: ${({ stroke }) => stroke || ATTRS.stroke};
  stroke-width: ${({ strokeWidth }) => strokeWidth || ATTRS.strokeWidth};
  stroke-linecap: ${({ strokeLinecap }) =>
    strokeLinecap || ATTRS.strokeLinecap};
  stroke-linejoin: ${({ strokeLinejoin }) =>
    strokeLinejoin || ATTRS.strokeLinejoin};
`;
export const MakeSvgComp = ({ className, viewBox, children, ...rest }) => {
  if (typeof children === 'string') {
    const svg = MakeSvgUri({ data: children, viewBox: viewBox, ...rest });
    return <SVG src={svg} />;
  } else {
    return (
      <Svg viewBox={viewBox || ATTRS.viewBox} className={className} {...rest}>
        {children}
      </Svg>
    );
  }
};

/**
 * Retorna component react-icon styled
 */
export const MakeStyledReactIcon = ({ component: Component, ...rest }) => {
  const Icon = props => <Component className={props.className} />;

  const StyledIcon = styled(Icon)`
    width: ${({ width }) => width || ATTRS.width};
    height: ${({ height }) => height || ATTRS.height};
    fill: ${({ fill }) => fill || ATTRS.fill};
    stroke: ${({ stroke }) => stroke || ATTRS.stroke};
    stroke-width: ${({ strokeWidth }) => strokeWidth || ATTRS.strokeWidth};
    stroke-linecap: ${({ strokeLinecap }) =>
      strokeLinecap || ATTRS.strokeLinecap};
    stroke-linejoin: ${({ strokeLinejoin }) =>
      strokeLinejoin || ATTRS.strokeLinejoin};
  `;

  return <StyledIcon {...rest} />;
};

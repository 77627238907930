import styled from 'styled-components/macro';
import { flexFont } from 'mixins';

export const Title1 = styled.h1`
  color: ${({ theme, color }) => color || theme.newtheme.color.cinza6};
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  letter-spacing: ${({ theme }) => theme.newtheme.font.titulo1.espacamento};
  font-weight: ${({ theme }) => theme.newtheme.font.titulo1.peso};
  margin: 0.8rem 0;
  ${flexFont('1440px', '1920px', '86px', '96px')}
`;

export const Title2 = styled.h2`
  color: ${({ theme, color }) => color || theme.newtheme.color.cinza6};
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  letter-spacing: ${({ theme }) => theme.newtheme.font.titulo2.espacamento};
  font-weight: ${({ theme }) => theme.newtheme.font.titulo2.peso};
  margin: 0.8rem 0;
  ${flexFont('1440px', '1920px', '55px', '60px')}
`;

export const Title3 = styled.h3`
  color: ${({ theme, color }) => color || theme.newtheme.color.cinza6};
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  letter-spacing: ${({ theme }) => theme.newtheme.font.titulo3.espacamento};
  font-weight: ${({ theme, fontWeigth }) =>
    fontWeigth || theme.newtheme.font.titulo3.peso};
  margin: 0.8rem 0;
  ${flexFont('1440px', '1920px', '40px', '48px')}
`;

export const Title4 = styled.h4`
  color: ${({ theme, color }) => color || theme.newtheme.color.cinza6};
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  letter-spacing: ${({ theme }) => theme.newtheme.font.titulo4.espacamento};
  font-weight: ${({ theme, fontWeigth }) =>
    fontWeigth || theme.newtheme.font.titulo3.peso};
  margin: 0.8rem 0;
  ${flexFont('1440px', '1920px', '30px', '34px')}
`;

export const Title5 = styled.h5`
  color: ${({ theme, color }) => color || theme.newtheme.color.cinza6};
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  letter-spacing: ${({ theme }) => theme.newtheme.font.titulo5.espacamento};
  font-weight: ${({ theme }) => theme.newtheme.font.titulo5.peso};
  margin: 0.8rem 0;
  ${flexFont('1440px', '1920px', '20px', '24px')}
`;

export const Title6 = styled.h6`
  color: ${({ theme, color }) => color || theme.newtheme.color.cinza6};
  font-family: ${({ theme }) => theme.newtheme.font.fontFamily};
  letter-spacing: ${({ theme }) => theme.newtheme.font.titulo6.espacamento};
  font-weight: ${({ theme }) => theme.newtheme.font.titulo6.peso};
  margin: 0.8rem 0;
  ${flexFont('1440px', '1920px', '17px', '20px')}
`;

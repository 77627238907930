import { useQuery } from 'react-query';
import axios from 'axios';
import CONFIG from 'config';
import { getUserData } from 'services/getUserData';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const fetchAgendaList = async (params) => {
  const { idEmpresa } = await getUserData();

  const fetchData = axios.post(`${BASE_URL}/agenda/${idEmpresa}`, params);

  const result = await fetchData;
  const { data } = result;

  return data || [];
};

export const useAgendaList = (params) => {
  return useQuery(['agenda', 'lista', params], () => fetchAgendaList(params), {
    keepPreviousData: false,
    enabled: !!Number(params.filter.mesAnoVigencia.mes),
  });
};

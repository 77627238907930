import React from 'react';

import { Wrapper } from 'components/FinWrappers';
import {
  SeparatorVertical,
  // SeparatorHorizontal,
} from 'components/Elements';
import GraficoBarraMatch from './GraficoBarra';
import GraficoPizzaMatch from './GraficoPizza';

import {
  Grafico,
  GraficosH2,
  AreaTextos,
  CustonGrafico1,
  CustonGrafico2,
} from '../Styles';

export default function Graficos(dados) {
  const data = [
    {
      name: '50% á 70%',
      valor: dados.dados.empresasMatch50_70,
      cores: '#F4E3A0',
    },
    {
      name: '70% á 80%',
      valor: dados.dados.empresasMatch70_80,
      cores: '#8DE5ED',
    },
    {
      name: '80% á 90%',
      valor: dados.dados.empresasMatch80_90,
      cores: '#BCE0E3',
    },
    {
      name: '90% á 100%',
      valor: dados.dados.empresasMatch90_100,
      cores: '#008894',
    },
  ];

  const valorReduzido = dados.dados.totalEmpresa - dados.dados.empresasMatch;

  return (
    <Wrapper style={{ padding: '40px 0px' }}>
      <CustonGrafico1>
        <Grafico>
          <GraficoPizzaMatch data={dados.dados} />
        </Grafico>
        <AreaTextos>
          <GraficosH2>
            Matching<span>Empresas que deram match</span>
          </GraficosH2>

          <ul>
            <li>
              Total de Empresas<strong> {dados.dados.totalEmpresa}</strong>
            </li>
            <li>
              Não deram Match<strong> {valorReduzido}</strong>
            </li>
            <li>
              Empresas que deram Match
              <strong> {dados.dados.empresasMatch}</strong>
            </li>
          </ul>
        </AreaTextos>
      </CustonGrafico1>

      <SeparatorVertical wsize="0.5rem" />
      <CustonGrafico2>
        <GraficosH2>
          Aderência<span>Aderência das empresas</span>
        </GraficosH2>

        <GraficoBarraMatch data={data} />
      </CustonGrafico2>
    </Wrapper>
  );
}

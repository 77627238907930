/* eslint-disable no-console */
import immer, { original } from 'immer';
import React, { createContext, useReducer } from 'react';
import { format } from 'date-fns';
import { removerParcela, convert, ordenarParcelas } from './helpers';

export const MODALS = {
  bordero: 'bordero',
  acertos: 'acertos',
  comentarios: 'comentarios',
  parcelas: 'parcelas',
};

const initialState = {
  search: '',
  date: format(new Date(), 'yyyy-MM-dd'),
  navigation: {
    tabActive: 'emAberto',
    tipoPregao: 0,
  },
  pagination: {
    size: 5,
    skip: 1,
  },
  modal: {
    active: '',
    values: null,
  },
  propostas: {
    nova: {},
    edicao: false,
  },
  bordero: {
    selecionados: [],
    nãoSelecionados: [],
  },
};

const actions = {
  SET_PAGINATION_SIZE: 'SET_PAGINATION_SIZE',
  SET_PAGINATION_SKIP: 'SET_PAGINATION_SKIP',
  SET_NAVIGATION_TAB: 'SET_NAVIGATION_TAB',
  SET_NAVIGATION_TIPO_PREGAO: 'SET_NAVIGATION_TIPO_PREGAO',
  SET_SEARCH: 'SET_SEARCH',
  SET_DATE: 'SET_DATE',
  SET_MODAL_ACTIVE: 'SET_MODAL_ACTIVE',
  REMOVE_MODAL_ACTIVE: 'REMOVE_MODAL_ACTIVE',
  SET_NOVA_PROPOSTA: 'SET_NOVA_PROPOSTA',
  SET_EDICAO_PROPOSTA: 'SET_EDICAO_PROPOSTA',
  SET_RESET_NOVA_PROPOSTA: 'SET_RESET_NOVA_PROPOSTA',
  UPDATE_NOVA_PROPOSTA: 'UPDATE_NOVA_PROPOSTA',
  UPDATE_NOVA_PROPOSTA_ACERTOS: 'UPDATE_NOVA_PROPOSTA_ACERTOS',
  SET_SELECAO: 'SET_SELECAO',
  REMOVE_SELECAO: 'REMOVE_SELECAO',
  UPDATE_ACERTOS: 'UPDATE_ACERTOS',
  UPDATE_VALOR_RECUSADO: 'UPDATE_VALOR_RECUSADO',
  ADD_PARCELAS_EMPRESTIMO: 'ADD_PARCELAS_EMPRESTIMO',
  UPDATE_PARCELAS_EMPRESTIMO: 'UPDATE_PARCELAS_EMPRESTIMO',
  UPDATE_PARCELAS_ALL_FIELDS_EMPRESTIMO:
    'UPDATE_PARCELAS_ALL_FIELDS_EMPRESTIMO',
  DELETE_PARCELAS_EMPRESTIMO: 'DELETE_PARCELAS_EMPRESTIMO',
  RESET_BORDERO: 'RESET_BORDERO',
  RESET_ALL: 'RESET_ALL',
};

const reducerActions = {
  SET_PAGINATION_SIZE: (state, payload) => {
    state.pagination.size = payload.value;
  },
  SET_PAGINATION_SKIP: (state, payload) => {
    state.pagination.skip = payload.value;
  },
  SET_NAVIGATION_TAB: (state, payload) => {
    state.navigation.tabActive = payload.value;
    state.search = '';
    state.pagination.skip = 1;
    state.navigation.tipoPregao = 0;
  },
  SET_NAVIGATION_TIPO_PREGAO: (state, payload) => {
    state.navigation.tipoPregao = payload.value;
    state.pagination.skip = 1;
  },
  SET_SEARCH: (state, payload) => {
    state.search = payload.value;
  },
  SET_DATE: (state, payload) => {
    state.date = payload.value;
  },
  SET_MODAL_ACTIVE: (state, payload) => {
    state.modal = { active: payload.active, values: payload.values };
  },
  REMOVE_MODAL_ACTIVE: (state) => {
    state.modal = { active: '', values: null };
  },
  SET_NOVA_PROPOSTA: (state, payload) => {
    state.propostas.nova = { ...payload.value };
    state.propostas.edicao = true;
  },
  SET_EDICAO_PROPOSTA: (state, payload) => {
    state.propostas.edicao = payload.value;
  },
  SET_RESET_NOVA_PROPOSTA: (state) => {
    state.propostas.nova = {};
    state.propostas.edicao = false;
  },
  UPDATE_NOVA_PROPOSTA: (state, payload) => {
    if (Array.isArray(payload.value)) {
      payload.value.forEach((item) => {
        if (item.subField) {
          state.propostas.nova[item.field][item.subField] = item.value;
        } else {
          state.propostas.nova[item.field] = item.value;
        }
      });
    }
  },
  UPDATE_NOVA_PROPOSTA_ACERTOS: (state, payload) => {
    state.propostas.nova[payload.field].tarifa = payload.value.tarifa;
    state.propostas.nova[payload.field].recompra = payload.value.recompra;
    state.propostas.nova[payload.field].pendencias = payload.value.pendencias;

    const totalAcertos =
      payload.value.tarifa + payload.value.pendencias + payload.value.recompra;

    state.propostas.nova[payload.field].acertos = totalAcertos;
  },
  SET_SELECAO: (state, payload) => {
    let array = payload.value;
    if (!Array.isArray(payload.value)) {
      array = [payload.value];
    }
    state.bordero.selecionados.push(...array);

    const newArray = array.map((i) => i.id);
    const removed = state.bordero.nãoSelecionados
      .filter((t) => newArray.indexOf(t.id) === -1)
      .map((r) => ({ id: r.id, valor: r.valor }));

    state.bordero.nãoSelecionados = removed;
  },
  REMOVE_SELECAO: (state, payload) => {
    let array = payload.value;
    if (!Array.isArray(payload.value)) {
      array = [payload.value];
    }
    if (array.length === 0) {
      state.bordero.selecionados = [];
    } else {
      const newArray = array.map((i) => i.id);
      const removed = state.bordero.selecionados
        .filter((t) => newArray.indexOf(t.id) === -1)
        .map((r) => ({ id: r.id, valor: r.valor }));

      state.bordero.selecionados = removed;
      state.bordero.nãoSelecionados.push(...array);
    }
  },
  UPDATE_ACERTOS: (state, payload) => {
    const { tarifa, recompra, pendencias } = original(
      state.propostas.nova[payload.field]
    );
    state.propostas.nova[payload.field].acertos =
      tarifa + recompra + pendencias;
  },
  UPDATE_VALOR_RECUSADO: (state) => {
    const { nãoSelecionados } = original(state.bordero);
    const valorRecusado = nãoSelecionados.reduce(
      (acc, curr) => acc + curr.valor,
      0
    );
    state.propostas.nova.bordero.valorRecusado = valorRecusado;
  },
  ADD_PARCELAS_EMPRESTIMO: (state, payload) => {
    const { valor, vencimento } = payload;
    const { parcelas } = original(state.propostas.nova.semNota);

    const venc = `${convert(vencimento)}T00:00:00`;

    const values = {
      valor,
      vencimento: venc,
      idNumero: parcelas.length + 1,
    };

    const novasParcelas = [...parcelas, values];
    const result = ordenarParcelas(novasParcelas);

    state.propostas.nova.semNota.parcelas = result;

    const valorTotal = result.reduce((acc, curr) => acc + curr.valor, 0);

    state.propostas.nova.semNota.valorTotalParcelas = valorTotal;
    state.propostas.nova.semNota.valorBruto = valorTotal;
  },
  UPDATE_PARCELAS_EMPRESTIMO: (state, payload) => {
    const { field, valor, id } = payload;
    const { parcelas } = original(state.propostas.nova.semNota);

    const value = field === 'vencimento' ? `${convert(valor)}T00:00:00` : valor;

    const newParcelas = parcelas.map((item) =>
      item.idNumero === id ? { ...item, [field]: value } : item
    );

    const result = ordenarParcelas(newParcelas);

    state.propostas.nova.semNota.parcelas = result;
    if (field === 'valor') {
      const valorTotal = result.reduce((acc, curr) => acc + curr.valor, 0);

      state.propostas.nova.semNota.valorTotalParcelas = valorTotal;
      state.propostas.nova.semNota.valorBruto = valorTotal;
    }
  },
  UPDATE_PARCELAS_ALL_FIELDS_EMPRESTIMO: (state, payload) => {
    const { parcelas } = payload;

    state.propostas.nova.semNota.parcelas = parcelas;

    const valorTotal = parcelas.reduce((acc, curr) => acc + curr.valor, 0);

    state.propostas.nova.semNota.valorTotalParcelas = valorTotal;
    state.propostas.nova.semNota.valorBruto = valorTotal;
  },
  DELETE_PARCELAS_EMPRESTIMO: (state, payload) => {
    const { index } = payload;
    const { parcelas } = original(state.propostas.nova.semNota);

    const newParcelas = removerParcela({ indice: index, parcelas });

    state.propostas.nova.semNota.parcelas = newParcelas;

    const valorTotal = newParcelas.reduce((acc, curr) => acc + curr.valor, 0);

    state.propostas.nova.semNota.valorTotalParcelas = valorTotal;
    state.propostas.nova.semNota.valorBruto = valorTotal;
  },
  RESET_BORDERO: (state) => {
    state.bordero.selecionados = [];
    state.bordero.nãoSelecionados = [];
  },
  RESET_ALL: () => initialState,
};

const reducer = (state, action) => {
  const fn = reducerActions[action.type];
  if (fn) {
    return immer(state, (draftState) => {
      fn(draftState, action.payload);
    });
  }
  console.log('[WARNING] Action without reducer:', action);
  return state;
};

export const OperacoesContext = createContext();

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const values = {
    setPage: (page) => {
      dispatch({ type: actions.SET_PAGINATION_SKIP, payload: { value: page } });
    },
    setSize: (size) => {
      dispatch({ type: actions.SET_PAGINATION_SIZE, payload: { value: size } });
    },
    setTabActive: (tab) => {
      dispatch({ type: actions.SET_NAVIGATION_TAB, payload: { value: tab } });
    },
    setTipoPregao: (tipo) => {
      dispatch({
        type: actions.SET_NAVIGATION_TIPO_PREGAO,
        payload: { value: tipo },
      });
    },
    setSearch: (term) => {
      dispatch({ type: actions.SET_SEARCH, payload: { value: term } });
    },
    setDate: (date) => {
      dispatch({ type: actions.SET_DATE, payload: { value: date } });
    },
    setModalActive: ({ active, values }) => {
      dispatch({ type: actions.SET_MODAL_ACTIVE, payload: { active, values } });
    },
    removeModalActive: () => {
      dispatch({ type: actions.REMOVE_MODAL_ACTIVE });
    },
    setNovaProposta: ({ value }) => {
      dispatch({ type: actions.SET_NOVA_PROPOSTA, payload: { value } });
    },
    setEdicaoProposta: ({ value }) => {
      dispatch({ type: actions.SET_EDICAO_PROPOSTA, payload: { value } });
    },
    setResetProposta: () => {
      dispatch({ type: actions.SET_RESET_NOVA_PROPOSTA });
    },
    updateNovaProposta: (value) => {
      dispatch({ type: actions.UPDATE_NOVA_PROPOSTA, payload: { value } });
    },
    updateNovaPropostaAcertos: ({ field, value }) => {
      dispatch({
        type: actions.UPDATE_NOVA_PROPOSTA_ACERTOS,
        payload: { field, value },
      });
    },
    setSelecao: ({ value }) => {
      dispatch({ type: actions.SET_SELECAO, payload: { value } });
    },
    removeSelecao: ({ value }) => {
      dispatch({ type: actions.REMOVE_SELECAO, payload: { value } });
    },
    updateAcertos: ({ field }) => {
      dispatch({ type: actions.UPDATE_ACERTOS, payload: { field } });
    },
    updateValorRecusado: () => {
      dispatch({ type: actions.UPDATE_VALOR_RECUSADO, payload: {} });
    },
    addParcelaEmprestimo: ({ valor, vencimento }) => {
      dispatch({
        type: actions.ADD_PARCELAS_EMPRESTIMO,
        payload: { valor, vencimento },
      });
    },
    updateParcelaEmprestimo: ({ field, valor, id }) => {
      dispatch({
        type: actions.UPDATE_PARCELAS_EMPRESTIMO,
        payload: { field, valor, id },
      });
    },
    updateParcelaAllFieldsEmprestimo: ({ parcelas }) => {
      dispatch({
        type: actions.UPDATE_PARCELAS_ALL_FIELDS_EMPRESTIMO,
        payload: { parcelas },
      });
    },
    deleteParcelaEmprestimo: ({ index }) => {
      dispatch({
        type: actions.DELETE_PARCELAS_EMPRESTIMO,
        payload: { index },
      });
    },
    resetBordero: () => {
      dispatch({ type: actions.RESET_BORDERO });
    },
    resetAll: () => {
      dispatch({ type: actions.RESET_ALL });
    },
  };

  return (
    <OperacoesContext.Provider value={[state, values]}>
      {children}
    </OperacoesContext.Provider>
  );
};

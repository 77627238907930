import React from 'react';
import { Route } from 'react-router-dom';

import { PaginaPropostas } from 'pages/Operacoes/Propostas';
import PaginaCondicionante from 'pages/Condicionante';
import PaginaCbbox from 'pages/Cbbox';
import {
  PaginaDetalheEmpreendedor,
  PaginaListaConvites,
} from 'pages/Empreendedores';

export function Modals({ background }) {
  if (background) {
    return (
      <>
        <Route
          exact
          path="/propostas/:idOperacao"
          render={(props) => (
            <PaginaPropostas
              {...props}
              isModal={!!background}
              background={background}
            />
          )}
        />
        <Route
          exact
          path="/condicionante"
          render={(props) => (
            <PaginaCondicionante
              {...props}
              isModal={!!background}
              background={background}
            />
          )}
        />
        <Route
          exact
          path="/Finmatch/:idEmpreendedor"
          render={(props) => (
            <PaginaCbbox
              {...props}
              isModal={!!background}
              background={background}
            />
          )}
        />

        <Route
          exact
          path="/clientes/:idEmpreendedor/detalhe"
          render={(props) => (
            <PaginaDetalheEmpreendedor
              {...props}
              isModal={!!background}
              background={background}
            />
          )}
        />
        <Route
          exact
          path="/convites"
          render={(props) => (
            <PaginaListaConvites
              {...props}
              isModal={!!background}
              background={background}
            />
          )}
        />
      </>
    );
  }
  return null;
}

export const modalFallback = [
  {
    path: '/clientes/:idEmpreendedor/detalhe',
    exact: true,
    component: PaginaDetalheEmpreendedor,
  },
  {
    path: '/convites',
    exact: true,
    component: PaginaListaConvites,
  },
  {
    path: '/condicionante',
    exact: true,
    component: PaginaCondicionante,
  },
  {
    path: '/Finmatch/:idEmpreendedor',
    exact: true,
    component: PaginaCbbox,
  },
  {
    path: '/propostas/:idOperacao',
    exact: true,
    component: PaginaPropostas,
  },
];

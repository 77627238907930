import { authenticationService } from './AuthenticationService';

export function handleResponse(response) {
  if(response){
      const data = response;
      if (response.status !== 200) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          authenticationService.logout();
          window.location.reload(true);
        }
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      return data;
    }
    return null
}

/* eslint-disable no-unused-vars */
import { useQuery } from 'react-query';
import axios from 'axios';

import CONFIG from 'config';
import { getUserData } from 'services/getUserData';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/`;

const apiAprovacaoCredito = async (params) => {
  const { idEmpresa } = await getUserData();

  const response = axios.get(
    `${BASE_URL}api/aprovacao-credito?idConvenio=${idEmpresa}&idGrupo=${params.idGrupo}`
  );
  const result = await response;
  return result.data ? result.data.data : [];
};

export const useQueryAprovacaoCredito = (params) => {
  return useQuery(
    ['fluxo', 'credito', params],
    () => apiAprovacaoCredito(params),
    {
      keepPreviousData: false,
    }
  );
};

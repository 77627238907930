import differenceInCalendarYears from 'date-fns/differenceInCalendarYears';
import { getYear } from 'date-fns';

class CalendarsDates {
  constructor() {
    this.initialYear = 1900;
    this.currentYear = getYear(new Date());
    this.diffYears = differenceInCalendarYears(
      new Date(this.currentYear, 1, 11),
      new Date(this.initialYear, 11, 31)
    );
    this.years = this.setYears();
    this.days = this.setDays();
    this.month = this.setMonths();
  }

  convertDay(value, type = 'text') {
    let date = value;
    if (typeof value === 'number') date = value.toString();
    const day = date.split('/');
    const idNumber = typeof day[0] === 'string' ? Number(day[0]) : day[0];
    if (type === 'id') return day.length > 0 ? this.days[idNumber].id : '';
    return day.length > 0 ? day[0] : '';
  }

  convertMonth(value, type = 'text') {
    let date = value;
    if (typeof value === 'number') date = value.toString();
    const month = date.split('/');
    const idNumber = typeof month[1] === 'string' ? Number(month[1]) : month[1];
    if (type === 'number') return month.length > 0 ? month[1] : '';
    if (type === 'id')
      return month.length > 0
        ? this.month[month.length === 1 ? month[0] : idNumber].id
        : '';
    return month.length > 0
      ? this.month[month.length === 1 ? month[0] : idNumber].mount
      : '';
  }

  convertYears(value, type = 'number') {
    let date = value;
    console.log(date);
    if (typeof value === 'number') date = value.toString();
    const year = date.split('/');
    if (type === 'id') {
      const id =
        year.length > 0 &&
        this.years
          .filter(res => res.year.toString() === year[2].toString())
          .map(r => r.id)[0];
      if (!!id) {
        return id;
      } else {
        return 0;
      }
    }
    if (year.length === 1) {
      const years = this.years;
      const value = years
        .filter(res => res.id.toString() === year[0].toString())
        .map(r => r.year)[0];

      if (value !== undefined) {
        return value === '' ? '0' : value;
      } else {
        return this.years[1].year;
      }
    }
    return year.length > 0 ? year[2] : '';
  }

  getYears() {
    return this.years;
  }

  getDays() {
    return this.days;
  }

  getMonths() {
    return this.month;
  }

  setYears() {
    const list = [];
    const diffYear = this.diffYears;
    let y = this.initialYear;
    list.push({ id: 0, year: '' });
    list.push({ id: 1, year: y });
    for (let i = 1; i <= diffYear; i++) {
      y = y + 1;
      list.push({ id: i + 2, year: y });
    }
    list.reverse();
    return list;
  }

  setDays() {
    return [
      { id: 0, day: '' },
      { id: 1, day: '01' },
      { id: 2, day: '02' },
      { id: 3, day: '03' },
      { id: 4, day: '04' },
      { id: 5, day: '05' },
      { id: 6, day: '06' },
      { id: 7, day: '07' },
      { id: 8, day: '08' },
      { id: 9, day: '09' },
      { id: 10, day: '10' },
      { id: 11, day: '11' },
      { id: 12, day: '12' },
      { id: 13, day: '13' },
      { id: 14, day: '14' },
      { id: 15, day: '15' },
      { id: 16, day: '16' },
      { id: 17, day: '17' },
      { id: 18, day: '18' },
      { id: 19, day: '19' },
      { id: 20, day: '20' },
      { id: 21, day: '21' },
      { id: 22, day: '22' },
      { id: 23, day: '23' },
      { id: 24, day: '24' },
      { id: 25, day: '25' },
      { id: 26, day: '26' },
      { id: 27, day: '27' },
      { id: 28, day: '28' },
      { id: 29, day: '29' },
      { id: 30, day: '30' },
      { id: 31, day: '31' },
    ];
  }

  setMonths() {
    return [
      { id: 0, mount: '' },
      { id: 1, mount: 'Janeiro' },
      { id: 2, mount: 'Fevereiro' },
      { id: 3, mount: 'Março' },
      { id: 4, mount: 'Abril' },
      { id: 5, mount: 'Maio' },
      { id: 6, mount: 'Junho' },
      { id: 7, mount: 'Julho' },
      { id: 8, mount: 'Agosto' },
      { id: 9, mount: 'Setembro' },
      { id: 10, mount: 'Outubro' },
      { id: 11, mount: 'Novembro' },
      { id: 12, mount: 'Dezembro' },
    ];
  }
}

export default new CalendarsDates();

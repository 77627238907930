import React, { useState, useEffect } from 'react';

import { ReactComponent as IconCheLeft } from 'assets/images/icon-chevron-left.svg';
import { ReactComponent as IconRight } from 'assets/images/icon-chevron-right.svg';
import { useSwipeable } from 'react-swipeable';
import { Carousel, CarouselItem, Inner, ButtonControl } from './style.carousel';

export const FinCarouselItem = ({ children, width }) => {
  return <CarouselItem width={width}>{children}</CarouselItem>;
};

export function FinCarousel({ children, auto, delay = 5000, showItems = 2 }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState(false);

  const item = () => 100 / showItems;

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  const left = {
    position: 'absolute',
    top: '50%',
    left: '1.5rem',
    transform: 'translateY(-50%)',
    zIndex: 9,
  };

  const right = {
    position: 'absolute',
    top: '50%',
    right: '1.5rem',
    transform: 'translateY(-50%)',
    zIndex: 9,
  };

  useEffect(() => {
    let interval;
    if (auto) {
      interval = setInterval(() => {
        if (!paused) {
          updateIndex(activeIndex + 1);
        }
      }, delay);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + showItems),
    onSwipedRight: () => updateIndex(activeIndex - showItems),
  });

  return (
    <Carousel
      {...handlers}
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}
    >
      <ButtonControl
        style={left}
        onClick={() => updateIndex(activeIndex - showItems)}
      >
        <IconCheLeft />
      </ButtonControl>
      <Inner translateX={`-${activeIndex * item()}%`}>
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, { width: `${item()}%` });
        })}
      </Inner>
      <ButtonControl
        style={right}
        onClick={() => updateIndex(activeIndex + showItems)}
      >
        <IconRight />
      </ButtonControl>
    </Carousel>
  );
}

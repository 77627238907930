import React, { useEffect, useCallback, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GoogleMapReact from 'google-map-react';

import { rgba } from 'polished';
import styled from 'styled-components/macro';
import { flatten } from 'ramda';

import { Creators as apiGeocodeActions } from 'store/ducks/apiGeocode';

import { LoadingInfo } from 'components/FinLoading';
import FeedbackInfo from 'components/FinFeedbackInfo';
import { MakeSvgUri } from 'helpers/svgFiles';
import { icons } from 'assets/icones/finplaceIcons';

import { Wrapper } from 'components/FinWrappers';
import { finTheme } from 'finTheme';

const WrapperMap = styled(Wrapper)`
  background-color: ${({ theme }) => rgba(theme.newtheme.color.cinza3, 0.3)};
  padding: 0;
  width: 100%;
  height: 35vh;
`;

const uri = MakeSvgUri({
  data: icons.placeFlat,
  stroke: 'none',
  fill: finTheme.newtheme.color.azulPrimario,
  width: '100%',
  height: '100%',
  viewBox: '0 0 20 20',
});

const key1 = 'AIzaSyBx1Zn0K7TEFoSGTqD4VjD2LmfmlfPwvA8';
// eslint-disable-next-line no-unused-vars
const key2 = 'AIzaSyBwPXwS0jACSV-ukKL_RTBe9ngizXWHuK4';

const AnyReactComponent = ({ text }) => (
  <div
    style={{
      color: 'white',
      backgroundColor: finTheme.newtheme.color.vermelhoPrimario,
      background: `url(${uri}) no-repeat 0 0`,
      width: '40px',
      height: '40px',
      display: 'inline-flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      textIndent: '101%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      transform: 'translate(-50%, -50%)',
    }}
  >
    {text}
  </div>
);

const Map = ({
  apiGeocodeState,
  apiGeocodeRequest,
  apiGeocodeCancel,
  address,
  text,
  locked,
  altura,
}) => {
  const [viewMap, setViewMap] = useState(false);
  const [defaultProps, setDefaultProps] = useState({
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 16,
  });

  const checkStatus = useCallback(() => {
    if (apiGeocodeState.data.length > 0) {
      const results = flatten(
        apiGeocodeState.data.map((data) =>
          data.results.map((result) => result.geometry.location)
        )
      );

      if (results.length > 0) {
        const latLng = {
          lat: results[0].lat,
          lng: results[0].lng,
        };

        setDefaultProps({
          center: latLng,
          zoom: defaultProps.zoom,
        });
      }

      setViewMap(
        apiGeocodeState.data.filter((res) => res.status === 'OK').length > 0
      );
    }
  }, [apiGeocodeState.data, defaultProps.zoom]);

  useEffect(() => {
    apiGeocodeRequest(address);
    return () => apiGeocodeCancel();
  }, [apiGeocodeRequest, address, apiGeocodeCancel]);

  useEffect(() => {
    checkStatus();
  }, [checkStatus]);

  return (
    <WrapperMap
      style={{
        height: altura || '35vh',
        backgroundColor: 'var(--fingray-100)',
        borderRadius: '16px',
      }}
    >
      {locked && !apiGeocodeState.isLoading && !apiGeocodeState.error && (
        <FeedbackInfo tipo="locked" text="Conteúdo bloqueado" />
      )}
      <LoadingInfo loading={apiGeocodeState.isLoading} />
      {apiGeocodeState.error && <FeedbackInfo tipo="error" />}
      {viewMap && !locked && (
        <div
          style={{
            height: altura || '35vh',
            width: '100%',
            borderRadius: '28px',
            overflow: 'hidden',
          }}
        >
          <GoogleMapReact
            bootstrapURLKeys={{
              key: key1,
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          >
            <AnyReactComponent
              lat={defaultProps.center.lat}
              lng={defaultProps.center.lng}
              text={text}
            />
          </GoogleMapReact>
        </div>
      )}
      {!locked &&
        !apiGeocodeState.isLoading &&
        !apiGeocodeState.error &&
        apiGeocodeState.data.filter((res) => res.status === 'OK').length ===
          0 && <FeedbackInfo tipo="empty" text="Conteúdo não disponível" />}
    </WrapperMap>
  );
};

const mapStateToProps = (state) => ({
  apiGeocodeState: state.apiGeocode,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(apiGeocodeActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Map);

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

// import { useLocation } from 'react-router-dom';
import { Wrapper } from 'components/FinWrappers';
import { icons } from 'assets/icones/finplaceIcons';
// import feather from 'feather-icons/dist/icons.json';

import { SeparatorHorizontal } from 'components/FinSeparador';
import { finTheme } from 'finTheme';
import LinkCuston from './LinkCuston';

const Shadown = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: ${({ open }) => (open ? '0' : '-115%')};
  left: 0;
  z-index: ${({ theme }) => theme.newtheme.zIndex.shadownMenu};
  cursor: pointer;
  background-color: ${({ open, theme }) =>
    open ? theme.newtheme.overlay.preto : 'transparent'};
  transition: background-color 0.3s ease
    ${({ open }) => (!open ? ', top 0s linear 0.5s' : '')};
`;

const CustonWrapper = styled(Wrapper)`
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.newtheme.backgroundColor.branco};
  height: 100vh;
  width: 68px;
  overflow-x: hidden;
  overflow-y: ${({ open }) => (open ? 'auto' : 'hidden')};
  padding: 0;
  padding-bottom: 2rem;
  position: fixed;
  left: 0;
  top: 56px;
  z-index: ${({ theme }) => theme.newtheme.zIndex.sideBarMenu};
  box-shadow: ${({ theme }) => theme.newtheme.dropShadow.dropShadow2};

  transition: width 0.2s ease;
  ${({ open }) =>
    open &&
    css`
      width: 285px;
    `}
`;

const CustonWrapperInfo = styled(Wrapper)`
  width: 285px;
  opacity: ${({ view }) => (view ? '1' : '0')};
  transition: opacity 0.2s ease;
`;

const Label = styled.div`
  width: 100%;
  text-align: left;
  font-size: ${({ pfontSize, theme }) =>
    pfontSize || theme.newtheme.font.corpo2.tamanho};
  font-weight: 500;
  color: ${({ pcolor, theme }) => pcolor || theme.newtheme.color.cinza6};
`;

const LabelCuston = styled(Label)`
  margin-bottom: 2rem;
  height: 40px;
`;

const CustonLink = styled.a`
  font-size: ${({ pfontSize, theme }) =>
    pfontSize || theme.newtheme.font.corpo2.tamanho};
  color: ${({ pcolor, theme }) => pcolor || theme.newtheme.color.cinza6};
  font-weight: 500;
  text-align: left;
  line-height: 2rem;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const SideBarMenu = ({ open, closeMenu }) => {
  const [version, setVersion] = React.useState(null);
  // const location = useLocation();

  React.useEffect(() => {
    (async function getVersion() {
      if (process.env.NODE_ENV === 'development') {
        return setVersion(process.env.REACT_APP_VERSION);
      }

      return fetch(`${process.env.PUBLIC_URL}/static/version.txt`)
        .then((r) => r.text())
        .then((text) => {
          setVersion(text);
        });
    })();
  }, []);

  return (
    <>
      <CustonWrapper open={open}>
        <LinkCuston
          to="/"
          label="Página inicial"
          icon={icons.housePricing}
          tooltipText="Página inicial"
          id="home"
          isDisabledTooltip={open}
          onClick={closeMenu}
          exact
        />
        <LinkCuston
          to="/meusdados"
          label="Dados Cadastrais"
          icon={icons.touchId}
          tooltipText="Dados Cadastrais"
          id="meusdados"
          isDisabledTooltip={open}
          onClick={closeMenu}
          exact
        />
        <LinkCuston
          to="/Finmatch"
          label="Finmatch"
          strokeWidth="0"
          icon={icons.finmatchCinza}
          tooltipText="Finmatch"
          id="Finmatch"
          isDisabledTooltip={open}
          onClick={closeMenu}
          exact
        />
        <LinkCuston
          to="/minha-rede/inicio"
          // to={{
          //   pathname: '/clientes',
          //   search: 'page=1&qtd=10&order=a-z&aba=todos',
          // }}
          label="Minha Rede"
          icon={icons.globeOutline}
          strokeWidth="2"
          tooltipText="Minha Rede"
          id="meusclientes"
          isDisabledTooltip={open}
          onClick={closeMenu}
          exact
        />
        <LinkCuston
          to="/operacoes"
          // to="/main-operacoes/andamento-convenio"
          label="Negociações"
          icon={icons.payment}
          tooltipText="Negociações"
          id="negociacoes"
          isDisabledTooltip={open}
          onClick={closeMenu}
          exact
        />
        <LinkCuston
          to="/agenda"
          label="Agenda"
          strokeWidth="0"
          icon={icons.finagenda}
          tooltipText="Agenda Comercial"
          id="agenda"
          isDisabledTooltip={open}
          onClick={closeMenu}
          exact
        />
        {/* <LinkCuston
          to="/dashboard"
          label="Indicadores"
          icon={icons.stockMarket}
          tooltipText="Indicadores"
          id="indicadores"
          isDisabledTooltip={open}
          onClick={closeMenu}
          exact
        /> */}

        {/* <LinkCuston
          to="/finmeeting"
          label="Finmeeting"
          icon={icons.calendar}
          viewBox="0 0 84 84"
          strokeWidth="2"
          tooltipText="Finmeeting"
          id="finmeeting"
          isDisabledTooltip={open}
          onClick={closeMenu}
          exact
        /> */}

        {/* open && (
          <>
            <SeparatorHorizontal
              pborderColor={finTheme.newtheme.color.cinza2}
              ppadding="0.8rem"
            />
            <LinkCuston
              to="/indicadores"
              label="Indicadores"
              icon={icons.stockMarket}
              tooltipText="Indicadores"
              id="indicadores"
              isDisabledTooltip={open}
              onClick={closeMenu}
              exact
            />
            <LinkCuston
              to={{
                pathname: '/condicionante',
                state: { background: location },
              }}
              label="Condicionantes"
              icon={icons.handshake}
              tooltipText="Condicionantes"
              id="condicionantes"
              isDisabledTooltip={open}
              onClick={closeMenu}
              exact
            />
            <LinkCuston
              to="/ancora"
              label="Âncora"
              icon={feather.anchor}
              viewBox="0 0 30 30"
              tooltipText="Âncora"
              id="ancora"
              isDisabledTooltip={open}
              onClick={closeMenu}
              exact
            />
          </>
            ) */}
        <SeparatorHorizontal
          pborderColor={finTheme.newtheme.color.cinza2}
          ppadding="0.8rem"
        />
        {open && (
          <>
            <CustonWrapperInfo
              view={open}
              tipo="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <CustonLink
                href="https://finplace.com.br/quem-somos/"
                target="_blank"
              >
                Sobre a Finplace
              </CustonLink>
              <Label
                pcolor={finTheme.newtheme.color.cinza4}
                pfontSize={finTheme.newtheme.font.caption.tamanho}
              >
                {`Versão ${version}`}
              </Label>
              <SeparatorHorizontal ppadding="0.8rem" />
              <LabelCuston pcolor={finTheme.newtheme.color.cinza4}>
                © 2023 Finplace
              </LabelCuston>
            </CustonWrapperInfo>
          </>
        )}
      </CustonWrapper>
      <Shadown open={open} onClick={closeMenu} />
    </>
  );
};

SideBarMenu.propTypes = {
  open: PropTypes.bool,
  closeMenu: PropTypes.func,
};

export default SideBarMenu;

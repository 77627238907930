import React, { forwardRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { Label, Flex, Info } from './style.motivo';
import { InputTextArea } from '../Input';

export const Motivo = forwardRef((props, ref) => {
  const { disabled } = props;
  return (
    <Flex column style={{ marginBottom: '10px' }}>
      <ReactTooltip
        id="info-motivo"
        wrapper="span"
        place="right"
        type="dark"
        effect="solid"
      >
        <div style={{ width: '200px', wordBreak: 'break-word' }}>
          Qual a justificativa de ter aprovado ou negado um limite de crédito
          para uma empresa.
        </div>
      </ReactTooltip>
      <Flex
        justify="flex-start"
        style={{ marginBottom: '8px', width: 'calc(100% - 6px)' }}
      >
        <Label>Motivo</Label>
        <Info aria-label="motivo" data-tip data-for="info-motivo" />
      </Flex>
      <InputTextArea disabled={disabled} ref={ref} {...props} />
    </Flex>
  );
});

import styled, { css } from 'styled-components/macro';

import { FinInputTextArea } from 'components/FinInput/MainInput';

export const Title = styled.h5`
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  color: var(--fingray-900);
  font-weight: 600;
  line-height: 2rem;
  text-rendering: geometricprecision;
  letter-spacing: 0;
  margin-bottom: 1rem;
  padding-right: 1rem;
`;

export const Text = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  color: var(--fingray-900);
  font-weight: 400;
  line-height: 1.4rem;
  text-rendering: geometricprecision;
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  align-items: ${({ align }) => align || 'flex-start'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
`;

export const InputTextArea = styled(FinInputTextArea)`
  font-family: 'Inter', sans-serif;
  width: 100%;
  /* & > span {
    color: var(--fingray-900);
    text-transform: lowercase;
  }
  & > span:first-letter {
    text-transform: uppercase;
  } */
  & > textarea {
    font-family: 'Inter', sans-serif;
    position: relative;
    font-family: 'Inter', sans-serif;
    height: 100px;
    border-radius: 10px;
    border: none;
    font-weight: 400;
    background-color: var(--fingray-300);
    font-size: 0.9rem;
    letter-spacing: 0;
    color: var(--fingray-900);
    &::placeholder {
      color: var(--fingray-900);
      letter-spacing: 0;
    }

    ${({ disabled }) =>
      disabled &&
      css`
        background: #f2f2f3;
      `}
  }
`;

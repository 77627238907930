import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as IconClose } from 'assets/images/icon-x-line.svg';
import { UiPopUpActions } from 'store/ducks/uiPopUp';

import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';

// import { useShowModalPath } from 'hooks/useShowModalPath';

import {
  Root,
  Container,
  Image,
  Wrapper,
  LinkExternal,
  LinkInternal,
  Button,
} from './style.finpopup';

export function FinPopUp() {
  const dispatch = useDispatch();
  // const { show } = useShowModalPath({ path: `/finscanner-landing-page` });

  const { campaign } = useSelector((state) => state.uiPopUp);

  const checkDate = (start, end) => {
    const antes = isBefore(new Date(start), new Date());
    const depois = isAfter(new Date(end), new Date());

    return antes && depois;
  };

  const checkUrl = (url) => {
    // showInUrl: null => show all urls
    if (!url) {
      return true;
    }
    return window.location.pathname === url;
  };

  const close = (value) => {
    dispatch(UiPopUpActions.campaignShowed(value));
  };

  return campaign.map((item) => {
    if (
      item.active &&
      !item.showed &&
      checkUrl(item.showInUrl) &&
      checkDate(item.startDate, item.endDate)
    ) {
      const { urlExternal, urlInternal, callFunc /* finscannerShow */ } =
        item.action;

      const noActions = Object.keys(item.action).length === 0;

      return (
        <Container key={item.name}>
          <Wrapper>
            <Button onClick={() => close(item.name)}>
              <IconClose />
            </Button>
            <Root ratio={2}>
              {urlExternal && (
                <LinkExternal
                  href={urlExternal}
                  onClick={() => {
                    close(item.name);
                  }}
                  target={'_blank'}
                >
                  <Image src={item.image} />
                </LinkExternal>
              )}

              {urlInternal && (
                <LinkInternal
                  to={urlInternal}
                  onClick={() => {
                    close(item.name);
                  }}
                >
                  <Image src={item.image} />
                </LinkInternal>
              )}

              {callFunc && (
                <Image
                  src={item.image}
                  alt="image-banner-action"
                  onClick={() => {
                    callFunc();
                    close(item.name);
                  }}
                />
              )}

              {/* {finscannerShow && (
                <Image
                  src={item.image}
                  alt="image-banner-finscanner-show"
                  onClick={() => {
                    show();
                    close(item.name);
                  }}
                />
              )} */}

              {noActions && (
                <Image alt="image-banner-no-action" src={item.image} />
              )}
            </Root>
          </Wrapper>
        </Container>
      );
    }
    return null;
  });
}

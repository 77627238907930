import React from 'react';
import styled, { css } from 'styled-components/macro';

import Head from './Head';
import Body from './Body';
import Footer from './Footer';

const MainTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  ${({ theme }) =>
    theme === 'secondary' &&
    css`
      background-color: #f7f7f7;
      border-radius: 0.2rem;
      thead {
        border-bottom: 1px solid #ffffff;
      }
      thead tr th {
        color: #378894;
        padding: 0.4rem 1rem;
      }
      tbody tr td {
        color: #646464;
        padding: 0.3rem 1rem;
      }
    `}
`;

const Table = ({ data, caption, footPosition, footContent, theme }) => {
  return (
    <MainTable theme={theme}>
      <Head data={data} caption={caption} />
      <Body data={data} />
      {footContent && (
        <Footer
          data={data}
          footPosition={footPosition}
          footContent={footContent}
        />
      )}
    </MainTable>
  );
};

export default Table;

import React from 'react';

import Skeleton from 'react-loading-skeleton';

export function SkeletonLine() {
  return (
    <div
      style={{
        width: '100%',
        heigth: '56px',
      }}
    >
      <Skeleton />
    </div>
  );
}

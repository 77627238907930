import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { darken, rgba } from 'polished';
import { device } from '../../devices';

export const MainButton = styled.button`
  position: relative;
  align-items: center;
  border: none;
  border-radius: ${({ rounded }) => (rounded ? '100px' : '2px')};
  color: ${({ theme }) => theme.newtheme.buttons.text};
  cursor: pointer;
  display: inline-flex;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  height: ${({ size }) => size};
  justify-content: center;
  line-height: normal;
  padding: 0 24px;
  text-align: center;
  user-select: none;
  white-space: nowrap;
  outline: none;
  box-shadow: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) =>
      theme.newtheme.buttons.disabled.background};
  }
  ${({ tipo, preenchimento }) =>
    tipo === 'primary' &&
    preenchimento === 'flat' &&
    css`
      background-color: ${({ theme }) =>
        theme.newtheme.buttons.primary.background};

      &:enabled:hover {
        background-color: ${({ theme }) =>
          darken(0.04, theme.newtheme.buttons.primary.background)};
      }
      &:focus {
        box-shadow: ${({ theme }) =>
          `0 0 0 0.2em ${rgba(theme.newtheme.buttons.primary.border, 0.4)}`};
      }
      &:active {
        border-color: ${({ theme }) =>
          theme.newtheme.buttons.primary.background};
      }
    `}

  ${({ tipo, preenchimento }) =>
    tipo === 'primary' &&
    preenchimento === 'outline' &&
    css`
      background-color: transparent;
      border: 2px solid
        ${({ theme }) => theme.newtheme.buttons.primary.background};
      color: ${({ theme }) => theme.newtheme.buttons.primary.background};

      &:enabled:hover {
        background-color: ${({ theme }) =>
          rgba(theme.newtheme.buttons.primary.background, 0.2)};
      }
      &:focus {
        box-shadow: ${({ theme }) =>
          `0 0 0 0.2em ${rgba(theme.newtheme.buttons.primary.border, 0.4)}`};
      }
      &:active {
        border-color: ${({ theme }) =>
          theme.newtheme.buttons.primary.background};
      }
    `}



    ${({ tipo, preenchimento }) =>
    tipo === 'secondary' &&
    preenchimento === 'flat' &&
    css`
      background-color: ${({ theme }) =>
        theme.newtheme.buttons.secondary.background};

      &:enabled:hover {
        background-color: ${({ theme }) =>
          darken(0.04, theme.newtheme.buttons.secondary.background)};
      }
      &:focus {
        box-shadow: ${({ theme }) =>
          `0 0 0 0.2em ${rgba(theme.newtheme.buttons.secondary.border, 0.4)}`};
      }
      &:active {
        border-color: ${({ theme }) =>
          theme.newtheme.buttons.secondary.background};
      }
    `}

      ${({ tipo, preenchimento }) =>
    tipo === 'secondary' &&
    preenchimento === 'outline' &&
    css`
      background-color: transparent;
      border: 2px solid
        ${({ theme }) => theme.newtheme.buttons.secondary.background};
      color: ${({ theme }) => theme.newtheme.buttons.secondary.background};

      &:enabled:hover {
        background-color: ${({ theme }) =>
          rgba(theme.newtheme.buttons.secondary.background, 0.2)};
      }
      &:focus {
        box-shadow: ${({ theme }) =>
          `0 0 0 0.2em ${rgba(theme.newtheme.buttons.secondary.border, 0.4)}`};
      }
      &:active {
        border-color: ${({ theme }) =>
          theme.newtheme.buttons.secondary.background};
      }
    `}


    ${({ tipo, preenchimento }) =>
    tipo === 'light' &&
    preenchimento === 'flat' &&
    css`
      background-color: ${({ theme }) =>
        theme.newtheme.buttons.light.background};
      color: ${({ theme }) => theme.newtheme.buttons.light.text};
      &:enabled:hover {
        background-color: ${({ theme }) =>
          darken(0.14, theme.newtheme.buttons.light.background)};
      }
      &:focus {
        box-shadow: ${({ theme }) =>
          `0 0 0 0.2em ${rgba(theme.newtheme.buttons.light.border, 0.4)}`};
      }
      &:active {
        border-color: ${({ theme }) => theme.newtheme.buttons.light.background};
      }
    `}


      ${({ tipo, preenchimento }) =>
    tipo === 'light' &&
    preenchimento === 'outline' &&
    css`
      background-color: transparent;
      border: 2px solid
        ${({ theme }) => theme.newtheme.buttons.light.background};
      color: ${({ theme }) => theme.newtheme.buttons.light.background};

      &:enabled:hover {
        background-color: ${({ theme }) =>
          rgba(theme.newtheme.buttons.light.background, 0.2)};
      }
      &:focus {
        box-shadow: ${({ theme }) =>
          `0 0 0 0.2em ${rgba(theme.newtheme.buttons.light.border, 0.4)}`};
      }
      &:active {
        border-color: ${({ theme }) => theme.newtheme.buttons.light.background};
      }
    `}

      ${({ tipo, preenchimento }) =>
    tipo === 'error' &&
    preenchimento === 'flat' &&
    css`
      background-color: ${({ theme }) =>
        theme.newtheme.buttons.error.background};

      &:enabled:hover {
        background-color: ${({ theme }) =>
          darken(0.04, theme.newtheme.buttons.error.background)};
      }
      &:focus {
        box-shadow: ${({ theme }) =>
          `0 0 0 0.2em ${rgba(theme.newtheme.buttons.error.border, 0.4)}`};
      }
      &:active {
        border-color: ${({ theme }) => theme.newtheme.buttons.error.background};
      }
    `}


      ${({ tipo, preenchimento }) =>
    tipo === 'error' &&
    preenchimento === 'outline' &&
    css`
      background-color: transparent;
      border: 2px solid
        ${({ theme }) => theme.newtheme.buttons.error.background};
      color: ${({ theme }) => theme.newtheme.buttons.error.background};

      &:enabled:hover {
        background-color: ${({ theme }) =>
          rgba(theme.newtheme.buttons.error.background, 0.2)};
      }
      &:focus {
        box-shadow: ${({ theme }) =>
          `0 0 0 0.2em ${rgba(theme.newtheme.buttons.error.border, 0.4)}`};
      }
      &:active {
        border-color: ${({ theme }) => theme.newtheme.buttons.error.background};
      }
    `}

        ${({ tipo, preenchimento }) =>
    tipo === 'alert' &&
    preenchimento === 'flat' &&
    css`
      background-color: ${({ theme }) =>
        theme.newtheme.buttons.alert.background};

      &:enabled:hover {
        background-color: ${({ theme }) =>
          darken(0.04, theme.newtheme.buttons.alert.background)};
      }
      &:focus {
        box-shadow: ${({ theme }) =>
          `0 0 0 0.2em ${rgba(theme.newtheme.buttons.alert.border, 0.4)}`};
      }
      &:active {
        border-color: ${({ theme }) => theme.newtheme.buttons.alert.background};
      }
    `}


      ${({ tipo, preenchimento }) =>
    tipo === 'alert' &&
    preenchimento === 'outline' &&
    css`
      background-color: transparent;
      border: 2px solid
        ${({ theme }) => theme.newtheme.buttons.alert.background};
      color: ${({ theme }) => theme.newtheme.buttons.alert.background};

      &:enabled:hover {
        background-color: ${({ theme }) =>
          rgba(theme.newtheme.buttons.alert.background, 0.2)};
      }
      &:focus {
        box-shadow: ${({ theme }) =>
          `0 0 0 0.2em ${rgba(theme.newtheme.buttons.alert.border, 0.4)}`};
      }
      &:active {
        border-color: ${({ theme }) => theme.newtheme.buttons.alert.background};
      }
    `}

          ${({ tipo, preenchimento }) =>
    tipo === 'success' &&
    preenchimento === 'flat' &&
    css`
      background-color: ${({ theme }) =>
        theme.newtheme.buttons.success.background};

      &:enabled:hover {
        background-color: ${({ theme }) =>
          darken(0.04, theme.newtheme.buttons.success.background)};
      }
      &:focus {
        box-shadow: ${({ theme }) =>
          `0 0 0 0.2em ${rgba(theme.newtheme.buttons.success.border, 0.4)}`};
      }
      &:active {
        border-color: ${({ theme }) =>
          theme.newtheme.buttons.success.background};
      }
    `}

            ${({ tipo, preenchimento }) =>
    tipo === 'success' &&
    preenchimento === 'outline' &&
    css`
      background-color: transparent;
      border: 2px solid
        ${({ theme }) => theme.newtheme.buttons.success.background};
      color: ${({ theme }) => theme.newtheme.buttons.success.background};

      &:enabled:hover {
        background-color: ${({ theme }) =>
          rgba(theme.newtheme.buttons.success.background, 0.2)};
      }
      &:focus {
        box-shadow: ${({ theme }) =>
          `0 0 0 0.2em ${rgba(theme.newtheme.buttons.success.border, 0.4)}`};
      }
      &:active {
        border-color: ${({ theme }) =>
          theme.newtheme.buttons.success.background};
      }
    `}

            ${({ tipo, preenchimento }) =>
    tipo === 'info' &&
    preenchimento === 'flat' &&
    css`
      background-color: ${({ theme }) =>
        theme.newtheme.buttons.info.background};

      &:enabled:hover {
        background-color: ${({ theme }) =>
          darken(0.04, theme.newtheme.buttons.info.background)};
      }
      &:focus {
        box-shadow: ${({ theme }) =>
          `0 0 0 0.2em ${rgba(theme.newtheme.buttons.info.border, 0.4)}`};
      }
      &:active {
        border-color: ${({ theme }) => theme.newtheme.buttons.info.background};
      }
    `}


              ${({ tipo, preenchimento }) =>
    tipo === 'info' &&
    preenchimento === 'outline' &&
    css`
      background-color: transparent;
      border: 2px solid ${({ theme }) => theme.newtheme.buttons.info.background};
      color: ${({ theme }) => theme.newtheme.buttons.info.background};

      &:enabled:hover {
        background-color: ${({ theme }) =>
          rgba(theme.newtheme.buttons.info.background, 0.2)};
      }
      &:focus {
        box-shadow: ${({ theme }) =>
          `0 0 0 0.2em ${rgba(theme.newtheme.buttons.info.border, 0.4)}`};
      }
      &:active {
        border-color: ${({ theme }) => theme.newtheme.buttons.info.background};
      }
    `}

              ${({ tipo, preenchimento }) =>
    tipo === 'dark' &&
    preenchimento === 'flat' &&
    css`
      background-color: ${({ theme }) =>
        theme.newtheme.buttons.dark.background};

      &:enabled:hover {
        background-color: ${({ theme }) =>
          darken(0.04, theme.newtheme.buttons.dark.background)};
      }
      &:focus {
        box-shadow: ${({ theme }) =>
          `0 0 0 0.2em ${rgba(theme.newtheme.buttons.dark.border, 0.4)}`};
      }
      &:active {
        border-color: ${({ theme }) => theme.newtheme.buttons.dark.background};
      }
    `}

                ${({ tipo, preenchimento }) =>
    tipo === 'dark' &&
    preenchimento === 'outline' &&
    css`
      background-color: transparent;
      border: 2px solid ${({ theme }) => theme.newtheme.buttons.dark.background};
      color: ${({ theme }) => theme.newtheme.buttons.dark.background};

      &:enabled:hover {
        background-color: ${({ theme }) =>
          rgba(theme.newtheme.buttons.dark.background, 0.2)};
      }
      &:focus {
        box-shadow: ${({ theme }) =>
          `0 0 0 0.2em ${rgba(theme.newtheme.buttons.dark.border, 0.4)}`};
      }
      &:active {
        border-color: ${({ theme }) => theme.newtheme.buttons.dark.background};
      }
    `}

                ${({ tipo, preenchimento }) =>
    tipo === 'link' &&
    preenchimento === 'flat' &&
    css`
      background-color: ${({ theme }) =>
        theme.newtheme.buttons.link.background};
      color: ${({ theme }) => theme.newtheme.buttons.link.text};

      &:enabled:hover {
        color: ${({ theme }) => darken(0.04, theme.newtheme.buttons.link.text)};
        text-decoration: underline;
      }
    `}



  ${({ largura }) =>
    largura === 'total' &&
    css`
      width: 100%;
    `};

  @media ${device.laptop} {
    height: ${({ size }) => size};
  }

  @media ${device.desktop} {
    height: ${({ theme }) => theme.newtheme.buttons.sizes.small};
  }
`;

const Button = ({
  children,
  disabled,
  largura,
  onClick,
  preenchimento,
  size,
  tipo,
  rounded,
  style,
  className,
}) => {
  return (
    <MainButton
      disabled={disabled}
      largura={largura}
      onClick={onClick}
      tipo={tipo}
      preenchimento={preenchimento}
      size={size}
      data-testid="botao"
      rounded={rounded}
      style={style}
      className={className}
    >
      {children}
    </MainButton>
  );
};

Button.propTypes = {
  children: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  largura: PropTypes.oneOf(['padrao', 'total']),
  onClick: PropTypes.func,
  preenchimento: PropTypes.oneOf(['flat', 'outline']),
  rounded: PropTypes.bool,
  style: PropTypes.object,
  tipo: PropTypes.oneOf([
    'primary',
    'secondary',
    'error',
    'alert',
    'success',
    'light',
    'dark',
    'link',
  ]),
  size: PropTypes.string,
};

Button.defaultProps = {
  disabled: false,
  largura: 'padrao',
  onClick: null,
  preenchimento: 'flat',
  tipo: 'primary',
  size: '38px',
  rounded: false,
};

export default Button;

import { useQuery } from 'react-query';
import axios from 'axios';
import { getUserData } from 'services/getUserData';
import CONFIG from 'config';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const fetchResumo = async (params) => {
  const { idEmpresa } = await getUserData();

  const payload = {
    ...params,
    idConvenio: idEmpresa,
  };

  const fetchData = axios.post(`${BASE_URL}/operacao/resumo`, payload);

  const result = await fetchData;

  const { data } = result;

  return data || [];
};

export const useFetchOperacoesResumo = (params) => {
  return useQuery(['operacoes', 'resumo', params], () => fetchResumo(params), {
    keepPreviousData: false,
  });
};

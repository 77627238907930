import React, { useContext } from 'react';
import Map from 'components/FinMap';
import { capitalizeWords } from 'helpers/string';
import { Card } from '../Comps/Card';

import { Flex } from './styles.filiais';
import { TitleFiliais } from '../Comps/TitleHead';

import { MenuFiliais } from './MenuFiliais';
import { PerfilContext } from '../PaginaFinCredit';
import { IconLockStyle } from '../style.layout';

import { SkeletonLine } from './SkeletonLine';

import {
  Content,
  SubTitle,
  InformationAdd,
} from '../Contato/styles.infocontato';

export function Filiais() {
  const { perfil } = useContext(PerfilContext);

  const logradouro =
    perfil.data && perfil.data.logradouro
      ? `${perfil.data.logradouro} ${
          perfil.data.numero && `, ${perfil.data.numero}`
        } ${perfil.data.cep && ` - ${perfil.data.cep}`}`
      : '';
  const complemento =
    perfil.data && perfil.data.complemento ? perfil.data.complemento : '';

  const withData = perfil.data;

  return (
    <>
      <TitleFiliais />
      <Card style={{ margin: '13px 0 48px' }}>
        <Flex column align="flex-start">
          <Flex style={{ marginBottom: '30px' }}>
            <MenuFiliais />
          </Flex>
          <Flex justify="flex-start" align="baseline">
            <Content marginRight="10px" largura="50%">
              <SubTitle>Fundação</SubTitle>
              {withData ? (
                <InformationAdd>{perfil.data.dataFundacao}</InformationAdd>
              ) : (
                <SkeletonLine />
              )}
            </Content>

            <Content marginRight="10px" largura="50%">
              <SubTitle>CNAE Principal</SubTitle>
              {withData ? (
                <InformationAdd>{`${perfil.data.cnaEcod} - ${perfil.data.cnaEdesc}`}</InformationAdd>
              ) : (
                <SkeletonLine />
              )}
            </Content>
          </Flex>
          <Flex
            justify="flex-start"
            align="baseline"
            style={{ marginBottom: '30px' }}
          >
            <Content marginRight="10px" largura="50%">
              <SubTitle>Segmento</SubTitle>
              {withData ? (
                <InformationAdd>
                  {capitalizeWords(perfil.data.segmento)}
                </InformationAdd>
              ) : (
                <SkeletonLine />
              )}
            </Content>
          </Flex>

          <Flex justify="flex-start" align="baseline">
            <Content marginRight="10px" largura="50%">
              <SubTitle>Endereço</SubTitle>
              {withData ? (
                <InformationAdd>
                  {perfil.data.criptografia ? <IconLockStyle /> : logradouro}
                </InformationAdd>
              ) : (
                <SkeletonLine />
              )}
            </Content>

            <Content marginRight="10px" largura="50%">
              <SubTitle>Cidade</SubTitle>
              {withData ? (
                <InformationAdd>
                  {capitalizeWords(perfil.data.cidade)}
                </InformationAdd>
              ) : (
                <SkeletonLine />
              )}
            </Content>
          </Flex>

          <Flex
            justify="flex-start"
            align="baseline"
            style={{ marginBottom: '10px' }}
          >
            <Content marginRight="10px" largura="50%">
              <SubTitle>Complemento</SubTitle>
              {withData ? (
                <InformationAdd>
                  {perfil.data && perfil.data.criptografia ? (
                    <IconLockStyle />
                  ) : (
                    complemento
                  )}
                </InformationAdd>
              ) : (
                <SkeletonLine />
              )}
            </Content>

            <Content marginRight="10px" largura="50%">
              <SubTitle>UF</SubTitle>
              {withData ? (
                <InformationAdd>{perfil.data.estado}</InformationAdd>
              ) : (
                <SkeletonLine />
              )}
            </Content>
          </Flex>

          <Map
            locked={perfil && perfil.criptografia}
            text="Meu Local"
            address={`${perfil.data && perfil.data.logradouro} - ${
              perfil.data && perfil.data.numero
            }`}
            altura="169px"
          />
        </Flex>
      </Card>
    </>
  );
}

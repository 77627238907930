/* eslint-disable no-unused-vars */
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import CONFIG from 'config';
import { handleErrors } from 'helpers/requisicoes/handleErrors';
import toast from 'react-hot-toast';
import { getUserData } from 'services/getUserData';
import { saveAs } from 'file-saver';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const download = async (params) => {
  const { idUsuario } = await getUserData();

  const axiosOptions = {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const payload = {
    idUsuario,
    idGrupo: params.idGrupo,
    zipName: params.nome,
    idDocumentos: params.documentos,
  };

  const mutateData = axios.post(
    `${BASE_URL}/matching/cliente/perfil/download`,
    payload,
    axiosOptions
  );

  const result = await mutateData;
  const { data } = result;

  const blob = new Blob([data], {
    type: 'application/octet-stream',
  });
  const filename = `${payload.zipName}.zip`;
  saveAs(blob, filename);

  return data || [];
};

export const useMutateDownloadDoc = () => {
  const queryClient = useQueryClient();

  return useMutation((params) => download(params), {
    onMutate: async (params) => {
      return { params };
    },
    onError: (error) => {
      const message = handleErrors(error);
      toast.error(message, { duration: 4000 });
    },
    onSuccess: (params, data, variables, context) => {
      queryClient.invalidateQueries('perfil');
    },
  });
};

import React, { useMemo, createElement } from 'react';
import { Network } from '@nivo/network';

import { useTooltip } from '@nivo/tooltip';

import { ReactComponent as IconMatriz } from 'assets/images/icon-matriz.svg';
import { ReactComponent as IconPJ } from 'assets/images/icon-pj.svg';
import { ReactComponent as IconPF } from 'assets/images/icon-pf.svg';
import { capitalizeWords, truncate } from 'helpers/string';
import { formatCnpjCpf } from 'helpers/format';

import { Faders } from '@phosphor-icons/react';
import { InputConfigurationNetworkChart } from '../InputConfigurationNetworkChart';
import { Button } from '../InputConfigurationNetworkChart/style';

import {
  Container,
  TooltipRoot,
  Label,
  Bullet,
  Title,
  Wrapper,
  Tag,
  Flex,
} from './style.bar-chart';

export function ChartRedeSocios({ setCurrentNode, data }) {
  const [config, setConfig] = React.useState({
    centeringStrength: 0.2,
    repulsivity: 49,
    distanceMin: 1,
    distanceMax: 'Infinity',
    iterations: 260,
  });

  const [showConfig, setShowConfig] = React.useState(false);

  const CustomNodeComponent = (props) => {
    const { showTooltipFromEvent, hideTooltip } = useTooltip();

    const renderTooltip = useMemo(
      () => () =>
        createElement(
          (props) => {
            const { color, labelTipo, name, cpf, cnpj } = props.node.data;
            const cpfFormat = (value) => {
              return value.indexOf('*') !== -1 ? value : formatCnpjCpf(value);
            };
            return (
              <TooltipRoot>
                <Bullet css={{ bgColor: color }} />
                <Title>{labelTipo}</Title>
                <Label>{truncate(capitalizeWords(name), 35)}</Label>
                <Label>{cnpj ? formatCnpjCpf(cnpj) : cpfFormat(cpf)}</Label>
              </TooltipRoot>
            );
          },
          { ...props }
        ),
      [props]
    );

    const { node } = props;
    const { x, y, id, data } = node;
    const { color, tipo, cnpj, cpf } = data;

    if (tipo === 'pj') {
      return (
        <g
          style={{ cursor: 'pointer' }}
          data-testid={id}
          transform={`translate(${x - 16},${y - 16}) scale(0.8)`}
          onClick={() => setCurrentNode({ cpfcnpj: cnpj || cpf, color })}
          onMouseEnter={(event) => showTooltipFromEvent(renderTooltip(), event)}
          onMouseLeave={() => hideTooltip()}
          onMouseMove={(event) => showTooltipFromEvent(renderTooltip(), event)}
        >
          <circle
            cx="16"
            cy="16"
            r="14.5"
            fill={color}
            stroke="#fff"
            stroke-width="3"
          />
          <path
            fill="#fff"
            d="M10.5 12H13v-.5a1.506 1.506 0 0 1 1.5-1.5h3a1.506 1.506 0 0 1 1.5 1.5v.5h2.5a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-11a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1Zm7.5-.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v.5h4v-.5ZM10.5 13v4.5h11V13h-11Z"
          />
        </g>
      );
    }

    if (tipo === 'pf') {
      return (
        <g
          style={{ cursor: 'pointer' }}
          data-testid={id}
          transform={`translate(${x - 16},${y - 16}) scale(0.8)`}
          onClick={() => setCurrentNode({ cpfcnpj: cnpj || cpf, color })}
          onMouseEnter={(event) => showTooltipFromEvent(renderTooltip(), event)}
          onMouseLeave={() => hideTooltip()}
          onMouseMove={(event) => showTooltipFromEvent(renderTooltip(), event)}
        >
          <circle
            cx="16"
            cy="16"
            r="14.5"
            fill={color}
            stroke="#fff"
            stroke-width="3"
          />
          <path
            fill="#fff"
            d="M16 18.25a2.75 2.75 0 1 0 0-5.5 2.75 2.75 0 0 0 0 5.5Z"
          />
          <path
            fill="#fff"
            d="M21 10H11a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V11a1 1 0 0 0-1-1Zm0 11h-.231a5.044 5.044 0 0 0-1.625-2.387 4.803 4.803 0 0 0-.563-.394 3.744 3.744 0 0 1-5.162 0c-.197.117-.385.249-.563.393A5.043 5.043 0 0 0 11.231 21H11V11h10v10Z"
          />
        </g>
      );
    }

    return (
      <g
        style={{ cursor: 'pointer' }}
        data-testid={id}
        transform={`translate(${x - 19},${y - 19}) scale(0.8)`}
        onClick={() =>
          setCurrentNode({ cpfcnpj: cnpj || cpf, color: '#008894' })
        }
        onMouseEnter={(event) => showTooltipFromEvent(renderTooltip(), event)}
        onMouseLeave={() => hideTooltip()}
        onMouseMove={(event) => showTooltipFromEvent(renderTooltip(), event)}
      >
        <circle
          cx="19"
          cy="19"
          r="17.5"
          fill="#008894"
          stroke="#fff"
          stroke-width="3"
        />
        <path
          fill="#fff"
          d="m26.948 17.506-6.562-5.963a1.313 1.313 0 0 0-1.772 0l-6.562 5.963a1.313 1.313 0 0 0-.427.968v7.556c-.005.33.111.652.328.902a1.305 1.305 0 0 0 .985.443h3.937a.656.656 0 0 0 .656-.656V22.78a.656.656 0 0 1 .657-.656h2.625a.656.656 0 0 1 .656.656v3.938a.656.656 0 0 0 .656.656h3.938c.217.002.432-.052.623-.156a1.32 1.32 0 0 0 .689-1.157v-7.588a1.313 1.313 0 0 0-.427-.968Z"
        />
      </g>
    );
  };

  // const linkDinstance = () => Math.floor(Math.random() * 100) + 1;

  return (
    <div style={{ position: 'relative' }}>
      <Container style={{ backgroundColor: '#E1FAFD', marginTop: '1rem' }}>
        <Button onClick={() => setShowConfig((state) => !state)}>
          <Faders size={32} />
        </Button>
        <Wrapper>
          <Flex style={{ gap: '8px' }}>
            <IconMatriz />
            <Tag>Matriz</Tag>
          </Flex>

          <Flex>
            <IconPJ />
            <Tag>Sócio PJ</Tag>
          </Flex>

          <Flex>
            <IconPF />
            <Tag>Sócio PF</Tag>
          </Flex>
        </Wrapper>

        <Network
          data={data}
          width={790}
          // height={260}
          height={700}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          nodeComponent={CustomNodeComponent}
          distanceMin={config.distanceMin}
          distanceMax={config.distanceMax}
          linkDistance={(e) => e.distance}
          // linkDistance={linkDinstance}
          centeringStrength={config.centeringStrength}
          repulsivity={config.repulsivity}
          iterations={config.iterations}
          nodeSize={(n) => n.size}
          activeNodeSize={(n) => 1.5 * n.size}
          nodeColor={(e) => e.color}
          nodeBorderWidth={1}
          nodeBorderColor={{
            from: 'color',
            modifiers: [['darker', 0.8]],
          }}
          linkThickness={(n) => 2 + 2 * n.target.data.height}
          linkBlendMode="multiply"
          motionConfig="wobbly"
        />
      </Container>
      {showConfig && (
        <InputConfigurationNetworkChart config={config} setConfig={setConfig} />
      )}
    </div>
  );
}

import { put } from 'redux-saga/effects';

import { loadCepSucess, loadCepFailure } from 'store/old_actions/actions';

/** ***************************** List  ******************************** */
export function* loadCep(action) {
  try {
    let { cep } = action;

    cep =
      action.cep.split('-').length > 1
        ? action.cep.split('-')[0] + action.cep.split('-')[1]
        : cep;

    let result = [];

    if (cep.length === 8) {
      result = yield fetch(`https://viacep.com.br/ws/${cep}/json/`).then(
        (response) => response.json()
      );

      if (result.erro) {
        // eslint-disable-next-line no-throw-literal
        throw { response: 'Cep inválido!!' };
      }
    }

    yield put(loadCepSucess(result));
  } catch (err) {
    // console.log(err);
    yield put(loadCepFailure(err.response));
  }
}

import React, { useCallback, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Creators as apiCbboxAction } from 'store/ducks/apiCbbox';
import { StringToSvg } from 'helpers/svgFiles/StringToSvg';
import { icons } from 'assets/icones/finplaceIcons';

// import feather from 'feather-icons/dist/icons.json';

import { ModalCuston } from 'components/FinModalCuston';

import { LoadingInfo } from 'components/FinLoading';
import FeedbackInfo from 'components/FinFeedbackInfo';
import { useFeedback } from 'hooks/useFeedback';

// import FinTagCreditBrasil from 'components/FinTagCreditBrasil';
import ConteudoCbbox from './Conteudo';

import { Container } from './Styles';

const PaginaCbbox = ({ history, match, isModal }) => {
  const apiCbboxState = useSelector((state) => state.apiCbbox);

  const { ready, waiting, error, empty } = useFeedback(apiCbboxState);

  const dispatch = useDispatch((state) => state.router.location.query);

  const {
    params: { idEmpreendedor },
  } = match;

  const dadosCbbox = useCallback(() => {
    // dispatch(apiCbboxAction.apiCbboxRequest(Cedente[2]));
    dispatch(apiCbboxAction.apiCbboxRequest(idEmpreendedor));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    dadosCbbox();
  }, [dadosCbbox]);

  const toBack = () => history.goBack();

  const TitleModalV2 = () => {
    return (
      <>
        <StringToSvg
          strokeWidth="0"
          viewBox="0 0 200 36"
          width="200px"
          height="50px"
          fill="#007A87"
        >
          {icons.finmatch}
        </StringToSvg>
        {/* <TituloModalNovo>Finmatch</TituloModalNovo> */}
      </>
    );
  };

  return isModal ? (
    <ModalCuston
      versao="2"
      open={true}
      // title="Finmatch"
      scrolling="true"
      onClose={toBack}
      header={<TitleModalV2 />}
      bgColor="#f1f1f1"
      width="1169px"
    >
      <Container>
        {empty && <FeedbackInfo tipo={empty} text="Conteúdo não disponível" />}
        {error && <FeedbackInfo tipo={error} />}
        {waiting && <LoadingInfo loading={waiting} />}
        {ready && <ConteudoCbbox />}
      </Container>
      {/* <FinTagCreditBrasil /> */}
    </ModalCuston>
  ) : (
    <Container style={{ marginTop: '8rem' }}>
      <Container>
        {empty && <FeedbackInfo tipo={empty} text="Conteúdo não disponível" />}
        {error && <FeedbackInfo tipo={error} />}
        {waiting && <LoadingInfo loading={waiting} />}
        {ready && <ConteudoCbbox />}
      </Container>
      {/* <FinTagCreditBrasil /> */}
    </Container>
  );
};

export default PaginaCbbox;

import React from 'react';
import { useSelector } from 'react-redux';

import { Wrapper } from 'components/FinWrappers';
// import { currency } from 'helpers/format';

import { maskCNPJ } from 'utils/formatValues';

import { currency } from 'helpers/format';
import { TooltipDefault } from 'components/TooltipDefault';
import { icons } from 'assets/icones/finplaceIcons';
import { StringToSvg } from 'helpers/svgFiles/StringToSvg';

import InitialLetters from 'components/FinInitialLetters';
import { date } from 'components/FinFormatter';
import { NivelAderencia } from './nivelAderencia';
// import formatDistance from 'date-fns/formatDistance';
import {
  CustomWrapper,
  CircleLetters,
  CustomWrapperCircleLetters,
  BlocoConteudo,
  Bloco1Paragrafo,
  Bloco1ParagrafoDestaque,
  SituacaoEmpresa,
  TextBlur,
  CustomH2,
  CustomH3,
  CustomParagrafo,
  TextoDisclaimer,
} from './Styles';

const anosFundacao = (data) => {
  if (data === '' || data === '0001-01-01T00:00:00' || data === undefined) {
    return '';
  }
  let ano = new Date(data);
  let anoAtual = new Date();
  ano = ano.getFullYear();
  anoAtual = anoAtual.getFullYear();
  const contAnos = anoAtual - ano;
  return ` (${contAnos} anos)`;

  // return formatDistance(new Date('2020-01-01T00:00:00'), new Date());
};

const DadosCedente = () => {
  const apiCbboxState = useSelector(
    (state) => state.apiCbbox.data[0].informacoes_Match
  );

  const dataCedente = (dataEnviada) => {
    if (
      dataEnviada === '' ||
      dataEnviada === '0001-01-01T00:00:00' ||
      dataEnviada === 'undefined' ||
      dataEnviada === null ||
      dataEnviada === 'null' ||
      dataEnviada === undefined
    ) {
      return 'Sem Data Informada';
    }
    return date(dataEnviada);
  };

  // const valorCapitalSocial = (vlCapitalSocial) => {
  //   if (vlCapitalSocial !== '' && vlCapitalSocial !== undefined) {
  //     return currency(vlCapitalSocial);
  //   }
  //   return 'valor não informado';
  // };

  const valorFaturamento = currency(apiCbboxState.faturamento || 0);
  const valorEndividamento = currency(apiCbboxState.endividamento || 0);

  const valorCapitalSocial = currency(apiCbboxState.capitalSocial || 0);
  const valorConcentracao =
    apiCbboxState.cedente_Concentracao === 'Não Classificado'
      ? 'Não Classificado'
      : `${apiCbboxState.cedente_Concentracao}`;

  // console.log('DADOS DA API');
  // console.log(apiCbboxState);
  let valorMatchPortcentagem;
  if (
    apiCbboxState.matchPorcentagem === undefined ||
    apiCbboxState.matchPorcentagem === 'undefined'
  ) {
    valorMatchPortcentagem = 0;
  } else {
    valorMatchPortcentagem = apiCbboxState.matchPorcentagem;
  }

  return (
    <>
      <BlocoConteudo
        pwidth="96% !important"
        alignItems="center"
        tipo="row"
        justifyContent="initial"
      >
        <Wrapper
          pwidth="75%"
          padding="0rem"
          alignItems="center"
          tipo="column"
          justifyContent="initial"
        >
          <CustomH2 className="primeiro">Análise Cedente</CustomH2>

          <CustomH3>Nível de Aderência</CustomH3>
          <NivelAderencia dados={valorMatchPortcentagem} />

          <Wrapper
            pwidth="100%"
            justifyContent="center"
            className="blocoInfoValores"
          >
            {/* <RatingCedente dados={apiCbboxState.cedenteRating || 'Ø'} /> */}
            <Wrapper pwidth="31%">
              <CustomWrapper pwidth="18%" className="CircleIconsAderencia">
                <StringToSvg
                  viewBox="-2 4 55 40"
                  width="30px"
                  height="20px"
                  strokeWidth="0"
                >
                  {icons.cbboxFaturamento}
                </StringToSvg>
              </CustomWrapper>
              <CustomWrapper pwidth="80%" padding="0">
                <CustomParagrafo style={{ fontSize: '0.80rem' }}>
                  Faturamento (ano)
                </CustomParagrafo>
                <CustomParagrafo
                  style={{ fontSize: '1.1rem', lineHeight: '1.5rem' }}
                >
                  <strong>{valorFaturamento}</strong>
                </CustomParagrafo>
              </CustomWrapper>
            </Wrapper>
            <Wrapper pwidth="33%">
              <CustomWrapper pwidth="18%" className="CircleIconsAderencia">
                <StringToSvg
                  viewBox="-2 4 55 40"
                  width="30px"
                  height="20px"
                  strokeWidth="0"
                >
                  {icons.cbboxEndividamento}
                </StringToSvg>
              </CustomWrapper>
              <CustomWrapper pwidth="80%" padding="0">
                <CustomParagrafo style={{ fontSize: '0.80rem' }}>
                  Endividamento Total
                </CustomParagrafo>
                <CustomParagrafo
                  style={{ fontSize: '1.1rem', lineHeight: '1.5rem' }}
                >
                  <strong>{valorEndividamento}</strong>
                </CustomParagrafo>
              </CustomWrapper>
            </Wrapper>
            <Wrapper pwidth="35%">
              <CustomWrapper pwidth="18%" className="CircleIconsAderencia">
                <StringToSvg
                  viewBox="-0 -0 20 20"
                  width="30px"
                  height="20px"
                  strokeWidth="0"
                >
                  {icons.cbboxConcentracao}
                </StringToSvg>
              </CustomWrapper>
              <CustomWrapper pwidth="80%" padding="0">
                <CustomParagrafo style={{ fontSize: '0.80rem' }}>
                  Concentração
                </CustomParagrafo>
                <CustomParagrafo
                  style={{ fontSize: '1.1rem', lineHeight: '1.5rem' }}
                >
                  <strong>
                    <TooltipDefault
                      position="left"
                      data="A concentração é o percentual de representatividade dos 3 principais clientes (sacados) sobre o faturamento da empresa."
                    >
                      {valorConcentracao}
                    </TooltipDefault>
                  </strong>
                </CustomParagrafo>
              </CustomWrapper>
            </Wrapper>
          </Wrapper>
          <TextoDisclaimer>
            *Lorem Ipsum is simply dummy text of the printing and typesetting
            simply dummy text of the printing simply dummy text of the
            printingsimply dummy text of the printing
          </TextoDisclaimer>
        </Wrapper>

        <Wrapper
          pwidth="25%"
          padding="0rem"
          tipo="column"
          alignItems="center"
          justifyContent="flex"
          className="linhaEsquerda"
        >
          <CustomWrapperCircleLetters style={{ width: '100%' }}>
            <CircleLetters>
              <p>
                <InitialLetters letters={apiCbboxState.nome} />
              </p>
              <SituacaoEmpresa className="ativo">ativo</SituacaoEmpresa>
            </CircleLetters>
            <Bloco1Paragrafo>
              <strong>{apiCbboxState.nome}</strong>
              <br />
              {apiCbboxState.cnpj !== '************' ? (
                maskCNPJ(apiCbboxState.cnpj)
              ) : (
                <TextBlur>**.***.***-****-**</TextBlur>
              )}
            </Bloco1Paragrafo>
          </CustomWrapperCircleLetters>

          <CustomWrapper pwidth="100% !important">
            <Bloco1ParagrafoDestaque>
              Capital Social e Fundação
            </Bloco1ParagrafoDestaque>
            <Bloco1Paragrafo>
              {valorCapitalSocial}
              {/* &nbsp;-&nbsp;
              {apiCbboxState.uf !== '' && apiCbboxState.uf !== null
                ? apiCbboxState.uf
                : 'Não Informado'} */}
            </Bloco1Paragrafo>
            <Bloco1Paragrafo>
              {dataCedente(apiCbboxState.dt_fundacao)}
              {anosFundacao(apiCbboxState.dt_fundacao)}
            </Bloco1Paragrafo>
          </CustomWrapper>

          <CustomWrapper pwidth="100% !important">
            <Bloco1ParagrafoDestaque>Segmento</Bloco1ParagrafoDestaque>
            <Bloco1Paragrafo>
              {apiCbboxState.cedente_Segmento !== ''
                ? apiCbboxState.cedente_Segmento
                : 'Não Classificado'}
            </Bloco1Paragrafo>
          </CustomWrapper>

          <CustomWrapper pwidth="100% !important">
            <Bloco1ParagrafoDestaque>Ramo</Bloco1ParagrafoDestaque>
            <Bloco1Paragrafo>
              {apiCbboxState.cnaE_Desc !== ''
                ? apiCbboxState.cnaE_Desc
                : 'Não Classificado'}
              <br />
              &nbsp;
            </Bloco1Paragrafo>
          </CustomWrapper>

          <CustomWrapper pwidth="100% !important" style={{ display: 'none' }}>
            <Bloco1ParagrafoDestaque>
              Atividade Principal
            </Bloco1ParagrafoDestaque>
            <Bloco1Paragrafo>
              {apiCbboxState.cnaE_Cod} - {apiCbboxState.cnaE_Desc}
            </Bloco1Paragrafo>
          </CustomWrapper>
        </Wrapper>
      </BlocoConteudo>
    </>
  );
};

export default DadosCedente;

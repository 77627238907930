import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import operacoes from './old_reducers/operacoes';
import propostas from './old_reducers/propostas';
import borderos from './old_reducers/borderos';
import dashboard from './old_reducers/dashboard';
import pregoes from './old_reducers/pregoes';
import user from './old_reducers/user';
import reducerUI from './old_reducers/reducerUI';
import messagesComps from './old_reducers/messagesComps';
import finbox from './old_reducers/finbox';
import condicionante from './old_reducers/condicionante';
import resumo from './old_reducers/resumo';
import precificacao from './old_reducers/precificacao';
import listDownload from './old_reducers/listDownload';
import cep from './old_reducers/cep';
import perfil from './old_reducers/perfil';
import filters from './old_reducers/filters';
import uiPopUp from './uiPopUp';

import apiTermosNaoAssinado from './apiTermosNaoAssinado';
import apiTermosAssinar from './apiTermosAssinar';

import apiGeocode from './apiGeocode';
import apiFiltro from './apiFiltro';
import apiCreateMeeting from './apiCreateMeeting';

import routeSave from './routeSave';

import apiMotivosConvites from './apiMotivosConvites';
import apiListClientes from './apiListClientes';
import apiInformacoes from './apiInformacoes';
import apiConvitesRecebidos from './apiConvitesRecebidos';
import apiConvitesEnviados from './apiConvitesEnviados';
import apiPesquisa from './apiPesquisa';
/* import apiConvidar from './apiConvidar';
import apiAceitar from './apiAceitar';
import apiRecusar from './apiRecusar';
import apiCancelar from './apiCancelar';
import apiBloquear from './apiBloquear';
import apiDesbloquear from './apiDesbloquear'; */

import uiPagination from './uiPagination';
import uiToaster from './uiToaster';
import uiDialog from './uiDialog';
import uiMessages from './uiMessages';
import uiNotificationContent from './uiNotificationContent';

import apiCbbox from './apiCbbox';
import apiCNAE from './apiMatchListaCNAE';
import apiMATCHADERENCIA from './apiMatchObtemAderencia';
import apiMATCHLISTAGEM from './apiMatchObtemListagem';
import apiMATCHCADASTRO from './apiMatchCadastro';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    operacoes,
    propostas,
    borderos,
    dashboard,
    pregoes,
    user,
    reducerUI,
    messagesComps,
    finbox,
    condicionante,
    resumo,
    precificacao,
    listDownload,
    cep,
    perfil,
    filters,
    apiTermosNaoAssinado,
    apiTermosAssinar,
    apiGeocode,
    apiFiltro,
    apiCreateMeeting,
    apiMotivosConvites,
    apiListClientes,
    apiInformacoes,
    apiConvitesRecebidos,
    apiConvitesEnviados,
    apiPesquisa,
    routeSave,
    apiCbbox,
    apiCNAE,
    apiMATCHADERENCIA,
    apiMATCHLISTAGEM,
    apiMATCHCADASTRO,
    /* apiConvidar,
    apiAceitar,
    apiRecusar,
    apiCancelar,
    apiBloquear,
    apiDesbloquear, */
    uiPagination,
    uiToaster,
    uiDialog,
    uiMessages,
    uiPopUp,
    uiNotificationContent,
  });

export default createRootReducer;

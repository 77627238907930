import React from 'react';
import { ReactComponent as IconUser } from 'assets/images/icon-user-outline.svg';
import * as UserStyles from './styles.user';

export function User({ data }) {
  return (
    <UserStyles.Wrapper>
      <UserStyles.BgIcon>
        <IconUser />
      </UserStyles.BgIcon>
      <UserStyles.ContentDescription>
        <UserStyles.Name>{data.nome}</UserStyles.Name>
        {/* <UserStyles.Email>{data.email}</UserStyles.Email> */}
      </UserStyles.ContentDescription>
    </UserStyles.Wrapper>
  );
}

import React, { useEffect, useContext } from 'react';

import { LoadingInfo } from 'components/FinLoading';
import { Wrapper } from 'components/FinWrappers';
import FeedbackInfo from 'components/FinFeedbackInfo';

import Pagination from 'components/FinPagination/PaginationDefault';
import CardList from './CardList';
import List from '../List';

import { useFetchConvitesEnviados } from '../queries/useFetchConvitesEnviados';
import { ConvitesContext } from './Provider';

const Enviados = ({ tabActive }) => {
  const [state, actions] = useContext(ConvitesContext);

  const {
    data: dados,
    isLoading,
    error,
  } = useFetchConvitesEnviados({
    size: state.pagination.size,
    skip: state.pagination.skip,
  });

  useEffect(() => {
    return () => {
      actions.setPage(1);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper
      padding="50px 50px 30px"
      tipo="column"
      style={{ minHeight: '400px', justifyContent: 'space-between' }}
    >
      {isLoading && <LoadingInfo loading={isLoading} />}
      {error && <FeedbackInfo tipo="error" />}
      {!!dados && dados.data.length === 0 && (
        <FeedbackInfo tipo="empty" text="Conteúdo não disponível" />
      )}
      {!!dados && (
        <>
          <List>
            {dados.data
              .filter((f) => !f.localChangedIsHide)
              .map((res) => (
                <CardList data={res} key={res.idConexao} tipo={tabActive} />
              ))}
          </List>
          <Pagination
            changePage={(value) => actions.setPage(value.page)}
            totalPages={!!dados && dados.totalPages}
            page={state.pagination.skip}
            place="center"
          />
        </>
      )}
    </Wrapper>
  );
};

export default Enviados;

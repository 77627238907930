/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { finTheme } from 'finTheme';

export const customStyles = {
  container: (styles) => ({ ...styles, width: '100%' }),
  control: (styles, { isFocused }) => {
    return {
      ...styles,
      boxShadow: isFocused
        ? `0 0 0 1px ${finTheme.newtheme.color.azulPrimario}`
        : undefined,
      borderColor: isFocused ? finTheme.newtheme.color.azulPrimario : undefined,
      ':hover': {
        ...styles[':hover'],
        borderColor: isFocused
          ? finTheme.newtheme.color.azulPrimario
          : undefined,
      },
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected
        ? finTheme.newtheme.color.azulPrimario
        : isFocused
        ? '#f2f2f2'
        : undefined,
      ':hover': {
        ...styles[':hover'],
        backgroundColor: isSelected
          ? finTheme.newtheme.color.azulPrimario
          : undefined,
      },
      ':active': {
        ...styles[':active'],
        backgroundColor: finTheme.newtheme.color.azulPrimario,
      },
    };
  },
  input: (styles) => {
    return { ...styles };
  },
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles, { data }) => ({ ...styles }),
};

import React from 'react';
import { flatten } from 'ramda';

import { Ring } from '@uiball/loaders';
import * as NWResult from './style.resultsearch';

import { Card } from '../Card';
import { NWButton } from '../Button';

import { statusConexao } from '../../config';

export const ResultSearch = ({
  data,
  search,
  setSearchTerm,
  loadMoreItems,
  currentPage,
  isFetching,
  status,
}) => {
  const withData = data && data.pages;

  const [dados] = withData ? data.pages : [];

  const lista = withData
    ? flatten(data.pages.filter((res) => res.data).map((r) => r.data))
    : [];

  const total = dados && dados.totalRecords ? dados.totalRecords : 0;

  return (
    <NWResult.Box>
      <NWResult.Wrapper>
        <NWResult.RegionInfo>
          <NWResult.TitleBar>
            {`${total} Resultados para a busca : "${search}"`}
          </NWResult.TitleBar>
        </NWResult.RegionInfo>
        <NWResult.RegionAction>
          <NWButton.Secondary onClick={() => setSearchTerm('')}>
            Limpar Busca
          </NWButton.Secondary>
        </NWResult.RegionAction>
      </NWResult.Wrapper>

      <NWResult.List>
        {lista.map((item) => {
          const typeInvite = () =>
            item.tipoConvite === 1 ? 'sent' : 'received';
          const isInvite = item.tipoConvite ? typeInvite() : null;

          return (
            <NWResult.ItemList key={item.idGrupo}>
              <Card
                tipo="horizontal"
                data={item}
                isInvitation={isInvite}
                isLocked={item.bloqueado}
                isConnection={item.statusConexao === statusConexao.aceita}
              />
            </NWResult.ItemList>
          );
        })}
        {currentPage < total && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '1rem 0',
            }}
          >
            <NWButton.Secondary
              style={{ width: '200px' }}
              onClick={() => loadMoreItems()}
            >
              {status === 'loading' || isFetching ? (
                <Ring size={20} lineWeight={3} speed={2} color="white" />
              ) : (
                `Carregar mais`
              )}
            </NWButton.Secondary>
          </div>
        )}
      </NWResult.List>
    </NWResult.Box>
  );
};

import styled from 'styled-components/macro';

import { FinSelectBox } from 'components/FinSelectBox';

import { ReactComponent as Calendar } from '../../assets/icones/icone-calendario.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #000;
  font-family: 'Inter', 'sans-serif';
  font-size: 14px;
  letter-spacing: -0.128px;
  line-height: 1;
  background-color: #fff;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 70px;
  justify-content: flex-start;
  align-items: center;
  margin-top: 100px;
`;

export const IconContainer = styled.div`
  display: flex;
  width: 85px;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #00889433;
  border-radius: 16px;
`;

export const CalendarIcon = styled(Calendar)`
  display: flex;
  width: 55.5px;
  height: 42.5px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

export const Title = styled.div`
  font-size: 40px;
  font-weight: 700;
`;

export const TimeDate = styled.div`
  font-size: 24px;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 4px 12px 3px #0000001a;
  overflow: hidden;
  padding: 0 1px;
  border-radius: 12px;
  margin-bottom: 100px;
`;

export const PrioritySelect = styled(FinSelectBox)`
  & div {
    height: 20px;
    border-radius: 12px;
    border: 1px solid #c2c2c2;
    position: relative;
    color: #646464;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }

  & select {
    text-align: center;
    vertical-align: middle;
    font-family: 'Inter', 'sans-serif';
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.128px;
    color: #646464;
    line-height: 1;
  }

  & ::after {
    background-color: #646464;
    position: absolute;
    right: 4px;
  }
`;

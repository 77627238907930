import { BehaviorSubject } from 'rxjs';
import { handleResponse } from './HandleResponse';
import Api from './Api';

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem('currentUser'))
);

export const authenticationService = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

function login(user, password) {
  /*const requestOptions = {
    method: 'POST',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify({ user, password })
  };*/

  /*const request2 = {
    mode: 'cors',
    user: user,
    password: password,
  };*/

  const request = {
    params: {
      user: user,
      password: password,
    },
  };

  return Api.loginUser(request)
    .then(handleResponse)
    .then(user => {
      console.log(user);
      if (user.data.status) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        currentUserSubject.next(user);
        console.log(user);
      }

      return user;
    })
    .catch(e => {
      console.log(e);
    });

  /*
    return fetch('https://securitytest.azurewebsites.net/Api/Test/Loga', requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);

            return user;
    });
    */
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('currentUser');
  currentUserSubject.next(null);
}

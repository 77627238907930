import styled, { css } from 'styled-components/macro';
import { rgba } from 'polished';
import { device } from 'devices';

export const Shadown = styled.div`
  position: fixed;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: calc(100% + 56px);
  z-index: ${({ zIndex }) => zIndex};
  top: 0;
  left: 0;

  ${({ color, theme }) =>
    color
      ? css`
          background-color: ${rgba(color, 0.4)};
        `
      : css`
          background-color: ${rgba(theme.newtheme.modal.shadowColor, 0.4)};
        `};

  ${({ scrolling }) =>
    scrolling === 'true' &&
    css`
      height: 100%;
      overflow-y: scroll;
    `}
`;

export const Container = styled.div`
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
  position: relative;
  width: ${({ pwidth }) => pwidth};
  padding: ${({ ppadding }) => ppadding};
  background: ${({ theme, bgColor }) =>
    bgColor || theme.newtheme.modal.bodyColor};
  border-radius: 8px;
  transition: all 0.1s ease-in;
  margin: ${({ margin }) => margin || `10vh 0 10vh 0`};
  overflow: hidden;
  min-width: 600px;

  ${({ center, margin }) =>
    center &&
    css`
      margin: ${margin || `0 0 10vh 0`};
      top: 50%;
      transform: translate(0, -50%);
    `}

  ${({ boxShadow }) =>
    boxShadow &&
    css`
      box-shadow: ${({ theme }) => theme.newtheme.dropShadow.dropShadow1};
    `};

  ${({ scrolling }) =>
    !scrolling &&
    css`
      @media ${device.laptop} {
        min-height: 300px;
        max-width: 800px;
      }
    `}

  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${minHeight};
    `}

    ${({ customStyle }) => customStyle && customStyle}
`;

export const ButtonClose = styled.button`
  background-color: transparent;
  border: none;
  text-decoration: none;
  outline: none;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 1rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  align-items: center;
  flex-wrap: wrap;
`;

export const Title = styled.h2`
  margin: 0;
  flex: 1;
  color: ${({ theme }) => theme.newtheme.color.azulPrimario};
  font-size: ${({ theme }) => theme.newtheme.font.titulo5.tamanho};
  font-weight: 600;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

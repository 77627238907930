import React from 'react';

import messages from 'helpers/messages/index.json';
import { Ring } from '@uiball/loaders';
import { Flex, Title, Text } from './style.dialog';
import { NWButton } from '../Button';

import { useMutateBlock } from '../../hook/mutates';

export function BlockDialog({ data, closePopover }) {
  const block = useMutateBlock();

  React.useEffect(() => {
    if (block.isSuccess) {
      closePopover();
    }
  }, [block.isSuccess, closePopover]);

  return (
    <Flex direction="column" style={{ padding: '2rem' }}>
      <Title>{messages.dialog.bloqueio.titulo}</Title>
      <Text>{messages.dialog.bloqueio.texto}</Text>
      <Flex
        justify="flex-end"
        style={{ width: '100%', gap: '10px', marginTop: '2rem' }}
      >
        <NWButton.Error
          onClick={() => {
            closePopover();
          }}
        >
          {messages.dialog.bloqueio.labelCancelar}
        </NWButton.Error>
        <NWButton.ErrorHeavy
          largura="padrao"
          disabled={block.isLoading}
          onClick={() => block.mutate({ idGrupo: data.idGrupo })}
        >
          {block.isLoading ? (
            <Ring size={20} lineWeight={3} speed={2} color="white" />
          ) : (
            messages.dialog.bloqueio.labelConfirme
          )}
        </NWButton.ErrorHeavy>
      </Flex>
    </Flex>
  );
}

import React, { useEffect, useCallback, useRef } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { currency } from 'helpers/format';
import { oddOrEven } from 'helpers/number';
import { finTheme } from 'finTheme';
import LoadingBar from 'react-top-loading-bar';
import { ButtonCircle } from 'components/FinButton/ButtonCircle';
import { ReactComponent as IcoRestaurar } from 'assets/images/icon-resturar.svg';
import { useQueryClient } from 'react-query';
import { WrapperButton } from 'pages/Empreendedores/DetalheEmpreendedor/styles.dashboard';
import { useParams } from 'react-router-dom';
import { theme } from './theme';

import { dashboardKeys } from '../queryKeyFactory';

import { useChartFaturamentoMensal } from '../hooks';

/* const initData = [
  {
    mesAno: 'Jan/21',
    faturamento: 0,
    faturamentoColor: '#FFD7D2',
  },
  {
    mesAno: 'Fev/21',
    faturamento: 0,
    faturamentoColor: '#F6EAE8',
  },
  {
    mesAno: 'Mar/21',
    faturamento: 0,
    faturamentoColor: '#FFD7D2',
  },
  {
    mesAno: 'Abr/21',
    faturamento: 0,
    faturamentoColor: '#F6EAE8',
  },
  {
    mesAno: 'Mai/21',
    faturamento: 0,
    faturamentoColor: '#FFD7D2',
  },
  {
    mesAno: 'Jun/21',
    faturamento: 0,
    faturamentoColor: '#F6EAE8',
  },
  {
    mesAno: 'Jul/21',
    faturamento: 0,
    faturamentoColor: '#FFD7D2',
  },
  {
    mesAno: 'Ago/21',
    faturamento: 0,
    faturamentoColor: '#F6EAE8',
  },
  {
    mesAno: 'Set/21',
    faturamento: 0,
    faturamentoColor: '#FFD7D2',
  },
  {
    mesAno: 'Out/21',
    faturamento: 0,
    faturamentoColor: '#F6EAE8',
  },
  {
    mesAno: 'Nov/21',
    faturamento: 0,
    faturamentoColor: '#FFD7D2',
  },
  {
    mesAno: 'Dez/21',
    faturamento: 0,
    faturamentoColor: '#F6EAE8',
  },
]; */

export const FaturamentoMensal = () => {
  const ref = useRef(null);
  const { idGrupo } = useParams();

  const queryClient = useQueryClient();

  const faturamentoMensal = useChartFaturamentoMensal({
    ano: 2021,
    idEmpresa: idGrupo,
  });

  const { isLoading, data, isError, isSuccess } = faturamentoMensal;

  const reloadChart = () => {
    queryClient.resetQueries(
      dashboardKeys.faturamentoMensal({
        ano: 2021,
        idEmpresa: idGrupo,
      })
    );
  };

  const getData = useCallback(() => {
    let fill = {};
    if (isSuccess) {
      const values = data.length > 0 && data.map((res) => res.valorFaturamento);
      const higherValue = Math.max(...values);

      const custonData =
        data.length > 0 &&
        data.map((res, index) => {
          if (res.valorFaturamento === higherValue) {
            fill = {
              match: (d) => {
                return d.data.value === higherValue;
              },
              id: 'lines',
            };
            return {
              ...res,
              faturamento: res.valorFaturamento,
              faturamentoColor: '#007A87',
            };
          }
          let values = {};
          if (oddOrEven(index) === 'even') {
            values = {
              ...res,
              faturamento: res.valorFaturamento,
              faturamentoColor: '#8ad4d2',
            };
          } else {
            values = {
              ...res,
              faturamento: res.valorFaturamento,
              faturamentoColor: '#8ad4d2',
            };
          }
          return values;
        });

      return { data: custonData, fill };
    }

    return { data: [], fill };
  }, [data, isSuccess]);

  useEffect(() => {
    if (ref.current) {
      if (isLoading) {
        ref.current.continuousStart();
      }
      if (isSuccess || isError) {
        ref.current.complete();
      }
    }
  }, [isError, isLoading, isSuccess]);

  const getDataFill = getData();

  return (
    <>
      <LoadingBar
        color={finTheme.newtheme.color.azulPrimario}
        ref={ref}
        containerStyle={{ position: 'absoulte', bottom: '0' }}
        shadow={false}
      />
      <WrapperButton>
        <ButtonCircle
          ariaLabel="reload-chart-1"
          size="large"
          tooltipText="Recarregar"
          tooltipPlace="top"
          tipo="button"
          id="reload-chart-1"
          onClick={(e) => {
            e.stopPropagation();
            reloadChart();
          }}
        >
          <IcoRestaurar />
        </ButtonCircle>
      </WrapperButton>
      <ResponsiveBar
        theme={theme}
        data={getDataFill.data}
        keys={['faturamento']}
        indexBy="mesAno"
        margin={{ top: 50, right: 20, bottom: 50, left: 100 }}
        padding={0.3}
        groupMode="grouped"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={({ id, data }) => String(data[`${id}Color`])}
        layout="vertical"
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: '#006874',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: '#006874',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        fill={[getDataFill.fill]}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        valueFormat={(value) => currency(value)}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          // legend: 'Meses',
          legendPosition: 'middle',
          legendOffset: 42,
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 12,
          tickRotation: 0,
          // legend: 'Faturamento',
          legendPosition: 'middle',
          legendOffset: -120,
          format: (value) => currency(value),
        }}
        enableLabel={true}
        labelSkipWidth={80}
        labelSkipHeight={15}
        labelTextColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        legends={[]}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={(e) => {
          return `${e.id}: ${e.formattedValue} in country: ${e.indexValue}`;
        }}
      />
    </>
  );
};

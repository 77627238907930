/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { createActions, createReducer } from 'reduxsauce';
import {
  call,
  put,
  take,
  fork,
  cancel,
  cancelled,
  select,
} from 'redux-saga/effects';
import axios, { CancelToken } from 'axios';

import { push } from 'connected-react-router';

import { getUserData } from 'services/getUserData';
import { Creators as uiToasterActions } from 'store/ducks/uiToaster';
import { Creators as apiConvitesRecebidosActions } from 'store/ducks/apiConvitesRecebidos';
import { Creators as apiListClientesActions } from 'store/ducks/apiListClientes';
import { CreatorsUiMessages } from 'store/ducks/uiMessages';

import CONFIG from '../../config';

/**
 * Criando action types & creators
 */

export const { Types, Creators } = createActions({
  apiRecusarRequest: ['idConvite', 'idCedente', 'idTipoRecusa', 'obs'],
  apiRecusarSuccess: ['data'],
  apiRecusarError: [],
  apiRecusarCancel: [],
});

/**
 * Criando os reducer handlers
 */

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: false,
};

const apiSetRequest = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: true,
    data: [],
    error: false,
  };
};

const apiSetSuccess = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: action.data,
    error: false,
  };
};

const apiSetError = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: [],
    error: true,
  };
};

const apiSetCancel = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: [],
    error: false,
  };
};

/**
 * Criando reducer
 */

export default createReducer(INITIAL_STATE, {
  [Types.API_RECUSAR_REQUEST]: apiSetRequest,
  [Types.API_RECUSAR_SUCCESS]: apiSetSuccess,
  [Types.API_RECUSAR_ERROR]: apiSetError,
  [Types.API_RECUSAR_CANCEL]: apiSetCancel,
});

/**
 * Sagas
 */

function* sagaGetAsynResult(params, endpoint, idCedente) {
  const source = CancelToken.source();
  const url = `${CONFIG.API_REMODELING_URL}${endpoint}`;
  try {
    const response = yield axios.put(url, params, {
      cancelToken: source.token,
    });

    yield put(Creators.apiRecusarSuccess(response.data.data));

    const stateRouter = (state) => state.router;
    const routes = yield select(stateRouter);

    if (routes.location.pathname.indexOf('detalhe') !== -1) {
      yield put(CreatorsUiMessages.uiSendMessage('refuse_sucessfully'));
    }

    yield put(
      apiConvitesRecebidosActions.apiConvitesRecebidosRefuseSent({
        ready: true,
        id: params.idConexao,
      })
    );

    if (idCedente) {
      // Remove idConvite da url
      const route = (state) => state.router.location;
      const location = yield select(route);
      yield put(push(location.pathname));
      yield put(
        apiListClientesActions.apiListClientesRefuseInviteSent({
          ready: true,
          id: idCedente,
        })
      );
    }

    yield put(
      uiToasterActions.uiToasterMessage(
        { message: 'Convite recusado!!' },
        'success'
      )
    );
  } catch (error) {
    console.log(error);
    yield put(
      apiConvitesRecebidosActions.apiConvitesRecebidosRefuseSent({
        remove: true,
        id: params.idConexao,
      })
    );
    if (idCedente) {
      yield put(
        apiListClientesActions.apiListClientesRefuseInviteSent({
          remove: true,
          id: idCedente,
        })
      );
    }
    yield put(uiToasterActions.uiToasterMessage(error, 'error'));
    yield put(Creators.apiRecusarError());
  } finally {
    if (yield cancelled()) {
      console.log('cancel');
      source.cancel();
    }
  }
}

export function* sagaRecusar(action) {
  const user = yield call(getUserData);

  if (user) {
    // const params = {
    //   idEmpresa: user.idEmpresa,
    //   idPessoa: user.idUsuario,
    //   idConvite: action.idConvite,
    //   idTipoRecusa: action.idTipoRecusa,
    //   obs: action.obs,
    // };

    const params = {
      idConexao: action.idConvite,
      idTipoRecusa: action.idTipoRecusa,
      obs: action.obs,
    };

    yield put(
      apiConvitesRecebidosActions.apiConvitesRecebidosRefuseSent({
        ready: false,
        id: params.idConexao,
      })
    );

    if (action.idCedente) {
      yield put(
        apiListClientesActions.apiListClientesRefuseInviteSent({
          ready: false,
          id: action.idCedente,
        })
      );
    }

    console.log(action);

    const task = yield fork(
      sagaGetAsynResult,
      params,
      '/business-api/api/matching/conexao/recusar',
      action.idCedente
    );

    yield take(Types.API_RECUSAR_CANCEL);
    yield cancel(task);
  } else {
    yield put(Creators.apiRecusarError());
  }
}

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components/macro';

import { CheckboxRounded } from 'components/FinCheckboxCuston';

import Button from 'components/FinButton';

import { Creators as apiTermosNaoAssinadoActions } from 'store/ducks/apiTermosNaoAssinado';
import { Creators as apiTermosAssinarActions } from 'store/ducks/apiTermosAssinar';
import { Loading } from 'components/FinLoading';
import ModalCuston from './ModalCuston';

const Iframe = styled.iframe`
  max-width: 100%;
  width: 100%;
  height: 600px;
  position: relative;
  bottom: 0;
  border: none;
`;

const ModalTermos = ({
  apiTermosNaoAssinado,
  termosNaoAssinadoActions,
  termosAssinarActions,
}) => {
  const [verModalAberto, setVerModalAberto] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [urlDoc, setUrlDoc] = useState('');
  const [isLoading, setIsloading] = useState(true);

  const refIframe = useRef(null);

  useEffect(() => {
    if (refIframe.current) {
      refIframe.current.onload = () => {
        setIsloading(false);
      };
    }
  });

  useEffect(() => {
    const value = localStorage.getItem('first_acess_termos');

    if (value === 'termos') {
      setVerModalAberto(false);
    } else {
      termosNaoAssinadoActions.apiTermosNaoAssinadoRequest();
    }
  }, [termosNaoAssinadoActions]);

  React.useMemo(() => {
    if (!apiTermosNaoAssinado.isLoading && apiTermosNaoAssinado.data) {
      setUrlDoc(apiTermosNaoAssinado.data.link || '');
      setVerModalAberto(true);
      localStorage.setItem('first_acess_termos', 'termos');
    }
    if (!apiTermosNaoAssinado.data) {
      setVerModalAberto(false);
    }
  }, [apiTermosNaoAssinado.isLoading, apiTermosNaoAssinado.data]);

  const confirmar = () => {
    setVerModalAberto(false);
    setIsChecked(false);
    termosAssinarActions.apiTermosAssinarRequest(
      apiTermosNaoAssinado.data.idTermo
    );
  };
  const changeCheck = () => setIsChecked((prevState) => !prevState);

  const OncloseModal = () => {
    setVerModalAberto(!verModalAberto);
    termosNaoAssinadoActions.apiTermosNaoAssinadoCancel();
  };

  return (
    verModalAberto && (
      <ModalCuston
        title="ATENÇÃO"
        open={verModalAberto}
        scrolling="true"
        onClose={() => OncloseModal()}
        withoutClickShadow
      >
        <div
          style={{
            textAlign: 'left',
            lineHeight: '1.2rem',
            color: '#646464',
          }}
        >
          <p>
            {apiTermosNaoAssinado.data.tipo === 'privacidade'
              ? `Atualização da Política de Privacidade da plataforma Finplace`
              : `Atualização dos Termos e Condições de Uso da plataforma Finplace.`}
          </p>
          <br></br>
          <Loading loading={isLoading} />
          <Iframe ref={refIframe} src={urlDoc} />
          <div style={{ margin: '30px 0' }}>
            <CheckboxRounded
              name="aceite"
              value="aceite"
              rounded="active"
              checked={isChecked}
              aoSelecionar={changeCheck}
              label={
                apiTermosNaoAssinado.data.tipo === 'privacidade'
                  ? 'Li e aceito a Política de Privacidade'
                  : 'Li e aceito os Termos e Condições de Uso'
              }
            />
          </div>
          <Button
            tipo="primary"
            size="48px"
            onClick={() => confirmar()}
            disabled={!isChecked}
          >
            OK
          </Button>
        </div>
      </ModalCuston>
    )
  );
};

const mapStateToProps = (state) => ({
  apiTermosNaoAssinado: state.apiTermosNaoAssinado,
  apiTermosAssinar: state.apiTermosAssinar,
});

const mapDispatchToProps = (dispatch) => {
  return {
    termosNaoAssinadoActions: bindActionCreators(
      apiTermosNaoAssinadoActions,
      dispatch
    ),
    termosAssinarActions: bindActionCreators(apiTermosAssinarActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalTermos);

import React from 'react';
import { Link } from 'react-router-dom';

import notFoundFigure from 'assets/images/feedback-graphics/undraw_not_found_60pq.svg';

export const Pagina404 = ({ location }) => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: '6rem',
      alignItems: 'center',
    }}
  >
    <h2
      className="title is-2 text-notfound"
      style={{ color: 'gray', marginTop: '2rem' }}
    >
      Página não encontrada para <code>{location.pathname}</code>
    </h2>
    <img
      src={notFoundFigure}
      alt="not found"
      style={{ width: '38%', marginBottom: '1rem' }}
    />
    <Link to="/"> Retornar para a Página inicial </Link>
  </div>
);

import { styled } from '../../../../stitches.config';

export const Container = styled('div', {
  position: 'relative',
  backgroundColor: '#ffffff',
  width: '100%',
  borderRadius: '29px',
  border: '1px solid #eaeaea',
  marginBottom: '1rem',
});

export const TooltipRoot = styled('div', {
  padding: '12px',
  background: '#000000',
  borderRadius: '6px',
  maxWidth: '280px',
  display: 'flex',
  flexDirection: 'column',
});

export const Label = styled('span', {
  fontFamily: 'Inter, sans-serif',
  color: 'var(--fingray-100)',
  fontWeight: '400',
  fontSize: '12px',
  textRendering: 'geometricprecision',
  // whiteSpace: 'nowrap',
});

export const Lagend = styled('div', {
  fontFamily: 'Inter, sans-serif',
  color: 'var(--fingray-900)',
  fontWeight: '400',
  fontSize: '12px',
  textRendering: 'geometricprecision',
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '1rem',
});

export const Title = styled('span', {
  fontFamily: 'Inter, sans-serif',
  color: 'var(--fingray-100)',
  fontWeight: '700',
  fontSize: '14px',
  textRendering: 'geometricprecision',
  // whiteSpace: 'nowrap',
});

export const Bullet = styled('span', {
  width: '14px',
  height: '14px',
  borderRadius: '4px',
  bgColor: 'gray',
});

export const Header = styled('div', {
  display: 'flex',
  gap: '10px',
});

export const Box = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '16px',
  bgColor: 'gray',
  padding: '1rem',
});

export const NumberBox = styled('div', {
  fontFamily: 'Inter, sans-serif',
  color: 'var(--fingray-900)',
  fontWeight: '700',
  fontSize: '16px',
  textRendering: 'geometricprecision',
  whiteSpace: 'nowrap',
  lineHeight: '1rem',
});

export const TextBox = styled('div', {
  fontFamily: 'Inter, sans-serif',
  color: 'var(--fingray-900)',
  fontWeight: '400',
  fontSize: '14px',
  textRendering: 'geometricprecision',
  // whiteSpace: 'nowrap',
  lineHeight: '1rem',
});

export const Wrapper = styled('div', {
  position: 'absolute',
  top: '1rem',
  left: '1rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

export const Tag = styled('span', {
  backgroundColor: '#000000',
  color: '#ffffff',
  padding: '2px 8px',
  borderRadius: '346px',
  fontFamily: 'Inter, sans-serif',
  fontWeight: '600',
  fontSize: '0.75rem',
  textRendering: 'geometricprecision',
  whiteSpace: 'nowrap',
  display: 'inline-flex',
  alignItems: 'center',
});

export const Flex = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
});

import React from 'react';

import Skeleton from 'react-loading-skeleton';

export function SkeletonLoading() {
  return (
    <div
      style={{
        width: 100,
        display: 'flex',
        gap: '15px',
      }}
    >
      <Skeleton width="157px" height="159px" />
      <Skeleton width="157px" height="159px" />
      <Skeleton width="157px" height="159px" />
      <Skeleton width="157px" height="159px" />
    </div>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Theme } from '../../theme';

const ArrowHor = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  animation: fadeIn 0.5s ease-in-out;
`;

const ArrowVert = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  animation: fadeIn 0.5s ease-in-out;
`;

const ArrowLeft = styled(ArrowHor)`
  border-right: 10px solid ${Theme.gray6};
  top: 50%;
  left: -10px;
  transform: translate(0, -50%);
`;

const ArrowRight = styled(ArrowHor)`
  border-left: 10px solid ${Theme.gray6};
  top: 50%;
  right: -10px;
  transform: translate(0, -50%);
`;

const ArrowDown = styled(ArrowVert)`
  border-top: 10px solid ${Theme.gray6};
  bottom: -10px;
  ${({ align }) => (align === 'left' ? 'left: 8px' : '')}
  ${({ align }) => (align === 'right' ? 'right: 8px' : '')}
  ${({ align }) =>
    align === 'center' ? 'left: 50%;transform: translate(-50%, 0);' : ''}
`;

const ArrowUp = styled(ArrowVert)`
  border-bottom: 10px solid ${Theme.gray6};
  top: -10px;
  ${({ align }) => (align === 'left' ? 'left: 8px' : '')}
  ${({ align }) => (align === 'right' ? 'right: 8px' : '')}
  ${({ align }) =>
    align === 'center' ? 'left: 50%;transform: translate(-50%, 0);' : ''}
`;

const Wrapper = styled.div`
  font-size: 0.8rem;
  line-height: 1rem;
  color: white;
  padding: 0.8rem;
  background-color: ${({ bgcolor }) => bgcolor && bgcolor};
  border-radius: 0.2rem;
  width: max-content;
  max-width: ${({ maxWidth }) => maxWidth};
  text-align: center;
  font-weight: 400;
  white-space: pre-wrap;
  box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
`;

const Container = styled.div`
  position: relative;
`;

const TooltipItem = ({ bgcolor, maxWidth, position, align, data }) => {
  const renderArrow = () => {
    switch (position) {
      case 'left':
        return <ArrowRight />;
      case 'right':
        return <ArrowLeft />;
      case 'down':
        return <ArrowUp align={align} />;
      case 'top':
        return <ArrowDown align={align} />;
      default:
        return <ArrowLeft />;
    }
  };
  return (
    <Container>
      {renderArrow()}
      <Wrapper maxWidth={maxWidth} bgcolor={bgcolor}>
        {data}
      </Wrapper>
    </Container>
  );
};

TooltipItem.propTypes = {
  bgcolor: PropTypes.string.isRequired,
  maxWidth: PropTypes.string,
  position: PropTypes.string.isRequired,
  align: PropTypes.string,
  data: PropTypes.string.isRequired,
};

TooltipItem.defaultProps = {
  bgcolor: Theme.gray6,
  maxWidth: '250px',
  position: 'left',
  align: 'center',
  data: null,
};

export default TooltipItem;

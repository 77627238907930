import React, { useContext } from 'react';

import { ReactComponent as IconeFolderOpen } from 'assets/images/icon-folder-open.svg';
import { ReactComponent as IconeMessage } from 'assets/images/icon-chat-message.svg';
import { TagStatus } from 'components/FinTag';
import { Wrapper } from 'components/FinWrappers';
import CurrencyFormatter from 'currency-formatter';

import { currency } from 'helpers/format';
import { dotToComma } from 'components/Formatter';

import { RaceBy } from '@uiball/loaders';

import { Caption } from 'components/FinParagraph';
import {
  FinInput,
  InputCurrencyStyle,
  FieldStyle,
} from 'components/FinInput/MainInput';
import { useFetchListaBordero } from '../../queries/useFetchListaBordero';
import { STATUS, IDS } from '../../config';
import { OperacoesContext, MODALS } from '../../provider';
import {
  CardRowStyle,
  CaptionCustom,
  CaptionCustomLine,
  ButtonRowStyle,
} from '../style';

export const EditaAntecipacao = () => {
  const [state, actions] = useContext(OperacoesContext);
  const {
    propostas: { nova },
  } = state;

  const { isLoading } = useFetchListaBordero({
    idBordero: nova.bordero.idBordero,
  });

  const onHandleFloat = (event) => {
    actions.updateNovaProposta([
      { field: 'nota', subField: 'vFloat', value: Number(event.target.value) },
    ]);
  };

  const onHandleBlurFloat = (event) => {
    if (event.target.value === '') {
      actions.updateNovaProposta([
        { field: 'nota', subField: 'vFloat', value: 0 },
      ]);
    }
  };

  const onHandleTaxa = (event) => {
    const value = CurrencyFormatter.unformat(event.target.value, {
      code: 'EUR',
    });

    actions.updateNovaProposta([
      { field: 'nota', subField: 'taxa', value: Number(value) },
    ]);
  };

  const borderoAcess =
    STATUS[IDS[nova.statusProposta.idPropostaStatus]].permissoes.indexOf(
      'verBordero'
    ) !== -1
      ? ['view']
      : ['edit'];

  const acertosAcess =
    STATUS[IDS[nova.statusProposta.idPropostaStatus]].permissoes.indexOf(
      'verAcertos'
    ) !== -1
      ? ['view']
      : ['edit'];

  const comentarioAcess =
    STATUS[IDS[nova.statusProposta.idPropostaStatus]].permissoes.indexOf(
      'verComentario'
    ) !== -1
      ? ['view']
      : ['edit'];

  return (
    <>
      <CardRowStyle>
        <Caption>Bruto Solicitado</Caption>
        <CaptionCustom>{currency(nova.nota.face)}</CaptionCustom>
      </CardRowStyle>
      <CardRowStyle>
        <Wrapper padding="0">
          {isLoading ? (
            <RaceBy size={30} lineWeight={5} speed={1.4} color="black" />
          ) : (
            <ButtonRowStyle
              fillSvg="#008894"
              colorHover="#70e5ef"
              onClick={() =>
                actions.setModalActive({
                  active: MODALS.bordero,
                  values: {
                    idBordero: nova.bordero.idBordero,
                    rules: borderoAcess,
                  },
                })
              }
            >
              <IconeFolderOpen />
            </ButtonRowStyle>
          )}
          <Caption>Títulos Recusados</Caption>
        </Wrapper>
        <CaptionCustom>{currency(nova.bordero.valorRecusado)}</CaptionCustom>
      </CardRowStyle>
      <CardRowStyle>
        <Caption>Bruto Proposta</Caption>
        <CaptionCustom>{currency(nova.nota.vop)}</CaptionCustom>
      </CardRowStyle>
      <CardRowStyle>
        <Caption>PMP (dias)</Caption>
        <CaptionCustom>{dotToComma(nova.nota.pmp.toFixed(2))}</CaptionCustom>
      </CardRowStyle>
      <CardRowStyle hideIndicator style={{ padding: '7px 0' }}>
        <CaptionCustomLine>Taxa (%)</CaptionCustomLine>
        <FieldStyle>
          <InputCurrencyStyle
            name="taxa"
            prefix=""
            decimalSeparator=","
            thousandSeparator=""
            onChangeEvent={onHandleTaxa}
            value={dotToComma(nova.nota.taxa)}
          />
        </FieldStyle>
      </CardRowStyle>
      <CardRowStyle hideIndicator style={{ padding: '7px 0' }}>
        <CaptionCustomLine>Float (dias)</CaptionCustomLine>
        <FinInput
          name="float"
          onChange={onHandleFloat}
          onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
          onBlur={onHandleBlurFloat}
          value={nova.nota.vFloat}
        />
      </CardRowStyle>
      <CardRowStyle>
        <Wrapper padding="0">
          <ButtonRowStyle
            fillSvg="#008894"
            colorHover="#70e5ef"
            onClick={() =>
              actions.setModalActive({
                active: MODALS.acertos,
                values: { rules: acertosAcess },
              })
            }
          >
            <IconeFolderOpen />
          </ButtonRowStyle>
          <Caption>Acertos</Caption>
        </Wrapper>
        <CaptionCustom>{currency(nova.nota.acertos)}</CaptionCustom>
      </CardRowStyle>
      <CardRowStyle>
        <Caption>Líquido</Caption>
        <CaptionCustom>{currency(nova.nota.liquido)}</CaptionCustom>
      </CardRowStyle>
      <CardRowStyle hideIndicator>
        <Wrapper padding="0">
          <ButtonRowStyle
            strokeSvg="#008894"
            colorHover="#70e5ef"
            onClick={() =>
              actions.setModalActive({
                active: MODALS.comentarios,
                values: { rules: comentarioAcess },
              })
            }
          >
            <IconeMessage />
          </ButtonRowStyle>
          <Caption>Comentário</Caption>
        </Wrapper>
        {!!nova.observacao && (
          <TagStatus
            bgcolor="#FF4D35"
            textColor="#FFF"
            altura="24px"
            id="count-comments"
          >
            1
          </TagStatus>
        )}
      </CardRowStyle>
    </>
  );
};

export const breakpoints = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1025px',
  laptopL: '1440px',
  desktop: '1920px',
  desktopL: '2160px',
};

export const size = {
  mobileS: breakpoints.mobileS,
  mobileM: breakpoints.mobileM,
  mobileL: breakpoints.mobileL,
  tablet: breakpoints.tablet,
  laptop: breakpoints.laptop,
  laptopL: breakpoints.laptopL,
  desktop: breakpoints.desktop,
  desktopL: breakpoints.desktopL,
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktopL})`,
};

/* 1366 x 625 - Hd screen notebook */

import styled, { css } from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

export const Tab = styled.div`
  display: flex;
  box-shadow: inset 0 -1px 0 ${({ theme }) => theme.newtheme.color.cinza2};
  overflow: hidden;
  justify-content: space-between;
  width: 100%;
  margin-top: 0;
  padding: 0;
`;

export const UnderlineNav = styled.ul`
  display: flex;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
`;

export const ItemTab = styled.li`
  display: flex;
  flex-direction: column;
  text-align: -webkit-match-parent;
`;

export const Item = styled.span`
  color: var(--fingray-900);
  font-family: 'Inter';
  font-weight: 400;
  font-size: 0.875rem;
  white-space: nowrap;
  text-decoration: none;
  height: 35px;

  padding: 0 16px;
  margin: 0 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  border-radius: 10px;

  ${({ isActive }) =>
    isActive && `font-weight: 700; color: var(--finblue-primary);`}

  &:hover {
    background-color: var(--fingray-300);
  }
`;

export const BorderBottom = styled.span`
  margin-top: 0.5rem;
  width: 100%;
  border-bottom: transparent;
  ${({ isActive }) =>
    isActive &&
    css`
      border-bottom: 2px solid var(--finblue-primary);
    `}
`;

export const Flex = styled.div`
  display: flex;
  ${({ row }) =>
    row &&
    css`
      flex-direction: row;
    `}

  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
    `}

  justify-content: ${({ justify }) => justify || `center`};
  align-items: ${({ align }) => align || `center`};
  width: 100%;
`;

export const Label = styled.span`
  font-family: 'Inter', sans-serif;
  color: var(--fingray-900);
  font-size: 0.875rem;
  font-weight: 400;
  text-rendering: geometricprecision;
  white-space: nowrap;
  margin-left: 8px;
`;

export const ItemLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  text-align: -webkit-match-parent;
  outline: none;
  text-decoration: none;

  &.active ${Item} {
    font-weight: 700;
    color: var(--finblue-primary);
  }

  &.active ${BorderBottom} {
    border-bottom: 2px solid var(--finblue-primary);
  }
`;

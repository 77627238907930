import { ReactComponent as IconeTrophy } from 'assets/images/icon-trophy-c.svg';
import { ReactComponent as IconeTrash } from 'assets/images/icon-trash.svg';
import { ReactComponent as IconeSad } from 'assets/images/icon-sad.svg';
import { ReactComponent as IconeHandDown } from 'assets/images/icon-hand-down.svg';
import { ReactComponent as IconeCheckdProp } from 'assets/images/icon-checked-prop.svg';
import { ReactComponent as IconeClockHist } from 'assets/images/icon-clock-hist.svg';
import { ReactComponent as IcoCash } from 'assets/images/icon-cash.svg';
import { ReactComponent as IcoHandDollar } from 'assets/images/icon-dollar.svg';

const INTERACTION = {
  verComentario: 'verComentario',
  addComentario: 'addComentario',

  negociar: 'negociar',
  aceitar: 'aceitar',

  verBordero: 'verBordero',
  editarBordero: 'editarBordero',

  verAcertos: 'verAcertos',
  editarAcertos: 'editarAcertos',

  verParcelas: 'verParcelas',
  editarParcelas: 'editarParcelas',

  recalcular: 'recalcular',
  enviar: 'enviar',

  confirmarPagamento: 'confirmarPagamento',
};

const IDS_STATUS_PAY = {
  iniciado: 1,
  aguardandoCedente: 2,
  // aguardandoConvenio: 3,
  finalizado: 4,
  finalizadoAuto: 5,
};

export const STATUS_PAY_PROPOSTAS = [
  { name: 'aConfirmarPagamento', id: [IDS_STATUS_PAY.iniciado] },
  { name: 'operacaoPaga', id: [IDS_STATUS_PAY.aguardandoCedente] },
  {
    name: 'pagamentoConcluido',
    id: [IDS_STATUS_PAY.finalizado, IDS_STATUS_PAY.finalizadoAuto],
  },
];

export const STATUS_PAGAMENTO = [
  { name: 'victory', value: [IDS_STATUS_PAY.iniciado] },
  { name: 'youConfirmed', value: [IDS_STATUS_PAY.aguardandoCedente] },
  {
    name: 'heConfirmed',
    value: [IDS_STATUS_PAY.finalizado, IDS_STATUS_PAY.finalizadoAuto],
  },
];

export const IDS_SUB_TIPO_EMPRESTIMO = {
  1: 'emprestimo',
  2: 'fomentoAProducao',
  3: 'cedulaCreditoBancario',
  4: 'contratoLongoPrazo',
  5: 'notaPromissoria',
};

export const LIST_SUB_TIPO_EMPRESTIMO = [
  // { id: 0, nome: 'undefinied', label: 'Undefinied', active: false },
  { id: 1, nome: 'emprestimo', label: 'Empréstimo', active: false },
  {
    id: 2,
    nome: 'fomentoAProducao',
    label: 'Fomento à Produção',
    active: false,
  },
  {
    id: 3,
    nome: 'cedulaCreditoBancario',
    label: 'Cedula de Crédito Bancário',
    active: false,
  },
  {
    id: 4,
    nome: 'contratoLongoPrazo',
    label: 'Contrato de Longo Prazo',
    active: false,
  },
  { id: 5, nome: 'notaPromissoria', label: 'Nota Promissória', active: false },
];

const IDS_TIPO_PREGAO = {
  DESCONTO: 1,
  BPI_NN_PROPRIO: 2,
  BPI_NN_FINANCIADOR: 3,
  EMPRESTIMO: 4,
  CHEQUE: 5,
  CONSIGNADO: 6,
  FINFOLHA: 7,
  COBRANCA_SIMPLES: 8,
};

export const LIST_TIPO_PREGAO = [
  { id: 0, nome: 'todos', label: 'Todos', active: false },
  {
    id: 1,
    nome: 'boletoPreImpresso',
    label: 'Boleto Pré-Impresso',
    active: false,
  },
  { id: 2, nome: 'emprestimo', label: 'Empréstimo', active: false },
  { id: 3, nome: 'cheque', label: 'Cheque', active: false },
  { id: 4, nome: 'antecipacao', label: 'Antecipação', active: false },
  { id: 8, nome: 'cobrança simples', label: 'Cobrança Simples', active: false },
];

export const TIPO_PREGAO = {
  antecipacao: [
    IDS_TIPO_PREGAO.DESCONTO,
    IDS_TIPO_PREGAO.BPI_NN_PROPRIO,
    IDS_TIPO_PREGAO.BPI_NN_FINANCIADOR,
  ],
  cobrancaSimples: [IDS_TIPO_PREGAO.COBRANCA_SIMPLES],
  emprestimo: [IDS_TIPO_PREGAO.EMPRESTIMO],
  cheque: [IDS_TIPO_PREGAO.CHEQUE],
  consignado: [IDS_TIPO_PREGAO.CONSIGNADO],
  finfolha: [IDS_TIPO_PREGAO.FINFOLHA],
};

export const IDS_INDICATORS = {
  0: 'inicial',
  1: 'semModificacao',
  2: 'aumentou',
  3: 'diminuiu',
  4: 'calculoInvalido',
};

export const IDS = {
  1: 'propostaRecebida',
  2: 'aguardandoResposta',
  3: 'propostaNegociada',
  4: 'aceiteFinal',
  5: 'propostaAprovada',

  6: 'aConfirmarPagamento',
  7: 'operacaoPaga',
  8: 'pagamentoConcluido',

  9: 'operacaoCancelada',
  10: 'operacaoPerdida',
  11: 'operacaoDesistida',

  12: 'novaProposta',
};

const PERMISSOES = {
  propostaRecebida: [
    INTERACTION.verComentario,
    INTERACTION.verBordero,
    INTERACTION.verAcertos,
    INTERACTION.aceitar,
    INTERACTION.negociar,
    INTERACTION.verParcelas,
  ],
  aguardandoResposta: [
    INTERACTION.verComentario,
    INTERACTION.verBordero,
    INTERACTION.verAcertos,
    INTERACTION.verParcelas,
  ],
  propostaNegociada: [
    INTERACTION.verComentario,
    INTERACTION.verBordero,
    INTERACTION.verAcertos,
    INTERACTION.verParcelas,
  ],
  aceiteFinal: [
    INTERACTION.verComentario,
    INTERACTION.verBordero,
    INTERACTION.verAcertos,
    INTERACTION.verParcelas,
  ],
  propostaAprovada: [
    INTERACTION.verComentario,
    INTERACTION.verBordero,
    INTERACTION.verAcertos,
    INTERACTION.verParcelas,
  ],

  aConfirmarPagamento: [
    INTERACTION.confirmarPagamento,
    INTERACTION.verComentario,
    INTERACTION.verBordero,
    INTERACTION.verAcertos,
    INTERACTION.verParcelas,
  ],
  operacaoPaga: [
    INTERACTION.verComentario,
    INTERACTION.verBordero,
    INTERACTION.verAcertos,
    INTERACTION.verParcelas,
  ],
  pagamentoConcluido: [
    INTERACTION.verComentario,
    INTERACTION.verBordero,
    INTERACTION.verAcertos,
    INTERACTION.verParcelas,
  ],

  operacaoCancelada: [
    INTERACTION.verComentario,
    INTERACTION.verBordero,
    INTERACTION.verAcertos,
    INTERACTION.verParcelas,
  ],
  operacaoPerdida: [
    INTERACTION.verComentario,
    INTERACTION.verBordero,
    INTERACTION.verAcertos,
    INTERACTION.verParcelas,
  ],
  operacaoDesistida: [
    INTERACTION.verComentario,
    INTERACTION.verBordero,
    INTERACTION.verAcertos,
    INTERACTION.verParcelas,
  ],

  novaProposta: [
    INTERACTION.addComentario,
    INTERACTION.editarBordero,
    INTERACTION.editarAcertos,
    INTERACTION.enviar,
    INTERACTION.recalcular,
    INTERACTION.editarParcelas,
  ],
};

export const STATUS = {
  propostaRecebida: {
    id: 1,
    label: 'Proposta Recebida',
    permissoes: PERMISSOES.propostaRecebida,
    icon: null,
    color: null,
  },
  aguardandoResposta: {
    id: 2,
    label: 'Aguardando resposta do empreendedor',
    permissoes: PERMISSOES.aguardandoResposta,
    icon: { value: IconeClockHist, tipo: 'stroke' },
    color: '#008894',
  },
  propostaNegociada: {
    id: 3,
    label: 'Proposta Negociada',
    permissoes: PERMISSOES.propostaNegociada,
    icon: { value: IconeCheckdProp, tipo: 'stroke' },
    color: '#70e5ef',
  },
  aceiteFinal: {
    id: 4,
    label: 'Proposta aceita, aguardando resposta do empreendedor',
    permissoes: PERMISSOES.aceiteFinal,
    icon: { value: IconeClockHist, tipo: 'stroke' },
    color: '#008894',
  },
  propostaAprovada: {
    id: 5,
    label: 'Proposta Vencedora',
    permissoes: PERMISSOES.propostaAprovada,
    icon: { value: IconeTrophy, tipo: 'fill' },
    color: '#FFCB04',
  },

  aConfirmarPagamento: {
    id: 6,
    label: 'A Confirmar Pagamento',
    permissoes: PERMISSOES.aConfirmarPagamento,
    icon: { value: IconeTrophy, tipo: 'fill' },
    color: '#FFCB04',
  },
  operacaoPaga: {
    id: 7,
    label: 'Operação Paga',
    permissoes: PERMISSOES.operacaoPaga,
    icon: { value: IcoCash, tipo: 'stroke' },
    color: '#008894',
  },
  pagamentoConcluido: {
    id: 8,
    label: 'Pagamento Concluído',
    permissoes: PERMISSOES.pagamentoConcluido,
    icon: { value: IcoHandDollar, tipo: 'stroke' },
    color: '#008894',
  },

  operacaoCancelada: {
    id: 9,
    label: 'Operação Cancelada',
    permissoes: PERMISSOES.operacaoCancelada,
    icon: { value: IconeTrash, tipo: 'stroke' },
    color: '#FF4D35',
  },
  operacaoPerdida: {
    id: 10,
    label: 'Operação Perdida',
    permissoes: PERMISSOES.operacaoPerdida,
    icon: { value: IconeHandDown, tipo: 'stroke' },
    color: '#FF4D35',
  },
  operacaoDesistida: {
    id: 11,
    label: 'Operação Desistida',
    permissoes: PERMISSOES.operacaoDesistida,
    icon: { value: IconeSad, tipo: 'stroke' },
    color: '#FF4D35',
  },

  novaProposta: {
    id: 12,
    label: 'Nova Proposta',
    permissoes: PERMISSOES.novaProposta,
    icon: null,
    color: null,
  },

  operacaoConcluida: {
    id: 13,
    label: 'Operação Concluída',
    permissoes: PERMISSOES.pagamentoConcluido,
    icon: { value: IcoHandDollar, tipo: 'stroke' },
    color: '#008894',
  },
};

import React from 'react';
import styled from 'styled-components/macro';
import ScaleLoader from 'react-spinners/ScaleLoader';

import { StringToSvg } from 'helpers/svgFiles/StringToSvg';

import feather from 'feather-icons/dist/icons.json';

import { Wrapper } from 'components/FinWrappers';

import { finTheme } from 'finTheme';

const Text = styled.span`
  color: ${({ theme, color }) => color || theme.newtheme.color.cinza6};
  font-weight: 600;
  text-align: center;
  margin-left: 1rem;
  font-size: 0.9rem;
`;

const FeedbackInfo = ({ tipo, text, colorFont, colorIco }) => {
  const icon = (tipo) => {
    switch (tipo) {
      case 'info':
        return feather['alert-circle'];
      case 'success':
        return feather.check;
      case 'warn':
        return feather['alert-triangle'];
      case 'error':
        return feather['x-circle'];
      case 'locked':
        return feather.lock;
      case 'empty':
        return feather.file;
      default:
        return feather.file;
    }
  };
  return (
    <Wrapper>
      {tipo === 'loading' ? (
        <div>
          <ScaleLoader
            color={finTheme.newtheme.color.azulPrimario}
            loading={true}
          />
        </div>
      ) : (
        <StringToSvg
          viewBox="0 0 24 24"
          width="30px"
          height="27px"
          stroke={colorIco || finTheme.newtheme.color.azulPrimario}
        >
          {icon(tipo)}
        </StringToSvg>
      )}
      <Wrapper padding="0">
        <Text color={colorFont}>{text || `Algo de errado aconteceu`}</Text>
      </Wrapper>
    </Wrapper>
  );
};

export default FeedbackInfo;

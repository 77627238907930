import React from 'react';
import { NWN404 } from 'components/NWPlaceholder';
import { Flex } from './style.layout';

export function Page404() {
  return (
    <Flex
      column
      align="flex-start"
      justify="flex-start"
      style={{ height: '445px' }}
    >
      <NWN404 />
    </Flex>
  );
}

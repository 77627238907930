import styled, { css } from 'styled-components/macro';

import { CheckboxFlat } from 'components/FinCheckboxCuston/CheckboxFlat';
import Button from 'components/FinButton/Button';
import { darken } from 'polished';

const Truncate = css`
  width: 15ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 300px;

  &::-webkit-scrollbar-track {
    margin-top: 3.75rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #d8d8d8;
    border-radius: 10px;
`;

export const CheckDirectory = styled.div`
  display: flex;
`;
export const Checkbox = styled(CheckboxFlat)``;

export const ContentDescription = styled.div``;

export const Label = styled.span`
  font-family: 'Inter';
  font-weight: 400;
  font-size: 0.875rem;
  ${Truncate}
`;

export const LabelUpDate = styled.span``;

export const ContainerButton = styled.div`
  background: #ffffff;
  padding: 1rem 0 0 0;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: flex-end;
`;

export const ButtonDownload = styled(Button)`
  font-family: 'Inter';
  font-weight: 500;
  font-size: 0.875rem;
  height: 40px;
  border-radius: 10px;
  background-color: var(--finblue-primary);
  color: #ffffff;

  & svg path {
    fill: #ffffff;
  }

  &:enabled:hover {
    background-color: ${darken(0.1, '#008894')};
  }

  &:disabled {
    color: #6c6c6c;
    background-color: #d4d4d4;
    & svg path {
      fill: #6c6c6c;
    }
  }
`;

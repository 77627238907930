import React from 'react';
import PropTypes from 'prop-types';

import { capitalizeFirstLetter } from 'helpers/string';
import { useInitialLetters } from 'hooks/useInitialLetters';
import * as Avatar from './style.avatar';

export const FinAvatar = ({
  online,
  image,
  name,
  style,
  onClick,
  bgColor,
  className,
  fontSize,
  withShadow,
}) => {
  const { first, last } = useInitialLetters(name);
  return (
    <Avatar.Content
      className={className}
      style={style}
      online={online}
      onClick={onClick}
      withShadow={withShadow}
    >
      <Avatar.Wrapper>
        {!image && <Avatar.Background bgColor={bgColor} />}
        {image && <Avatar.Image src={image} />}
        {!image && (
          <Avatar.Name fontSize={fontSize}>
            {capitalizeFirstLetter(`${first}${last}`)}
          </Avatar.Name>
        )}
      </Avatar.Wrapper>
    </Avatar.Content>
  );
};

FinAvatar.propTypes = {
  online: PropTypes.bool.isRequired,
  image: PropTypes.string,
  name: PropTypes.string,
  bgColor: PropTypes.string,
};

import React from 'react';
import PropTypes from 'prop-types';

import { finTheme } from 'finTheme';

import {
  Field,
  InputFeedback,
  Option,
  SelectComp,
  StatusBar,
  Label,
} from './Styles';

export const Select = ({
  name,
  label,
  largura,
  data,
  handleChange,
  handleBlur,
  error,
  disabled,
  marginRight,
  marginLeft,
  valueActive,
  minHeight,
}) => {
  const renderOptions = () => {
    return data.map((res) => {
      return <Option value={res.id} key={res.id} label={res.name} />;
    });
  };
  return (
    <Field
      minHeight={minHeight}
      error={error}
      largura={largura}
      disabled={disabled}
      marginRight={marginRight}
      marginLeft={marginLeft}
    >
      <Label
        color={finTheme.newtheme.color.cinza6}
        fontweight="600"
        marginbottom="0.5rem"
      >
        {label}
      </Label>
      <SelectComp
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
        value={valueActive}
      >
        {renderOptions()}
      </SelectComp>
      <StatusBar />
      {error && <InputFeedback>{error}</InputFeedback>}
    </Field>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  largura: PropTypes.string,
  data: PropTypes.array.isRequired,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  marginRight: PropTypes.string,
  marginLeft: PropTypes.string,
  valueActive: PropTypes.any,
  minHeight: PropTypes.string,
};

Select.defaultProps = {
  name: null,
  label: '',
  largura: '50%',
  data: null,
  handleChange: null,
  handleBlur: null,
  error: null,
  disabled: false,
  marginRight: null,
  marginLeft: null,
  valueActive: undefined,
  minHeight: '98px',
};

export default Select;

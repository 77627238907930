const INITIAL_STATE = {
  data: [],
  isLoading: null,
  error: null,
  postData: [],
  postIsLoading: null,
  postError: null,
  campanhaData: [],
  isLoadingCampanha: null,
  errorCampanha: null,
  statusAtivacao: {},
};

const perfil = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LOAD_PERFIL_REQUEST':
      console.log(action);
      return {
        ...state,
        isLoading: true,
      };
    case 'LOAD_PERFIL_SUCCESS':
      console.log(action);
      return {
        ...state,
        data: action.data,
        error: false,
        isLoading: false,
      };
    case 'LOAD_PERFIL_FAILURE':
      return {
        ...state,
        data: action.data,
        isLoading: false,
        error: true,
      };
    case 'CLEAR_PERFIL':
      console.log(action);
      return {
        ...state,
        data: [],
        isLoading: null,
        error: null,
      };
    case 'POST_PERFIL_REQUEST':
      console.log(action);
      return {
        ...state,
        postIsLoading: true,
      };
    case 'POST_PERFIL_SUCCESS':
      console.log(action);
      return {
        ...state,
        postData: action.data,
        postError: false,
        postIsLoading: false,
      };
    case 'POST_PERFIL_FAILURE':
      return {
        ...state,
        postData: action.data,
        postIsLoading: false,
        postError: true,
      };
    case 'LOAD_CAMPANHA_REQUEST':
      console.log(action);
      return {
        ...state,
        isLoadingCampanha: true,
      };
    case 'LOAD_CAMPANHA_SUCCESS':
      console.log(action);
      return {
        ...state,
        campanhaData: action.data,
        errorCampanha: false,
        isLoadingCampanha: false,
      };
    case 'LOAD_CAMPANHA_FAILURE':
      return {
        ...state,
        campanhaData: action.data,
        isLoadingCampanha: false,
        errorCampanha: true,
      };
    case 'CLEAR_CAMPANHA':
      console.log(action);
      return {
        ...state,
        campanhaData: [],
        isLoadingCampanha: null,
        errorCampanha: null,
        statusAtivacao: {},
      };
    case 'POST_CAMPANHA_REQUEST':
      console.log(action);
      return {
        ...state,
        isLoadingCampanha: true,
      };
    case 'POST_CAMPANHA_SUCCESS':
      console.log(action);
      return {
        ...state,
        statusAtivacao: action.data,
        errorCampanha: false,
        isLoadingCampanha: false,
      };
    case 'POST_CAMPANHA_FAILURE':
      return {
        ...state,
        statusAtivacao: action.data,
        isLoadingCampanha: false,
        errorCampanha: true,
      };
    default:
      return state;
  }
};

export default perfil;

import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as apiMotivosActions } from 'store/ducks/apiMotivosConvites';
import { Wrapper } from 'components/FinWrappers';
import { Paragraph2 } from 'components/FinParagraph';
import { Select } from 'components/FinSelect';
import { InputTextAreaMaterial } from 'components/FinInput/InputMaterial';
import { LoadingInfo } from 'components/FinLoading';
import FeedbackInfo from 'components/FinFeedbackInfo';
import { useFeedback } from 'hooks/useFeedback';

import { DialogCuston } from './DialogCuston';

const DialogWithSelect = () => {
  const [erroText, setErroText] = useState(false);

  const [observacao, setObservacao] = useState('');
  const [motivo, setMotivo] = useState(0);

  const uiDialogState = useSelector((state) => state.uiDialog);
  const apiMotivosConvitesState = useSelector(
    (state) => state.apiMotivosConvites
  );

  const { ready, waiting, error, empty } = useFeedback(apiMotivosConvitesState);

  const dispatch = useDispatch();

  const clear = () => {
    setMotivo(0);
    setObservacao('');
  };

  const getMotivos = useCallback(
    (idTipoEmpresa, tipo) => {
      if (tipo === 'recusarConvite') {
        dispatch(apiMotivosActions.apiMotivoConvitesRequest(idTipoEmpresa));
      }
    },
    [dispatch]
  );

  const cancelMotivos = useCallback(() => {
    dispatch(apiMotivosActions.apiMotivoConvitesCancel());
  }, [dispatch]);

  const onChangeSelect = useCallback(
    (e) => {
      setMotivo(Number(e.target.value));
    },
    [setMotivo]
  );

  const disableButton = useCallback(() => {
    if (motivo === 9 && observacao === '' && uiDialogState.listaMotivo)
      return true;
    if (motivo === 0 && uiDialogState.listaMotivo) return true;
    return false;
  }, [motivo, observacao, uiDialogState.listaMotivo]);

  useEffect(() => {
    if (uiDialogState.listaMotivo) {
      getMotivos(1, uiDialogState.listaMotivo);
    }

    return () => cancelMotivos();
  }, [cancelMotivos, getMotivos, uiDialogState]);

  useEffect(() => {
    if (motivo === 9 && observacao === '') {
      setErroText(true);
    } else {
      setErroText(false);
    }
  }, [motivo, observacao]);

  return (
    <DialogCuston
      disablePrimaryButton={disableButton()}
      whenClosing={clear}
      whenConfirming={clear}
      aditionalPayloadToAction={{ idTipoRecusa: motivo, obs: observacao }}
    >
      <Wrapper
        padding="32px 0"
        justifyContent="flex-start"
        alignItems="flex-start"
        tipo="column"
      >
        <Paragraph2>{uiDialogState.text}</Paragraph2>
        {uiDialogState.listaMotivo.length > 0 && (
          <>
            {waiting && <LoadingInfo loading={waiting} />}
            {error && <FeedbackInfo tipo="error" />}
            {empty && (
              <FeedbackInfo tipo="empty" text="Conteúdo não disponível" />
            )}
            {ready && (
              <Wrapper padding="16px 0" pwidth="100%">
                <Select
                  name="lista-motivo"
                  label="Ah! Por favor, nos informe o motivo da sua recusa:"
                  minHeight="56px"
                  largura="100%"
                  handleChange={(e) => onChangeSelect(e)}
                  data={apiMotivosConvitesState.data}
                  valueActive={motivo}
                />
              </Wrapper>
            )}
            <InputTextAreaMaterial
              id="observacao"
              label="Observação"
              handleChange={(e) => setObservacao(e.target.value)}
              largura="100%"
              value={observacao}
              error={erroText}
              errormessage="Preencha o campo"
            />
          </>
        )}
      </Wrapper>
    </DialogCuston>
  );
};

export default DialogWithSelect;

import React, { useContext } from 'react';

import placeholder1 from 'assets/images/placeholder/placeholder@1x.png';

import { ImageRenderer } from 'components/FinImageRenderer';
import { FinAvatar } from 'components/FinAvatar';

import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { FinErrorBoundary } from 'components/FinErrorBoundary';
import { maskCNPJ } from 'helpers/format';

import { useGetImage } from 'hooks/useGetImage';
import { NWBreadcrumb, NWBreadcrumbItem } from 'components/NWBreadcrumb';
import { capitalizeWords } from 'helpers/string';

import { StepsWizard } from 'components/FinWizard';
import { pagesLabel } from 'pages/MinhaRede/config';
import { TabsFincredit } from './Comps/Tab';
import { PerfilContext } from './PaginaFinCredit';

import {
  Container,
  Wrapper,
  RegionFullImageBanner,
  WrapperImageBanner,
  WrapperShadow,
  RegionInfoUser,
  CompanyName,
  Cnpj,
  Site,
  Separator,
  Flex,
  AreaContent,
  RegionContent,
  RegionCard,
  WrapperContent,
  IconLockStyle,
} from './style.layout';

import { Tag } from './QuadroSocietario/styles.user';

import { MainCardAction } from './MainCardAction';
import { PageFinmatch } from './PageFinmatch';
import { PageFinscanner } from './PageFinscanner';
import { PagePerfil } from './PagePerfil';
import { PageEndividamento } from './PageEndividamento';
import { PageSocios } from './PageSocios';
import { Page404 } from './Page404';

import { SkeletonHeading } from './Comps/Skeleton';

export function PaginaPrincipal(props) {
  const [prevLocation, setPrevLocation] = React.useState(null);
  const { perfil } = useContext(PerfilContext);

  const location = useLocation();

  React.useEffect(() => {
    if (location.state) {
      setPrevLocation(location.state);
    }
  }, [location, prevLocation]);

  const withImage =
    perfil.data && perfil.data.midia && perfil.data.midia.fachada
      ? perfil.data.midia.fachada.caminhoArquivo
      : null;

  const imageFallback = perfil.data && !perfil.data.midia ? placeholder1 : null;

  const miniature = useGetImage({
    url: withImage,
    largura: 100,
    altura: 0,
    qualidade: 10,
    fallback: placeholder1,
  });

  const fullImage = useGetImage({
    url: withImage,
    qualidade: 20,
    largura: 1368,
    altura: 320,
    fallback: imageFallback,
  });

  const {
    match: { params },
  } = props;

  const capitalizedName = capitalizeWords(perfil.data && perfil.data.nome);

  return (
    <StepsWizard>
      <Container>
        <RegionFullImageBanner>
          <WrapperShadow>
            <AreaContent>
              <RegionInfoUser>
                {perfil.isLoading && <SkeletonHeading />}
                <Flex column align="flex-start" style={{ marginRight: '30px' }}>
                  {perfil.data && perfil.data.nome && (
                    <FinAvatar
                      online={perfil.data.online}
                      name={perfil.data.nome}
                      withShadow
                    />
                  )}
                  {perfil.data && (
                    <Tag style={{ marginTop: '5px' }}>
                      {perfil.data.online ? `ONLINE` : `OFFLINE`}
                    </Tag>
                  )}
                </Flex>
                <Flex column align="flex-start" justify="flex-start">
                  <CompanyName style={{ width: '25ch' }}>
                    {perfil.data && capitalizedName}
                  </CompanyName>
                  <Flex justify="flex-start">
                    {perfil.data && perfil.data.criptografia ? (
                      <IconLockStyle color="#ffffff" />
                    ) : (
                      <Cnpj>{perfil.data && maskCNPJ(perfil.data.cnpj)}</Cnpj>
                    )}
                    {perfil.data && perfil.data.segmentoMatriz && <Separator />}
                    <Site>
                      {perfil.data &&
                        perfil.data.segmentoMatriz &&
                        capitalizeWords(perfil.data.segmentoMatriz)}
                    </Site>
                  </Flex>
                </Flex>
              </RegionInfoUser>
            </AreaContent>
          </WrapperShadow>
          <WrapperImageBanner>
            <ImageRenderer url={fullImage} thumb={miniature} />
          </WrapperImageBanner>
        </RegionFullImageBanner>
        <Wrapper>
          <WrapperContent>
            <RegionContent>
              <NWBreadcrumb>
                <NWBreadcrumbItem to="/minha-rede">Minha Rede</NWBreadcrumbItem>
                {pagesLabel(prevLocation) && (
                  <NWBreadcrumbItem to={prevLocation}>
                    {pagesLabel(prevLocation)}
                  </NWBreadcrumbItem>
                )}
                <NWBreadcrumbItem noIcon>Fincredit</NWBreadcrumbItem>
              </NWBreadcrumb>
              <TabsFincredit {...props} />
              <FinErrorBoundary>
                <Switch>
                  <Route exact path="/fincredit/:idGrupo">
                    <Redirect to={`/fincredit/${params.idGrupo}/perfil`} />
                  </Route>
                  <Route
                    path="/fincredit/:idGrupo/perfil"
                    render={(props) => <PagePerfil {...props} />}
                  />
                  <Route
                    path="/fincredit/:idGrupo/endividamento"
                    render={(props) => <PageEndividamento {...props} />}
                  />
                  <Route
                    path="/fincredit/:idGrupo/socios"
                    render={(props) => <PageSocios {...props} />}
                  />
                  <Route
                    path="/fincredit/:idGrupo/finscanner"
                    render={(props) => <PageFinscanner {...props} />}
                  />
                  <Route
                    path="/fincredit/:idGrupo/finmatch"
                    render={(props) => <PageFinmatch {...props} />}
                  />
                  <Route path="" render={(props) => <Page404 {...props} />} />
                </Switch>
              </FinErrorBoundary>
            </RegionContent>
            <RegionCard>
              <FinErrorBoundary>
                <MainCardAction idGrupo={params.idGrupo} />
              </FinErrorBoundary>
            </RegionCard>
          </WrapperContent>
        </Wrapper>
      </Container>
    </StepsWizard>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { FramePic } from 'components/FinFrame';
import userDefault from 'assets/images/user-pic.svg';

const Pic = styled.img`
  width: 100%;
  height: auto;
`;

const PicUser = ({ image, size }) => {
  let tamanho = '40px';

  if (size === 'medium') {
    tamanho = '50px';
  } else if (size === 'large') tamanho = '60px';
  return (
    <FramePic bgColor="transparent" pwidth={tamanho} pheight={tamanho}>
      <Pic src={image} />
    </FramePic>
  );
};

PicUser.propTypes = {
  image: PropTypes.string,
};

PicUser.defaultProps = {
  image: userDefault,
};

export default PicUser;

import styled from 'styled-components/macro';
import { rgba } from 'polished';

import { device } from 'devices';

export const Separador = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(216, 216, 216, 0.5);
  margin: 1rem 0;

  @media ${device.laptop} {
    display: none;
  }
`;

export const SeparadorVertical = styled.div`
  width: 1px;
  height: 100%;
  background-color: rgba(216, 216, 216, 0.5);
  margin: ${({ psize }) => psize || '0 0.5rem'};
`;

export const SeparatorVertical = styled.div`
  width: ${({ pwidth }) => pwidth || '2rem'};
  display: flex;
  justify-content: center;
  height: ${({ pheight }) => pheight || '100%'};
  &:before {
    background-color: ${({ pborderColor }) =>
      pborderColor ? rgba(pborderColor, 0.4) : 'transparent'};
    content: '';
    display: inline-block;
    width: ${({ pstroke }) => pstroke || '1px'};
  }
`;

export const SeparatorHorizontal = styled.div`
  padding: ${({ ppadding }) => (ppadding ? `${ppadding} 0` : '1rem 0')};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ pwidth }) => pwidth || '100%'};
  &:before {
    background-color: ${({ pborderColor }) =>
      pborderColor ? rgba(pborderColor, 0.4) : 'transparent'};
    content: '';
    display: inline-block;
    height: ${({ pstroke }) => pstroke || '1px'};
    width: 100%;
  }
`;

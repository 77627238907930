import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import qs from 'query-string';

const arraySettings = {
  arrayFormat: 'bracket-separator',
  arrayFormatSeparator: '|',
  parseBooleans: true,
};

const convertToNumber = (params) => {
  if (typeof params === 'object') {
    Object.keys(params).forEach((item) => {
      if (String(Number(params[item])) === params[item]) {
        params[item] = Number(params[item]);
      }
    });
  } else if (String(Number(params)) === params) {
    params = Number(params);
  }

  return params;
};

export const useQueryState = () => {
  const location = useLocation();
  const history = useHistory();

  const setQueries = useCallback(
    (params) => {
      const queryString = qs.stringify({ ...params }, arraySettings);

      history.push(`${location.pathname}?${queryString}`);
    },
    [history, location]
  );

  const setQueriesAdd = useCallback(
    (params) => {
      const existingQueries = qs.parse(location.search, arraySettings);

      const queryString = qs.stringify(
        { ...existingQueries, ...params },
        arraySettings
      );

      history.push(`${location.pathname}?${queryString}`);
    },
    [history, location]
  );

  const removeQueries = useCallback(
    (key) => {
      const existingQueries = qs.parse(location.search, arraySettings);

      if (key) {
        delete existingQueries[key];

        const queryString = qs.stringify({ ...existingQueries }, arraySettings);

        history.push(`${location.pathname}?${queryString}`);
      } else {
        history.push(`${location.pathname}`);
      }
    },
    [history, location]
  );

  const getQueries = useCallback(
    (key) => {
      return key
        ? convertToNumber(qs.parse(location.search, arraySettings)[key])
        : convertToNumber(qs.parse(location.search, arraySettings));
    },
    [location]
  );

  return { setQueries, getQueries, setQueriesAdd, removeQueries };
};

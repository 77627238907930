/* eslint-disable no-console */
import IMask from 'imask';

const phoneRegExp = /(?:\()[0-9]{2}(?:\))\s?[0-9]{4,5}(?:-)[0-9]{4}$/;

export const maskPhone = (value, type = 'masked') => {
  if (value) {
    console.log(value);
    if (value.toString().search(phoneRegExp) !== -1) {
      console.log('Formated');
    }

    let text = value.replace(')', '');
    text = text.replace('(', '');
    text = text.replace('-', '');
    text = text.replace(/(\s+)/, '');

    console.log(text);
    const v = value.toString().length;
    let masked = [];
    console.log(v);
    if (v <= 15) {
      if (v >= 1 && v <= 14) {
        console.log('residencia');
        masked = IMask.createMask({
          mask: '(00) 0000-0000',
        });
      } else {
        console.log('celular');
        masked = IMask.createMask({
          mask: '(00) 00000-0000',
        });
      }
      masked.resolve(value.toString());
      if (type === 'masked') {
        console.log(masked.value);
        return masked.value;
      }
      return masked.unmaskedValue;
    }
    return value;
  }
  return value;
};

export const maskCNPJ = (value, type = 'masked') => {
  if (value) {
    const masked = IMask.createMask({
      mask: '00.000.000/0000-00',
    });
    masked.resolve(value.toString());
    if (type === 'masked') {
      return masked.value;
    }
    return masked.unmaskedValue;
  }
  return '';
};
export const dotToCommaDecimals = (value, decimal, withPercent) => {
  const val = value.toFixed(decimal);

  if (withPercent) {
    const percent = val.toString().replace('.', ',');

    return `${percent} %`;
  }

  return val.toString().replace('.', ',');
};

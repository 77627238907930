import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components/macro';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { push } from 'connected-react-router';

import { Creators as apiFilterActions } from 'store/ducks/apiFiltro';
import { Creators as searchRouterActions } from 'store/ducks/searchRouter';
import { Creators as apiPesquisaActions } from 'store/ducks/apiPesquisa';

import { icons } from 'assets/icones/finplaceIcons';
import HeaderTitle from 'components/FinHeaderTitle';

import { Wrapper } from 'components/FinWrappers';

import { SeparatorHorizontal } from 'components/FinSeparador';
import Input from 'components/FinInput';
import { StringToSvg } from 'helpers/svgFiles/StringToSvg';
import feather from 'feather-icons/dist/icons.json';
import { finTheme } from 'finTheme';
import useDebounce from 'hooks/useDebounce';
import { Content, Header, WrapperLayout, BtnWrapper } from './Styles';

import FilterMenu from './FilterMenu';
import BarActions from './BarActions';

import ListaEmpreendedor from './ListaEmpreendedor';
import NavTabs from './NavTabs';
import ResultListSearch from './ResultListSearch';

const IconSearch = () => (
  <Wrapper padding="0" pwidth="20px" pheight="20px">
    <StringToSvg
      viewBox="0 0 24 24"
      width="100%"
      height="100%"
      stroke={finTheme.newtheme.color.azulPrimario}
    >
      {feather.search}
    </StringToSvg>
  </Wrapper>
);

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const WrapperContent = styled(Wrapper)`
  padding: 2rem;
  flex-direction: column;
  width: 100%;
  margin: 0 0 6rem 0;
`;

const PaginaEmpreendedores = ({
  apiFilterAction,
  apiPesquisaAction,
  match,
  query,
}) => {
  const [openMenu, setOpenMenu] = useState(true);
  const [valueSearch, setValueSearch] = useState(
    query.busca ? decodeURIComponent(query.busca) : ''
  );

  const [openSearch, setOpenSearch] = useState(false);
  const [pageSearch, setPageSearch] = useState(1);

  const debouncedSearchTerm = useDebounce(valueSearch, 500);

  const closeSearch = useCallback(() => {
    setOpenSearch(false);
    setValueSearch('');
  }, []);

  const loadMoreItens = useCallback(() => {
    apiPesquisaAction.apiPesquisaRequest(valueSearch, pageSearch + 1);
    setPageSearch(pageSearch + 1);
  }, [apiPesquisaAction, pageSearch, valueSearch]);

  useEffect(() => {
    if (debouncedSearchTerm !== '') {
      setPageSearch(1);
      apiPesquisaAction.apiPesquisaRequest(debouncedSearchTerm);
    }
  }, [apiPesquisaAction, debouncedSearchTerm]);

  useEffect(() => {
    apiFilterAction.apiFiltroRequest();
    return () => {
      apiFilterAction.apiFiltroCancel();
    };
  }, [apiFilterAction]);
  return (
    <Container>
      <Header>
        <HeaderTitle icon={icons.bCheck}>Clientes</HeaderTitle>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Wrapper pwidth="100%" padding="0" pposition="relative">
          <Input
            placeholder="Pesquisar empreendedores"
            onChange={(e) => setValueSearch(e.target.value)}
            onFocus={() => setOpenSearch(true)}
            icone={<IconSearch />}
            value={valueSearch}
          />
          {valueSearch !== '' && (
            <BtnWrapper onClick={() => setValueSearch('')}>
              <StringToSvg
                viewBox="0 0 18 18"
                width="20"
                height="20"
                stroke="none"
                fill={finTheme.newtheme.color.azulPrimario}
              >
                {icons.closeFlat}
              </StringToSvg>
            </BtnWrapper>
          )}
          <ResultListSearch
            open={openSearch}
            close={closeSearch}
            moreItens={loadMoreItens}
            page={pageSearch}
          />
        </Wrapper>
      </Header>
      <WrapperLayout>
        <FilterMenu open={openMenu} />
        <Content>
          <BarActions openMenu={() => setOpenMenu(!openMenu)} />
          <NavTabs />
          <SeparatorHorizontal />
          <WrapperContent>
            <ListaEmpreendedor match={match} />
          </WrapperContent>
        </Content>
      </WrapperLayout>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  apiFiltroState: state.apiFiltro,
  location: state.router.location,
  query: state.router.location.query,
});

const mapDispatchToProps = (dispatch) => {
  return {
    apiFilterAction: bindActionCreators(apiFilterActions, dispatch),
    searchRouterAction: bindActionCreators(searchRouterActions, dispatch),
    apiPesquisaAction: bindActionCreators(apiPesquisaActions, dispatch),
    push: (path) => dispatch(push(path)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaginaEmpreendedores);

import React from 'react';

import messages from 'helpers/messages/index.json';
import { Flex, Title, Text } from './style.dialog';
import { NWButton } from '../Button';

import { Checkbox } from '../../ModalFiltros/style.form';

export function ContentHabilitar({ enabled, onClose }) {
  const [contract, setContract] = React.useState({
    rule1: false,
    rule2: false,
    rule3: false,
  });

  const allSelected = () => {
    return !contract.rule1 || !contract.rule2 || !contract.rule3;
  };

  return (
    <Flex direction="column" style={{ padding: '2rem' }}>
      <Title>{messages.dialog.habilitar.titulo}</Title>
      <Text>{messages.dialog.habilitar.texto}</Text>
      <Flex
        direction="column"
        style={{
          margin: '1.8rem 0 1rem',
          padding: '1rem',
          background: 'var(--fingray-100)',
          borderRadius: '6px',
          width: '100%',
        }}
      >
        <Checkbox
          // ftW="700"
          name="contract-1"
          label="O Contrato Mãe foi assinado entre as partes;"
          onChange={() =>
            setContract((state) => ({ ...state, rule1: !state.rule1 }))
          }
          checked={contract.rule1}
        />
        <Checkbox
          // ftW="700"
          name="contract-2"
          label="O Limite de Crédito foi aprovado, e;"
          onChange={() =>
            setContract((state) => ({ ...state, rule2: !state.rule2 }))
          }
          checked={contract.rule2}
        />
        <Checkbox
          // ftW="700"
          name="contract-3"
          label="O mesmo está APTO à operar."
          onChange={() =>
            setContract((state) => ({ ...state, rule3: !state.rule3 }))
          }
          checked={contract.rule3}
        />
      </Flex>
      <Flex
        justify="flex-end"
        style={{ width: '100%', gap: '10px', marginTop: '2rem' }}
      >
        <NWButton.Secondary
          onClick={() => {
            onClose();
          }}
        >
          {messages.dialog.habilitar.labelCancelar}
        </NWButton.Secondary>
        <NWButton.Primary
          largura="padrao"
          disabled={allSelected()}
          onClick={() => {
            enabled();
            onClose();
          }}
        >
          {messages.dialog.habilitar.labelConfirme}
        </NWButton.Primary>
      </Flex>
    </Flex>
  );
}

import { createStitches, keyframes } from '@stitches/react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { darken } from 'polished';

import { ReactComponent as Arrow } from '../../assets/icones/icone-seta-pra-baixo.svg';

const slideDown = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-10px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const { styled } = createStitches({
  utils: {
    icon: (value) => ({
      background: `url(${value}) no-repeat center left`,
    }),
  },
});

export const Popover = PopoverPrimitive.Root;

export const PopoverTriggerButtonFloat = styled(PopoverPrimitive.Trigger, {
  fontFamily: 'Inter',
  borderRadius: '10px',
  height: '40px',
  fontSize: '0.9rem',
  fontWeight: 'normal',
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  '&:hover': { backgroundColor: darken(0.1, '#fff') },
  padding: '0 6px',
  position: 'absolute',
  right: '4px',
  top: '4px',
  width: '40px',
  zIndex: '99',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const PopoverTrigger = styled(PopoverPrimitive.Trigger, {
  fontFamily: 'Inter',
  borderRadius: '12px',
  minWidth: '129px',
  height: '20px',
  fontSize: '12px',
  fontWeight: '600',
  backgroundColor: '#fff',
  color: '#646464',
  border: '1px solid #C2C2C2',
  cursor: 'pointer',
  '&:hover': { backgroundColor: darken(0.1, '#fff') },
  '& svg': {
    '& path': {
      fill: '#646464',
    },
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // textTransform: 'uppercase',
  position: 'relative',
  variants: {
    priority: {
      max: {
        backgroundColor: '#AAEBBA',
        color: '#3B5C44',
        border: 'none',
        '&:hover': { backgroundColor: darken(0.1, '#AAEBBA') },
        '& svg': {
          '& path': {
            fill: '#3B5C44',
          },
        },
      },
      higher: {
        backgroundColor: '#7FE8C1',
        color: '#2F6C55',
        border: 'none',
        '&:hover': { backgroundColor: darken(0.1, '#7FE8C1') },
        '& svg': {
          '& path': {
            fill: '#2F6C55',
          },
        },
      },
      high: {
        backgroundColor: '#F7EAA9',
        color: '#BC841B',
        border: 'none',
        '&:hover': { backgroundColor: darken(0.1, '#F7EAA9') },
        '& svg': {
          '& path': {
            fill: '#BC841B',
          },
        },
      },
      standard: {
        backgroundColor: '#EDC985',
        color: '#C38002',
        border: 'none',
        '&:hover': { backgroundColor: darken(0.1, '#EDC985') },
        '& svg': {
          '& path': {
            fill: '#C38002',
          },
        },
      },
      disabled: {
        backgroundColor: '#e6e6e6',
        pointerEvents: 'none',
        cursor: 'not-allowed',
      },
    },
  },
});

export const PopoverAnchor = styled(PopoverPrimitive.Anchor, {});

export const PopoverContent = styled(PopoverPrimitive.Content, {
  flexDirection: 'column',
  animationDuration: '0.6s',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  '&[data-side="bottom"]': { animationName: slideDown },
  borderRadius: '17px',
  backgroundColor: '#fff',
  border: '1px solid #ECECEC',
  boxShadow: '0px 3px 4px 3px rgba(0, 0, 0, 0.05)',
  padding: '10px 12px',
  zIndex: '999',
});

export const Option = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  textAlign: 'center',
  width: '140px',
  heigth: '20px',
  backgroundColor: '#fff',
  borderRadius: '5px',
  margin: '4px 0',
  padding: '5px 0',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#EBFDFF',
  },
  '&:active': {
    backgroundColor: '#F2F2F3',
  },
  variants: {
    priority: {
      max: {
        '& span:first-child': {
          backgroundColor: '#AAEBBA',
        },
        backgroundColor: '#F2F2F3',
      },
      higher: {
        '& span:first-child': {
          backgroundColor: '#7FE8C1',
        },
        backgroundColor: '#F2F2F3',
      },
      high: {
        '& span:first-child': {
          backgroundColor: '#F7EAA9',
        },
        backgroundColor: '#F2F2F3',
      },
      standard: {
        '& span:first-child': {
          backgroundColor: '#EDC985',
        },
        backgroundColor: '#F2F2F3',
      },
      disabled: {
        '& span:first-child': {
          backgroundColor: '#979696',
          color: '#ffffff',
        },
        backgroundColor: 'transparent',
        pointerEvents: 'none',
        cursor: 'not-allowed',
      },
    },
  },
});

export const PriorityId = styled('span', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  verticalAlign: 'middle',
  width: '20px',
  height: '20px',
  borderRadius: '20px',
  backgroundColor: '#E3E8E4',
  color: '#646464',
  fontFamily: 'Inter, sans-serif',
  fontSize: '10px',
  fontWeight: '500',
  lineHeight: '1',
  marginLeft: '4px',
});

export const PriorityName = styled('span', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#646464',
  fontFamily: 'Inter, sans-serif',
  fontSize: '12px',
  lineHeight: '1.5',
  marginLeft: '16px',
});

export const ArrowDown = styled(Arrow, {
  position: 'absolute',
  right: '8px',
  bottom: '7px',
});

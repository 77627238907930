import axios from 'axios';
import { useQuery } from 'react-query';
import CONFIG from 'config';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/`;

const fetchEndividamento = async (params) => {
  const fetchData = axios.get(
    `${BASE_URL}api/graficos/consulta-scr/${params.idGrupo}`
  );

  const result = await fetchData;

  const { data } = result;

  return data.data ? data : [];
};

export const useChartEndividamento = (params) => {
  return useQuery(
    ['chart', 'endividamento', params],
    () => fetchEndividamento(params),
    {
      keepPreviousData: false,
      useErrorBoundary: true,
    }
  );
};

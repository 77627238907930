import axios from 'axios';
import { put, select, call } from 'redux-saga/effects';

import { getUserData } from 'services/getUserData';
import {
  loadDataPropostasSuccess,
  loadDataPropostasFailure,
  setDefaultDataPropostas,
  calculaPropostasSuccess,
  calculaPropostasFailure,
  incluirContraPropostasSuccess,
  incluirContraPropostasFailure,
  aceitarPropostaSuccess,
  aceitarPropostaFailure,
  aprovadaPropostaSuccess,
  aprovadaPropostaFailure,
  InfoPagPropostaSuccess,
  InfoPagPropostaFailure,
  PagamentoPropostaSuccess,
  PagamentoPropostaFailure,
  resumoClearDataHistory,
  showNotification,
  contentNotification,
  changeAllResumoSetData,
} from 'store/old_actions/actions';

import { getBordero, getNewProposta, getPropostaNewSemNota } from './selectors';

import config from '../../config';

/** *********************** Load propostas saga ***************************** */
export function* getPropostas(action) {
  try {
    const dataUser = yield call(getUserData);

    const reqData = {
      params: {
        idOperacao: action.idOperacao,
        idConvenio: dataUser.idEmpresa,
        idPessoaConvenio: dataUser.idUsuario,
      },
    };

    yield put(setDefaultDataPropostas());

    const res = yield axios.post(
      `${config.API_BASE_URL}/api/proposta/convenio-lista`,
      null,
      reqData
    );

    // Clear data resume history
    yield put(resumoClearDataHistory([]));

    yield put(loadDataPropostasSuccess(res.data));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield put(loadDataPropostasFailure(err.response));
    yield put(
      contentNotification(
        `Erro!!! Name: ${err.name}Message :${err.message}`,
        2000
      )
    );
    yield put(showNotification());
  }
}

/** *********************** Calcula propostas saga ***************************** */
// eslint-disable-next-line no-unused-vars
export function* calculaPropostas(action) {
  try {
    const dataUser = yield call(getUserData);

    let resumo = {};
    let tituloList = [];

    // Get Proposta sem nota
    const propSemNota = yield select(getPropostaNewSemNota);

    // Get Bordero data
    const borderos = yield select(getBordero);

    if (borderos !== null) {
      // Create list to request
      const titulos = borderos.sacados.map((sacado) => sacado.titulos);
      titulos.forEach((res) => {
        res.forEach((sacado) => {
          tituloList.push(sacado);
        });
      });
    } else {
      tituloList = null;
    }

    // console.log(tituloList);

    // Get Proposta data
    const propostas = yield select(getNewProposta);
    // console.log(propostas);

    const requestData = {
      ...propostas,
      idConvenio: dataUser.idEmpresa,
      idPessoaConvenio: dataUser.idUsuario,
      bordero: tituloList,
      semNota: propSemNota !== null ? { ...propSemNota } : null,
    };

    // console.log('Request: ' + JSON.stringify(requestData));

    // Request data
    const res = yield axios.post(
      `${config.API_BASE_URL}/api/proposta/simulacao-convenio`,
      requestData
    );
    // console.log('Response: ' + JSON.stringify(res.data));
    yield put(calculaPropostasSuccess(res.data));

    if (res.data.semNota !== null) {
      yield put({
        type: 'EDIT_ITEM_DEFAULT_PROPOSTA',
        field: 'valorLiquido',
        value: res.data.semNota.valorLiquido,
      });
      yield put({
        type: 'EDIT_ITEM_DEFAULT_PROPOSTA',
        field: 'pmp',
        value: res.data.semNota.pmp,
      });
    }

    resumo = {
      antecipacao: {
        valorBruto: res.data.vop,
        taxa: res.data.taxa,
        pmp: res.data.pmp,
        liquido: res.data.liquido,
      },
      emprestimo: {
        valorBruto: res.data.semNota ? res.data.semNota.valorBruto : 0,
        taxa: res.data.semNota ? res.data.semNota.taxa : 0,
        pmp: res.data.semNota ? res.data.semNota.pmp : 0,
        liquido: res.data.semNota ? res.data.semNota.valorLiquido : 0,
      },
    };

    yield put(changeAllResumoSetData(resumo));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield put(calculaPropostasFailure(err.response));

    if (err.response.status === 400) {
      yield put(
        contentNotification(
          `Erro!!! Name: ${err.name}Message :${err.response.data}`,
          2000
        )
      );
      yield put(showNotification());
    } else {
      yield put(
        contentNotification(
          `Erro!!! Name: ${err.name}Message :${err.message}`,
          2000
        )
      );
      yield put(showNotification());
    }
  }
}

/** *********************** Contra propostas saga ***************************** */
export function* incluirContraPropostas(action) {
  try {
    // const headers = yield call(getUserAuthenticated);

    const user = yield call(getUserData);

    // Get Bordero data
    const borderos = yield select(getBordero);

    let tituloList = [];

    if (borderos !== null) {
      // Create list to request
      const titulos = borderos.sacados.map((sacado) => sacado.titulos);

      titulos.forEach((res) => {
        res.forEach((sacado) => {
          tituloList.push(sacado);
        });
      });
    } else {
      tituloList = null;
    }

    // Get Proposta data
    const propostas = yield select(getNewProposta);
    // console.log(propostas);

    // Get new proposta sem nota
    const semNota = yield select(getPropostaNewSemNota);
    // console.log(semNota);

    const requestData = {
      ...propostas,
      idConvenio: user.idEmpresa,
      idPessoaConvenio: user.idUsuario,
      bordero: tituloList,
      semNota: semNota === null ? null : { ...semNota },
    };

    // console.log('Request: ' + JSON.stringify(requestData));

    // Request data
    const res = yield axios.post(
      `${config.API_BASE_URL}/api/proposta/convenio-contra`,
      requestData
    );

    // console.log('Response: ' + JSON.stringify(res.data));

    yield put(incluirContraPropostasSuccess(res.data));

    yield put(resumoClearDataHistory([]));

    yield put({
      type: 'LOAD_DATA_PROPOSTAS_REQUEST',
      idOperacao: action.idOperacao,
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield put(incluirContraPropostasFailure(err.response));
    yield put(
      contentNotification(
        `Erro!!! Name: ${err.name}Message :${err.message}`,
        2000
      )
    );
    yield put(showNotification());
  }
}

/** *********************** Aceitar propostas saga ***************************** */
export function* aceitarPropostas(action) {
  try {
    // const headers = yield call(getUserAuthenticated);

    const user = yield call(getUserData);

    const requestData = {
      params: {
        idProposta: action.value.idProposta,
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
      },
    };

    // console.log(JSON.stringify(requestData));

    // Request data
    /* const res = yield axios.post(
        config.API_BASE_URL + '/api/proposta/convenio-aceite',
        null,
        { headers, ...requestData }
      ); */
    const res = yield axios.post(
      `${config.API_BASE_URL}/api/proposta/convenio-aceite`,
      null,
      requestData
    );

    // console.log(JSON.stringify(res.data));

    yield put(aceitarPropostaSuccess(res.data));
    yield put({
      type: 'LOAD_DATA_PROPOSTAS_REQUEST',
      idOperacao: action.value.idOperacao,
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield put(aceitarPropostaFailure(err.response));
    yield put(
      contentNotification(
        `Erro!!! Name: ${err.name}Message :${err.message}`,
        2000
      )
    );
    yield put(showNotification());
  }
}

/** *********************** Aprovada pagamento saga ***************************** */
export function* aprovadaPropostas(action) {
  try {
    // const headers = yield call(getUserAuthenticated);

    const user = yield call(getUserData);

    const requestData = {
      params: {
        idProposta: action.idProposta,
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
      },
    };

    // console.log(JSON.stringify(requestData));

    // Request data
    const res = yield axios.post(
      `${config.API_BASE_URL}/api/proposta/convenio-vitoria`,
      null,
      requestData
    );

    // console.log(JSON.stringify(res.data));

    yield put(aprovadaPropostaSuccess(res.data));
    yield put({
      type: 'LOAD_DATA_PROPOSTAS_REQUEST',
      idOperacao: action.idOperacao,
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield put(aprovadaPropostaFailure(err.response));
    yield put(
      contentNotification(
        `Erro!!! Name: ${err.name}Message :${err.message}`,
        2000
      )
    );
    yield put(showNotification());
  }
}

/** *********************** Get info pagamento saga ***************************** */
export function* getInfoPagProposta(action) {
  try {
    // const headers = yield call(getUserAuthenticated);

    const user = yield call(getUserData);

    const requestData = {
      params: {
        idOperacao: action.idOperacao,
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
      },
    };

    // console.log(JSON.stringify(requestData));

    // Request data
    const res = yield axios.post(
      `${config.API_BASE_URL}/api/pagamento/seleciona-convenio`,
      null,
      requestData
    );

    // console.log(JSON.stringify(res.data));

    yield put(InfoPagPropostaSuccess(res.data));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield put(InfoPagPropostaFailure(err.response));
    yield put(
      contentNotification(
        `Erro!!! Name: ${err.name}Message :${err.message}`,
        2000
      )
    );
    yield put(showNotification());
  }
}

/** *********************** Confirme pagamento saga ***************************** */
export function* pagamentoProposta(action) {
  try {
    // const headers = yield call(getUserAuthenticated);

    const user = yield call(getUserData);

    const requestData = {
      params: {
        idProposta: action.value.idProposta,
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
      },
    };

    // console.log(JSON.stringify(requestData));

    // Request data
    const res = yield axios.post(
      `${config.API_BASE_URL}/api/pagamento/confirma-convenio`,
      null,
      requestData
    );

    // console.log(JSON.stringify(res.data));

    yield put(PagamentoPropostaSuccess(res.data));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield put(PagamentoPropostaFailure(err.response));
    yield put(
      contentNotification(
        `Erro!!! Name: ${err.name}Message :${err.message}`,
        2000
      )
    );
    yield put(showNotification());
  }
}

/** *********************** SemNota ***************************** */

import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import { ModalCuston, Head } from 'components/FinModalCuston';
import { Creators as routeSaveActions } from 'store/ducks/routeSave';
import { useActions } from 'hooks/useActions';
import NavLinksConvites from './NavLinksConvites';
import Content from './Content';

import { Provider } from './Provider';

const Container = styled.div`
  width: calc(100% - 100px);
  margin: auto;
  background-color: ${({ theme }) => theme.newtheme.color.branco};
  margin-top: 3rem;
  margin-bottom: 3rem;
  border-radius: ${({ theme }) => theme.newtheme.borderRadius};
  padding-bottom: 2rem;
`;

const PaginaListaConvites = ({ history, isModal, background }) => {
  // eslint-disable-next-line no-unused-vars
  const [modalView, setModalView] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [tabActive, setTabActive] = useState('recebidos');

  const actions = useActions(routeSaveActions);

  const saveRoute = useCallback(() => {
    actions.routeSave(background);
  }, [actions, background]);

  useEffect(() => {
    saveRoute();
  }, [saveRoute]);

  const toBack = () => history.goBack();
  return isModal ? (
    <ModalCuston
      open={modalView}
      title=""
      scrolling="true"
      onClose={toBack}
      header={<Head />}
      ppadding="0"
    >
      <Provider>
        <NavLinksConvites isSelected={tabActive} changeTab={setTabActive} />
        <Content tabActive={tabActive} />
      </Provider>
    </ModalCuston>
  ) : (
    <Container>
      <Provider>
        <Head />
        <NavLinksConvites isSelected={tabActive} changeTab={setTabActive} />
        <Content tabActive={tabActive} />
      </Provider>
    </Container>
  );
};

export default PaginaListaConvites;

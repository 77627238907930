/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-console */
import { put, call } from 'redux-saga/effects';
import axios from 'axios';

import { getUserData } from 'services/getUserData';
import {
  loadDataFinalizadosSuccess,
  loadDataFinalizadosFailure,
  showNotification,
  contentNotification,
  showLoading,
  hideLoading,
} from 'store/old_actions/actions';

// import { getDateMainSearch } from './selectors';

import config from '../../config';

// eslint-disable-next-line no-unused-vars
export function* filtrarPregao(orderBy, target, quantidade, page, filters) {
  try {
    // const headers = yield call(getUserAuthenticated);

    const user = yield call(getUserData);

    /*
    const date = yield select(getDateMainSearch);


    const requestData = {
      params: {
        idConvenio: user.idEmpresa,
        idPessoaConvenio: user.idUsuario,
        dtInicio: date,
      },
    }; */

    yield put(showLoading());

    // Request data
    // const res = yield axios.post(config.API_BASE_URL + url, null, requestData);

    /**
     * new endpoint from remodeling
     */

    const ordem = orderBy || { ordemDataInicio: 1 };
    const tipoAba = target ? target : 1;
    const size = quantidade || 5;
    const skip = page || 1;

    /**
     * Filters => ["busca": "string",  "dataInicioVigencia": "2021-08-16T19:59:03.436Z", "tipoPregao": "1 = BoletoPreImpresso"]
     */

    const payload = {
      size,
      skip,
      filter: {
        idConvenio: user.idEmpresa,
        tipoAba,
        ...ordem,
        ...filters,
      },
    };
    const res = yield axios.post(
      `${config.API_REMODELING_URL}/business-api/api/pregao/proposta/ofertas/convenios`,
      payload
    );

    yield put(loadDataFinalizadosSuccess(res.data || []));

    yield put({
      type: 'LOAD_COUNT_RECORDS_DATA_REQUEST',
      tipoAba,
      filters,
    });

    yield put(hideLoading());
  } catch (err) {
    console.log(err);
    yield put(hideLoading());
    yield put(loadDataFinalizadosFailure(err.response));
    yield put(
      contentNotification(
        `Erro!!! Name: ${err.name}Message :${err.message}`,
        2000
      )
    );
    yield put(showNotification());
  }
}

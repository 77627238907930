/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { useQueryClient } from 'react-query';
import { useMutateFields } from './mutates/useUpdateFields';
import { AgendaContext } from './reducer';

import {
  PopoverTrigger,
  Popover,
  Option,
  PriorityId,
  PriorityName,
  ArrowDown,
} from './priorityStyles';
import { PopoverContent } from './Popover';

import { Spinner } from './Spinner';

export const PriorityPopover = ({
  priorityVars,
  varId,
  idAgenda,
  checkEdition,
}) => {
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [isLoading, setIsloading] = React.useState(false);

  const [state] = useContext(AgendaContext);
  const updateFields = useMutateFields();

  const refPriority = React.useRef();

  const {
    filter: { mesAnoVigencia },
  } = state;

  const queryClient = useQueryClient();
  const grafico = queryClient.getQueryData([
    'agenda',
    'grafico',
    { mesAnoVigencia },
  ]);

  const checkDisablePriority = (id) => {
    const data = grafico.data.graficoPrioridades.filter(
      (item) => item.idPrioridade === id
    )[0];
    return data.percMax === data.percTotalAgenda;
  };

  const labels = {
    0: '0 (MÁXIMA)',
    1: '1 (MUITO ALTA)',
    2: '2 (ALTA) ',
    3: '3 (PADRÃO)',
    4: 'Selecionar',
  };

  const check =
    checkEdition.habilitacaoEdicaoGeral ||
    checkEdition.habilitacaoEdicaoPrioridade;

  const setPrioritySelect = (id) => {
    updateFields.mutate([{ idItemAgenda: idAgenda, idPrioridade: Number(id) }]);
  };

  React.useEffect(() => {
    if (refPriority.current) {
      setIsloading(updateFields.isLoading);
    }
    if (!updateFields.isLoading) {
      setIsloading(false);
    }
  }, [updateFields.isLoading]);

  return (
    <div style={{ position: 'relative', width: '157px' }}>
      <Popover tabIndex="-1" open={popoverOpen}>
        <PopoverTrigger
          priority={check ? priorityVars[varId] : 'disabled'}
          tabIndex="-1"
          onClick={() => setPopoverOpen(true)}
        >
          {labels[varId]}
          <ArrowDown />
        </PopoverTrigger>
        <PopoverContent
          onPointerDownOutside={() => setPopoverOpen(false)}
          ref={refPriority}
        >
          <Option
            data-priority="0"
            priority={
              !checkDisablePriority(0) ? (varId === 0 ? 'max' : '') : 'disabled'
            }
            onClick={() => {
              setPopoverOpen(false);
              setPrioritySelect(0);
            }}
          >
            <PriorityId>0</PriorityId>
            <PriorityName>(MÁXIMA)</PriorityName>
          </Option>

          <Option
            data-priority="1"
            priority={
              !checkDisablePriority(1)
                ? varId === 1
                  ? 'higher'
                  : ''
                : 'disabled'
            }
            onClick={() => {
              setPopoverOpen(false);
              setPrioritySelect(1);
            }}
          >
            <PriorityId>1</PriorityId>
            <PriorityName>(MUITO ALTA)</PriorityName>
          </Option>

          <Option
            data-priority="2"
            priority={
              !checkDisablePriority(2)
                ? varId === 2
                  ? 'high'
                  : ''
                : 'disabled'
            }
            onClick={() => {
              setPopoverOpen(false);
              setPrioritySelect(2);
            }}
          >
            <PriorityId>2</PriorityId>
            <PriorityName>(ALTA)</PriorityName>
          </Option>

          <Option
            data-priority="3"
            priority={varId === 3 ? 'standard' : ''}
            onClick={() => {
              setPopoverOpen(false);
              setPrioritySelect(3);
            }}
          >
            <PriorityId>3</PriorityId>
            <PriorityName>(PADRÃO)</PriorityName>
          </Option>
        </PopoverContent>
      </Popover>
      {isLoading && <Spinner />}
    </div>
  );
};

import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Tabs, ItemTab, Item } from 'components/FinTabs';
import { StringToSvg } from 'helpers/svgFiles/StringToSvg';
import { finTheme } from 'finTheme';
import { icons } from 'assets/icones/finplaceIcons';

import { Wrapper } from 'components/FinWrappers';

import { checkStatusConexao } from '../CheckStatusConexao';

const NavLinksDetail = ({ isSelected, changeTab, data }) => {
  const listaClientesState = useSelector((state) => state.apiListClientes);
  const { idEmpreendedor } = useParams();

  const [cedente] = listaClientesState.data.filter(
    (res) => res.id.toString() === idEmpreendedor
  );

  const empreendedor = cedente || data;

  return (
    empreendedor !== undefined && (
      <Tabs ppadding="0 50px">
        <ItemTab>
          <Item
            onClick={() => changeTab('geral')}
            isActive={isSelected === 'geral'}
          >
            <Wrapper
              pwidth="21px"
              pheight="21px"
              padding="0"
              pmargin="0 0.6rem 0 0"
            >
              <StringToSvg
                viewBox="0 -2 24 24"
                width="100%"
                height="100%"
                strokeWidth={'1'}
                stroke={finTheme.newtheme.color.cinza4}
              >
                {icons.infoDoc}
              </StringToSvg>
            </Wrapper>
            Visão Geral
          </Item>
        </ItemTab>
        <ItemTab>
          <Item
            onClick={() => changeTab('endereco')}
            isActive={isSelected === 'endereco'}
          >
            <Wrapper
              pwidth="23px"
              pheight="21px"
              padding="0"
              pmargin="0 0.6rem 0 0"
            >
              <StringToSvg
                viewBox="0 -1 24 24"
                width="100%"
                height="100%"
                strokeWidth={'1'}
                stroke={finTheme.newtheme.color.cinza4}
              >
                {icons.build}
              </StringToSvg>
            </Wrapper>
            Endereço
          </Item>
        </ItemTab>
        {checkStatusConexao(empreendedor) === 'conectado' && (
          <ItemTab>
            <Item
              onClick={() => changeTab('contatos')}
              isActive={isSelected === 'contatos'}
            >
              <Wrapper
                pwidth="24px"
                pheight="24px"
                padding="0"
                pmargin="0 0.6rem 0 0"
              >
                <StringToSvg
                  viewBox="0 0 24 24"
                  width="100%"
                  height="100%"
                  strokeWidth={'2'}
                  stroke={finTheme.newtheme.color.cinza4}
                >
                  {icons.notebook}
                </StringToSvg>
              </Wrapper>
              Contatos
            </Item>
          </ItemTab>
        )}
        {checkStatusConexao(empreendedor) === 'conectado' && (
          <ItemTab>
            <Item
              onClick={() => changeTab('documentos')}
              isActive={isSelected === 'documentos'}
            >
              <Wrapper
                pwidth="23px"
                pheight="21px"
                padding="0"
                pmargin="0 0.6rem 0 0"
              >
                <StringToSvg
                  viewBox="0 0 24 24"
                  width="100%"
                  height="100%"
                  strokeWidth={'2'}
                  stroke={finTheme.newtheme.color.cinza4}
                >
                  {icons.documentText}
                </StringToSvg>
              </Wrapper>
              Documentos
            </Item>
          </ItemTab>
        )}
        {checkStatusConexao(empreendedor) === 'conectado' && (
          <ItemTab>
            <Item
              onClick={() => changeTab('dashboard')}
              isActive={isSelected === 'dashboard'}
            >
              <Wrapper
                pwidth="23px"
                pheight="21px"
                padding="0"
                pmargin="0 0.6rem 0 0"
              >
                <StringToSvg
                  viewBox="0 0 36 36"
                  width="80%"
                  height="80%"
                  strokeWidth={'1'}
                  stroke={finTheme.newtheme.color.cinza4}
                >
                  {icons.Finscanner_symbol}
                </StringToSvg>
              </Wrapper>
              Finscanner (teste)
            </Item>
          </ItemTab>
        )}
      </Tabs>
    )
  );
};

export default NavLinksDetail;

import React from 'react';
import * as NWStep from './style.step';
import {
  CreditoAprovado,
  CreditoPendente,
  CreditoRecusado,
  HabilitacaoAprovada,
  HabilitacaoPendente,
  HabilitacaoRecusada,
} from '../Step';

// import {
//   StepTouched,
//   // StepPending,
//   // SubStepAproved,
//   // SubStepDisabled,
//   // SubStepPending,
// } from './Steps';

const status = (id, rest) => {
  const comps = {
    1: <CreditoPendente {...rest} noLine />,
    2: <CreditoAprovado {...rest} noLine />,
    3: <CreditoRecusado {...rest} noLine />,
  };
  return comps[id] || [];
};

const statusHabilitacao = (id, rest) => {
  const comps = {
    1: <HabilitacaoPendente {...rest} />,
    2: <HabilitacaoRecusada {...rest} />,
    3: <HabilitacaoAprovada {...rest} />,
  };
  return comps[id] || [];
};

export const RegionSteps = ({ data }) => {
  const habilitacao = data.statusHabilitado || data.habilitacaoStatus;
  return (
    <NWStep.RegionSteps>
      {/* <StepTouched value="C" label="Crédito" active /> */}
      {status(data.aprovacaoStatus, { label: data.aprovacaoStatusDesc })}
      {/* <StepTouched value="H" label="Habilitação" active /> */}
      {statusHabilitacao(habilitacao, {
        label: data.habilitacaoStatusDesc,
        noLine: true,
      })}
    </NWStep.RegionSteps>
  );
};

import React from 'react';

import { ReactComponent as IconFile } from 'assets/images/icon-filetext-outline.svg';
import { dateTime } from 'helpers/format';
import * as DocStyles from './styles.docsuser';
import { NWTable } from '../Comps/Table';

export function DocsUser({ data, selecionados, setSelecionados }) {
  const onHandlerChange = (e, id) => {
    if (e.target.checked) {
      setSelecionados((state) => [...state, id]);
    } else {
      const removed = selecionados.filter((item) => item !== id);
      setSelecionados(removed);
    }
  };

  return (
    <NWTable.Tr>
      <NWTable.Td>
        <DocStyles.CheckDirectory>
          <DocStyles.Checkbox
            checked={selecionados.indexOf(data.idDocumento) !== -1}
            onChange={(e) => onHandlerChange(e, data.idDocumento)}
          />
          <IconFile style={{ margin: '0 17px 0 20px' }} />
          <DocStyles.Label>{data.nome}</DocStyles.Label>
        </DocStyles.CheckDirectory>
      </NWTable.Td>
      <NWTable.Td>
        <DocStyles.ContentDescription>
          <DocStyles.Label>
            {data.dtAtualizacao && dateTime(data.dtAtualizacao)}
          </DocStyles.Label>
        </DocStyles.ContentDescription>
      </NWTable.Td>
      <NWTable.Td>
        <DocStyles.ContentDescription>
          <DocStyles.Label>
            {data.dtUltimoDownload && dateTime(data.dtUltimoDownload)}
          </DocStyles.Label>
        </DocStyles.ContentDescription>
      </NWTable.Td>
    </NWTable.Tr>
  );
}

import axios from 'axios';
import { useQuery } from 'react-query';
import CONFIG from 'config';

import { dashboardKeys } from '../queryKeyFactory';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/`;

const fetchFaturamentoMensal = async (params) => {
  const fetchData = axios.post(
    `${BASE_URL}api/cedente/titulo/faturamentos/mensal/${params.idEmpresa}`,
    { ano: params.ano }
  );

  const result = await fetchData;
  const { data } = result;

  return data.data ? data.data : [];
};

export const useChartFaturamentoMensal = (params) => {
  return useQuery(
    dashboardKeys.faturamentoMensal(params),
    () => fetchFaturamentoMensal(params),
    {
      keepPreviousData: false,
    }
  );
};

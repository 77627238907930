import React from 'react';
import { Title6 } from 'components/FinHeadings';
import { Overline } from 'components/FinParagraph';
import { PropostaButtons } from './Status/PropostaButtons';

import {
  CardStyle,
  CardStyleHead,
  CardStyleBody,
  CardStyleFooter,
} from './style';

import { Antecipacao } from './Antecipacao';
import { Emprestimo } from './Emprestimo';
import { Cheque } from './Cheque';
import { CobrancaSimples } from './CobrancaSimples';

import { Podium } from './Podium';
import { Status } from './Status';
import { IDS, TIPO_PREGAO } from '../config';
import { BoxChampion } from './BoxChampion';

export const CardProposta = ({ data }) => {
  const statusProposta = IDS[data.statusProposta.idPropostaStatus];
  const styleCustom =
    statusProposta === 'aConfirmarPagamento' ||
    statusProposta === 'operacaoPaga' ||
    statusProposta === 'pagamentoConcluido'
      ? {
          border: `1px solid #ffcb04`,
          backgroundColor: `#fcf7e5`,
        }
      : null;

  return (
    <>
      <CardStyle style={styleCustom}>
        {IDS[data.statusProposta.idPropostaStatus] === 'propostaAprovada' && (
          <BoxChampion data={data} />
        )}
        <CardStyleHead>
          <Title6
            style={{ margin: '0.4rem 0 0 0', fontWeight: 'bold' }}
          >{`${data.titulo}`}</Title6>
          <Overline>{`Total: ${
            data.podium.quantidadePropostas || 0
          } Propostas`}</Overline>
          <Podium position={data.podium.posicao} />
        </CardStyleHead>
        <CardStyleBody>
          {TIPO_PREGAO.antecipacao.indexOf(data.tipoPregao) !== -1 && (
            <Antecipacao data={data} />
          )}
          {TIPO_PREGAO.cobrancaSimples.indexOf(data.tipoPregao) !== -1 && (
            <CobrancaSimples data={data} />
          )}
          {TIPO_PREGAO.emprestimo.indexOf(data.tipoPregao) !== -1 && (
            <Emprestimo data={data} />
          )}
          {TIPO_PREGAO.cheque.indexOf(data.tipoPregao) !== -1 && (
            <Cheque data={data} />
          )}
        </CardStyleBody>
        <CardStyleFooter>
          <PropostaButtons
            tipo={IDS[data.statusProposta.idPropostaStatus]}
            data={data}
            excludeInteraction={['confirmarPagamento']}
          />
          <Status
            tipo={IDS[data.statusProposta.idPropostaStatus]}
            changeToAproved={[
              'aConfirmarPagamento',
              'operacaoPaga',
              'pagamentoConcluido',
            ]}
          />
        </CardStyleFooter>
      </CardStyle>
    </>
  );
};

import React from 'react';
import { icons } from 'assets/icones/finplaceIcons';
import { StringToSvg } from 'helpers/svgFiles/StringToSvg';
import { ModalExperimente } from '../Styles';

const ModalConteutoExperimente = () => {
  return (
    <ModalExperimente>
      <p>&nbsp;</p>
      <p>Entre em contato com a nossa equipe através dos canais abaixo:</p>
      <p>&nbsp;</p>
      <p className="comIcone">
        <StringToSvg
          viewBox="0 0 25 25"
          width="25px"
          height="25px"
          strokeWidth="0"
        >
          {icons.Telefone}
        </StringToSvg>
        &nbsp;
        <strong>(11) 5555-5806</strong>
        <span>Selecione a opção: comercial</span>
      </p>
      <p>
        <a
          className="comIcone"
          href="mailt:engajamento@finplace.com.br?subject='Finmatch Via Modal Sistema'"
        >
          <StringToSvg
            viewBox="0 0 30 25"
            width="30px"
            height="25px"
            strokeWidth="0"
          >
            {icons.Email}
          </StringToSvg>
          &nbsp; engajamento@finplace.com.br
        </a>
      </p>
    </ModalExperimente>
  );
};
export default ModalConteutoExperimente;

import styled, { css } from 'styled-components/macro';

import * as FinTable from 'components/FinTable';

export const Table = styled(FinTable.Table)`
  overflow: hidden;
`;

export const Thead = styled(FinTable.Thead)`
  overflow: hidden;
  background-color: var(--fingray-200);
  border-bottom: none;
`;

const BorderRadiusLeft = css`
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
`;

const BorderRadiusRight = css`
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
`;

export const Th = styled(FinTable.Th)`
  ${({ borderRadiusLeft }) => borderRadiusLeft && BorderRadiusLeft};
  ${({ borderRadiusRight }) => borderRadiusRight && BorderRadiusRight};
  ${({ align }) => align && 'left'};
  padding: 12px 0;
  color: var(--fingray-500);
  font-family: 'Inter';
  font-weight: 600;
  font-size: 0.875rem;
`;

export const Tr = styled(FinTable.Tr)`
  & th:first-child,
  & td:first-child {
    padding-left: 26px;
  }

  & th:last-child,
  & td:last-child {
    padding-right: 26px;
  }
`;

export const Tbody = styled(FinTable.Tbody)``;

export const Td = styled(FinTable.Td)`
  font-family: 'Inter';
  font-weight: 400;
  font-size: 0.875rem;
  vertical-align: middle;
  color: var(--fingray-900);
`;

export const TFooter = styled.tfoot`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

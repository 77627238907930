/* eslint-disable no-unused-vars */
import { useMutation } from 'react-query';
import axios from 'axios';
import CONFIG from 'config';
import { handleErrors } from 'helpers/requisicoes/handleErrors';
import toast from 'react-hot-toast';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const chat = async (params) => {
  const mutateData = axios.post(
    `${BASE_URL}/operacao/chat/convenio/${params.idOperacao}`
  );

  const result = await mutateData;
  const { data } = result;

  return data || [];
};

export const useMutateChat = () => {
  return useMutation((params) => chat(params), {
    onMutate: async (params) => {
      return { params };
    },
    onError: (error) => {
      const message = handleErrors(error);
      toast.error(message, { duration: 4000 });
    },
    onSuccess: (params, data, variables, context) => {
      window.open(
        `https://meet.jit.si/Finplace-${params.idOperacao}`,
        'Chat Finplace',
        'height=600,width=700'
      );
    },
  });
};

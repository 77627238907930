import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import {
  Field,
  Label,
  SelectComp,
  Option,
  StatusBar,
  InputFeedback,
} from './Styles';

const SelectFormik = ({ label, data, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <Field error={meta.error} largura={props.largura}>
      <Label
        htmlFor={props.id || props.name}
        color={props.theme.color.cinza3}
        fontweight="400"
        marginbottom="0.5rem"
      >
        {label}
      </Label>
      <SelectComp {...field} {...props}>
        {data.map((res) => {
          return <Option value={res.name} key={res.id} label={res.name} />;
        })}
      </SelectComp>
      <StatusBar />
      {meta.touched && meta.error ? (
        <InputFeedback>{meta.error}</InputFeedback>
      ) : null}
    </Field>
  );
};

SelectFormik.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  largura: PropTypes.string,
  data: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

SelectFormik.defaultProps = {
  name: null,
  field: false,
  label: '',
  largura: '50%',
  data: null,
  value: null,
  handleChange: null,
  handleBlur: null,
  error: null,
  disabled: false,
};

export default SelectFormik;

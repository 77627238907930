import React from 'react';

import { Table } from 'components/Table';
import { currency, dotToComma } from 'components/Formatter';

const ResumeValues = ({ data }) => {
  let totalOperacoes = 0;
  const formatValues = () => {
    const { taxa, historico, oferta, bruto, pmp, liquido } = data;

    totalOperacoes += data.liquido;
    const formated = {
      oferta: oferta || 0,
      bruto: bruto ? currency(bruto) : 0,
      taxa: taxa ? `${dotToComma(taxa)} %` : 0,
      historico: historico ? `${dotToComma(historico)} %` : `0 %`,
      pmp: pmp ? `${dotToComma(pmp.toFixed(2))} dias` : `0 dias`,
      liquido: liquido ? currency(liquido) : currency(0),
    };
    return [formated];
  };
  return (
    <Table
      data={formatValues()}
      footPosition={5}
      footContent={currency(totalOperacoes)}
    />
  );
};

export { ResumeValues };

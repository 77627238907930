import React from 'react';

import { SeparatorHorizontal } from 'components/FinSeparador';
import { Caption } from 'components/FinParagraph';
import { useSelector } from 'react-redux';
import { ButtonStyle, ButtonWrapper, NotifyItem } from './style';

export const StatusButton = ({
  data,
  status,
  onClick,
  label,
  borderHidden,
  children,
}) => {
  const notify = useSelector(
    (state) => state.uiNotificationContent.notifications
  );

  const withData = !!data && notify.length > 0;

  const notification = withData
    ? notify.filter((item) => item.idPregao === data.idPregao).length > 0
    : null;

  if (status === 'isHidden') {
    return null;
  }

  return (
    <ButtonWrapper status={status} borderHidden={borderHidden}>
      <ButtonStyle status={status} onClick={onClick}>
        {label === 'Negociação' && notification && <NotifyItem />}
        {children}
      </ButtonStyle>
      <SeparatorHorizontal ppadding="0.2rem 0" />

      <Caption style={{ textAlign: 'center', lineHeight: '14px' }}>
        {label}
      </Caption>
    </ButtonWrapper>
  );
};

import React from 'react';
import Skeleton from 'react-loading-skeleton';

const Wrapper = ({ children }) => {
  return (
    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      {children}
    </div>
  );
};

const WrapperBar = ({ children }) => {
  return (
    <div style={{ width: '100%', margin: '0 3rem 0 1rem' }}>{children}</div>
  );
};

export const HeaderSkeleton = () => {
  return (
    <Wrapper>
      <Skeleton circle style={{ width: '40px', height: '40px' }} />
      <WrapperBar>
        <Skeleton count={1} />
      </WrapperBar>
    </Wrapper>
  );
};

import React from 'react';

import { PaginationButton, PaginationLink } from './style.pagination';

export const NumberButton = ({ onclick, current, value }) => (
  <PaginationButton>
    <PaginationLink
      onClick={(e) => onclick(e, value)}
      current={current && 'active'}
    >
      {value}
    </PaginationLink>
  </PaginationButton>
);

import React from 'react';

import { currency } from 'components/Formatter';

import {
  Card,
  PriTop,
  PriValue,
  PriLevel,
  TopCont,
  GroupIcon,
  BottomCont,
  PriBottom,
  TotalGraphic,
  FilledGraphic,
} from './cardStyles';

export const PriorityCard = ({
  backGroundColor,
  priLvlLabel,
  priLvlColor,
  iconcolor,
  backGraphColor,
  fill,
  data,
  limit,
}) => {
  return (
    <Card backGroundColor={backGroundColor}>
      <PriTop>
        <PriValue>{currency(data.vlrTotalAgenda)}</PriValue>
        <PriLevel priLvlColor={priLvlColor}>{priLvlLabel}</PriLevel>
      </PriTop>
      <PriBottom>
        <TopCont>
          <GroupIcon iconcolor={iconcolor} />
          {data.percMax === data.percTotalAgenda ? (
            <span>Máx {data.percTotalAgenda}%</span>
          ) : (
            <span>{data.percTotalAgenda}%</span>
          )}
        </TopCont>
        <BottomCont>
          <TotalGraphic backGraphColor={backGraphColor}>
            <FilledGraphic
              fill={fill}
              filledPercent={data.percTotalAgenda}
              maxValue={data.percMax}
            />
          </TotalGraphic>
          {limit ? (
            <span style={{ marginTop: '5px' }}>
              Limite Máximo {data.percMax}%
            </span>
          ) : (
            <span style={{ marginTop: '5px' }}>Sem Limite Máximo</span>
          )}
        </BottomCont>
      </PriBottom>
    </Card>
  );
};

import React from 'react';
import { StringToSvg } from 'helpers/svgFiles/StringToSvg';
import Button from 'components/FinButton';
import { icons } from 'assets/icones/finplaceIcons';
import feather from 'feather-icons/dist/icons.json';
import { finTheme } from 'finTheme';

import { Wrapper } from 'components/FinWrappers';
import { MenuHabilitar } from 'components/FinMenu';

export const ButtonConect = ({ onConect }) => (
  <Button
    tipo="primary"
    size={finTheme.newtheme.buttons.sizes.smallM}
    onClick={onConect}
  >
    <StringToSvg
      viewBox="0 0 20 20"
      width="20"
      height="20"
      stroke="none"
      fill={finTheme.newtheme.color.branco}
    >
      {icons.userPlus}
    </StringToSvg>
    &nbsp;&nbsp;Conectar
  </Button>
);

export const ButtonChat = ({ onChat, online }) => (
  <Button
    tipo="primary"
    size={finTheme.newtheme.buttons.sizes.smallM}
    onClick={onChat}
    disabled={!online}
  >
    <StringToSvg
      viewBox="0 -3 20 20"
      width="20"
      height="20"
      stroke="none"
      fill={finTheme.newtheme.color.branco}
    >
      {icons.chatFlat}
    </StringToSvg>
    &nbsp;&nbsp;Chat
  </Button>
);

export const ButtonEnable = ({
  onEnable,
  open,
  close,
  id,
  statusHabilitado,
}) => (
  <Wrapper padding="0" pposition="relative">
    <Button
      tipo="success"
      size={finTheme.newtheme.buttons.sizes.smallM}
      onClick={onEnable}
    >
      <StringToSvg
        viewBox="0 -3 20 20"
        width="20"
        height="20"
        stroke="none"
        fill={finTheme.newtheme.color.branco}
      >
        {icons.settingsFlat}
      </StringToSvg>
      &nbsp;&nbsp;Habilitar
    </Button>
    <MenuHabilitar
      open={open}
      close={close}
      id={id}
      statusHabilitado={statusHabilitado}
    />
  </Wrapper>
);

export const ButtonUnlock = ({ onUnlock }) => (
  <Button
    tipo="primary"
    preenchimento="outline"
    size={finTheme.newtheme.buttons.sizes.smallM}
    onClick={onUnlock}
  >
    <StringToSvg
      viewBox="0 0 30 30"
      width="20"
      height="20"
      stroke={finTheme.newtheme.color.azulPrimario}
    >
      {feather.unlock}
    </StringToSvg>
    &nbsp;&nbsp;Desbloquear
  </Button>
);

export const ButtonRemove = ({ onRemove }) => (
  <Button
    tipo="primary"
    preenchimento="outline"
    size={finTheme.newtheme.buttons.sizes.smallM}
    onClick={onRemove}
  >
    <StringToSvg
      viewBox="0 -2 30 30"
      width="23"
      height="23"
      stroke={finTheme.newtheme.color.azulPrimario}
    >
      {feather.x}
    </StringToSvg>
    &nbsp;&nbsp;Remover
  </Button>
);

export const ButtonAccept = ({ onAccept }) => (
  <Button
    tipo="primary"
    size={finTheme.newtheme.buttons.sizes.smallM}
    onClick={onAccept}
  >
    <StringToSvg
      viewBox="0 -2 30 30"
      width="23"
      height="23"
      stroke={finTheme.newtheme.color.branco}
    >
      {feather.check}
    </StringToSvg>
    &nbsp;&nbsp;Aceitar
  </Button>
);

export const ButtonRefuse = ({ onRefuse }) => (
  <Button
    tipo="primary"
    preenchimento="outline"
    size={finTheme.newtheme.buttons.sizes.smallM}
    onClick={onRefuse}
  >
    <StringToSvg
      viewBox="0 -2 30 30"
      width="23"
      height="23"
      stroke={finTheme.newtheme.color.azulPrimario}
    >
      {feather.x}
    </StringToSvg>
    &nbsp;&nbsp;Recusar
  </Button>
);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Wrapper } from 'components/FinWrappers';
import { finTheme } from 'finTheme';
import { StringToSvg } from 'helpers/svgFiles/StringToSvg';

const WrapperCuston = styled(Wrapper)`
  cursor: pointer;
  padding: 0 1rem;
  position: relative;
`;

const Label = styled(Wrapper)`
  font-size: ${({ theme }) => theme.newtheme.font.subtitulo2.tamanho};
  color: ${({ theme }) => theme.newtheme.color.cinza6};
`;

const ButtonIcons = ({ onclick, icon, children }) => {
  return (
    <WrapperCuston onClick={onclick}>
      <Wrapper padding="0.4rem">
        <Wrapper pwidth="20px" pheight="20px" padding="0">
          <StringToSvg
            viewBox="0 0 22 22"
            width="100%"
            height="100%"
            strokeWidth={'1'}
            stroke={finTheme.newtheme.color.azulPrimario}
          >
            {icon}
          </StringToSvg>
        </Wrapper>
      </Wrapper>
      <Label padding="0">{children}</Label>
    </WrapperCuston>
  );
};

ButtonIcons.propTypes = {
  onclick: PropTypes.func.isRequired,
  icon: PropTypes.string,
  children: PropTypes.any,
};

export default ButtonIcons;

import React from 'react';

import { Table } from 'components/Table';

const ContaBancaria = ({ data }) => {
  const conta = [
    {
      Banco: data.codNmBanco,
      Agência: data.agencia,
      Conta: `${data.conta}-${data.digito}`,
    },
  ];
  return (
    <>
      <Table theme="secondary" data={conta} />
      <div style={{ width: '100%', padding: '1rem' }}></div>
    </>
  );
};

export { ContaBancaria };

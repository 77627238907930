/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { createActions, createReducer } from 'reduxsauce';
import {
  call,
  put,
  take,
  fork,
  cancel,
  cancelled,
  select,
} from 'redux-saga/effects';
import axios from 'axios';

import { getUserData } from 'services/getUserData';
import { Creators as uiToasterActions } from 'store/ducks/uiToaster';
import { Creators as apiListClientesActions } from 'store/ducks/apiListClientes';
import { CreatorsUiMessages } from 'store/ducks/uiMessages';

import CONFIG from '../../config';

const { CancelToken } = axios;

/**
 * Criando action types & creators
 */

export const { Types, Creators } = createActions({
  apiDesbloquearRequest: ['idCedente'],
  apiDesbloquearSuccess: ['data'],
  apiDesbloquearError: [],
  apiDesbloquearCancel: [],
});

/**
 * Criando os reducer handlers
 */

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: false,
};

const apiSetRequest = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: true,
    data: [],
    error: false,
  };
};

const apiSetSuccess = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: action.data,
    error: false,
  };
};

const apiSetError = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: [],
    error: true,
  };
};

const apiSetCancel = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: [],
    error: false,
  };
};

/**
 * Criando reducer
 */

export default createReducer(INITIAL_STATE, {
  [Types.API_DESBLOQUEAR_REQUEST]: apiSetRequest,
  [Types.API_DESBLOQUEAR_SUCCESS]: apiSetSuccess,
  [Types.API_DESBLOQUEAR_ERROR]: apiSetError,
  [Types.API_DESBLOQUEAR_CANCEL]: apiSetCancel,
});

/**
 * Sagas
 */

function* checkPage() {
  const route = (state) => state.router.location;
  const location = yield select(route);
  if (location.pathname === '/clientes') {
    const query = [];
    Object.keys(location.query).forEach((res) => {
      if (['page', 'qtd', 'order', 'aba'].includes(res)) {
        query.push(res);
      }
    });

    if (query.length !== 4) {
      return false;
    }
    return true;
  }
  return false;
}

function* sagaGetAsynResult(params, endpoint) {
  const source = CancelToken.source();
  const url = `${CONFIG.API_REMODELING_URL}${endpoint}`;
  try {
    const response = yield axios.put(url, params, {
      cancelToken: source.token,
    });

    yield put(Creators.apiDesbloquearSuccess(response.data.data));

    const stateRouter = (state) => state.router;
    const routes = yield select(stateRouter);

    if (routes.location.state && routes.location.state.background) {
      yield put(CreatorsUiMessages.uiSendMessage('unblock_sucessfully'));
    }

    yield put(
      apiListClientesActions.apiListClientesUnlockSent({
        ready: true,
        id: params.idGrupo,
      })
    );

    const check = yield call(checkPage);

    if (check) {
      yield put(apiListClientesActions.apiListClientesRequest(null, true));
    }

    yield put(
      uiToasterActions.uiToasterMessage(
        { message: 'Empreendedor desbloqueado!!' },
        'success'
      )
    );
  } catch (error) {
    console.log(error);
    yield put(
      apiListClientesActions.apiListClientesUnlockSent({
        remove: true,
        id: params.idGrupo,
      })
    );
    yield put(uiToasterActions.uiToasterMessage(error, 'error'));
    yield put(Creators.apiDesbloquearError());
  } finally {
    if (yield cancelled()) {
      console.log('cancel apiDesbloquear');
      source.cancel();
    }
  }
}

export function* sagaDesbloquear(action) {
  const user = yield call(getUserData);

  if (user) {
    // const params = {
    //   idCedente: action.idCedente,
    //   idPessoa: user.idUsuario,
    //   idConvenio: user.idEmpresa,
    // };

    const params = {
      idGrupo: action.idCedente,
      idUsuario: user.idUsuario,
      idConvenio: user.idEmpresa,
    };

    yield put(
      apiListClientesActions.apiListClientesUnlockSent({
        ready: false,
        id: params.idGrupo,
      })
    );

    const task = yield fork(
      sagaGetAsynResult,
      params,
      '/business-api/api/matching/conexao/desbloquear/grupo'
    );

    yield take(Types.API_DESBLOQUEAR_CANCEL);
    yield cancel(task);
  } else {
    yield put(Creators.apiDesbloquearError());
  }
}

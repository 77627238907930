import { useQuery } from 'react-query';
import axios from 'axios';

import CONFIG from 'config';
import { getUserData } from 'services/getUserData';

const baseUrl = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const apiCount = async () => {
  const { idEmpresa } = await getUserData();

  const response = axios.get(
    `${baseUrl}/convenio/contador/financiador/${idEmpresa}`
  );
  const result = await response;
  return result.data || [];
};

export const useQueryCount = () => {
  return useQuery(['count'], apiCount, {
    keepPreviousData: false,
  });
};

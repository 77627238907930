import React from 'react';
import PropTypes from 'prop-types';
import requiredIf from 'react-required-if';
import styled, { css } from 'styled-components/macro';
import { rgba } from 'polished';

import { ReactComponent as IconChecked } from 'assets/images/checked-icon.svg';
import { ReactComponent as IconIndeterminate } from 'assets/images/indeterminate-icon.svg';

const IconCheckedStyle = styled(IconChecked).attrs({
  'aria-hidden': true,
  focusable: false,
})`
  display: none;
  pointer-events: none;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
`;

const IconIndeterminateStyle = styled(IconIndeterminate).attrs({
  'aria-hidden': true,
  focusable: false,
})`
  display: none;
  pointer-events: none;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
`;

const CheckboxWrapper = styled.span`
  display: inline-block;
  position: relative;
  border: 0;
  padding: 0;
  min-width: 20px;
  height: 20px;
  background-color: ${({ theme }) => theme.newtheme.color.branco};
  border-radius: 12px;
`;

const CheckedStyle = css`
  background-color: ${({ theme }) => theme.newtheme.color.azulPrimario};
  border: 2px solid ${({ theme }) => theme.newtheme.color.azulPrimario};
  ${({ indeterminate }) =>
    indeterminate
      ? css`
          & + ${IconCheckedStyle} + ${IconIndeterminateStyle} {
            display: block;
          }
          & + ${IconCheckedStyle} {
            display: none;
          }
        `
      : css`
          & + ${IconCheckedStyle} {
            display: block;
          }
          & + ${IconCheckedStyle} + ${IconIndeterminateStyle} {
            display: none;
          }
        `}
`;

const DisabledStyle = css`
  background-color: ${({ theme }) => theme.newtheme.color.cinza2};
  border: 2px solid ${({ theme }) => theme.newtheme.color.cinza3};

  ${({ checked }) =>
    checked &&
    css`
      background-color: ${({ theme }) => theme.newtheme.color.cinza3};
      border: 2px solid ${({ theme }) => theme.newtheme.color.cinza3};
      & + ${IconCheckedStyle} {
        display: block;
      }
      & + ${IconCheckedStyle} + ${IconIndeterminateStyle} {
        display: none;
      }
    `}

  ${({ indeterminate }) =>
    indeterminate &&
    css`
      background-color: ${({ theme }) => theme.newtheme.color.cinza3};
      border: 2px solid ${({ theme }) => theme.newtheme.color.cinza3};
      & + ${IconCheckedStyle} + ${IconIndeterminateStyle} {
        display: block;
      }
      & + ${IconCheckedStyle} {
        display: none;
      }
    `}
`;

const CheckBoxStyles = styled.span`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  overflow: hidden;
  border-radius: 4px;

  background-color: transparent;
  border: 2px solid ${({ theme }) => theme.newtheme.color.cinza3};

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: none;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: none;
  }
`;

const Hover = css`
  &:hover + ${CheckBoxStyles} {
    background-color: ${({ theme }) => theme.newtheme.color.cinza1};
  }
  &:active + ${CheckBoxStyles} {
    background-color: ${({ theme }) => rgba(theme.newtheme.color.cinza2, 0.6)};
  }
`;

const Input = styled.input.attrs(({ ariaLabel }) => ({
  'aria-checked': 'mixed',
  type: 'checkbox',
  'aria-label': ariaLabel,
}))`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  opacity: 0;
  cursor: pointer;

  ${({ indeterminate, disabled }) =>
    !indeterminate &&
    css`
      &[type='checkbox']:not(:checked) {
        ${!disabled && Hover}
      }
    `}

  ${({ indeterminate }) =>
    indeterminate &&
    css`
      &[type='checkbox']:not(:checked) + ${CheckBoxStyles} {
        ${CheckedStyle}
      }
    `}

  &:checked + ${CheckBoxStyles} {
    ${CheckedStyle}
  }

  &:disabled + ${CheckBoxStyles} {
    ${DisabledStyle}
  }
`;

const LabelContent = styled.span`
  display: flex;
  justify-content: flex-start;
  margin-left: 8px;
  font-size: 14px;
  align-items: center;
`;

const Wrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const CheckboxFlat = (props) => {
  const { disabled, checked, indeterminate, label, className } = props;

  return (
    <Wrapper className={className}>
      <CheckboxWrapper>
        <Input onClick={(e) => e.stopPropagation()} {...props} />
        <CheckBoxStyles
          disabled={disabled}
          checked={checked}
          indeterminate={indeterminate}
        />
        <IconCheckedStyle width="20" height="20" />
        <IconIndeterminateStyle width="20" height="20" />
      </CheckboxWrapper>
      {label && <LabelContent>{label}</LabelContent>}
    </Wrapper>
  );
};

/**
 * checked = true is requerid if indeterminate is equal = true
 */

CheckboxFlat.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.any,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  value: PropTypes.string.isRequired,
  checked: requiredIf(PropTypes.bool, (props) => props.indeterminate),
  indeterminate: PropTypes.bool,
};

CheckboxFlat.defaultProps = {
  onChange: null,
  disabled: false,
  label: '',
  name: null,
  id: '',
  value: '',
  indeterminate: false,
};

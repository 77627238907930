import React, { useRef, useContext } from 'react';

import { SeparatorHorizontal } from 'components/FinSeparador';
import { NWButton } from 'pages/MinhaRede/Comps/Button';
import { ReactComponent as IconError } from 'assets/images/icon-close-flat.svg';
import { ReactComponent as IconCheck } from 'assets/images/icon-checkcircle.svg';
import { ReactComponent as IconCheckCircle } from 'assets/images/CheckCircleFlat.svg';
import { ReactComponent as IconThumb } from 'assets/images/icon-thumb-up.svg';
import { ReactComponent as IconProhibit } from 'assets/images/Prohibit.svg';

import { Ring } from '@uiball/loaders';

import { TargetStep, RenderSteps } from 'components/FinWizard';
import { Step1, Step2, Step3 } from 'components/FinWizard/Fincredit';

import { Limite } from '../Comps/Limite';
import { Tranche } from '../Comps/Tranche';
import { Motivo } from '../Comps/Motivo';
import { ConcentracaoMaxima } from '../Comps/ConcentracaoMaxima';
import { Title, Text, Flex, Accordion } from './style.credito';
import { ButtonStretch, ButtonModal } from '../Comps/ButtonStretch';

import { Historico } from './Historico';

import { CreditoContext, statusCondition } from './reducer';
import { useErrosForm } from '../../hooks/useErrosForm';

import {
  useMutateAprovacaoCredito,
  useMutateRecusarCredito,
} from '../../hooks/mutate/useMutateAprovacaoCredito';

export const CreditoAprovado = (props) => {
  const { idGrupo, data } = props;
  const inputLimite = useRef();
  const inputTranche = useRef();
  const inputConcentracao = useRef();
  const inputMotivo = useRef();

  const { erros, changeErros } = useErrosForm();
  const aprovacaoCredito = useMutateAprovacaoCredito();
  const recusarCredito = useMutateRecusarCredito();

  const [values, actions] = useContext(CreditoContext);

  const { changeAccordion, changeCondition, resetFields } = actions;

  const {
    fields,
    active: { condition, accordionOpen },
  } = values;

  React.useEffect(() => {
    if (condition === statusCondition.reading) {
      changeErros([]);
      resetFields();
    }
  }, [condition, changeErros, resetFields]);

  const validation = React.useCallback(
    (list) => {
      if (Array.isArray(list)) {
        const ls = [];
        list.forEach((res) => {
          if (fields[res] === 0 || fields[res] === '') {
            ls.push({ name: res, id: 1 });
          }
        });
        changeErros(ls);
        return ls.length > 0;
      }

      return null;
    },
    [changeErros, fields]
  );

  const aceitar = React.useCallback(() => {
    const erro = validation(['limite', 'tranche', 'concentracao', 'motivo']);
    if (!erro) {
      aprovacaoCredito.mutate({
        ...fields,
        idGrupo,
        changeCondition: () => changeCondition(statusCondition.reading),
      });
    }
  }, [changeCondition, idGrupo, aprovacaoCredito, validation, fields]);

  const recusar = React.useCallback(() => {
    const erro = validation(['motivo']);
    if (!erro) {
      recusarCredito.mutate({
        idGrupo,
        motivo: fields.motivo,
        changeCondition: () => changeCondition(statusCondition.reading),
      });
    }
  }, [changeCondition, idGrupo, recusarCredito, validation, fields.motivo]);

  const isDisabled = condition === statusCondition.reading;
  const dataFileds =
    condition === statusCondition.editing
      ? { ...fields }
      : { ...data, concentracao: data.concentracaoMaxima };

  return (
    <>
      <RenderSteps steps={[<Step1 />, <Step2 />, <Step3 />]} />
      <TargetStep id="target3" style={{ width: '100%' }}>
        <Flex justify="space-between" style={{ gap: '8px' }}>
          <Flex justify="flex-start">
            <IconCheckCircle />
            <Title
              style={{
                marginLeft: '0.8rem' /* color: 'var(--fingreen-300)' */,
              }}
            >
              Crédito aprovado
            </Title>
          </Flex>
          <Historico idGrupo={idGrupo}>
            <ButtonModal />
          </Historico>
          <ButtonStretch
            action={() => changeAccordion(!accordionOpen)}
            isOpen={accordionOpen}
          />
        </Flex>
        <Accordion open={accordionOpen}>
          <Flex column>
            <Text>
              Gerencie o status de aprovação e limite de crédito de seus
              clientes.
            </Text>
            <SeparatorHorizontal ppadding="0.4rem" />
            <Limite
              ref={inputLimite}
              value={dataFileds.limite}
              disabled={isDisabled}
              error={erros && erros.limite}
              onChangeEvent={(e, m, v) => actions.setLimite(v)}
              onFocus={() => changeErros([])}
            />
            <Tranche
              ref={inputTranche}
              value={dataFileds.tranche}
              disabled={isDisabled}
              error={erros && erros.tranche}
              onChangeEvent={(e, m, v) => actions.setTranche(v)}
              onFocus={() => changeErros([])}
            />
            <ConcentracaoMaxima
              ref={inputConcentracao}
              value={dataFileds.concentracao}
              disabled={isDisabled}
              error={erros && erros.concentracao}
              onChangeEvent={(e, m, v) => actions.setConcentracao(v)}
              onFocus={() => changeErros([])}
            />
            <Motivo
              ref={inputMotivo}
              value={dataFileds.motivo}
              disabled={isDisabled}
              error={erros && erros.motivo}
              onChange={(e) => actions.setMotivo(e.target.value)}
              onFocus={() => changeErros([])}
            />
            <SeparatorHorizontal ppadding="0.4rem" />
            <NWButton.Primary
              largura="total"
              disabled={isDisabled}
              onClick={() => aceitar()}
            >
              {aprovacaoCredito.isLoading ? (
                <Ring size={20} lineWeight={3} speed={2} color="white" />
              ) : (
                <>
                  <IconCheck />
                  <span style={{ marginLeft: '0.5rem' }}>Salvar</span>
                </>
              )}
            </NWButton.Primary>
            <SeparatorHorizontal ppadding="0.3rem" />
            <Flex style={{ gap: '1rem', width: '100%', alignItems: 'center' }}>
              <NWButton.Error
                largura="total"
                disabled={isDisabled}
                onClick={() => recusar()}
              >
                {recusarCredito.isLoading ? (
                  <Ring size={20} lineWeight={3} speed={2} color="white" />
                ) : (
                  <>
                    <IconError />
                    <span style={{ marginLeft: '0.5rem' }}>Recusar</span>
                  </>
                )}
              </NWButton.Error>
              {condition === statusCondition.reading ? (
                <NWButton.Secondary
                  largura="total"
                  onClick={() => changeCondition(statusCondition.editing)}
                >
                  <IconThumb />
                  <span style={{ marginLeft: '0.5rem' }}>Editar</span>
                </NWButton.Secondary>
              ) : (
                <NWButton.Secondary
                  largura="total"
                  onClick={() => {
                    changeCondition(statusCondition.reading);
                  }}
                >
                  <IconProhibit />
                  <span style={{ marginLeft: '0.5rem' }}>Cancelar</span>
                </NWButton.Secondary>
              )}
            </Flex>
          </Flex>
        </Accordion>
      </TargetStep>
    </>
  );
};

import React from 'react';
import { ContainerPaginacao, Pagination } from './styles';
import { NextButton } from './NextButton';
import { PreviousButton } from './PreviousButton';
import { FirstButton } from './FirstButton';
import { LastButton } from './LastButton';
import { NumberButton } from './NumberButton';

const filterItems = (paginacao, items) => {
  const { totalPaginas, pagina } = paginacao;
  const quantidadeItems = 10;
  const midlle = Math.round(quantidadeItems / 2);

  if (totalPaginas > quantidadeItems) {
    const a = ((pagina / quantidadeItems) * 100).toFixed(3);
    if (a > 50) {
      const val = items.filter(
        (res, index) =>
          index >= pagina - midlle - 1 && index <= pagina + midlle - 1
      );
      return val;
    }
    const filtered = items.filter((res, index) => index < quantidadeItems);
    return filtered;
  }
  return items;
};

const RenderItens = ({ paginacao, onclick }) => {
  const { totalPaginas, pagina } = paginacao;

  const listItems = [];
  for (let i = 1; i <= totalPaginas; i += 1) {
    listItems.push(
      <NumberButton
        key={i}
        onclick={onclick}
        current={pagina === i && 'active'}
        value={i}
      />
    );
  }
  return filterItems(paginacao, listItems);
};

const PaginationDefault = ({
  changePage,
  totalPages,
  page,
  estilo,
  place,
  className,
}) => {
  const align = {
    center: 'center',
    left: 'flex-start',
    rigth: 'flex-end',
  };

  const goPage = (e, i) => {
    e.preventDefault();
    e.stopPropagation();
    window.scrollTo(0, 0);

    changePage({ page: i });
  };

  return (
    totalPages > 1 && (
      <ContainerPaginacao
        place={align[place]}
        estilo={estilo}
        className={className}
      >
        <Pagination>
          <FirstButton
            onclick={goPage}
            first={1}
            visibility={Number(page) > 1 ? `visible` : `hidden`}
          />
          <PreviousButton
            onclick={goPage}
            subtract={Number(page) - 1}
            visibility={Number(page) > 1 ? `visible` : `hidden`}
          />

          <RenderItens
            paginacao={{ pagina: Number(page), totalPaginas: totalPages }}
            onclick={goPage}
          />

          <NextButton
            onclick={goPage}
            sum={Number(page) + 1}
            visibility={Number(page) < totalPages ? `visible` : `hidden`}
          />
          <LastButton
            onclick={goPage}
            last={totalPages}
            visibility={Number(page) < totalPages ? `visible` : `hidden`}
          />
        </Pagination>
      </ContainerPaginacao>
    )
  );
};

export default PaginationDefault;

import React from 'react';
import PropTypes from 'prop-types';

import { StringToSvg } from 'helpers/svgFiles/StringToSvg';
import feather from 'feather-icons/dist/icons.json';

import { Wrapper } from 'components/FinWrappers';
import { finTheme } from 'finTheme';
import { Container, ButtonClose, More, BoxCuston } from './styles';

export const ButtonMore = ({ onclick }) => {
  return (
    <Wrapper pwidth="100%">
      <More onClick={onclick}>
        &nbsp;&nbsp; Ver mais
        <StringToSvg
          viewBox="0 0 24 24"
          width="30px"
          height="27px"
          stroke={finTheme.newtheme.color.azulPrimario}
        >
          {feather['chevron-down']}
        </StringToSvg>
      </More>
    </Wrapper>
  );
};

const SearchMenu = ({ onClose, open, width, height, position, children }) => {
  return (
    open && (
      <Container pwidth={width} pos={position}>
        <ButtonClose onClick={onClose}>
          <StringToSvg viewBox="0 0 24 24" width="30px" height="27px">
            {feather.x}
          </StringToSvg>
        </ButtonClose>
        <BoxCuston noHover>
          <Wrapper
            pwidth="100%"
            pheight={height}
            tipo="column"
            padding="3rem"
            justifyContent="flex-start"
          >
            {children}
          </Wrapper>
        </BoxCuston>
      </Container>
    )
  );
};

SearchMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  position: PropTypes.string,
  padding: PropTypes.string,
};

SearchMenu.defaultProps = {
  width: '100%',
  height: '300px',
  position: '0',
};

export default SearchMenu;

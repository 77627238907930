import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TooltipDefault } from 'components/TooltipDefault';
import { IconInclude } from 'components/Elements/icons';
import { CheckBoxCuston } from 'components/CheckboxComp/CheckboxCuston';
import { finTheme } from 'finTheme';

import {
  ContentList,
  TextList,
  Feedback,
  WrapperSelected,
  BtnClose,
} from './Styles';

const BuscaCnae = ({
  value,
  handleBlur,
  fieldName,
  dataCnae,
  isloadingCnae,
  removeCnae,
  addCnaeItem,
  close,
  addSearch,
}) => {
  const [listCnaes, setListaCnaes] = useState([]);
  const [listCnaesSelected, setListaCnaesSelected] = useState([]);

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delay: 0.3,
        when: 'beforeChildren',
        staggerChildren: 0.1,
      },
    },
  };

  const handleChangeCuston = (event, cnae) => {
    console.log(event);
    removeCnae(cnae);

    addSearch(event.target.value);
  };

  const renderOptions = () => {
    if (!isloadingCnae && isloadingCnae !== null) {
      return listCnaes.map((res) => {
        return (
          <li key={res.cnae}>
            {value.filter((r) => {
              const v = JSON.parse(r);
              return v.cnae === res.cnae;
            }).length === 0 && (
              <>
                <TooltipDefault
                  bgcolor={finTheme.newtheme.color.cinza6}
                  data={res.descricao}
                  position="top"
                  style={{ width: 'fit-content' }}
                >
                  <CheckBoxCuston
                    id={`${fieldName}.${res.cnae}`}
                    type="checkbox"
                    name={fieldName}
                    value={JSON.stringify({
                      cnae: res.cnae,
                      descricao: res.descricao,
                    })}
                    checked={
                      /* value.includes(res.cnae.toString()) */ value.filter(
                        (r) => {
                          const v = JSON.parse(r);
                          return v.cnae === res.cnae;
                        }
                      ).length > 0
                    }
                    onChange={(event) => handleChangeCuston(event, res.cnae)}
                    onBlur={handleBlur}
                  />
                  <TextList
                    lwidth="270px"
                    para={`${fieldName}.${res.cnae}`}
                    id={`${fieldName}.${res.cnae}`}
                  >
                    {res.descricao}
                  </TextList>
                </TooltipDefault>
              </>
            )}
          </li>
        );
      });
    }
    return null;
  };

  const handleChangeCustonAdd = (event, cnae, descricao) => {
    console.log(event);

    addCnaeItem({ cnae, descricao });

    addSearch(event.target.value);
  };

  const renderSelected = () => {
    return listCnaesSelected.map((res) => {
      const resParse = JSON.parse(res);
      return (
        <li key={resParse.cnae}>
          <TooltipDefault
            bgcolor={finTheme.newtheme.color.cinza6}
            data={resParse.descricao}
            position="top"
            style={{ width: 'fit-content' }}
          >
            <CheckBoxCuston
              id={`${fieldName}.${resParse.cnae}`}
              type="checkbox"
              name={fieldName}
              value={JSON.stringify({
                cnae: resParse.cnae,
                descricao: resParse.descricao,
              })}
              checked={
                listCnaesSelected.filter((r) => {
                  const v = JSON.parse(r);
                  return v.cnae === resParse.cnae;
                }).length > 0
              }
              onChange={(event) =>
                handleChangeCustonAdd(event, resParse.cnae, resParse.descricao)
              }
              onBlur={handleBlur}
            />
            <TextList
              lwidth="270px"
              para={`${fieldName}.${resParse.cnae}`}
              id={`${fieldName}.${resParse.cnae}`}
            >
              {resParse.descricao}
            </TextList>
          </TooltipDefault>
        </li>
      );
    });
  };

  const checkDataValues = (data1, data2) => {
    const values = [];
    data1.forEach((res) => {
      const v = typeof res === 'string' ? JSON.parse(res) : res;
      data2.forEach((r) => {
        const a = typeof r === 'string' ? JSON.parse(r) : r;
        if (v.cnae && a.cnae) {
          if (v.cnae.toString() === a.cnae.toString()) {
            values.push(v.cnae);
          }
        }
      });
    });
    return values;
  };

  useEffect(() => {
    if (value.length > 0) {
      const v = checkDataValues(dataCnae, value);
      if (v.length > 0) {
        removeCnae(v);
      }
    }
    setListaCnaes(dataCnae);
  }, [dataCnae, removeCnae, value]);

  useEffect(() => {
    setListaCnaesSelected(value);
    console.log(value);
  }, [value]);

  return (
    <ContentList
      id="busca-content"
      variants={container}
      initial="hidden"
      animate="visible"
    >
      <BtnClose onClick={() => close()}>
        <IconInclude
          bgcolor={finTheme.newtheme.color.cinza6}
          icon="X"
          padding="0.8rem"
          style={{ marginRight: '5px' }}
        />
      </BtnClose>
      <WrapperSelected id="selected-content">
        <h4>Selecionados</h4>
        <ul style={{ padding: '16px' }}>{renderSelected()}</ul>
      </WrapperSelected>
      {dataCnae.length > 0 ? (
        <ul style={{ padding: '16px' }}>{renderOptions()}</ul>
      ) : (
        <Feedback>Sem resultados</Feedback>
      )}
    </ContentList>
  );
};

const mapStateToProps = (state) => {
  return {
    isloadingCnae: state.filters.isloadingCnae,
    dataCnae: state.filters.dataCnae,
    errorCnae: state.filters.errorCnae,
  };
};

export default connect(mapStateToProps)(BuscaCnae);

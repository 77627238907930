import React from 'react';

import * as StylesContato from './styles.infocontato';

export function InfoContato({ label, text, largura }) {
  return (
    <StylesContato.Content marginRight="10px" largura={largura}>
      <StylesContato.SubTitle>{label}</StylesContato.SubTitle>
      <StylesContato.InformationAdd>{text}</StylesContato.InformationAdd>
    </StylesContato.Content>
  );
}

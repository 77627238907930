/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { createActions, createReducer } from 'reduxsauce';
import {
  call,
  put,
  take,
  fork,
  cancel,
  cancelled,
  select,
} from 'redux-saga/effects';
import axios from 'axios';

import { getUserData } from 'services/getUserData';
import { Creators as uiToasterActions } from 'store/ducks/uiToaster';
import { Creators as apiConvitesEnviadosActions } from 'store/ducks/apiConvitesEnviados';
import { Creators as apiListClientesActions } from 'store/ducks/apiListClientes';
import { CreatorsUiMessages } from 'store/ducks/uiMessages';

import CONFIG from '../../config';

const { CancelToken } = axios;

/**
 * Criando action types & creators
 */

export const { Types, Creators } = createActions({
  apiCancelarRequest: ['idConvite', 'idCedente'],
  apiCancelarSuccess: ['data'],
  apiCancelarError: [],
  apiCancelarCancel: [],
});

/**
 * Criando os reducer handlers
 */

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: false,
};

const apiSetRequest = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: true,
    data: [],
    error: false,
  };
};

const apiSetSuccess = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: action.data,
    error: false,
  };
};

const apiSetError = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: [],
    error: true,
  };
};

const apiSetCancel = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    isLoading: false,
    data: [],
    error: false,
  };
};

/**
 * Criando reducer
 */

export default createReducer(INITIAL_STATE, {
  [Types.API_CANCELAR_REQUEST]: apiSetRequest,
  [Types.API_CANCELAR_SUCCESS]: apiSetSuccess,
  [Types.API_CANCELAR_ERROR]: apiSetError,
  [Types.API_CANCELAR_CANCEL]: apiSetCancel,
});

/**
 * Sagas
 */

function* sagaGetAsynResult(params, endpoint, idCedente) {
  const source = CancelToken.source();
  const url = `${CONFIG.API_REMODELING_URL}${endpoint}/${params.idConexao}`;
  try {
    const response = yield axios.delete(url, {
      cancelToken: source.token,
    });

    yield put(Creators.apiCancelarSuccess(response.data.data));

    const stateRouter = (state) => state.router;
    const routes = yield select(stateRouter);

    if (routes.location.pathname.indexOf('detalhe') !== -1) {
      yield put(CreatorsUiMessages.uiSendMessage('cancel_sucessfully'));
    }

    yield put(
      apiConvitesEnviadosActions.apiConvitesEnviadosRemoveSent({
        ready: true,
        id: params.idConexao,
      })
    );

    if (idCedente) {
      yield put(
        apiListClientesActions.apiListClientesCancelInviteSent({
          ready: true,
          id: idCedente,
        })
      );
    }

    yield put(
      uiToasterActions.uiToasterMessage(
        { message: 'Convite removido!!' },
        'success'
      )
    );
  } catch (error) {
    console.log(error);

    yield put(
      apiConvitesEnviadosActions.apiConvitesEnviadosRemoveSent({
        remove: true,
        id: params.idConexao,
      })
    );
    if (idCedente) {
      yield put(
        apiListClientesActions.apiListClientesCancelInviteSent({
          remove: true,
          id: idCedente,
        })
      );
    }

    yield put(uiToasterActions.uiToasterMessage(error, 'error'));
    yield put(Creators.apiCancelarError());
  } finally {
    if (yield cancelled()) {
      console.log('cancel');
      source.cancel();
    }
  }
}

export function* sagaCancelar(action) {
  const user = yield call(getUserData);

  console.log(action);

  if (user) {
    // const params = {
    //   idEmpresa: user.idEmpresa,
    //   idPessoa: user.idUsuario,
    //   idConvite: action.idConvite,
    // };

    const params = {
      idConexao: action.idConvite,
    };

    yield put(
      apiConvitesEnviadosActions.apiConvitesEnviadosRemoveSent({
        ready: false,
        id: params.idConexao,
      })
    );

    if (action.idCedente) {
      yield put(
        apiListClientesActions.apiListClientesCancelInviteSent({
          ready: false,
          id: action.idCedente,
        })
      );
    }

    const task = yield fork(
      sagaGetAsynResult,
      params,
      '/business-api/api/matching/conexao/cancelar',
      action.idCedente
    );

    yield take(Types.API_CANCELAR_CANCEL);
    yield cancel(task);
  } else {
    yield put(Creators.apiCancelarError());
  }
}

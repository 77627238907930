import styled, { css } from 'styled-components/macro';
import { rgba } from 'polished';
import { Tr } from 'components/FinTable';

export const TrCustom = styled(Tr)`
  cursor: pointer;

  &:hover {
    background-color: ${rgba('#f6f6f6', 0.4)};
  }
  ${({ active }) =>
    active &&
    css`
      background-color: ${rgba('#f6f6f6', 0.2)};
    `}
`;

import React from 'react';
import { Title4 } from 'components/FinHeadings';
import { Paragraph2 } from 'components/FinParagraph';
import { Wrapper } from 'components/FinWrappers';
import { SeparatorHorizontal } from 'components/FinSeparador';
import { Container, BoxWrapper, WrapperChart } from './styles.dashboard';

import { BarChart } from './BarChart';
import { BarChartConcentracao } from './BarChartConcentracao';
import { LineChartDiasFaturamento } from './LineChartDiasFaturamento';

import {
  useFaturamentoMensal,
  useFaturamentoDiario,
  useConcentracaoSacado,
} from '../hooks/queries';

export const PaginaDataCharts = ({ idEmpreendedor }) => {
  const faturamentoMensal = useFaturamentoMensal({
    ano: 2021,
    idEmpresa: idEmpreendedor,
  });
  const faturamentoDiario = useFaturamentoDiario({
    idEmpresa: idEmpreendedor,
  });
  const concentracaoSacado = useConcentracaoSacado({
    idEmpresa: idEmpreendedor,
  });

  return (
    <Container>
      <Wrapper
        tipo="column"
        pwidth="1000px"
        alignItems="flex-start"
        padding="1rem 0"
      >
        <p>
          A funcionalidade, Finscanner, está em teste. Isso significa que os
          dados exibidos abaixo são apenas referentes aos títulos carregados na
          plataforma pelo empreendedor, o que pode não representar 100% do
          faturamento da empresa.
        </p>
        <Title4 fontWeigth="bold">Faturamento Mensal</Title4>
        <Paragraph2>Valor do faturamento mensal</Paragraph2>
      </Wrapper>
      <BoxWrapper>
        <WrapperChart>
          <BarChart
            faturamentoMensal={faturamentoMensal}
            idEmpreendedor={idEmpreendedor}
          />
        </WrapperChart>
      </BoxWrapper>
      <SeparatorHorizontal />
      <Wrapper
        tipo="column"
        pwidth="1000px"
        alignItems="flex-start"
        padding="1rem 0"
      >
        <Title4 fontWeigth="bold">Faturamento médio diário</Title4>
        <Paragraph2>Com base nos ultimos 12 meses</Paragraph2>
      </Wrapper>
      <BoxWrapper>
        <WrapperChart>
          <LineChartDiasFaturamento
            faturamentoDiario={faturamentoDiario}
            idEmpreendedor={idEmpreendedor}
          />
        </WrapperChart>
      </BoxWrapper>
      <SeparatorHorizontal />
      <Wrapper
        tipo="column"
        pwidth="1000px"
        alignItems="flex-start"
        padding="1rem 0"
      >
        <Title4 fontWeigth="bold">Concentração de Sacados - Top 10</Title4>
        <Paragraph2>
          Com base no faturamento médio mensal dos ultimos 90 dias
        </Paragraph2>
      </Wrapper>
      <BoxWrapper altura="400px">
        <WrapperChart>
          <BarChartConcentracao
            concentracaoSacado={concentracaoSacado}
            idEmpreendedor={idEmpreendedor}
          />
        </WrapperChart>
      </BoxWrapper>
      <SeparatorHorizontal />
    </Container>
  );
};

import { useQuery } from 'react-query';
import axios from 'axios';
import CONFIG from 'config';

const BASE_URL = `${CONFIG.API_REMODELING_URL}/business-api/api`;

const fetchExibePrecos = async (params) => {
  const fetchData = axios.get(
    `${BASE_URL}/operacao/exibe/preco/${params.idOperacao}`
  );

  const result = await fetchData;
  const { data } = result;

  return data || [];
};

export const useFetchExibePrecos = (params) => {
  return useQuery(
    ['operacoes', 'preco', params],
    () => fetchExibePrecos(params),
    {
      keepPreviousData: false,
    }
  );
};

const INITIAL_STATE = {
  data: [],
  isLoading: false,
  error: false,
  isLoadingEnabled: false,
  isLoadingInfo: false,
  dataInfo: [],
  isLoadingDownload: false,
  dataMeeting: [],
  isLoadingMeeting: null,
  errorMeeting: null,
  dataPermissao: [],
  isLoadingPermissao: null,
  errorPermissao: null,
};

const finbox = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    /** ******************** LIST ********************* */
    case 'LOAD_LIST_REQUEST':
      // console.log(action);
      return {
        ...state,
        isLoading: true,
      };
    case 'LOAD_LIST_SUCCESS':
      // console.log(action);
      return {
        ...state,
        data: action.data,
        isLoading: false,
      };
    case 'LOAD_LIST_FAILURE':
      return {
        ...state,
        data: action.data,
        error: true,
      };
    /** ******************** Download ********************* */
    case 'DOWNLOAD_DOC_REQUEST':
      return {
        ...state,
        isLoadingDownload: true,
      };
    case 'DOWNLOAD_DOC_SUCCESS':
      return {
        ...state,
        isLoadingDownload: false,
      };
    case 'DOWNLOAD_DOC_FAILURE':
      return {
        ...state,
        data: action.data,
        isLoadingDownload: false,
        error: true,
      };
    /** ******************** Habilita ********************* */
    case 'HABILITA_REQUEST':
      return {
        ...state,
        isLoadingEnabled: true,
      };
    case 'HABILITA_SUCCESS':
      return {
        ...state,
        isLoadingEnabled: false,
      };
    case 'HABILITA_FAILURE':
      return {
        ...state,
        data: action.data,
        isLoadingEnabled: false,
        error: true,
      };
    /** ******************** InfoBasica ********************* */
    case 'INFOBASICA_REQUEST':
      return {
        ...state,
        isLoadingInfo: true,
      };
    case 'INFOBASICA_SUCCESS':
      return {
        ...state,
        isLoadingInfo: false,
        dataInfo: action.data,
      };
    case 'INFOBASICA_FAILURE':
      return {
        ...state,
        data: action.data,
        isLoadingInfo: false,
        error: true,
      };
    case 'INFOBASICA_CLEAR':
      return {
        ...state,
        dataInfo: [],
      };
    /** ******************** Meeting ********************* */
    case 'GENERATES_MEETING_REQUEST':
      return {
        ...state,
        isLoadingMeeting: true,
      };
    case 'GENERATES_MEETING_SUCCESS':
      return {
        ...state,
        dataMeeting: action.data,
        isLoadingMeeting: false,
        errorMeeting: false,
      };
    case 'GENERATES_MEETING_FAILURE':
      return {
        ...state,
        dataMeeting: action.data,
        isLoadingMeeting: false,
        errorMeeting: true,
      };
    /** ******************** Permissoes ********************* */
    case 'PERMISSOES_REQUEST':
      return {
        ...state,
        isLoadingPermissao: true,
      };
    case 'PERMISSOES_SUCCESS':
      return {
        ...state,
        dataPermissao: action.data,
        isLoadingPermissao: false,
        errorPermissao: false,
      };
    case 'PERMISSOES_FAILURE':
      return {
        ...state,
        dataPermissao: action.data,
        isLoadingPermissao: false,
        errorPermissao: true,
      };
    default:
      return state;
  }
};

export default finbox;
